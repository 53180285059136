import { createContext } from 'react'

export type Message = {
    success: string
    error: string
    form_error: string
    warning: string
    info: string
}

export type SetMessage = React.Dispatch<React.SetStateAction<Message>>

type MessageContextType = {
    message: { success: string, error: string, form_error: string, warning: string, info: string }
    setMessage: SetMessage
}

// error is used to dispaly the error snackbar where as form_error is used to display the red text that is found under forms
export const MessageContext = createContext<MessageContextType>({
    message: {success: '', error: '', form_error: '', warning: '', info: ''},
    setMessage: () => {}
})