import { CRUDAction } from "@actions/Actions/actions"
import FranchiseCostingColumnHandler from "@adapters/handlers/CRUDHandlers/FranchiseCostingColumnHandlers/franchiseCostingHandlers"


class FranchiseCostingColumnActions extends CRUDAction {
    handler: FranchiseCostingColumnHandler
    
    constructor() {
        super()
        this.handler = new FranchiseCostingColumnHandler()
    }
}

export default FranchiseCostingColumnActions