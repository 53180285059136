import Table from '@components/tables/Table/Table'
import FranchisorRoyaltyActions from '@actions/Finance/FranchisorInvoiceActions/franchisorInvoiceActions'
import RoyaltyInvoiceActions from '@actions/Finance/RoyaltyInvoiceActions/royaltyInvoiceActions'

import EditRoyaltyInvoicesHandler from '../RoyaltiesInvoicesTable/EditRoyaltyInvoicesHandler'
import MarkAsPaidDialogHandler from './MarkAsPaidDialogHandler'


export interface TableProps {
	action: FranchisorRoyaltyActions | RoyaltyInvoiceActions
	// awaitingPaymentLengthCallback: (data: any) => void
}

const AwaitingRoyaltiesTable = (props: TableProps) => {
	// const {action, awaitingPaymentLengthCallback} = props
	const {action} = props

	const columns = [
		{ field: 'id', headerName: 'ID' },
		{ field: 'tenant_name', headerName: "Franchisee", minWidth: 200, flex: 2 },
		{ field: 'num_lines', headerName: 'Number of lines', minWidth: 150, flex: 2 },
		{ field: 'total_amount', headerName: 'Invoice amount', minWidth: 150, flex: 1.5 },
        { field: 'invoice_type', headerName: 'Invoice Type', minWidth: 150, flex: 2 },
		{ field: 'school_type', headerName: 'School Type', minWidth: 150, flex: 1 },
		{ field: 'due_date', headerName: 'Due Date', minWidth: 100, flex: 2 },
		{ field: 'days_overdue', headerName: 'Days Overdue', minWidth: 120, flex: 2 },
		{ field: 'date_sent', headerName: 'Date sent', minWidth: 150, flex: 1 },
	]

    return (
        <Table single_selection_dialogs={[EditRoyaltyInvoicesHandler]} dialogs={[MarkAsPaidDialogHandler]} action={action} filter={{'approved': 'True'}} columns={columns}></Table>
    )
}

export default AwaitingRoyaltiesTable