import React, { useState, useContext, useEffect } from 'react'

import InastixWord from '@images/inastix/inastix_word.png'

import { useMediaQuery, Theme } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Tooltip from '@mui/material/Tooltip'

import Form, { FormDataEntryType } from '@components/forms/Form'
import { AuthenticationAction } from '@actions/Actions/actions'
import { IsLoggedInContext } from '@contexts/IsLoggedInContext'
import { JWT_REGISTER_ENDPOINT } from '@adapters/routes/endpoints'
import { SetupStepContext } from '@contexts/SetupStepContext'
import { MessageContext } from '@contexts/MessageContext'

import { handleLogin } from '../helper/helper'
import XeroSignUp from '../XeroSignUp'
import PasswordRules from './PasswordRules'

import './passwordRules.css'
import { AuthTypography } from '../util/StyledComponents'


const SignUp = () => {
    const is_small_screen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

    const theme = useTheme()

	const {setIsLoggedIn} = useContext(IsLoggedInContext)
    const {setMessage} = useContext(MessageContext)
	const {setSetupStep} = useContext(SetupStepContext)

    const [email, set_email] = useState('')
    const [first_name, set_first_name] = useState('')
    const [last_name, set_last_name] = useState('')
    const [password, set_password] = useState('')
    const [confirm_password, set_confirm_password] = useState('')
    const [passwords_match, set_passwords_match] = useState(true)

    const [email_blurred, set_email_blurred] = useState(false)
    const [name_blurred, set_name_blurred] = useState(false)
    const [password_focus, set_password_focus] = useState(false)

    const [accepted_terms, set_accepted_terms] = useState(false)

    useEffect(() => {
        // Load reCAPTCHA script when the component mounts
        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js?render=6LeSCWUmAAAAAC6acEwKoQoAcbpg_Do1RIesd0zt';
        script.async = true;
        document.body.appendChild(script)
  
        return () => {
          // Cleanup: Remove the script when the component unmounts
          document.body.removeChild(script)
        }
    }, [])

    const isFormValid = () =>{
        return (
            validate_email(email) &&
            password === confirm_password && 
            accepted_terms
        )
    }

    // blur function changes state after validation is done
    const handle_blur = (event: React.FocusEvent<HTMLInputElement>) => {
        const { name: field_name } = event.target
        switch (field_name) {
            case 'email':
                set_email_blurred(true)
                if (email === '' || !validate_email(email)) {
                    event.target.classList.add('error')
                } else {
                    event.target.classList.remove('error')
                }
                break
            case 'fullName':
                set_name_blurred(true)
                if (first_name + " " + last_name === '') {
                    event.target.classList.add('error')
                } else {
                    event.target.classList.remove('error')
                }
                break
            default:
                break
        }
    }
    
    const handle_email_change = (event: React.ChangeEvent<HTMLInputElement>) =>{
        set_email(event.target.value)
    }

    const handle_first_name_change = (event: React.ChangeEvent<HTMLInputElement>) =>{
        set_first_name(event.target.value)
    }

    const handle_last_name_change = (event: React.ChangeEvent<HTMLInputElement>) =>{
        set_last_name(event.target.value)
    }

    const handle_password_change = (event: React.ChangeEvent<HTMLInputElement>) =>{
        set_password(event.target.value)
        set_passwords_match(event.target.value === confirm_password)
    }

    const handle_confirm_password_change = (event: React.ChangeEvent<HTMLInputElement>) => {
        set_confirm_password(event.target.value)
        set_passwords_match(password === event.target.value)
    }
    
    const validate_email = (email: string) => {
        return /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)
    }

    const createFields = () => {
        return (
            <>
            <FormControl sx={{padding: '0 2rem'}}>
                <Box display='flex' gap={2}>
                    <TextField 
                        required
                        id="firstname"
                        name="firstname"
                        label="First Name"
                        value={first_name}
                        onChange={handle_first_name_change}
                        onBlur={handle_blur}
                        autoComplete="given-name"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField 
                        required
                        id="lastname"
                        name="lastname"
                        label="Last Name"
                        value={last_name}
                        onChange={handle_last_name_change}
                        onBlur={handle_blur}
                        autoComplete="family-name"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Box>
                <br/>
                <TextField 
                    required
                    id="email"
                    name="email"
                    label="Email Address" 
                    value={email}
                    onChange={handle_email_change}
                    onBlur={handle_blur}
                    error={email_blurred && !validate_email(email)}
                    helperText={email_blurred && !validate_email(email) && 'Invalid email'}
                    autoComplete="email"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <br/>
                <TextField
                    required
                    id="password"
                    name="password"
                    label="Password"
                    type='password'
                    value={password}
                    onChange={handle_password_change}
                    onFocus={() => set_password_focus(true)}
                    onBlur={() => set_password_focus(false)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {password_focus && (
                                        <div className="password-rules">
                                            <PasswordRules password={password} />
                                        </div>
                                    )
                                }
                            </InputAdornment>
                        ),
                    }}
                    autoComplete="password"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <br/>
                <Tooltip
                    PopperProps={{
                        disablePortal: true,
                    }}
                    open={!passwords_match}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title="Passwords do not match"
                    placement={is_small_screen ? 'bottom' : 'right'}
                    arrow
                >
                    <TextField
                        required
                        error={!passwords_match}
                        name="confirm_password"
                        label="Confirm Password"
                        type='password'
                        value={confirm_password}
                        onChange={handle_confirm_password_change}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Tooltip>
                <FormControlLabel
                    sx={{margin: '0.8rem 2rem 1rem 2rem'}}
                    control={
                        <Checkbox
                            id="accepted-terms-checkbox"
                            checked={accepted_terms}
                            onChange={(event)=> set_accepted_terms(event.target.checked)}
                        />
                    }
                    label={<>I accept the <Link href="https://monkeynastixonline.co.za/POPIACT2021.pdf" target="_blank"> Privacy Policy</Link></>}
                />
                <Button variant="contained" type="submit"> Sign Up </Button> 
            </FormControl>
            </> 
        )
    }

    const onSubmit = async (form_data_object: FormDataEntryType): Promise<any> => {
        if (isFormValid()) {    
            setMessage(props => ({...props, form_error: ""}))
            
            // Call the Google reCAPTCHA API to get the response token
            const token = await window.grecaptcha.execute('6LeSCWUmAAAAAC6acEwKoQoAcbpg_Do1RIesd0zt', { action: 'submit' })

            delete form_data_object['firstname']
            delete form_data_object['lastname']
            // Here, you can send the token to the backend for verification if required.
            // Append the reCAPTCHA token to the FormData
            const updatedFormDataObject = {
                ...form_data_object,
                recaptcha_token: token,
                fullname: first_name + " " + last_name
            }
            
            const authentication_action = new AuthenticationAction()
            return authentication_action.authenticate(JWT_REGISTER_ENDPOINT, updatedFormDataObject)
            .then(user_jwt => {
                localStorage.setItem('signed_in_with_xero', "false")
                handleLogin(user_jwt, setSetupStep, setIsLoggedIn)
            })
        }
        else
            setMessage(props => ({...props, form_error: "Make sure all fields are filled in correclty"}))
	}

    return (
        <Grid container height={'100vh'} sx={{gap: { xs: '1rem', md: 0 }}} alignItems={'center'} flexDirection={'row-reverse'}>
            <Grid item md={6} sm={12} xs={12} padding={2} sx={{backgroundColor: theme.palette.primary.main, height:'100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '3rem'}}>
                <Box  sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <div style={{position: 'relative'}}>
                        <Typography sx={{position: 'absolute', right: 0, top: '-3px', color: 'white', fontSize: '0.9rem'}}> Welcome to </Typography> 
                        <img src={InastixWord} width="230ch" alt="iNastix logo"/>
                    </div>                
                </Box>
                <Box>
                    <Typography color="white" variant="h4" fontWeight={'bold'}> {is_small_screen ? "Sign up with" : "Alternatively,"} </Typography>
                    <br/>
                    <XeroSignUp label_text={is_small_screen ? 'Use an existing Xero account': "Sign up with an existing Xero account"}/>
                </Box>
            </Grid>
            <Grid item md={6} sm={12} xs={12} textAlign={'center'} sx={is_small_screen ? {padding: '0 3rem 1rem 3rem'}: {}}>
                <AuthTypography variant='h1' mt={5} mb={4}> Create your account </AuthTypography>
                <Form onSubmitCallback={onSubmit} createFields={createFields} autofill='off'/>
                <Link href="/login" sx={{display: 'block', marginTop:2, width: 'fit-content', marginLeft: 'auto', marginRight: 'auto'}}> Login </Link>
            </Grid>
        </Grid>
    )
}

export default SignUp