import { FormDataEntryType } from "@components/forms/Form"


interface FormDataObject {
    id: string
    [key: string]: any
}

const parseInstructorFinanceAndPermissionData = (form_data: FormDataEntryType) => {
    const parsed_form_data: object[] = []
    const permission_form_data: FormDataObject[] = []

    for (const key in form_data) {
        const result: Record<string, string | boolean | FormDataEntryValue> = {}

        const [id, field] = key.split(":")
        
        const value = form_data[key]
        
        result[field] = value

        if (!Object.keys(permission_form_data).length) {
            permission_form_data.push({id: id as string})
        }

        if (field === 'manage_classes' || field === 'manage_members' || field === 'transfer_children' || field === 'manage_schools') {
            const existingPermission = permission_form_data.find(permission => permission.id === id)

            if (existingPermission)
                existingPermission[field] = value
            else
                permission_form_data.push({ id, [field]: value })
        }
        else if (field === 'salary' || field === 'commission')
            parsed_form_data.push(result)
        else {
            const value = form_data[key]
            const id = form_data['id']
            parsed_form_data.push({id: id, [key]: value})
        }
    }

    return {parsed_form_data, permission_form_data}
}

export {
    parseInstructorFinanceAndPermissionData
}