import {ReactNode} from 'react'

import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'

import Pages from '@pages/Page'

import SideNav from './SideNav'


interface SettingsProps {
    children: ReactNode
}

const Settings = (props: SettingsProps) => {
    const {children} = props

    return (
        <Pages page_title="Settings">
            <Grid container> 
                <Grid item md={4} sm={12} xs={12}>
                    <SideNav/>
                </Grid>
                <Grid item md={8} sm={12} xs={12}>
                    <Box sx={{margin: '1rem 2rem', position: 'relative'}}>{children}</Box>
                </Grid>
            </Grid>
        </Pages>
    )
}

export default Settings