import log from "loglevel"

import { JWT_REFRESH_ENDPOINT } from "@adapters/routes/endpoints"
import { logoutRisky } from "@adapters/helpers/logout"
import { getLocalStorageUser } from "@utils/localStorage/user"

import FetchWrapper from "./fetchWrapper"
import { ContentType, MethodType } from "../enums/enum"


class GenericFetchWrapper extends FetchWrapper {
    refreshAttempted: boolean
    content_type: ContentType

    constructor(content_type: ContentType = ContentType.JSON) {
        super(content_type)
        this.content_type = content_type
        this.refreshAttempted = false
    }

    refreshToken = async () => {
        const previous_content_type = this.content_type
        this.content_type = ContentType.JSON  // set content_type back to json because that's what's needed to submit refresh request. content_type might have been changed for example by a multipart/form-data request
        return this.post(JWT_REFRESH_ENDPOINT, {refresh: localStorage.getItem('refresh_token')})
        .then(data => {
            localStorage.setItem('access_token', data.access_token)
            localStorage.setItem('refresh_token', data.refresh_token)
            this.content_type = previous_content_type
        })
    }

    // TODO: This needs to be handleFetch method. Can't call super.handleFetch() in child for some reason.
    getHandleFetch = async (url: string, data: object, method_type: MethodType): Promise<any> => {
        const request_options = this.createRequestOptions(method_type, data)

        return fetch(url, request_options)
        .then(async response => {

            if (response.status === 401) {
                // if we get a  401 from the jwt refresh endpoint it means the refresh token is no longer valid and we need to log the user out
                if (this.refreshAttempted) {
                    return logoutRisky()
                }

                log.info('Access Token expired')
                
                this.refreshAttempted = true
                try {
                    await this.refreshToken()
                    log.info('Refreshed the Access Token')
                } catch (error) {
                    log.error(error)
                    return logoutRisky()
                }

                // If the access token was successfully refreshed, make the same request
                const response = this.handleFetch(url, data, method_type)
                return response
            }
            else if (!response.ok) {

                return response.text().then(text => {
                    const errorMessage = text.replace(/"/g, '')  // replaces "" with empty space, so our error message appears without quotes in the ui

                    throw new Error(errorMessage)
                })
            }

            let response_data = await response.json()

            return response_data
        })
    }

    // TODO: needs the correct implementation here
    handleFetch = async (url: string, data: object, method_type: MethodType): Promise<any> => {
        const request_options = this.createRequestOptions(method_type, data)
        return fetch(url, request_options)
        .then(async response => {

            if (response.status === 401) {
                // if we get a  401 from the jwt refresh endpoint it means the refresh token is no longer valid and we need to log the user out
                if (this.refreshAttempted) {
                    return logoutRisky()
                }

                const user = getLocalStorageUser()
                if (!Object.keys(user).length)
                    window.location.href = '/login'

                log.info('Access Token expired')
                
                this.refreshAttempted = true
                try {
                    await this.refreshToken()
                    log.info('Refreshed the Access Token')
                } catch (error) {
                    log.error(error)
                    return logoutRisky()
                }

                // If the access token was successfully refreshed, make the same request
                const response = this.handleFetch(url, data, method_type)
                return response
            
            }
            else if (!response.ok) {

                return response.text().then(text => {
                    const errorMessage = text.replace(/"/g, '')  // replaces "" with empty space, so our error message appears without quotes in the ui

                    throw new Error(errorMessage)
                })
            }
            else if (response.status === 204)
                return  // return nothing if the response has no content

            let response_data
            if (this.content_type === ContentType.SPREADSHEET || this.content_type === ContentType.CSV)
                response_data = await response.blob()
            else
                response_data = await response.json()

            return response_data
        })
    }  
}

export default GenericFetchWrapper