import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import Typography from '@mui/material/Typography'

import Form, { FormDataEntryType } from '@components/forms/Form'

import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'

import { MessageContext } from '@contexts/MessageContext'
import { AuthenticationAction } from '@actions/Actions/actions'
import AuthFooter from '../util/AuthFooter'
import { AuthTypography } from '../util/StyledComponents'
import { RESET_PASSWORD_ENDPOINT } from '@adapters/routes/endpoints'


const ForgotPassword = () => {
    const navigate = useNavigate()

    const {setMessage} = useContext(MessageContext)

    const [email, set_email] = useState('')
    const [email_blurred, set_email_blurred] = useState(false)

    useEffect(() => {
        // Load reCAPTCHA script when the component mounts
        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js?render=6LeSCWUmAAAAAC6acEwKoQoAcbpg_Do1RIesd0zt';
        script.async = true;
        document.body.appendChild(script);
  
        return () => {
          // Cleanup: Remove the script when the component unmounts
          document.body.removeChild(script);
        };
      }, []);
    
    const isFormValid = () =>{
        return (
            validate_email(email)
        )
    }

    // blur function changes state after validation is done
    const handle_blur = (event: React.FocusEvent<HTMLInputElement>) => {
        const { name: field_name } = event.target
        switch (field_name) {
            case 'email':
                set_email_blurred(true)
                if (email === '' || !validate_email(email)) {
                    event.target.classList.add('error')
                } else {
                    event.target.classList.remove('error')
                }
                break
            default:
                break
        }
    }
    
    const handle_email_change = (event: React.ChangeEvent<HTMLInputElement>) =>{
        set_email(event.target.value)
    }
    
    const validate_email = (email: string) => {
        return /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)
    }

    const createFields = () => {
        return (
            <>
            <Box>
                <TextField 
                    required
                    name="email"
                    label="Email Address" 
                    value={email}
                    onChange={handle_email_change}
                    onBlur={handle_blur}
                    error={email_blurred && !validate_email(email)}
                    helperText={email_blurred && !validate_email(email) && 'Invalid email'}
                />
            </Box>
            <br/>
            <Button variant="contained" type="submit" sx={{width: '100%'}}> send reset link </Button> 
            </> 
        )
    }

    const onSubmit = async (form_data_object: FormDataEntryType): Promise<any> => {
        if (isFormValid()) {    
            setMessage(props => ({...props, form_error: ""}))

            // Call the Google reCAPTCHA API to get the response token
            const token = await window.grecaptcha.execute('6LeSCWUmAAAAAC6acEwKoQoAcbpg_Do1RIesd0zt', { action: 'submit' });

            // Here, you can send the token to the backend for verification if required.
            // Append the reCAPTCHA token to the FormData
            const updatedFormDataObject = {
                ...form_data_object,
                recaptcha_token: token,
            }
            const authentication_action = new AuthenticationAction()
            return authentication_action.resetPassword(RESET_PASSWORD_ENDPOINT, updatedFormDataObject)
            .then(() => {
                navigate('/account/reset-sent')
            })
        }
        else
            setMessage(props => ({...props, form_error: "Make sure all fields are filled in correclty"}))
	}

    return (
        <>
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', marginTop: 'auto'}}>
            <AuthTypography variant="h1" mt={'auto'}> Reset your Password </AuthTypography>
            <Typography sx={{maxWidth: '50%', margin: '1rem auto 2rem auto'}} textAlign='center'>Enter the email address associated with your iNastix account and we'll send a link to reset your password.</Typography>
            <Form onSubmitCallback={onSubmit} createFields={createFields}/>   
            <AuthFooter/>
        </Box>
        </>
    )
}

export default ForgotPassword