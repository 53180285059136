import { OrderStatus } from "./enum"


const orderStatusUIStrings: Record<OrderStatus, string> = {
    [OrderStatus.PENDING]: "Pending",
    [OrderStatus.PROCESSING]: "Processing", 
    [OrderStatus.SHIPPED]: "Shipped",      
    [OrderStatus.DELIVERED]: "Delivered", 
    [OrderStatus.CANCELLED]: "Cancelled"   
}


export {
    orderStatusUIStrings
}
