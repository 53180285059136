import { createContext } from 'react'

export type SetOpenSetRoylatiesDialog = React.Dispatch<React.SetStateAction<boolean>>
export type SetOpenSetSchoolFinancesDialog = React.Dispatch<React.SetStateAction<boolean>>
export type SetAuthorisedFranchiseIds = React.Dispatch<React.SetStateAction<string[]>>
export type SetAuthorisedSchoolIds = React.Dispatch<React.SetStateAction<string[]>>


interface IAuthorisedFranchiseIdsContext {
    authorised_franchise_ids: string[]
    setAuthorisedFranchiseIds: SetAuthorisedFranchiseIds
}

export const AuthorisedFranchiseIdsContext = createContext<IAuthorisedFranchiseIdsContext>({
    authorised_franchise_ids: [],
    setAuthorisedFranchiseIds: () => {}
})


interface IAuthorisedSchoolIdsContext {
    authorised_school_ids: string[]
    setAuthorisedSchoolIds: SetAuthorisedSchoolIds
}

export const AuthorisedSchoolIdsContext = createContext<IAuthorisedSchoolIdsContext>({
    authorised_school_ids: [],
    setAuthorisedSchoolIds: () => {}
})


interface IOpenSetRoylatiesDialogContext {
    open_set_royalties_dialog: boolean
    setOpenSetRoylatiesDialog: SetOpenSetRoylatiesDialog
}

export const OpenSetRoylatiesDialogContext = createContext<IOpenSetRoylatiesDialogContext>({
    open_set_royalties_dialog: false,
    setOpenSetRoylatiesDialog: () => {}
})


interface IOpenSetSchoolFinancesDialogContext {
    open_set_school_finances_dialog: boolean
    setOpenSetSchoolFinancesDialog: SetOpenSetSchoolFinancesDialog
}

export const OpenSetSchoolFinancesDialogContext = createContext<IOpenSetSchoolFinancesDialogContext>({
    open_set_school_finances_dialog: false,
    setOpenSetSchoolFinancesDialog: () => {}
})