import { BaseAction } from "@actions/Actions/actions"
import OpenAIChatHandler from "@adapters/handlers/OpenAIChatHandlers/openaiChatHandler"


class OpenAIChatActions extends BaseAction {
    handler: OpenAIChatHandler
    
    constructor() {
        super()
        this.handler = new OpenAIChatHandler()
    }

    sendUserQuery = (data: object): Promise<any> => {
        return this.handler.sendUserQuery(data)
    }
}

export default OpenAIChatActions