import {useEffect, useContext} from 'react'

import { useNavigate } from 'react-router-dom'

import AuthFetchWrapper from '@adapters/api/fetchWrappers/authFetchWrapper'
import { MessageContext } from '@contexts/MessageContext'
import { SetupStepContext } from '@contexts/SetupStepContext'
import { IsLoggedInContext } from '@contexts/IsLoggedInContext'
import { LocalStorageCompany } from '@utils/interfaces/interfaces'
import { NumOfRequestsContext } from '@contexts/NumOfRequestsContext'

import { handleCompanyLocalStorageInit, handleLogin, LoginData } from '../helper/helper'
import { logout } from '@adapters/helpers/logout'
import { LocalstorageUserExistsContext } from '@contexts/LocalstorageUserExistsContext'
import { LocalstorageCompanyExistsContext } from '@contexts/LocalstorageCompanyExistsContext'


interface XeroSignUpCallbackData extends LoginData {
    tenant?: LocalStorageCompany
}

const XeroSignUpCallback = () => {
    const navigation = useNavigate()

    const {setMessage} = useContext(MessageContext)
    const {setSetupStep} = useContext(SetupStepContext)
    const {setIsLoggedIn} = useContext(IsLoggedInContext)
    const {setNumOfRequests} = useContext(NumOfRequestsContext)
    const {setLocalstorageUserExists} = useContext(LocalstorageUserExistsContext)
    const {setLocalstorageCompanyExists} = useContext(LocalstorageCompanyExistsContext)

    useEffect(() => {
        setNumOfRequests(props => props + 1)
        
        const params = new URLSearchParams(window.location.search)
        const code = params.get('code')

        if (code) {
            const fetch_wrapper = new AuthFetchWrapper()
            fetch_wrapper.post('auth/xero/sign-up-callback', {code: code})
            .then((data: XeroSignUpCallbackData) => {
                localStorage.removeItem('connected_companies')  // we want to remove connected_companies so that the XeroConnectionSelect component (renders connected company dropdown) works as expected
                if (data.tenant) {
                    handleCompanyLocalStorageInit(data.tenant)
                    delete data.tenant
                }

                localStorage.setItem('signed_in_with_xero', "true")
                handleLogin(data, setSetupStep, setIsLoggedIn)
                navigation('/')
            })
            .catch(error => {
                if (error.message?.toLowerCase().includes("failed to fetch"))
                    logout(navigation, setIsLoggedIn, setLocalstorageUserExists, setLocalstorageCompanyExists)
                else {
                    // we have disconnected the users connection to our app in the backend if an error occurs
                    setMessage(props => ({...props, error: error.message}))
                    navigation('/signup')
                }
            })
            .finally(() => {
                setNumOfRequests(props => props - 1)
            })
        }
        else {
            setNumOfRequests(props => props - 1)
            navigation('/')
        }
    }, [navigation, setIsLoggedIn, setMessage, setNumOfRequests, setSetupStep, setLocalstorageUserExists, setLocalstorageCompanyExists])

    return (
        <>
        </>
    )
}

export default XeroSignUpCallback