import {useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next'

import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select, {SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'

import CompanyActions from '@actions/CompanyActions/companyActions'
import { CountryTerm } from '@pages/Setup/CompanySetup/FinanceAndTerms'
import ChooseCountry, { CountryOption } from '@components/forms/Fields/ChooseCountry'


interface CountryTermsProp {
    country: string
    terms: string
}

const SelectCountryTerms = (props: CountryTermsProp) => {
    const { t } = useTranslation('set_up')

    const {country, terms} = props
    const [country_terms, setCountryTerms] = useState<CountryTerm[] | null>(null)
    const [country_term, setCountryTerm] = useState('')
    const [selected_country, setSelectedCountry] = useState<CountryOption | null>(null)

    useEffect(() => {
        if (selected_country) {
            setCountryTerm('')
            const company_actions = new CompanyActions()
            company_actions.getCountryTerms(selected_country.label)
            .then((country_terms: CountryTerm[]) => {
                setCountryTerm(terms)
                setCountryTerms(country_terms)
            }) 
        }
    }, [selected_country, terms])

    const handleCountryTermChange = (event: SelectChangeEvent) => {
        setCountryTerm(event.target.value as string)
    }
    
    const setSelectedCountryCallback = (selected_country: CountryOption | null) => {
        setSelectedCountry(selected_country)
    }
    return (
        <>
        <ChooseCountry setSelectedCountryCallback={setSelectedCountryCallback} country_id={country}/>
        <br/>
        {country_terms && 
            <>
            <Box sx={{display: 'flex', gap: '1rem', maxWidth: '300px'}}>
                <FormControl fullWidth>
                    <InputLabel id="country-term-type-select-label"> {t('country_terms')} </InputLabel>
                    <Select
                        labelId="country-term-type-select-label"
                        id="country-term-type-select"
                        value={country_term}
                        name="country_term"
                        label={t('country_terms')}
                        onChange={handleCountryTermChange}
                        required
                    >
                        {country_terms.map((country_term: CountryTerm) => (
                            <MenuItem key={country_term.id} value={country_term.id}>{country_term.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
            <br/>
            </>
        }
        </>
    )
}

export default SelectCountryTerms