import GenericFetchWrapper from "@adapters/api/fetchWrappers/genericFetchWrapper"
import { BaseHandler } from "@adapters/handlers/Handlers/handlers"
import { SEND_ROYALTIES_ENDPOINT } from "@adapters/routes/endpoints"


class SendRoyaltiesHandler extends BaseHandler {
    endpoint: string
    fetch_wrapper: GenericFetchWrapper

    constructor () {
        super()
        this.endpoint = SEND_ROYALTIES_ENDPOINT 
        this.fetch_wrapper = new GenericFetchWrapper
    }

    sendRoyalties (data: object): Promise<any> {
        return this.fetch_wrapper.post(this.endpoint, data)
    }
}

export default SendRoyaltiesHandler