enum CertificateType {
	STANDARD_CERTIFICATE_CHECKED = 'standard_certificate_checked',
	PERCEPTUAL_MA_CERTIFICATE_CHECKED = 'perceptual_ma_certificate_checked',
}

enum CertificateNames {
	STANDARD_CERTIFICATE = 'standard_certificate',
	PERCEPTUAL_MA_CERTIFICATE = 'perceptual_ma_certificate',
}

enum BackendModelCertificateNames {
	PARTICIPATION = 'participation',
	MOVEMENT = 'movement'
}


export {
    CertificateType, CertificateNames, BackendModelCertificateNames
}