import { createContext } from 'react'


export type SetLocalstorageCompanyExists = React.Dispatch<React.SetStateAction<boolean>>

interface ILocalstorageCompanyExistsContext {
    local_storage_company_exists: boolean
    setLocalstorageCompanyExists: SetLocalstorageCompanyExists
}

export const LocalstorageCompanyExistsContext = createContext<ILocalstorageCompanyExistsContext>({
    local_storage_company_exists: false,
    setLocalstorageCompanyExists: () => {}
})