import { CRUDAction } from "@actions/Actions/actions"
import AddSchoolToChildrenHandler from "@adapters/handlers/CRUDHandlers/ChildHandlers/addSchoolToChildHandler"


class AddSchoolToChildActions extends CRUDAction {
    handler: AddSchoolToChildrenHandler

    constructor() {
        super()
        this.handler = new AddSchoolToChildrenHandler()
    }    
}

export default AddSchoolToChildActions