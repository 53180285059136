import GenericFetchWrapper from "@adapters/api/fetchWrappers/genericFetchWrapper"
import { BaseHandler } from "@adapters/handlers/Handlers/handlers"
import { OPENAI_CHAT_ENDPOINT } from "@adapters/routes/endpoints"


class OpenAIChatHandler extends BaseHandler {
    endpoint: string
    fetch_wrapper: GenericFetchWrapper

    constructor () {
        super()
        this.fetch_wrapper = new GenericFetchWrapper()
        this.endpoint = OPENAI_CHAT_ENDPOINT
    }

    sendUserQuery (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.endpoint)
        return this.fetch_wrapper.post(parsed_endpoint, data)
    }
}

export default OpenAIChatHandler