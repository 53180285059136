import React, { useContext, useState } from 'react'
import { Typography } from '@mui/material'
import { DialogButton, DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { DialogForIdsProps } from '@components/dialogs/interfaces/interface'
import { DialogComponentProps } from '@components/tables/Table/Table'
import ChildActions from '@actions/CRUDActions/ChildActions/childActions'
import { Child } from '@utils/interfaces/interfaces'
import { TableActionButon } from '@styles/Buttons'
import { MessageContext } from '@contexts/MessageContext'
import { useTranslation } from 'react-i18next'
import UnarchiveIcon from '@mui/icons-material/Unarchive'

const UnarchiveChildDialog = (props: DialogForIdsProps) => {
    const { ids, state_manager, onClose, open } = props
    const { setMessage } = useContext(MessageContext)
    const { t } = useTranslation('tables')

    const handleUnarchive = async () => {
        
        const idsToUnarchive = ids.map(id => parseInt(id, 10))

        
        const children_to_unarchive = state_manager.data.filter((child: Child) =>
            idsToUnarchive.includes(child.id)
        )

        if (children_to_unarchive.length === 0) {
            console.error("No children found for IDs:", idsToUnarchive)
            setMessage((props) => ({ ...props, error: t("child_not_found") }))
            return
        }

        handleClose() 

        const child_actions = new ChildActions()
        Promise.all(
            children_to_unarchive.map((child) =>
                child_actions.update({ ...child, archive: false }, child.id.toString())
            )
        )
            .then(() => {
                
                const updated_children = state_manager.data.map((child) =>
                    idsToUnarchive.includes(child.id) ? { ...child, archive: false } : child
                )
                state_manager.setData(updated_children)
                setMessage((props) => ({ ...props, success: t("successfully_unarchived") }))
            })
            .catch((error: any) => {
                console.log(error)
                setMessage((props) => ({ ...props, error: t("failed_to_unarchive_some_children") }))
            })
    }

    const handleClose = () => {
        onClose()
    }

    return (
        <INastixDialog onClose={handleClose} open={open}>
            <INastixDialogTitle>{t("confirm_unarchive")}</INastixDialogTitle>
            <DialogDivider />
            <Typography>
                {t('are_you_sure_you_want_to_unarchive', { count: ids.length })} {t(ids.length === 1 ? 'item' : 'items')}?
            </Typography>
            <DialogButton variant='contained' onClick={handleUnarchive}>
                {t('unarchive')}
            </DialogButton>
        </INastixDialog>
    )
}

const UnarchiveChildDialogHandler = (props: DialogComponentProps) => {
    const { disabled, ids, state_manager } = props
    const [open, setOpen] = useState(false)
    const { t } = useTranslation('tables')

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <>
            <TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<UnarchiveIcon />}>
                <Typography>{t('Unarchive')}</Typography>
            </TableActionButon>
            <UnarchiveChildDialog
                ids={ids}
                state_manager={state_manager}
                open={open}
                onClose={handleClose}
            />
        </>
    )
}

export default UnarchiveChildDialogHandler

