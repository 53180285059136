import { CRUDAction } from "@actions/Actions/actions"
import UserTableHandler from "@adapters/handlers/CRUDHandlers/UserHandlers/userTableHandler"


class UserTableActions extends CRUDAction {
    handler: UserTableHandler

    constructor() {
        super()
        this.handler = new UserTableHandler()
    }

    get(
        page?: number,
        page_size?: number,
        filter = {},
        group: string = ''
    ): Promise<any> {
        return super.get(page, page_size, filter, group);
    }
}

export default UserTableActions