import { CRUDAction } from "@actions/Actions/actions"
import SchoolConnectAFranchiseSetupHandler from "@adapters/handlers/CRUDHandlers/SchoolHandlers/schoolConnectAFranchiseHandler"
import SchoolFinanceSetupHandler from "@adapters/handlers/CRUDHandlers/SchoolHandlers/schoolFinanceSetupHandlers"
import SchoolHandler from "@adapters/handlers/CRUDHandlers/SchoolHandlers/schoolHandlers"


class SchoolActions extends CRUDAction {
    handler: SchoolHandler
    connect_a_franchise_setup_handler: SchoolConnectAFranchiseSetupHandler
    finance_setup_handler: SchoolFinanceSetupHandler

    constructor() {
        super()
        this.handler = new SchoolHandler()
        this.connect_a_franchise_setup_handler = new SchoolConnectAFranchiseSetupHandler()
        this.finance_setup_handler = new SchoolFinanceSetupHandler()
    }

    setConnectAFranchiseSetup = (data: object): Promise<any> =>  {
        return this.connect_a_franchise_setup_handler.connectAFranchisesetup(data)
    }

    setFinanceSetup = (data: object): Promise<any> =>  {
        return this.finance_setup_handler.financeSetup(data)
    }
}

export default SchoolActions