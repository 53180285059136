import {useState, useEffect} from 'react'

import UserActions from '@actions/CRUDActions/UserActions/userActions'
import { Child, Instructor, LocalStorageCompany, LocalStorageUser } from '@utils/interfaces/interfaces'
import StateManager from '@actions/StateManager/stateManager'
import { companyIsInGroup, isInGroup } from '@utils/utils/util'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { CompanyType, UserSetupStep, UserType } from '@utils/enums/enums'
import FranchiseeActions from '@actions/CRUDActions/FranchiseeActions/franchiseeActions'
import { UserAuthorisedMap } from '@utils/maps/maps'

import log from 'loglevel'
import InstructorActions from '@actions/CRUDActions/InstructorActions/instructorActions'
import { InastixNotification } from './interfaces'
import ChildActions from '@actions/CRUDActions/ChildActions/childActions'


interface UserInfo {
	username: string
	email: string
	role: string
	request_franchise_name?: string
	request_franchise_status?: string
    request_school_name?: string
	request_school_status?: string
    monkeynastix_authorised?: string
    date_of_qualification?: string
    south_african_gl_course?: string
    police_clearance_certificate?: string
    employment_contract?: string
}

interface MemeberInfo {
    child_name: string
    parent_name: string
    parent_email: string
    is_sponsored: "Yes" | undefined
    reason_for_sponsor: string
    sponsored_amount: string | undefined
}


function useNotificationInfo(ids: string[], state_manager: StateManager) {
	const [user_info, setUserInfo] = useState<UserInfo | MemeberInfo | null>(null)
	const [table_caption, setTableCaption] = useState("")

  	useEffect(() => {
        if (ids.length > 0) {
            const makeNotificationInfo = async () => {
                const company = getLocalStorageCompany()

                const notification_id = ids[0]
                const notification: InastixNotification = state_manager.data.find((notification) => notification.id === notification_id)
                if (notification) {
                    let result
                        
                    if (notification.sent_from_child)
                        result = await createMemberInfo(notification, company)
                    else if (notification.sent_from_user)
                        result = await createUserInfo(notification, company)
                    else {
                        const message = `sent_from_child and sent_from_user both cannot be null at the same time`
                        log.error(message)
                        throw new Error("Something went wrong. Please try again")
                    }

                    const {user_info, table_caption} = result
                    setUserInfo(user_info)
                    setTableCaption(table_caption)
                }
            }
            makeNotificationInfo()
        }
	}, [ids, state_manager.data])
	return {user_info, table_caption}
}


const createMemberInfo = async (notification: InastixNotification, company: LocalStorageCompany) => {
    const child_actions = new ChildActions()
    const sent_from_child_id = notification.sent_from_child as number

    const child: Child = await child_actions.getById(sent_from_child_id.toString())
    let member_info: MemeberInfo = {
        child_name: child.username,
        parent_email: child.parent_contact_email,
        parent_name: child.parent_contact_name,
        is_sponsored: child.is_sponsored ? "Yes": undefined,
        reason_for_sponsor: child.reason_for_sponsor,
        sponsored_amount: child.is_sponsored ? child.sponsored_amount: undefined
    }

    return {user_info: member_info as MemeberInfo, table_caption: ""}
}

const createUserInfo = async (notification: InastixNotification, company: LocalStorageCompany) => {
    const user_actions = new UserActions()

    const sent_from_user_id = notification.sent_from_user as number
    let table_caption = ""

    const user: LocalStorageUser =  await user_actions.getById(sent_from_user_id.toString())
    let user_info: UserInfo = {
        username: user.username,
        email: user.email,
        role: user.connected_group,
    }
    if (isInGroup(user, company, UserType.INSTRUCTOR) || isInGroup(user, company, UserType.ASSISTANT) || user.is_instructor) {

        const instructor_actions = new InstructorActions()
        const instructors: Instructor[] = await instructor_actions.get(undefined, undefined, {user__email: user.email})
        const instructor = instructors[0]  // there will be only be one instructor with the user email. user email is unique
        console.log(instructor)
        let additional_instructor_info = {
            date_of_qualification: instructor.date_of_qualification,
            south_african_gl_course: instructor.south_african_gl_course,
            police_clearance_certificate: instructor.police_clearance_certificate,
            copy_of_id_photo: instructor.copy_of_id_photo,
            employment_contract: instructor.employment_contract
        }
        user_info = {...user_info, ...additional_instructor_info}
    }
    if (companyIsInGroup(company, CompanyType.FRANCHISOR)) {
        const is_user_past_choose_company_setup = user.setup_step > UserSetupStep.CHOOSE_COMPANY
        const is_user_completed =  user.setup_step === UserSetupStep.COMPLETED
        if (is_user_past_choose_company_setup || is_user_completed) {

            const franchisee_actions = new FranchiseeActions()
            const requested_franchise_or_school: LocalStorageCompany = await franchisee_actions.getById(user.company_uuid)
            if (companyIsInGroup(requested_franchise_or_school, [CompanyType.FRANCHISEE, CompanyType.MASTER_FRANCHISE]))
                user_info.request_franchise_name = requested_franchise_or_school.tenant_name
            else if (companyIsInGroup(requested_franchise_or_school, CompanyType.SCHOOL))
                user_info.request_school_name = requested_franchise_or_school.tenant_name

            // we don't have to show the franchisor that the franchisor status of the company they authorizing 
            if (!companyIsInGroup(requested_franchise_or_school, CompanyType.FRANCHISOR)) {
                if (companyIsInGroup(requested_franchise_or_school, [CompanyType.FRANCHISEE, CompanyType.MASTER_FRANCHISE]))
                    user_info.request_franchise_status = UserAuthorisedMap[user.master_admin_authorized.toString() as keyof typeof UserAuthorisedMap]
                else if (companyIsInGroup(requested_franchise_or_school, CompanyType.SCHOOL))
                    user_info.request_school_status = UserAuthorisedMap[user.master_admin_authorized.toString() as keyof typeof UserAuthorisedMap]
            }
        }
        else
            table_caption = "This user is not yet fully setup"
    }
    else if (companyIsInGroup(company, [CompanyType.MASTER_FRANCHISE, CompanyType.FRANCHISEE]))
        user_info.monkeynastix_authorised = UserAuthorisedMap[user.monkeynastix_authorized.toString() as keyof typeof UserAuthorisedMap]
    else {
        const message = "Company does not the have the permissions to make authorisations"
        log.error(message)
        throw new Error(message)
    }
    return {user_info: user_info, table_caption: table_caption}
}

export default useNotificationInfo