import { styled } from '@mui/material/styles'
import Badge, {BadgeProps} from '@mui/material/Badge'


export const NavItems = styled('div')(() => ({
	display: 'flex',
}))


export const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
	'& .MuiBadge-badge': {
		right: 12,
		top: 13,
	},
}))