import GenericFetchWrapper from "@adapters/api/fetchWrappers/genericFetchWrapper"
import { BaseHandler } from "@adapters/handlers/Handlers/handlers"
import { MINASTIX_MEMBER_POOL_ENDPOINT } from "@adapters/routes/endpoints"


class FranchiseMemberPoolHandler extends BaseHandler {
    endpoint: string
    fetch_wrapper: GenericFetchWrapper

    constructor () {
        super()
        this.fetch_wrapper = new GenericFetchWrapper()
        this.endpoint = MINASTIX_MEMBER_POOL_ENDPOINT
    }

    thisFranchisesChild (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.endpoint)
        return this.fetch_wrapper.post(parsed_endpoint, data)
    }

    sendToMemberPool (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.endpoint)
        const added_endpoint = `${parsed_endpoint}add-to-member-pool/`
        return this.fetch_wrapper.post(added_endpoint, data)
    }
}

export default FranchiseMemberPoolHandler