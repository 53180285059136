import Cart from "./Cart"
import CheckoutDetails from "./CheckoutDetails"
import OrderComplete from "./OrderComplete"


const GetCheckoutStepsComponent = (active_step: number) => {
    switch (active_step) {
        case 0:
            return <Cart/>
        case 1:
            return <CheckoutDetails/>
        case 2:
            return <OrderComplete/>
        default:
            return 'Unknown stepIndex'
    }
}

export default GetCheckoutStepsComponent