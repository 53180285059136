import React from 'react'

import XeroLogo from '@images/xero/logo/logo.png'
import { xero_config as config } from 'src/config'

import Button from '@mui/material/Button'


interface XeroSignInProps {
    Icon?: React.ReactElement
    logo_image?: string
    label_text?: string
    logo_width?: string
    styles?: React.CSSProperties
}

const CLIENT_ID = process.env.REACT_APP_INASTIX_CLIENT_ID

// Xero sign in button docs -> https://developer.xero.com/documentation/xero-app-store/app-partner-guides/sign-in/#1-create-your-oauth20-app
const XeroSignIn = ({Icon, logo_image=XeroLogo, label_text = "Sign In with Xero", logo_width="35px", styles}: XeroSignInProps) => {

    const handleSignIn = () => {
        window.location.href = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${config.xero_sign_in_callback_url}&scope=${config.xero_scopes}`
    }
    
    return (
        <>
        <Button onClick={handleSignIn} sx={{border: '1px solid lightgrey', padding: "0.2rem 1rem", textTransform: 'none', gap: 1, borderRadius: 2, color: 'darkslategray', ...styles}}> 
            {Icon ? Icon : <img src={logo_image} alt="A blue Xero logo" width={logo_width}/>}
            {label_text} 
        </Button>
        </>
    )
}

export default XeroSignIn
