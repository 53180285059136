import {useState, useContext} from 'react'

import Typography from '@mui/material/Typography'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox'

import { DialogButton, DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { MessageContext } from '@contexts/MessageContext'
import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import {DialogProps} from '@utils/dialogProps/DialogProps'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { useTranslation } from 'react-i18next'
import FranchisorRoyaltyActions from '@actions/Finance/FranchisorInvoiceActions/franchisorInvoiceActions'
import RoyaltyInvoiceActions from '@actions/Finance/RoyaltyInvoiceActions/royaltyInvoiceActions'


const MarkAsPaidDialog = (props: DialogProps) => {
	const {ids, state_manager, onClose, open} = props

	const { t } = useTranslation('overview')

	const company = getLocalStorageCompany()

	const {setMessage} = useContext(MessageContext)

	const handleSend = () => {
		const invoice_objects = state_manager.data.filter(obj => ids.includes(obj.id))
		let franchisor_royalty_invoice_ids = ''
		let royalty_invoice_ids = ''
		console.log(invoice_objects)
		for (let i = 0; i < invoice_objects.length; i++) {
			if (invoice_objects[i].invoice_type === 'Royalties from franchisees') {
				if (franchisor_royalty_invoice_ids.length > 0) {
					franchisor_royalty_invoice_ids = `${franchisor_royalty_invoice_ids},${invoice_objects[i].id}`
				} else {
					franchisor_royalty_invoice_ids = invoice_objects[i].id.toString()
				}
			} else {
				if (royalty_invoice_ids.length > 0) {
					royalty_invoice_ids = `${royalty_invoice_ids},${invoice_objects[i].id}`
				} else {
					royalty_invoice_ids = invoice_objects[i].id.toString()
				}
			}
		}
		console.log(franchisor_royalty_invoice_ids)
		console.log(franchisor_royalty_invoice_ids.length)
		console.log(royalty_invoice_ids)
		console.log(royalty_invoice_ids.length)
		if (royalty_invoice_ids.length > 0) {
			console.log('Deleting royalty invoices')
			const royalty_invoice_actions = new RoyaltyInvoiceActions()
			royalty_invoice_actions.delete(royalty_invoice_ids)
			.then(data => {
				console.log(data)
				setMessage(props => ({...props, success: "Invoices marked as paid"}))
			})
			.catch(error => {
				setMessage(props => ({...props, error: error.message}))
			})
			.finally(async () => {
				return state_manager.setGetAction(undefined, undefined, {'approved': 'True'})
				.then(() => {
					handleClose()
				})
			})
		}
		if (franchisor_royalty_invoice_ids.length > 0) {
			console.log('Deleting franchisor royalty invoices')
			const franchisor_royalty_invoice_actions = new FranchisorRoyaltyActions()
			franchisor_royalty_invoice_actions.delete(franchisor_royalty_invoice_ids)
			.then(data => {
				console.log(data)
				setMessage(props => ({...props, success: "Invoices marked as paid"}))
			})
			.catch(error => {
				setMessage(props => ({...props, error: error.message}))
			})
			.finally(async () => {
				return state_manager.setGetAction(undefined, undefined, {'approved': 'True'})
				.then(() => {
					handleClose()
				})
			})
		}
	}

	const handleClose = () => {
		onClose()
	}

	return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> {t('confirm')} </INastixDialogTitle>
			<DialogDivider/>
			<Typography>
			{t('are_you_sure_you_want_to_mark')} {ids.length} {ids.length === 1 ? <span>{t('item')}</span> : <span>{t('items')}</span>} {company.is_xero_company && <span> {t('as_paid')}</span>}?
			</Typography>
			<DialogButton variant='contained' onClick={handleSend}> {t('mark_as_paid')} </DialogButton>
		</INastixDialog>
	)
}


const MarkAsPaidDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

	const { t } = useTranslation('overview')

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<ForwardToInboxIcon/>}>
			<Typography> {t('mark_as_paid')} </Typography>
		</TableActionButon>
		<MarkAsPaidDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>
		</>
	)
}

export default MarkAsPaidDialogHandler