import {useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next'

import log from 'loglevel'

import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import FormControl from '@mui/material/FormControl'

import { LocalStorageCompany } from '@utils/interfaces/interfaces'
import CompanyActions from '@actions/CompanyActions/companyActions'
import { companyIsInGroup, isInGroup } from '@utils/utils/util'
import { CompanySetupStep, CompanyType, UserType } from '@utils/enums/enums'
import FranchiseeActions from '@actions/CRUDActions/FranchiseeActions/franchiseeActions'
import { getLocalStorageUser } from '@utils/localStorage/user'

import { CompanyLabel, SetCompaniesCompanyLabel } from '.'
import { getLocalStorageCompany } from '@utils/localStorage/company'


const fetchCompanies = async (setCompanies: SetCompaniesCompanyLabel) => {
    const user = getLocalStorageUser()

    const company_actions = new CompanyActions()
    company_actions.getAllCompanies()
    .then((data: LocalStorageCompany[]) => {
        const parsed_data = data
        .filter(company => !(
            company.tenant_name.includes("Demo Company") || 
            company.tenant_name.includes("Demo School") || 
            companyIsInGroup(company, CompanyType.FRANCHISOR) || 
            user.groups.some(group => group.tenant === company.company_uuid) ||  // remove the franchises that the user is already connected to 
            company.setup_step !== CompanySetupStep.COMPLETED ||
            company.is_demo_company || 
            company.is_test_company
        ))
        .map(company => ({
            label: company.tenant_name,
            company_uuid: company.company_uuid
        }))
        setCompanies(parsed_data)
    })
    .catch(error => {
        log.error(error.message)
    })
}

const fetchSchools = async (setSchools: SetCompaniesCompanyLabel) => {
    const user = getLocalStorageUser()

    const franchisee_actions = new FranchiseeActions()
    franchisee_actions.get(undefined, undefined, {group: CompanyType.SCHOOL})
    .then((data: LocalStorageCompany[]) => {
        const parsed_data = data
        .filter(company => !(
            company.tenant_name.includes("Demo Company") || 
            company.tenant_name.includes("Demo School") || 
            companyIsInGroup(company, CompanyType.FRANCHISOR) || 
            user.groups.some(group => group.tenant === company.company_uuid) ||  // remove the schools that the user is already connected to 
            company.setup_step !== CompanySetupStep.COMPLETED ||
            company.is_demo_company || 
            company.is_test_company
        ))        
        .map(company => ({
            label: company.tenant_name,
            company_uuid: company.company_uuid
        }))
        setSchools(parsed_data)
    })
    .catch(error => {
        log.error(error.message)
    })
}

interface SelectAnExistingCompanyProps {
    selectedCompanyCallback?: (selected_company: CompanyLabel | null) => void
}

const SelectAnExistingCompany = (props: SelectAnExistingCompanyProps) => {
    const {selectedCompanyCallback} = props

    const user = getLocalStorageUser()
	const conected_company = getLocalStorageCompany()

    const { t } = useTranslation('set_up')

    const [company, setCompany] = useState<CompanyLabel | null>(null)
    const [companies, setCompanies] = useState<Array<CompanyLabel>>([])

    const is_school_operator = isInGroup(user, conected_company, UserType.SCHOOL_OPERATOR)
    const is_signed_in_with_xero = JSON.parse(localStorage.getItem('signed_in_with_xero') || 'null')

    useEffect(() => {
        const fetchData = is_school_operator ? fetchSchools : fetchCompanies
        fetchData(setCompanies)
    }, [is_signed_in_with_xero, is_school_operator])

    const handleChange = (event: any, new_value: CompanyLabel | null) => {
        setCompany(new_value)
        if (selectedCompanyCallback)
            selectedCompanyCallback(new_value)
    }

    return (
        <FormControl fullWidth>
            <Autocomplete
                disablePortal
                id="combo-box-companies"
                options={companies}
                value={company}
                onChange={handleChange}
                renderInput={(params) => 
                    <TextField 
                        {...params}
                        label={is_school_operator ? t('schools') : t('companies')}
                        placeholder={is_school_operator ? String(t('enter_school_name')) : String(t('enter_company_name'))}
                    />
                }
            />
        </FormControl>
    )
}

export default SelectAnExistingCompany