import { useState } from "react"

import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

import changeLanguage from '@utils/language/change'
import languageOptions, { LanguageConf } from "@utils/enums/enums"
import { useTranslation } from "react-i18next"

interface MenuItem {
    label: string
    value: string
}

const LanguageSelect = () => {
    const { i18n } = useTranslation('settings')

    const [language, setLanguage] = useState<object | null>(languageOptions[0])
    const { t } = useTranslation('set_up')

    const handleChange = (event: any, new_language: object | null) => {
        setLanguage(new_language)
        console.log(event)
        console.log(event.target)
        console.log(event.target.name)
        console.log(event.target.value)
        console.log(new_language)
        if (new_language !== null) {
            const menu_iteam = new_language as MenuItem
            const lng = menu_iteam.label as LanguageConf
            changeLanguage(i18n, lng)
        }
    }

    return (
        <Autocomplete
            value={language}
            onChange={handleChange}
            disablePortal
            id="combo-box-language"
            options={languageOptions}
            sx={{ width: '100%' }}
            renderInput={(params) => <TextField {...params} label={t('language')} name="language" />}
        />
    )
}

export default LanguageSelect