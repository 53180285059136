import React, {useState, useEffect} from 'react'

import FormControl from "@mui/material/FormControl"
import FormHelperText from "@mui/material/FormHelperText"
import InputAdornment from "@mui/material/InputAdornment"
import OutlinedInput from "@mui/material/OutlinedInput"
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { SchoolTypeEmail } from "@utils/enums/enums"


interface MonkeynastixEmailFieldProps {
    email_address: string
    selected_school_type: SchoolTypeEmail
    handleSchoolTypeEmailChange: (e: SelectChangeEvent) => void
}

const MonkeynastixEmailField = (props: MonkeynastixEmailFieldProps) => {
    const {email_address, selected_school_type, handleSchoolTypeEmailChange} = props

    const parseEmailAddress = () => {
        return `${email_address.replace(/\s/g, '').toLowerCase()}`
    }

    return (
        <FormControl sx={{width: 'auto', '@media (max-width: 38ch)': {width: '38ch'}, backgroundColor: '#f2f3f4' }} variant="outlined">
            <OutlinedInput
                id="monkeynastix-email"
                endAdornment={
                    <InputAdornment position="end">
                        <Select
                            labelId="demo-simple-select-label-school-type"
                            id="demo-simple-select-school-type"
                            value={selected_school_type}
                            onChange={handleSchoolTypeEmailChange}
                        >
                            <MenuItem value={SchoolTypeEmail.BABYNASTIX}>{SchoolTypeEmail.BABYNASTIX}</MenuItem>
                            <MenuItem value={SchoolTypeEmail.MONKEYNASTIX}>{SchoolTypeEmail.MONKEYNASTIX}</MenuItem>
                            <MenuItem value={SchoolTypeEmail.SUPERNASTIX}>{SchoolTypeEmail.SUPERNASTIX}</MenuItem>
                        </Select>
                    </InputAdornment>
                }
                aria-describedby="monkeynastix-email-helper-text"
                inputProps={{
                    'aria-label': 'weight',
                }}
                value={parseEmailAddress()}
                readOnly  
                placeholder="Address of city"
                name="monkeynastix_email"
                sx={{backgroundColor: 'white', padding: 0}}
            />
            <FormHelperText sx={{fontSize: '0.6rem'}} id="monkeynastix-email-helper-text">
                All emails sent to this inbox will be forwarded to your franchise contact email that you specified above
            </FormHelperText>
        </FormControl>
    )
}

export default MonkeynastixEmailField