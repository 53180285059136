import { createContext } from 'react'


export type SetNumOfChildrenNeedingSchool = React.Dispatch<React.SetStateAction<number>>

interface INumOfChildrenNeedingSchoolContext {
    num_of_children_needing_school: number
    setNumOfChildrenNeedingSchool: SetNumOfChildrenNeedingSchool
}

export const NumOfChildrenNeedingSchoolContext = createContext<INumOfChildrenNeedingSchoolContext>({
    num_of_children_needing_school: 0,
    setNumOfChildrenNeedingSchool: () => {}
})