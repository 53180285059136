import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { FZoneProduct } from '.'


const ProductOnSale = () => {
    return (
        <Box 
            sx={{
                position: 'absolute',
                padding: '1rem',
                borderRadius: '100%',
                backgroundColor: 'orange',
                color: 'white',
                width: '1rem',
                height: '1rem',
                margin: '0.5rem',
                display: 'flex', 
                justifyContent: 'center'
            }}
        >
            <Typography 
                sx={{
                    lineHeight: '1rem',
                    fontSize: '0.85rem'
                }}
            > 
                Sale! 
            </Typography>
        </Box>
    )
}


interface ShowProductPriceProps {
    product: FZoneProduct
}

const ShowProductPrice = (props: ShowProductPriceProps) => {
    const {product} = props

    return (
        <>
        {product.on_sale ? 
            <>
            <span> R{product.on_sale_price} </span>
            <span style={{textDecoration: 'line-through'}}> R{product.price} </span>
            </>
            :
            <span>R{product.price}</span>
        }
        </>
    )
}


export {ProductOnSale, ShowProductPrice}