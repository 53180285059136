import { CRUDAction } from "@actions/Actions/actions"

import CompanyNotificationHandler from "@adapters/handlers/CRUDHandlers/CompanyNotificationHandlers/companyNotificationHandlers"
import CompanyNotificationCountHandler from "@adapters/handlers/CRUDHandlers/CompanyNotificationHandlers/companyNotificationCountHandler"
import CompanyAuthorizeNotificationHandler from "@adapters/handlers/CRUDHandlers/CompanyNotificationHandlers/companyAuthorizeNotificationHandler"
import CompanyUnauthorizeNotificationHandler from "@adapters/handlers/CRUDHandlers/CompanyNotificationHandlers/companyUnauthorizeNotificationHandler"


class CompanyNotificationActions extends CRUDAction {
    handler: CompanyNotificationHandler
    count_handler: CompanyNotificationCountHandler
    authorize_notification_handler: CompanyAuthorizeNotificationHandler
    unauthorize_notification_handler: CompanyUnauthorizeNotificationHandler

    constructor() {
        super()
        this.handler = new CompanyNotificationHandler()
        this.count_handler = new CompanyNotificationCountHandler()
        this.authorize_notification_handler = new CompanyAuthorizeNotificationHandler()
        this.unauthorize_notification_handler = new CompanyUnauthorizeNotificationHandler()
    }

    authorize_companies (notification_ids: Array<string>): Promise<any> {
        return this.authorize_notification_handler.post(notification_ids)
    }

    unauthorize_companies (notification_ids: Array<string>): Promise<any> {
        return this.unauthorize_notification_handler.post(notification_ids)
    }

    count (): Promise<any> {
        return this.count_handler.get()
    }
}


export default CompanyNotificationActions