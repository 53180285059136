import {useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import dayjs from 'dayjs'

import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Box from '@mui/material/Box'

import Form from "@components/forms/Form"
import { FormDataEntryType } from "@components/forms/Form"
import { CompanyType, UserType } from '@utils/enums/enums'
import { getLocalStorageCompany, updateLocalStorageCompany } from '@utils/localStorage/company'
import { CompanySetupStepContext } from '@contexts/CompanySetupStepContext'
import CompanyActions from '@actions/CompanyActions/companyActions'
import { SetupBox, SetupHeadingTypography } from '@styles/Setup'
import { SubmitButon } from '@styles/Buttons'
import { CompanyMap } from '@utils/maps/maps'
import { isInGroup } from '@utils/utils/util'
import { getLocalStorageUser } from '@utils/localStorage/user'
import { MessageContext } from '@contexts/MessageContext'
import ChooseCountry from '@components/forms/Fields/ChooseCountry'
import DatePickerNastix from '@components/pickers/DatePickerNastix'

import log from 'loglevel'

import ChooseMonkeynastixType, { MonkeynastixType } from './ChooseMonkeynastixType'


const ChooseCompanyType = () => {
    const user = getLocalStorageUser()
	const company = getLocalStorageCompany()

    const { t } = useTranslation('choose_company_type')

    const {setCompanySetupStep} = useContext(CompanySetupStepContext)
    const {setMessage} = useContext(MessageContext)

    const master_franchise_info = t('the_country_chosen_should_be_the_country_in_which_your_master_franchise_is_listed')

    const [company_type, setCompanyType] = useState<CompanyType>(CompanyType.DEFAULT)
    const [monkeynastix_type, setMonkeynastixType] = useState<MonkeynastixType>({
        is_monkeynastix: false,
        is_babynastix: false,
        is_supernastix: false
    })

    const chooseMonkeynastixTypeCallback = (data: MonkeynastixType) => {
        setMonkeynastixType(data)
    }

    const createFields = () => {
        return (
            <>
            <SetupBox>
                <SetupHeadingTypography variant='h2'> {t('choose_company_type')} </SetupHeadingTypography>
                <br/>
                <Box display='flex' gap='1rem' sx={{flexWrap: 'wrap'}}>
                    <Box sx={{textAlign: 'center', paddingTop: '0.5rem'}}>
                        <FormControl sx={{minWidth: '150px'}}>
                            <InputLabel id="company-type-select-label">{t('company_type')}</InputLabel>
                            <Select
                                labelId="company-type-select-label"
                                id="company-type-select"
                                value={company_type}
                                label={t('company_type')}
                                name="company_type"
                                onChange={handleChange}
                                required
                            >
                                {Object.values(CompanyType)
                                    .filter(company_type => company_type !== CompanyType.SCHOOL)
                                    .filter((company_type) => company_type !== CompanyType.FRANCHISOR)
                                    .filter((company_type) => 
                                        (company_type === CompanyType.FRANCHISEE && isInGroup(user, company, UserType.FRANCHISEE_OPERATOR)) ||
                                        (company_type === CompanyType.FRANCHISEE && isInGroup(user, company, UserType.FRANCHISEE_ADMIN)) ||
                                        (company_type === CompanyType.MASTER_FRANCHISE && isInGroup(user, company, UserType.MASTER_FRANCHISE_OPERATOR))
                                    )
                                    .map((company_type) => (
                                        <MenuItem key={company_type} value={company_type} sx={{ display: 'flex', alignItems: 'center' }}>
                                            {company_type === CompanyType.DEFAULT ? (
                                                <em>None</em>
                                            ) : (
                                                <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                                                <span style={{ textTransform: 'capitalize' }}>{CompanyMap[company_type]}</span>
                                                </div>
                                            )}
                                        </MenuItem>
                                    )
                                )}
                            </Select>
                            {company_type === CompanyType.FRANCHISEE && <> <br/><br/><ChooseCountry helper_text=''/></>}
                        </FormControl>
                        {company_type === CompanyType.MASTER_FRANCHISE && <> <br/><br/><ChooseCountry helper_text={master_franchise_info}/> </>}
                    </Box>
                    <Box >
                        <DatePickerNastix max_date={dayjs()} label={"Franchise Start Date"} name={"franchise_since_date"} helper_text={master_franchise_info}/>
                        <br/>
                        <ChooseMonkeynastixType chooseMonkeynastixTypeCallback={chooseMonkeynastixTypeCallback}/>
                    </Box>
                </Box>
                <FormControl sx={{minWidth: '200px'}}>
                    <SubmitButon variant='contained' type="submit"> {t('submit')} </SubmitButon>
                </FormControl>
            </SetupBox>
            </>
        )
    }

    const handleChange = (event: SelectChangeEvent) => {
        setCompanyType(event.target.value as CompanyType)
    }

    const hasAtLeastOneTrueProperty = (monkeynastix_type: MonkeynastixType): boolean => {
        return Object.values(monkeynastix_type).some(value => value === true)
    }

    const onSubmit = async (form_data_object: FormDataEntryType): Promise<any> => {
        if (!hasAtLeastOneTrueProperty(monkeynastix_type)) {
            setMessage(props => ({...props, error: t('select_at_least_one_monkeynastix_type_before_continuing')}))
            return
        }

        const data = {
            ...form_data_object,
            ...monkeynastix_type
        }
        const company_action = new CompanyActions()
        return company_action.chooseCompanySetup(data)
		.then((group) => {
            const company = getLocalStorageCompany()
            updateLocalStorageCompany('setup_step', company.setup_step + 1)
            if (form_data_object['master_franchisee'])
                updateLocalStorageCompany('master_franchisee', form_data_object['master_franchisee'])
            updateLocalStorageCompany('country', form_data_object['country'])
            updateLocalStorageCompany('groups', [group])
            setCompanySetupStep(prop => prop + 1)		
        })
        .catch(error => {
            log.error(error.message)
            setMessage(props => ({...props, error: t('you_have_selected_a_country_that_is_already_taken')}))
        })
	}

    return (
		<Form onSubmitCallback={onSubmit} createFields={createFields}/>
    )
}

export default ChooseCompanyType