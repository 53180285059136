import {useEffect, useState, useContext} from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import { Divider, Typography } from '@mui/material'

import FZoneEcommerceProductActions from '@actions/CRUDActions/FZoneEcommerceAtions/fzoneEcommerceProductActions'
import { PageHeadingTypography } from '@styles/Typography'

import { FZoneProduct } from '../Ecommerce'
import { CartContext } from '../contexts'
import { CheckoutStepContext } from './contexts'
import FZoneEcommerceCartItemActions from '@actions/CRUDActions/FZoneEcommerceAtions/fzoneEcommerceCartItemActions'


export interface FZoneCart {
    id: number
    description: string
    products: number[]    
    cart_items: FZoneCartItem[]
}

export interface FZoneCartItem {
    id: number
    product: number
    cart: number
    quantity: number
}

export const updateCartTotal = (products: FZoneProduct[], cart: FZoneCart | null) => {
    if (!products.length || !cart) {
        return 0
    }

    let new_cart_total = 0

    for (const cart_item of cart.cart_items) {
        const product = products.find(product => product.id === cart_item.product)

        if (!product) {
            continue
        }

        const item_total = Number(product.price) * cart_item.quantity
        new_cart_total += item_total
    }

    const formatted_total = Number(new_cart_total.toFixed(2))
    return formatted_total
}


const Cart = () => {
    const {cart, setCart} = useContext(CartContext)
    const {setCheckoutStep} = useContext(CheckoutStepContext)
    
    const [products, setProducts] = useState<FZoneProduct[]>([])
    const [cart_total, setCartTotal] = useState<number>(0)

    useEffect(() => {
        const product_actions = new FZoneEcommerceProductActions()
        product_actions.get()
        .then((products: FZoneProduct[]) => {
            setProducts(products)
        })
    }, [])

    useEffect(() => {
        const formatted_total = updateCartTotal(products, cart)
        setCartTotal(formatted_total)
    }, [cart, products])

    const handleDeleteCartItem = (remove_cart_item: FZoneCartItem, product: FZoneProduct) => {
        if (!cart)
            return

        // const cart_actions = new FZoneEcommerceCartActions()
        const cart_items = cart.cart_items.filter(cart_item => cart_item.id !== remove_cart_item.id)
        // cart_actions.partial_update({products: [...cart.products.filter(product_id => product.id !== product_id)]}, cart.id.toString())

        setCart(props => (props ? {...props, cart_items: cart_items} : null))

        const cart_item_actions = new FZoneEcommerceCartItemActions()
        cart_item_actions.delete(remove_cart_item.id.toString())
    }

    const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>, change_cart_item: FZoneCartItem) => {
        const new_quantity = parseInt(event.target.value)
        if (new_quantity < 1)
            return 

        setCart(props => (props ? {
            ...props, 
            cart_items: 
                [...props.cart_items.map(cart_item => {
                    if (cart_item.id === change_cart_item.id)
                        cart_item.quantity = new_quantity
                    return cart_item
                })]
        } : null ))

        const cart_item_actions = new FZoneEcommerceCartItemActions()
        cart_item_actions.update({quantity: new_quantity}, change_cart_item.id.toString())
    }

    const proceedToCheckout = () => {
        setCheckoutStep(props => props + 1)
    }

	return (
        <Box sx={{ flexGrow: 1 }}>
            <PageHeadingTypography variant='h3'> Cart </PageHeadingTypography>
            <Grid container spacing={4}>
                <Grid item md={8} sm={8}>
                    {cart && cart.cart_items.map(cart_item => {
                        const product = products.find(product => product.id === cart_item.product)

                        if (!product)
                            return
                        
                        return (
                            <Box key={cart_item.id} display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={3}>
                                {product.name}
                                <Typography> R{product.price} </Typography>
                                <TextField  
                                    type="number" 
                                    value={cart_item.quantity}
                                    label="Quantity"
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleQuantityChange(event, cart_item)}
                                    sx={{width: '80px'}}
                                />
                                <Typography minWidth={'100px'}> R{Number((Number(product.price) * cart_item.quantity).toFixed(2))} </Typography>
                                <IconButton onClick={() => handleDeleteCartItem(cart_item, product)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Box>
                        )
                    })}
                </Grid>
                <Grid item md={4} sm={4}>
                    <Card >
                        <CardContent>
                            <Typography sx={{ fontSize: '1.2rem', color: 'darkslategray', fontWeight: 'bold' }} gutterBottom>
                                Cart Total
                            </Typography>
                            <Typography sx={{ fontSize: '1rem' }} color='text.secondary' gutterBottom>
                                Subtotal
                            </Typography>
                            <Typography sx={{ fontSize: '1rem' }} color='text.secondary' gutterBottom>
                                R{cart_total}
                            </Typography>
                            <Divider/>
                            <Typography sx={{marginTop: '1rem', fontSize: '1rem' }} color='text.secondary' gutterBottom>
                                Total
                            </Typography>
                            <Typography sx={{ fontSize: '1.2rem', color: 'darkslategray', fontWeight: 'bold' }} gutterBottom>
                                R{cart_total}
                            </Typography>
                        </CardContent>
                        <CardActions sx={{justifyContent: 'center'}}>
                            <Button onClick={proceedToCheckout} size="small" variant='contained' sx={{width: '100%'}} disabled={cart ? !(!!cart.cart_items.length) : false}> Proceed to checkout </Button>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </Box>
	)
}

export default Cart
