import { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { MessageContext } from '@contexts/MessageContext'

import ServerStatus from '../ServerStatus'


interface NetworkStatusProps {
	isOnlineCallback?: (is_online: boolean) => void
	isServerOnlineCallback?: (is_server_online: boolean) => void
}

const NetworkStatus = (props: NetworkStatusProps) => {
	const {isOnlineCallback, isServerOnlineCallback} = props

	const { t } = useTranslation('network_status')

	const {setMessage} = useContext(MessageContext)

	const [is_online, setIsOnline] = useState(true)
 
	useEffect(() => {
		const handleOnline = () => setIsOnline(true)
		const handleOffline = () => setIsOnline(false)
	
		window.addEventListener(t('online'), handleOnline)//
		window.addEventListener(t('offline'), handleOffline)
   
		return () => {
			window.removeEventListener(t('online'), handleOnline)
			window.removeEventListener(t('offline'), handleOffline)
		}
	}, [t])

	useEffect(() => {
		if (!is_online)
			setMessage(props => ({...props, info: t('you_are_offline')}))

		if (isOnlineCallback)
			isOnlineCallback(is_online)
	}, [is_online, setMessage, t, isOnlineCallback])

	return (
       <>
       {is_online && <ServerStatus isServerOnlineCallback={isServerOnlineCallback}/>}
       </>
   )
}

export default NetworkStatus





// import React, { useEffect, useState } from 'react'

// const NetworkStatus: React.FC = () => {
//  	 const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine)

//   	// Add an event listener to update the network status
// 	useEffect(() => {
// 		const handleOnlineStatusChange = () => {
// 			setIsOnline(navigator.onLine)
// 		}

// 		window.addEventListener('online', handleOnlineStatusChange);
// 		window.addEventListener('offline', handleOnlineStatusChange);

// 		return () => {
// 			window.removeEventListener('online', handleOnlineStatusChange);
// 			window.removeEventListener('offline', handleOnlineStatusChange);
// 		}
// 	}, [])

// 	return (
// 		<>
// 		{!isOnline && (
// 			<Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
// 				<Alert severity="error" className={classes.offlineMessage}>
// 					You are currently offline. Please check your internet connection.
// 				</Alert>
// 			</Snackbar>
// 		)}
// 		</>
// 	)
// }

// export default NetworkStatus;
