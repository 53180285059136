// 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

const downloadFile = (response: BlobPart, filename: string) => {
    const url = window.URL.createObjectURL(new Blob([response]))

    const link = document.createElement('a')
    link.href = url
    link.download = filename

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}


export {
    downloadFile
}