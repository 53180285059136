import {useState} from 'react'
import { useTranslation } from 'react-i18next'

import Autocomplete from '@mui/material/Autocomplete'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'

import { CurrencyOptions } from '@utils/arrays/currencies'
import { INastixCurrency } from '@utils/enums/enums'



export interface Currency {
    code: string
    value: string
    label: string
    backend_map: INastixCurrency
}

interface CurrencySelectorProps {
    currencySelectedCallback?: (selected_curreny: Currency | null) => void
}

const CurrencySelector = (props: CurrencySelectorProps) => {
    const {currencySelectedCallback} = props

    const{ t } = useTranslation('finance_terms')

    const [selected_currency, setSelectedCurrency] = useState<Currency | null>(null)

    return (
        <FormControl sx={{minWidth: '23ch'}}>
            <Autocomplete
                id="currency-select"
                options={CurrencyOptions}
                value={selected_currency}
                onChange={(event: any, new_value: Currency | null) => {
                    setSelectedCurrency(new_value)
                    if (currencySelectedCallback)
                        currencySelectedCallback(new_value)
                }}
                renderOption={(props, option) => (
                    <Box key={option.code} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            alt={`${option.code} country flag`}
                        />
                        {option.label}
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField 
                        {...params}
                        required 
                        name="currency" 
                        label={t('choose_a_currency')}
                    />
                )}
            />
            <br/>
        </FormControl>
    )
}

export default CurrencySelector;
