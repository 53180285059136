import {useState, useEffect, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import Alert from '@mui/material/Alert'
import Typography from '@mui/material/Typography'

import TransferList from '@components/pickers/TransferList'
import ChildActions from '@actions/CRUDActions/ChildActions/childActions'
import { Child, Class } from '@utils/interfaces/interfaces'
import { DialogButton, DialogDivider, INastixDialog, INastixDialogSubTitle, INastixDialogTitle } from '@styles/Dialog'
import ClassActions from '@actions/CRUDActions/ClassActions/classActions'
import { MessageContext } from '@contexts/MessageContext'
import { MAX_CHILDREN_PER_CLASS } from 'src/config'
import { CommonDialogProps } from '@utils/dialogProps/DialogProps'

import log from 'loglevel'


const TransferChildrenDialog = (props: CommonDialogProps) => {
	const {ids, state_manager, onClose, open} = props

	const {setMessage} = useContext(MessageContext)
    const { t } = useTranslation('classes')

	const [class_name, setClassName] = useState('')
	const [class_children_count, setClassChildrenCount] = useState(0)
    const [left, setLeft] = useState<Array<Child>>([])
    const [right, setRight] = useState<Array<Child>>([])
	const [school_name, setSchoolName] = useState<string | null>(null)

	useEffect(() => {
		if (open) {
			const class_actions = new ClassActions()
			class_actions.getById(ids[0].toString())
			.then((company_class: Class) => {
				setClassName(company_class.name)
				setSchoolName(company_class.school_name)
				setClassChildrenCount(company_class.children_count)
			})
		}
	}, [ids, open])

	useEffect(() => {
		const fetchData = async () => {
			const relevant_company_class: Class[] = state_manager.data.filter((company_class) => company_class.id === ids[0])
			const school_id = relevant_company_class[0].school

			const child_actions = new ChildActions()
			try {
				const in_class_data = await child_actions.getByInClass(ids[0])
				const not_in_class_data = await child_actions.getByNotInClass(ids[0], school_id)
				console.log(in_class_data)
				console.log(not_in_class_data)
			
				const not_in_class_updated = not_in_class_data.map((child: Child) => {return {...child, name: child.username }})
				const in_class_updated = in_class_data.map((child: Child) => {return {...child, name: child.username }})

				setLeft(not_in_class_updated)
				setRight(in_class_updated)
			} catch (error) {
				log.error(error)
			}
		}
		if (ids && ids.length)
			fetchData()
	}, [ids])

	const handleTransfer = () => {
		const child_actions = new ChildActions()

		if (right.length >= MAX_CHILDREN_PER_CLASS) {
			setMessage(props => ({...props, error: `${class_name} has reached maximum capacity`}))
			return
		}

		child_actions.transferChildren(ids[0], right, left)
		.then(() => {
			if (state_manager)
				state_manager.setGetAction()
			handleClose()
			setMessage(props => ({...props, success: t('child_transferred')}))
		})
	}

    const handleClose = () => {
		onClose()
	}

    return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle sx={{padding: 0, paddingTop: '0.4rem'}}> {t('transfer_children')} </INastixDialogTitle>
			<INastixDialogSubTitle sx={{padding: 0, paddingBottom: '0.4rem'}}> {class_name} </INastixDialogSubTitle>
			<Typography sx={{padding: 0, paddingBottom: '0.6rem', fontSize: '0.8rem'}}>  Showing non-suspended children connected to <span style={{fontStyle: 'italic'}}> {school_name}</span>.</Typography>
			{/* <Alert severity="info" sx={{fontSize: '0.8rem'}}> Parents will be notified if their child is transferred in or out of this class.</Alert> */}
			<br/>
			<DialogDivider/>
            <TransferList left={left} setLeft={setLeft} right={right} setRight={setRight} title1={'Not in Class'} title2={'In Class'}/>
			<DialogButton variant='contained' onClick={handleTransfer}> {t('submit')} </DialogButton>
		</INastixDialog>
    )
}

export default TransferChildrenDialog