import { CRUDAction } from "@actions/Actions/actions"
import AmountOfInvoicesHandler from "@adapters/handlers/FinanceHandlers/AmountOfInvoicesHandler/amountOfInvoicesHandler"


class AmountOfInvoicesActions extends CRUDAction {
    handler: AmountOfInvoicesHandler

    constructor() {
        super()
        this.handler = new AmountOfInvoicesHandler()
    }    
}

export default AmountOfInvoicesActions