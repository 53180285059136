import { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import dayjs, { Dayjs } from 'dayjs'

import Typography from "@mui/material/Typography"
import { useTheme } from '@mui/material/styles'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import AddressFields from "@components/forms/Fields/AddressFields"
import CompanyActions from '@actions/CompanyActions/companyActions'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { Address } from '@utils/interfaces/interfaces'
import PhoneNumber from '@pages/Setup/UserSetup/UserDetails/PhoneNumber'
import EmailFields from '@components/forms/Fields/EmailFields'
import HandleTerms from '@pages/Setup/CompanySetup/FinanceAndTerms/HandleTerms'
import { MessageContext } from '@contexts/MessageContext'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import { SettingsHeadingTypography } from '@styles/Typography'
import ChooseMonkeynastixType, { MonkeynastixType } from '@pages/Setup/CompanySetup/ChooseCompanyType/ChooseMonkeynastixType'
import { sortCompanyTerms } from '@utils/utils/util'

import Settings from '../Settings'
import SettingsSubHeading from '../Settings/SettingsSubHeading'


const BusinessProfile = () => {
    const {setMessage} = useContext(MessageContext)
    const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)
    const theme = useTheme()

    const { t } = useTranslation('business_profile')

    const [address, setAddress] = useState<Address>({
        id: 0,
        country: '',
        street: '',
        city: '',
        region: '',
        postal_code: '',
    })

    const [contact_info, setContactInfo] = useState({
        email: '',
        phone_number: '',
        country_code: ''
    })

    const [terms, setTerms] = useState<Record<string, Record<string, Dayjs>>>({})

    const [monkeynastix_types, setMonkeynastixTypes] = useState<MonkeynastixType | null>()
    const [tenant_id, setTenantId] = useState(0)

    useEffect(() => {
        const company = getLocalStorageCompany()
        
        const company_actions = new CompanyActions()
        company_actions.getCompanySettings(company.company_uuid.toString())
        .then(data => {
            console.log(data)

            setTenantId(data.tenant_id)

            for (const address of data.addresses) {
                setAddress(address)  // TODO: only setting one address block for now. Address is a one to many so need to add support for multiple
            }

            const contacts = data.contacts
            for (const contact of contacts) {
                setContactInfo(contact)  // TODO: only setting one contact block for now. Contact is a one to many so need to add support for multiple
            }

            const sorted_terms = sortCompanyTerms()
            if (sorted_terms) {
                for (const key of Object.keys(sorted_terms)) {
                    const terms = sorted_terms[key]
                    for (const [index, term] of terms.entries()) {
                        setTerms(props => ({
                            ...props, 
                            [key]: {
                                ...props[key],
                                [`term_${index + 1}_start`]: dayjs(term.from_date),
                                [`term_${index + 1}_end`]: dayjs(term.to_date)
                            }
                        }))
                    }
                }
            }
            setMonkeynastixTypes(data.monkeynastix_types)
        })

        return () => {
            setTerms({})
        }         
    }, [change_company_rerender])

    const handleContactSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault()

        // set default country code to south africa
        if (!contact_info.country_code)
            setContactInfo(props => ({...props, country_code: 'ZA +27'}))

        const company_actions = new CompanyActions()
        company_actions.postCompanySetttings({tenant_id: tenant_id, contacts: [contact_info]})
        .then(() => {
            setMessage(props => ({...props, success: t('updated_successfully')}))
        })
        .catch(() => {
            setMessage(props => ({...props, error: t('something_went_wrong')}))
        })
    }

    const handleAddressSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault()

        const data = new FormData(event.currentTarget)
        const form_data_object = Object.fromEntries(data.entries())

        const company_actions = new CompanyActions()
        company_actions.postCompanySetttings({tenant_id: tenant_id, addresses: [form_data_object]})
        .then(() => {
            setMessage(props => ({...props, success: t('updated_successfully')}))
        })
        .catch(() => {
            setMessage(props => ({...props, error: t('something_went_wrong')}))
        })
    }

    const setPhoneNumberCallback = (number: string) => {
        setContactInfo(props => ({...props, phone_number: number}))
    }  

    const setCountryCodeCallback = (country_code: string) => {
        setContactInfo(props => ({...props, country_code: country_code}))
    }  
    
    const setEmailCallback = (email: string) => {
        setContactInfo(props => ({...props, email: email}))
    }   

    const chooseMonkeynastixTypeCallback = (data: MonkeynastixType) => {
        const company_actions = new CompanyActions()
        company_actions.postCompanySetttings({tenant_id: tenant_id, monkeynastix_types: data})
        .then(() => {
            setMessage(props => ({...props, success: t('updated_successfully')}))
        })
        .catch(() => {
            setMessage(props => ({...props, error: t('something_went_wrong')}))
        })
    }

    return (
        <Settings>
        <SettingsHeadingTypography variant="h1"> {t('company_profile')} </SettingsHeadingTypography>

        <SettingsSubHeading> {t('terms')} </SettingsSubHeading>
        <Typography variant="caption"> <Link to={'/contact'} style={{color: theme.palette.primary.main}}>{t('contact_monkeynastix_admin')}</Link> {t('if_you_want_to_change_your_term_details')} </Typography>
        <br/>
        <br/>
        <>
        {Object.keys(terms).map(key => (
            <Accordion key={key}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>{t(key)} {t('terms')} </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <HandleTerms initial_terms={terms[key]} num_of_terms={Object.keys(terms[key]).length / 2} disable={true}/>
                </AccordionDetails>
            </Accordion>
        ))
        }
        </>

        <SettingsSubHeading> Monkeynastix types </SettingsSubHeading>
        <br/>
        {monkeynastix_types && <ChooseMonkeynastixType chooseMonkeynastixTypeCallback={chooseMonkeynastixTypeCallback} initial_values={monkeynastix_types} styling={'horizontal'}/>}

        <form onSubmit={handleContactSubmit}>
            <SettingsSubHeading button_text={t('update_contact') as string}> {t('update_contact')} </SettingsSubHeading>
            <br/>
            <PhoneNumber initial_phone_number={contact_info.phone_number} setPhoneNumberCallback={setPhoneNumberCallback} initial_country_code={contact_info.country_code} setCountryCodeCallback={setCountryCodeCallback}/>
            <br/>
            <EmailFields initial_email={contact_info.email} setEmailCallback={setEmailCallback}/>
        </form>

        <form onSubmit={handleAddressSubmit}>
            <SettingsSubHeading button_text={t('update_address')as string}> {t('delivery_address')} </SettingsSubHeading>
            <br/>
            {address.country && <AddressFields address_values={address}/>}
        </form>
        </Settings>
    )
}

export default BusinessProfile