import { useEffect, useState, useContext, useMemo, useCallback } from 'react'

import Alert from '@mui/material/Alert'

import { getLocalStorageCompany, updateLocalStorageCompany } from '@utils/localStorage/company'
import { INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { companyIsInGroup } from '@utils/utils/util'
import { CompanyType } from '@utils/enums/enums'
import { LocalstorageCompanyExistsContext } from '@contexts/LocalstorageCompanyExistsContext'
import TransferClassesList from '@components/tables/CRUD/ChildTable/TransferClassesList'
import { Child } from '@utils/interfaces/interfaces'


const ChildNeedsAllocationDialog = () => {
    const company = getLocalStorageCompany()

    const {local_storage_company_exists} = useContext(LocalstorageCompanyExistsContext)

    const [open, setOpen] = useState(false)
	const [selected_child_ids, setSelectedChildIds] = useState<number[]>([])
	const [selected_child, setSelectedChild] = useState<Child | null>(null)

    const needing_to_allocate_children = useMemo(() => {
        if (local_storage_company_exists && company?.needing_to_allocate_children) {
            let parsed_data
            try {
                parsed_data = JSON.parse(company.needing_to_allocate_children)
            } catch (error) {
                // if company.needing_to_allocate_children is an [] and not a string we catch this json parse error
                parsed_data = []
            }
            return parsed_data
        }
    }, [local_storage_company_exists, company.needing_to_allocate_children])
    
    useEffect(() => {
        if (!companyIsInGroup(company, CompanyType.FRANCHISOR) && needing_to_allocate_children?.length) {
            setOpen(true)
            setSelectedChildIds(needing_to_allocate_children)
        }
    }, [company, needing_to_allocate_children])

    const handleClose = () => {
        setOpen(false)

        let new_selected_child_ids: number[] = []
        new_selected_child_ids = selected_child_ids.slice(1)

        setSelectedChildIds(new_selected_child_ids)
        updateLocalStorageCompany('needing_to_allocate_children', JSON.stringify(new_selected_child_ids))
    }

    const selectedChildCallback = useCallback((selected_child: Child) => {
        setSelectedChild(selected_child)
    }, [setSelectedChild])

    return (
        <INastixDialog
            open={open}
            disableEscapeKeyDown
        >
        <INastixDialogTitle>{selected_child?.username} Needs Allocation</INastixDialogTitle>
        <Alert severity="warning"> You must allocate {selected_child?.username} to a class before you can continue</Alert>
        <br/>
        <TransferClassesList ids={selected_child_ids} onClose={handleClose} open={open} setSelectedChildCallback={selectedChildCallback}/>
        </INastixDialog>
    )
}

export default ChildNeedsAllocationDialog
