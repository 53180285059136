import UserActions from '@actions/CRUDActions/UserActions/userActions'
import { LanguageConf } from '@utils/enums/enums'
import { getLocalStorageUser, updateLocalStorageUser } from '@utils/localStorage/user'
import { i18n } from 'i18next'
import { LanguageConfMap } from './map'


const changeLanguage = (i18n: i18n, lng: LanguageConf) => {
    i18n.changeLanguage(LanguageConfMap[lng])

    const user = getLocalStorageUser()

    if (user.id) {
        const user_actions = new UserActions()
        user_actions.postUserSetttings({id: user.id, settings: {language: lng}})
        .then(() => {
            updateLocalStorageUser('language', lng)
        })
    }
}

export default changeLanguage