import GenericFetchWrapper from "@adapters/api/fetchWrappers/genericFetchWrapper"
import { BaseHandler } from "@adapters/handlers/Handlers/handlers"
import { USERS_ENDPOINT } from "@adapters/routes/endpoints"


class UserSettingsHandler extends BaseHandler {
    endpoint: string
    fetch_wrapper: GenericFetchWrapper

    constructor () {
        super()
        this.fetch_wrapper = new GenericFetchWrapper()
        this.endpoint = USERS_ENDPOINT + '/settings'
    }

    getById (id: string): Promise<any> {
        return this.fetch_wrapper.get(this.endpoint + '/' + id)
    }

    post (data: object): Promise<any> {
        return this.fetch_wrapper.post(this.endpoint, data)
    }

    put (data: object, id: string): Promise<any> {
        return this.fetch_wrapper.put(this.endpoint + '/', data, id)
    }
}

export default UserSettingsHandler