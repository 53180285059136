import { CRUDAction } from "@actions/Actions/actions"
import InstructorHandler from "@adapters/handlers/CRUDHandlers/InstructorHandlers/instructorHandler"
import InstructorUserPermissionsHandler from "@adapters/handlers/CRUDHandlers/InstructorHandlers/instructorUserPermissionsHandler"
import InstructorByNotificationIdsHandler from "@adapters/handlers/CRUDHandlers/InstructorHandlers/instructorsByNotificationIdsHandler"


class InstructorActions extends CRUDAction {
    handler: InstructorHandler
    by_notification_ids: InstructorByNotificationIdsHandler
    instructor_user_permissions: InstructorUserPermissionsHandler

    constructor() {
        super()
        this.handler = new InstructorHandler()
        this.by_notification_ids = new InstructorByNotificationIdsHandler()
        this.instructor_user_permissions = new InstructorUserPermissionsHandler()
    }

    getInstructor = (id: string): Promise<any> => {
        return this.handler.getById(id)
    }

    postInstructor = (data: object): Promise<any> => {
        console.log(data)
        return this.handler.post(data)
    }

    get (page_size?: number, page?: number, filter = {}, group: string = ''): Promise<any> {
        return this.handler.get(page, page_size, filter, group)
    }

    updateMultiple (data: object[]): Promise<any> {
        return this.handler.putMultiple(data)
    }

    getInstructorByNotificationIds (ids: string[]): Promise<any> {
        return this.by_notification_ids.getByNotifcationIds(ids)
    }

    postInstructorShirt = (data: object): Promise<any> => {
        return this.handler.postShirt(data)
    }

    updateInstructorUserPermissions = (data: object): Promise<any> => {
        return this.instructor_user_permissions.updateInstructorUserPermissions(data)
    }

    updateInstructorFiles = (data: object, id: string): Promise<any> => {
        return this.instructor_user_permissions.updateInstructorFiles(data, id)
    }
}

export default InstructorActions