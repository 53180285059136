import {useState} from 'react'

import Typography from '@mui/material/Typography'
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation'

import TransferChildrenDialog from './TransferChildrenDialog'
import { TableActionButon } from '@styles/Buttons'
import { DialogComponentProps } from '@components/tables/Table/Table'
import { useTranslation } from 'react-i18next'


const TransferChildrenDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const { t } = useTranslation('classes')

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<TransferWithinAStationIcon/>}>
			<Typography> {t('transfer')} </Typography>
		</TableActionButon>
		{ids && open && <TransferChildrenDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>}
		</>
	)
}

export default TransferChildrenDialogHandler