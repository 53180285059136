import {useState, useEffect, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import TransferList from '@components/pickers/TransferList'
import { Child, Class } from '@utils/interfaces/interfaces'
import ClassActions from '@actions/CRUDActions/ClassActions/classActions'
import { MessageContext } from '@contexts/MessageContext'
import { DialogButton } from '@styles/Dialog'
import ChildActions from '@actions/CRUDActions/ChildActions/childActions'
import { MAX_CHILDREN_PER_CLASS } from 'src/config'
import { ChildTransferDialogProps } from '@utils/dialogProps/DialogProps'
import { NumOfChildrenNotAllocatedContext } from '@pages/Children/contexts/NumOfChildrenNotAllocated'

import log from 'loglevel'


const TransferClassesList = (props: ChildTransferDialogProps) => {
	const {ids, state_manager, onClose, open, setSelectedChildCallback} = props
	const selected_child_id: number = ids[0]

	const {setMessage} = useContext(MessageContext)
	const {setNumOfChildrenNotAllocated} = useContext(NumOfChildrenNotAllocatedContext)

    const { t } = useTranslation('children')

    const [left, setLeft] = useState<Array<Class>>([])
    const [right, setRight] = useState<Array<Class>>([])
    const [button_disabled, setButtonDisabled] = useState<boolean>(true)
	const [selected_child, setSelectedChild] = useState<Child | null>(null)

	useEffect(() => {
		if (open && selected_child_id) {
			const child_actions = new ChildActions()
			child_actions.getById(selected_child_id.toString())
			.then((child: Child) => {
				setSelectedChild(child)
			})
		}
	}, [selected_child_id, open])

    useEffect(() => {
        if (selected_child && setSelectedChildCallback)
            setSelectedChildCallback(selected_child)
    }, [selected_child, setSelectedChildCallback])

    useEffect(() => {
		if (right && right.length > 1)
			setButtonDisabled(true)
        else if (right && right.length)
            setButtonDisabled(false)
        else
            setButtonDisabled(true)        
    }, [right])

	useEffect(() => {
		const fetchData = async () => {
			if (!state_manager)
				return

			const relevant_child: Child[] = state_manager.data.filter((child) => child.id === ids[0])
			const tenant_school_id = relevant_child[0].tenant

			console.log(relevant_child)
			const class_actions = new ClassActions()
			
			// we can index 'ids' by zero because 'ids' is the class that the user selected and they can only ever select one class for the transfer list process
			await Promise.all([
				class_actions.getByContainsChild(selected_child_id.toString()),
				class_actions.getByNotContainsChild(selected_child_id.toString(), tenant_school_id.toString())
			])
			.then(([contains_child_data, not_contains_child_data]) => {
				setLeft(not_contains_child_data)
				setRight(contains_child_data)
			})
			.catch(error => {
				log.error(t('an_error_occurred:'), error)
			})
		}
		if (open && selected_child_id)
			fetchData()
	}, [selected_child_id, open, t])

	const handleTransfer = async () => {
		const class_actions = new ClassActions()

		for (const company_class of right) {
			if (company_class.children_count >= MAX_CHILDREN_PER_CLASS) {
				setMessage(props => ({...props, error: `${company_class.name} has reached maximum capacity`}))
				return
			}
		}
		class_actions.transferClasses(selected_child_id, right, left)
		.then(async () => {
			if (state_manager)
				state_manager.setGetAction()

			if (!selected_child) {
				handleClose()
				setMessage(props => ({...props, success: "Class transferred"}))
				return
			}

			const child_actions = new ChildActions()
			const child: Child = await child_actions.getById(selected_child_id.toString())

			// only change children_not_allocated notification number if child has no classes
			if (selected_child.company_class.length === 0 && child.company_class.length > 0)
				setNumOfChildrenNotAllocated(props => props - 1)
			else if (child.company_class.length === 0 && selected_child.company_class.length > 0)
				setNumOfChildrenNotAllocated(props => props + 1)

			handleClose()
			setMessage(props => ({...props, success: "Class transferred"}))
		})
		.catch(error => {
			log.error('An error occurred:', error)
			setMessage(props => ({...props, error: "Something went wrong. Please try again."}))
		})
	}

    const handleClose = () => {
		onClose()
	}

    return (
        <>
        <TransferList
            left={left} 
            setLeft={setLeft} 
            right={right} 
            setRight={setRight} 
            title1={t('child_is_not_in_class') as string} 
            title2={t('child_is_in_class') as string}
        />
        <DialogButton disabled={button_disabled} variant='contained' onClick={handleTransfer}> {t('submit')} </DialogButton>
        </>
    )
}

export default TransferClassesList