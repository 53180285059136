import {useEffect, useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import {
    Chart as ChartJS,
    ChartData,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import ChildActions from '@actions/CRUDActions/ChildActions/childActions'

import { ChartBox } from '../styles'
import ToggleChartVisibility from '../ToggleChartVisibility'
import { DashbaordStatsProps } from './interfaces'


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)


type TShirtsCountData = {
    two_to_three_months_tshirts: number
    three_to_six_months_tshirts: number
    six_to_twelve_months_tshirts: number
    one_to_two_years_tshirts: number
    two_to_three_years_tshirts: number
    three_to_four_years_tshirts: number
    four_to_five_years_tshirts: number
    five_to_six_years_tshirts: number
    seven_to_eight_years_tshirts: number
    nine_to_ten_years_tshirts: number
    eleven_to_twelve_years_tshirts: number
    extra_small_tshirts: number
    small_tshirts: number
    medium_tshirts: number
    large_tshirts: number
    extra_large_tshirts: number
}


const labels = ['2-3 (mos)', '3-6 (mos)', '6-12 (mos)', '1-2 (yrs)', '2-3 (yrs)', '3-4 (yrs)', '4-5 (yrs)', '5-6 (yrs)', '7-8 (yrs)', '9-10 (yrs)', '11-12 (yrs)', 'xs', 's', 'm', 'l', 'xl']

const ChildTShirtGroups = (props: DashbaordStatsProps) => {
    const {edit_dashboard_open, checked, setChecked, selected_franchise_option, selected_school_type_options} = props
    
    const { t } = useTranslation('dashboard')
    const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: "Children T-Shirts grouped by size",
            },
        },
    }

    const [data, setData] = useState<ChartData<"bar", (number | [number, number] | null)[], string>>({labels, datasets: []})

    useEffect(() => {
        if (!selected_franchise_option)
            return

        const setChildrenBirthdayStat = async () => {

            const child_actions = new ChildActions()
            
            const today = new Date()
            const oneYearAgo = new Date(today)
            oneYearAgo.setFullYear(today.getFullYear() - 1)  // Calculate date 1 year ago
            const threeYearsAgo = new Date(today)
            threeYearsAgo.setFullYear(today.getFullYear() - 3)  // Calculate date 3 years ago

            const data: any = {
                franchise_id: selected_franchise_option.id,
            }
            if (selected_school_type_options)
                data['selected_school_type_options'] = selected_school_type_options

            const tshirt_count_data: TShirtsCountData = await child_actions.childrenStatTShirts(data)

            setData({
                labels,
                datasets: [
                    {
                        label: "Children",
                        data: Object.values(tshirt_count_data),
                        backgroundColor: 'rgba(53, 162, 235, 0.7)',
                    },
                ],
            })
        }
        setChildrenBirthdayStat()

    }, [selected_franchise_option, selected_school_type_options, change_company_rerender])

    return (
        <>
        <ChartBox edit_dashboard_open={edit_dashboard_open} checked={checked}>
            <Bar options={options} data={data} />
            <canvas style={{ display: 'inline', height: 0 }} />
        </ChartBox>
        {edit_dashboard_open && 
            <>
            <ToggleChartVisibility checked={checked} setChecked={setChecked}/>
            </>
        }
        </>
    )
}

export default ChildTShirtGroups
