import {useEffect, useContext} from 'react'

import { useNavigate, useLocation  } from 'react-router-dom'

import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { IsLoggedInContext } from '@contexts/IsLoggedInContext'


const OzowSuccessCallback = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const query_params = new URLSearchParams(location.search)

    const status = query_params.get('status')
    const status_message = query_params.get('status_message')

    const {is_logged_in} = useContext(IsLoggedInContext)

    console.log(status)
    console.log(status_message)

    useEffect(() => {
        const delay = 3000
        const redirectTimer = setTimeout(() => {
            if (is_logged_in)
                navigate('/finance/sales-overview')
            else
                navigate('/login')
        }, delay)

        return () => clearTimeout(redirectTimer)

    }, [is_logged_in, navigate])

    return (
        <Box style={{display:'flex', flexDirection: 'column', height: '100vh', alignItems: 'center', justifyContent: 'center'}}>
            <CheckCircleIcon color={'success'}/>
            <Typography variant='h6' sx={{color: 'success.main', fontWeight: 'bold'}}> Payment Successful </Typography>
            <br/>
            <Typography variant='body1'> You will be redirected shortly... </Typography>
        </Box>
    )
}

export default OzowSuccessCallback