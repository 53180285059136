import { BaseAction } from "@actions/Actions/actions"
import BulkEmailHandler from "@adapters/handlers/EmailHandlers/bulkEmailHandlers"

import EmailHandler from "@adapters/handlers/EmailHandlers/emailHandlers"
import { FormDataEntryType } from "@components/forms/Form"


class EmailActions extends BaseAction {
    email_handler: EmailHandler
    bulk_email_handler: BulkEmailHandler

    constructor() {
        super()
        this.email_handler = new EmailHandler()
        this.bulk_email_handler = new BulkEmailHandler()
    }

    sendEmailToNastix (data: FormDataEntryType): Promise<any> {
        return this.email_handler.createForNastix(data)
    }

    sendEmailToDevs (data: FormDataEntryType): Promise<any> {
        return this.email_handler.createForDevs(data)
    }

    sendEmailToAnother (data: object): Promise<any> {
        return this.email_handler.createForAnother(data)
    }

    sendBulkEmail (data: object): Promise<any> {
        return this.bulk_email_handler.createBulk(data)
    }

    sendToAuthorisedMember (data: object): Promise<any> {
        return this.email_handler.authorisedMember(data)
    }

    sendToAwaitingPaymentMember (data: object): Promise<any> {
        return this.email_handler.awaitingPaymentMember(data)
    }

    sendToUnauthorisedMember (data: object): Promise<any> {
        return this.email_handler.unauthorisedMember(data)
    }

    notifyDevsOnError (data: object): Promise<any> {
        return this.email_handler.notifyDevsOnError(data)
    }
}


export default EmailActions