import { BaseAction } from "@actions/Actions/actions"
import BulkChildHandler from "@adapters/handlers/ChildHandlers/bulkChildHandler"


class BulkChildActions extends BaseAction {
    bulk_child_handler: BulkChildHandler

    constructor() {
        super()
        this.bulk_child_handler = new BulkChildHandler()
    }

    updateToAuthorised (data: object): Promise<any> {
        return this.bulk_child_handler.updateToAuthorised(data)
    }

    updateToSentCertificates (data: object): Promise<any> {
        return this.bulk_child_handler.updateToSentCertificates(data)
    }

    importChildrenToXero (data: object): Promise<any> {
        return this.bulk_child_handler.importChildrenToXero(data)
    }

    transferChildrenToOtherFranchise (data: object): Promise<any> {
        return this.bulk_child_handler.transferChildrenToOtherFranchise(data)
    }
}


export default BulkChildActions