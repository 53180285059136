import React, {useState} from 'react'
import { useTranslation } from 'react-i18next'

import log from 'loglevel'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import {Typography} from '@mui/material'
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox'

import Form, { FormDataEntryType } from '@components/forms/Form'
import { BulkEmail } from '@utils/interfaces/interfaces'
import EmailActions from '@actions/EmailActions/emailActions'
import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import { DialogDivider, DialogFormControl, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import ChildActions from '@actions/CRUDActions/ChildActions/childActions'
import FranchiseeActions from '@actions/CRUDActions/FranchiseeActions/franchiseeActions'
import SchoolActions from '@actions/CRUDActions/SchoolActions/schoolActions'
import { DialogProps } from '@utils/dialogProps/DialogProps'

import { BulkEmailType } from './enum'


export interface SendBulkEmail {
	email: FormDataEntryType
	ids: string[]
	bulk_email_type?: BulkEmailType
	html_message?: string
	pdf_content?: FormData
	image_content?: FormData
}

type InastixFormData = BulkEmail

const SendBulkEmailDialog = (props: DialogProps) => {
	// disable the warning for not using state_manager in this dialog
	// eslint-disable-next-line  
	const {ids, state_manager, onClose, open} = props

	const [form_data, setFormData] = useState<InastixFormData>({
        subject: '',
        message: '',
    })

	const { t } = useTranslation('send_bulk_email')

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target
        setFormData(prev_form_data => ({
            ...prev_form_data,
            [name]: value,
        }))
    }

	const createFields = () => {

		//const { t } = useTranslation('send_bulk_email')
		return (
			<>
			<DialogFormControl>
			<Grid container spacing={2}>
				<Grid item xs={12}>
                    <TextField
                        fullWidth
                        required
                        label={t('subject')}
                        name="subject"
                        value={form_data.subject}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required
                        multiline
                        rows={4}
                        label={t('message')}
                        name="message"
                        value={form_data.message}
                        onChange={handleInputChange}
                    />
                </Grid>
				<Grid item xs={12}>
                    <Button type="submit" variant="contained" sx={{width: '100%'}}>
                        {t('submit')}
                    </Button>
                </Grid>
			</Grid>
			</DialogFormControl>
			</>
		)
	}

	const handleSend = async (form_data_object: FormDataEntryType): Promise<any> => {
		let data: SendBulkEmail = {
			email: form_data_object,
			ids: ids
		} 

		if (state_manager.isActionPropOfType(ChildActions))
			data.bulk_email_type = BulkEmailType.MEMBERS
		else if (state_manager.isActionPropOfType(FranchiseeActions))
			data.bulk_email_type = BulkEmailType.FRANCHISEES
		else if (state_manager.isActionPropOfType(SchoolActions))
			data.bulk_email_type = BulkEmailType.SCHOOLS
		else 
			throw new Error("Invalid action and therefore bulk_email_type. bulk_email_type can be of type ChildActions, FranchiseeActions or SchoolActions")

		const email_actions = new EmailActions()
		return email_actions.sendBulkEmail(data)
		.then(() => {
			handleClose()
			return {message: t('bulk_email_sent')}
		})
		.catch(error => {
			log.error("Email failed: " + error.message)
			throw new Error ("Email failed. Try again")
		})
	}

	const handleClose = () => {
		onClose()
	}

	//const { t } = useTranslation('send_bulk_email')

	return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> {t('bulk_email')} </INastixDialogTitle>
			<DialogDivider/>
			<Form onSubmitCallback={handleSend} createFields={createFields}/>
		</INastixDialog>
	)
}


const SendBulkEmailDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const { t } = useTranslation('send_bulk_email')

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<ForwardToInboxIcon/>}>
			<Typography> {t('send_bulk_email')} </Typography>
		</TableActionButon>
		<SendBulkEmailDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>
		</>
	)
}

export default SendBulkEmailDialogHandler