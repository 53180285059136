import {useState} from 'react'
import { useTranslation } from 'react-i18next'

import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation'

import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'

import TransferClassesDialog from './TransferClassesDialog'


const TransferClassesDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

    const { t } = useTranslation('children')

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<TransferWithinAStationIcon/>}>
			{t('allocate_to_class')}
		</TableActionButon>
		<TransferClassesDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>
		</>
	)
}

export default TransferClassesDialogHandler