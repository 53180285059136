import {useState, useContext, useEffect} from 'react'
import { useTranslation } from 'react-i18next'

import Typography from '@mui/material/Typography'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'

import { NumOfCompanyNotificationsContext } from '@contexts/NumOfNotificationsContext'
import CompanyNotificationActions from '@actions/CRUDActions/NotificationActions/companyNotifcationActions'
import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import { DialogButton, DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { MessageContext } from '@contexts/MessageContext'
import {DialogProps} from '@utils/dialogProps/DialogProps'
import { CompanyMessageType } from '@actions/CRUDActions/NotificationActions/enum'
import INastixTableContainer from '@components/tables/Table/INastixTableContainer'
import { companyIsInGroup } from '@utils/utils/util'
import { CompanyType } from '@utils/enums/enums'
import { getLocalStorageCompany } from '@utils/localStorage/company'

import useCompanyNotificationInfo from './useCompanyNotificationInfo'
import { AuthorisedFranchiseIdsContext, AuthorisedSchoolIdsContext, OpenSetRoylatiesDialogContext, OpenSetSchoolFinancesDialogContext } from './context'


const CompanyAcceptNotificationDialog = (props: DialogProps) => {
	const connected_company = getLocalStorageCompany()
	const is_connected_company_franchise = companyIsInGroup(connected_company, [CompanyType.FRANCHISEE, CompanyType.MASTER_FRANCHISE])

	const { t: tNotifications } = useTranslation('company_notifications_table')
	const { t: tCompanyGroups } = useTranslation('company_groups')
	
	const {ids, state_manager, onClose, open} = props

	const {setNumOfCompanyNotifications} = useContext(NumOfCompanyNotificationsContext)
	const {setMessage} = useContext(MessageContext)
	const {setOpenSetRoylatiesDialog} = useContext(OpenSetRoylatiesDialogContext)
	const {setOpenSetSchoolFinancesDialog} = useContext(OpenSetSchoolFinancesDialogContext)
	const {setAuthorisedFranchiseIds} = useContext(AuthorisedFranchiseIdsContext)
	const {setAuthorisedSchoolIds} = useContext(AuthorisedSchoolIdsContext)

	const {franchise_info, table_caption} = useCompanyNotificationInfo(ids, state_manager)

	const handleAuthorize = () => {
		const company_notification_actions = new CompanyNotificationActions()
		company_notification_actions.authorize_companies(ids)
		.then(async () => {
			setNumOfCompanyNotifications(props => props - ids.length)  // taking the total num of notifications and minusing it by the length of the deleted notifications

			const relevant_notifications = state_manager.data.filter(notification => ids.includes(notification.id))

			if (franchise_info) {
				const is_company = 'company_owner_name' in franchise_info && 'company_owner_email' in franchise_info
				const is_school = 'school_owner_name' in franchise_info && 'school_owner_email' in franchise_info
			
				let sent_from_company_or_school_uuids = relevant_notifications
					.filter(notification => {
						if (is_company)
							return notification.message_type === CompanyMessageType.AUTHORIZE_FRANCHISEE
						else if (is_school && is_connected_company_franchise)
							return notification.message_type === CompanyMessageType.AUTHORIZE_SCHOOL
						else
							return false
					})
					.map(notification => notification.sent_from_company)
				
				if (sent_from_company_or_school_uuids.length) {
					if (is_company) {
						setOpenSetRoylatiesDialog(true)
						setAuthorisedFranchiseIds(sent_from_company_or_school_uuids)
					}
					else if (is_school) {
						setOpenSetSchoolFinancesDialog(true)
						setAuthorisedSchoolIds(sent_from_company_or_school_uuids)
					}
				}

				let message: string
				if (ids.length > 1) {
					if (is_company) {
						message = 'Authorised franchises'
					} else if (is_school) {
						message = 'Authorised schools'
					} else {
						message = 'Authorised franchises/schools'
					}
				}
				else {
					if ('company_owner_name' in franchise_info && 'company_owner_email' in franchise_info) {
						message = 'Authorised franchise'
					} else if ('school_owner_name' in franchise_info && 'school_owner_email' in franchise_info) {
						message = 'Authorised school'
					} else {
						message = 'Authorised franchise/school'
					}
				}
				setMessage(props => ({...props, success: message}))
			}

			state_manager.setDeleteMultipleAction(ids)
			handleClose()
		})
	}

	const handleClose = () => {
		onClose()
	}

	return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> {tNotifications('confirm_authorisation')} </INastixDialogTitle>
			<DialogDivider/>
			{franchise_info  && <INastixTableContainer table_row_data={franchise_info} transFunc1={tNotifications} transFunc2={tCompanyGroups} caption={table_caption}/>}
			<DialogButton variant='contained' onClick={handleAuthorize}> {tNotifications('authorise')} </DialogButton>
		</INastixDialog>
	)
}


const CompanyAcceptNotificationDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props
	const [isDisabled, setIsDisabled] = useState(disabled)

	const { t } = useTranslation()

	const [open, setOpen] = useState(false)

	useEffect(() => {
		if (ids.length === 1) {
			setIsDisabled(false)
		}
		else if(ids.length < 1 || ids.length > 1) {
			setIsDisabled(true)
		}
	}, [ids])

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={isDisabled} onClick={handleClickOpen} startIcon={<VerifiedUserIcon/>} border_color={'mediumseagreen'}>
			<Typography> {t('authorise')} </Typography>
		</TableActionButon>
		{open && <CompanyAcceptNotificationDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>}
		</>
	)
}

export default CompanyAcceptNotificationDialogHandler