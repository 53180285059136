import { styled } from '@mui/material/styles'
import Box, {BoxProps} from '@mui/material/Box'


interface ChartBoxProps extends BoxProps {
    edit_dashboard_open: boolean
    checked: boolean
}

export const ChartBox = styled(Box, {
        shouldForwardProp: (prop) => prop !== 'edit_dashboard_open' && prop !== 'checked',
    })<ChartBoxProps>(({ edit_dashboard_open, checked }) => ({
        padding: 2, 
        border: '1px solid grey',
        ...(edit_dashboard_open && {border: checked ? '3px solid green' :'1px solid grey'})
}))
