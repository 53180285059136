function getUniqueObjectsConfigs<T extends Record<string, any>>(objects: T[], unique_key: string): T[] {
    // Create a map to track unique object keys
    const unique_objects_map: { [key: string]: boolean } = {}

    // Filter the array to include only unique objects based on the unique_key
    const unique_objects = objects.filter(object => {
        const key = object[unique_key]
        if (!unique_objects_map[key]) {
            unique_objects_map[key] = true
            return true
        }
        return false
    })

    return unique_objects
}

export {
    getUniqueObjectsConfigs
}