import React, {useState} from 'react'

import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'

import Form, { FormDataEntryType } from '@components/forms/Form'
import { Email } from '@utils/interfaces/interfaces'
import EmailActions from '@actions/EmailActions/emailActions'
import SelectDepartments from './SelectDepartments'
import { Department } from './enum'
import { useTranslation } from 'react-i18next'



type FormData = Email

const ContactForm = () => {

    const [form_data, setFormData] = useState<FormData>({
        name: '',
        email: '',
        subject: '',
        message: '',
    })

    const sendMailPromise = (form_data_object: any, department_type: string): Promise<any> => {
        const email_actions = new EmailActions()
        if (department_type === Department.DEVS)
            return email_actions.sendEmailToDevs(form_data_object)
        else if (department_type === Department.MONKEYNASTIX)
            return email_actions.sendEmailToNastix(form_data_object)
        throw new Error('Invalid department type')
    }

    const handleSubmit = async (form_data_object: FormDataEntryType): Promise<any> => {
        const department_type = form_data_object.department
        return sendMailPromise(form_data_object, department_type as string)
        .finally(() => {
            // reset form to blank fields
            setFormData({
                name: '',
                email: '',
                subject: '',
                message: '',
            })
        })
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }))
    }

    const { t } = useTranslation('contact_form')

    const createFields = () => {
        return (
            <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SelectDepartments/>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required
                        label={t('name')}
                        name="name"
                        value={form_data.name}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required
                        label={t('email')}
                        name="email"
                        value={form_data.email}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required
                        label={t('subject')}
                        name="subject"
                        value={form_data.subject}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required
                        multiline
                        rows={4}
                        label={t('message')}
                        name="message"
                        value={form_data.message}
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button type="submit" variant="contained" color="primary">
                        {t('submit')}
                    </Button>
                </Grid>
            </Grid>
            </>
        )
    }
    
    return (
        <Form onSubmitCallback={handleSubmit} createFields={createFields}></Form>
    )
}

export default ContactForm
