import { createContext } from 'react'


export type SetChangeCompanyRerender = React.Dispatch<React.SetStateAction<boolean>>

interface IChangeCompanyContext {
    change_company_rerender: boolean
    setChangeCompanyRerender: SetChangeCompanyRerender
}

export const ChangeCompanyRerenderContext = createContext<IChangeCompanyContext>({
    change_company_rerender: false,
    setChangeCompanyRerender: () => {}
})