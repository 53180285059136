import { useEffect, useState } from 'react'

import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { useTranslation } from 'react-i18next'


interface RegistrationValues {
    company_reg_number: string
    company_vat_number: string
}

interface CompanyRegistrationDetailsProps {
    reg_values?: RegistrationValues
    disabled?: boolean
    passed_should_shrink?: boolean
    reg_num_required?: boolean
    vat_num_required?: boolean
    show_reg_number?: boolean
    show_vat_number?: boolean
}

const CompanyRegistrationDetails = (props: CompanyRegistrationDetailsProps) => {
    const {reg_values, disabled=false, passed_should_shrink=false, reg_num_required=false, vat_num_required=false, show_reg_number=true, show_vat_number=true} = props
    const { t } = useTranslation('company_registration_details')

    const [should_shrink, setShouldShrink] = useState<boolean | undefined>(undefined)

    useEffect(() => {
        setShouldShrink(passed_should_shrink ?? window.innerWidth < 1010);

        const handleResize = () => {
            setShouldShrink(passed_should_shrink ?? window.innerWidth < 1010)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [passed_should_shrink])

    const handleRegOnChange = () => {
        setShouldShrink(true)
    }

    return (
        <Grid container spacing={2} justifyContent={'center'}>
            {show_reg_number && 
                <Grid item md={show_vat_number ? 6 : 12}>
                    <TextField
                        disabled={disabled}
                        id="company-reg-number"
                        name="company_reg_number"
                        onChange={handleRegOnChange}
                        value={reg_values && reg_values.company_reg_number}
                        type="text"
                        label={t('company_reg_number_lable')}
                        fullWidth
                        required={reg_num_required}
                        InputLabelProps={{
                            shrink: should_shrink,
                        }}
                    />
                </Grid>
            }
            {show_vat_number &&
                <Grid item md={show_reg_number ? 6 : 12}>
                    <TextField
                        disabled={disabled}
                        required={vat_num_required}
                        id="company-vat-number"
                        name="company_vat_number"
                        value={reg_values && reg_values.company_vat_number}
                        type="number"
                        label={t('company_vat_number_lable')}
                        fullWidth
                    />
                </Grid>
            }
        </Grid> 
    )
}

export default CompanyRegistrationDetails