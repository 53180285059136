import FranchiseeActions from "@actions/CRUDActions/FranchiseeActions/franchiseeActions"
import FranchiseeTable from "@components/tables/Custom/FranchiseeTable"

import ReportingPage from ".."
import CreateMasterFranchisePackageReporDialogHandler from "@components/dialogs/Reports/TShirtReports/PackageSlip"


const ReportPackageSlip = () => {
	const franchise_actions = new FranchiseeActions()

    return (
        <ReportingPage>
            <FranchiseeTable action={franchise_actions} use_as_reporting={true} dialogs={[CreateMasterFranchisePackageReporDialogHandler]}/>
        </ReportingPage>
    )
}

export default ReportPackageSlip