import GenericFetchWrapper from "@adapters/api/fetchWrappers/genericFetchWrapper"
import { BaseHandler } from "@adapters/handlers/Handlers/handlers"
import { CHILDREN_ENDPOINT } from "@adapters/routes/endpoints"


class TransferToAttendanceHandler extends BaseHandler {
    endpoint: string
    fetch_wrapper: GenericFetchWrapper

    constructor () {
        super()
        this.endpoint = CHILDREN_ENDPOINT + '/transfer-to-attendance'
        this.fetch_wrapper = new GenericFetchWrapper()
    }

    transferToAttendanceChildren = (data: object): Promise<any> => {
        return this.fetch_wrapper.post(this.endpoint, data)
    }
}

export default TransferToAttendanceHandler