import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'


const UnsubscribeFromMailing = () => {

    return (
        <>
        <Typography> Are you sure you want to unsubscribe? </Typography>
        <Button> unsubscribe </Button>
        </>
    )
}

export default UnsubscribeFromMailing