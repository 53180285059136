import { School } from '@utils/interfaces/interfaces'
import { createContext } from 'react'


export type SetSchools = React.Dispatch<React.SetStateAction<School[]>>

interface ISchoolsContext {
    schools: School[]
    setSchools: SetSchools
}

export const SchoolsContext = createContext<ISchoolsContext>({
    schools: [],
    setSchools: () => {}
})