import {useContext, useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'

import TextField from "@mui/material/TextField"
import Grid from "@mui/material/Grid"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel  from "@mui/material/FormControlLabel"
import FormGroup from "@mui/material/FormGroup"
import Box from "@mui/material/Box"

import { FormDataEntryType } from "@components/forms/Form"
import UserActions from "@actions/CRUDActions/UserActions/userActions"
import Form from "@components/forms/Form"
import { SetupStepContext } from '@contexts/SetupStepContext'
import { getLocalStorageUser, updateLocalStorageUser } from '@utils/localStorage/user'
import AddressFields from '@components/forms/Fields/AddressFields'
import { Address } from '@utils/interfaces/interfaces'
import { SetupBox, SetupButon, SetupFormGrid, SetupHeadingTypography, SetupSubHeadingTypography } from '@styles/Setup'

import LanguageSelect from "./LanguageSelect"
import PhoneNumber from './PhoneNumber'


interface UserDetailsSubmitData { [x: string]: boolean | FormDataEntryValue | Address }

const UserDetails = () => {
    const {setSetupStep} = useContext(SetupStepContext)

    const{ t } = useTranslation('user_setup')

    const [email, setEmail] = useState('')
    const [email_error, setEmailError] = useState(false)
    const [initial_address, setInitialAddress] = useState<Address>({
        country: '',
        street: '',
        city: '',
        region: '',
        postal_code: '',
    })
    const [physical_address, setPhysicalAddress] = useState<Address>({
        country: '',
        street: '',
        city: '',
        region: '',
        postal_code: '',
    })
    const [is_delivery_same_as_physical, setIsDeliverySameAsPhysical] = useState(true)
    const [contact_info, setContactInfo] = useState({
        email: '',
        phone_number: '',
        country_code: '',
    })

    useEffect(() => {
        const user = getLocalStorageUser()

        const user_actions = new UserActions()
        user_actions.getUserSettings(user.id.toString())
        .then(data => {
            for (const address of data.addresses) {
                // set any potential null values of the address object to an empty string
                for (const key in address) {
                    if (address[key] === null || address[key] === undefined)
                        address[key] = ""
                }
                if (address.hasOwnProperty('zip')) {
                    address['postal_code'] = address['zip']
                    delete address['zip']
                }
                setInitialAddress(address)  // TODO: only setting one address block for now. Address is a one to many so need to add support for multiple
            }

            const contacts = data.contacts
            for (const contact of contacts) {
                // set any potential null values of the contact object to an empty string
                for (const key in contact) {
                    if (contact[key] === null)
                        contact[key] = ""
                }
                setContactInfo(contact)  // TODO: only setting one contact block for now. Contact is a one to many so need to add support for multiple
                setEmail(contact.email)
            }
        })
        
    }, [])

    const validateEmail = (email: string) => {
        return /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)
    }

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value)
        if (validateEmail(event.target.value)) {
            setEmailError(false)
        }
    }

    const handleBlur = () => {
        if (!validateEmail(email)) {
            setEmailError(true)
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsDeliverySameAsPhysical(event.target.checked)
    }
    
    const handleAddressCallback = (data: Address) => {
        setPhysicalAddress(data)
    }

    const createFields = () => {
        return (
            <>
            <SetupBox>
                <SetupHeadingTypography variant="h1"> {t('details_setup')} </SetupHeadingTypography>
                <SetupFormGrid container spacing={6}>
                    <Grid item md={5}>
                        <SetupSubHeadingTypography variant="h2"> {t('contact')} </SetupSubHeadingTypography>
                        <TextField 
                            required
                            error={email_error}
                            name="email"
                            label={t('email_address')} 
                            value={email}
                            onChange={handleEmailChange}
                            onBlur={handleBlur}
                            helperText={email_error && t('invalid_email')}
                            sx={{display: 'block'}}
                            fullWidth
                            autoComplete="email"
                        />
                        <br/>
                        <PhoneNumber 
                            initial_phone_number={contact_info.phone_number}
                            setPhoneNumberCallback={setPhoneNumberCallback}
                            initial_country_code={contact_info.country_code} 
                            setCountryCodeCallback={setCountryCodeCallback}
                            add_autocomplete={true}
                        />
                        <br/>
                        <LanguageSelect/>
                    </Grid>
                    <Grid item md={7}>
                        <SetupSubHeadingTypography variant="h2"> {t('physical_address')} </SetupSubHeadingTypography>
                        <AddressFields address_values={initial_address} setAddressCallback={handleAddressCallback}/>
                        <FormGroup>
                            <FormControlLabel 
                                control={
                                    <Checkbox
                                        checked={is_delivery_same_as_physical}
                                        onChange={handleChange}
                                        name='is_delivery_same_as_physical'
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                } 
                                label={t('is_physical_and_delivery_the_same')}
                            />
                        </FormGroup>
                    </Grid>
                    {!is_delivery_same_as_physical &&
                        <Box sx={{margin: 'auto', width: '50%'}}>
                            <SetupSubHeadingTypography variant="h2"> {t('delivery_address')} </SetupSubHeadingTypography>
                            <AddressFields/>
                        </Box>
                    }
                </SetupFormGrid>
                <SetupButon variant='contained' type="submit"> {t('submit')} </SetupButon>
            </SetupBox>
            </>
        )
    }

    const setPhoneNumberCallback = (number: string) => {
        setContactInfo(props => ({...props, phone_number: number}))
    }

    const setCountryCodeCallback = (country_code: string) => {
        setContactInfo(props => ({...props, country_code: country_code}))
    }  

    const onSubmit = async (form_data_object: FormDataEntryType): Promise<any> => {
        console.log(form_data_object)
        let data: UserDetailsSubmitData = {...form_data_object}
        if (!is_delivery_same_as_physical)
            data['physical_address'] = physical_address

        const user_action = new UserActions()
        return user_action.createSetupDetails(data)
		.then(() => {
            const user = getLocalStorageUser()
            updateLocalStorageUser('setup_step', user.setup_step + 1)
            setSetupStep(prop => prop + 1)
		})
	}

    return (
		<Form onSubmitCallback={onSubmit} createFields={createFields}/>
    )
}

export default UserDetails