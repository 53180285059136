import {useState, useContext, useEffect} from 'react'
import { useTranslation } from 'react-i18next'

import HubIcon from '@mui/icons-material/Hub'
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"

import { DialogButton, DialogDivider, DialogFormControl, INastixDialog, INastixDialogTitle } from "@styles/Dialog"
import Form, { FormDataEntryType } from '@components/forms/Form'
import { updateLocalStorageUser } from '@utils/localStorage/user'
import { UserSetupStep, UserType } from '@utils/enums/enums'
import { MessageContext } from '@contexts/MessageContext'
import { DialogMainProps } from '@components/dialogs/interfaces/interface'
import { LocalStorageCompany, LocalStorageUser } from '@utils/interfaces/interfaces'
import { SetupStepContext } from '@contexts/SetupStepContext'
import GenericFetchWrapper from '@adapters/api/fetchWrappers/genericFetchWrapper'
import { ContentType } from '@adapters/api/enums/enum'
import { USERS_ENDPOINT } from '@adapters/routes/endpoints'
import FranchiseeActions from '@actions/CRUDActions/FranchiseeActions/franchiseeActions'

import SelectAnExistingCompany from './SelectAnExistingCompany'
import ChooseUserTypeFields from '../ChooseUserType/ChooseUserTypeFields'
import { validateFileUploadsAndAppendToFormData } from '../ChooseUserType/utils'
import { CompanyLabel } from '.'


const CreateCompanyDialog = (props: DialogMainProps) => {
	const {onClose, open} = props

    const {setMessage} = useContext(MessageContext)
    const {setSetupStep} = useContext(SetupStepContext)

    const { t } = useTranslation('set_up')

    const [company, setCompany] = useState<CompanyLabel | null>(null)
    const [localstorage_company, setLocalstorageCompany] = useState<LocalStorageCompany | null>(null)

    const [is_instructor, setIsInstructor] = useState(false)
    const [south_african_gl_course, setSouthAfricanGICourse] = useState<File | null>(null)
    const [police_clearance_certificate, setPoliceClearanceCertificate] = useState<File | null>(null)
    const [employment_contract, setEmploymentContract] = useState<File | null>(null)
    const [copy_of_id_photo, setCopyOfIdPhoto] = useState<File | null>(null)

    const selectedCompanyCallback = (selected_company: CompanyLabel | null) => {
        setCompany(selected_company)
    }

    const setIsInstructorCallback = (is_instructor: boolean) => {
        setIsInstructor(is_instructor)
    }

    useEffect(() => {
        if (company) {
            const franchise_actions = new FranchiseeActions()
            franchise_actions.getById(company.company_uuid)
            .then((company: LocalStorageCompany) => {
                setLocalstorageCompany(company)
            })
        }
        else 
            setLocalstorageCompany(null)
    }, [company])

    const createFields = () => {
        return (
            <Box sx={{textAlign:'center', marginTop: '1rem'}}>
            <DialogFormControl sx={{width: '300px', margin: 'auto'}}>
                <Typography sx={{fontSize: '0.7rem'}}> {t('add_franchise_if_not_here')} </Typography>
                <br/>
                <SelectAnExistingCompany selectedCompanyCallback={selectedCompanyCallback}/>
                <br/>
                <ChooseUserTypeFields 
                    parsed_filter_by_company={localstorage_company}
                    setIsInstructorCheckedCallback={setIsInstructorCallback} 
                    setSouthAfricanGICourse={setSouthAfricanGICourse} 
                    setPoliceClearanceCertificate={setPoliceClearanceCertificate} 
                    setCopyOfIdPhoto={setCopyOfIdPhoto}
                    setEmploymentContract={setEmploymentContract}
                />
                <DialogButton type="submit" variant='contained'> {t('connect')} </DialogButton>
            </DialogFormControl>
            </Box>
        )
    }

	const handleConnect = async (form_data_object: FormDataEntryType): Promise<any> => {
        let form_data = new FormData()
        const form_data_object_string = JSON.stringify(form_data_object)
        form_data.append('object_data', form_data_object_string)

        const user_type = form_data_object.user_type
        if (is_instructor || user_type === UserType.INSTRUCTOR || user_type === UserType.ASSISTANT) {
            const response = validateFileUploadsAndAppendToFormData(is_instructor, south_african_gl_course, police_clearance_certificate, copy_of_id_photo, employment_contract, form_data, form_data_object, setMessage)
            if (!response)
                return
        }

        if (company) {
            const generic_fetchwrapper = new GenericFetchWrapper(ContentType.FORM_DATA)
            const endpoint = `${USERS_ENDPOINT}/setup/choose-company`
            const data = JSON.stringify({company_uuid: company.company_uuid, connected_group_name: user_type})
            form_data.append('data', data)

            return generic_fetchwrapper.post(endpoint, form_data)
            .then((user: LocalStorageUser) => {
                for (const [key, value] of Object.entries(user)) {
                    updateLocalStorageUser(key, value)
                }
                setSetupStep(UserSetupStep.GET_AUTHORIZED)	
                handleClose()	
            })
        }
    }

	const handleClose = () => {
		onClose()
        setMessage(props => ({...props, form_error: ""}))
	}

	return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle sx={{textAlign: 'center'}}> 
            {t('connect_to_an_existing_franchise')}
            </INastixDialogTitle>
			<DialogDivider/>
            <Form onSubmitCallback={handleConnect} createFields={createFields}/>
		</INastixDialog>
	)
}


const ConnectToAnExistingCompanyDialogHandler = () => {
    const { t } = useTranslation('set_up')

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

    return (
        <>
        <Button onClick={handleClickOpen} sx={{textTransform: 'capitalize', display: 'flex', justifyContent: 'start', margin: '0 0.3rem'}} startIcon={<HubIcon sx={{ fontSize: "30px" }}/>}>
            {t('connect_to_an_existing_franchise')}
        </Button>
		<CreateCompanyDialog open={open} onClose={handleClose}/>
        </>
    )
}

export default ConnectToAnExistingCompanyDialogHandler


