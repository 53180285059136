import {useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next'

import { createTheme, ThemeProvider } from '@mui/material/styles'

import { MessageContext, Message } from '@contexts/MessageContext'
import { NumOfRequestsContext } from '@contexts/NumOfRequestsContext'
import { IsLoggedInContext } from '@contexts/IsLoggedInContext'
import { NumOfCompanyNotificationsContext, NumOfNotificationsContext } from '@contexts/NumOfNotificationsContext'
import UISnackbar from '@components/snackbars/UISnackBar'
import UIBackdrop from '@components/backdrops/UIBackdrop'
import Routing from '@components/routing/Routing'
import { SetupStepContext } from '@contexts/SetupStepContext'
import { CompanySetupStepContext } from '@contexts/CompanySetupStepContext'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import changeLanguage from '@utils/language/change'
import { LanguageConf } from '@utils/enums/enums'
import { getLocalStorageUser } from '@utils/localStorage/user'
import { TriggerTableDataRerenderContext } from '@contexts/TriggerRerender/triggerTableDataRerender'
import { LanguageConfMap } from '@utils/language/map'
import { IsEmailVerifiedContext } from '@contexts/IsEmailVerifiedContext'
import { LocalstorageCompanyExistsContext } from '@contexts/LocalstorageCompanyExistsContext'
import { LocalstorageUserExistsContext } from '@contexts/LocalstorageUserExistsContext'
import UnderMaintenance from '@components/utils/Maintenance'
import { AmountOfFinancesData } from '@pages/Finances/Finances/interfaces'
import { amountOfFinancesDataObject } from '@pages/Finances/Finances/objects'
import { AmountOfFinancesDataContext } from '@pages/Finances/Finances/contexts'
import FZoneEcommerceCartActions from '@actions/CRUDActions/FZoneEcommerceAtions/fzoneEcommerceCartActions'
import { FZoneCart } from '@pages/Fzone/Checkout/Cart'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { CartContext } from '@pages/Fzone/contexts'

import { is_under_maintenance } from './config'


const theme = createTheme({
	palette: {
		mode: 'light',
		primary: {
			main: '#1f365c',
		},
		secondary: {
			main: '#ffaa00',
		},
		// success: {
		// 	main: '#36b729', 
		// },
		text: {
			primary: 'rgba(0, 0, 0, 0.87)',
			secondary: 'rgba(0, 0, 0, 0.6)',
			disabled: 'rgba(0, 0, 0, 0.38)',
		},
		divider: 'rgba(0, 0, 0, 0.12)'
	},
	typography: {
		h1: {
		  fontSize: '2.5rem',
		},
		h2: {
			fontSize: '2rem',
	  	},
		h3: {
		  	fontSize: '1.7rem',
		},
		fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(','),
	},
})

const App = () => {
    const { i18n } = useTranslation()

    const [message, setMessage] = useState<Message>({'success': "", 'error': "", 'form_error': "", 'info': "", 'warning': ""})
	const [num_of_requests, setNumOfRequests] = useState<number>(0)
	const [num_of_notifications, setNumOfNotifications] = useState(0)
	const [num_of_company_notifications, setNumOfCompanyNotifications] = useState(0)
	const [setup_step, setSetupStep] = useState(-1)
	const [company_setup_step, setCompanySetupStep] = useState(-1)
	const [is_logged_in, setIsLoggedIn] = useState(false)
	const [change_company_rerender, setChangeCompanyRerender] = useState(false)
	const [trigger_table_data_rerender, setTriggerTableDataRerender] = useState(false)
    const [is_email_verified, setIsEmailVerified] = useState(true)
    const [local_storage_user_exists, setLocalstorageUserExists] = useState(false)
    const [local_storage_company_exists, setLocalstorageCompanyExists] = useState(false)
	const [amount_of_finances_data, setAmountOfFinancesData] = useState<AmountOfFinancesData>(amountOfFinancesDataObject)
    const [cart, setCart] = useState<FZoneCart | null>(null)

	useEffect(() => {
		const access_token = localStorage.getItem('access_token')
		setIsLoggedIn(!(access_token === null) && !(access_token === 'undefined'))
	}, [])

	useEffect(() => {
		const user = getLocalStorageUser()
		if (is_logged_in) {
			if (user.language === null) 
				changeLanguage(i18n, LanguageConf.ENGLISH)
			else {
				if (user.language === LanguageConf.ENGLISH)
					i18n.changeLanguage(LanguageConfMap[LanguageConf.ENGLISH])
				else if (user.language === LanguageConf.AFRIKAANS)
					i18n.changeLanguage(LanguageConfMap[LanguageConf.AFRIKAANS])
				else
					i18n.changeLanguage(LanguageConfMap[LanguageConf.ENGLISH])
			}
		}
	}, [i18n, is_logged_in])

	useEffect(() => {
		if (!is_logged_in)
			return

        const cart_actions = new FZoneEcommerceCartActions()

        cart_actions.get()
        .then((carts: FZoneCart[]) => {
            if (!carts.length) {
                const company = getLocalStorageCompany()
                const user = getLocalStorageUser()
                cart_actions.create({
                    tenant: company.tenant_id,
                    user: user.id,
                    products: []
                })
                .then((cart: FZoneCart) => {
                    setCart(cart)
                })
				.catch((e) => {
					console.error(e)
				})
            }
            else {
                const cart = carts[0]
                setCart(cart)
            }
        })
		.catch((e) => {
			console.error(e)
		})
	}, [is_logged_in])

	return (
		<>
		{is_under_maintenance ?
			<UnderMaintenance/>
			:
            <>
			<AmountOfFinancesDataContext.Provider value={{amount_of_finances_data, setAmountOfFinancesData}}>

			<MessageContext.Provider value={{message, setMessage}}>
			<NumOfRequestsContext.Provider value={{num_of_requests, setNumOfRequests}}>
			<IsLoggedInContext.Provider value={{is_logged_in, setIsLoggedIn}}>
			<IsEmailVerifiedContext.Provider value={{is_email_verified, setIsEmailVerified}}>
			<LocalstorageUserExistsContext.Provider value={{local_storage_user_exists, setLocalstorageUserExists}}>
			<LocalstorageCompanyExistsContext.Provider value={{local_storage_company_exists, setLocalstorageCompanyExists}}>
			<NumOfNotificationsContext.Provider value={{num_of_notifications, setNumOfNotifications}}>
			<NumOfCompanyNotificationsContext.Provider value={{num_of_company_notifications, setNumOfCompanyNotifications}}>
			<SetupStepContext.Provider value={{setup_step, setSetupStep}}>
			<CompanySetupStepContext.Provider value={{company_setup_step, setCompanySetupStep}}>
			<ChangeCompanyRerenderContext.Provider value={{change_company_rerender, setChangeCompanyRerender}}>
			<TriggerTableDataRerenderContext.Provider value={{trigger_table_data_rerender, setTriggerTableDataRerender}}>
			<CartContext.Provider value={{cart, setCart}}>
			<ThemeProvider theme={theme}>
				<Routing/>
			</ThemeProvider>
			</CartContext.Provider>	
			</TriggerTableDataRerenderContext.Provider>
			</ChangeCompanyRerenderContext.Provider>
			</CompanySetupStepContext.Provider>
			</SetupStepContext.Provider>
			</NumOfCompanyNotificationsContext.Provider>
			</NumOfNotificationsContext.Provider>
			</LocalstorageCompanyExistsContext.Provider>
			</LocalstorageUserExistsContext.Provider>
			</IsEmailVerifiedContext.Provider>
			</IsLoggedInContext.Provider>
			</NumOfRequestsContext.Provider>
			</MessageContext.Provider>

			<UIBackdrop num_of_requests={num_of_requests}/>
			<UISnackbar message={message} setMessage={setMessage}/>
			</AmountOfFinancesDataContext.Provider>	
			</>
		}
		</>
	)
}

export default App
