import {useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import SummarizeIcon from '@mui/icons-material/Summarize'

import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import { DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { DialogProps } from '@utils/dialogProps/DialogProps'
import { NumOfRequestsContext } from '@contexts/NumOfRequestsContext'
import DownloadCSVReportActions from '@actions/ReportActions/downloadCSVReportActions'
import { downloadFile } from '@utils/files/blobs'
import { convertDateToYYYYMMDD, createListOfMonkeynastixTypes } from '@utils/utils/util'
import FranchiseeActions from '@actions/CRUDActions/FranchiseeActions/franchiseeActions'
import { Franchisee } from '@utils/interfaces/interfaces'


const CreateFranchiseDetailsReporDialog = (props: DialogProps) => {
	// disable the warning for not using state_manager in this dialog
	// eslint-disable-next-line  
	const {ids, state_manager, onClose, open} = props

	const {setNumOfRequests} = useContext(NumOfRequestsContext)

	const { t } = useTranslation('t_shirt_reports')

	const [program_type, setProgramType] = useState('all')

	const handleFranchiseDetailsReportCreate = async () => {
		setNumOfRequests(props => props + 1)

		const download_csv_report_actions = new DownloadCSVReportActions()

		let filters = {}
		if (program_type === 'all')
			filters = {'company_uuid__in': ids}
		else 
			filters = {'company_uuid__in': ids, "program_type": program_type}


		const franchise_actions = new FranchiseeActions()
		const franchises: Franchisee[] = await franchise_actions.get(undefined, undefined, filters)

		let csv_data = []
		for (const franchise of franchises) {
			const address = franchise.addresses[0]
			const contact = franchise.contacts[0]
			csv_data.push([
				franchise.tenant_name,
				address.region,
				franchise.company_owner_name,
				franchise.dot_com_email,
				createListOfMonkeynastixTypes(franchise, true).join(" • "),
				franchise.company_owner_email,
				`${contact.country_code} ${contact.phone_number}`,
				franchise.company_reg_number,
				franchise.royalties_percentage,
				franchise.extramural_reg_fee,
				franchise.intramural_reg_fee,
				franchise.extramural_fees,
				franchise.intramural_fees,
				franchise.per_class_fees
			])
		}

		const data = {
			csv_header_row: [
				'Franchise Name', 'Province', 'Contact Name', '.com Email', 'Program Type', 'Email', 'Phone Number', 'Company Registration Number', 'Royalty Percentage',
				'Extramural Registration Fee', 'Intramural Registration Fee', 'Extramural Tuition Fees', 'Intramural Tuition Fees', 'Per Class Fees'
			],
			csv_data: csv_data
		}
		try {
			const response = await download_csv_report_actions.dowloadCSVReport(data)

			setNumOfRequests(props => props - 1)

			let current_date = convertDateToYYYYMMDD(new Date())

			let filename = `Franchise Details Report ${current_date}.csv`
			downloadFile(response, filename)
		} catch (error) {
			console.error('Error generating CSV Details report:', error)
            setNumOfRequests(props => props - 1)
		}
	}

	const handleProgramTypeChange = (event: SelectChangeEvent) => {
        setProgramType(event.target.value as string)
    }

	const handleClose = () => {
		onClose()
	}

	return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> Get Franchise Details </INastixDialogTitle>
			<DialogDivider/>
			<FormControl fullWidth>
				<InputLabel id="program-type-select-label">{t('Program Type')}</InputLabel>
				<Select
					labelId="program-type-select-label"
					id="program-type-select"
					name="program_type"
					value={program_type}
					label={t('Program Type')}
					onChange={handleProgramTypeChange}
					sx={{marginBottom: 2}}
					required
				>
					<MenuItem value={'all'}>{t('All')}</MenuItem>
					<MenuItem value={'is_babynastix'}>{t('Babynastix')}</MenuItem>
					<MenuItem value={'is_monkeynastix'}>{t('Monkeynastix')}</MenuItem>
					<MenuItem value={'is_supernastix'}>{t('Supernastix')}</MenuItem>
				</Select>
			</FormControl>
			<Button variant='contained' onClick={handleFranchiseDetailsReportCreate} sx={{width: '100%', marginTop: '1rem'}}>{t('create')}</Button>
        </INastixDialog>
	)
}


const CreateFranchiseDetailsReporDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const { t } = useTranslation('t_shirt_reports')

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<SummarizeIcon/>}>
			<Typography> Details Report</Typography>
		</TableActionButon>
		<CreateFranchiseDetailsReporDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>
		</>
	)
}

export default CreateFranchiseDetailsReporDialogHandler