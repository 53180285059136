import {useState} from 'react'

import HelpIcon from '@mui/icons-material/Help'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import { DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'

import { DialogMainProps } from '../interfaces/interface'
import UserTypeInfo from '@pages/Setup/UserSetup/ChooseUserType/UserTypeInfo'


const UserTypeInfoDialog = (props: DialogMainProps) => {
	const {onClose, open} = props

	const handleClose = () => {
		onClose()
	}

	return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> User Type Info </INastixDialogTitle>
			<DialogDivider/>
            <UserTypeInfo/>
		</INastixDialog>
	)
}


const UserTypeInfoDialogHandler = () => {
	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
        <Tooltip title="User Type Descriptions">
        <IconButton onClick={handleClickOpen} sx={{position: 'absolute', top: '0.5rem', right: '-3rem'}}>
            <HelpIcon/>
        </IconButton>
        </Tooltip>
		<UserTypeInfoDialog open={open} onClose={handleClose}/>
		</>
	)
}

export default UserTypeInfoDialogHandler