import { useTranslation } from 'react-i18next'

import { GridColumnVisibilityModel  } from '@mui/x-data-grid'

import { CRUDAction } from '@actions/Actions/actions'
import ClassForm from '@components/forms/ClassForm'
import Table from '@components/tables/Table/Table'
import TransferChildrenDialogHandler from './TransferChildrenDialogHandler'
import { companyIsInGroup, permissionValid } from '@utils/utils/util'
import { getLocalStorageUser } from '@utils/localStorage/user'
import { UserPermissions } from '@utils/enums/permissions'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { CompanyType } from '@utils/enums/enums'

import { renderConnectedCompanyCell } from '../UsersTable'
import CreateClassRegisterReportDialogHandler from '../../../dialogs/Reports/ClassRegister/CreateClassRegister'


export interface TableProps {
	action: CRUDAction
	use_as_reporting?: boolean
}

export const addToColumnVisibilityModelForClassTables = () => {
    const company = getLocalStorageCompany()
    const is_school_company = companyIsInGroup(company, CompanyType.SCHOOL)

	const column_visibility_model: GridColumnVisibilityModel = {}

	if (is_school_company)
		column_visibility_model['school_name'] = false

	const is_franchise_or_school = companyIsInGroup(company, [CompanyType.FRANCHISEE, CompanyType.SCHOOL])
	if (is_franchise_or_school)
		column_visibility_model['connected_company_name'] = false

	return column_visibility_model
} 

export const columns_for_class_tables = (connectedCompanyHeader: string, schoolHeader: string, sortable: boolean=true) => {
	return [
		{
			field: 'connected_company_name',
			headerName: connectedCompanyHeader,
			renderCell: (params: any) => renderConnectedCompanyCell(params, 'connected_company_name'),
			minWidth: 180,
			flex: 0.2,
			sortable: sortable
		},
		{
			field: 'school_name',
			headerName: schoolHeader,
			minWidth: 260,
			flex: 2,
			sortable: sortable
		},
	]
}


const ClassTable = (props: TableProps) => {
	const {action, use_as_reporting} = props
    
	const { t } = useTranslation('classes')
	const user = getLocalStorageUser()
	const connected_company = getLocalStorageCompany()
	const is_master_franchise_doesnt_act_like_franchise_or_franchisor = !connected_company.acts_like_a_franchise && companyIsInGroup(connected_company, [CompanyType.MASTER_FRANCHISE, CompanyType.FRANCHISOR])

	const can_add = !use_as_reporting && !is_master_franchise_doesnt_act_like_franchise_or_franchisor && permissionValid(user, UserPermissions.CREATE_CLASSES)
	const can_edit = !use_as_reporting && !is_master_franchise_doesnt_act_like_franchise_or_franchisor && permissionValid(user, UserPermissions.EDIT_CLASSES)
	const can_delete = !use_as_reporting && !is_master_franchise_doesnt_act_like_franchise_or_franchisor && permissionValid(user, UserPermissions.DELETE_CLASSES)
	const can_transfer = !use_as_reporting && !is_master_franchise_doesnt_act_like_franchise_or_franchisor && permissionValid(user, UserPermissions.TRANSFER_CLASSES)

	const connected_company_header = t('connected_franchise')
	const school_header = t('school')

	const use_pagination = companyIsInGroup(connected_company, CompanyType.MASTER_FRANCHISE) && !connected_company.acts_like_a_franchise ? true : false

	const columns = [
		{ field: 'id', headerName: 'ID', minWidth: 150, flex: 2 },
		{ field: 'name', headerName: t('name'), minWidth: 150, flex: 2, sortable: !use_pagination },
		...columns_for_class_tables(connected_company_header, school_header, !use_pagination),
		{ field: 'instructor_name', sortable: !use_pagination, headerName: t('instructors'), minWidth: 275, flex: 2 },
		{ field: 'frequency', sortable: !use_pagination, headerName: t('frequency'), minWidth: 150, flex: 2 },
		{ field: 'children_count', filterable: !use_pagination, sortable: !use_pagination, headerName: t('num_of_children'), minWidth: 150, flex: 2 },
		{ field: 'date_created', headerName: t('date_created'), minWidth:200, flex:2},
		{ field: 'date_modified', headerName: t('date_modified'), minWidth:200, flex:2},
	]

	const column_visibility_model = addToColumnVisibilityModelForClassTables()

	const paginationFilterParser = (field: string, value: string) => {
		let custom_operator = undefined

		if (field === 'school_name') {
			field = 'school__name'
		}
		else if (field === 'connected_company_name') {
			field = 'school__connected_company__tenant_name'
		}
		return {field, value, custom_operator}
	}

    return (
        <Table
			single_selection_dialogs={can_transfer ? [TransferChildrenDialogHandler] : []} 
			dialogs={!is_master_franchise_doesnt_act_like_franchise_or_franchisor ? [CreateClassRegisterReportDialogHandler] : []}
			Form={ClassForm}
			column_visibility_model={column_visibility_model}
			paginationFilterParser={use_pagination ? paginationFilterParser : undefined}
			use_pagination={use_pagination}
			page_size={50}
			amount_of_rows_selection={[50, 100]}
			action={action}
			columns={columns}
			can_add={can_add}
			can_edit={can_edit}
			can_delete={can_delete}
			sort_by_asc='name'
		/>
    )
}

export default ClassTable