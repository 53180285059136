import { BaseAction } from "@actions/Actions/actions"
import ReportingStatHandler from "@adapters/handlers/ReportHandlers/reportingStatsHandler"


class ReportingStatActions extends BaseAction {
    reporting_stat_handler: ReportingStatHandler

    constructor() {
        super()
        this.reporting_stat_handler = new ReportingStatHandler()
    }

    reportingStat (data: object): Promise<any> {
        return this.reporting_stat_handler.reportingStat(data)
    }
}


export default ReportingStatActions