import { useTranslation } from 'react-i18next'

import { CRUDAction } from '@actions/Actions/actions'
import Table from '@components/tables/Table/Table'
import { childTableValueGetter, valueConnectedSchoolGetter } from '@components/tables/CRUD/ChildTable'

import MemeberPoolToFranchiesDialogHandler from '@components/dialogs/MemberPoolToFranchiseDialogHandler'


export interface TableProps {
	action: CRUDAction
}

const MinastixMemberPoolTable = (props: TableProps) => {
	const {action} = props
    const { t } = useTranslation('children')

	const columns = [
		{ field: 'id', headerName: t('id') },
		{ field: 'username', headerName: t('name'), minWidth: 200 },
        { field: 'school_from_minatix', headerName: t('typed_school'), minWidth: 250 },
		{ field: 'age', headerName: t('age') },
		{ field: 'shirt_size', headerName: t('shirt_size'), valueGetter: childTableValueGetter },
		{ field: 'preferred_parent_contact_detail', headerName: t('parent_contact'), minWidth: 200 },
		{ field: 'sex', headerName: t('gender'), cellClassName: 'center-aligned-cell', minWidth: 85, valueGetter: childTableValueGetter },
		{ field: 'birthday', headerName: t('birthday'), minWidth: 120, flex: 0.2 },
		{ field: 'registered', headerName: t('registered'), minWidth: 120, flex: 0.2, valueGetter: childTableValueGetter},
	]

    return (
        <Table
			dialogs={[MemeberPoolToFranchiesDialogHandler]}
			page_size={100}
			amount_of_rows_selection={[100]}
			action={action} 
			columns={columns} 
		/>
    )
}

export default MinastixMemberPoolTable