import GenericFetchWrapper from "@adapters/api/fetchWrappers/genericFetchWrapper"
import { GenericHandler } from "@adapters/handlers/Handlers/handlers"
import { COMPANIES_ENDPOINT } from "@adapters/routes/endpoints"


class ProvisionDemoCompanyHandler extends GenericHandler {
    endpoint: string
    fetch_wrapper: GenericFetchWrapper

    constructor () {
        super()
        this.fetch_wrapper = new GenericFetchWrapper()
        this.endpoint = COMPANIES_ENDPOINT + '/provision-demo-account'
    }
}

export default ProvisionDemoCompanyHandler