import {useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import Typography from '@mui/material/Typography'
import ArchiveIcon from '@mui/icons-material/Archive'

import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import { MessageContext } from '@contexts/MessageContext'
import { DialogButton, DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { DialogForIdsProps } from '@components/dialogs/interfaces/interface'
import ChildActions from '@actions/CRUDActions/ChildActions/childActions'
import { Child } from '@utils/interfaces/interfaces'


const ArchiveChildDialog = (props: DialogForIdsProps) => {
	const {ids, state_manager, onClose, open} = props

	const {setMessage} = useContext(MessageContext)
    const { t } = useTranslation('tables')

	const handleArchive = async () => {
		const child_found = state_manager.data.find((child: Child) => child.id === parseInt(ids[0]))
		console.log(child_found)

		const child_actions = new ChildActions()
		child_actions.update({...child_found, archive: true}, ids[0].toString())
		.then(data => {
			console.log(data)
			const unarchived_children = state_manager.data.filter(child => child.id !== ids[0])
			state_manager.setData(unarchived_children)
			setMessage(props => ({...props, success: "Successfully archived"}))
		})
		.catch((error: any) => {
			console.log(error)
		})
		.finally(() => {
			handleClose()
		})
	}

	const handleClose = () => {
		onClose()
	}

	return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> Confirm Archive </INastixDialogTitle>
			<DialogDivider/>
			<Typography>
				{t('Are you sure you want to archive', { count: ids.length })} {t(ids.length === 1 ? 'item' : 'items')}?
			</Typography>
			<DialogButton variant='contained' onClick={handleArchive}> Archive </DialogButton>
		</INastixDialog>
	)
}


const ArchiveChildDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<ArchiveIcon/>}>
			<Typography> Archive </Typography>
		</TableActionButon>
		<ArchiveChildDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>
		</>
	)
}

export default ArchiveChildDialogHandler