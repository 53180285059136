import { GenericHandler } from "../Handlers/handlers"
import { COMPANIES_ENDPOINT } from "@adapters/routes/endpoints"


class CompanyHandler extends GenericHandler {
    constructor (endpoint=COMPANIES_ENDPOINT) {
        super(endpoint)
    }
}

export default CompanyHandler