import {useContext, useState, useEffect, useRef} from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Stack from '@mui/material/Stack'
import { useMediaQuery, Theme } from '@mui/material'
import Box from '@mui/material/Box'

import { getLocalStorageUser, updateLocalStorageUser } from '@utils/localStorage/user'
import { MainHeadingTypography } from '@styles/Setup'
import { SetupStepContext } from '@contexts/SetupStepContext'
import { CompanySetupStep, UserSetupStep, UserType } from '@utils/enums/enums'
import { isInGroup } from '@utils/utils/util'
import SetupFooter from '@pages/Setup/Footer'
import BackToLogin from '@pages/Setup/BackToLogin'
import UserActions from '@actions/CRUDActions/UserActions/userActions'
import { LocalStorageUser } from '@utils/interfaces/interfaces'
import { IsEmailVerifiedContext } from '@contexts/IsEmailVerifiedContext'
import { CompanySetupStepContext } from '@contexts/CompanySetupStepContext'
import { DesktopStepper, MobileStepper } from '@pages/Setup/Setup'

import GetStepComponent from './GetStepComponent'
import ColorlibStepIcon from './ColorStepIcon'
import { SelectedUserTypeContext } from './setSelectedUserType'
import VerifiyEmail from '../VerifiyEmail'
import { AuthorizedStatus } from '../GetAuthorized/enum'
import { getLocalStorageCompany } from '@utils/localStorage/company'


const SetupStepper = () => {
    const is_small_screen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

    const { t } = useTranslation('set_up')
	const navigation = useNavigate()

    let steps = [t('details_setup'), t('choose_a_user_type'), t('choose_company'), t('get_authorized')]

    const {setup_step, setSetupStep} = useContext(SetupStepContext)
    const {company_setup_step} = useContext(CompanySetupStepContext)
    const {is_email_verified, setIsEmailVerified} = useContext(IsEmailVerifiedContext)

    const [selected_user_type, setSelectedUserType] = useState<UserType>(UserType.DEFAULT)
    const steps_ref = useRef<string[]>([])

    const user = getLocalStorageUser()
	const company = getLocalStorageCompany()
    const is_school_operator = isInGroup(user, company, UserType.SCHOOL_OPERATOR)

    if (is_school_operator)
        steps = [t('details_setup'), t('choose_a_user_type'), t('choose_school'), t('get_authorized')]

    useEffect(() => {
        if (user.setup_step === undefined) {
            const user_actions = new UserActions()
            user_actions.connectedUser()
            .then((user: LocalStorageUser) => {
                // Iterate over key-value pairs in user and update user localStorage
                Object.entries(user).forEach(([key, value]) => {
                    updateLocalStorageUser(key, value)
                })
                if (user)
                    setSetupStep(user.setup_step)
            })
        }
        else {
            const user = getLocalStorageUser()
            setSetupStep(user.setup_step)
        }
    }, [user, setSetupStep])

    useEffect(() => {
        if (!user?.is_email_verified)
            setIsEmailVerified(false)
        else
            setIsEmailVerified(true)
    }, [user, setIsEmailVerified])

    useEffect(() => {
        setSetupStep(user.setup_step)
        if (selected_user_type === UserType.SCHOOL_OPERATOR)
            steps_ref.current = [t('details_setup'), t('choose_a_user_type'), t('choose_school'), t('get_authorized')];

    }, [selected_user_type, user.setup_step, t, setSetupStep])

    useEffect(() => {
        if (setup_step === UserSetupStep.COMPLETED) {
            if (company_setup_step === CompanySetupStep.COMPLETED)
			    navigation('/dashboard')
            else 
			    navigation('/setup/franchise')
        }
    }, [setup_step, company_setup_step, navigation])

    const isChooseCompanySetup = (user: LocalStorageUser): boolean => {
        return !!user?.is_choose_company_setup;
    }

    const isUserFullyAuthorised = (user: LocalStorageUser): boolean => {
        return (
            user.master_admin_authorized === AuthorizedStatus.AUTHORIZED && 
            user.monkeynastix_authorized === AuthorizedStatus.AUTHORIZED && 
            user.operator_authorized === AuthorizedStatus.AUTHORIZED
        )
    }

    const isStepCompleted = (index: number): boolean => {
        const user = getLocalStorageUser()

        if (index === UserSetupStep.CHOOSE_COMPANY)
            return isChooseCompanySetup(user) 
        else if (index === UserSetupStep.GET_AUTHORIZED)
            return isUserFullyAuthorised(user)
        else
            return index < setup_step
    }

    return (
        <>
        {is_email_verified ?
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh'}}>
            <Box padding={3} mb={'auto'}>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    <MainHeadingTypography variant="h1"> {t('user_setup')} </MainHeadingTypography>
                    <BackToLogin/>
                </Box>
                <SelectedUserTypeContext.Provider value={{selected_user_type, setSelectedUserType}}>
                <Stack sx={{padding: '2rem 0' }} spacing={4}>
                    {is_small_screen ? 
                        <MobileStepper
                            setup_step={setup_step}
                            steps={steps}
                            GetStepComponent={GetStepComponent}
                            ColorlibStepIcon={ColorlibStepIcon}
                            stepCompleted={isStepCompleted}
                        /> 
                        : 
                        <DesktopStepper
                            setup_step={setup_step}
                            steps={steps}
                            GetStepComponent={GetStepComponent}
                            ColorlibStepIcon={ColorlibStepIcon}
                            stepCompleted={isStepCompleted}
                        />
                    }
                </Stack>
                </SelectedUserTypeContext.Provider>
            </Box>
            <SetupFooter/>
            </Box>
            : 
            <VerifiyEmail/>
        }
        </>
    )
}

export default SetupStepper