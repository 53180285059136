import { CRUDHandler } from "@adapters/handlers/Handlers/handlers"
import { PARENT_ENDPOINT } from "@adapters/routes/endpoints"


class ParentHandler extends CRUDHandler {
    endpoint: string

    constructor () {
        super()
        this.endpoint = PARENT_ENDPOINT
    }

    getParent (endpoint: string): Promise<any> {
        const new_edpoint = this.endpoint + '/' + endpoint
        return this.fetch_wrapper.get(new_edpoint)
    }

    get (page_size?: number, page?: number, filter?: object, group?: string): Promise<any> {
        return this.fetch_wrapper.get(this.endpoint, page, page_size, filter, group)
    }

    getById(id: string): Promise<any> {
        return this.fetch_wrapper.get(this.endpoint + id)
    }

    post (data: object): Promise<any> {
        return this.fetch_wrapper.post(this.endpoint, data)
    }

    put (data: object, id: string): Promise<any> {
        return this.fetch_wrapper.put(this.endpoint, data, id)
    }

    patch (data: object, id: string): Promise<any> {
        return this.fetch_wrapper.patch(this.endpoint, data, id)
    }

    delete (id: string): Promise<any> {
        return this.fetch_wrapper.delete(this.endpoint, id)
    }

    deleteMultiple (ids: Array<string>): Promise<any> {
        return this.fetch_wrapper.deleteMultiple(this.endpoint, ids)
    }
}

export default ParentHandler