import { NavigateFunction } from 'react-router-dom'

import GenericFetchWrapper from "@adapters/api/fetchWrappers/genericFetchWrapper"
import { JWT_LOGOUT_ENDPOINT } from "@adapters/routes/endpoints"
import { SetIsLoggedIn } from '@contexts/IsLoggedInContext'

import log from "loglevel"


const logout = async (navigate: NavigateFunction, setIsLoggedIn: SetIsLoggedIn, setLocalstorageUserExists: any, setLocalstorageCompanyExists: any): Promise<any> => {
    const fetch_wrapper = new GenericFetchWrapper()
    return fetch_wrapper.post(JWT_LOGOUT_ENDPOINT, {})
    .then(() => {
        setIsLoggedIn(false)
        setLocalstorageUserExists(false)
        setLocalstorageCompanyExists(false)

        try {
            const localstorage_has_installed_app = localStorage.getItem('has_installed_app')
            const has_installed_app = localstorage_has_installed_app ? JSON.parse(localstorage_has_installed_app) : false
            localStorage.clear()
            localStorage.setItem('has_installed_app', has_installed_app)
        } catch (error) {
            log.error("Error while clearing localStorage: ", error)
            throw new Error("Error while clearing localStorage: " + error)
        }

        navigate('/login')

        return "Successfullly logged out"
    })
    .catch(error => {
        log.error(error.message)
        throw new Error("Error logging out")
    })
}


// this logout method should only be used if navigate and setIsLoggedIn props can't be passed into function.
// logoutRisky can flash other application pages while user is in the process of being logged out!!
const logoutRisky = async (): Promise<any> => {
    try {
        const localstorage_has_installed_app = localStorage.getItem('has_installed_app')
        const has_installed_app = localstorage_has_installed_app ? JSON.parse(localstorage_has_installed_app) : false
        localStorage.clear()
        localStorage.setItem('has_installed_app', has_installed_app)
    } catch (error) {
        log.error("Error while clearing localStorage:", error)
        throw new Error("Error while clearing localStorage: " + error)
    }

    window.location.href = '/login'
}


export {
    logout,
    logoutRisky
}