import {useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import {Typography} from '@mui/material'
import SummarizeIcon from '@mui/icons-material/Summarize'

import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import { DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { DialogProps } from '@utils/dialogProps/DialogProps'
import { NumOfRequestsContext } from '@contexts/NumOfRequestsContext'
import ExcelReportActions from '@actions/ReportActions/excelReportActions'
import { convertDateToYYYYMMDD } from '@utils/utils/util'
import { downloadFile } from '@utils/files/blobs'


const CreateInstructorWeeklyScheduleReportDialog = (props: DialogProps) => {
	// disable the warning for not using state_manager in this dialog
	// eslint-disable-next-line  
	const {ids, state_manager, onClose, open} = props

	const { t } = useTranslation('t_shirt_reports')

	const {setNumOfRequests} = useContext(NumOfRequestsContext)

    const handleWeeklyScheduleReportCreate = async () => {
		setNumOfRequests(props => props + 1)

        const instructor_weekly_schedule_report_actions = new ExcelReportActions()

        try {
            const response = await instructor_weekly_schedule_report_actions.createInstructorWeeklyScheduleReport({instructor_ids: ids})
			setNumOfRequests(props => props - 1)

			let current_date = convertDateToYYYYMMDD(new Date())

			let filename = `Instructor Weekly Schedule ${current_date}.xlsx`
			downloadFile(response, filename)

        } catch (error) {
			console.error('Error generating Instructor Weekly Schedule Report:', error)
            setNumOfRequests(props => props - 1)
		}
    }

	const handleClose = () => {
		onClose()
	}

	return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> Weekly Schedule Report </INastixDialogTitle>
			<DialogDivider/>
            <Button variant='contained' onClick={handleWeeklyScheduleReportCreate} sx={{width: '100%', marginTop: '1rem'}}>{t('create')}</Button>
        </INastixDialog>
	)
}


const CreateInstructorWeeklyScheduleReporDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const { t } = useTranslation('t_shirt_reports')

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<SummarizeIcon/>}>
			<Typography> Weekly Schedule </Typography>
		</TableActionButon>
		<CreateInstructorWeeklyScheduleReportDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>
		</>
	)
}

export default CreateInstructorWeeklyScheduleReporDialogHandler