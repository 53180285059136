import {useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import SummarizeIcon from '@mui/icons-material/Summarize'

import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import { DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { DialogProps } from '@utils/dialogProps/DialogProps'
import { NumOfRequestsContext } from '@contexts/NumOfRequestsContext'
import { downloadFile } from '@utils/files/blobs'
import { convertDateToYYYYMMDD } from '@utils/utils/util'
import ExcelReportActions from '@actions/ReportActions/excelReportActions'
import SelectTermIndex from '@pages/Setup/SchoolSetup/SchoolFinanceSetup/SelectTermIndex'
import { CertificateDialogLocalstorageData } from '@components/dialogs/MemberCertificatesDialogHandler'
import SelectCountryTermsOnly from '@pages/Setup/SchoolSetup/SchoolFinanceSetup/SelectCountryTermOnly'


const ChildCertificatesReportDialog = (props: DialogProps) => {
	// disable the warning for not using state_manager in this dialog
	// eslint-disable-next-line  
	const {ids, state_manager, onClose, open} = props

	const certificate_dialog: CertificateDialogLocalstorageData | null = localStorage.getItem('certificate_dialog') ? JSON.parse(localStorage.getItem('certificate_dialog') as string) : null

	const {setNumOfRequests} = useContext(NumOfRequestsContext)

	const { t } = useTranslation('t_shirt_reports')

	const [country_term_id, setCountryTermId] = useState<number | 'All' | null>(certificate_dialog ? certificate_dialog.selected_country_term_id : null)
    const [term_index, setTermIndex] = useState<number | null>(certificate_dialog ? certificate_dialog.selected_term_index : null)

	const handleChildCertificatesReportCreate = async () => {
		setNumOfRequests(props => props + 1)

		const excel_report_actions = new ExcelReportActions()

		try {
			const response = await excel_report_actions.createChildCertificatesReport({school_ids: ids, selected_country_term_id: country_term_id, selected_term_index: term_index, report_type: 'school'})
			setNumOfRequests(props => props - 1)

			let current_date = convertDateToYYYYMMDD(new Date())

			let filename = `Certificate Report ${current_date}.xlsx`
			downloadFile(response, filename)

		} catch (error) {
			console.error('Error generating Child Certificates Report:', error)
			setNumOfRequests(props => props - 1)
		}
	}

	const handleClose = () => {
		onClose()
	}

	return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> Certificate Report </INastixDialogTitle>
			<DialogDivider/>
			<Box display='flex' gap={'2rem'}>
				<SelectCountryTermsOnly country_term_id={country_term_id} setCountryTermId={setCountryTermId}/>
				<SelectTermIndex country_term_id={country_term_id} term_index={term_index} setTermIndex={setTermIndex}/>
			</Box>
			<Button variant='contained' onClick={handleChildCertificatesReportCreate} sx={{width: '100%', marginTop: '1rem'}}>{t('create')}</Button>
        </INastixDialog>
	)
}


const ChildCertificatesReportDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<SummarizeIcon/>}>
			<Typography> Certificate Report</Typography>
		</TableActionButon>
		<ChildCertificatesReportDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>
		</>
	)
}

export default ChildCertificatesReportDialogHandler