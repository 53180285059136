import {useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag'
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'

import Pages from '@pages/Page'
import { PageHeadingTypography } from '@styles/Typography'
import { UserPermissions } from '@utils/enums/permissions'

import FzoneFilesPage from './Files'
import FzoneEcommercePage from './Ecommerce'
import FzoneEcommerceOrdersPage from './Orders'


const FzonePage = () => {
    const location = useLocation()

    const [tab_value, setTabValue] = useState('files')

    useEffect(() => {
        const query_params = new URLSearchParams(location.search)
        const tab_type = query_params.get('tab_type')

        if (tab_type)
            setTabValue(tab_type)
    }, [location.search])

    const handleChange = (event: React.SyntheticEvent, new_value: string) => {
        setTabValue(new_value)
    }

    const GetSelectedTabComponent = () => {
        if (tab_value === 'files') 
            return <FzoneFilesPage/>
        else if (tab_value === 'shop')
            return <FzoneEcommercePage/>
        else if (tab_value === 'orders')
            return <FzoneEcommerceOrdersPage/>
    }

	return (
		<Pages page_title="FZone" permission={UserPermissions.VIEW_FZONE}>
			<PageHeadingTypography> FZone </PageHeadingTypography>
            <Tabs
                value={tab_value}
                onChange={handleChange}
                aria-label="icon position tabs example"
                centered
                textColor="secondary"
                indicatorColor="secondary"
            >
                <Tab value="files" icon={<DriveFileMoveIcon />} iconPosition="start" label="Files"  />
                <Tab value="shop" icon={<ShoppingBagIcon />} iconPosition="start" label="Shop"/>
                <Tab value="orders" icon={<LocalShippingIcon />} iconPosition="start" label="Orders"/>
            </Tabs>
                {GetSelectedTabComponent()}
		</Pages>
	)
}

export default FzonePage
