import Table from '@components/tables/Table/Table'
import { useTranslation } from 'react-i18next'
import { PAID_INVOICES_STORAGE_KEY } from './const'
import InvoiceActions from '@actions/Finance/InvoiceActions/invoiceActions'


export interface TableProps {
	action: InvoiceActions
}

const PaidInvoicesTable = (props: TableProps) => {
	const {action} = props

	const { t } = useTranslation('overview')

	const columns = [
		{ field: 'id', headerName: 'ID' },
		{ field: 'child_name', headerName: t('child'), minWidth: 150, flex: 2 },
		{ field: 'parent_name', headerName: t('parent'), minWidth: 150, flex: 2 },
		{ field: 'school_name', headerName: t('school'), minWidth: 200, flex: 2 },
		{ field: 'amount', headerName: t('amount_paid'), minWidth: 150, flex: 1.5 },
		{ field: 'date_paid', headerName: t('date_paid'), minWidth: 120, flex: 2 },
		{ field: 'date_created', headerName: t('date_created'), minWidth: 150, flex: 1 },
		{ field: 'transaction_ref', headerName: t('reference'), minWidth: 150, flex: 2 },
	]

    return (
        <Table dialogs={[]} action={action} columns={columns} filter={{'paid': 'True'}}></Table>
    )
}

export default PaidInvoicesTable