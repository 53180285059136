import { useNavigate } from 'react-router-dom'

import Monkey500 from '@images/inastix/500_1.png'

import { useMediaQuery, Theme } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'


const Page500 = () => {
    const is_small_screen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

    const navigate = useNavigate()

    const handleClick = (endpoint: string) => {
        navigate(endpoint)
    }

    return (
        <Box display={'flex'} alignItems={'center'} height={'100vh'} flexWrap='wrap' justifyContent='space-between'>
            <Box sx={{flexGrow: 1, textAlign: 'center'}} marginTop={is_small_screen ? 4 : 0}>
                <img src={Monkey500} alt="500 Monkey" width="220px"/>
            </Box>
            <Box padding={5} sx={{margin: 'auto', flex: '1 0 60%', maxWidth:'60ch'}}>
                <Typography variant={'h1'} fontWeight={800} color={'darkslategray'}> Internal server error </Typography>
                <br/>
                <Typography variant={'h1'} fontWeight={700} color={'darkslategray'} gutterBottom> Oops, something went wrong </Typography>
                <Typography variant={'h5'} color={'darkslategray'}> Try refreshing the page or contact us if the problem persits. </Typography>
                <br/>
                <Typography color={'darkslategray'}> Go back to your dashboard or send us an email if this is an issue. </Typography>
                <br/>
                <Box display={'flex'} gap={2} justifyContent={'end'} sx={is_small_screen ? {justifyContent: 'center'} : {}}>
                    <Button variant='contained' onClick={() => handleClick('/dashboard')}> go to dashboard </Button>
                    <Button variant='contained' onClick={() => handleClick('/contact')}> contact us </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default Page500