import {useState} from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { CompanyMessageType } from '@actions/CRUDActions/NotificationActions/enum'
import Table from '@components/tables/Table/Table'
import CompanyNotificationActions from '@actions/CRUDActions/NotificationActions/companyNotifcationActions'

import log from 'loglevel'

import CompanyAcceptNotificationDialogHandler from './CompanyAcceptNotificationDialogHandler'
import CompanyDeclineNotificationDialogHandler from './DeclineNotificationDialogHandler'
import { AuthorisedFranchiseIdsContext, AuthorisedSchoolIdsContext, OpenSetRoylatiesDialogContext, OpenSetSchoolFinancesDialogContext } from './context'
import RoyaltiesDialog from './RoyaltiesDialog'
import SchoolFinanceDialog from './SchoolFinanceDialog'


export interface TableProps {
	action: CompanyNotificationActions
}

const CompanyNotificationTable = (props: TableProps) => {
	const {action} = props
	const { t } = useTranslation('company_notifications_table')

	const columns = [
		{ field: 'id', headerName: 'ID' },
		{ field: 'message', headerName: t('message'), flex: 1,
			renderCell: (params: any) => {
				console.log(params)
	
				const notification = params.row
				const message_type = notification.message_type
				let message = ""
				if (message_type === CompanyMessageType.AUTHORIZE_COMPANY || message_type === CompanyMessageType.AUTHORIZE_FRANCHISEE)
					message = "Authorise franchise"
				else if (message_type === CompanyMessageType.AUTHORIZE_SCHOOL)
					message = "Authorise school"
				else
					log.error(`message_type is ${message_type} which doesn't exist in MessageType`)

				const date_created = new Date(notification.date_created)
				const date: Date = new Date(date_created)
	
				const options: Intl.DateTimeFormatOptions = { day: "numeric", month: "long" }
				const formatted_date: string = date.toLocaleDateString("en-US", options)
	
				return (
					<Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
						<Typography sx={{width: 10}}> {notification.tenant_name} </Typography>
						<Typography width={5}> {message} </Typography>
						<Typography mr={2}> {formatted_date} </Typography>
					</Box>
				)
			},
		}
	]

	const [open_set_royalties_dialog, setOpenSetRoylatiesDialog] = useState(false)
	const [authorised_franchise_ids, setAuthorisedFranchiseIds] = useState<string[]>([])

	const [open_set_school_finances_dialog, setOpenSetSchoolFinancesDialog] = useState(false)
	const [authorised_school_ids, setAuthorisedSchoolIds] = useState<string[]>([])

    return (
		<OpenSetRoylatiesDialogContext.Provider value={{open_set_royalties_dialog, setOpenSetRoylatiesDialog}}>
		<OpenSetSchoolFinancesDialogContext.Provider value={{open_set_school_finances_dialog, setOpenSetSchoolFinancesDialog}}>
		<AuthorisedFranchiseIdsContext.Provider value={{authorised_franchise_ids, setAuthorisedFranchiseIds}}>
		<AuthorisedSchoolIdsContext.Provider value={{authorised_school_ids, setAuthorisedSchoolIds}}>
        	<Table dialogs={[CompanyDeclineNotificationDialogHandler, CompanyAcceptNotificationDialogHandler]} action={action} columns={columns}></Table>
			<RoyaltiesDialog open={open_set_royalties_dialog} setOpen={setOpenSetRoylatiesDialog}/>
			<SchoolFinanceDialog open={open_set_school_finances_dialog} setOpen={setOpenSetSchoolFinancesDialog}/>
		</AuthorisedSchoolIdsContext.Provider>
		</AuthorisedFranchiseIdsContext.Provider>
		</OpenSetSchoolFinancesDialogContext.Provider>
		</OpenSetRoylatiesDialogContext.Provider>
    )
}

export default CompanyNotificationTable