import { getLocalStorageCompany } from "@utils/localStorage/company"

import ChooseCompanyType from "../ChooseCompanyType"
import CompanyDetails from "../CompanyDetails"
import FinanceAndTerms from "../FinanceAndTerms"
import GetCompanyAuthorized from "../GetCompanyAuthorized"
import XeroManagementSetup from "../XeroManagementSetup"


const GetStepComponent = (active_step: number) => {
    const company = getLocalStorageCompany()

    switch (active_step) {
        case 0:
            return <CompanyDetails/>
        case 1:
            return <ChooseCompanyType/>
        case 2:
            return <FinanceAndTerms/>
        case 3:
            return company.is_xero_company ? <XeroManagementSetup/> : <GetCompanyAuthorized/>
        case 4:
            return <GetCompanyAuthorized/>
        default:
            return 'Unknown stepIndex'
    }
}

export default GetStepComponent