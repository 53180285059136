import {useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import EditIcon from '@mui/icons-material/Edit'

import StateManager from '@actions/StateManager/stateManager'
import { FormComponent, FormDataEntryType } from '@components/forms/Form'
import trackRequests from '@adapters/helpers/trackRequests'
import { NumOfRequestsContext } from '@contexts/NumOfRequestsContext'
import { SetTableSelections } from '@components/tables/CRUD/CRUDTable'
import { TableActionButon } from '@styles/Buttons'
import { DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { FormDialogProps } from '@utils/dialogProps/DialogProps'
import { MessageContext } from '@contexts/MessageContext'


const EditDialog = (props: FormDialogProps) => {
	const {Form, selectedObject, state_manager, setTableSelections, onClose, open} = props
    const { t } = useTranslation('tables')

	const {setNumOfRequests} = useContext(NumOfRequestsContext)

	const handleEditSubmit = async (form_data_object: FormDataEntryType): Promise<any> => {
		if (!form_data_object.id)
			throw new Error("There is no id in the form data object being posted. Add an id field")

		const promise = state_manager.setUpdateAction(form_data_object, form_data_object.id.toString())
		return trackRequests(promise, setNumOfRequests)
		.then(() => {
			setTableSelections({
				row_selection_model: [],
				selections: []
			})
			handleClose()  // close the dialog if the promise resolved
			return {message: t('updated_successfully')}
		})
	}

	const handleClose = () => {
		onClose()
	}

	const form_component = Form(handleEditSubmit, selectedObject)

	return (
		open ? (
			<INastixDialog onClose={handleClose} open={open}>
				<INastixDialogTitle> {t('edit')} </INastixDialogTitle>
				<DialogDivider/>
				<br/>
				{form_component}
			</INastixDialog>
		) : <></>
	)
}

interface MyComponentProps {
	disabled: boolean
    Form: FormComponent
	selectedObject: object
	state_manager: StateManager
	setTableSelections: SetTableSelections
}


const EditDialogHandler = (props: MyComponentProps) => {
	const {disabled, Form, selectedObject, state_manager, setTableSelections} = props
    const { t } = useTranslation('tables')

	const [open, setOpen] = useState(false)
	const {setMessage} = useContext(MessageContext)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setMessage({'success': "", 'error': "", 'form_error': "", 'info': "", 'warning': ""})
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<EditIcon/>}>
			{t('edit')}
		</TableActionButon>
		<EditDialog Form={Form} selectedObject={selectedObject} state_manager={state_manager} setTableSelections={setTableSelections} open={open} onClose={handleClose}/>
		</>
	)
}

export default EditDialogHandler