import { XERO_ACCOUNTS_ROYALTIES_ENDPOINT } from "@adapters/routes/endpoints"
import { GenericHandler } from "../../Handlers/handlers"


class XeroAccountCodesRoyaltiesHandler extends GenericHandler {
    constructor (endpoint=XERO_ACCOUNTS_ROYALTIES_ENDPOINT) {
        super(endpoint)
    }
}

export default XeroAccountCodesRoyaltiesHandler