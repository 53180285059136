import {useEffect, useState, useContext} from 'react'

import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { useMediaQuery, Theme} from '@mui/material'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Button from '@mui/material/Button'

import Pages from '@pages/Page'
import { MessageContext } from '@contexts/MessageContext'
import UserActions from '@actions/CRUDActions/UserActions/userActions'
import {getLocalStorageDashboardConfig, setLocalStorageDashboardConfig, updateLocalStorageDashboardConfig } from '@utils/localStorage/dashboard_config'
import { companyIsInGroup, createListOfMonkeynastixTypes, isInGroup, permissionValid } from '@utils/utils/util'
import { getLocalStorageUser, updateLocalStorageUser } from '@utils/localStorage/user'
import FranchiseeActions from '@actions/CRUDActions/FranchiseeActions/franchiseeActions'
import  {Franchisee, LocalStorageDashboardConfig } from '@utils/interfaces/interfaces'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import { CompanySetupStep, CompanyType, UserSetupStep, UserType } from '@utils/enums/enums'
import { LocalstorageCompanyExistsContext } from '@contexts/LocalstorageCompanyExistsContext'
import { LocalstorageUserExistsContext } from '@contexts/LocalstorageUserExistsContext'
import { SchoolMap } from '@utils/maps/maps'

import DashboardNav from './DashboardNav'
import BirthdaysStat from './stats/BirthdaysStat'
import ChildAgeGroups from './stats/ChildAgeGroups'
import InstructorsVsClasses from './stats/InstructorsVsClasses'
import ChildTotal from './stats/ChildTotal'
import ChildrenCertificatesStat from './stats/ChildrenCertificates'
import MemberRegistrations from './stats/MemberRegistrations'
import ChildrenAttritionRateNumbersStat from './stats/ChildrenAttritionRateNumbersStat'
import ChildrenAttritionRatePercentageStat from './stats/ChildrenAttritionRatePercentageStat'
import ChildTShirtGroups from './stats/ChildTShirtGroups'
import InstructorTShirtGroups from './stats/InstructorTShirtGroups'
import ChildStatuses from './stats/ChildStatuses'
import ReportingStat from './stats/ReportingStat'


export type FranchiseOption = {
	id: string
	tenant_name: string
	tenant_group: string
	is_babynastix: boolean
	is_monkeynastix: boolean
	is_supernastix: boolean
}

export interface SchoolTypeOption {
	value: string
	label: string
}

interface DashboardConfig {
	show_children_grouped_by_age: boolean
	show_children_grouped_by_tshirts: boolean
	show_instructors_grouped_by_tshirts: boolean
	show_children_total: boolean
	show_children_statuses: boolean
	show_children_certificates: boolean
	show_term_to_term_growth: boolean
	show_birthdays_stat: boolean
	show_instructors_vs_classes: boolean
	show_registered_to_allocated: boolean
	show_registered_to_authorised: boolean
	show_registered_vs_authorised: boolean
	show_num_franchisees: boolean
	show_children_attrition_numbers: boolean
	show_children_attrition_percentage: boolean
	show_reporting: boolean
}

interface VisibleStats {
	child_age_groups: boolean
	child_tshirt_groups: boolean,
	instructor_tshirt_groups: boolean,
	children_total: boolean
	children_statuses: boolean
	children_certificates: boolean
	term_to_term: boolean
	birthdays_stat: boolean
	instructors_vs_classes: boolean
	registered_to_allocated: boolean
	registered_to_authorised: boolean
	registered_vs_authorised: boolean
	num_franchisees: boolean
	children_attrition_numbers: boolean
	children_attrition_percentage: boolean
	reporting: boolean
}


const init_stats = {
	child_age_groups: false,
	child_tshirt_groups: false,
	instructor_tshirt_groups: false,
	children_total: false,
	children_statuses: false,
	children_certificates: false,
	term_to_term: false,
	birthdays_stat: false,
	instructors_vs_classes: false,
	registered_to_allocated: false,
	registered_to_authorised: false,
	registered_vs_authorised: false,
	num_franchisees: false,
	children_attrition_numbers: false,
	children_attrition_percentage: false,
	reporting: false
}


const DashboardPage = () => {
    const is_small_screen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
	
	const user = getLocalStorageUser()

	const navigation = useNavigate()
    const { t } = useTranslation('dashboard')

    const {setMessage} = useContext(MessageContext)
	const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)
	const {local_storage_company_exists} = useContext(LocalstorageCompanyExistsContext)
	const {local_storage_user_exists} = useContext(LocalstorageUserExistsContext)

	const [edit_dashboard_open, setEditDashboardOpen] = useState(false)
	const [show_using_demo_company_card, setShowUsingDemoCompanyCard] = useState(true)

	const [visibleStats, setVisibleStats] = useState<VisibleStats>(init_stats)
	const [checkedStats, setCheckedStats] = useState(init_stats)

	const [selected_franchise_option, setSelectedFranchiseOption] = useState<FranchiseOption | null>(null)
	const [franchise_options, setFranchiseOptions] = useState<FranchiseOption[] | null>(null)
	const [selected_school_type_options, setSelectedSchoolTypeOptions] = useState<SchoolTypeOption[] | null>(null)
	const [school_type_options, setSchoolTypeOptions] = useState<SchoolTypeOption[] | null>(null)

	const company = getLocalStorageCompany()
	const is_school = companyIsInGroup(company, CompanyType.SCHOOL)
	const is_franchisor = companyIsInGroup(company, CompanyType.FRANCHISOR)
	const is_master_franchise_and_doesnt_act_like_franchise = !company.acts_like_a_franchise && companyIsInGroup(company, CompanyType.MASTER_FRANCHISE)

	type DashboardSetting = {
		key: string
		visibleStateKey: keyof typeof visibleStats
		checkedStateKey: keyof typeof checkedStats
	}

	// append '/dashboard' to the end of the url if we don't have any string there
	useEffect(() => {
		const url = window.location.pathname
		if (url.search('dashboard'))
			navigation('/dashboard')
	}, [navigation]) 

	useEffect(() => {
		if (!local_storage_user_exists || !local_storage_company_exists)
			return

		if (!Object.keys(company).length)
			return 

		const is_school_operator = isInGroup(user, company, UserType.SCHOOL_OPERATOR)

		if (user.setup_step !== UserSetupStep.COMPLETED)
			navigation('/setup/user')
		else if (!is_school_operator && company.setup_step !== CompanySetupStep.COMPLETED)
			navigation('/setup/franchise')
		else if (is_school_operator && company.setup_step !== CompanySetupStep.COMPLETED)
			navigation('/setup/school')
		else if (!permissionValid(user, 'view_userdashboardsettingsmodel'))
			navigation('/classes')
	}, [navigation, company, user, local_storage_company_exists, local_storage_user_exists])

	useEffect(() => {
		if (!local_storage_user_exists || !local_storage_company_exists)
			return

		if (!franchise_options)
			return			

		const connected_company_franchise: FranchiseOption = {
			id: company.company_uuid.toString(),
			tenant_name: company.tenant_name.toString(),
			tenant_group: company.groups[0].name,
			is_babynastix: company.is_babynastix,
			is_monkeynastix: company.is_monkeynastix,
			is_supernastix: company.is_supernastix
		}
		setSelectedFranchiseOption(connected_company_franchise)

	}, [franchise_options, local_storage_company_exists, local_storage_user_exists])

	useEffect(() => {
		if (!local_storage_user_exists || !local_storage_company_exists)
			return

        const setInitialFranchiseOptions = async () => {
			const franchisee_actions = new FranchiseeActions()
			const franchises_data: Franchisee[] = await franchisee_actions.get(undefined, undefined, {'tenant': company.company_uuid})

			let parse_initial_franchise_options: FranchiseOption[] | undefined
			
			if (is_franchisor || is_master_franchise_and_doesnt_act_like_franchise) {
				parse_initial_franchise_options = franchises_data.map(franchise => ({
					id: franchise.id,
					tenant_name: franchise.tenant_name,
					tenant_group: franchise.groups[0].name,
					is_babynastix: franchise.is_babynastix,
					is_monkeynastix: franchise.is_monkeynastix,
					is_supernastix: franchise.is_supernastix
				}))
			}

			const parse_franchise_options: FranchiseOption[] = [{
				id: company.company_uuid,
				tenant_name: company.tenant_name,
				tenant_group: company.groups[0] ? company.groups[0].name : "",
				is_babynastix: company.is_babynastix,
				is_monkeynastix: company.is_monkeynastix,
				is_supernastix: company.is_supernastix
			}]
			
			if (parse_initial_franchise_options) {
				parse_franchise_options.push(...parse_initial_franchise_options)
			}

			setFranchiseOptions(parse_franchise_options)
        }

        setInitialFranchiseOptions()
	},  [change_company_rerender, local_storage_company_exists, local_storage_user_exists])
	
	const dashboardSettings: DashboardSetting[] = [
		{ key: 'show_children_grouped_by_age', visibleStateKey: 'child_age_groups', checkedStateKey: 'child_age_groups' },
		{ key: 'show_children_grouped_by_tshirts', visibleStateKey: 'child_tshirt_groups', checkedStateKey: 'child_tshirt_groups' },
		{ key: 'show_instructors_grouped_by_tshirts', visibleStateKey: 'instructor_tshirt_groups', checkedStateKey: 'instructor_tshirt_groups' },
		{ key: 'show_children_total', visibleStateKey: 'children_total', checkedStateKey: 'children_total' },
		{ key: 'show_children_statuses', visibleStateKey: 'children_statuses', checkedStateKey: 'children_statuses' },
		{ key: 'show_children_certificates', visibleStateKey: 'children_certificates', checkedStateKey: 'children_certificates' },
		{ key: 'show_term_to_term_growth', visibleStateKey: 'term_to_term', checkedStateKey: 'term_to_term' },
		{ key: 'show_birthdays_stat', visibleStateKey: 'birthdays_stat', checkedStateKey: 'birthdays_stat' },
		{ key: 'show_instructors_vs_classes', visibleStateKey: 'instructors_vs_classes', checkedStateKey: 'instructors_vs_classes' },
		{ key: 'show_registered_to_allocated', visibleStateKey: 'registered_to_allocated', checkedStateKey: 'registered_to_allocated' },
		{ key: 'show_registered_to_authorised', visibleStateKey: 'registered_to_authorised', checkedStateKey: 'registered_to_authorised' },
		{ key: 'show_registered_vs_authorised', visibleStateKey: 'registered_vs_authorised', checkedStateKey: 'registered_vs_authorised' },
		{ key: 'show_num_franchisees', visibleStateKey: 'num_franchisees', checkedStateKey: 'num_franchisees' },
		{ key: 'show_children_attrition_numbers', visibleStateKey: 'children_attrition_numbers', checkedStateKey: 'children_attrition_numbers' },
		{ key: 'show_children_attrition_percentage', visibleStateKey: 'children_attrition_percentage', checkedStateKey: 'children_attrition_percentage' },
		{ key: 'show_reporting', visibleStateKey: 'reporting', checkedStateKey: 'reporting' }
	]

	useEffect(() => {
		if (!local_storage_user_exists || !local_storage_company_exists) return
	
		const dashboard_config = getLocalStorageDashboardConfig() as LocalStorageDashboardConfig
	
		const applySettings = (data: LocalStorageDashboardConfig) => {
			setLocalStorageDashboardConfig(data)
	
			dashboardSettings.forEach(setting => {
				setVisibleStats(prev => ({ ...prev, [setting.visibleStateKey]: data[setting.key as keyof LocalStorageDashboardConfig] }))
				setCheckedStats(prev => ({ ...prev, [setting.checkedStateKey]: data[setting.key as keyof LocalStorageDashboardConfig] }))
			})
		}
	
		if (!dashboard_config) {
			const user_actions = new UserActions()
			user_actions.getDashboardSettings()
				.then(data => {
					applySettings(data)
				})
				.catch(error => {
					setMessage(props => ({ ...props, error: error.message }))
				})
		} else {
			applySettings(dashboard_config)
		}
	}, [local_storage_company_exists, local_storage_user_exists])
	

	useEffect(() => {
		if (selected_franchise_option) {
		  	const monkeynastix_types = createListOfMonkeynastixTypes(selected_franchise_option, false)
	
			const filtered_keys = Object.keys(SchoolMap).filter((key) => monkeynastix_types.includes(key))
			const filteredSchoolMap: typeof SchoolMap = filtered_keys.reduce((acc: typeof SchoolMap, key: string) => {
				acc[key] = SchoolMap[key]
				return acc
			}, {})
		
			const options = Object.keys(filteredSchoolMap).map((key) => ({
				value: key,
				label: filteredSchoolMap[key]
			}))

		  	setSchoolTypeOptions(options)
			setSelectedSchoolTypeOptions(options)
		}
	}, [selected_franchise_option])

	const handleSaveChanges = () => {
		setMessage(props => ({ ...props, success: t('changes_saved') }))
		setEditDashboardOpen(false)
	
		const dashboard_config = getLocalStorageDashboardConfig() as LocalStorageDashboardConfig
	
		dashboardSettings.forEach(setting => {
			dashboard_config[setting.key as keyof DashboardConfig] = checkedStats[setting.checkedStateKey]
		})
	
		const user_actions = new UserActions()
		user_actions.postDashboardSettings(dashboard_config)
			.then(() => {
				const newVisibleStats: Partial<VisibleStats> = {}

				dashboardSettings.forEach(setting => {
					newVisibleStats[setting.visibleStateKey as keyof VisibleStats] = !!dashboard_config[setting.key as keyof LocalStorageDashboardConfig]
					updateLocalStorageDashboardConfig(setting.key, dashboard_config[setting.key as keyof LocalStorageDashboardConfig])
				})

				setVisibleStats(prev => ({ ...prev, ...newVisibleStats }))
			})
			.catch(error => {
				setMessage(props => ({ ...props, error: error.message }))
			})
	}

	const handleFranchiseOptionChange = async (franchise_option: FranchiseOption | null) => {
		if (!franchise_option)
			return 

		setSelectedFranchiseOption(franchise_option)
	}

	const handleCloseShowUsingDemoCompanyCard = () => {
		const user_actions = new UserActions()
		user_actions.update({'show_using_demo_company_card': false}, user.id.toString())
		.then(() => {
			updateLocalStorageUser('show_using_demo_company_card', false)
			setShowUsingDemoCompanyCard(false)
		})
	}

	const onSelectedSchoolTypeOptions = (event: any, value: SchoolTypeOption[] | null) => {
		setSelectedSchoolTypeOptions(value)
    }

	const stat_template_styles = is_small_screen ? {width: '85%', flex: '1 1 100%'} : {flex: '1 1 100%'}

	// const cropCompanyName = (tenant_name: string) => {
	// 	// remove 'monkeynastix' from company name to shorten it so it can be seen in smaller screens 
	// 	if (tenant_name.length > 25)
	// 		return tenant_name.replace(/monkeynastix /i, "")
	// 	return tenant_name
	// }

	const showing_results_for_label = selected_franchise_option?.tenant_group === CompanyType.FRANCHISOR ?
		"Franchisor"
		: selected_franchise_option?.tenant_group === CompanyType.MASTER_FRANCHISE ? 
			"Master Franchise" 
			: t('franchisee')

	const selected_school_type_options_parsed = selected_school_type_options ? selected_school_type_options.map(selected_school_type_option => selected_school_type_option.value) : null

	return (
		<>
		<Pages page_title="Dashboard">
			<>
			<DashboardNav 
				edit_dashboard_open={edit_dashboard_open} 
				setEditDashboardOpen={setEditDashboardOpen} 
				handleSaveChanges={handleSaveChanges}
			/>
				<Typography variant="h1" marginTop={'1rem'} gutterBottom fontWeight={500} color={'darkslategray'}> {t('dashboard')} </Typography>
				{user.show_using_demo_company_card && company.is_demo_company && show_using_demo_company_card &&
					<Card sx={{ minWidth: 275, marginBottom:'1.5rem'}}>
						<CardContent>
							<Typography mb={'.5rem'} fontSize={'1.5rem'} color={'#0078c8'} fontWeight={500}> {t('get_to_know_inastix')} </Typography>
							<Typography mb={'.5rem'} color={'darkslategray'} fontWeight={500}> {t('using_a_demo_company')} </Typography>
							<Typography> {t('this_data_is_fictional')} </Typography>
						</CardContent>
						<CardActions>
							<Button sx={{marginLeft: 'auto'}} size="small" onClick={handleCloseShowUsingDemoCompanyCard}>{t('close')}</Button>
						</CardActions>
					</Card>
				}
				<Box sx={{
					display: 'flex', gap: '2rem', 
					marginTop: (franchise_options && franchise_options.length > 1) || (school_type_options && school_type_options.length > 1) ? '2rem': '', 
					justifyContent: 'center', flexWrap: 'wrap'
				}}>
					{franchise_options && franchise_options.length > 1 ?
						<>
						{/* <Typography variant="h6" fontWeight={'300'} gutterBottom> {t('showing_results_for')} </Typography> */}
						<Autocomplete
							value={selected_franchise_option}
							onChange={(event, new_value) => handleFranchiseOptionChange(new_value)}
							options={franchise_options}
							getOptionLabel={(option) => option.tenant_name}
							sx={{
								width: selected_franchise_option?.tenant_name ? `${selected_franchise_option.tenant_name.length + 13}ch` : '35ch', 
							}}
							renderInput={(params) => (
								<TextField
									{...params}
									label={showing_results_for_label}
								/>
							)}
							isOptionEqualToValue={(option, value) => option.id === value.id}
						/>
						</>
						: null 
					}
					{school_type_options && school_type_options.length > 1 && 
						<Autocomplete
							multiple
							options={school_type_options}
							getOptionLabel={(option) => option.label}
							value={selected_school_type_options ? selected_school_type_options : []}
							onChange={onSelectedSchoolTypeOptions}
							isOptionEqualToValue={(option, value) => option.value === value.value}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Franchise types"
								/>
							)}
							sx={{width: 300}}
							size="small"
						/>
					}
				</Box>
			<br/><br/>
			<Grid container spacing={2} justifyContent={'center'}>
				{(edit_dashboard_open || visibleStats.child_age_groups) && 
					<Grid item md={6} sx={stat_template_styles}>
						<ChildAgeGroups 
							checked={checkedStats.child_age_groups} 
							setChecked={(value) => setCheckedStats(props => ({...props, child_age_groups: value }))} 
							edit_dashboard_open={edit_dashboard_open}
							selected_franchise_option={selected_franchise_option}
							selected_school_type_options={selected_school_type_options_parsed}
						/>
					</Grid>	
				}
				{(edit_dashboard_open || visibleStats.child_tshirt_groups) && 
					<Grid item md={6} sx={stat_template_styles}>
						<ChildTShirtGroups 
							checked={checkedStats.child_tshirt_groups} 
							setChecked={(value) => setCheckedStats(props => ({...props, child_tshirt_groups: value }))} 
							edit_dashboard_open={edit_dashboard_open}
							selected_franchise_option={selected_franchise_option}
							selected_school_type_options={selected_school_type_options_parsed}
						/>
					</Grid>	
				}
				{(edit_dashboard_open || visibleStats.children_total) && 
					<Grid item md={6} sx={{...stat_template_styles, maxWidth: '280px !important'}}>
						<ChildTotal 
							checked={checkedStats.children_total} 
							setChecked={(value) => setCheckedStats(props => ({...props, children_total: value }))} 
							edit_dashboard_open={edit_dashboard_open}
							selected_franchise_option={selected_franchise_option}
							selected_school_type_options={selected_school_type_options_parsed}
						/>
					</Grid>	
				}
				{(edit_dashboard_open || visibleStats.children_statuses) && 
					<Grid item md={6} sx={{...stat_template_styles, maxWidth: '280px !important'}}>
						<ChildStatuses
							checked={checkedStats.children_statuses}
							setChecked={(value) => setCheckedStats(props => ({...props, children_statuses: value }))}
							edit_dashboard_open={edit_dashboard_open}
							selected_franchise_option={selected_franchise_option}
							selected_school_type_options={selected_school_type_options_parsed}
						/>
					</Grid>						
				}
				{(edit_dashboard_open || visibleStats.children_certificates) && 
					<Grid item md={6} sx={{...stat_template_styles, maxWidth: '280px !important'}}>
						<ChildrenCertificatesStat 
							checked={checkedStats.children_certificates} 
							setChecked={(value) => setCheckedStats(props => ({...props, children_certificates: value }))} 
							edit_dashboard_open={edit_dashboard_open}
							selected_franchise_option={selected_franchise_option}
							selected_school_type_options={selected_school_type_options_parsed}
						/>
					</Grid>	
				}
				{(edit_dashboard_open || visibleStats.reporting) &&
					<Grid item md={6} sx={stat_template_styles}>
						<ReportingStat
							checked={checkedStats.reporting}
							setChecked={(value) => setCheckedStats(props => ({...props, reporting: value}))}
							edit_dashboard_open={edit_dashboard_open}
							selected_franchise_option={selected_franchise_option}
						/>
					</Grid>
				}
				{(edit_dashboard_open || visibleStats.instructor_tshirt_groups) && 
					<Grid item md={6} sx={stat_template_styles}>
						<InstructorTShirtGroups 
							checked={checkedStats.instructor_tshirt_groups} 
							setChecked={(value) => setCheckedStats(props => ({...props, instructor_tshirt_groups: value }))} 
							edit_dashboard_open={edit_dashboard_open}
							selected_franchise_option={selected_franchise_option}
							selected_school_type_options={selected_school_type_options_parsed}
						/>
					</Grid>	
				}
				{(edit_dashboard_open || visibleStats.term_to_term) && 			
					<Grid item md={6} sx={stat_template_styles}>
						<MemberRegistrations 
							checked={checkedStats.term_to_term} 
							setChecked={(value) => setCheckedStats(props => ({...props, term_to_term: value }))} 
							edit_dashboard_open={edit_dashboard_open}
							selected_franchise_option={selected_franchise_option}
							selected_school_type_options={selected_school_type_options_parsed}
						/>
					</Grid>	
				}
				{(edit_dashboard_open || visibleStats.children_attrition_numbers) && 			
					<Grid item md={6} sx={stat_template_styles}>
						<ChildrenAttritionRateNumbersStat  
							checked={checkedStats.children_attrition_numbers} 
							setChecked={(value) => setCheckedStats(props => ({...props, children_attrition_numbers: value }))} 
							edit_dashboard_open={edit_dashboard_open}
							selected_franchise_option={selected_franchise_option}
							selected_school_type_options={selected_school_type_options_parsed}
						/>
					</Grid>	
				}
				{(edit_dashboard_open || visibleStats.children_attrition_percentage) && 			
					<Grid item md={6} sx={stat_template_styles}>
						<ChildrenAttritionRatePercentageStat
							checked={checkedStats.children_attrition_percentage} 
							setChecked={(value) => setCheckedStats(props => ({...props, children_attrition_percentage: value }))} 
							edit_dashboard_open={edit_dashboard_open}
							selected_franchise_option={selected_franchise_option}
							selected_school_type_options={selected_school_type_options_parsed}
						/>
					</Grid>	
				}
				{!is_school && (edit_dashboard_open || visibleStats.instructors_vs_classes) && 
					<Grid item md={6} sx={stat_template_styles}>
						<InstructorsVsClasses 
							checked={checkedStats.instructors_vs_classes} 
							setChecked={(value) => setCheckedStats(props => ({...props, instructors_vs_classes: value }))} 
							edit_dashboard_open={edit_dashboard_open}
							selected_franchise_option={selected_franchise_option}
						/>
					</Grid>	
				}
				{(edit_dashboard_open || visibleStats.birthdays_stat) && 			
					<Grid item md={6} sx={stat_template_styles}>
						<BirthdaysStat 
							checked={checkedStats.birthdays_stat} 
							setChecked={(value) => setCheckedStats(props => ({...props, birthdays_stat: value }))} 
							edit_dashboard_open={edit_dashboard_open}
							selected_franchise_option={selected_franchise_option}
							selected_school_type_options={selected_school_type_options_parsed}
						/>
					</Grid>	
				}
			</Grid>
			</>
			{/* add new chart visibility here to toggle no data message */}

		</Pages>
		</>

	)
}

export default DashboardPage
