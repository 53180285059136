import {useState, useContext, useEffect} from 'react'
import { useTranslation } from 'react-i18next'

import Typography from '@mui/material/Typography'
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser'
import Dialog from '@mui/material/Dialog'

import NotificationActions from '@actions/CRUDActions/NotificationActions/notifcationActions'
import { NumOfNotificationsContext } from '@contexts/NumOfNotificationsContext'
import { DialogButton, DialogDivider, INastixDialogTitle } from '@styles/Dialog'
import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import { MessageContext } from '@contexts/MessageContext'
import { MessageType } from '@actions/CRUDActions/NotificationActions/enum'
import {DialogProps} from '@utils/dialogProps/DialogProps'
import INastixTableContainer from '@components/tables/Table/INastixTableContainer'

import { OpenInstructorFinanceDialogContext } from './OpenInstructorFinanceDialogContext'
import { AuthorisedInstructorIdsContext } from './AuthorisedInstructorIdsContext'
import useNotificationInfo from './useNotificationInfo'


const AcceptNotificationDialog = (props: DialogProps) => {
	const {ids, state_manager, onClose, open} = props
	
	const { t: tNotifications } = useTranslation('notifications_table')
	const { t: tUserGroups } = useTranslation('user_groups')
	
	const {setNumOfNotifications} = useContext(NumOfNotificationsContext)
	const {setMessage} = useContext(MessageContext)
	const {setOpenInstructorFinanceDialog} = useContext(OpenInstructorFinanceDialogContext)
	const {setAuthorisedInstructorIds} = useContext(AuthorisedInstructorIdsContext)

	const {user_info, table_caption} = useNotificationInfo(ids, state_manager)
	
	const handleAuthorize= () => {
		const notification_actions = new NotificationActions()
		notification_actions.authorize_users(ids)
		.then(() => {
			setNumOfNotifications(props => props - ids.length)  // taking the total num of notifications and minusing it by the length of the deleted notifications
	
			const relevant_notifications = state_manager.data.filter(notification => ids.includes(notification.id))
			const instructor_ids = relevant_notifications
				.filter(notification => [MessageType.AUTHORIZE_INSTRUCTOR, MessageType.AUTHORIZE_ASSISTANT].includes(notification.message_type))
				.map(notification => notification.instructor_id)
	
			if (instructor_ids.length) {
				setAuthorisedInstructorIds(instructor_ids)
				setOpenInstructorFinanceDialog(true)
			}

			if (ids.length > 1)
				setMessage(props => ({...props, success: tNotifications('users_authorized')}))
			else
				setMessage(props => ({...props, success: tNotifications('user_authorized')}))

			state_manager.setDeleteMultipleAction(ids)
			handleClose()
		})
	}

	const handleClose = () => {
		onClose()
	}

	return (
		<Dialog onClose={handleClose} open={open} sx={{'.MuiPaper-root': {padding: '0 1rem 1rem 1rem'}}}>
			<INastixDialogTitle> {tNotifications('confirm_authorisation')}</INastixDialogTitle>
			<DialogDivider/>
			{user_info  && <INastixTableContainer table_row_data={user_info} transFunc1={tNotifications} transFunc2={tUserGroups} caption={table_caption}/>}
			<DialogButton variant='contained' onClick={handleAuthorize}> {tNotifications('authorise')} </DialogButton>
		</Dialog>
	)
}


const AcceptNotificationDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props
	const [isDisabled, setIsDisabled] = useState(disabled)

	const [open, setOpen] = useState(false)

	const { t } = useTranslation('notifictions_table')

	useEffect(() => {
		if (ids.length === 1) {
			setIsDisabled(false)
		}
		else if(ids.length < 1 || ids.length > 1) {
			setIsDisabled(true)
		}
	}, [ids])
	
	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={isDisabled} onClick={handleClickOpen} startIcon={<VerifiedUserIcon/>} border_color={'mediumseagreen'}>
			<Typography> {t('authorise')} </Typography>
		</TableActionButon>
		<AcceptNotificationDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>
		</>
	)
}

export default AcceptNotificationDialogHandler