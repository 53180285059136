import {useEffect, useState} from 'react'

import Typography from '@mui/material/Typography'
import { TreeViewBaseItem } from '@mui/x-tree-view'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListSubheader from '@mui/material/ListSubheader'

import FZoneActions from '@actions/FZoneActions/fZoneActions'
import INastixRichTreeView from '@components/navigation/INastixTreeView'
import { SchoolType } from '@utils/enums/enums'
import { Box } from '@mui/system'
import { getLocalStorageCompany } from '@utils/localStorage/company'


const convertCategoriesToTreeViewItems = (categories: Category[], parentId: number | null): TreeViewBaseItem[] => {
    return categories
        .filter(category => category.parent_category === parentId)
        .map(category => ({
            id: String(category.id),
            label: category.category_name,
            children: [
				...convertCategoriesToTreeViewItems(categories, category.id),
				...category.files.map(file => {
                    let file_type
                    if (file.file.includes('.mp4'))
                        file_type = 'video'
                    else if (file.file.includes('.mp3'))
                        file_type = 'music'
                    else
                        file_type = 'file'

                    return {
                        id: `${file_type};${file.file};${file.id}`, 
                        label: file.file_name,
                    }
                }),
                ...category.live_links.map(live_link => ({
					id: `live_link;${live_link.link};${live_link.id}`, 
					label: live_link.link_name,
				}))
			]
        }))
}


interface FileUpload {
	id: number
	category_fzone_model: number
	file: string
	file_name: string
}

interface LiveLinkUpload {
    id: number
	category_fzone_model: number
    link_name: string
    link: string
}

interface Category {
    id: number
    category_name: string
    child_categories: number[]
    parent_category: number | null
    program_type: SchoolType
	files: FileUpload[]
    live_links: LiveLinkUpload[]
}

const FzoneFilesPage = () => {
    const connected_company = getLocalStorageCompany()

    const [tree_view_categories_monkeynastix, setTreeViewCategoriesMonkeynastix] = useState<TreeViewBaseItem[]>([])
    const [tree_view_categories_babynastix, setTreeViewCategoriesBabynastix] = useState<TreeViewBaseItem[]>([])
    const [tree_view_categories_supernastix, setTreeViewCategoriesSupernastix] = useState<TreeViewBaseItem[]>([])

    useEffect(() => {
        const fzone_actions = new FZoneActions()
        fzone_actions.viewFzone()
        .then((categories: Category[]) => {
            console.log(categories)
            const tree_view_categories_babynastix = connected_company.is_babynastix ? categories.filter(category => category.program_type === SchoolType.BABYNASTIX) : []
            const tree_view_categories_monkeynastix = connected_company.is_monkeynastix ? categories.filter(category => category.program_type === SchoolType.MONKEYNASTIX) : []
            const tree_view_categories_supernastix = connected_company.is_supernastix ? categories.filter(category => category.program_type === SchoolType.SUPERNASTIX) : []

			const converted_tree_view_categories_babynastix = convertCategoriesToTreeViewItems(tree_view_categories_babynastix, null)
			const converted_tree_view_categories_monkeynastix = convertCategoriesToTreeViewItems(tree_view_categories_monkeynastix, null)
			const converted_tree_view_categories_supernastix = convertCategoriesToTreeViewItems(tree_view_categories_supernastix, null)

            console.log(converted_tree_view_categories_monkeynastix)

            setTreeViewCategoriesBabynastix(converted_tree_view_categories_babynastix)
            setTreeViewCategoriesMonkeynastix(converted_tree_view_categories_monkeynastix)
            setTreeViewCategoriesSupernastix(converted_tree_view_categories_supernastix)
        })

    }, [connected_company.is_babynastix, connected_company.is_monkeynastix, connected_company.is_supernastix])

	return (
		<div style={{marginTop: '2rem'}}>
            {tree_view_categories_monkeynastix.length || tree_view_categories_babynastix.length || tree_view_categories_supernastix.length ?
                <Box display={'flex'} justifyContent={'center'} gap={'1rem'} flexWrap={'wrap'}>
                {tree_view_categories_babynastix.length ? 
                    <List
                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', border: '1px solid darkgrey', borderRadius: '5%', textAlign: 'center', overflowY: 'auto' }}
                        aria-labelledby="nested-list-subheader"
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader" sx={{ width: '100%', margin: 'auto', position: 'sticky', top: 0, backgroundColor: 'background.paper', zIndex: 1 }}>
                                Babynastix
                            </ListSubheader>
                        }
                    >
                        <ListItem>
                            <INastixRichTreeView tree_view_base_item={tree_view_categories_babynastix}/>
                        </ListItem>
                    </List> : <></>
                }
                {tree_view_categories_monkeynastix.length ? 
                    <List
                        sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper', border: '1px solid darkgrey', borderRadius: '5%', textAlign: 'center', overflowY: 'auto'  }}
                        aria-labelledby="nested-list-subheader"
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader" sx={{ width: '100%', margin: 'auto', position: 'sticky', top: 0, backgroundColor: 'background.paper', zIndex: 1 }}>
                                Monkeynastix
                            </ListSubheader>
                        }
                    >
                        <ListItem>
                            <INastixRichTreeView tree_view_base_item={tree_view_categories_monkeynastix}/>
                        </ListItem>
                    </List> : <></>
                }
                {tree_view_categories_supernastix.length ?
                    <List
                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', border: '1px solid darkgrey', borderRadius: '5%', textAlign: 'center', overflowY: 'auto' }}
                        aria-labelledby="nested-list-subheader"
                        subheader={
                            <ListSubheader component="div" id="nested-list-subheader" sx={{ width: '100%', margin: 'auto', position: 'sticky', top: 0, backgroundColor: 'background.paper', zIndex: 1 }}>
                                Supernastix
                            </ListSubheader>
                        }
                    >
                        <ListItem>
                            <INastixRichTreeView tree_view_base_item={tree_view_categories_supernastix}/>
                        </ListItem>
                    </List> : <></>
                }
                </Box>
                :
                <Typography> No categories added </Typography>
            }
		</div>
	)
}

export default FzoneFilesPage
