import {useContext} from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'

import Form, { FormDataEntryType, OnSubmitCallback } from '@components/forms/Form'
import { DialogButton, DialogFormControl } from '@styles/Dialog'
import { Instructor } from '@utils/interfaces/interfaces'
import { InstructorForm } from '@components/tables/Custom/NotificationTable/InstructorsMap'
import UserMultipleAuthStatusSelect from '@components/selects/UserMultipleAuthStatusSelects'
import { NumOfRequestsContext } from '@contexts/NumOfRequestsContext'
import { parseInstructorFinanceAndPermissionData } from '@components/tables/Custom/NotificationTable/utils'
import InstructorActions from '@actions/CRUDActions/InstructorActions/instructorActions'
import InstructorDetails from '@pages/Setup/UserSetup/ChooseUserType/InstructorDetails'

import log from 'loglevel'


const EditInstructorForm = (onSubmitCallback: OnSubmitCallback, values?: object) => {
    const instructor_values = values as Instructor

	const { t } = useTranslation('instructor_form')
	const {setNumOfRequests} = useContext(NumOfRequestsContext)

    const createFields = (values?: object) => {
        return (
            <Box sx={{textAlign: 'center'}}>
			<DialogFormControl>
                {values && <input type="hidden" value={instructor_values.id} id="id" name="id"/>}
				{instructor_values?.id && 
					<InstructorForm 
						instructor_id={instructor_values.id} 
						salary={instructor_values.salary}
						commission={instructor_values.commission}
						manage_classes={instructor_values.manage_classes}
						manage_members={instructor_values.manage_members}
						transfer_children={instructor_values.transfer_children}
						manage_schools={instructor_values.manage_schools}
					/>
				}
				<br/>
				{instructor_values?.id && <InstructorDetails passed_shirt_size={instructor_values.shirt_size}/>}
				<br/>
				<UserMultipleAuthStatusSelect values={instructor_values}/>
                {values ? 
                    <DialogButton variant='contained' type="submit"> {t('edit')} </DialogButton>
                    : <DialogButton variant='contained' type="submit"> {t('create')} </DialogButton>
                }			
                </DialogFormControl>
            </Box>
        )
    }

    const onSubmit = async (form_data_object: FormDataEntryType): Promise<any> => {
		const updated_form_data_object: Record<string, any> = {}
		const id = form_data_object['id']
		updated_form_data_object['id'] = id

		const {parsed_form_data, permission_form_data} = parseInstructorFinanceAndPermissionData(form_data_object)

		console.log(parsed_form_data)

		let send_data
		if (permission_form_data.length > 1)
			send_data = permission_form_data[1]
		else
			send_data = {id: id}

		const instructor_actions = new InstructorActions()
		await instructor_actions.updateInstructorUserPermissions(send_data)
		.catch(error => {
			log.error(error.message)
		})
		.finally(() => {
			setNumOfRequests(0)
		})

		function combineObjects(dataArray: Record<string, string>[]): Record<string, string> {
			const combinedObject: Record<string, string> = {}
		  
			for (const obj of dataArray) {
				for (const [key, value] of Object.entries(obj)) {
					combinedObject[key] = value
				}
			}
		  
			return combinedObject
		}
		
		const combinedResult = combineObjects(parsed_form_data as any)

		return onSubmitCallback(combinedResult)
		.finally(() => {
			setNumOfRequests(0)
		})
	}

	return (
        <Form onSubmitCallback={onSubmit} createFields={() => createFields(values)}></Form>
	)
}

export default EditInstructorForm