import {useState, useEffect, useCallback} from 'react'
import { useTranslation } from 'react-i18next'

import { Dayjs } from 'dayjs'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { Typography } from '@mui/material'

import DatePickerObjectNastix from '@components/pickers/DatePickerObjectNastix'
import { Terms } from './types'


interface HandleTermsProps {
    initial_terms?: Record<string, Dayjs>
    num_of_terms: number
    setTermsCallback?: (value: Terms, key_terms_callback_wrapper: string) => void
    key_terms_callback_wrapper?: string
    disable?: boolean
}

const HandleTerms = (props: HandleTermsProps) => {
    const {initial_terms, num_of_terms, setTermsCallback, key_terms_callback_wrapper, disable} = props

    const { t } = useTranslation('company_registration_details')

    const [terms, setTerms] = useState<Terms>(null)
    const [terms_errors, setTermsErrors] = useState<Record<string, boolean> | null>(null)

    useEffect(() => {
        if (initial_terms)
            setTerms(initial_terms)
        else {
            for (let term_number=1; term_number <= num_of_terms; term_number++) {
                setTerms(props => ({
                    ...props, 
                    [`term_${term_number}_start`]: null, 
                    [`term_${term_number}_end`]: null
                }))
            }
        }
    }, [initial_terms, num_of_terms])

    useEffect(() => {
        for (let term_number=1; term_number <= num_of_terms; term_number++) {
            setTermsErrors(props => ({
                ...props, 
                [`term_${term_number}_start`]: false, 
                [`term_${term_number}_end`]: false
            }))
        }
    }, [num_of_terms])

    const memoizedSetTermsCallback = useCallback(
        (terms: Terms, key_terms_callback_wrapper: string | undefined) => {
            if (setTermsCallback && key_terms_callback_wrapper) {
                setTermsCallback(terms, key_terms_callback_wrapper);
            }
        },
        [setTermsCallback]
    )
    
    useEffect(() => {
        memoizedSetTermsCallback(terms, key_terms_callback_wrapper)
    }, [terms, key_terms_callback_wrapper, memoizedSetTermsCallback])

    const isDateMoreThanOneMonthApart  = (selected_date: Dayjs, compare_date: Dayjs): boolean => {
        const month_difference = selected_date.diff(compare_date, 'month')
        return month_difference > 0 || month_difference < 0
    }

    const onAcceptCallback = (selected_date: Dayjs, compare_date: Dayjs, selected_date_key: string) => {
        if (!isDateMoreThanOneMonthApart(selected_date, compare_date))
            setTermsErrors(props => ({...props, [selected_date_key]: true}))
        else
            setTermsErrors(props => ({...props, [selected_date_key]: false}))
	}

    return (
        <>
        <Box>
            {terms && terms_errors && [...Array(num_of_terms)].map((_, index) => (
                <div key={index}>
                <Typography variant="body1" mb={1}> {t('term')} {index + 1} </Typography>
                <Grid container spacing={2}>
                    <Grid item md={6}>
                        <DatePickerObjectNastix
                            dates={terms} 
                            setDates={setTerms} 
                            dates_key={`term_${index + 1}_start`}
                            compare_date_key={`term_${index + 1}_end`}
                            error={terms_errors[`term_${index + 1}_start`]}
                            onAcceptCallback={onAcceptCallback}
                            label={t('term_start')}
                            disabled={disable}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <DatePickerObjectNastix
                            dates={terms} 
                            setDates={setTerms} 
                            dates_key={`term_${index + 1}_end`}
                            compare_date_key={`term_${index + 1}_start`}
                            error={terms_errors[`term_${index + 1}_end`]}
                            onAcceptCallback={onAcceptCallback}
                            label={t('term_end')}
                            disabled={disable}
                        />
                    </Grid>
                </Grid>
                <br/>
                </div>
            ))}                
        </Box>
        </>
    )
}

export default HandleTerms