// import {useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import { BreadcrumbMainTypography, BreadcrumbTypography } from '@styles/Typography'

import Pages from '@pages/Page'
import { UserPermissions } from '@utils/enums/permissions'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { companyIsInGroup } from '@utils/utils/util'
import { CompanyType } from '@utils/enums/enums'

import './styles.css'
// import { amountOfFinancesDataObject } from './objects'


interface FinancesPageProps {
	children: React.ReactNode
}

const FinancesPage = (props: FinancesPageProps) => {
	const {children} = props

	const location = useLocation()
	const url = location.pathname
	const string_after_finance = url.substring(url.indexOf('finance') + 'finance'.length + 1)
	const converted_string = string_after_finance.replace(/-/g, ' ')
	const { t } = useTranslation('overview')

	// ADD BACK IN WHEN AMOUNT OF FINANCES IS WORKING
	// const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)
	// const {setAmountOfFinancesData} = useContext(AmountOfFinancesDataContext)

	// useEffect(() => {
	// 	const fetchData = async () => {
	// 		const finances_localstorage = localStorage.getItem('finances')
	// 		const finances: AmountOfFinancesData | null = finances_localstorage ? JSON.parse(finances_localstorage) : null 

	// 		if (!finances) {
	// 			try {
	// 				const data: AmountOfFinancesData = await getAmountOfFinances()
	// 				localStorage.setItem('finances', JSON.stringify(data))
	// 				setAmountOfFinancesData(data)
	// 			} catch (error) {
	// 				console.error("Error fetching data:", error)
	// 			}
	// 		}
	// 		else {
	// 			const finances_length = finances ? Object.keys(finances).length : 0
	// 			if (!finances_length)
	// 				throw new Error("Amount of Finance Data cannot have 0 keys")

	// 			localStorage.setItem('finances', JSON.stringify(finances))
	// 			setAmountOfFinancesData(finances)
	// 		}
	// 	}
		
	// 	fetchData()
	// }, [change_company_rerender])

	const isActiveLink = (href: string) => {
		return location.pathname === href
	}

	const company = getLocalStorageCompany()
	const is_master_franchise = companyIsInGroup(company, CompanyType.MASTER_FRANCHISE)
	const acts_like_a_franchise = company.acts_like_a_franchise
	const is_franchisor = companyIsInGroup(company, CompanyType.FRANCHISOR)

	return (
		<Pages page_title="Finances" permission={UserPermissions.VIEW_FINANCES}>
			<Breadcrumbs aria-label="breadcrumb">
				<BreadcrumbMainTypography sx={{marginBottom: 0}}> {t('finances')} </BreadcrumbMainTypography>
				{(!is_franchisor && acts_like_a_franchise) ?
					<Link
						underline={isActiveLink('/finance/member-invoices') ? 'always' : 'hover'}
						color="inherit"
						href={url}
					>
						<BreadcrumbTypography>{converted_string}</BreadcrumbTypography>
					</Link>
					: 
					<Link
						underline={isActiveLink('/finance/royalty-invoices') ? 'always' : 'hover'}
						color="inherit"
						href={url}
					>
						<BreadcrumbTypography>{converted_string}</BreadcrumbTypography>
					</Link>
				}
			</Breadcrumbs>
			<br/>
			<Divider/>
			<Box sx={{display: 'flex', justifyContent: 'center', gap: '2rem', padding: '1rem'}}>
				{/* <Link
						underline={isActiveLink('/finance/sales-overview') ? 'always' : 'hover'}
						color="inherit"
						href="/finance/sales-overview"
					>
						{t('sales_overview')}
				</Link> */}
				{!is_franchisor &&
					<Link
						underline={isActiveLink('/finance/bills') ? 'always' : 'hover'}
						color="inherit"
						href="/finance/bills"
					>
						{t('bills')}
					</Link>
				}
				{(!is_franchisor && acts_like_a_franchise) &&
					<Link
						underline={isActiveLink('/finance/member-payments') ? 'always' : 'hover'}
						color="inherit"
						href="/finance/member-payments"
					>
						{t('member_payments')}
					</Link>
				}
				{(!is_franchisor && acts_like_a_franchise) &&
					<Link
						underline={isActiveLink('/finance/school-payments') ? 'always' : 'hover'}
						color="inherit"
						href="/finance/school-payments"
					>
						{t('school_payments')}
					</Link>
				}
				{(!is_franchisor && acts_like_a_franchise) &&
					<Link
						underline={isActiveLink('/finance/member-invoices') ? 'always' : 'hover'}
						color="inherit"
						href="/finance/member-invoices"
					>
						{t('member_invoices')}
					</Link>
				}
				{(is_franchisor || is_master_franchise) &&
					<Link
					underline={isActiveLink('/finance/royalty-invoices') ? 'always' : 'hover'}
					color="inherit"
					href="/finance/royalty-invoices"
				>
					{t('royalty_invoices')}
					</Link>
				}
			</Box>
			<Divider/>
			<br/>
			<br/>
			{children}
		</Pages>
	)
}

export default FinancesPage
