
import { Dayjs } from 'dayjs'

import { useMediaQuery, Theme } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import Typography from '@mui/material/Typography'
import { responsiveDatePicker } from '../DatePickerNastix'


interface DatePickerNastixProps {
	dates: Record<string, Dayjs | null>
	setDates: (date: Record<string, Dayjs | null>) => void
	dates_key: string
	compare_date_key?: string
	onAcceptCallback?: (date: Dayjs, compare_date: Dayjs, selected_date_key: string) => void
	error?: boolean
	label: string
    use_mobile?: boolean
	max_date?: Dayjs
    min_date?: Dayjs
    disabled?: boolean  // set to true if you don't want user to be able to change the already set date
}


const DatePickerObjectNastix = (props: DatePickerNastixProps) => {
	const {dates, setDates, dates_key, compare_date_key, onAcceptCallback, error, label, use_mobile=false, max_date, min_date, disabled} = props

	const theme = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))
    const theme_or_use_mobile = theme || use_mobile

	const value = dates[dates_key]
    const ResponsiveDatePicker = responsiveDatePicker(theme_or_use_mobile)

	const onAccept = (date: Dayjs | null) => {
		if (compare_date_key) {
			const compare_date = dates[compare_date_key]  // this can be undefined
			if (date && compare_date && onAcceptCallback)
				onAcceptCallback(date, compare_date, dates_key)
		}
	}

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DemoContainer 
				components={[          
					'DatePicker',
					'MobileDatePicker',
					'DesktopDatePicker',
					'StaticDatePicker'
				]}>
				<ResponsiveDatePicker 
					disabled={disabled}
					label={label}
					value={value}
					onChange={(new_value: Dayjs | null) => {
						if (new_value) {
							const updated_state = {...dates}
							updated_state[dates_key] = new_value
							setDates(updated_state)
						}
					}}
					maxDate={max_date}
					minDate={min_date}
					format="DD/MM/YYYY"
					onAccept={onAccept}
					sx={{
						'.MuiOutlinedInput-notchedOutline': {
							borderColor: error ? 'red' : undefined,
						}
					}}
				/>
			</DemoContainer>
			{error ? <Typography color={'red'}> Invalid date chosen </Typography> : <></>}
		</LocalizationProvider>
	)
}

export default DatePickerObjectNastix