import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useMediaQuery, Theme } from '@mui/material'

import { SettingsSubHeadingTypography } from '@styles/Typography'


interface SettingsSubHeadingProps {
    children: React.ReactNode
    button_text?: string
}

const SettingsSubHeading: React.FC<SettingsSubHeadingProps> = ({ children, button_text  }) => {
    const is_small_screen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

    const styles = {
        divider: is_small_screen ? {textAlign: 'center'} : {textAlign: 'left'},
        button: is_small_screen ? {} : {marginLeft: '1rem'},
        box: is_small_screen ? {justifyContent: 'center', marginBottom: '1rem'} : {}
    }

    return (
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 4, mb: 3, flexWrap: 'wrap', rowGap: '1rem', ...styles.box}}>
            {is_small_screen ? 
                <Divider sx={{ flexGrow: 1}} textAlign='center'>
                    <SettingsSubHeadingTypography variant="h3">{children}</SettingsSubHeadingTypography>
                </Divider>
                :
                <Divider sx={{ flexGrow: 1}} textAlign='left'>
                    <SettingsSubHeadingTypography variant="h3">{children}</SettingsSubHeadingTypography>
                </Divider>
            }
            {button_text && <Button variant='outlined' type="submit" sx={{...styles.button, textTransform: 'capitalize'}}> {button_text} </Button>}
        </Box>
    )
}

export default SettingsSubHeading
