import {useState, useEffect, useRef} from 'react'
import Backdrop from '@mui/material/Backdrop'

import InastixSpinner from '@images/inastix/inastix_loader.gif'


interface UIBackdropProps {
    num_of_requests: number
}

const UIBackdrop = (props: UIBackdropProps) => {
    const {num_of_requests} = props

    const [open, setOpen] = useState(false)

    const timerRef = useRef<NodeJS.Timeout | null>(null)

    const handleClose = () => {
        setOpen(false)
    }
    
    useEffect(() => {
        const handleOpenState = async () => {
            if (num_of_requests > 0) {
                // only show backdrop after 500ms. This is to improve user exprience by only showing loader when request is taking long
                timerRef.current = setTimeout(() => {
                    setOpen(true)
                }, 500) 
            } else {
                setOpen(false)
                if (timerRef.current) clearTimeout(timerRef.current)
            }

            return () => {if (timerRef.current) clearTimeout(timerRef.current)}
        }  // a cleanup function that clears the timer variable. This is important to avoid any memory leaks or unexpected behavior.

        handleOpenState()
    }, [num_of_requests])

    return (
        <>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={handleClose}
        >
            <img
                src={InastixSpinner} // Replace InastixSpinnerGIF with the path to your GIF file
                alt="Loading Spinner"
                style={{ width: '250px', height: '140.625px' }}  // aspect ratio of InastixSpinner: 1920px - 1080px
            />
        </Backdrop>
        </>
    )
}

export default UIBackdrop