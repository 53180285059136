import { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation'

import { DialogButton, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import {DialogProps} from '@utils/dialogProps/DialogProps'
import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'

import HandleSchools from '@components/forms/ChildForm/HandleSchools'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import SchoolActions from '@actions/CRUDActions/SchoolActions/schoolActions'
import { School } from '@utils/interfaces/interfaces'
import AddSchoolToChildActions from '@actions/CRUDActions/ChildActions/addSchoolToChildActions'
import { MessageContext } from '@contexts/MessageContext'
import { NumOfChildrenNeedingSchoolContext } from '@pages/Children/contexts/NumOfChildrenNeedingSchool'


const AddSchoolDialog = (props: DialogProps) => {
	const {ids, state_manager, onClose, open} = props

    const { t } = useTranslation('children')

    const {setMessage} = useContext(MessageContext)
	const {setNumOfChildrenNeedingSchool} = useContext(NumOfChildrenNeedingSchoolContext)
    const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)

    const [school, setSchool] = useState<School | null>(null)
    const [schools, setSchools] = useState<School[]>([])

    useEffect(() => {
        const school_actions = new SchoolActions()
        school_actions.get()
        .then((data:School[]) => {
            setSchools(data)
        })
    }, [change_company_rerender])

    const onSelectSchoolCallback = (selected_school: School | null) => {
        setSchool(selected_school)
    }  

    const handleConfirm = () => {
        if (school) {
            const add_school_to_child_actions = new AddSchoolToChildActions()
            const child_data = {
                'child_ids': ids,
                'school_id': school.id
            }
            add_school_to_child_actions.create(child_data)
            .then(data => {
                setNumOfChildrenNeedingSchool(props => props - ids.length)
                setMessage(props => ({...props, success: t('school_added_to_children')}))
            })
            .catch(error => {
                setMessage(props => ({...props, error: error.message}))
            })
            .finally(async () => {
                return state_manager.setGetAction(undefined, undefined, {'has_school': false})
                .then(() => {
                    handleClose()
                })
            })
        } 
        // else {
        //     setMessage({{'error': 'Please select a school first'}})
        // }

	}

    const handleClose = () => {
		onClose()
	}

    return (
		<INastixDialog onClose={handleClose} open={open}>
        	
            <INastixDialogTitle sx={{padding: 0, paddingTop: '0.4rem', marginBottom: '1rem'}}> 
                {t('select_school')} 
            </INastixDialogTitle>

            <HandleSchools onSelectCallback={onSelectSchoolCallback} schools={schools} school={school}/>
			 
            <DialogButton variant='contained' onClick={handleConfirm}> {t('allocate_to_school')} </DialogButton>
            
		</INastixDialog>
    )
}


const AddSchoolDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

    const { t } = useTranslation('children')

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<TransferWithinAStationIcon/>}>
			{t('allocate_to_school')}
		</TableActionButon>
		<AddSchoolDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>
		</>
	)
}

export default AddSchoolDialogHandler