import { CRUDAction } from "@actions/Actions/actions"

import NotificationHandler from "@adapters/handlers/CRUDHandlers/NotificationHandlers/notificationHandlers"
import NotificationCountHandler from "@adapters/handlers/CRUDHandlers/NotificationHandlers/notificationCountHandler"
import AuthorizeNotificationHandler from "@adapters/handlers/CRUDHandlers/NotificationHandlers/authorizeNotificationHandler"
import UnauthorizeNotificationHandler from "@adapters/handlers/CRUDHandlers/NotificationHandlers/unauthorizeNotificationHandler"


class NotificationActions extends CRUDAction {
    handler: NotificationHandler
    count_handler: NotificationCountHandler
    authorize_notification_handler: AuthorizeNotificationHandler
    unauthorize_notification_handler: UnauthorizeNotificationHandler

    constructor() {
        super()
        this.handler = new NotificationHandler()
        this.count_handler = new NotificationCountHandler()
        this.authorize_notification_handler = new AuthorizeNotificationHandler()
        this.unauthorize_notification_handler = new UnauthorizeNotificationHandler()
    }

    authorize_users (notification_ids: Array<string>): Promise<any> {
        return this.authorize_notification_handler.post(notification_ids)
    }

    unauthorize_users (notification_ids: Array<string>): Promise<any> {
        return this.unauthorize_notification_handler.post(notification_ids)
    }

    count (): Promise<any> {
        return this.count_handler.get()
    }
}


export default NotificationActions