import { ContentType, MethodType } from "../enums/enum"

import { BACKEND_URL } from "@adapters/routes/base_urls"


abstract class FetchWrapper {
    base_url: string | undefined
    content_type: ContentType

    constructor(content_type: ContentType = ContentType.JSON) {
        this.base_url = BACKEND_URL
        this.content_type = content_type
    }

    get (endpoint: string): Promise<any> {
        const base_endpoint_url = this.getBaseEndpointUrl(endpoint)

        const response = this.handleFetch(base_endpoint_url, {}, MethodType.GET)
        return response
    }

    post (endpoint: string, data: object): Promise<any> {
        const base_endpoint_url = this.getBaseEndpointUrl(endpoint)

        const response = this.handleFetch(base_endpoint_url, data, MethodType.POST)
        return response
    }

    put (endpoint: string, data: object, id: string): Promise<any> {
        const base_endpoint_url = this.getBaseEndpointUrl(endpoint) + id + '/'

        const response = this.handleFetch(base_endpoint_url, data, MethodType.PUT)
        return response
    }

    putAll (endpoint: string, data: object): Promise<any> {
        const base_endpoint_url = this.getBaseEndpointUrl(endpoint)

        const response = this.handleFetch(base_endpoint_url, data, MethodType.PUT)
        return response
    }
    
    delete (endpoint: string, id: string): Promise<any> {
        const base_endpoint_url = this.getBaseEndpointUrl(endpoint) + id

        const response = this.handleFetch(base_endpoint_url, {}, MethodType.DELETE)
        return response
    }

    abstract handleFetch (url: string, data: object, method_type: MethodType): Promise<any>

    /**
    Parse the endpoint string and ensure that it ends with a forward slash (/).
    @param {string} endpoint - The endpoint string to parse.
    @returns {string} - The endpoint string with a forward slash (/) at the end.
    **/
    parse_endpoint (endpoint: string) : string {
        let last_char = endpoint.charAt(endpoint.length - 1)
        if (last_char !== '/')
            return endpoint + '/'
        return endpoint
    }

    createRequestOptions = (method: MethodType = MethodType.GET, data: object | null = null, headers: object | null = null) : object => {
        let obj = {}
        let header: Record<string, string> = {
            'Authorization': `JWT ${localStorage.getItem('access_token')}`,
            ...headers
        }
        const json_or_spreadsheet = this.content_type === ContentType.JSON || this.content_type === ContentType.SPREADSHEET || this.content_type === ContentType.CSV
        if (json_or_spreadsheet)
            header['Content-Type'] = ContentType.JSON

        if (method === MethodType.POST || method === MethodType.PUT) {
            obj = {
                method: method,
                headers: header,
                body: json_or_spreadsheet ? JSON.stringify(data) : data
            }
        }
        else {
            obj = {
                method: method,
                headers: header
            }
        }
    
        return obj
    }

    getBaseEndpointUrl = (endpoint: string) => {
        if (this.base_url)
            return this.base_url + this.parse_endpoint(endpoint)
        throw new Error("this.base_url is undefined. Stopping server request process. BASE_URL is likely not set in env vars")
    }
}

export default FetchWrapper