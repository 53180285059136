import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"

import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

import { SetupBox, SetupHeadingTypography } from "@styles/Setup"
import XeroAccountCodes from "@pages/Settings/BusinessSettings/XeroManager/XeroAccountCodes"
import Form from "@components/forms/Form"
import { MessageContext } from "@contexts/MessageContext"
import { SubmitButon } from "@styles/Buttons"
import CompanyActions from "@actions/CompanyActions/companyActions"
import { getLocalStorageCompany, updateLocalStorageCompany } from "@utils/localStorage/company"
import { XeroAccountCodesStructProps } from "@pages/Settings/BusinessSettings/XeroManager/interfaces"
import { changeSnakeCaseToWords, companyIsInGroup } from "@utils/utils/util"
import { CompanyType } from "@utils/enums/enums"
import { CompanySetupStepContext } from "@contexts/CompanySetupStepContext"


const XeroManagementSetup = () => {
    const {setMessage} = useContext(MessageContext)
    const {setCompanySetupStep} = useContext(CompanySetupStepContext)

    const { t } = useTranslation('set_up')

    const [xero_account_codes, setXeroAccountCodes] = useState<XeroAccountCodesStructProps>()
    const [is_sep_account_codes_checked, setIsSepAccountCodesChecked] = useState(false)

    const handleIsSepAccountCodesCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsSepAccountCodesChecked(event.target.checked)
    }

    const company = getLocalStorageCompany()
    const is_franchise = companyIsInGroup(company, CompanyType.FRANCHISEE)

    const createFields = () => {
        return (
            <SetupBox>
                <SetupHeadingTypography> {t('xero_management')} </SetupHeadingTypography>
                {!is_franchise &&
                    <FormControlLabel 
                        control={
                            <Checkbox
                                checked={is_sep_account_codes_checked}
                                onChange={handleIsSepAccountCodesCheckedChange}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        }
                        label={"Do you have separate account codes for royalties?"}
                    />
                }
                {<XeroAccountCodes accountCodesCallback={handleAccountCodesCallback} is_sep_account_codes_checked={is_sep_account_codes_checked} styling_type="vertical"/>}
                <SubmitButon variant='contained' type="submit" sx={{width:'150px'}}> {t('submit')} </SubmitButon>
            </SetupBox>
        )
    }

    const handleAccountCodesCallback = (xero_account_codes: XeroAccountCodesStructProps) => {
        setXeroAccountCodes(xero_account_codes)
    }

    const isAccountCodesValid = () => {
        if (!xero_account_codes) {
            setMessage(props => ({...props, error: "Account codes are required"}))
            return false
        }

        const codes_to_include = new Set(['sales_account_code', 'sales_account_uuid'])

        if (!is_franchise)
            Object.keys(xero_account_codes).forEach(key => codes_to_include.add(key))

        for (const [key, value] of Object.entries(xero_account_codes)) {
            // don't do validation check for is_sep_account_codes_checked
            if (key === 'is_sep_account_codes_checked')
                continue

            if (codes_to_include.has(key) && !value) {
                setMessage(props => ({...props, error: `${changeSnakeCaseToWords(key)} is required`}))
                return false
            }
        }
        return true
    }

    const onSubmit = async (): Promise<any> => {
        setMessage(props => ({...props, error: ""}))

        if (!isAccountCodesValid())
            return

        let data = {is_sep_account_codes_checked: is_sep_account_codes_checked}

        if (xero_account_codes) {
            data = {
                ...data,
                ...xero_account_codes
            }
        }

        const company_setup_actions = new CompanyActions()
        company_setup_actions.xeroManagementSetup(data)
        .then(() => {
            const company = getLocalStorageCompany()
            updateLocalStorageCompany('setup_step', company.setup_step + 1)
            setCompanySetupStep(prop => prop + 1)
        })
    }

    return (
		<Form onSubmitCallback={onSubmit} createFields={createFields}/>
    )
}

export default XeroManagementSetup