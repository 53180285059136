import { createContext } from 'react'


export type SetChangeUserRerender = React.Dispatch<React.SetStateAction<boolean>>

interface IChangeUserContext {
    change_user_rerender: boolean
    setChangeUserRerender: SetChangeUserRerender
}

export const ChangeUserRerenderContext = createContext<IChangeUserContext>({
    change_user_rerender: false,
    setChangeUserRerender: () => {}
})