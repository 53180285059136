import {useContext, useState} from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import {Typography} from '@mui/material'

import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import { DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { DialogProps } from '@utils/dialogProps/DialogProps'
import BulkChildActions from '@actions/ChildActions/bulkChildActions'
import CloudSyncIcon from '@mui/icons-material/CloudSync'
import { MessageContext } from '@contexts/MessageContext'


const ImportChildrenToXeroDialog = (props: DialogProps) => {
	// disable the warning for not using state_manager in this dialog
	// eslint-disable-next-line  
	const {ids, state_manager, onClose, open} = props

	const { t } = useTranslation('import_children_to_xero')

	const {setMessage} = useContext(MessageContext)

	const [disable_button, setDisableButton] = useState(false)

	const handleImportToXero = async (): Promise<any> => {
		setDisableButton(true)
		const bulk_child_actions = new BulkChildActions()
        bulk_child_actions.importChildrenToXero({children_ids: ids})
        .then(data => {
			setMessage(props => ({...props, success: t("children_imported")}))
		})
		.catch(error => {
			setMessage(props => ({...props, error: error.message}))
			setDisableButton(false)
		})
		.finally(async () => {
			return state_manager.setGetAction()
			.then(() => {
				setDisableButton(false)
				handleClose()
			})
		})
	}

	const handleClose = () => {
		onClose()
	}

	return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> {t("import_children_to_xero")} </INastixDialogTitle>
			<DialogDivider/>
			<Button disabled={disable_button} onClick={handleImportToXero} variant="contained" sx={{width: '100%'}}>
                {t("Import")}
            </Button>
		</INastixDialog>
	)
}


const ImportChildrenToXeroDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

	const { t } = useTranslation('import_children_to_xero')

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<CloudSyncIcon/>}>
			<Typography> {t("import_to_xero")} </Typography>
		</TableActionButon>
		<ImportChildrenToXeroDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>
		</>
	)
}

export default ImportChildrenToXeroDialogHandler