export const CountriesOptions = [
    {id: 1, code: 'ZA', label: "South Africa"},
    {id: 2, code: 'BW', label: "Botswana"},
    {id: 3, code: 'MY', label: "Malaysia"},
    {id: 4, code: 'GH', label: "Ghana"},
    {id: 5, code: 'LS', label: "Lesotho"},
    {id: 6, code: 'KE', label: "Kenya"},
    {id: 7, code: 'ZM', label: "Zambia"},
    {id: 8, code: 'NA', label: "Namibia"},
    {id: 9, code: 'MR', label: "Mauritius"},
    {id: 10, code: 'CY', label: "Cyprus"},
    {id: 11, code: 'NI', label: "Northern Ireland"},
    {id: 12, code: 'GB', label: "United Kingdom"},
    {id: 13, code: 'MT', label: "Malta"},
    {id: 14, code: 'Earth', label: "Earth"},
    {id: 15, code: 'US', label: "United States"},
    {id: 16, code: 'FR', label: "France"},
    {id: 17, code: 'CD', label: "Democratic Republic of the Congo"},
]
