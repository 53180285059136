import { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { SERVER_STATUS } from '@adapters/routes/endpoints'
import { MessageContext } from '@contexts/MessageContext'
import { BACKEND_URL } from '@adapters/routes/base_urls'


interface ServerStatusProps {
    isServerOnlineCallback?: (is_server_online: boolean) => void
}

const ServerStatus = (props: ServerStatusProps) => {
    const {isServerOnlineCallback} = props

    const { t } = useTranslation('server_status')

    const {setMessage} = useContext(MessageContext)

    const [is_server_online, setIsServerOnline] = useState(true)

    useEffect(() => {
        const checkServerStatus = async () => {
            try {
                const response = await fetch(`${BACKEND_URL}${SERVER_STATUS}`)
                if (!response.ok)
                    setIsServerOnline(false)
            } catch (error) {
                setIsServerOnline(false)
            }
        }
        checkServerStatus()
    }, [])

    useEffect(() => {
        if (!is_server_online)
            setMessage(props => ({...props, error: t('server_is_not_responding_try_again_in_a_moment')}))

        if (isServerOnlineCallback)
            isServerOnlineCallback(is_server_online)
    }, [is_server_online, setMessage, t, isServerOnlineCallback])

    return (<></>)
}

export default ServerStatus
