import { useTranslation } from 'react-i18next'

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { School } from '@utils/interfaces/interfaces';

interface SchoolsProps {
    onSelectCallback?: (selected_school: School | null) => void
    schools: School[]
    school: School | null
}

const SchoolField = (props: SchoolsProps) => {
    const {onSelectCallback, schools, school} = props
    const { t } = useTranslation('child_form')

    const handleSelect = (event: any, value: School | null) => {
        if(onSelectCallback)
            onSelectCallback(value)
    }

    return (
        <Autocomplete
            id="school"
            options={schools.sort((a, b) => -b.school_type.localeCompare(a.school_type))}
            groupBy={(option) => option.school_type}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label={t('school')}/>}
            value={school}
            onChange={handleSelect}
            sx={{marginBottom: 2}}
            fullWidth
        />
    )
}

export default SchoolField