import { useState  } from 'react'
import { useTranslation } from 'react-i18next'

import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'


interface IsVatRegisteredProps {
    onSelect: (value: string) => void
}

const IsVatRegistered = (props: IsVatRegisteredProps) => {
    const {onSelect} = props

    const { t } = useTranslation('finance_terms')

    const [is_vat_registered, setIsVatRegistered] = useState('')

    const handleChange = (event: SelectChangeEvent) => {
        const selected_value = event.target.value
        setIsVatRegistered(selected_value)
        onSelect(selected_value)
    }

    return (
        <FormControl sx={{minWidth: '20ch'}}>
            <InputLabel id="trading-status-label">{t('vat_registered')}</InputLabel>
            <Select
                labelId="trading-status-label"
                label={t('vat_registered')}
                value={is_vat_registered}
                onChange={handleChange}
                name="vat_registered"
                required
            >
                <MenuItem value={'True'}> {t('yes')} </MenuItem>
                <MenuItem value={'False'}> {t('no')} </MenuItem>
            </Select>
        </FormControl>
    )
}

export default IsVatRegistered
