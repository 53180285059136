import { CRUDAction } from "@actions/Actions/actions"
import ArchivedChildHandler from "@adapters/handlers/CRUDHandlers/ArchivedChildHandlers/archivedChildHandlers"


class ArchivedChildActions extends CRUDAction {
    handler: ArchivedChildHandler

    constructor() {
        super()
        this.handler = new ArchivedChildHandler()
    }
}

export default ArchivedChildActions