import {useContext, useState} from 'react'
import { useTranslation } from 'react-i18next'

import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import HelpIcon from '@mui/icons-material/Help'
import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel  from "@mui/material/FormControlLabel"
import FormGroup from "@mui/material/FormGroup"
import { useMediaQuery, Theme } from '@mui/material'
import { SelectChangeEvent } from '@mui/material/Select'

import { FormDataEntryType } from "@components/forms/Form"
import Form from "@components/forms/Form"
import { CompanySetupStepContext } from '@contexts/CompanySetupStepContext'
import { getLocalStorageCompany, updateLocalStorageCompany } from '@utils/localStorage/company'
import CompanyActions from '@actions/CompanyActions/companyActions'
import AddressFields from '@components/forms/Fields/AddressFields'
import { SetupBox, SetupButon, SetupFormGrid, SetupHeadingTypography, SetupSubHeadingTypography } from '@styles/Setup'
import UserActions from '@actions/CRUDActions/UserActions/userActions'
import { MessageContext } from '@contexts/MessageContext'
import { getLocalStorageUser } from '@utils/localStorage/user'
import { SchoolType, SchoolTypeEmail, UserType } from '@utils/enums/enums'
import { isInGroup, removeWordFromString } from '@utils/utils/util'
import MonkeynastixEmailField from '@components/forms/Fields/EmailFields/MonkeynastixEmailField'
import { Address} from '@utils/interfaces/interfaces'

import log from 'loglevel'

import Contact from './Contact'


interface CompanyDetailsSubmitData { [x: string]: string | boolean | FormDataEntryValue | Address}

const CompanyDetails = () => {
    const is_small_screen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
    const theme = useTheme()

    const user = getLocalStorageUser()
	const company = getLocalStorageCompany()
    const is_school_operator = isInGroup(user, company, UserType.SCHOOL_OPERATOR)

    const {setCompanySetupStep} = useContext(CompanySetupStepContext)
    const {setMessage} = useContext(MessageContext)

    const { t } = useTranslation('company_details')

    const [physical_address, setPhysicalAddress] = useState<Address>({
        country: '',
        street: '',
        city: '',
        region: '',
        postal_code: '',
    })
    const [is_delivery_same_as_physical, setIsDeliverySameAsPhysical] = useState(true)
    const [selected_school_type, setSelectedSchoolType] = useState<SchoolTypeEmail>(SchoolTypeEmail.MONKEYNASTIX)

    const handleSchoolTypeEmailChange = (event: SelectChangeEvent) => {
        setSelectedSchoolType(event.target.value as SchoolTypeEmail)
    }

    const MonkeynastixEmail = () => {
        const monkeynastix_email_address = removeWordFromString(company.tenant_name, [SchoolType.BABYNASTIX, SchoolType.MONKEYNASTIX, SchoolType.SUPERNASTIX])

        return (
            <Box sx={{marginTop: '2rem'}}>
                <Box sx={{display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '0.65rem'}}>
                    <Typography sx={{fontSize: theme.typography.h6.fontSize, fontWeight: '600', color: 'darkslategray'}} variant="h3"> 
                        {t('monkeynastix_email')} 
                    </Typography>
                    <Tooltip title={t('used_for_all_your_monkeynastix_emailing')}>
                        <IconButton>
                            <HelpIcon sx={{color: theme.palette.primary.main}}/>
                        </IconButton>
                    </Tooltip>
                </Box>
                <MonkeynastixEmailField 
                    email_address={monkeynastix_email_address} 
                    selected_school_type={selected_school_type} 
                    handleSchoolTypeEmailChange={handleSchoolTypeEmailChange}/>
            </Box>
        )
    }

    const createFields = () => {
        return (
            <SetupBox>
                <SetupHeadingTypography variant="h2"> {t('details_setup')} </SetupHeadingTypography>
                <SetupFormGrid container justifyContent={'center'}>
                    <Grid item md={5} padding={'1rem'}>
                        <SetupSubHeadingTypography variant="h3"  sx={{marginBottom: 0}}> {t('contact')} </SetupSubHeadingTypography>
                        <Typography variant='caption' sx={{marginBottom: '1rem', display:'block', width: '75%'}}> 
                            {t('choose_from_a_list_of_preferred_contacts_or_create_new_contact_details_for_this')}
                            {is_school_operator ? t('school') : t('company')} 
                        </Typography>
                        <Contact/>
                        {!is_small_screen && !is_school_operator && <MonkeynastixEmail/>}
                    </Grid>
                    <Grid item md={7} padding={'1rem'}>
                        <SetupSubHeadingTypography variant="h3"> {t('physical_address')} </SetupSubHeadingTypography>
                        <AddressFields setAddressCallback={handleAddressCallback}/>
                        <FormGroup>
                            <FormControlLabel 
                                control={
                                    <Checkbox
                                        checked={is_delivery_same_as_physical}
                                        onChange={handleChange}
                                        name='is_delivery_same_as_physical'
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                } 
                                label={t('is_physical_and_delivery_same')}
                            />
                        </FormGroup>
                    </Grid>
                    {!is_delivery_same_as_physical &&
                        <Box sx={{margin: 'auto', width: '50%'}}>
                            <SetupSubHeadingTypography variant="h2"> {t('delivery_address')} </SetupSubHeadingTypography>
                            <AddressFields/>
                        </Box>
                    }
                    {is_small_screen && !is_school_operator && <MonkeynastixEmail/>}
                </SetupFormGrid>
                <br/>
                <SetupButon variant='contained' type="submit" sx={{width:'150px'}}> {t('submit')} </SetupButon>
            </SetupBox>
        )
    }

    const handleAddressCallback = (data: Address) => {
        setPhysicalAddress(data)
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsDeliverySameAsPhysical(event.target.checked)
    }

    const createSetupDetails = async (form_data_object: FormDataEntryType) => {
        const company_action = new CompanyActions()
        return company_action.createSetupDetails(form_data_object)
        .then(() => {
            const company = getLocalStorageCompany()
            updateLocalStorageCompany('setup_step', company.setup_step + 1)
            setCompanySetupStep(prop => prop + 1)
        })
    }

    const onSubmit = async (form_data_object: FormDataEntryType): Promise<any> => {
        log.info(`Data passed to form submit: ${JSON.stringify(form_data_object)}`)

        let data: CompanyDetailsSubmitData = {...form_data_object}

        if (!is_delivery_same_as_physical)
            data['physical_address'] = physical_address

        let alternate_email = form_data_object['email'] as string
        const preferred_contact_selected = form_data_object['preferred_contact']
        if (preferred_contact_selected) {
            const user_actions = new UserActions()
            await user_actions.getById(preferred_contact_selected.toString())
            .then(data => {
                alternate_email = data['email']
            })
        }
        else if (!alternate_email) {
            setMessage(props => ({...props, error: t('you_must_either_provide_a_main_contact_user_or_additional_contact_details')}))
            return
        }

        data['monkeynastix_email'] = `${data['monkeynastix_email']}${selected_school_type}`

        log.info(`Data sent from form submit: ${JSON.stringify(data)}`)
        return createSetupDetails(data as FormDataEntryType)
	}

    return (
		<Form onSubmitCallback={onSubmit} createFields={createFields}/>
    )
}

export default CompanyDetails