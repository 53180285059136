import Link from "@mui/material/Link"
import Box from "@mui/material/Box"
import Typography from '@mui/material/Typography'

import dayjs from 'dayjs'


const AuthFooter = () => {
    const today = dayjs()
    const formatted_date = today.format('YYYY')

    return (
        <>
        <Box sx={{display: 'flex', marginTop:'auto', marginBottom: '1rem', gap: 3}}>
            <Typography variant="caption"> <Link href="monkeynastixonline.co.za">iNastix@{formatted_date}</Link></Typography>
            <Typography variant="caption"> <Link href="https://www.monkeynastixonline.co.za/contact/"> Support </Link></Typography>
        </Box>
        </>
    )
}

export default AuthFooter