import { CRUDAction } from "@actions/Actions/actions"
import ParentHandler from "@adapters/handlers/CRUDHandlers/ParentHandlers/parentHandlers"
import { Parent } from "@utils/interfaces/interfaces"


class ParentActions extends CRUDAction {
    handler: ParentHandler

    constructor() {
        super()
        this.handler = new ParentHandler()
    }

    getParent (endpoint: string): Promise<Parent[]> {
        return this.handler.getParent(endpoint)
    }
}

export default ParentActions