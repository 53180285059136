import {useState} from 'react'

import { Dayjs } from 'dayjs'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'


interface TimePickerNastixProps {
    initial_time?: Dayjs
    label: string
    name: string
    onTimeChange?: (newTime: Dayjs | null) => void
}

const TimePickerNastix = (props: TimePickerNastixProps) => {
    const {initial_time, label, name, onTimeChange } = props

    const [time, setTime] = useState<Dayjs | null>(initial_time ? initial_time : null)

    const handleChange = (new_time: Dayjs | null) => {
        setTime(new_time)
        if (onTimeChange) {
            onTimeChange(new_time)
        }
    }

    const getTimeString = () => {
        if (time)
            return time.format('HH:mm:ss')
        return ''
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
            label={label}
            value={time}
            onChange={handleChange}
        />
        {time && (
            // we use a hidden input so we can specify a name prop for the time picker field so it can be inluded in a form post submission
            <input
                type="hidden"
                name={name} 
                value={getTimeString()}
                required
            />
        )}
        </LocalizationProvider>
    )
}

export default TimePickerNastix;
