import {useEffect, useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import {
    Chart as ChartJS,
    ChartData,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import ChildActions from '@actions/CRUDActions/ChildActions/childActions'

import { ChartBox } from '../styles'
import ToggleChartVisibility from '../ToggleChartVisibility'
import { DashbaordStatsProps } from './interfaces'


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)


type ChildrenCountData = {
    zero_to_one_boy_children: number
    one_to_three_boy_children: number
    four_to_six_boy_children: number
    seven_and_up_boy_children: number
    zero_to_one_girl_children: number
    one_to_three_girl_children: number
    four_to_six_girl_children: number
    seven_and_up_girl_children: number
    zero_to_one_other_children: number
    one_to_three_other_children: number
    four_to_six_other_children: number
    seven_and_up_other_children: number
}


const labels = ['0-1', '1-3', '4-6', '7-up']

const ChildAgeGroups = (props: DashbaordStatsProps) => {
    const {edit_dashboard_open, checked, setChecked, selected_franchise_option, selected_school_type_options} = props
    
    const { t } = useTranslation('dashboard')
    const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)

    const boys_label = t('boys')
    const girls_label = t('girls')
    const others_label = t('other')

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: t('children_grouped_by_sex_and_age_group'),
            },
        },
    }

    const [data, setData] = useState<ChartData<"bar", (number | [number, number] | null)[], string>>({labels, datasets: []})

    useEffect(() => {
        if (!selected_franchise_option)
            return

        const setChildrenBirthdayStat = async () => {

            const child_actions = new ChildActions()
            
            const today = new Date()
            const oneYearAgo = new Date(today)
            oneYearAgo.setFullYear(today.getFullYear() - 1)  // Calculate date 1 year ago
            const threeYearsAgo = new Date(today)
            threeYearsAgo.setFullYear(today.getFullYear() - 3)  // Calculate date 3 years ago

            const data: any = {
                franchise_id: selected_franchise_option.id,
            }
            if (selected_school_type_options)
                data['selected_school_type_options'] = selected_school_type_options

            const children_count_data: ChildrenCountData = await child_actions.childrenStatAge(data)

            setData({
                labels,
                datasets: [
                    {
                        label: boys_label,
                        data: [children_count_data.zero_to_one_boy_children, children_count_data.one_to_three_boy_children, children_count_data.four_to_six_boy_children, children_count_data.seven_and_up_boy_children],
                        backgroundColor: 'rgba(53, 162, 235, 0.7)',
                    },
                    {
                        label: girls_label,
                        data: [children_count_data.zero_to_one_girl_children, children_count_data.one_to_three_girl_children, children_count_data.four_to_six_girl_children, children_count_data.seven_and_up_girl_children],
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                    {
                        label: others_label,
                        data: [children_count_data.zero_to_one_other_children, children_count_data.one_to_three_other_children, children_count_data.four_to_six_other_children, children_count_data.seven_and_up_other_children],
                        backgroundColor: 'rgba(53, 162, 235, 0.3)',
                    },
                ],
            })
        }
        setChildrenBirthdayStat()

    }, [selected_franchise_option, selected_school_type_options, change_company_rerender])

    return (
        <>
        <ChartBox edit_dashboard_open={edit_dashboard_open} checked={checked}>
            <Bar options={options} data={data} />
            <canvas style={{ display: 'inline', height: 0 }} />
        </ChartBox>
        {edit_dashboard_open && 
            <>
            <ToggleChartVisibility checked={checked} setChecked={setChecked}/>
            </>
        }
        </>
    )
}

export default ChildAgeGroups
