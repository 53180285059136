import {useContext, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import { useMediaQuery, Theme } from '@mui/material'

import { CompanySetupStepContext } from '@contexts/CompanySetupStepContext'
import { MainHeadingTypography } from '@styles/Setup'
import XeroConnectionsSelect from '@components/navigation/PrimaryAppBar/XeroConnectionsSelect'
import { getLocalStorageUser } from '@utils/localStorage/user'
import { CompanySetupStep, UserType } from '@utils/enums/enums'
import SetupFooter from '@pages/Setup/Footer'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import BackToLogin from '@pages/Setup/BackToLogin'
import { isInGroup } from '@utils/utils/util'

import GetStepComponent from './GetStepComponent'
import CompanyBeingSetup from '../CompanyBeingSetup'
import ColorlibStepIcon from './ColorStepIcon'
import { DesktopStepper, MobileStepper } from '@pages/Setup/Setup'


const CompanySetupStepper = () => {
    const is_small_screen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

	const navigation = useNavigate()

    const { t } = useTranslation('set_up') 

    const steps = [t('details_setup'), t('choose_type_of_franchise'), t('finance_and_terms'), t('get_authorized')]

    const user = getLocalStorageUser()
    const company = getLocalStorageCompany()

    const {company_setup_step, setCompanySetupStep} = useContext(CompanySetupStepContext)

    if (company.is_xero_company)
        steps.splice(3, 0, t('xero_management'))
        
    const authorised_to_setup_company = isInGroup(user, company, [UserType.FRANCHISEE_ADMIN, UserType.FRANCHISEE_OPERATOR, UserType.MASTER_FRANCHISE_OPERATOR])

    useEffect(() => {
        setCompanySetupStep(company.setup_step)
    }, [company.setup_step, setCompanySetupStep])

    useEffect(() => {
        if (company_setup_step === CompanySetupStep.COMPLETED)
			navigation('/dashboard')
    }, [company_setup_step, navigation])


    return (
        <>
        {authorised_to_setup_company ? 
            <>
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh'}}>
            <Box padding={3}>
                <MainHeadingTypography variant="h1"> {t('company_setup')}  </MainHeadingTypography>
                <Box display={'flex'} gap={2} alignItems={'center'} marginTop={'0.5rem'}>
                    <XeroConnectionsSelect dark_mode={true}/>
                    <BackToLogin/>
                </Box>
                <br/>
                <Stack sx={{ width: '100%' }} spacing={4}>
                    {is_small_screen ? 
                        <MobileStepper
                            setup_step={company_setup_step}
                            steps={steps}
                            GetStepComponent={GetStepComponent}
                            ColorlibStepIcon={ColorlibStepIcon}
                        /> 
                        : 
                        <DesktopStepper
                            setup_step={company_setup_step}
                            steps={steps}
                            GetStepComponent={GetStepComponent}
                            ColorlibStepIcon={ColorlibStepIcon}
                        />
                    }
                </Stack>
            </Box>
            <SetupFooter/>
            </Box>
            </>
            : (
                <CompanyBeingSetup/>
            )
        }
        </>
    )
}

export default CompanySetupStepper