import { INastixCurrency } from "@utils/enums/enums"


export const CurrencyOptions = [
    {
        code: 'ZA',
        value: 'ZAR',
        label: 'ZAR - Rand',
        backend_map: INastixCurrency.RAND
    },
    {
        code: 'BW',
        value: 'BWP',
        label: 'BWP - Pula',
        backend_map: INastixCurrency.BOTSWANA_PULA
    },
    {
        code: 'MY',
        value: 'MYR',
        label: 'MYR - Ringgit',
        backend_map: INastixCurrency.MALAYSIAN_RINGGIT
    },
    {
        code: 'GH',
        value: 'GHS',
        label: 'GHS - Ghanaian Cedi',
        backend_map: INastixCurrency.GHANAIAN_CEDI
    },
    {
        code: 'LS',
        value: 'LSL',
        label: 'LSL - Lesotho Loti',
        backend_map: INastixCurrency.LESOTHO_LOTI
    },
    {
        code: 'KE',
        value: 'KES',
        label: 'KES - Kenyan Shilling',
        backend_map: INastixCurrency.KENYAN_SHILLING
    },
    {
        code: 'ZM',
        value: 'ZMW',
        label: 'ZMW - Zambian Kwacha',
        backend_map: INastixCurrency.ZAMBIAN_KWACHA
    },
    {
        code: 'NA',
        value: 'NAD',
        label: 'NAD - Namibian Dollar',
        backend_map: INastixCurrency.NAMIBIAN_DOLLAR
    },
    {
        code: 'MU',
        value: 'MUR',
        label: 'MUR - Mauritian Rupee',
        backend_map: INastixCurrency.MAURITIAN_RUPEE
    },
    {
        code: 'EU',
        value: 'EUR',
        label: 'EUR - Euro',
        backend_map: INastixCurrency.EURO
    },
    {
        code: 'GB',
        value: 'GBP',
        label: 'GBP - British Pound',
        backend_map: INastixCurrency.BRITISH_POUND
    },
    {
        code: 'US',
        value: 'USD',
        label: 'USD - US Dollar',
        backend_map: INastixCurrency.US_DOLLAR
    },
    {
        code: 'CD',
        value: 'CDF',
        label: 'CDF - Congolese Franc',
        backend_map: INastixCurrency.CONGOLESE_FRANC
    },
    {
        code: 'RO',
        value: 'RON',
        label: 'RON - Romanian Leu', 
        backend_map: INastixCurrency.ROMANIAN_LEU 
    },
]
