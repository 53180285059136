import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'

import Form, { FormDataEntryType } from '@components/forms/Form'
import FranchiseeActions from '@actions/CRUDActions/FranchiseeActions/franchiseeActions'
import {RoyaltiesDialogProps} from '@utils/dialogProps/DialogProps'

import FranchiseesMap from './FranchiseesMap'


const RoyaltiesDialog = (props: RoyaltiesDialogProps) => {
	const {open, setOpen} = props
    const{ t } = useTranslation('finance_details')

    const createFields = () => {
        return (
            <>
            <FranchiseesMap />
            <Box sx={{textAlign: 'right', marginTop: '1rem'}}>
                <Button onClick={handleCancel}>
                    {t('cancel')}
                </Button>
                <Button variant='contained' type="submit">{t('ok')}</Button>
            </Box>
            </>
        )
    }

    const handleSubmit = async (form_data: FormDataEntryType) => {
        const parsed_form_data = []

        for (const key in form_data) {
            const result: Record<string, string | boolean | FormDataEntryValue> = {}

            const [id, field] = key.split(":")
            const value = form_data[key]
          
            result['company_uuid'] = id
            result[field] = value

            parsed_form_data.push(result)
        }

        const franchisee_actions = new FranchiseeActions()
        return franchisee_actions.updateMultiple(parsed_form_data)
        .then(() => {
            handleClose()
            return {message: t('royalties_set')}
        })
        .catch(error => {
            console.log(error.message)
        })
    }

	const handleClose = () => {
		setOpen(false)
	}

    const handleCancel = () => {
        handleClose()
    }

    const handleBackdropClick = (event: any) => {
		event.stopPropagation()
    }

	return (
		<INastixDialog onClose={handleClose} open={open} onClick={handleBackdropClick}>
			<INastixDialogTitle> {t('franchisee_finances')} </INastixDialogTitle>
			<DialogDivider/>
            <Form onSubmitCallback={handleSubmit} createFields={createFields}/>
		</INastixDialog>
	)
}

export default RoyaltiesDialog
