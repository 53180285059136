import { useTranslation } from 'react-i18next'

import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { School } from '@utils/interfaces/interfaces';

interface SchoolProps {
    onSelectCallback?: (selected_schools: School[]) => void
    schools: School[]
    parent_schools?: School[]
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const HandleParentSchools = (props: SchoolProps) => {
    const {onSelectCallback, schools, parent_schools} = props
    const { t } = useTranslation('parents_form')

    const handleSelect = (event: any, value: School[]) => {
        if(onSelectCallback)
            onSelectCallback(value)
    }

    const school_placeholder = t('schools')

    return(
        <Autocomplete
            multiple
            id="schools"
            options={schools.sort((a, b) => -b.school_type.localeCompare(a.school_type))}
            groupBy={(option) => option.school_type}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.name}
                </li>
            )}
            renderInput={(params) => ( 
                <TextField {...params} label={t('school')} placeholder={school_placeholder}/>
            )}
            value={parent_schools}
            onChange={handleSelect}
            sx={{ marginBottom: 2 }}
            fullWidth
        />
    );
}

export default HandleParentSchools