import { createContext } from 'react'


export type SetNumOfRequests = React.Dispatch<React.SetStateAction<number>>

interface INumOfRequestsContext {
    num_of_requests: number
    setNumOfRequests: SetNumOfRequests
}

export const NumOfRequestsContext = createContext<INumOfRequestsContext>({
    num_of_requests: 0,
    setNumOfRequests: () => {}
})