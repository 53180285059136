import {useContext, useEffect} from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Stack from '@mui/material/Stack'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Box from '@mui/material/Box'
import { fabClasses } from '@mui/material'

import { CompanySetupStepContext } from '@contexts/CompanySetupStepContext'
import { MainHeadingTypography } from '@styles/Setup'
import XeroConnectionsSelect from '@components/navigation/PrimaryAppBar/XeroConnectionsSelect'
import { getLocalStorageUser } from '@utils/localStorage/user'
import { CompanySetupStep, UserType } from '@utils/enums/enums'
import CompanyBeingSetup from '@pages/Setup/CompanySetup/CompanyBeingSetup'
import SetupFooter from '@pages/Setup/Footer'
import BackToLogin from '@pages/Setup/BackToLogin'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { ColorlibConnector } from '@pages/Setup/UserSetup/SetupStepper/ColorStepIcon'

import GetStepComponent from './GetStepComponent'
import ColorlibStepIcon from './ColorStepIcon'


const SchoolSetupStepper = () => {
	const navigation = useNavigate()
    const { t } = useTranslation('set_up')

    const steps = [t('details_setup'), t('connect_a_franchise'), t('additional_setup'), t('get_authorized')]
    const {company_setup_step, setCompanySetupStep} = useContext(CompanySetupStepContext)
    const school_setup_step = company_setup_step

    const user = getLocalStorageUser()
    const company = getLocalStorageCompany()
    const authorised_to_setup_school = user.groups ? user.groups.some(group => group.name !== UserType.INSTRUCTOR && group.name !== UserType.ASSISTANT): fabClasses

    useEffect(() => {
        setCompanySetupStep(company.setup_step)
    }, [company.setup_step, setCompanySetupStep])

    useEffect(() => {
        if (company_setup_step === CompanySetupStep.COMPLETED)
			navigation('/dashboard')
    }, [company_setup_step, navigation])

    return (
        <>
        {authorised_to_setup_school ? 
            <>
            <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100vh'}}>
            <Box padding={3}>
                <MainHeadingTypography variant="h1"> {t('school_setup')} </MainHeadingTypography>
                <Box display={'flex'} gap={2} alignItems={'center'} marginTop={'0.5rem'}>
                    <XeroConnectionsSelect dark_mode={true}/>
                    <BackToLogin/>
                </Box>
                <br/>
                <Stack sx={{ width: '100%' }} spacing={4}>
                    <Stepper alternativeLabel activeStep={school_setup_step} connector={<ColorlibConnector />}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    {GetStepComponent(school_setup_step)}
                </Stack>
            </Box>
            <SetupFooter/>
            </Box>
            </>
            : (
                <CompanyBeingSetup/>
            )
        }
        </>
    )
}

export default SchoolSetupStepper