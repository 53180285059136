import {useEffect} from 'react'

import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'

import GenericFetchWrapper from '@adapters/api/fetchWrappers/genericFetchWrapper'
import { BACKEND_MEDIA_URL, USERS_ENDPOINT } from '@adapters/routes/endpoints'
import { ContentType } from '@adapters/api/enums/enum'
import { getLocalStorageUser } from '@utils/localStorage/user'


/**
 * Converts a given string into a hexadecimal color representation.
 *
 * @param {string} string - The input string for which to generate a color.
 * @returns {string} - A hexadecimal color code generated based on the input string.
 *
 * @example
 * // Usage:
 * const color = stringToColor('example');
 * console.log(color); // Outputs: '#1a4d82'
*/
function stringToColor(string: string): string {
    let hash = 0
    let i

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash)
    }

    let color = '#'

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff
        color += `00${value.toString(16)}`.slice(-2)
    }
    /* eslint-enable no-bitwise */
    return color
}

function stringAvatar(name: string): object {
    let parsed_name: string

    if (name.includes(' ')) {
        // If the name contains a space, use the first letter of each word.
        const [firstName, lastName] = name.split(' ')
        parsed_name = `${firstName[0]}${lastName[0]}`
    } else {
        // If the name does not contain a space, use the first letter only.
        parsed_name = name[0]
    }

    return {
        sx: {bgcolor: stringToColor(name)},
        children: parsed_name,
    }
}

interface INastixAvatarProps {
    profile_picture: string
    setProfilePicture: React.Dispatch<React.SetStateAction<string>>
}

const INastixAvatar = (props: INastixAvatarProps) => {
    const {profile_picture, setProfilePicture} = props

    const user = getLocalStorageUser()
    
    useEffect(() => {
        const fetch_wrapper = new GenericFetchWrapper(ContentType.FORM_DATA)
        fetch_wrapper.get(USERS_ENDPOINT + '/profile-picture/')
        .then(data => {
            if (data.profile_picture)
                setProfilePicture(BACKEND_MEDIA_URL + data.profile_picture)
        })
    }, [setProfilePicture])

    return (
        <Box>
        <Stack direction="row" spacing={2}>
            {profile_picture ? 
                <Avatar alt={`${user.username} Profile avatar`} src={profile_picture} />
                :
                <Avatar {...stringAvatar(user.username)} />
            }
        </Stack>
        </Box>
    )
}

export default INastixAvatar