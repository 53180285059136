import { CRUDHandler } from "@adapters/handlers/Handlers/handlers"
import { EVALUATIONS_ENDPOINT } from "@adapters/routes/endpoints"


class EvaluationHandler extends CRUDHandler {
    endpoint: string

    constructor () {
        super()
        this.endpoint = EVALUATIONS_ENDPOINT
    }

    get (page_size?: number, page?: number, filter?: object, group?: string): Promise<any> {
        return this.fetch_wrapper.get(this.endpoint, page, page_size, filter, group)
    }

    getById(id: string): Promise<any> {
        return this.fetch_wrapper.get(this.endpoint + '/' + id)
    }

    post (data: object): Promise<any> {
        return this.fetch_wrapper.post(this.endpoint, data)
    }

    put (data: object, id: string): Promise<any> {
        return this.fetch_wrapper.put(this.endpoint, data, id)
    }

    patch (data: object, id: string): Promise<any> {
        return this.fetch_wrapper.patch(this.endpoint, data, id)
    }

    delete (id: string): Promise<any> {
        return this.fetch_wrapper.delete(this.endpoint, id)
    }

    deleteMultiple (ids: Array<string>): Promise<any> {
        return this.fetch_wrapper.deleteMultiple(this.endpoint, ids)
    }
}

export default EvaluationHandler