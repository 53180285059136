import {useState} from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'

import { Class } from '@utils/interfaces/interfaces'

import Form, { OnSubmitCallback } from "../Form"


const AttendanceForm = (onSubmitCallback: OnSubmitCallback, values?: object) => {
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value)
    }

    const handleDesciptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDescription(event.target.value)
    }

    const { t } = useTranslation('attendance_form');

    // values is an object passed in if we want the form to be populated with existing data. Typiically used for the edit form or in settings
    const createFields = (values?: object) => {
        const class_values = values as Class
        return (
            <>
            <Box>
                {values && <input type="hidden" value={class_values.id} id="id" name="id"/>}
                <Typography variant="h3"> {t('details')} </Typography>
                <TextField 
                    id="name"
                    label={t('name')}
                    name="name"
                    variant="outlined"
                    onChange={handleNameChange}
                    value={values ? class_values.name : name}
                    required
                />
                <TextField 
                    id="description"
                    label={t('description')}
                    name="description"
                    variant="outlined"
                    onChange={handleDesciptionChange}
                    value={values ? class_values.description : description}
                    required
                />
                {values ? 
                    <Button type="submit"> {t('edit')}</Button>
                    : <Button type="submit"> {t('create')} </Button>
                }
            </Box>
            </>
        )
    }
    
    return (
        <Form onSubmitCallback={onSubmitCallback} createFields={() => createFields(values)}></Form>
    )
}

export default AttendanceForm
