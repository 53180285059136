import { LocalStorageDashboardConfig } from "@utils/interfaces/interfaces"


const setLocalStorageDashboardConfig = (data: LocalStorageDashboardConfig) => {
    if (data !== undefined)
        localStorage.setItem('dashboard_config', JSON.stringify(data))
}

const getLocalStorageDashboardConfig = (): LocalStorageDashboardConfig => {
    const dashboard_config_json = localStorage.getItem("dashboard_config")

    if (dashboard_config_json === 'undefined')
        localStorage.removeItem("dashboard_config")

    const dashboard_config = dashboard_config_json ? JSON.parse(dashboard_config_json) : null

    return dashboard_config
}

const updateLocalStorageDashboardConfig = (key: string, value: any) => {
    if (value !== undefined) {
        const dashboard_config = getLocalStorageDashboardConfig()
        const updated_dashboard_config = { ...dashboard_config, [key]: value }

        localStorage.setItem('dashboard_config', JSON.stringify(updated_dashboard_config))

        return updated_dashboard_config
    } else
        return getLocalStorageDashboardConfig()
}


export {setLocalStorageDashboardConfig, getLocalStorageDashboardConfig, updateLocalStorageDashboardConfig}