import { useTranslation } from 'react-i18next'

import CancelIcon from '@mui/icons-material/Cancel'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import Tooltip from '@mui/material/Tooltip'

import Table, { DialogComponent } from '@components/tables/Table/Table'
import { CRUDAction } from '@actions/Actions/actions'
import SendBulkEmailDialogHandler from '@components/dialogs/SendBulkEmailDialogHandler'
import { getLocalStorageUser } from '@utils/localStorage/user'
import { capitalizeFirstLetter, getWordBeforeAt, isValidEmail, permissionValid } from '@utils/utils/util'
import { UserPermissions } from '@utils/enums/permissions'
import FranchiseeForm from '@components/forms/FranchiseeForm'


export interface TableProps {
	action: CRUDAction
	use_as_reporting?: boolean
	single_selection_dialogs?: DialogComponent[]
	dialogs?: DialogComponent[]
}


const valuePreferredContactGetter = (params: any) => {
	const contact_name = params.value

	let parsed_name: string | null = ""

	if (isValidEmail(contact_name)) {
		parsed_name = getWordBeforeAt(contact_name)
		if (parsed_name)
			parsed_name = capitalizeFirstLetter(parsed_name)
	}
	else 
		parsed_name = capitalizeFirstLetter(contact_name)
	return (
		parsed_name
	)
}

const valueAddressGetter = (params: any) => {
	const first_item = params.value[0]

	if (first_item?.region)
		return (first_item.region + " " + first_item.country)
	return ("")
}

const valueContactGetter = (params: any) => {
	const first_item = params.value[0]

	if (first_item?.email)
		return (first_item.email)
	return ("")
}

const FranchiseeTable = (props: TableProps) => {
	const {action, use_as_reporting, dialogs, single_selection_dialogs} = props

	const user = getLocalStorageUser()

	const can_add = !use_as_reporting
	const can_edit = !use_as_reporting
	const can_delete = !use_as_reporting
	const can_send_bulk_email = !use_as_reporting && permissionValid(user, UserPermissions.SEND_BULK_EMAIL_FRANCHISEES)

	const { t } = useTranslation('franchise_table')

	const columns = [
		{ field: 'id', headerName: 'ID', hide: true, minWidth:50, flex: 2 },
		{ field: 'tenant_name', headerName: t('name'), minWidth: 250, flex: 2 },
		{ field: 'company_owner_name', headerName: t('franchise_owner'), minWidth: 200, flex: 2 },
		{ field: 'ready_to_send_certificates', headerName: "Certificates Ready", minWidth: 200, flex: 2,
			renderCell: (params: any) => {
				if (params.value) 
					return <Tooltip title={"Certificates ready to be sent out."}><CheckCircleIcon sx={{ color: '#51b77f' }} /></Tooltip>
				else
					return <Tooltip title={"Certificates not ready to be sent out."}><CancelIcon sx={{ color: '#ed586e' }} /></Tooltip>
			},
		},
		{ field: 'addresses', headerName: t('delivery_address'), minWidth: 200, flex: 2,
			valueGetter: valueAddressGetter
		},
		{ field: 'royalties_percentage', headerName: t('royalties'), minWidth: 100},
		{ field: 'preferred_contact_user', headerName: t('contact_person'), minWidth: 150, flex: 2,
			valueGetter: valuePreferredContactGetter
		},
		{ field: 'contacts', headerName: t('contact_details'), minWidth: 220, flex: 2,
			valueGetter: valueContactGetter
		},
	]

	const this_dialogs: Array<DialogComponent> = []
	const this_single_selection_dialogs: Array<DialogComponent> = []

	if (dialogs) {
		this_dialogs.push(...dialogs)
	}

	if (single_selection_dialogs) {
		this_single_selection_dialogs.push(...single_selection_dialogs)
	}

	if (can_send_bulk_email)
		this_dialogs.push(SendBulkEmailDialogHandler)

    return (
		<>
        <Table
			dialogs={this_dialogs}
			single_selection_dialogs={this_single_selection_dialogs}
			Form={FranchiseeForm}
			action={action}
			columns={columns}
			can_add={can_add}
			can_edit={can_edit}
			can_delete={can_delete}
			page_size={100}
			amount_of_rows_selection={[25, 50, 100]}
		/>
		</>
    )
}

export default FranchiseeTable