import { createContext } from 'react'


export type SetLocalstorageUserExists = React.Dispatch<React.SetStateAction<boolean>>

interface ILocalstorageUserExistsContext {
    local_storage_user_exists: boolean
    setLocalstorageUserExists: SetLocalstorageUserExists
}

export const LocalstorageUserExistsContext = createContext<ILocalstorageUserExistsContext>({
    local_storage_user_exists: false,
    setLocalstorageUserExists: () => {}
})