const MAX_CHILDREN_PER_CLASS = 15

const backend_url = process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_BACKEND_URL_PRODUCTION
    : process.env.REACT_APP_ENVIRONMENT === 'staging' ? process.env.REACT_APP_BACKEND_URL_STAGING
        : process.env.REACT_APP_BACKEND_URL_DEVELOPMENT


const frontend_url = process.env.REACT_APP_ENVIRONMENT === 'production'
    ? process.env.REACT_APP_FRONTEND_URL_PRODUCTION
    : process.env.REACT_APP_ENVIRONMENT === 'staging' ? process.env.REACT_APP_FRONTEND_URL_STAGING
        : process.env.REACT_APP_FRONTEND_URL_DEVELOPMENT


const xero_config = {
    xero_scopes: 'openid profile email offline_access accounting.transactions accounting.settings accounting.contacts',
    xero_sign_in_callback_url: frontend_url + 'login',
    xero_sign_up_callback_url: frontend_url + 'auth/xero/sign-up-callback',
}


const is_under_maintenance = false


export {
    MAX_CHILDREN_PER_CLASS,
    backend_url, 
    frontend_url, 
    xero_config,
    is_under_maintenance
}