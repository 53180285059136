import {useEffect, useState, useContext} from 'react'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox  from '@mui/material/Checkbox'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'

import { DialogFormSubHeadingTypography } from '@styles/Typography'
import { Franchisee, School } from '@utils/interfaces/interfaces'
import FranchiseeActions from '@actions/CRUDActions/FranchiseeActions/franchiseeActions'
import INastixCardSlider from '@components/sliders/CardSlider'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import CompanyActions from '@actions/CompanyActions/companyActions'
import { ExtracurricularActivitiyType } from '@components/forms/SchoolForm'
import { INastixCurrencyMap } from '@utils/maps/maps'

import { AuthorisedSchoolIdsContext } from './context'
import SchoolActions from '@actions/CRUDActions/SchoolActions/schoolActions'


interface SchoolFormProps {
    school_company_id: string
}

const SchoolForm = (props: SchoolFormProps) => {
    const {school_company_id} = props

    const company = getLocalStorageCompany()

    const [class_fee, setClassFee] = useState('')
    const [class_fees, setClassFees] = useState<Number[]>([])
    const [extra_or_intramural_fee, setExtraOrIntrmuralFee] = useState('')
    const [extra_or_intramural_fees, setExtraOrIntrmuralFees] = useState<Number[]>([])
    const [is_sponsored_checked, setIsSponsoredChecked] = useState(false)
    const [is_reduced_registration_checked, setIsReducedRegistrationChecked] = useState(false)

    // eslint-disable-next-line
    const [is_franchise_extramural, setIsFranchiseExtramural] = useState(false)
    // eslint-disable-next-line
    const [is_franchise_intramural, setIsFranchiseIntramural] = useState(false)

    useEffect(() => {
        const company = getLocalStorageCompany()

        const company_actions = new CompanyActions()
        company_actions.getCompanySettings(company.company_uuid.toString())
        .then(data => {
            const finance = data.finance

            const school_actions = new SchoolActions()
            school_actions.getById(school_company_id)
            .then((school: School) => {
                const activity_type = school.extracurricular_activitiy_type

                if (activity_type === ExtracurricularActivitiyType.INTRAMURAL) {
                    setExtraOrIntrmuralFees(finance.intramural_fees)
                }
                else if (activity_type === ExtracurricularActivitiyType.EXTRAMURAL) {
                    setExtraOrIntrmuralFees(finance.extramural_fees)
                }
                else {
                    throw new Error(`Tenant school is not in the correct Extracurricular activity type. Activity type: ${activity_type}`)
                }
            })

            setClassFees(finance.per_class_fees)
        })
        .catch(error => {
            console.error('Error fetching company settings:', error)
        })
    }, [school_company_id])

    // useEffect(() => {
    //     if (activity_type === ExtracurricularActivitiyType.INTRAMURAL) {
    //         setFees(intramural_fees)
    //     } else {
    //         setFees(extramural_fees)
    //     }
    // }, [activity_type, intramural_fees, extramural_fees])

    // const handleActivityTypeChange = (event: SelectChangeEvent) => {
    //     setActivityType(event.target.value as string)
    // }

    const handleIsSponsoredChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsSponsoredChecked(event.target.checked)
    }

    const handleIsReducedRegistrationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsReducedRegistrationChecked(event.target.checked)
    }

    const handleExtraOrIntrmuralFeeChange = (event: SelectChangeEvent) => {
        setExtraOrIntrmuralFee(event.target.value as string)
    }

    const handleClassFeeChange = (event: SelectChangeEvent) => {
        setClassFee(event.target.value as string)
    }

    const currency_symbol = INastixCurrencyMap[company.currency]

    return (
        <div style={{width: '70%', margin: 'auto'}}>
        <input type="hidden" value={school_company_id} name="school_company_id"/>
        {/* <FormControl fullWidth>
            <InputLabel id="activity-type-select-label">Activity type</InputLabel>
            <Select
                labelId="activity-type-select-label"
                id="activity-type-select"
                name="extracurricular_activitiy_type"
                value={activity_type as string}
                label={"Activity type"}
                onChange={handleActivityTypeChange}
                sx={{marginBottom: 2}}
                required
            >
                { is_franchise_extramural && <MenuItem value={ExtracurricularActivitiyType.EXTRAMURAL}>Extramural</MenuItem> }
                { is_franchise_intramural && <MenuItem value={ExtracurricularActivitiyType.INTRAMURAL}>Intramural</MenuItem> }
            </Select>
        </FormControl> */}
        <FormControl variant="filled" fullWidth>
            <InputLabel id="class-fee-select-label">Class fee ({currency_symbol})</InputLabel>
            <Select
                labelId='class-fee-select-label'
                id='class-fee-select'
                name='per_class_fee'
                value={class_fee}
                label={`Class fee (${currency_symbol})`}
                onChange={handleClassFeeChange}
            >
                {class_fees.map((fee, index) => (
                    <MenuItem key={index} value={fee.toString()}>{fee.toString()}</MenuItem>
                ))}
            </Select>
        </FormControl> 
        <br/>
        <br/>
        <FormControl sx={{marginBottom: '1rem'}}>
            <FormControlLabel 
                control={
                    <Checkbox 
                        checked={is_sponsored_checked}
                        onChange={handleIsSponsoredChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        name="is_sponsored"
                    />
                } 
                label="Is this school sponsored?" 
            />
            {!is_sponsored_checked ?
                    <FormControl variant="filled" fullWidth>
                        <InputLabel id="tuition-fee-select-label">Tuition fee ({currency_symbol})</InputLabel>
                        <Select
                            labelId='tuition-fee-select-label'
                            id='tuition-fee-select'
                            name='tuition_fee'
                            value={extra_or_intramural_fee}
                            label={`Tuition fee (${currency_symbol})`}
                            onChange={handleExtraOrIntrmuralFeeChange}
                        >
                            {extra_or_intramural_fees.map((fee, index) => (
                                <MenuItem key={index} value={fee.toString()}>{fee.toString()}</MenuItem>
                            ))}
                        </Select>
                    </FormControl> 
                : <TextField
                    id="sponsored-fee"
                    label={`Sponsored fee (${currency_symbol})`}
                    type="number"
                    name="tuition_fee"
                    required
                    inputProps={{ step: 0.01 }}
                />
            }
            <br/>
        </FormControl>
        <FormControl sx={{marginBottom: '1rem'}}>
            <FormControlLabel 
                control={
                    <Checkbox 
                        checked={is_reduced_registration_checked}
                        onChange={handleIsReducedRegistrationChange}
                        inputProps={{ 'aria-label': 'controlled' }}
                        name="is_reduced_registration"
                    />
                } 
                label="Does this school have a reduced registration fee?" 
            />
            {is_reduced_registration_checked &&
                <TextField
                    id="reduced-registration-fee"
                    label={`Registration fee (${currency_symbol})`}
                    type="number"
                    name="registration_fee"
                    required
                    inputProps={{ step: 0.01 }}
                    sx={{marginTop: '0.7rem'}}
                />
            }
            <br/>
        </FormControl>
        </div>
    )
}


const SchoolsMap = () => {
    const {authorised_school_ids} = useContext(AuthorisedSchoolIdsContext)

    const [schools, setSchools] = useState<Franchisee[]>([])

    useEffect(() => {
        if (authorised_school_ids.length) {
            const franchisee_actions = new FranchiseeActions()
            franchisee_actions.getFranchiseesByCompanyUUIDs(authorised_school_ids)
            .then((schools: Franchisee[]) => {
                setSchools(schools)
            })
        }
    }, [authorised_school_ids])
  
    return (
        <INastixCardSlider slider_length={schools.length}>
            {schools.map((school_company, index) => (
                <div key={index} style={{margin: '2rem'}}>
                    <DialogFormSubHeadingTypography> {school_company.tenant_name} </DialogFormSubHeadingTypography>
                    <SchoolForm school_company_id={school_company.real_school?.toString() as string}/>
                </div>
            ))}
        </INastixCardSlider>
    )
}

export default SchoolsMap
  