import {useEffect, useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
} from 'chart.js'
import { Pie } from 'react-chartjs-2'

import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import ChildActions from '@actions/CRUDActions/ChildActions/childActions'

import { ChartBox } from '../styles'
import ToggleChartVisibility from '../ToggleChartVisibility'
import { DashbaordStatsProps } from './interfaces'


ChartJS.register(
    ArcElement,
    Tooltip,
    Legend
)

type StatusesCountData = {
    authorized: number
    awaiting_payment: number
    on_hold: number
    unauthorized: number
    left: number
}


const ChildStatuses = (props: DashbaordStatsProps) => {
    const {edit_dashboard_open, checked, setChecked, selected_franchise_option, selected_school_type_options} = props
    
    const { t } = useTranslation('dashboard')
    const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)

    const authorized_label = t('authorized')
    const awaiting_payment_label = t('awaiting_payment')
    const on_hold_label = t('on_hold')
    const unauthorized_label = t('unauthorized')
    const left_label = t('left')

    const labels = [authorized_label, awaiting_payment_label, on_hold_label, unauthorized_label, left_label]

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: t("children_statuses"),
            },
        },
    }

    const [data, setData] = useState<any>({labels, datasets: []})
    const [child_total, setChildTotal] = useState<number>(0)

    useEffect(() => {
        if (!selected_franchise_option)
            return

        const setChildrenStatusesStat = async () => {

            const child_actions = new ChildActions()
            
            const data: any = {
                franchise_id: selected_franchise_option.id,
            }
            if (selected_school_type_options)
                data['selected_school_type_options'] = selected_school_type_options

            const statuses_count_data: StatusesCountData = await child_actions.childrenStatusesStat(data)
            setChildTotal(statuses_count_data.authorized + statuses_count_data.awaiting_payment + statuses_count_data.on_hold + statuses_count_data.unauthorized + statuses_count_data.left)
            setData({
                labels,
                datasets: [
                    {
                        label: t("total"),
                        data: [statuses_count_data.authorized, statuses_count_data.awaiting_payment, statuses_count_data.on_hold, statuses_count_data.unauthorized, statuses_count_data.left],
                        backgroundColor: [
                            'rgba(53, 162, 235, 0.7)',
                            'rgba(255, 99, 132, 0.5)',
                            'rgba(53, 162, 235, 0.3)',
                            'rgb(85, 133, 222)',
                            'rgb(255, 192, 157)'
                        ],
                    }
                ],
            })
        }
        setChildrenStatusesStat()

    }, [selected_franchise_option, selected_school_type_options, change_company_rerender])

    return (
        <>
        <ChartBox edit_dashboard_open={edit_dashboard_open} checked={checked}>
            <Pie options={options} data={data} />
            <p style={{margin: 0, color: 'darkslategrey', fontStyle: 'italic', fontWeight: '100', textAlign: 'right', marginRight: '2rem'}}> 
                {t('total:')} <span style={{fontStyle: 'normal'}}>{child_total}</span>
            </p>
            <canvas style={{ display: 'inline', height: 0 }} />
        </ChartBox>
        {edit_dashboard_open && 
            <>
            <ToggleChartVisibility checked={checked} setChecked={setChecked}/>
            </>
        }
        </>
    )
}

export default ChildStatuses
