const DEFAULT_COORDS = {
    static_balance_left: {
        red: { x: 0, y: 0 },
        yellow: { x: 0, y: 0 },
        green: { x: 0, y: 0 }
    },
    dynamic_balance: {
        red: { x: 0, y: 0 },
        yellow: { x: 0, y: 0 },
        green: { x: 0, y: 0 }
    },
    jump_and_land: {
        red: { x: 0, y: 0 },
        yellow: { x: 0, y: 0 },
        green: { x: 0, y: 0 }
    },
    one_leg_hop_left: {
        red: { x: 0, y: 0 },
        yellow: { x: 0, y: 0 },
        green: { x: 0, y: 0 }
    },
    ball_throw: {
        red: { x: 0, y: 0 },
        yellow: { x: 0, y: 0 },
        green: { x: 0, y: 0 }
    },
    monkeynastix_skill: {
        red: { x: 0, y: 0 },
        yellow: { x: 0, y: 0 },
        green: { x: 0, y: 0 }
    },

    ball_catch: {
        red: { x: 0, y: 0 },
        yellow: { x: 0, y: 0 },
        green: { x: 0, y: 0 }
    },
    ball_kick: {
        red: { x: 0, y: 0 },
        yellow: { x: 0, y: 0 },
        green: { x: 0, y: 0 }
    },
    one_leg_hop_right: {
        red: { x: 0, y: 0 },
        yellow: { x: 0, y: 0 },
        green: { x: 0, y: 0 }
    },
    sequence: {
        red: { x: 0, y: 0 },
        yellow: { x: 0, y: 0 },
        green: { x: 0, y: 0 }
    },
    static_balance_right: {
        red: { x: 0, y: 0 },
        yellow: { x: 0, y: 0 },
        green: { x: 0, y: 0 }
    },
    two_legs_jump: {
        red: { x: 0, y: 0 },
        yellow: { x: 0, y: 0 },
        green: { x: 0, y: 0 }
    }
}


export {
    DEFAULT_COORDS
}




