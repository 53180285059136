import {useState, useContext, useEffect} from 'react'
import { useTranslation } from 'react-i18next'

import Typography from '@mui/material/Typography'
import GppBadIcon from '@mui/icons-material/GppBad'

import NotificationActions from '@actions/CRUDActions/NotificationActions/notifcationActions'
import { NumOfNotificationsContext } from '@contexts/NumOfNotificationsContext'
import { DialogButton, DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import { MessageContext } from '@contexts/MessageContext'
import {DialogProps} from '@utils/dialogProps/DialogProps'
import INastixTableContainer from '@components/tables/Table/INastixTableContainer'
import useNotificationInfo from './useNotificationInfo'


const DeclinetNotificationDialog = (props: DialogProps) => {
	const {ids, state_manager, onClose, open} = props

	const { t: tNotifications } = useTranslation('notifications_table')
	const { t: tUserGroups } = useTranslation('user_groups')

	const {setNumOfNotifications} = useContext(NumOfNotificationsContext)
	const {setMessage} = useContext(MessageContext)

	const user_info = useNotificationInfo(ids, state_manager)

	const handleUnauthorize = () => {
		const notification_actions = new NotificationActions()

		notification_actions.unauthorize_users(ids)
		.then(() => {
			state_manager.setDeleteMultipleAction(ids)
			setNumOfNotifications(props => props - ids.length)  // taking the total num of notifications and minusing it by the length of the deleted notifications
            handleClose()

			if (ids.length > 1)
				setMessage(props => ({...props, warning: tNotifications('users_unauthorized')}))
			else
				setMessage(props => ({...props, warning: tNotifications('user_unauthorized')}))
		})
	}

	const handleClose = () => {
		onClose()
	}

	return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> {tNotifications('confirm_unauthorisation')} </INastixDialogTitle>
			<DialogDivider/>
			<br/>
			<Typography>{tNotifications('are_you_sure_you_want_to')} <b> {tNotifications('unauthorise')} {ids.length} {ids.length === 1 ? <span>{tNotifications('item')}</span> : <span>{tNotifications('items')}</span>}</b>?</Typography>
			<br/>
			{user_info && <INastixTableContainer table_row_data={user_info} transFunc1={tNotifications} transFunc2={tUserGroups}/>}
			<DialogButton variant='contained' onClick={handleUnauthorize}> {tNotifications('unauthorise')} </DialogButton>
		</INastixDialog>
	)
}


const DeclineNotificationDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props
	const [isDisabled, setIsDisabled] = useState(disabled)

	const [open, setOpen] = useState(false)

	const { t } = useTranslation('notifications_table')

	useEffect(() => {
		if (ids.length === 1) {
			setIsDisabled(false)
		}
		else if(ids.length < 1 || ids.length > 1) {
			setIsDisabled(true)
		}
	}, [ids])

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={isDisabled} onClick={handleClickOpen} startIcon={<GppBadIcon/>} border_color={'red'}>
			<Typography> {t('unauthorise')} </Typography>
		</TableActionButon>
		<DeclinetNotificationDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>
		</>
	)
}

export default DeclineNotificationDialogHandler