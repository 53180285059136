import {useEffect, useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import {
    Chart as ChartJS,
    ChartData,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

import { ChartBox } from '../styles'
import ToggleChartVisibility from '../ToggleChartVisibility'
import ChildActions from '@actions/CRUDActions/ChildActions/childActions'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import { FranchiseOption } from '..'
import { CompanyType } from '@utils/enums/enums'
import { DashbaordStatsProps } from './interfaces'


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)


const BirthdaysStat = (props: DashbaordStatsProps) => {
    const {edit_dashboard_open, checked, setChecked, selected_franchise_option, selected_school_type_options} = props

    const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)
    const { t } = useTranslation('dashboard')

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: t('number_of_birthdays'),
            },
        },
    }


    const is_franchisor = selected_franchise_option?.tenant_group === CompanyType.FRANCHISOR

    const term_label = t('this_term')
    const month_label = t('this_month')
    const week_label = t('this_week')

    const [data, setData] = useState<ChartData<"bar", (number | [number, number] | null)[], string>>({labels: [term_label, month_label, week_label], datasets: []})

    useEffect(() => {
        if (!selected_franchise_option)
            return

        const setChildrenBirthdayData = async () => {
            const child_actions = new ChildActions()

            const data: any = {
                franchise_id: selected_franchise_option.id,
            }
            if (selected_school_type_options)
                data['selected_school_type_options'] = selected_school_type_options

            const birthday_chidlren: number[] = await child_actions.childrenStatBirthday(data)

            setData({
                labels: is_franchisor ? [week_label, month_label] : [week_label, month_label, term_label],
                datasets: [
                    {
                        data: birthday_chidlren,
                        backgroundColor: 'rgba(53, 162, 235, 0.7)',
                    },
                ],
            })   
        }

        setChildrenBirthdayData()
    }, [selected_franchise_option, selected_school_type_options, change_company_rerender])

    return (
        <>
        <ChartBox edit_dashboard_open={edit_dashboard_open} checked={checked}>
            <Bar options={options} data={data} />
            <canvas style={{ display: 'inline', height: 0 }} />
        </ChartBox>
        {edit_dashboard_open && 
            <>
            <ToggleChartVisibility checked={checked} setChecked={setChecked}/>
            </>
        }
        </>
    )
}

export default BirthdaysStat
