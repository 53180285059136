import { useEffect, useState, useContext } from 'react'

import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { useTheme } from '@mui/material/styles'

import { getLocalStorageCompany, updateLocalStorageCompany } from '@utils/localStorage/company'
import { INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import GenericFetchWrapper from '@adapters/api/fetchWrappers/genericFetchWrapper'
import { MASTER_FRANCHISE_YEARLY_FORM_ENDPOINT } from '@adapters/routes/endpoints'
import { MessageContext } from '@contexts/MessageContext'
import CompanyActions from '@actions/CompanyActions/companyActions'
import { CountryTerm } from '@pages/Setup/CompanySetup/FinanceAndTerms'
import { Terms } from '@pages/Setup/CompanySetup/FinanceAndTerms/types'
import HandleTerms from '@pages/Setup/CompanySetup/FinanceAndTerms/HandleTerms'
import ChildAuthorisationDuePeriod from '@pages/Setup/CompanySetup/FinanceAndTerms/components/ChildAuthorisationDuePeriod'
import MasterFranchiseRegFees from '@pages/Setup/CompanySetup/FinanceAndTerms/components/MasterFranchiseRegFees'
import { companyIsInGroup } from '@utils/utils/util'
import { CompanyType } from '@utils/enums/enums'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import { LocalstorageCompanyExistsContext } from '@contexts/LocalstorageCompanyExistsContext'

import log from 'loglevel'
import { INastixCurrencyMap } from '@utils/maps/maps'


const MasterFranchiseYearlyFormDialog = () => {
    const theme = useTheme()
    const company = getLocalStorageCompany()

    const {setMessage} = useContext(MessageContext)
    const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)
    const {local_storage_company_exists} = useContext(LocalstorageCompanyExistsContext)

    const [terms, setTerms] = useState<Record<string, Terms> | null>(null)
    const [open, setOpen] = useState(false)
    const [country_terms, setCountryTerms] = useState<CountryTerm[] | null>(null)

    useEffect(() => {
        const company_actions = new CompanyActions()
        company_actions.getCountryTerms()
        .then((country_terms: CountryTerm[]) => {
            setCountryTerms(country_terms)
        })
    }, [])

    useEffect(() => {
        if (companyIsInGroup(company, CompanyType.MASTER_FRANCHISE) && company.is_needing_new_yearly_details)
            setOpen(true)
    }, [company, local_storage_company_exists, change_company_rerender])

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        event.stopPropagation()

        const data = new FormData(event.currentTarget) 
        const form_data_object = Object.fromEntries(data.entries())

        let converted_terms: Record<string, Record<string, string>> = {}
        if (terms) {
            // convert terms to a more readible format. Used to put in correct format for django Database datefield as well
            for (const country_term_key of Object.keys(terms)) {
                const country_terms = terms[country_term_key]
                if (!country_terms)
                    continue
                let found_valid_term_value = false
                for (const key of Object.keys(country_terms)) {
                    const term_value = country_terms[key]
                    
                    if (!term_value) {
                        if (found_valid_term_value) {
                            setMessage(props => ({...props, error: "Term details cannot be empty"}))
                            return
                        }
                    }
                    else {
                        if (!term_value.isValid()) {
                            setMessage(props => ({...props, error: "Invalid date chosen"}))
                            return
                        }

                        found_valid_term_value = true
                        if (!converted_terms[country_term_key]) {
                            converted_terms[country_term_key] = {}
                        }
                        converted_terms[country_term_key][key] = term_value.format('YYYY-MM-DD')
                    }
                }
            }
        }

        form_data_object['country_terms'] = converted_terms as unknown as FormDataEntryValue

        const fetch_wrapper = new GenericFetchWrapper()
        fetch_wrapper.post(MASTER_FRANCHISE_YEARLY_FORM_ENDPOINT, form_data_object)
        .then(() => {
            setOpen(false)
            updateLocalStorageCompany('is_needing_new_yearly_details', false)
            setMessage(props => ({...props, success: "Yearly details set"}))
        })
        .catch(error => {
            log.error(error.message)
            setMessage(props => ({...props, error: "Something went wrong. Please try again."}))
        })
    }

    const setTermsCallback = (terms: Terms, key_terms_callback_wrapper: string) => {
        setTerms(props => ({...props, [key_terms_callback_wrapper]: terms}))
    }

    const currency_symbol = INastixCurrencyMap[company.currency]

    // TODO: have field that allows for same details as last year
    return (
        <INastixDialog
            open={open}
            maxWidth="md"
            fullWidth
            disableEscapeKeyDown
        >
        <INastixDialogTitle>Master Franchise Yearly Form</INastixDialogTitle>
        <DialogContent>
        <form onSubmit={handleSubmit}>
            <Typography variant="h3" sx={{ marginTop: '0rem', fontSize: theme.typography.h6.fontSize, fontWeight: '600', color: 'darkslategray'}}>
                Terms 
            </Typography>
            <Grid container sx={{gap: '3rem'}}>
                <Grid item md={5}>
                    <br/>
                    <ChildAuthorisationDuePeriod/>
                    <Typography variant="h3" sx={{ marginTop: 'rem', fontSize: theme.typography.h6.fontSize, fontWeight: '600', color: 'darkslategray'}}>
                        Fees 
                    </Typography>
                    <MasterFranchiseRegFees styles={{flexDirection: 'column'}} currency_symbol={currency_symbol || ''}/>
                </Grid>
                <Grid item md={6}>
                    <br/>
                    {country_terms && 
                        country_terms.map((country_term: CountryTerm) => (
                            <Accordion key={country_term.id}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography>{country_term.name}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <HandleTerms setTermsCallback={setTermsCallback} key_terms_callback_wrapper={country_term.name} num_of_terms={country_term.num_of_terms}/>
                                </AccordionDetails>
                            </Accordion>
                        ))
                    }  
                </Grid>
            </Grid>
            <br/>
            <Button variant='contained' type="submit" sx={{float: 'right'}}>done</Button>
        </form>
        </DialogContent>
        </INastixDialog>
    )
}

export default MasterFranchiseYearlyFormDialog
