import { BaseHandler, AuthenticationHandler, CRUDHandler, SessionHandler, GenericHandler } from "@adapters/handlers/Handlers/handlers"

import { GET_SESSION, SET_SESSION } from "@adapters/routes/endpoints"


export abstract class BaseAction {
    handler: BaseHandler

    constructor() {
        this.handler = new AuthenticationHandler()
    }
}

// comment

export class AuthenticationAction extends BaseAction {
    handler: AuthenticationHandler

    constructor() {
        super()
        this.handler = new AuthenticationHandler()
    }

    authenticate (endpoint: string, data: object): Promise<any> {
        return this.handler.post(endpoint, data)
    }

    resetPassword (endpoint: string, data: object): Promise<any> {
        return this.handler.post(endpoint, data)
    }

    signleSignIn (endpoint: string, data: object): Promise<any> {
        return this.handler.post(endpoint, data)
    }

    // make sure that access token is valid before continuing with all of the application api requests
    pingServer (endpoint: string): Promise<any> {
        return this.handler.get(endpoint)
    }

    forgotPassword (endpoint: string, data: object): Promise<any> {
        return this.handler.post(endpoint, data)
    }
}


export class SessionAction extends BaseAction {
    handler: SessionHandler

    constructor() {
        super()
        this.handler = new SessionHandler()
    }

    get_session (endpoint: string = GET_SESSION, query_params: object): Promise<any> {
        return this.handler.get(endpoint, query_params)
    }

    set_session (endpoint: string = SET_SESSION, query_params: object): Promise<any> {
        return this.handler.get(endpoint, query_params)
    }
}


export class GenericAction extends BaseAction {
    handler: GenericHandler

    constructor () {
        super()
        this.handler = new GenericHandler()
    }

    get (): Promise<any> {
        return this.handler.get()
    }

    getById (id: string): Promise<any> {
        return this.handler.getById(id)
    }

    create (data: object): Promise<any> {
        return this.handler.post(data)
    }

    update (data: object, id: string): Promise<any> {
        return this.handler.put(id, data)
    }
}


export abstract class CRUDAction extends BaseAction {
    abstract handler: CRUDHandler

    get (page_size?: number, page?: number, filter = {}, group: string = ''): Promise<any> {
        return this.handler.get(page, page_size, filter, group)
    }

    getById (id: string): Promise<any> {
        return this.handler.getById(id)
    }

    create (data: object): Promise<any> {
        return this.handler.post(data)
    }

    update (data: object, id: string): Promise<any> {
        return this.handler.put(data, id)
    }

    partial_update (data: object, id: string): Promise<any> {
        return this.handler.patch(data, id)
    }

    delete(id: string): Promise<any> {
        return this.handler.delete(id)
    }

    deleteMultiple (ids: Array<string>): Promise<any> {
        return this.handler.deleteMultiple(ids)
    }
}
