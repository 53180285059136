import { Typography } from "@mui/material"
import ReportingPage from ".."


const ReportDashboard = () => {
    return (
        <ReportingPage>
            <Typography sx={{fontSize: '2rem', color: 'darkslategray', textAlign: 'center', fontWeight: 600}}> Welcome to your Reporting Dashbaord </Typography>
            <Typography sx={{fontSize: '1rem', color: 'darkslategray', textAlign: 'center', marginTop: '6rem'}}> No Reporting Stats to show yet </Typography>
        </ReportingPage>
    )
}

export default ReportDashboard