import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'

import { Franchisee } from '@utils/interfaces/interfaces'
import { DialogButton, DialogFormControl } from '@styles/Dialog'

import Form, { OnSubmitCallback } from "../Form"


const FranchiseeForm = (onSubmitCallback: OnSubmitCallback, values?: object) => {
    const franchisee_values = values as Franchisee

    const { t } = useTranslation('franchise_table')

    // values is an object passed in if we want the form to be populated with existing data. Typiically used for the edit form or in settings
    const createFields = (values?: object) => {
        return (
            <DialogFormControl sx={{padding: '1rem', paddingTop: 0}}>
            <Grid container rowGap={'2rem'}>
                {franchisee_values && <input type="hidden" value={franchisee_values.id} id="id" name="id"/>}
                <Box display={'flex'} gap={'1rem'} flexWrap={'wrap'} margin={'auto'}>
                <TextField 
                    id="royalties-percentage"
                    label={t('royalties')}
                    name="royalties_percentage"
                    variant="outlined"
                    defaultValue={franchisee_values?.royalties_percentage}
                    sx={{display: 'block'}}
                    autoComplete="off"
                    required
                />
                </Box>
                {values ? 
                    <DialogButton sx={{width: '100%'}} variant='contained' type="submit"> {t('edit')} </DialogButton>
                    : <DialogButton sx={{width: '100%'}} variant='contained' type="submit"> {t('create')} </DialogButton>
                }
            </Grid>
            </DialogFormControl>
        )
    }

    return (
        <Form onSubmitCallback={onSubmitCallback} createFields={() => createFields(values)}></Form>
    )
}

export default FranchiseeForm