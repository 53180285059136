import {useState, useContext, useEffect} from 'react'
import { useTranslation, } from 'react-i18next'

import Typography from '@mui/material/Typography'
import GppBadIcon from '@mui/icons-material/GppBad'

import { NumOfCompanyNotificationsContext } from '@contexts/NumOfNotificationsContext'
import { DialogButton, DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import { MessageContext } from '@contexts/MessageContext'
import CompanyNotificationActions from '@actions/CRUDActions/NotificationActions/companyNotifcationActions'
import {DialogProps} from '@utils/dialogProps/DialogProps'
import INastixTableContainer from '@components/tables/Table/INastixTableContainer'

import useCompanyNotificationInfo from './useCompanyNotificationInfo'


const CompanyDeclinetNotificationDialog = (props: DialogProps) => {
	const {ids, state_manager, onClose, open} = props

	const { t: tNotifications } = useTranslation('company_notifications_table')
	const { t: tCompanyGroups } = useTranslation('company_groups')

	const {setNumOfCompanyNotifications} = useContext(NumOfCompanyNotificationsContext)
	const {setMessage} = useContext(MessageContext)

	const {franchise_info, table_caption} = useCompanyNotificationInfo(ids, state_manager)

	const handleUnauthorize= () => {
		const company_notification_actions = new CompanyNotificationActions()
		company_notification_actions.unauthorize_companies(ids)
		.then(() => {
			state_manager.setDeleteMultipleAction(ids)
			setNumOfCompanyNotifications(props => props - ids.length)  // taking the total num of notifications and minusing it by the length of the deleted notifications
            handleClose()

			if (ids.length > 1)
				setMessage(props => ({...props, warning: tNotifications('companies_unauthorized')}))
			else
				setMessage(props => ({...props, warning: tNotifications('company_unauthorized')}))
		})
	}

	const handleClose = () => {
		onClose()
	}

	return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> {tNotifications('confirm_unauthorisation')} </INastixDialogTitle>
			<DialogDivider/>
			<br/>
			<Typography>{tNotifications('are_you_sure_you_want_to')} <b> {tNotifications('unauthorise')} {ids.length} {ids.length === 1 ? <span>{tNotifications('item')}</span> : <span>{tNotifications('items')}</span>}</b>?</Typography>
			<br/>
			{franchise_info  && <INastixTableContainer table_row_data={franchise_info} transFunc1={tNotifications} transFunc2={tCompanyGroups} caption={table_caption}/>}
			<DialogButton variant='contained' onClick={handleUnauthorize}> {tNotifications('unauthorise')} </DialogButton>
		</INastixDialog>
	)
}


const CompanyDeclineNotificationDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

	const { t: tNotifications } = useTranslation('company_notifications_table')

	const [isDisabled, setIsDisabled] = useState(disabled)
	const [open, setOpen] = useState(false)

	useEffect(() => {
		if (ids.length === 1) {
			setIsDisabled(false)
		}
		else if(ids.length < 1 || ids.length > 1) {
			setIsDisabled(true)
		}
	}, [ids])

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={isDisabled} onClick={handleClickOpen} startIcon={<GppBadIcon/>} border_color={'red'}>
			<Typography> {tNotifications('unauthorise')} </Typography>
		</TableActionButon>
		{open && <CompanyDeclinetNotificationDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>}
		</>
	)
}

export default CompanyDeclineNotificationDialogHandler