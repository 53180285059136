import { BaseAction } from "@actions/Actions/actions"
import ClassRegisterReportHandler from "@adapters/handlers/ReportHandlers/classRegisterReportHandler"


class ClassRegisterReportActions extends BaseAction {
    class_register_report_handler: ClassRegisterReportHandler

    constructor() {
        super()
        this.class_register_report_handler = new ClassRegisterReportHandler()
    }

    createClassRegisterReport (data: object): Promise<any> {
        return this.class_register_report_handler.createClassRegisterReport(data)
    }
}


export default ClassRegisterReportActions