import {useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Autocomplete from '@mui/material/Autocomplete'
import TextField  from '@mui/material/TextField'

import { Instructor } from '@utils/interfaces/interfaces'
import InstructorActions from '@actions/CRUDActions/InstructorActions/instructorActions'
import { getLocalStorageCompany } from '@utils/localStorage/company'

import log from 'loglevel'


interface SelectInstructorProp {
    instructor_ids?: number[]
    selectedInstructorsCallback?: (selected_instructor: Instructor[] | null) => void
}

const SelectInstructors = (props: SelectInstructorProp) => {
    const {instructor_ids, selectedInstructorsCallback} = props
    const { t } = useTranslation('class_form')

    const [instructors, setInstructors] = useState<Instructor[]>([])
    const [instructors_options, setInstructorsOptions] = useState<Instructor[]>([])

    useEffect(() => {
        const company = getLocalStorageCompany()
        const instructor_actions = new InstructorActions()
        instructor_actions.get(undefined, undefined, {'by_franchise_or_tenant_school_uuid': company.company_uuid.toString()})
        .then((instructors: Instructor[]) => {
            setInstructorsOptions(instructors)
            if (instructor_ids) {
                const initital_instructors = instructors.filter(instructor => instructor_ids.includes(instructor.user))
                setInstructors(initital_instructors)
                if (selectedInstructorsCallback)
                    selectedInstructorsCallback(initital_instructors)
            }
        })
        .catch(error => {
            log.error(error.message)
        })
    }, [])

    return (
        <Box sx={{ width:200 }}>
            <Autocomplete
                multiple
                value={instructors}
                options={instructors_options}
                isOptionEqualToValue={(option, value) => option.id === value.id} // Check if option's id is equal to the value's id
                getOptionLabel={(option) => option.username}
                renderInput={(params) => <TextField {...params } label={t('instructor')} />}
                onChange={(event, new_value) => {
                    setInstructors(new_value)
                    if (selectedInstructorsCallback)
                        selectedInstructorsCallback(new_value)
                }}
            />
        </Box>    
    )
}

export default SelectInstructors