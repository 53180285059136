import {useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next'

import log from 'loglevel'

import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import FormControl from '@mui/material/FormControl'

import { LocalStorageCompany } from '@utils/interfaces/interfaces'
import CompanyActions from '@actions/CompanyActions/companyActions'
import { companyIsInGroup } from '@utils/utils/util'
import { CompanyType } from '@utils/enums/enums'

import { CompanyLabel, SetCompanies } from '.'


const fetchCompanies = async (setCompanies: SetCompanies) => {
    const company_actions = new CompanyActions()
    company_actions.getAllCompanies()
    .then((data: LocalStorageCompany[]) => {
        const parsed_data = data
        .filter(company => !(
            company.tenant_name.includes("Demo Company") || 
            company.tenant_name.includes("Demo School") || 
            companyIsInGroup(company, [CompanyType.FRANCHISOR, CompanyType.SCHOOL]) || 
            company.is_demo_company || 
            company.is_test_company
        ))
        .map(company => ({
            label: company.tenant_name,
            company_uuid: company.company_uuid
        }))
        setCompanies(parsed_data)
    })
    .catch(error => {
        log.error(error.message)
    })
}

interface SelectAnExistingCompanyProps {
    selectedCompanyCallback?: (selected_company: CompanyLabel | null) => void
}

const SelectAnExistingConnectingSchoolCompany = (props: SelectAnExistingCompanyProps) => {
    const {selectedCompanyCallback} = props

    const { t } = useTranslation('set_up')

    const [company, setCompany] = useState<CompanyLabel | null>(null)
    const [companies, setCompanies] = useState<Array<CompanyLabel>>([])

    useEffect(() => {
        fetchCompanies(setCompanies)
    }, [])

    const handleChange = (event: any, new_value: CompanyLabel | null) => {
        setCompany(new_value)
        if (selectedCompanyCallback)
            selectedCompanyCallback(new_value)
    }

    return (
        <FormControl fullWidth>
            <Autocomplete
                disablePortal
                id="combo-box-companies"
                options={companies}
                value={company}
                onChange={handleChange}
                renderInput={(params) => 
                    <TextField 
                        {...params}
                        label={t('companies')}
                        placeholder={String(t('enter_company_name'))}
                    />
                }
            />
        </FormControl>
    )
}

export default SelectAnExistingConnectingSchoolCompany