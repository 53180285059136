import { useTranslation } from 'react-i18next'

import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { Parent } from '@utils/interfaces/interfaces';

interface HandleParentsProps {
    onSelectCallback?: (selected_parents: Parent[]) => void
    parents: Parent[]
    child_parents?: Parent[]
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const HandleParents = (props: HandleParentsProps) => {
    const {onSelectCallback, parents, child_parents} = props
    const { t } = useTranslation('child_form')
    
    const handleSelect = (event: any, value: Parent[]) => {
        if(onSelectCallback)
            onSelectCallback(value)
    }

    const parent_placeholder = t('parent')

    return (
        <Autocomplete
            multiple
            id="parents"
            options={parents}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label={t('parents')} placeholder={parent_placeholder} />
            )}
            value={child_parents}
            onChange={handleSelect}
            sx={{marginBottom: 2}}
            fullWidth
        />
    );
}

export default HandleParents