import { ContentType } from "@adapters/api/enums/enum"
import GenericFetchWrapper from "@adapters/api/fetchWrappers/genericFetchWrapper"
import { BaseHandler } from "@adapters/handlers/Handlers/handlers"
import { CLASS_REGISTER_REPORT_ENDPOINT } from "@adapters/routes/endpoints"


class ClassRegisterReportHandler extends BaseHandler {
    endpoint: string
    fetch_wrapper: GenericFetchWrapper

    constructor () {
        super()
        this.fetch_wrapper = new GenericFetchWrapper(ContentType.SPREADSHEET)
        this.endpoint = CLASS_REGISTER_REPORT_ENDPOINT
    }

    createClassRegisterReport (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.endpoint)
        return this.fetch_wrapper.post(parsed_endpoint, data)
    }
}

export default ClassRegisterReportHandler