import React from 'react'

import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Stack from '@mui/material/Stack'


interface PasswordRulesProps {
    password: string
}

//password rule check list that is displayed on sign up form
const PasswordRules: React.FC<PasswordRulesProps> =({password}) => {
    const has_lower_case = /[a-z]/.test(password)
    const has_upper_case = /[A-Z]/.test(password)
    const has_number = /\d/.test(password)
    const has_special_char = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password)
    const has_min_length = password.length >= 8;

    return(
        <>
        <Stack spacing={0} sx={{ '& > :not(style)': { ml: 1 }}}>
            <FormControlLabel
                control={<Checkbox checked={has_min_length}/>}
                label = "Use 8 or more characters"
            /> 
            <FormControlLabel
                control={<Checkbox checked={has_upper_case && has_lower_case}/>}
                label = "Use upper and lower case letters (e.g.Aa)"
            />
            <FormControlLabel
                control={<Checkbox checked={has_number}/>}
                label = "Use a number (e.g.1234)"
            />
            <FormControlLabel
                control={<Checkbox checked={has_special_char}/>}
                label = "Use a symbol(e.g.!@#$)"
            /> 
        </Stack> 
        </>
    )
}

export default PasswordRules