import { createContext } from 'react'

import { FZoneCart } from './Checkout/Cart'


export type SetCart = React.Dispatch<React.SetStateAction<FZoneCart | null>>

interface ICartContext {
    cart: FZoneCart | null
    setCart: SetCart
}

export const CartContext = createContext<ICartContext>({
    cart: null,
    setCart: () => {}
})