enum EmailType {
    MONKEYNASTIX = 'monkeynastix',
    SYSTEM_ERROR = 'system_error',
    TO_ANOTHER = 'to_another',
    TECHNICAL_SUPPORT = 'technical_support',
    CHILD_UNAUTHORISED = 'child_unauthorised',
    CHILD_AUTHORISED = 'child_authorised',
    CHILD_AWAITING_PAYMENT = 'child_awaiting_payment'
}

export {
    EmailType
}