import { CRUDAction } from "@actions/Actions/actions"
import RoyaltyHandler from "@adapters/handlers/FinanceHandlers/RoyaltyHandlers/royaltyHandlers"



class RoyaltyActions extends CRUDAction {
    handler: RoyaltyHandler

    constructor() {
        super()
        this.handler = new RoyaltyHandler()
    }
}

export default RoyaltyActions