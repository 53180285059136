 import React, {useState, useContext, useEffect} from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { Typography } from '@mui/material'

import { DialogButton, DialogFormControl } from '@styles/Dialog'
import { DialogFormSubHeadingTypography } from '@styles/Typography'
import { Address, CountryTerm, Franchisee } from '@utils/interfaces/interfaces'
import Contact from '@pages/Setup/CompanySetup/CompanyDetails/Contact'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import CompanyActions from '@actions/CompanyActions/companyActions'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import { INastixCurrencyMap } from '@utils/maps/maps'
import FranchiseeActions from '@actions/CRUDActions/FranchiseeActions/franchiseeActions'

import Form, { OnSubmitCallback } from "../Form"
import AddressFields from '../Fields/AddressFields'


enum SchoolType {
    BABYNASTIX = 'babynastix',
    MONKEYNASTIX = 'monkeynastix',
    SUPERNASTIX = 'supernastix'
}

export enum ExtracurricularActivitiyType {
    EXTRAMURAL = 'extramural',
    INTRAMURAL = 'intramural'
}

interface ContactDetails {
    country_code: string
    email: string
    phone_number: string
}

export interface NewSchool {
    id: string
    name: string
    tenant: string
    school_owner_name: string
    school_owner_email: string
    term_type: CountryTerm[]
    connected_company: string
    school_type: SchoolType
    tuition_fee: number
    termly_tuition_fee: number
    monthly_tuition_fee: number
    per_class_fee: number
    addresses: Address[]
    contacts: ContactDetails[]
    companies: string[]
    is_sponsored: boolean
    is_sponsored_termly: boolean
    is_sponsored_monthly: boolean
    is_reduced_registration: boolean
    registration_fee: number
    extracurricular_activitiy_type: ExtracurricularActivitiyType
    is_school_invoiced: boolean
    receives_certificates: boolean
    payment_structure: string
}

export interface Finance {
    extramural_fees: number[]
    intramural_fees: number[]
    per_class_fees: number[]
}

enum PaymentStructure {
    YEARLY = 'yearly',
    TERMLY = 'termly',
    MONTHLY = 'monthly'
}

const SchoolForm = (onSubmitCallback: OnSubmitCallback, values?: object) => {
    const school_values = values as NewSchool

    const company = getLocalStorageCompany()

    const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)

    const [name, setName] = useState('')
    const [owner_name, setOwnerName] = useState('')
    const [owner_email, setOwnerEmail] = useState('')
    const [activity_type, setActivityType] = useState('')
    const [payment_structure, setPaymentStructure] = useState('')

    const [is_sponsored_baby_yearly, setIsSponsoredBabyYearly] = useState(false)
    const [is_sponsored_baby_termly, setIsSponsoredBabyTermly] = useState(false)
    const [is_sponsored_baby_monthly, setIsSponsoredBabyMonthly] = useState(false)
    const [is_reduced_registration_baby, setIsReducedRegistrationBaby] = useState(false)
    const [yearly_sponsored_fee_baby, setYearlySponsoredFeeBaby] = useState('')
    const [termly_sponsored_fee_baby, setTermlySponsoredFeeBaby] = useState('')
    const [monthly_sponsored_fee_baby, setMonthlySponsoredFeeBaby] = useState('')
    const [registration_fee_baby, setReducedRegistrationFeeBaby] = useState('')
    const [yearly_fee_baby, setYearlyFeeBaby] = useState('')
    const [termly_fee_baby, setTermlyFeeBaby] = useState('')
    const [monthly_fee_baby, setMonthlyFeeBaby] = useState('')
    const [class_fee_baby, setClassFeeBaby] = useState('')

    const [is_sponsored_monkey_yearly, setIsSponsoredMonkeyYearly] = useState(false)
    const [is_sponsored_monkey_termly, setIsSponsoredMonkeyTermly] = useState(false)
    const [is_sponsored_monkey_monthly, setIsSponsoredMonkeyMonthly] = useState(false)
    const [is_reduced_registration_monkey, setIsReducedRegistrationMonkey] = useState(false)
    const [yearly_sponsored_fee_monkey, setYearlySponsoredFeeMonkey] = useState('')
    const [termly_sponsored_fee_monkey, setTermlySponsoredFeeMonkey] = useState('')
    const [monthly_sponsored_fee_monkey, setMonthlySponsoredFeeMonkey] = useState('')
    const [registration_fee_monkey, setReducedRegistrationFeeMonkey] = useState('')
    const [yearly_fee_monkey, setYearlyFeeMonkey] = useState('')
    const [termly_fee_monkey, setTermlyFeeMonkey] = useState('')
    const [monthly_fee_monkey, setMonthlyFeeMonkey] = useState('')
    const [class_fee_monkey, setClassFeeMonkey] = useState('')

    const [is_sponsored_super_yearly, setIsSponsoredSuperYearly] = useState(false)
    const [is_sponsored_super_termly, setIsSponsoredSuperTermly] = useState(false)
    const [is_sponsored_super_monthly, setIsSponsoredSuperMonthly] = useState(false)
    const [is_reduced_registration_super, setIsReducedRegistrationSuper] = useState(false)
    const [yearly_sponsored_fee_super, setYearlySponsoredFeeSuper] = useState('')
    const [termly_sponsored_fee_super, setTermlySponsoredFeeSuper] = useState('')
    const [monthly_sponsored_fee_super, setMonthlySponsoredFeeSuper] = useState('')
    const [registration_fee_super, setReducedRegistrationFeeSuper] = useState('')
    const [yearly_fee_super, setYearlyFeeSuper] = useState('')
    const [termly_fee_super, setTermlyFeeSuper] = useState('')
    const [monthly_fee_super, setMonthlyFeeSuper] = useState('')
    const [class_fee_super, setClassFeeSuper] = useState('')

    const [allow_yearly, setAllowYearly] = useState(false)
    const [allow_termly, setAllowTermly] = useState(false)
    const [allow_monthly, setAllowMonthly] = useState(false)
    const [allow_per_class, setAllowPerClass] = useState(false)

    const [extramural_fees, setExtramuralFees] = useState<Number[]>([0.00])
    const [intramural_fees, setIntramuralFees] = useState<Number[]>([0.00])
    const [fees, setFees] = useState<Number[]>([0.00])
    const [class_fees, setClassFees] = useState<Number[]>([0.00])

    const [country_terms, setCountryTerms] = useState<CountryTerm[]>([])
    const [terms, setTerms] = useState('')

    const [is_franchise_extramural, setIsFranchiseExtramural] = useState(false)
    const [is_franchise_intramural, setIsFranchiseIntramural] = useState(false)
    const [is_invoiced, setIsInvoiced] = useState(false)
    const [receives_certificates, setReceivesCertifcates] = useState(false)
    const [is_babynastix, setIsBabynastix] = useState(false)
    const [is_monkeynastix, setIsMonkeynastix] = useState(false)
    const [is_supernastix, setIsSupernastix] = useState(false)
    const [is_franchise_babynastix, setIsFranchiseBabynastix] = useState(false)
    const [is_franchise_monkeynastix, setIsFranchiseMonkeynastix] = useState(false)
    const [is_franchise_supernastix, setIsFranchiseSupernastix] = useState(false)

    const [num_of_terms, setNumOfTerms] = useState<number>()

    const [amount_of_types, setAmountOfTypes] = useState(0)

    const [contact_details, setContactDetails] = useState<ContactDetails>({
        country_code: '',
        email: '',
        phone_number: ''
    })
    const [address, setAddress] = useState({
        country: '',
        street: '',
        region: '',
        city: '',
        postal_code: '',
    })

    const [vat_exclusive, setVatExclusive] = useState('')

    const { t } = useTranslation('school_form')

    // Function to format a number to two decimal places
    function formatNumberToTwoDecimalPlaces(num: Number): string {
        let converted_num = Number(num).toFixed(2)
        return converted_num;
    }

    //Setting schools address, contact details and other variables if they exist
    useEffect(() => {
        if (school_values) {
            console.log(school_values)

            let is_sponsored_yearly = school_values.is_sponsored
            let is_sponsored_termly = school_values.is_sponsored_termly
            let is_sponsored_monthly = school_values.is_sponsored_monthly
            let is_reduced_registration = school_values.is_reduced_registration

            let yearly_fee = ''
            if (school_values.tuition_fee)
                yearly_fee = school_values.tuition_fee.toString()
            let termly_fee = ''
            if (school_values.termly_tuition_fee)
                termly_fee = school_values.termly_tuition_fee.toString()
            let monthly_fee = ''
            if (school_values.monthly_tuition_fee)
                monthly_fee = school_values.monthly_tuition_fee.toString()
            let per_class_fee = ''
            if(school_values.per_class_fee)
                per_class_fee = school_values.per_class_fee.toString()

            setName(school_values.name.toString())
            setOwnerName(school_values.school_owner_name.toString())
            setOwnerEmail(school_values.school_owner_email.toString())
            setTerms(school_values.term_type[0].id.toString())
            setNumOfTerms(school_values.term_type[0].num_of_terms)
            setActivityType(school_values.extracurricular_activitiy_type.toString())
            if (school_values.is_school_invoiced) {
                setIsInvoiced(school_values.is_school_invoiced)
            } else {
                setIsInvoiced(false)
            }
            if (school_values.receives_certificates) {
                setReceivesCertifcates(school_values.receives_certificates)
            } else {
                setReceivesCertifcates(false)
            }
            if (school_values.payment_structure) {
                setPaymentStructure(school_values.payment_structure.toString())
            } else {
                setPaymentStructure('')
            }
            
            let school_type = school_values.school_type.toString()
            if (school_type === 'babynastix') {
                setIsBabynastix(true)
                setIsMonkeynastix(false)
                setIsSupernastix(false)
                setAmountOfTypes(props => props + 1)
                setClassFeeBaby(per_class_fee)

                setIsSponsoredBabyYearly(is_sponsored_yearly)
                if (is_sponsored_yearly) {
                    setYearlySponsoredFeeBaby(yearly_fee)
                    setYearlyFeeBaby('')
                } else {
                    setYearlyFeeBaby(yearly_fee)
                    setYearlySponsoredFeeBaby('')
                }
                setIsSponsoredBabyTermly(is_sponsored_termly)
                if (is_sponsored_termly) {
                    setTermlySponsoredFeeBaby(termly_fee)
                    setTermlyFeeBaby('')
                } else {
                    setTermlyFeeBaby(termly_fee)
                    setTermlySponsoredFeeBaby('')
                }
                setIsSponsoredBabyMonthly(is_sponsored_monthly)
                if (is_sponsored_monthly) {
                    setMonthlySponsoredFeeBaby(monthly_fee)
                    setMonthlyFeeBaby('')
                } else {
                    setMonthlyFeeBaby(monthly_fee)
                    setMonthlySponsoredFeeBaby('')
                }
                
                setIsReducedRegistrationBaby(is_reduced_registration)
                if (is_reduced_registration) {
                    setReducedRegistrationFeeBaby(school_values.registration_fee.toString())
                } else {
                    setReducedRegistrationFeeBaby('')
                }
            } else if (school_type === 'monkeynastix') {
                setIsBabynastix(false)
                setIsMonkeynastix(true)
                setIsSupernastix(false)
                setAmountOfTypes(props => props + 1)
                setClassFeeMonkey(per_class_fee)
                
                setIsSponsoredMonkeyYearly(is_sponsored_yearly)
                if (is_sponsored_yearly) {
                    setYearlySponsoredFeeMonkey(yearly_fee)
                    setYearlyFeeMonkey('')
                } else {
                    setYearlyFeeMonkey(yearly_fee)
                    setYearlySponsoredFeeMonkey('')
                }
                setIsSponsoredMonkeyTermly(is_sponsored_termly)
                if (is_sponsored_termly) {
                    setTermlySponsoredFeeMonkey(termly_fee)
                    setTermlyFeeMonkey('')
                } else {
                    setTermlyFeeMonkey(termly_fee)
                    setTermlySponsoredFeeMonkey('')
                }
                setIsSponsoredMonkeyMonthly(is_sponsored_monthly)
                if (is_sponsored_monthly) {
                    setMonthlySponsoredFeeMonkey(monthly_fee)
                    setMonthlyFeeMonkey('')
                } else {
                    setMonthlyFeeMonkey(monthly_fee)
                    setMonthlySponsoredFeeMonkey('')
                }
                
                setIsReducedRegistrationMonkey(is_reduced_registration)
                if (is_reduced_registration) {
                    setReducedRegistrationFeeMonkey(school_values.registration_fee.toString())
                } else {
                    setReducedRegistrationFeeMonkey('')
                }
            } else if (school_type === 'supernastix') {
                setIsBabynastix(false)
                setIsMonkeynastix(false)
                setIsSupernastix(true)
                setAmountOfTypes(props => props + 1)
                setClassFeeSuper(per_class_fee)

                setIsSponsoredSuperYearly(is_sponsored_yearly)
                if (is_sponsored_yearly) {
                    setYearlySponsoredFeeSuper(yearly_fee)
                    setYearlyFeeSuper('')
                } else {
                    setYearlyFeeSuper(yearly_fee)
                    setYearlySponsoredFeeSuper('')
                }
                setIsSponsoredSuperTermly(is_sponsored_termly)
                if (is_sponsored_termly) {
                    setTermlySponsoredFeeSuper(termly_fee)
                    setTermlyFeeSuper('')
                } else {
                    setTermlyFeeSuper(termly_fee)
                    setTermlySponsoredFeeSuper('')
                }
                setIsSponsoredSuperMonthly(is_sponsored_monthly)
                if (is_sponsored_monthly) {
                    setMonthlySponsoredFeeSuper(monthly_fee)
                    setMonthlyFeeSuper('')
                } else {
                    setMonthlyFeeSuper(monthly_fee)
                    setMonthlySponsoredFeeSuper('')
                }
                
                setIsReducedRegistrationSuper(is_reduced_registration)
                if (is_reduced_registration) {
                    setReducedRegistrationFeeSuper(school_values.registration_fee.toString())
                } else {
                    setReducedRegistrationFeeSuper('')
                }
            }

            const address = school_values.addresses[0]
            setAddress({
                country: address.country,
                street: address.street,
                region: address.region,
                city: address.city,
                postal_code: address.postal_code,
            })

            const contact_details = school_values.contacts[0]
            if (contact_details) {
                setContactDetails({
                    country_code: contact_details.country_code,
                    email: contact_details.email,
                    phone_number: contact_details.phone_number,
                })
            }
        }
    }, [school_values, change_company_rerender])

    //Getting franchises finance model to set all the lists of fees that could be displayed
    useEffect(() => {
        const company = getLocalStorageCompany()

        const company_actions = new CompanyActions()
        const franchisee_actions = new FranchiseeActions()

        let company_id = company.company_uuid.toString()
        let company_country_terms = company.country_terms

        const filterCountryTerms = (company_country_terms: number[]) => {
            const company_actions = new CompanyActions()
            if (company.country !== null) {
                company_actions.getCountryTerms(company.country)
                .then((country_terms: CountryTerm[]) => {
                    const filtered_country_terms = country_terms.filter(obj => company_country_terms.includes(obj.id))
                    setCountryTerms(filtered_country_terms)
                }) 
            }
        }

        setIsFranchiseBabynastix(company.is_babynastix)
        setIsFranchiseMonkeynastix(company.is_monkeynastix)
        setIsFranchiseSupernastix(company.is_supernastix)
        if (school_values) {
            if (school_values.connected_company.toString() !== company.company_uuid.toString()) {
                company_id = school_values.connected_company.toString()
                franchisee_actions.getById(school_values.connected_company.toString())
                .then((data: Franchisee) => {
                    console.log(data)
                    setIsFranchiseBabynastix(data.is_babynastix)
                    setIsFranchiseMonkeynastix(data.is_monkeynastix)
                    setIsFranchiseSupernastix(data.is_supernastix)
                    filterCountryTerms(data.country_terms)
                })
            } else {
                filterCountryTerms(company_country_terms)
            }
        } else {
            filterCountryTerms(company_country_terms)
        }
        
        company_actions.getCompanySettings(company_id)
        .then(data => {
            const finance = data.finance
            setAllowYearly(finance.allows_yearly_fees)
            setAllowTermly(finance.allows_termly_fees)
            setAllowMonthly(finance.allows_monthly_fees)
            setAllowPerClass(finance.allows_per_class_fees)
            if (finance.vat_registered) {
                setVatExclusive('VAT Excl.')
            }
            if (finance.is_extramural) {
                setIsFranchiseExtramural(finance.is_extramural)
                setExtramuralFees(finance.extramural_fees.map(formatNumberToTwoDecimalPlaces))
            } else {setIsFranchiseExtramural(false)}
            if (finance.is_intramural) {
                setIsFranchiseIntramural(finance.is_intramural)
                setIntramuralFees(finance.intramural_fees.map(formatNumberToTwoDecimalPlaces))
            } else (setIsFranchiseIntramural(false))
            setClassFees(finance.per_class_fees.map(formatNumberToTwoDecimalPlaces))
        })
    }, [school_values, change_company_rerender])

    //Changing set of fees to either extramural or intramural based on which activity type the user has chosen
    useEffect(() => {
        if (activity_type === ExtracurricularActivitiyType.INTRAMURAL) {
            setFees(intramural_fees)
        } else {
            setFees(extramural_fees)
        }
    }, [activity_type, intramural_fees, extramural_fees])

    const handleActivityTypeChange = (event: SelectChangeEvent) => {
        setActivityType(event.target.value as string)
    }

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value)
    }

    const handleOwnerNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOwnerName(event.target.value)
    }

    const handleOwnerEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setOwnerEmail(event.target.value)
    }

    const handleCountryTermChange = (event: SelectChangeEvent) => {
        const country_term = country_terms.find(obj => obj.id === Number(event.target.value))
        if (country_term) {
            setNumOfTerms(country_term.num_of_terms)
        }
        setTerms(event.target.value as string)
    }

    const handleIsBabynastixChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsBabynastix(event.target.checked)
        console.log(event.target.checked)
        if (event.target.checked) {
            setAmountOfTypes(a => amount_of_types + 1)
        } else {
            setAmountOfTypes(a => amount_of_types - 1)
        }
    }
    
    const handleIsMonkeynastixChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsMonkeynastix(event.target.checked)
        console.log(event.target.checked)
        if (event.target.checked) {
            setAmountOfTypes(a => amount_of_types + 1)
        } else {
            setAmountOfTypes(a => amount_of_types - 1)
        }
    }

    const handleIsSupernastixChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsSupernastix(event.target.checked)
        console.log(event.target.checked)
        if (event.target.checked) {
            setAmountOfTypes(a => amount_of_types + 1)
        } else {
            setAmountOfTypes(a => amount_of_types - 1)
        }
    }

    const handleFeeChange = (event: SelectChangeEvent) => {
        console.log(event.target.name)
        if (event.target.name === 'yearly_fee_baby')
            setYearlyFeeBaby(event.target.value as string)
        else if (event.target.name === 'termly_fee_baby')
            setTermlyFeeBaby(event.target.value as string)
        else if (event.target.name === 'monthly_fee_baby')
            setMonthlyFeeBaby(event.target.value as string)

        else if (event.target.name === 'yearly_fee_monkey')
            setYearlyFeeMonkey(event.target.value as string)
        else if (event.target.name === 'termly_fee_monkey')
            setTermlyFeeMonkey(event.target.value as string)
        else if (event.target.name === 'monthly_fee_monkey')
            setMonthlyFeeMonkey(event.target.value as string)

        else if (event.target.name === 'yearly_fee_super')
            setYearlyFeeSuper(event.target.value as string)
        else if (event.target.name === 'termly_fee_super')
            setTermlyFeeSuper(event.target.value as string)
        else if (event.target.name === 'monthly_fee_super')
            setMonthlyFeeSuper(event.target.value as string)
    }

    const handleClassFeeChange = (event: SelectChangeEvent) => {
        console.log(event.target.name)
        if (event.target.name === 'per_class_fee_baby')
            setClassFeeBaby(event.target.value as string)
        else if (event.target.name === 'per_class_fee_monkey')
            setClassFeeMonkey(event.target.value as string)
        else if (event.target.name === 'per_class_fee_super')
            setClassFeeSuper(event.target.value as string)
    }

    const handleIsSponsoredChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.name)
        if (event.target.name === 'is_sponsored_baby_yearly')
            setIsSponsoredBabyYearly(event.target.checked)
        else if (event.target.name === 'is_sponsored_baby_termly')
            setIsSponsoredBabyTermly(event.target.checked)
        else if (event.target.name === 'is_sponsored_baby_monthly')
            setIsSponsoredBabyMonthly(event.target.checked)

        else if (event.target.name === 'is_sponsored_monkey_yearly')
            setIsSponsoredMonkeyYearly(event.target.checked)
        else if (event.target.name === 'is_sponsored_monkey_termly')
            setIsSponsoredMonkeyTermly(event.target.checked)
        else if (event.target.name === 'is_sponsored_monkey_monthly')
            setIsSponsoredMonkeyMonthly(event.target.checked)

        else if (event.target.name === 'is_sponsored_super_yearly')
            setIsSponsoredSuperYearly(event.target.checked)
        else if (event.target.name === 'is_sponsored_super_termly')
            setIsSponsoredSuperTermly(event.target.checked)
        else if (event.target.name === 'is_sponsored_super_monthly')
            setIsSponsoredSuperMonthly(event.target.checked)
    }

    const handleIsInvoicedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsInvoiced(event.target.checked)
    }

    const handleReceivesCertificatesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setReceivesCertifcates(event.target.checked)
    }

    const handlePaymentStructureChange = (event: SelectChangeEvent) => {
        setPaymentStructure(event.target.value as string)
    }

    const handleIsReducedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.name)
        if (event.target.name === 'is_reduced_registration_baby')
            setIsReducedRegistrationBaby(event.target.checked)
        else if (event.target.name === 'is_reduced_registration_monkey')
            setIsReducedRegistrationMonkey(event.target.checked)
        else if (event.target.name === 'is_reduced_registration_super')
            setIsReducedRegistrationSuper(event.target.checked)
    }

    const handleSponsoredFeeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.name)
        if (event.target.name === 'yearly_sponsored_fee_baby')
            setYearlySponsoredFeeBaby(event.target.value)
        else if (event.target.name === 'termly_sponsored_fee_baby')
            setTermlySponsoredFeeBaby(event.target.value)
        else if (event.target.name === 'monthly_sponsored_fee_baby')
            setMonthlySponsoredFeeBaby(event.target.value)

        else if (event.target.name === 'yearly_sponsored_fee_monkey')
            setYearlySponsoredFeeMonkey(event.target.value)
        else if (event.target.name === 'termly_sponsored_fee_monkey')
            setTermlySponsoredFeeMonkey(event.target.value)
        else if (event.target.name === 'monthly_sponsored_fee_monkey')
            setMonthlySponsoredFeeMonkey(event.target.value)
        
        else if (event.target.name === 'yearly_sponsored_fee_super')
            setYearlySponsoredFeeSuper(event.target.value)
        else if (event.target.name === 'termly_sponsored_fee_super')
            setTermlySponsoredFeeSuper(event.target.value)
        else if (event.target.name === 'monthly_sponsored_fee_super')
            setMonthlySponsoredFeeSuper(event.target.value)
    }

    const handleReducedRegistrationFeeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.name)
        if (event.target.name === 'registration_fee_baby')
            setReducedRegistrationFeeBaby(event.target.value)
        if (event.target.name === 'registration_fee_monkey')
            setReducedRegistrationFeeMonkey(event.target.value)
        if (event.target.name === 'registration_fee_super')
            setReducedRegistrationFeeSuper(event.target.value)
    }
    const currency_symbol = INastixCurrencyMap[company.currency]

    // values is an object passed in if we want the form to be populated with existing data. Typiically used for the edit form or in settings
    const createFields = (values?: object) => {

        return (
            <DialogFormControl>
            <Grid container spacing={2} justifyContent={'center'}>
                {values && <input type="hidden" value={school_values.id} id="id" name="id"/>}
                <Grid item md={12} sx={{justifyContent: 'center', display: 'flex'}}>
                    <DialogFormSubHeadingTypography variant="h3"> {t('details')} </DialogFormSubHeadingTypography>
                </Grid>
                <Grid item md={6}>
                    <TextField 
                        id="school-name"
                        label={t('school_name')} 
                        name="name"
                        variant="outlined"
                        onChange={handleNameChange}
                        value={name}
                        sx={{marginBottom: 2}}
                        required
                        autoComplete="off"
                        fullWidth
                    />
                    <TextField 
                        id="school_owner_name"
                        label={t('school_owner_name')} 
                        name="school_owner_name"
                        variant="outlined"
                        onChange={handleOwnerNameChange}
                        value={owner_name}
                        sx={{marginBottom: 2}}
                        required
                        autoComplete="off"
                        fullWidth
                    />
                    <TextField
                        id="owner_email"
                        name="school_owner_email"
                        label={t('owner_email')}
                        variant="outlined"
                        placeholder="example@email.com"
                        onChange={handleOwnerEmailChange}
                        value={owner_email}
                        sx={{marginBottom: 2}}
                        required
                        autoComplete="off"
                        fullWidth
                    />
                    <br/>
                    
                </Grid>
                <Grid item md={6} sm={12} xs={12}>
                    <FormControl fullWidth>
                        <InputLabel id="activity-type-select-label">{t('activity_type')}</InputLabel>
                        <Select
                            labelId="activity-type-select-label"
                            id="activity-type-select"
                            name="extracurricular_activitiy_type"
                            value={activity_type as string}
                            label={t('activity_type')}
                            onChange={handleActivityTypeChange}
                            sx={{marginBottom: 2}}
                            required
                        >
                            { is_franchise_extramural && <MenuItem value={ExtracurricularActivitiyType.EXTRAMURAL}>{t('extramural')}</MenuItem> }
                            { is_franchise_intramural && <MenuItem value={ExtracurricularActivitiyType.INTRAMURAL}>{t('intramural')}</MenuItem> }
                        </Select>
                    </FormControl>
                    { activity_type === ExtracurricularActivitiyType.INTRAMURAL &&
                        <FormControlLabel 
                            control={
                                <Checkbox 
                                    name='is_invoiced' 
                                    checked={is_invoiced}
                                    onChange={handleIsInvoicedChange}
                                />
                            } 
                            label={t('school_invoices_the_members')}
                        />
                    }
                    <FormControlLabel 
                        control={
                            <Checkbox 
                                name='receives_certificates' 
                                checked={receives_certificates}
                                onChange={handleReceivesCertificatesChange}
                            />
                        } 
                        sx={{marginBottom: '0.5rem'}}
                        label={t('school_receives_member_certificates')}
                    />
                    { is_invoiced && 
                        <FormControl fullWidth>
                            <InputLabel id="payment-structure-select-label">{t('payment_structure')}</InputLabel>
                            <Select
                                labelId="payment-structure-select-label"
                                id="payment-structure-select"
                                name="payment_structure"
                                value={payment_structure as string}
                                label={t('payment_structure')}
                                onChange={handlePaymentStructureChange}
                                sx={{marginBottom: 2}}
                                required
                            >
                                {allow_yearly && <MenuItem value={PaymentStructure.YEARLY}>{t('yearly')}</MenuItem>}
                                {allow_termly && <MenuItem value={PaymentStructure.TERMLY}>{t('termly')}</MenuItem>}
                                {allow_monthly && <MenuItem value={PaymentStructure.MONTHLY}>{t('monthly')}</MenuItem>}
                            </Select>
                        </FormControl>
                    }
                    <FormControl fullWidth>
                        <InputLabel id="country-term-type-select-label">{t('terms')}</InputLabel>
                        <Select
                            labelId="country-term-type-select-label"
                            id="country-term-type-select"
                            value={terms}
                            name="country_term"
                            label={t('terms')}
                            onChange={handleCountryTermChange}
                            required
                        >
                            {country_terms.map((country_term: CountryTerm) => (
                                <MenuItem key={country_term.id} value={country_term.id}>{t(country_term.name)}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item md={12} sm={12} xs={12} sx={{justifyContent: 'center', display: 'flex'}}>
                    <DialogFormSubHeadingTypography variant="h3" marginTop={'1rem'} marginBottom={'0rem !important'}> {t('school_types')} </DialogFormSubHeadingTypography>
                </Grid>
                <Grid item md={12} sm={12} xs={12} sx={{display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap'}}>
                    { !values && is_franchise_babynastix &&
                        <FormControlLabel 
                            control={
                                <Checkbox 
                                    name='is_babynastix' 
                                    checked={is_babynastix}
                                    onChange={handleIsBabynastixChange}
                                />
                            } 
                            label="Babynastix"
                        />
                    }
                    { !values && is_franchise_monkeynastix &&
                        <FormControlLabel 
                            control={
                                <Checkbox 
                                    name='is_monkeynastix' 
                                    checked={is_monkeynastix}
                                    onChange={handleIsMonkeynastixChange}
                                />
                            } 
                            label="Monkeynastix"
                        />
                    }
                    { !values && is_franchise_supernastix &&
                        <FormControlLabel 
                            control={
                                <Checkbox 
                                    name='is_supernastix' 
                                    checked={is_supernastix}
                                    onChange={handleIsSupernastixChange}
                                />
                            } 
                            label="Supernastix"
                        />
                    }
                </Grid>
                {(is_babynastix || is_monkeynastix || is_supernastix) &&
                    <Grid item md={12} sm={12} xs={12} sx={{justifyContent: 'center', display: 'flex'}}>
                        <DialogFormSubHeadingTypography variant="h3" marginTop={'1rem'} marginBottom={'0 !important'}> {t('fees')} </DialogFormSubHeadingTypography>
                    </Grid>
                }
                { is_babynastix &&
                    <Grid item md={6} sm={12}>
                        { (amount_of_types !== 1) && <DialogFormSubHeadingTypography fontSize={'1rem !important'} variant="h5"> Babynastix </DialogFormSubHeadingTypography> }
                        { allow_per_class &&
                        <FormControl fullWidth>
                            <InputLabel id="class-fee-select-label">{t('class_fee')} ({currency_symbol}) {vat_exclusive}</InputLabel>
                            <Select
                                labelId='class-fee-select-label'
                                id='class-fee-select'
                                name='per_class_fee_baby'
                                value={class_fee_baby}
                                label={`${t('class_fee')} (${currency_symbol}) ${vat_exclusive}`}
                                onChange={handleClassFeeChange}
                                inputProps={{ step: 0.01 }}
                                sx={{marginBottom: 2}}
                                required
                            >
                                {class_fees.map(fee => (
                                    <MenuItem key={`class_fee_${fee}`} value={fee.toString()}>{fee.toString()}</MenuItem>
                                ))}
                            </Select>
                        </FormControl> 
                        }
                        {/* --------------------------------------------------------------------------------- */}
                        { allow_yearly && <>
                            <FormControlLabel 
                                control={
                                    <Checkbox 
                                        name='is_sponsored_baby_yearly' 
                                        checked={is_sponsored_baby_yearly}
                                        onChange={handleIsSponsoredChange}
                                    />
                                } 
                                label={t("is_sponsored_yearly")} 
                            />
                            { !is_sponsored_baby_yearly && activity_type !== '' ?
                                <FormControl fullWidth>
                                    <InputLabel id="yearly-baby-fee-select-label">{t('yearly_fee')} ({currency_symbol})</InputLabel>
                                    <Select
                                        labelId='yearly-baby-fee-select-label'
                                        id='yearly-baby-fee-select'
                                        name='yearly_fee_baby'
                                        value={yearly_fee_baby}
                                        label={`${t('yearly_fee')} (${currency_symbol})`}
                                        onChange={handleFeeChange}
                                        inputProps={{ step: 0.01 }}
                                        sx={{marginBottom: 2}}
                                        autoComplete='off'
                                        required
                                    >
                                        {fees.map(fee => (
                                            <MenuItem key={`fee_${fee}`} value={fee.toString()}>{fee.toString()}</MenuItem>
                                        ))}
                                    </Select>

                                </FormControl> 
                                : null }
                            { is_sponsored_baby_yearly ?
                                <TextField 
                                    id="yearly-sponsored-fee"
                                    label={`${t('yearly_sponsored_fee')} (${currency_symbol})`} 
                                    name="yearly_sponsored_fee_baby"
                                    variant="outlined"
                                    type="number"
                                    value={yearly_sponsored_fee_baby}
                                    onChange={handleSponsoredFeeChange}
                                    inputProps={{ step: 0.01 }}
                                    sx={{marginBottom: 2}}
                                    required
                                    fullWidth
                                />
                            : null }
                        </> }
                        {/* ------------------------------ */}
                        { allow_termly && <>
                            <FormControlLabel 
                                control={
                                    <Checkbox 
                                        name='is_sponsored_baby_termly' 
                                        checked={is_sponsored_baby_termly}
                                        onChange={handleIsSponsoredChange}
                                    />
                                } 
                                label={t("is_sponsored_termly")} 
                            />
                            { !is_sponsored_baby_termly && activity_type !== '' ?
                                <FormControl fullWidth>
                                    <InputLabel id="termly-baby-fee-select-label">{t('termly_fee')} ({currency_symbol})</InputLabel>
                                    <Select
                                        labelId='termly-baby-fee-select-label'
                                        id='termly-baby-fee-select'
                                        name='termly_fee_baby'
                                        value={termly_fee_baby}
                                        label={`${t('termly_fee')} (${currency_symbol})`}
                                        onChange={handleFeeChange}
                                        inputProps={{ step: 0.01 }}
                                        sx={{marginBottom: 2}}
                                        autoComplete='off'
                                        required
                                    >
                                        {fees.map(fee => (
                                            <MenuItem key={`fee_${fee}`} value={fee.toString()}>{fee.toString()}</MenuItem>
                                        ))}
                                    </Select>
                                    { parseFloat(termly_fee_baby) > 0 && num_of_terms &&
                                        <Typography>{num_of_terms.toString()} invoices of {currency_symbol}{(parseFloat(termly_fee_baby) / num_of_terms).toFixed(2)}</Typography>
                                    }
                                    <br/>
                                </FormControl> 
                                : null }
                            { is_sponsored_baby_termly ?
                                <>
                                    <TextField 
                                        id="termly-sponsored-fee"
                                        label={`${t('termly_sponsored_fee')} (${currency_symbol})`} 
                                        name="termly_sponsored_fee_baby"
                                        variant="outlined"
                                        type="number"
                                        value={termly_sponsored_fee_baby}
                                        onChange={handleSponsoredFeeChange}
                                        inputProps={{ step: 0.01 }}
                                        sx={{marginBottom: 2}}
                                        required
                                        fullWidth
                                    />
                                    { parseFloat(termly_sponsored_fee_baby) > 0 && num_of_terms &&
                                        <Typography>{num_of_terms.toString()} invoices of {currency_symbol}{(parseFloat(termly_sponsored_fee_baby) / num_of_terms).toFixed(2)}</Typography>
                                    }
                                    <br/>
                                </>
                            : null }
                        </> }
                        {/* ------------------------------ */}
                        { allow_monthly && <>
                            <FormControlLabel 
                                control={
                                    <Checkbox 
                                        name='is_sponsored_baby_monthly' 
                                        checked={is_sponsored_baby_monthly}
                                        onChange={handleIsSponsoredChange}
                                    />
                                } 
                                label={t("is_sponsored_monthly")} 
                            />
                            { !is_sponsored_baby_monthly && activity_type !== '' ?
                                <FormControl fullWidth>
                                    <InputLabel id="monthly-baby-fee-select-label">{t('monthly_fee')} ({currency_symbol})</InputLabel>
                                    <Select
                                        labelId='monthly-baby-fee-select-label'
                                        id='monthly-baby-fee-select'
                                        name='monthly_fee_baby'
                                        value={monthly_fee_baby}
                                        label={`${t('monthly_fee')} (${currency_symbol})`}
                                        onChange={handleFeeChange}
                                        inputProps={{ step: 0.01 }}
                                        sx={{marginBottom: 2}}
                                        autoComplete='off'
                                        required
                                    >
                                        {fees.map(fee => (
                                            <MenuItem key={`fee_${fee}`} value={fee.toString()}>{fee.toString()}</MenuItem>
                                        ))}
                                    </Select>
                                    { parseFloat(monthly_fee_baby) > 0 && <Typography>10 invoices of {currency_symbol}{(parseFloat(monthly_fee_baby) / 10).toFixed(2)}</Typography>}
                                </FormControl> 
                                : null }
                            { is_sponsored_baby_monthly ?
                                <>
                                    <TextField 
                                        id="monthly-sponsored-fee"
                                        label={`${t('monthly_sponsored_fee')} (${currency_symbol})`} 
                                        name="monthly_sponsored_fee_baby"
                                        variant="outlined"
                                        type="number"
                                        value={monthly_sponsored_fee_baby}
                                        onChange={handleSponsoredFeeChange}
                                        inputProps={{ step: 0.01 }}
                                        sx={{marginBottom: 2}}
                                        required
                                        fullWidth
                                    />
                                    { parseFloat(monthly_sponsored_fee_baby) > 0 && <Typography>10 invoices of {currency_symbol}{(parseFloat(monthly_sponsored_fee_baby) / 10).toFixed(2)}</Typography>}
                                    <br/>
                                </>
                            : null }
                        </> }
                        {/* --------------------------------------------------------------------------------- */}
                        <FormControlLabel 
                            control={<Checkbox name='is_reduced_registration_baby' checked={is_reduced_registration_baby} onChange={handleIsReducedChange}/>} 
                            label={t('is_reduced_registration_fee')} 
                            sx={{marginBottom: '0.5rem'}}
                        />
                        { is_reduced_registration_baby ?
                            <TextField 
                                id="reduced-registration-fee"
                                label={`Reduced ${t('registration_fee')} (${currency_symbol})`} 
                                name="registration_fee_baby"
                                variant="outlined"
                                type="number"
                                value={registration_fee_baby}
                                onChange={handleReducedRegistrationFeeChange}
                                inputProps={{ step: 0.01 }}
                                sx={{marginBottom: 2}}
                                required
                                autoComplete='off'
                                fullWidth
                            />
                            : null 
                        }
                    </Grid>
                }
                { is_monkeynastix && 
                    <Grid item md={6} sm={12}>
                        { (amount_of_types !== 1) && <DialogFormSubHeadingTypography fontSize={'1rem !important'} variant="h5"> Monkeynastix </DialogFormSubHeadingTypography> }
                        { allow_per_class &&
                            <FormControl fullWidth>
                                <InputLabel id="class-fee-select-label">{t('class_fee')} ({currency_symbol}) {vat_exclusive}</InputLabel>
                                <Select
                                    labelId='class-fee-select-label'
                                    id='class-fee-select'
                                    name='per_class_fee_monkey'
                                    value={class_fee_monkey}
                                    label={`${t('class_fee')} (${currency_symbol}) ${vat_exclusive}`}
                                    onChange={handleClassFeeChange}
                                    inputProps={{ step: 0.01 }}
                                    sx={{marginBottom: 2}}
                                    required
                                >
                                    {class_fees.map(fee => (
                                        <MenuItem key={`class_fee_${fee}`} value={fee.toString()}>{fee.toString()}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl> 
                        }
                        {/* --------------------------------------------------------------------------------- */}
                        { allow_yearly && <>
                            <FormControlLabel 
                                control={
                                    <Checkbox 
                                        name='is_sponsored_monkey_yearly' 
                                        checked={is_sponsored_monkey_yearly}
                                        onChange={handleIsSponsoredChange}
                                    />
                                } 
                                label={t("is_sponsored_yearly")} 
                            />
                            { !is_sponsored_monkey_yearly && activity_type !== '' ?
                                <FormControl fullWidth>
                                    <InputLabel id="yearly-monkey-fee-select-label">{t('yearly_fee')} ({currency_symbol})</InputLabel>
                                    <Select
                                        labelId='yearly-monkey-fee-select-label'
                                        id='yearly-monkey-fee-select'
                                        name='yearly_fee_monkey'
                                        value={yearly_fee_monkey}
                                        label={`${t('yearly_fee')} (${currency_symbol})`}
                                        onChange={handleFeeChange}
                                        inputProps={{ step: 0.01 }}
                                        sx={{marginBottom: 2}}
                                        autoComplete='off'
                                        required
                                    >
                                        {fees.map(fee => (
                                            <MenuItem key={`fee_${fee}`} value={fee.toString()}>{fee.toString()}</MenuItem>
                                        ))}
                                    </Select>

                                </FormControl> 
                                : null }
                            { is_sponsored_monkey_yearly ?
                                <TextField 
                                    id="yearly-sponsored-fee"
                                    label={`${t('yearly_sponsored_fee')} (${currency_symbol})`} 
                                    name="yearly_sponsored_fee_monkey"
                                    variant="outlined"
                                    type="number"
                                    value={yearly_sponsored_fee_monkey}
                                    onChange={handleSponsoredFeeChange}
                                    inputProps={{ step: 0.01 }}
                                    sx={{marginBottom: 2}}
                                    required
                                    fullWidth
                                />
                            : null }
                        </> }
                        {/* ------------------------------ */}
                        { allow_termly && <>
                            <FormControlLabel 
                                control={
                                    <Checkbox 
                                        name='is_sponsored_monkey_termly' 
                                        checked={is_sponsored_monkey_termly}
                                        onChange={handleIsSponsoredChange}
                                    />
                                } 
                                label={t("is_sponsored_termly")} 
                            />
                            { !is_sponsored_monkey_termly && activity_type !== '' ?
                                <FormControl fullWidth>
                                    <InputLabel id="termly-monkey-fee-select-label">{t('termly_fee')} ({currency_symbol})</InputLabel>
                                    <Select
                                        labelId='termly-monkey-fee-select-label'
                                        id='termly-monkey-fee-select'
                                        name='termly_fee_monkey'
                                        value={termly_fee_monkey}
                                        label={`${t('termly_fee')} (${currency_symbol})`}
                                        onChange={handleFeeChange}
                                        inputProps={{ step: 0.01 }}
                                        sx={{marginBottom: 2}}
                                        autoComplete='off'
                                        required
                                    >
                                        {fees.map(fee => (
                                            <MenuItem key={`fee_${fee}`} value={fee.toString()}>{fee.toString()}</MenuItem>
                                        ))}
                                    </Select>
                                    { parseFloat(termly_fee_monkey) > 0 && num_of_terms &&
                                        <Typography>{num_of_terms.toString()} invoices of {currency_symbol}{(parseFloat(termly_fee_monkey) / num_of_terms).toFixed(2)}</Typography>
                                    }
                                </FormControl> 
                                : null }
                            { is_sponsored_monkey_termly ?
                                <>
                                    <TextField 
                                        id="termly-sponsored-fee"
                                        label={`${t('termly_sponsored_fee')} (${currency_symbol})`} 
                                        name="termly_sponsored_fee_monkey"
                                        variant="outlined"
                                        type="number"
                                        value={termly_sponsored_fee_monkey}
                                        onChange={handleSponsoredFeeChange}
                                        inputProps={{ step: 0.01 }}
                                        sx={{marginBottom: 2}}
                                        required
                                        fullWidth
                                    />
                                    { parseFloat(termly_sponsored_fee_monkey) > 0 && num_of_terms &&
                                        <Typography>{num_of_terms.toString()} invoices of {currency_symbol}{(parseFloat(termly_sponsored_fee_monkey) / num_of_terms).toFixed(2)}</Typography>
                                    }
                                    <br/>
                                </>
                            : null }
                        </> }
                        {/* ------------------------------ */}
                        { allow_monthly && <>
                            <FormControlLabel 
                                control={
                                    <Checkbox 
                                        name='is_sponsored_monkey_monthly' 
                                        checked={is_sponsored_monkey_monthly}
                                        onChange={handleIsSponsoredChange}
                                    />
                                } 
                                label={t("is_sponsored_monthly")} 
                            />
                            { !is_sponsored_monkey_monthly && activity_type !== '' ?
                                <FormControl fullWidth>
                                    <InputLabel id="monthly-monkey-fee-select-label">{t('monthly_fee')} ({currency_symbol})</InputLabel>
                                    <Select
                                        labelId='monthly-monkey-fee-select-label'
                                        id='monthly-monkey-fee-select'
                                        name='monthly_fee_monkey'
                                        value={monthly_fee_monkey}
                                        label={`${t('monthly_fee')} (${currency_symbol})`}
                                        onChange={handleFeeChange}
                                        inputProps={{ step: 0.01 }}
                                        sx={{marginBottom: 2}}
                                        autoComplete='off'
                                        required
                                    >
                                        {fees.map(fee => (
                                            <MenuItem key={`fee_${fee}`} value={fee.toString()}>{fee.toString()}</MenuItem>
                                        ))}
                                    </Select>
                                    { parseFloat(monthly_fee_monkey) > 0 && <Typography>10 invoices of {currency_symbol}{(parseFloat(monthly_fee_monkey) / 10).toFixed(2)}</Typography>}
                                </FormControl> 
                                : null }
                            { is_sponsored_monkey_monthly ?
                                <>
                                    <TextField 
                                        id="monthly-sponsored-fee"
                                        label={`${t('monthly_sponsored_fee')} (${currency_symbol})`} 
                                        name="monthly_sponsored_fee_monkey"
                                        variant="outlined"
                                        type="number"
                                        value={monthly_sponsored_fee_monkey}
                                        onChange={handleSponsoredFeeChange}
                                        inputProps={{ step: 0.01 }}
                                        sx={{marginBottom: 2}}
                                        required
                                        fullWidth
                                    />
                                    { parseFloat(monthly_sponsored_fee_monkey) > 0 && <Typography>10 invoices of {currency_symbol}{(parseFloat(monthly_sponsored_fee_monkey) / 10).toFixed(2)}</Typography>}
                                    <br/>
                                </>
                            : null }
                        </> }
                        {/* --------------------------------------------------------------------------------- */}
                        <FormControlLabel 
                            control={<Checkbox name='is_reduced_registration_monkey' checked={is_reduced_registration_monkey} onChange={handleIsReducedChange}/>} 
                            label={t('is_reduced_registration_fee')} 
                            sx={{marginBottom: '0.5rem'}}
                        />
                        { is_reduced_registration_monkey ?
                            <TextField 
                                id="reduced-registration-fee"
                                label={`Reduced ${t('registration_fee')} (${currency_symbol})`} 
                                name="registration_fee_monkey"
                                variant="outlined"
                                type="number"
                                value={registration_fee_monkey}
                                onChange={handleReducedRegistrationFeeChange}
                                inputProps={{ step: 0.01 }}
                                sx={{marginBottom: 2}}
                                required
                                autoComplete='off'
                                fullWidth
                            />
                            : null 
                        }
                    </Grid>
                }   
                { is_supernastix &&
                    <Grid item md={6} sm={12}>
                        { (amount_of_types !== 1) && <DialogFormSubHeadingTypography fontSize={'1rem !important'} variant="h5"> Supernastix </DialogFormSubHeadingTypography> }
                        { allow_per_class &&
                            <FormControl fullWidth>
                                <InputLabel id="class-fee-select-label">{t('class_fee')} ({currency_symbol}) {vat_exclusive}</InputLabel>
                                <Select
                                    labelId='class-fee-select-label'
                                    id='class-fee-select'
                                    name='per_class_fee_super'
                                    value={class_fee_super}
                                    label={`${t('class_fee')} (${currency_symbol})`}
                                    onChange={handleClassFeeChange}
                                    inputProps={{ step: 0.01 }}
                                    sx={{marginBottom: 2}}
                                    required
                                >
                                    {class_fees.map(fee => (
                                        <MenuItem key={`class_fee_${fee}`} value={fee.toString()}>{fee.toString()}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl> 
                        }
                        {/* --------------------------------------------------------------------------------- */}
                        { allow_yearly && <>
                            <FormControlLabel 
                                control={
                                    <Checkbox 
                                        name='is_sponsored_super_yearly' 
                                        checked={is_sponsored_super_yearly}
                                        onChange={handleIsSponsoredChange}
                                    />
                                } 
                                label={t("is_sponsored_yearly")} 
                            />
                            { !is_sponsored_super_yearly && activity_type !== '' ?
                                <FormControl fullWidth>
                                    <InputLabel id="yearly-super-fee-select-label">{t('yearly_fee')} ({currency_symbol})</InputLabel>
                                    <Select
                                        labelId='yearly-super-fee-select-label'
                                        id='yearly-super-fee-select'
                                        name='yearly_fee_super'
                                        value={yearly_fee_super}
                                        label={`${t('yearly_fee')} (${currency_symbol})`}
                                        onChange={handleFeeChange}
                                        inputProps={{ step: 0.01 }}
                                        sx={{marginBottom: 2}}
                                        autoComplete='off'
                                        required
                                    >
                                        {fees.map(fee => (
                                            <MenuItem key={`fee_${fee}`} value={fee.toString()}>{fee.toString()}</MenuItem>
                                        ))}
                                    </Select>

                                </FormControl> 
                                : null }
                            { is_sponsored_super_yearly ?
                                <TextField 
                                    id="yearly-sponsored-fee"
                                    label={`${t('yearly_sponsored_fee')} (${currency_symbol})`} 
                                    name="yearly_sponsored_fee_super"
                                    variant="outlined"
                                    type="number"
                                    value={yearly_sponsored_fee_super}
                                    onChange={handleSponsoredFeeChange}
                                    inputProps={{ step: 0.01 }}
                                    sx={{marginBottom: 2}}
                                    required
                                    fullWidth
                                />
                            : null }
                        </> }
                        {/* ------------------------------ */}
                        { allow_termly && <>
                            <FormControlLabel 
                                control={
                                    <Checkbox 
                                        name='is_sponsored_super_termly' 
                                        checked={is_sponsored_super_termly}
                                        onChange={handleIsSponsoredChange}
                                    />
                                } 
                                label={t("is_sponsored_termly")} 
                            />
                            { !is_sponsored_super_termly && activity_type !== '' ?
                                <FormControl fullWidth>
                                    <InputLabel id="termly-super-fee-select-label">{t('termly_fee')} ({currency_symbol})</InputLabel>
                                    <Select
                                        labelId='termly-super-fee-select-label'
                                        id='termly-super-fee-select'
                                        name='termly_fee_super'
                                        value={termly_fee_super}
                                        label={`${t('termly_fee')} (${currency_symbol})`}
                                        onChange={handleFeeChange}
                                        inputProps={{ step: 0.01 }}
                                        sx={{marginBottom: 2}}
                                        autoComplete='off'
                                        required
                                    >
                                        {fees.map(fee => (
                                            <MenuItem key={`fee_${fee}`} value={fee.toString()}>{fee.toString()}</MenuItem>
                                        ))}
                                    </Select>
                                    { parseFloat(termly_fee_super) > 0 && num_of_terms &&
                                        <Typography>{num_of_terms.toString()} invoices of {currency_symbol}{(parseFloat(termly_fee_super) / num_of_terms).toFixed(2)}</Typography>
                                    }
                                </FormControl> 
                                : null }
                            { is_sponsored_super_termly ?
                                <>
                                    <TextField 
                                        id="termly-sponsored-fee"
                                        label={`${t('termly_sponsored_fee')} (${currency_symbol})`} 
                                        name="termly_sponsored_fee_super"
                                        variant="outlined"
                                        type="number"
                                        value={termly_sponsored_fee_super}
                                        onChange={handleSponsoredFeeChange}
                                        inputProps={{ step: 0.01 }}
                                        sx={{marginBottom: 2}}
                                        required
                                        fullWidth
                                    />
                                    { parseFloat(termly_sponsored_fee_super) > 0 && num_of_terms &&
                                        <Typography>{num_of_terms.toString()} invoices of {currency_symbol}{(parseFloat(termly_sponsored_fee_super) / num_of_terms).toFixed(2)}</Typography>
                                    }
                                    <br/>
                                </>
                            : null }
                        </> }
                        {/* ------------------------------ */}
                        { allow_monthly && <>
                            <FormControlLabel 
                                control={
                                    <Checkbox 
                                        name='is_sponsored_super_monthly' 
                                        checked={is_sponsored_super_monthly}
                                        onChange={handleIsSponsoredChange}
                                    />
                                } 
                                label={t("is_sponsored_monthly")} 
                            />
                            { !is_sponsored_super_monthly && activity_type !== '' ?
                                <FormControl fullWidth>
                                    <InputLabel id="monthly-super-fee-select-label">{t('monthly_fee')} ({currency_symbol})</InputLabel>
                                    <Select
                                        labelId='monthly-super-fee-select-label'
                                        id='monthly-super-fee-select'
                                        name='monthly_fee_super'
                                        value={monthly_fee_super}
                                        label={`${t('monthly_fee')} (${currency_symbol})`}
                                        onChange={handleFeeChange}
                                        inputProps={{ step: 0.01 }}
                                        sx={{marginBottom: 2}}
                                        autoComplete='off'
                                        required
                                    >
                                        {fees.map(fee => (
                                            <MenuItem key={`fee_${fee}`} value={fee.toString()}>{fee.toString()}</MenuItem>
                                        ))}
                                    </Select>
                                    { parseFloat(monthly_fee_super) > 0 && <Typography>10 invoices of {currency_symbol}{(parseFloat(monthly_fee_super) / 10).toFixed(2)}</Typography>}
                                </FormControl> 
                                : null }
                            { is_sponsored_super_monthly ?
                                <>
                                    <TextField 
                                        id="monthly-sponsored-fee"
                                        label={`${t('monthly_sponsored_fee')} (${currency_symbol})`} 
                                        name="monthly_sponsored_fee_super"
                                        variant="outlined"
                                        type="number"
                                        value={monthly_sponsored_fee_super}
                                        onChange={handleSponsoredFeeChange}
                                        inputProps={{ step: 0.01 }}
                                        sx={{marginBottom: 2}}
                                        required
                                        fullWidth
                                    />
                                    { parseFloat(monthly_sponsored_fee_super) > 0 && <Typography>10 invoices of {currency_symbol}{(parseFloat(monthly_sponsored_fee_super) / 10).toFixed(2)}</Typography>}
                                    <br/>
                                </>
                            : null }
                        </> }
                        {/* --------------------------------------------------------------------------------- */}
                        <FormControlLabel 
                            control={<Checkbox name='is_reduced_registration_super' checked={is_reduced_registration_super} onChange={handleIsReducedChange}/>} 
                            label={t('is_reduced_registration_fee')} 
                            sx={{marginBottom: '0.5rem'}}
                        />
                        { is_reduced_registration_super ?
                            <TextField 
                                id="reduced-registration-fee"
                                label={`Reduced ${t('registration_fee')} (${currency_symbol})`} 
                                name="registration_fee_super"
                                variant="outlined"
                                type="number"
                                value={registration_fee_super}
                                onChange={handleReducedRegistrationFeeChange}
                                inputProps={{ step: 0.01 }}
                                sx={{marginBottom: 2}}
                                required
                                autoComplete='off'
                                fullWidth
                            />
                            : null 
                        }
                    </Grid>
                }
                <Grid item md={12} sx={{justifyContent: 'center', display: 'flex', marginTop: '3rem'}}>
                    <DialogFormSubHeadingTypography variant="h3"> {t('delivery_address')} </DialogFormSubHeadingTypography>
                </Grid>
                <Grid item md={12} sx={{justifyContent: 'center', display: 'flex'}}>
                    <AddressFields address_values={address} autocomplete='on' required={true} helper_text_for_suburb={'This value will be used in the area/location dropdown in the Minastix online registration'}/>
                </Grid>
                <Grid item md={12} sx={{justifyContent: 'center', display: 'flex', marginTop: '3rem'}}>
                    <DialogFormSubHeadingTypography variant="h3"> {t('contact_details')} </DialogFormSubHeadingTypography>
                </Grid>
                <Grid item md={12} sx={{justifyContent: 'center', display: 'flex', paddingTop: '0 !important'}}>
                    <Contact initial_values={contact_details} show_preferred_contact_user={false} autocomplete='on'/>
                </Grid>
            </Grid>
            {values ? 
                <DialogButton variant='contained' type="submit"> {t('edit')} </DialogButton>
                : <DialogButton variant='contained' type="submit"> {t('create')} </DialogButton>
            }
            </DialogFormControl>
        )
    }
    
    return (
        <Form onSubmitCallback={onSubmitCallback} createFields={() => createFields(values)}></Form>
    )
}

export default SchoolForm
