import {useMemo} from 'react'
import { useTranslation } from 'react-i18next'

import INastixTabs from '@components/navigation/INastixTabs'
import InstructorTable from '@components/tables/CRUD/InstructorTable'
import AssistantTable from '@components/tables/CRUD/AssistantTable'

import InstructorActions from '@actions/CRUDActions/InstructorActions/instructorActions'
import UserTable from '@components/tables/CRUD/UsersTable'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { companyIsInGroup } from '@utils/utils/util'
import { CompanyType } from '@utils/enums/enums'
import UserTableActions from '@actions/CRUDActions/UserActions/userTableActions'
import AssistanceActions from '@actions/CRUDActions/InstructorActions/assistantActions'


const UsersTabs = () => {

	const { t } = useTranslation('instructors')

	const user_table_actions = useMemo(() => new UserTableActions(), [])
	const instructor_actions = useMemo(() => new InstructorActions(), [])
	const assistant_actions = useMemo(() => new AssistanceActions(), [])

	const company = getLocalStorageCompany()
	const is_school = companyIsInGroup(company, CompanyType.SCHOOL)

	let nav_items = is_school ? [] : [t('users')]
	nav_items.push(t('instructors'))
	nav_items.push(t('assistant'))

	let tab_items = is_school ? [] : [<UserTable action={user_table_actions}/>]
	tab_items.push(<InstructorTable action={instructor_actions}/>)
	tab_items.push(<AssistantTable action={assistant_actions}/>)

	return (
		<INastixTabs
			nav_items={nav_items}
			tab_items={tab_items}
		/>
	)
}

export default UsersTabs