import { createContext } from 'react'


export type SetIsEmailVerified = React.Dispatch<React.SetStateAction<boolean>>

interface IIsEmailVerifiedContext {
    is_email_verified: boolean
    setIsEmailVerified: SetIsEmailVerified
}

export const IsEmailVerifiedContext = createContext<IIsEmailVerifiedContext>({
    is_email_verified: false,
    setIsEmailVerified: () => {}
})