import GenericFetchWrapper from "@adapters/api/fetchWrappers/genericFetchWrapper"
import { BaseHandler } from "@adapters/handlers/Handlers/handlers"
import { INSTRUCTORS_ENDPOINT } from "@adapters/routes/endpoints"


class InstructorByNotificationIdsHandler extends BaseHandler {
    endpoint: string
    fetch_wrapper: GenericFetchWrapper

    constructor () {
        super()
        this.endpoint = INSTRUCTORS_ENDPOINT + '/by-notification-ids'
        this.fetch_wrapper = new GenericFetchWrapper()
    }

    getByNotifcationIds (ids: string[]): Promise<any> {
        return this.fetch_wrapper.post(this.endpoint, {'ids': ids})
    }
}

export default InstructorByNotificationIdsHandler