import {useEffect} from 'react'
import { useTranslation } from 'react-i18next'

import Pages from '@pages/Page'
import { PageHeadingTypography } from '@styles/Typography'
import { UserPermissions } from '@utils/enums/permissions'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { handleCompanyLocalStorageInit } from '@components/authentication/helper/helper'
import { fetchConnectedCompany } from '@actions/CRUDActions/ClassActions/util'

import ClassTabs from './ClassTabs'


const ClassesPage = () => {
	
    const { t } = useTranslation('classes')

	useEffect(() => {
        const company = getLocalStorageCompany()

		if (company.is_demo_company && !company.groups.length) {
			fetchConnectedCompany()
			.then(company => {
				handleCompanyLocalStorageInit(company)
			})
			.catch(error => {
				console.error(error)
			})
		}
	}, [])
	
	return (
		<Pages page_title="Classes" permission={UserPermissions.VIEW_CLASSES}>
			<PageHeadingTypography> {t('classes')} </PageHeadingTypography>
            <ClassTabs/>
		</Pages>
	)
}

export default ClassesPage
