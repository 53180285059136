// import DOMPurify from 'dompurify'

import {useEffect, useState} from 'react'

import { DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { OpenSetOpenDialogProps } from '@utils/dialogProps/DialogProps'
import InastixSystemActions from '@actions/CRUDActions/InastixSystemActions/childActions'
import { createTheme, ThemeProvider, Typography } from '@mui/material'


interface UserNotification {
	created_by: number
	show_on_this_datetime: string
	message: string  // HTML content
	notification_name: string
}

interface UserNotificationStatus {
	id: number
	notification: UserNotification
	seen: boolean
	seen_at: string | null
	user: string
}

const SystemNotificationDialog = (props: OpenSetOpenDialogProps) => {
	const {open, setOpen} = props

	const [user_notification_status, setUserNotificationStatus] = useState<UserNotificationStatus | null>(null)

	useEffect(() => {
		const system_actions = new InastixSystemActions()
		system_actions.get(undefined, undefined, {})
		.then((user_notification_statuses: UserNotificationStatus[]) => {
			if (user_notification_statuses.length) {
				const user_notification_status = user_notification_statuses[0]
				setOpen(true)
				setUserNotificationStatus(user_notification_status)
				system_actions.update({seen: true}, user_notification_status.id.toString())
			}
		})
	}, [])

	const handleClose = () => {
		setOpen(false)
	}

	const santizeHNotificationHtmlMessage = (html_message: string) => {
		// const safeHTML = DOMPurify.sanitize(html_message)
		return html_message
	}

	const theme = createTheme({
		typography: {
		  fontFamily: '"Montserrat", "Helvetica", "Arial", sans-serif',
		},
	  });

	return (
		<>
		{!!user_notification_status &&
			<ThemeProvider theme={theme}>
			<INastixDialog onClose={handleClose} open={open}>
				<INastixDialogTitle> {user_notification_status.notification.notification_name} </INastixDialogTitle>
				<DialogDivider/>
				{/* Safely insert HTML */}
				<Typography
					sx={{padding: "0 2rem", fontSize: '0.8rem'}}
					dangerouslySetInnerHTML={{ __html: santizeHNotificationHtmlMessage(user_notification_status.notification.message) }}
				/>
			</INastixDialog>
			</ThemeProvider>
		}
		</>
	)
}



export default SystemNotificationDialog