import { SetNumOfRequests } from "@contexts/NumOfRequestsContext"

// Defining a threshold for max number of requests
// Why do this -> https://docs.google.com/document/d/1hWJo5hY-ji3Zkiz70VZ8-VueuOQa4whl6cn3f8ySNe8

const trackRequests = async (promise: Promise<any>, setNumOfRequests: SetNumOfRequests) => {
	setNumOfRequests((prev_state: number) => prev_state + 1)

	try {
		const result = await promise
		return result
	} catch (error) {
		throw error
	} finally {
		setNumOfRequests((prev_state: number) => prev_state - 1)
	}
}

export default trackRequests