import React, {useState, useEffect} from 'react'

import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import PhoneIcon from '@mui/icons-material/Phone'

import CountrySelect from "./CountrySelect"
import { useTranslation } from 'react-i18next'


interface PhoneNumberProps {
    initial_phone_number?: string
    setPhoneNumberCallback?: (value: string) => void
    initial_country_code?: string
	setCountryCodeCallback?: (value: string) => void
    add_autocomplete?: boolean
    required?: boolean
    disabled?: boolean
}

const PhoneNumber = (props: PhoneNumberProps) => {
    const {initial_phone_number, setPhoneNumberCallback, initial_country_code, setCountryCodeCallback, required=true, add_autocomplete=false, disabled=false} = props

    const [phone_number, setPhoneNumber] = useState<string>('')
    const { t } = useTranslation('user_setup')

    useEffect(() => {
        if (initial_phone_number)
            setPhoneNumber(initial_phone_number)
    }, [initial_phone_number])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target
    
        // Remove any occurrences of "0" or "+"
        const sanitized_value = value.replace(/^0+/, '')
        const further_sanitized_value = sanitized_value.replace(/[+]/g, '')

        setPhoneNumber(further_sanitized_value)

        if (setPhoneNumberCallback)
            setPhoneNumberCallback(further_sanitized_value)
    }

    return (
        <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
            <CountrySelect initial_country_code={initial_country_code} setCountryCodeCallback={setCountryCodeCallback} disabled={disabled} required={required}/>

            <TextField
                disabled={disabled}
                id="number"
                label={t('phone_number')}
                name="phone_number"
                type="tel"
                value={phone_number}
                onChange={handleChange}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <PhoneIcon />
                        </InputAdornment>
                    ),
                }}
                required={required}
                autoComplete={add_autocomplete ? "tel" : "off"}
            />
        </Box>
    )
}

export default PhoneNumber