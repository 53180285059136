import React, { useState, useContext, useEffect } from 'react'

import { useNavigate, useParams } from 'react-router-dom'

import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import InputAdornment from '@mui/material/InputAdornment'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'

import Form, { FormDataEntryType } from '@components/forms/Form'

import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'

import { MessageContext } from '@contexts/MessageContext'
import { AuthenticationAction } from '@actions/Actions/actions'

import PasswordRules from '../SignUp/PasswordRules'
import '../SignUp/passwordRules.css'
import { FORGOT_PASSWORD_ENDPOINT } from '@adapters/routes/endpoints'
import AuthFooter from '../util/AuthFooter'
import { AuthTypography } from '../util/StyledComponents'
import log from 'loglevel'


const ResetPassword = () => {
    const navigate = useNavigate()

    const {setMessage} = useContext(MessageContext)

    const [password, set_password] = useState('')
    const [confirm_password, set_confirm_password] = useState('')
    const [passwords_match, set_passwords_match] = useState(true)
    const [password_focus, set_password_focus] = useState(false)

    const { uidb64, token } = useParams()

    useEffect(() => {
        // Load reCAPTCHA script when the component mounts
        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/api.js?render=6LeSCWUmAAAAAC6acEwKoQoAcbpg_Do1RIesd0zt';
        script.async = true;
        document.body.appendChild(script);
  
        return () => {
          // Cleanup: Remove the script when the component unmounts
          document.body.removeChild(script);
        };
      }, []);

    const isFormValid = () =>{
        return (
            password === confirm_password
        )
    }

    const handle_password_change = (event: React.ChangeEvent<HTMLInputElement>) =>{
        set_password(event.target.value)
        set_passwords_match(event.target.value === confirm_password)
    }

    const handle_confirm_password_change = (event: React.ChangeEvent<HTMLInputElement>) => {
        set_confirm_password(event.target.value)
        set_passwords_match(password === event.target.value)
    }

    const createFields = () => {
        return (
            <>
            <FormControl>
            <TextField
                    required
                    name="password"
                    label="Password"
                    type='password'
                    value={password}
                    onChange={handle_password_change}
                    onFocus={() => set_password_focus(true)}
                    onBlur={() => set_password_focus(false)}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {password_focus && (
                                        <div className="password-rules">
                                            <PasswordRules password={password} />
                                        </div>
                                    )
                                }
                            </InputAdornment>
                        ),
                    }}
                />
                <br/>
                <Tooltip
                    PopperProps={{
                        disablePortal: true,
                    }}
                    open={!passwords_match}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title="Passwords do not match"
                    placement="right"
                    arrow
                >
                    <TextField
                        required
                        error={!passwords_match}
                        name="confirm_password"
                        label="Confirm Password"
                        type='password'
                        value={confirm_password}
                        onChange={handle_confirm_password_change}
                    />
                </Tooltip>
                <Button variant="contained" type="submit" sx={{marginTop: 4}}> submit </Button> 
            </FormControl>
            </> 
        )
    }

    const onSubmit = async (form_data_object: FormDataEntryType): Promise<any> => {
        if (isFormValid()) {    
            setMessage(props => ({...props, form_error: ""}))

            // Call the Google reCAPTCHA API to get the response token
            const recaptcha_token = await window.grecaptcha.execute('6LeSCWUmAAAAAC6acEwKoQoAcbpg_Do1RIesd0zt', { action: 'submit' });

            // Here, you can send the token to the backend for verification if required.
            const data = {
                ...form_data_object,
                uidb64: uidb64,
                token: token,
                recaptcha_token: recaptcha_token
            }
            const authentication_action = new AuthenticationAction()
            return authentication_action.forgotPassword(FORGOT_PASSWORD_ENDPOINT, data)
            .then(message => {
                navigate('/account/password-reset-success')
                return message
            })
            .catch(error => {
                log.error(error)
                return error
            })
        }
        else
            setMessage(props => ({...props, form_error: "Make sure all fields are filled in correclty"}))
	}

    return (
        <>
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection:'column'}} height={'100vh'}>
            <AuthTypography variant="h1" gutterBottom mt={'auto'}> Reset your Password </AuthTypography>
            <Typography sx={{maxWidth: '50%', margin: '1rem auto 2rem auto'}}>Enter a new password to be used for your iNastix account.</Typography>
            <Form onSubmitCallback={onSubmit} createFields={createFields}/> 
            <AuthFooter/>
        </Box>
        </>
    )
}

export default ResetPassword