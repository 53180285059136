import { styled } from '@mui/material/styles'
import Typography, {TypographyProps} from '@mui/material/Typography'


export const PageHeadingTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
    marginBottom: '1rem',
    fontSize: '1.5rem',
    color: 'darkslategray',
    fontWeight: 'bold',
    textTransform: 'capitalize'

}))


export const DialogFormSubHeadingTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
    fontSize: theme.typography.h6.fontSize,
    fontWeight: '600',
    color: 'darkslategray',
    marginBottom: '1.5rem',
    textTransform: 'capitalize'
}))


export const SettingsHeadingTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
    fontSize: theme.typography.h1.fontSize,
    fontWeight: '600',
    color: 'darkslategray',
    marginBottom: 3
}))


export const SettingsSubHeadingTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
    fontSize: theme.typography.h3.fontSize,
    fontWeight: '600',
    color: 'darkslategray',
}))


export const BreadcrumbMainTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
    fontSize: '0.8rem',
    color: 'darkslategray',
    fontWeight: 'bold',
    textTransform: 'capitalize'
}))


export const BreadcrumbTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
    fontSize: '1.5rem',
    color: 'darkslategray',
    fontWeight: 'bold',
    textTransform: 'capitalize'
}))
