import {useState, useEffect, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import AmountOfInvoicesActions from '@actions/Finance/AmountOfInvoicesActions/amountOfInvoicesActions'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'

import FinancesPage from '../Finances'
import { StatsBox } from './styles'


const SalesOverviewPage = () => {
	const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)
	const [stats, setStats] = useState({
		awaiting_approval: 0,
		awaiting_payment: 0
	})

	const { t } = useTranslation('overview')

	useEffect(() => {
		const amount_of_invoices_actions = new AmountOfInvoicesActions()
		amount_of_invoices_actions.get()
		.then((data) => {
			setStats({
				awaiting_approval: data.awaiting_approval,
				awaiting_payment: data.awaiting_payment
			})
		})
	}, [change_company_rerender])

	return (
		<FinancesPage>
			<Typography> {t('invoices')} </Typography>
			<Box sx={{display: 'flex', justifyContent: 'space-evenly'}}>
				<StatsBox>
					<Typography> {t('generated')} </Typography>
					<Typography> {stats.awaiting_approval} </Typography>
				</StatsBox>
				<StatsBox>
					<Typography> {t('awaiting_payment')} </Typography>
					<Typography> {stats.awaiting_payment} </Typography>
				</StatsBox>
			</Box>
		</FinancesPage>
	)
}

export default SalesOverviewPage
