import {useState, useEffect, useContext} from 'react'

import { styled } from '@mui/material/styles'
import TextField from "@mui/material/TextField"
import Autocomplete from "@mui/material/Autocomplete"
import Box from "@mui/material/Box"
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip'

import XeroAccountCodesAllActions from '@actions/XeroActions/xeroAccountCodesAllActions'
import { MessageContext } from '@contexts/MessageContext'

import { XeroAccountCodesAllStructProps, XeroAccountCodesProps, XeroAccountCodesStructProps } from './interfaces'
import { account_codes_fields } from './utils'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { companyIsInGroup } from '@utils/utils/util'
import { CompanyType } from '@utils/enums/enums'


const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))  ({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 150,
    },
})

const XeroAccountCodes = (props: XeroAccountCodesProps) => {
    const {initial_account_codes, accountCodesCallback, is_sep_account_codes_checked, styling_type='horizontal'} = props

    const {setMessage} = useContext(MessageContext)

    const [xero_account_codes_all, setXeroAccountCodesAll] = useState<XeroAccountCodesAllStructProps[]>([])
    const [xero_account_codes, setXeroAccountCodes] = useState<XeroAccountCodesStructProps>({
        is_sep_account_codes_checked: false,

        sales_account_code: undefined,
        sales_account_uuid: undefined,

        baby_auth_uuid: undefined,
        baby_reg_uuid: undefined,
        baby_auth_code: undefined,
        baby_reg_code: undefined,
    
        monkey_auth_uuid: undefined,
        monkey_reg_uuid: undefined,
        monkey_auth_code: undefined,
        monkey_reg_code: undefined,
    
        super_auth_uuid: undefined,
        super_reg_uuid: undefined,
        super_auth_code: undefined,
        super_reg_code: undefined
    })

    useEffect(() => {
        const xero_account_codes_all_action = new XeroAccountCodesAllActions()
        xero_account_codes_all_action.get()
        .then((xero_account_codes_all: XeroAccountCodesAllStructProps[]) => {
            const filtered_xero_account_codes = xero_account_codes_all.filter((xero_account_code: XeroAccountCodesAllStructProps) => xero_account_code.code)  // filter out data where there is no code set for the xero account data
            setXeroAccountCodesAll(filtered_xero_account_codes)
            if (xero_account_codes_all.length && initial_account_codes)
                setXeroAccountCodes(initial_account_codes)
        })
        .catch(error => {
            setMessage(props => ({...props, error: error.message}))
        })
    }, [initial_account_codes, setMessage])

    useEffect(() => {
        accountCodesCallback(xero_account_codes)
    }, [xero_account_codes, accountCodesCallback])

    const handleAccountCodesChange = (key: string, value: XeroAccountCodesAllStructProps | null) => {
        setXeroAccountCodes(props => ({
            ...props, 
            [`${key}_code`]: value?.code, 
            [`${key}_uuid`]: value?.account_id
        }))
    }

    const handleSingleRoyaltyAccountCodeChange = (value: XeroAccountCodesAllStructProps | null) => {
        for (const account_codes_field of account_codes_fields) {
            setXeroAccountCodes(props => ({
                ...props, 
                [`${account_codes_field.key}_code`]: value?.code,
                [`${account_codes_field.key}_uuid`]: value?.account_id,
            }))
        }
    }

    const company = getLocalStorageCompany()
    const is_franchise = companyIsInGroup(company, CompanyType.FRANCHISEE)

    const vertical_styling = {display: 'flex', justifyContent: 'space-around', flexDirection: 'row', flexWrap: 'wrap', gap: '1rem'}
    const horizontal_styling = {display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', gap: '1rem'}

    return (
        <>
        <br/>
        <Box sx={styling_type === 'horizontal' ? horizontal_styling: vertical_styling}>
            <CustomWidthTooltip title="Income received from tuition will come here">
                <Autocomplete
                    value={xero_account_codes_all?.find(option => option.code === xero_account_codes.sales_account_code) || null}
                    isOptionEqualToValue={(option_a) => option_a.code === xero_account_codes.sales_account_code}
                    onChange={(event: any, new_value: XeroAccountCodesAllStructProps | null) => {
                        handleAccountCodesChange("sales_account", new_value)
                    }}
                    options={xero_account_codes_all}
                    getOptionLabel={(option: XeroAccountCodesAllStructProps) => option.code + ' - ' + option.name}
                    sx={{ width: 300, margin: 'auto' }}
                    renderInput={(params) => <TextField {...params} label="Sales Account" />}
                />
            </CustomWidthTooltip>
            {!is_franchise && 
                <>
                {is_sep_account_codes_checked || initial_account_codes?.is_sep_account_codes_checked ? 
                    account_codes_fields.map((account_codes_field, index) => (
                        <CustomWidthTooltip key={index} title={account_codes_field.title}>
                            <Autocomplete
                                value={
                                    xero_account_codes_all?.find(option => {
                                        const xero_account_codes_key = `${account_codes_field.key}_code` as keyof XeroAccountCodesStructProps
                                        return option.code === xero_account_codes[xero_account_codes_key]?.toString()
                                    }) 
                                    || null
                                }
                                isOptionEqualToValue={(option_a) => {
                                    const xero_account_codes_key = `${account_codes_field.key}_code` as keyof XeroAccountCodesStructProps
                                    return option_a.code === xero_account_codes[xero_account_codes_key]?.toString()
                                }}
                                onChange={(event: any, new_value: XeroAccountCodesAllStructProps | null) => {
                                    handleAccountCodesChange(account_codes_field.key, new_value)
                                }}
                                options={xero_account_codes_all}
                                getOptionLabel={(option: XeroAccountCodesAllStructProps) => option.code + ' - ' + option.name}
                                sx={{ width: 300, margin: 'auto' }}
                                renderInput={(params) => <TextField {...params} label={account_codes_field.label} />}
                            />
                        </CustomWidthTooltip>
                    ))
                    : 
                    <CustomWidthTooltip title={"Royalties generated will go here"}>
                        <Autocomplete
                            value={xero_account_codes_all?.find(option => option.code === xero_account_codes.baby_reg_code) || null}
                            isOptionEqualToValue={(option_a) => option_a.code === xero_account_codes.baby_reg_code}
                            onChange={(event: any, new_value: XeroAccountCodesAllStructProps | null) => {
                                handleSingleRoyaltyAccountCodeChange(new_value)
                            }}
                            options={xero_account_codes_all}
                            getOptionLabel={(option: XeroAccountCodesAllStructProps) => option.code + ' - ' + option.name}
                            sx={{ width: 300, margin: 'auto' }}
                            renderInput={(params) => <TextField {...params} label="Royalty Account" />}
                        />
                    </CustomWidthTooltip>
                }
                </>
            }
        </Box>
        </>
    )
}

export default XeroAccountCodes