import SchoolTable from "@components/tables/CRUD/SchoolTable"
import SchoolActions from "@actions/CRUDActions/SchoolActions/schoolActions"
import ChildCertificatesReportDialogHandler from '@components/dialogs/Reports/ChildCertificates/ChildCertificatesReportDialogHandler'

import ReportingPage from ".."
import { getLocalStorageCompany } from "@utils/localStorage/company"
import { useContext, useEffect, useState } from "react"
import { ChangeCompanyRerenderContext } from "@contexts/TriggerRerender/companyRerender"
import FranchiseeActions from "@actions/CRUDActions/FranchiseeActions/franchiseeActions"
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import FranchiseeTable from "@components/tables/Custom/FranchiseeTable"
import FranchiseCertificatesReportDialogHandler from "@components/dialogs/Reports/ChildCertificates/FranchiseCertificatesReportDialogHandler"


const ReportCertificates = () => {
    const connected_company = getLocalStorageCompany()

    const acts_like_a_franchise = connected_company.acts_like_a_franchise

    const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)

    const [report_type, setReportType] = useState(acts_like_a_franchise ? 'school' : 'franchise')

    useEffect(() => {
        setReportType(acts_like_a_franchise ? 'school' : 'franchise')
    }, [change_company_rerender, acts_like_a_franchise])

    const franchise_actions = new FranchiseeActions()
	const school_actions = new SchoolActions()

    const handleChange = (event: SelectChangeEvent) => {
        setReportType(event.target.value as string)
    }

    const renderReportTypeTable = () => {
        if (report_type === 'franchise')
            return <FranchiseeTable action={franchise_actions} use_as_reporting={true} dialogs={[FranchiseCertificatesReportDialogHandler]}/>
        else if (report_type === 'school')
            return <SchoolTable action={school_actions} use_as_reporting={true} dialogs={[ChildCertificatesReportDialogHandler]}/>
        else
            return <></>
    }

    return (
        <ReportingPage>
            <FormControl fullWidth>
                <InputLabel id="report-for-label">Report Type</InputLabel>
                <Select
                labelId="report-for-select-label"
                id="report-for-select"
                value={report_type}
                label="Report Type"
                onChange={handleChange}
                sx={{
                    marginBottom: '1rem', width: '200px'
                }}
                >
                    {!acts_like_a_franchise && <MenuItem value={'franchise'}>Franchise</MenuItem>}
                    <MenuItem value={'school'}>School</MenuItem>
                </Select>
            </FormControl>
            {renderReportTypeTable()}
        </ReportingPage>
    )
}

export default ReportCertificates