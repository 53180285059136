import { CRUDAction } from "@actions/Actions/actions"
import AssistantHandler from "@adapters/handlers/CRUDHandlers/InstructorHandlers/assistantHandler"


class AssistanceActions extends CRUDAction {
    handler: AssistantHandler

    constructor() {
        super()
        this.handler = new AssistantHandler()
    }
}

export default AssistanceActions