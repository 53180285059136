import React , {useState, useContext, useEffect} from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import {Typography} from '@mui/material'
import SummarizeIcon from '@mui/icons-material/Summarize'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress'

import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import { DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { DialogProps } from '@utils/dialogProps/DialogProps'
import { NumOfRequestsContext } from '@contexts/NumOfRequestsContext'
import { downloadFile } from '@utils/files/blobs'
import { convertDateToYYYYMMDD } from '@utils/utils/util'
import ExcelReportActions from '@actions/ReportActions/excelReportActions'
import { Franchisee, School } from '@utils/interfaces/interfaces'
import SchoolActions from '@actions/CRUDActions/SchoolActions/schoolActions'
import FranchiseeActions from '@actions/CRUDActions/FranchiseeActions/franchiseeActions'



const CreateFranchiseSalesReportDialog = (props: DialogProps) => {
	// disable the warning for not using state_manager in this dialog
	// eslint-disable-next-line  
	const {ids, state_manager, onClose, open} = props

	const {setNumOfRequests} = useContext(NumOfRequestsContext)

	const { t } = useTranslation('t_shirt_reports')

	const [school_options, setSchoolOptions] = useState<School[]>([])
	const [autocomplete_open, setAutocompleteOpen] = React.useState(false)
	const [selected_schools, setSelectedSchools] = useState<number[]>([])

	const loading = open && school_options.length === 0

	useEffect(() => {
		if (ids.length > 1)
			return

		let active = true
	
		if (!loading) {
		  	return undefined
		}
	
		(async () => {
			if (active) {
				const franchise_actions = new FranchiseeActions()
				franchise_actions.getById(ids[0].toString())
				.then((franchise: Franchisee) => {
					const school_actions = new SchoolActions()
					school_actions.get(undefined, undefined, {'connected_company__company_uuid': franchise.company_uuid})
					.then((schools: School[]) => {
						setSchoolOptions(schools)
					})
				})
			}
		})()
	
		return () => {
		  	active = false
		}
	}, [loading])

	useEffect(() => {
		if (!open) {
			setSchoolOptions([])
		}
	  }, [open])

	const handleFranchiseSalesReportCreate = async () => {
		setNumOfRequests(props => props + 1)

		const excel_report_actions = new ExcelReportActions()

		try {
			const response = await excel_report_actions.createFranchiseSalesReport({selected_schools: selected_schools, franchise_ids: ids})
			setNumOfRequests(props => props - 1)

			let current_date = convertDateToYYYYMMDD(new Date())

			let filename = `Franchise Sales Report ${current_date}.xlsx`
			downloadFile(response, filename)

		} catch (error) {
			console.error('Error generating Franchise sales Report:', error)
			setNumOfRequests(props => props - 1)
		}
	}

	const handleClose = () => {
		onClose()
	}

	return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> Get Franchise Sales </INastixDialogTitle>
			<DialogDivider/>
			{ids.length < 2 ?
				<Autocomplete
					multiple
					id="tags-standard"
					options={school_options}
					getOptionLabel={(option) => option.name}
					onChange={(event, new_schools) => {
						setSelectedSchools(new_schools.map(school => school.tenant))
					}}
					open={autocomplete_open}
					onOpen={() => {
					 	setAutocompleteOpen(true)
					}}
					onClose={() => {
						setAutocompleteOpen(false)
					}}
					loading={loading}
					renderInput={(params) => (
						<TextField
							{...params}
							sx={{marginTop: '1rem'}}
							label="School Options"
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<React.Fragment>
										{loading ? <CircularProgress color="inherit" size={20} /> : null}
										{params.InputProps.endAdornment}
									</React.Fragment>
								),
							}}
						/>
					)}
				/>
				: <></>
			}
			<Button variant='contained' onClick={handleFranchiseSalesReportCreate} sx={{width: '100%', marginTop: '1rem'}}>{t('create')}</Button>

        </INastixDialog>
	)
}


const CreateFranchiseSalesReportDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<SummarizeIcon/>}>
			<Typography> Sales Report</Typography>
		</TableActionButon>
		{open && <CreateFranchiseSalesReportDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>}
		</>
	)
}

export default CreateFranchiseSalesReportDialogHandler