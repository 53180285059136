import React from 'react'

import { SnackbarOrigin } from '@mui/material/Snackbar'

import { Message } from '@contexts/MessageContext'

import SuccessBar from './SuccessBar'
import InfoBar from './InfoBar'
import ErrorBar from './ErrorBar'
import WarningBar from './WarningBar'


export interface State extends SnackbarOrigin {
    open: boolean
}

interface UISnackbarProps {
    message: Message
    setMessage: React.Dispatch<React.SetStateAction<Message>>
}

const UISnackbar = (props: UISnackbarProps) => {
    const {message, setMessage} = props

    const resetMessage = () => {
        setMessage({'success': "", 'error': "", 'form_error': "", 'info': "", 'warning': ""})
    }

    return (
        <>
            {message.success ? <SuccessBar message={message.success} resetMessage={resetMessage}/> : ''}
            {message.error ? <ErrorBar message={message.error} resetMessage={resetMessage}/> : ''}
            {message.warning ? <WarningBar message={message.warning} resetMessage={resetMessage}/> : ''}
            {message.info ? <InfoBar message={message.info} resetMessage={resetMessage}/> : ''}
        </>
    )
}

export default UISnackbar