import FetchWrapper from "@adapters/api/fetchWrappers/fetchWrapper"
import AuthFetchWrapper from "@adapters/api/fetchWrappers/authFetchWrapper"
import CRUDFetchWrapper from "@adapters/api/fetchWrappers/crudFetchWrapper"
import SessionFetchWrapper from "@adapters/api/fetchWrappers/sessionFetchWrapper"
import GenericFetchWrapper from "@adapters/api/fetchWrappers/genericFetchWrapper"


export abstract class BaseHandler {
    abstract fetch_wrapper: FetchWrapper

    public addParamsToEndpoint(endpoint: string, query_params: object) {
        // Convert query_params object into a string of URL-encoded key-value pairs
        const params_str = Object.entries(query_params)
            .map(([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`)
            .join("&")
    
        // Append the params string to the endpoint, either as a query string or a path segment
        const separator = endpoint.includes("?") ? "&" : "?"
        return `${endpoint}${separator}${params_str}`
    }

    // Test seam to inject a mock FetchWrapper object
    public setFetchWrapper(fetchWrapper: FetchWrapper) {
        this.fetch_wrapper = fetchWrapper
    }
}


export class AuthenticationHandler extends BaseHandler {
    fetch_wrapper: AuthFetchWrapper

    constructor () {
        super()
        this.fetch_wrapper = new AuthFetchWrapper()
    }

    get (endpoint: string): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(endpoint)
        return this.fetch_wrapper.get(parsed_endpoint)
    }

    post (endpoint: string, data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(endpoint)
        return this.fetch_wrapper.post(parsed_endpoint, data)
    }
}


export class SessionHandler extends BaseHandler {
    fetch_wrapper: SessionFetchWrapper

    constructor () {
        super()
        this.fetch_wrapper = new SessionFetchWrapper()
    }

    get (endpoint: string, query_params: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(endpoint)
        const query_params_endpoint = this.addParamsToEndpoint(parsed_endpoint, query_params)
        return this.fetch_wrapper.get(query_params_endpoint)
    }
}


export class GenericHandler extends BaseHandler {
    fetch_wrapper: GenericFetchWrapper
    endpoint: string

    constructor (endpoint: string = '/') {
        super()
        this.endpoint = endpoint
        this.fetch_wrapper = new GenericFetchWrapper()
    }

    get (): Promise<any> {
        return this.fetch_wrapper.get(this.endpoint)
    }

    getById(id: string): Promise<any> {
        let url = ""
        if (this.endpoint.endsWith('/'))
            url = `${this.endpoint}${id}`
        else
            url = `${this.endpoint}/${id}`
        return this.fetch_wrapper.get(url)
    }

    post (data: object): Promise<any> {
        return this.fetch_wrapper.post(this.endpoint, data)
    }

    put (id: string, data: object): Promise<any> {
        return this.fetch_wrapper.put(this.endpoint, data, id)
    }

    delete (id: string): Promise<any> {
        return this.fetch_wrapper.delete(this.endpoint, id)
    }
}


export abstract class CRUDHandler extends BaseHandler {
    fetch_wrapper: CRUDFetchWrapper

    constructor () {
        super()
        this.fetch_wrapper = new CRUDFetchWrapper()
    }

    abstract get (page_size?: number, page?: number, filter?: object, group?: string): Promise<any> 

    abstract getById(id: string): Promise<any>

    abstract post (data: object): Promise<any> 

    abstract put (data: object, id: string): Promise<any>

    abstract patch (data: object, id: string): Promise<any>

    abstract delete (id: string): Promise<any>

    abstract deleteMultiple (ids: Array<string>): Promise<any> 
}
