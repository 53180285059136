import {useState, useEffect, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import Tooltip from '@mui/material/Tooltip'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import Button from '@mui/material/Button'

import { DialogButton, DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import INastixFileUpload from '@pages/Setup/UserSetup/ChooseUserType/INastixFileUpload'
import { LocalStorageUser} from '@utils/interfaces/interfaces'
import UserTableActions from '@actions/CRUDActions/UserActions/userTableActions'
import { UserTableUser } from '@components/forms/UserForm'
import UserActions from '@actions/CRUDActions/UserActions/userActions'
import InstructorActions from '@actions/CRUDActions/InstructorActions/instructorActions'
import GenericFetchWrapper from '@adapters/api/fetchWrappers/genericFetchWrapper'
import { JWT_PING_ENDPOINT } from '@adapters/routes/endpoints'
import { MessageContext } from '@contexts/MessageContext'
import StateManager from '@actions/StateManager/stateManager'
import log from 'loglevel'


interface UploadFilesDialogProps {
    state_manager: StateManager
    instructor_id: number
	open: boolean
	onClose: () => void
    instructor_docs: EmptyInstructorDocsProps
    initial_instructor_docs: EmptyInstructorDocsBooleanProps | null
    setInstructorDocs: React.Dispatch<React.SetStateAction<EmptyInstructorDocsProps>> 
}

interface EmptyInstructorDocsBooleanProps {
    gl_course: boolean
    police_clearance_certificate: boolean
    copy_of_id_photo: boolean
    employment_contract: boolean
}

export interface EmptyInstructorDocsProps {
    gl_course: null | File
    police_clearance_certificate: null | File
    copy_of_id_photo: null | File
    employment_contract: null | File
}


export const UploadFilesDialog = (props: UploadFilesDialogProps) => {
	const {state_manager, instructor_id, onClose, open, instructor_docs, initial_instructor_docs, setInstructorDocs} = props

	const {setMessage} = useContext(MessageContext)
    const { t } = useTranslation('classes')
    
    const gl_course_label = "GL Course" 
    const police_clearance_label = "Police Clearance Certificate"
    const copy_of_id_label = "Copy of your ID"
    const employment_contract_label = "Employment Contract"

    const handleSelectedSouthAfricanGICourseFileCallback = (file: File) => {
        instructor_docs['gl_course'] = file
        setInstructorDocs(instructor_docs)
    }

    const handleSelectedPoliceClearanceCertificateFileCallback = (file: File) => {
        instructor_docs['police_clearance_certificate'] = file
        setInstructorDocs(instructor_docs)
    }

    const handleSelectedCopyOfIdPhotoFileCallback = (file: File) => {
        instructor_docs['copy_of_id_photo'] = file
        setInstructorDocs(instructor_docs)
    }

    const handleSelectedEmploymentContractFileCallback = (file: File) => {
        if (!instructor_docs)
            return

        instructor_docs['employment_contract'] = file
        setInstructorDocs(instructor_docs)
    }
    
    const handleSubmit = async () => {
        let form_data = new FormData()

        // due to unique file upload (content-type), making sure access token is valid/refreshed in order to prevent data not being sent in post request
        // because of chaning content-type to 'application/json' during refreshing
        const fetch_wrapper = new GenericFetchWrapper()
        await fetch_wrapper.get(JWT_PING_ENDPOINT)

        form_data.append('instructor_id', instructor_id.toString())

        if (instructor_docs) {
            if (instructor_docs.gl_course)
                form_data.append('south_african_gl_course', instructor_docs.gl_course)
            if (instructor_docs.police_clearance_certificate)
                form_data.append('police_clearance_certificate', instructor_docs.police_clearance_certificate)
            if (instructor_docs.copy_of_id_photo)
                form_data.append('copy_of_id_photo', instructor_docs.copy_of_id_photo)
            if (instructor_docs.employment_contract)
                form_data.append('employment_contract', instructor_docs.employment_contract)
        }
        else {
            setMessage(props => ({...props, error: "Submit at least one document."}))
        }

        const instructor_actions = new InstructorActions()
        console.log(instructor_docs)
        instructor_actions.updateInstructorFiles(form_data, instructor_id.toString())
        .then(async () => {
            await state_manager.setGetAction()
            handleClose()
        })
        .catch((message) => {
            log.error(message)
        })
    }

    const handleClose = () => {
		onClose()
	}

    return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle sx={{padding: 0, paddingTop: '0.4rem'}}> Instructor Documents </INastixDialogTitle>
			{/* <Alert severity="info" sx={{fontSize: '0.8rem'}}> Parents will be notified if their child is transferred in or out of this class.</Alert> */}
			<br/>
			<DialogDivider/>
            <>
            {instructor_id &&
                <>
                {initial_instructor_docs?.gl_course ? <></> :
                    <>
                    <br/>
                    <INastixFileUpload label={gl_course_label} setSelectedFileCallback={handleSelectedSouthAfricanGICourseFileCallback} helper_text='*Optional'/>
                    </>
                }
                {initial_instructor_docs?.employment_contract ? <></> :
                    <>
                    <br/>
                    <INastixFileUpload label={employment_contract_label} setSelectedFileCallback={handleSelectedEmploymentContractFileCallback}/>
                    </>
                }
                </>
            }
            {initial_instructor_docs?.police_clearance_certificate ? <></> :
                <>
                <br/>            
                <INastixFileUpload label={police_clearance_label} setSelectedFileCallback={handleSelectedPoliceClearanceCertificateFileCallback}/>
                </>
            }
            {initial_instructor_docs?.copy_of_id_photo ? <></> :
                <>
                <br/>   
                <INastixFileUpload label={copy_of_id_label} setSelectedFileCallback={handleSelectedCopyOfIdPhotoFileCallback}/>
                </>
            }
            </>
			<DialogButton variant='contained' onClick={handleSubmit}> {t('submit')} </DialogButton>
		</INastixDialog>
    )
}


interface UploadFilesDialogHandlerInterface {
    id: number
    state_manager: StateManager
}

const UploadFilesDialogHandler = (props: UploadFilesDialogHandlerInterface) => {
	const {id, state_manager} = props

	const [open, setOpen] = useState(false)

    const [instructor_id, setInstructorId] = useState<number | null>(null)
    const [instructor_docs, setInstructorDocs] = useState<EmptyInstructorDocsProps>({
        gl_course: null,
        police_clearance_certificate: null,
        copy_of_id_photo: null,
        employment_contract: null,
    })
    const [initial_instructor_docs, setInitialInstructorDocs] = useState<EmptyInstructorDocsBooleanProps | null>(null)

    useEffect(() => {
        const user_actions = new UserActions()
        user_actions.getById(id.toString())
        .then((user: LocalStorageUser) => {
            const user_table_actions = new UserTableActions()
            user_table_actions.getById(id.toString())
            .then((user_table_users: UserTableUser[]) => {
                const user_table_user = user_table_users.find(user_table_user => user_table_user.user_group_name === user.connected_group)
                console.log(user_table_user)
                if (!user_table_user)
                    return

                if (user_table_user.instructor_id )
                    setInstructorId(user_table_user.instructor_id)

                const empty_instructor_docs: EmptyInstructorDocsBooleanProps = {
                    gl_course: false,
                    police_clearance_certificate: false,
                    copy_of_id_photo: false,
                    employment_contract: false
                }

                if (user_table_user.south_african_gl_course)
                    empty_instructor_docs.gl_course = true
                if (user_table_user.police_clearance_certificate)
                    empty_instructor_docs.police_clearance_certificate = true
                if (user_table_user.copy_of_id_photo)
                    empty_instructor_docs.copy_of_id_photo = true
                if (user_table_user.employment_contract)
                    empty_instructor_docs.employment_contract = true

                setInitialInstructorDocs(empty_instructor_docs)
            })
        })
    }, [])

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
        {instructor_id && initial_instructor_docs && Object.values(initial_instructor_docs).some(init_instructor_doc => !!init_instructor_doc) ? 
            <>
            <Tooltip title="Upload Docs">
                <Button onClick={handleClickOpen} variant='outlined'>
                    <UploadFileIcon sx={{color: 'red'}}/>
                </Button>
            </Tooltip>
            {open && <UploadFilesDialog state_manager={state_manager} instructor_id={instructor_id} open={open} onClose={handleClose} instructor_docs={instructor_docs} initial_instructor_docs={initial_instructor_docs} setInstructorDocs={setInstructorDocs}/>}
            </>
            : <></>
        }
		</>
	)
}

export default UploadFilesDialogHandler