import { useEffect, useState, useContext, useMemo } from 'react'

import Alert from '@mui/material/Alert'
import { SelectChangeEvent } from '@mui/material/Select'

import { getLocalStorageCompany, updateLocalStorageCompany } from '@utils/localStorage/company'
import { DialogButton, DialogFormControl, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { convertDateToYYYYMMDD, companyIsInGroup } from '@utils/utils/util'
import { CompanyType } from '@utils/enums/enums'
import { LocalstorageCompanyExistsContext } from '@contexts/LocalstorageCompanyExistsContext'
import { Child, ChildAuthorisation } from '@utils/interfaces/interfaces'
import ChildAuthorisationSelect from '@components/forms/ChildForm/ChildAuthorisationSelect'
import { ChildAuthorisationMap } from '@utils/maps/maps'
import ChildActions from '@actions/CRUDActions/ChildActions/childActions'
import { MessageContext } from '@contexts/MessageContext'

import log from 'loglevel'


const ChildNeedsAuthorisationDialog = () => {
    const company = getLocalStorageCompany()

    const {local_storage_company_exists} = useContext(LocalstorageCompanyExistsContext)
    const {setMessage} = useContext(MessageContext)

    const [open, setOpen] = useState(false)
	const [selected_child_ids, setSelectedChildIds] = useState<number[]>([])
	const [selected_child, setSelectedChild] = useState<Child | null>(null)
    const [child_authorisation_select, setChildAuthorisationSelect] = useState<ChildAuthorisation | string>('')

    const needing_to_authorise_children = useMemo(() => {
        if (local_storage_company_exists && company?.needing_to_authorise_children) {
            let parsed_data
            try {
                parsed_data = JSON.parse(company.needing_to_authorise_children)
            } catch (error) {
                // if company.needing_to_authorise_children is an [] and not a string we catch this json parse error
                parsed_data = []
            }
            return parsed_data
        }
    }, [local_storage_company_exists, company.needing_to_authorise_children])
    
    useEffect(() => {
        if (!companyIsInGroup(company, CompanyType.FRANCHISOR) && needing_to_authorise_children?.length) {
            setOpen(true)
            setSelectedChildIds(needing_to_authorise_children)
        }
    }, [company, needing_to_authorise_children])

    useEffect(() => {
		if (open) {
            const selected_child_id = selected_child_ids[0]
			const child_actions = new ChildActions()
			child_actions.getById(selected_child_id.toString())
			.then((child: Child) => {
				setSelectedChild(child)
			})
		}
	}, [selected_child_ids, open])

    const handleClose = () => {
        setOpen(false)

        let new_selected_child_ids: number[] = []
        new_selected_child_ids = selected_child_ids.slice(1)

        setSelectedChildIds(new_selected_child_ids)
        updateLocalStorageCompany('needing_to_authorise_children', JSON.stringify(new_selected_child_ids))
    }

    const handleChildAuthorisationChange = (event: SelectChangeEvent) => {
        setChildAuthorisationSelect(event.target.value as ChildAuthorisation)
    }

    const handleSubmit = () => {
        const selected_child_id = selected_child_ids[0]

        const data = {
            authorized: child_authorisation_select,
            date_of_authorization: convertDateToYYYYMMDD(new Date())
        }

        const child_actions = new ChildActions()
        child_actions.update(data, selected_child_id.toString())
        .then(() => {
            setMessage(props => ({...props, success: "Child updated"}))
            handleClose()
        })
        .catch(error => {
            log.error(error.message)
            setMessage(props => ({...props, error: "Something went wrong. Please try again."}))
        })
    }

    return (
        <INastixDialog
            open={open}
            disableEscapeKeyDown
        >
        <INastixDialogTitle>{selected_child?.username} Needs Authorisation</INastixDialogTitle>
        <Alert severity="warning"> A child cannot be in the {ChildAuthorisationMap[ChildAuthorisation.PENDING]} status for longer than {company.children_authorized_before_days} days. You must either authorise or suspend {selected_child?.username} before you can continue</Alert>
        <br/>
        <DialogFormControl sx={{width: '50%', margin: 'auto'}}>
            <ChildAuthorisationSelect child_authorisation_select={child_authorisation_select} handleChildAuthorisationChange={handleChildAuthorisationChange}/>
            <DialogButton disabled={child_authorisation_select === ''} variant='contained' onClick={handleSubmit}> submit </DialogButton>
        </DialogFormControl>
        </INastixDialog>
    )
}

export default ChildNeedsAuthorisationDialog
