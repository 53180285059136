import Pages from '@pages/Page'
import { PageHeadingTypography } from '@styles/Typography'
import FranchiseCostingActions from '@actions/CRUDActions/FranchiseCostingActions/franchiseCostingActions'
import FranchiseCostingTable from '@components/tables/CRUD/FranchiseCosting'


const FranchiseCostingPage = () => {
	return (
        <>
        <Pages page_title="Franchise Costing">
			<PageHeadingTypography> Franchise Costing </PageHeadingTypography>
            <FranchiseCostingTable action={new FranchiseCostingActions()}/>
        </Pages>
        </>
	)
}

export default FranchiseCostingPage
