import { useTranslation } from 'react-i18next'

import Pages from '@pages/Page'
import { PageHeadingTypography } from '@styles/Typography'

import UsersTabs from './UsersTabs'


const UsersPage = () => {

	const { t } = useTranslation('instructors')

	return (
		<Pages page_title={"Users"}>
			<PageHeadingTypography> {t('users')} </PageHeadingTypography>
            <UsersTabs/>
		</Pages>
	)
}

export default UsersPage
