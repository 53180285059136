import { useEffect, useState, useCallback  } from 'react'

import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import DriveEtaIcon from '@mui/icons-material/DriveEta'
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk'
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike'
import DirectionsSubwayIcon from '@mui/icons-material/DirectionsSubway'
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus'
import Accordion from '@mui/material/Accordion'
import Typography from '@mui/material/Typography'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import CardContent from '@mui/material/CardContent'
import Input from '@mui/material/Input'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import InputAdornment from '@mui/material/InputAdornment'
import FormHelperText from '@mui/material/FormHelperText'
import InfoIcon from '@mui/icons-material/Info'
import CalculateIcon from '@mui/icons-material/Calculate'
import SwapHorizIcon from '@mui/icons-material/SwapHoriz'

import InstructorDashboardActions from '@actions/InstructorDashboardActions/instructorDashboardActions'
import { PageHeadingTypography } from '@styles/Typography'
import Pages from '@pages/Page'
import SchoolActions from '@actions/CRUDActions/SchoolActions/schoolActions'
import { getLocalStorageUser } from '@utils/localStorage/user'
import { Address, Instructor, School } from '@utils/interfaces/interfaces'
import GradientCircularProgress from '@components/backdrops/GradientCircularProgress'
import { convertSecondsToMinutes } from '@utils/time/times'
import { convertKilometersToMeters, convertMetersToKilometers } from '@utils/utils/util'
import NonGridAddressFields from '@components/forms/Fields/NonGridAddressFields'
import UserActions from '@actions/CRUDActions/UserActions/userActions'

import { DRIVING_VEHICLE_FUEL_EFFICIENCY_IN_KM_PER_L_MAP } from './maps'
import InstructorActions from '@actions/CRUDActions/InstructorActions/instructorActions'
import { schoolsAverageFuelEfficieny } from './utils'
import { getUniqueObjectsConfigs } from '@utils/filters/filters'
import { parseNumberToMoneyValue, parseToMoneyValue } from '@utils/numbers/numbers'


export interface SchoolConfig {
    school_id: number
    school_name: string
    school_address: string | null
    transit_mode: 'walking' | 'bicycling' | 'driving' | 'bus' | 'rail'
    vehicle_weight: 'light' | 'medium' | 'large' | null
    min_before_arrival_time: '10' | '15' | '30'
    school_classes: SchoolClasses[] | null
    total_time_of_travel: number
    total_distance_of_travel: number
    average_fuel_effiency: number
    total_cost_of_travel: number
    status: 'OK' | 'ZERO_RESULTS'
    is_loading: boolean
    accordion_expanded: boolean
}

interface SchoolClasses {
    class_name: string
    // start_time: string
    travel_time: number
    travel_distance: number
    travel_cost: number | null
}

interface GoogleMapsDistanceAndTimesResponse {
    school_classes: {
        class_name: string
        travel_distance_meters: number
        travel_time_seconds: number
    }[]
    origin: string
    destination: string
    status: 'OK' | 'ZERO_RESULTS'
}


const InstructorDashboardPage = () => {
    const user = getLocalStorageUser()

    const [school_configs, setSchoolConfigs] = useState<SchoolConfig[]>([])
    const [fuel_price, setFuelPrice] = useState<number | null>(parseToMoneyValue(localStorage.getItem('fuel_price')) ?? null)
    const [fuel_price_error, setFuelPriceError] = useState<string | null>(null)
    const [salary, setSalary] = useState<number | null>(0)
    const [commission_earned, setCommissionEarned] = useState<number>(0)
    const [address, setAddress] = useState<Address | null>(null)
    const [recalculate_fuel_cost, setRecalculateFuelCost] = useState<boolean>(false)

	useEffect(() => {
        const user = getLocalStorageUser()

        const instructor_actions = new InstructorActions()
        instructor_actions.getById(user.instructor_id.toString())
        .then((instructor: Instructor) => {
            if (instructor.salary)
                setSalary(parseToMoneyValue(instructor.salary))

            if (instructor.amount_earned_from_commission)
                setCommissionEarned(parseToMoneyValue(instructor.amount_earned_from_commission) ?? 0)

            if (instructor.fuel_price)
                setFuelPrice(parseToMoneyValue(instructor.fuel_price))
        })
	}, [])

    useEffect(() => {
        if (fuel_price) {
            localStorage.setItem('fuel_price', fuel_price.toString())
            setFuelPriceError("")
        }
        else {
            setFuelPriceError("Set a fuel price.")
        }
    }, [fuel_price])

    const calculateFuelCost = useCallback((distance_m: number, fuel_efficiency_km_per_l: number) => {
        if (!fuel_price) {
            setFuelPriceError("Set a fuel price.")
            return 0
        }

        const distance_in_km = distance_m / 1000
        const fuel_consumed = distance_in_km / fuel_efficiency_km_per_l
        const fuel_cost = fuel_consumed * fuel_price
        return parseFloat(fuel_cost.toFixed(2))
    }, [fuel_price])

    const recalculateDistanceAndTime = useCallback(async (school_config: SchoolConfig, address: Address) => {
        console.log("Calculating...")

        const instructorDashboardActions = new InstructorDashboardActions()
        const data = {
            origin: parseAddressToStringRep(address),
            school_id: school_config.school_id,
            min_before_arrival_time: school_config.min_before_arrival_time,
            transit_mode: school_config.transit_mode
        }

        try {
            const distances_and_times: GoogleMapsDistanceAndTimesResponse = await instructorDashboardActions.viewDistanceCalculations(data)
            // const distances_and_times: GoogleMapsDistanceAndTimesResponse = {
            //     origin: parseAddressToStringRep(address),
            //     destination: "32A Curzon rd. Johannesburg, Gauteng, 2192, South Africa",
            //     status: 'OK',
            //     school_classes: [{
            //         class_name: "Sasha Class 1",
            //         travel_distance_meters: 1300,
            //         travel_time_seconds: 2345
            //     }]
            // }
            const updated_school_config = { ...school_config }

            updated_school_config.school_address = distances_and_times.destination
            updated_school_config.status = distances_and_times.status
            updated_school_config.is_loading = false
            let total_fuel_effiency = 0
            let total_cost_of_travel = 0
            let total_distance_of_travel = 0
            let total_time_of_travel = 0

            updated_school_config.school_classes = distances_and_times.school_classes.map(distance_and_time => {
                const travel_time = convertSecondsToMinutes(distance_and_time.travel_time_seconds, true)
                const travel_distance = convertMetersToKilometers(distance_and_time.travel_distance_meters, true)

                total_distance_of_travel = total_distance_of_travel + travel_distance
                total_time_of_travel = total_time_of_travel + travel_time
                
                let travel_cost = 0
                if (school_config.transit_mode === 'driving' && school_config.vehicle_weight) {
                    const fuel_effiency = DRIVING_VEHICLE_FUEL_EFFICIENCY_IN_KM_PER_L_MAP[school_config.vehicle_weight]
                    total_fuel_effiency = total_fuel_effiency + fuel_effiency
                    travel_cost = calculateFuelCost(distance_and_time.travel_distance_meters, fuel_effiency)
                    total_cost_of_travel = total_cost_of_travel + travel_cost
                }

                return {
                    class_name: distance_and_time.class_name,
                    travel_time: travel_time,
                    travel_distance: travel_distance,
                    travel_cost: travel_cost
                }
            })

            updated_school_config.total_distance_of_travel = parseNumberToMoneyValue(total_distance_of_travel) as number
            updated_school_config.total_time_of_travel = parseNumberToMoneyValue(total_time_of_travel) as number
            updated_school_config.total_cost_of_travel = parseNumberToMoneyValue(total_cost_of_travel) as number
            updated_school_config.average_fuel_effiency = schoolsAverageFuelEfficieny(updated_school_config)

            return updated_school_config
        } catch (error) {
            console.error('Error fetching distance calculations:', error)
            return school_config
        }
    }, [calculateFuelCost])

    useEffect(() => {
        const fetchIntialSchoolConfigs = async () => {
            try {
                const user = getLocalStorageUser()
                const user_actions = new UserActions()
                const user_data = await user_actions.getUserSettings(user.id.toString())
                const address: Address = user_data.addresses[0]
                setAddress(address)  // TODO: only setting one address block for now. Address is a one to many so need to add support for multiple

                const schoolActions = new SchoolActions()
                const schools: School[] = await schoolActions.get(undefined, undefined, {'classes__instructors': user.id})

                const unique_schools = getUniqueObjectsConfigs(schools, 'id')
                
                const initial_school_configs: SchoolConfig[] = unique_schools.map(school => ({
                    school_id: school.id,
                    school_name: school.name,
                    school_address: null,
                    transit_mode: 'driving',
                    vehicle_weight: 'medium',
                    min_before_arrival_time: '15',
                    school_classes: null,
                    total_time_of_travel: 0,
                    total_distance_of_travel: 0,
                    average_fuel_effiency: 0,
                    total_cost_of_travel: 0,
                    status: 'OK',
                    is_loading: true,
                    accordion_expanded: false
                }))

                setSchoolConfigs(initial_school_configs)

                const updatedSchoolConfigs = await Promise.all(initial_school_configs.map(async school_config => recalculateDistanceAndTime(school_config, address)))

                setSchoolConfigs(updatedSchoolConfigs)
            } catch (error) {
                console.error('Error fetching school configs:', error)
            }
        }

        fetchIntialSchoolConfigs()
    }, [recalculate_fuel_cost, recalculateDistanceAndTime])

    const updateSchoolConfig = async (school_config: SchoolConfig, updatedFields: Partial<SchoolConfig>, do_a_recalculation: boolean=true) => {
        if (!address)
            return

        const new_school_config = {
            ...school_config,
            ...updatedFields
        }

        setSchoolConfigs(props =>
            props.map(prop => 
                prop.school_id === school_config.school_id ? new_school_config : prop
            )
        )

        if (do_a_recalculation) {
            const updated_school_config = await recalculateDistanceAndTime(new_school_config, address)
            if (!updated_school_config)
                return

            setSchoolConfigs(props =>
                props.map(prop => 
                    prop.school_id === school_config.school_id ? updated_school_config : prop
                )
            )
        }
    }

    const handleTransitModeChange = (event: SelectChangeEvent, school_config: SchoolConfig) => {
        updateSchoolConfig(school_config, { transit_mode: event.target.value as "walking" | "bicycling" | "driving" | "bus" | "rail" })
    }

    const handleMinBeforeArrivalTimeChange = (event: SelectChangeEvent, school_config: SchoolConfig) => {
        updateSchoolConfig(school_config, { min_before_arrival_time: event.target.value as "10" | "15" | "30" })
    }

    const handleVehicleWeightChange = (event: SelectChangeEvent, school_config: SchoolConfig) => {
        updateSchoolConfig(school_config, { vehicle_weight: event.target.value as "light" | "medium" | "large" })
    }

    const renderCostOfTravelCellHeading = (transit_mode: "walking" | "bicycling" | "driving" | "bus" | "rail") => {
        if (transit_mode === 'walking' || transit_mode === 'bicycling')
            return <></>
        else
            return <TableCell align="right" sx={{fontSize: '0.9rem'}}>Cost of Travel (R)</TableCell>
    }

    const renderCostOfTravelCell = (school_class: SchoolClasses, transit_mode: "walking" | "bicycling" | "driving" | "bus" | "rail") => {
        if (transit_mode === 'walking' || transit_mode === 'bicycling')
            return <></>
        else if (transit_mode === 'driving')
            return <TableCell align="right">{school_class.travel_cost}</TableCell>
        else if (transit_mode === 'bus' || transit_mode === 'rail')
            return (
                <TableCell align="right">
                    <TextField id="outlined-basic" label="Outlined" variant="outlined" />
                </TableCell>
            )
    }

    const renderTotalCostOfTravelCell = (school_config: SchoolConfig, transit_mode: "walking" | "bicycling" | "driving" | "bus" | "rail") => {
        if (transit_mode === 'walking' || transit_mode === 'bicycling')
            return <></>
        else if (transit_mode === 'driving' || transit_mode === 'bus' || transit_mode === 'rail')
            return <TableCell align="right" sx={{fontWeight: '700'}}>{school_config.total_cost_of_travel}</TableCell>
    }

    const renderFuelEffiencyCellHeading = (transit_mode: "walking" | "bicycling" | "driving" | "bus" | "rail") => {
        if (transit_mode === 'driving')
            return <TableCell align="right">Est. Fuel Effiency (KM/L)</TableCell>
        else
            return <></>
    }

    const renderFuelEffiencyCell = (school_config: SchoolConfig, transit_mode: "walking" | "bicycling" | "driving" | "bus" | "rail") => {
        if (!school_config.vehicle_weight)
            return <></>

        if (transit_mode === 'driving')
            return <TableCell align="right" sx={{fontSize: '0.9rem'}}>{DRIVING_VEHICLE_FUEL_EFFICIENCY_IN_KM_PER_L_MAP[school_config.vehicle_weight]}</TableCell>
        else
            return <></>
    }

    const renderAverageFuelEffiencyCell = (school_config: SchoolConfig, transit_mode: "walking" | "bicycling" | "driving" | "bus" | "rail") => {
        if (!school_config.vehicle_weight)
            return <></>

        if (transit_mode === 'driving')
            return <TableCell align="right" sx={{fontWeight: '700'}}>{school_config.average_fuel_effiency}</TableCell>
        else
            return <></>
    }

    const renderAccordionDetails = (school_config: SchoolConfig) => {
        if (!school_config.school_classes)
            return <></>

        if (school_config.status === 'OK')
            return (
                <AccordionDetails>
                    <TableContainer>
                        <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>Class name</TableCell>
                                {/* <TableCell align="right">Start time</TableCell> */}
                                <TableCell align="right">Travel Distance (KM)</TableCell>
                                <TableCell align="right">Travel Time (Mins)</TableCell>
                                {renderFuelEffiencyCellHeading(school_config.transit_mode)}
                                {renderCostOfTravelCellHeading(school_config.transit_mode)}
                                {/* <TableCell align="right">Fuel price (R)</TableCell> */}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {school_config.school_classes.map(school_class => {
                                    return (
                                        <TableRow
                                            key={school_class.class_name}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {/* {school_class.class_name} <SwapHorizIcon/> */}
                                                {school_class.class_name}
                                            </TableCell>
                                            {/* <TableCell align="right">{school_class.start_time}</TableCell> */}
                                            <TableCell align="right" sx={{fontSize: '0.9rem'}}>{parseNumberToMoneyValue(school_class.travel_distance)}</TableCell>
                                            <TableCell align="right" sx={{fontSize: '0.9rem'}}>{parseNumberToMoneyValue(school_class.travel_time)}</TableCell>
                                            {/* <TableCell align="right">{fuel_price}</TableCell> */}
                                            {renderFuelEffiencyCell(school_config, school_config.transit_mode)}
                                            {renderCostOfTravelCell(school_class, school_config.transit_mode)}
                                        </TableRow>
                                    )
                                })}
                                <TableRow
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row"></TableCell>
                                    {/* <TableCell align="right">{school_class.start_time}</TableCell> */}
                                    <TableCell align="right" sx={{fontWeight: '700'}}>{parseNumberToMoneyValue(school_config.total_distance_of_travel)}</TableCell>
                                    <TableCell align="right" sx={{fontWeight: '700'}}>{parseNumberToMoneyValue(school_config.total_time_of_travel)}</TableCell>
                                    {/* <TableCell align="right">{fuel_price}</TableCell> */}
                                    {renderAverageFuelEffiencyCell(school_config, school_config.transit_mode)}
                                    {renderTotalCostOfTravelCell(school_config, school_config.transit_mode)}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </AccordionDetails>
            )
        else if (school_config.status === 'ZERO_RESULTS') 
            return <Typography sx={{fontStyle: 'italic', textAlign: 'center', marginBottom: '1rem', color: 'grey'}}> No results found </Typography>
    }

    const handleAccordionExpandedChange = (passed_school_config: SchoolConfig) => {
        updateSchoolConfig(passed_school_config, {accordion_expanded: !passed_school_config.accordion_expanded}, false)
    }

    const handleAddressCallback = (data: Address) => {
        setAddress(data)
    }

    const handleFuelPriceChange = (event: any) => {
        setFuelPrice(event.target.value)
    }

    const handleAddressRecalculate = () => {
        setRecalculateFuelCost(props => !props)
    }

    const handleFuelPriceBlur = (event: any) => {
        const updatedSchoolConfigs = school_configs.map(school_config => {
            if (!school_config.school_classes) return school_config
    
            let total_cost_of_travel = 0

            const updatedSchoolClasses = school_config.school_classes.map(distance_and_time => {
                let travel_cost: number = 0
                if (school_config.transit_mode === 'driving' && school_config.vehicle_weight) {
                    const fuel_efficiency = DRIVING_VEHICLE_FUEL_EFFICIENCY_IN_KM_PER_L_MAP[school_config.vehicle_weight]
                    travel_cost = parseNumberToMoneyValue(calculateFuelCost(convertKilometersToMeters(distance_and_time.travel_distance), fuel_efficiency)) ?? 0
                    total_cost_of_travel = parseNumberToMoneyValue(total_cost_of_travel + (travel_cost ?? 0)) ?? 0
                }
    
                return {
                    ...distance_and_time,
                    travel_cost: travel_cost,
                }
            })
    
            return {
                ...school_config,
                school_classes: updatedSchoolClasses,
                total_cost_of_travel: total_cost_of_travel
            }
        })

        setSchoolConfigs(updatedSchoolConfigs)

        const instructor_actions = new InstructorActions()
        instructor_actions.update({fuel_price: event.target.value}, user.instructor_id.toString())
    }

    const handleSalaryBlur = (event: any) => {
        const instructor_actions = new InstructorActions()
        instructor_actions.update({salary: event.target.value}, user.instructor_id.toString())
    }

    const handleCommissionEarnedBlur = (event: any) => {
        const instructor_actions = new InstructorActions()
        instructor_actions.update({amount_earned_from_commission: event.target.value}, user.instructor_id.toString())
    }

    const handleSalaryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setSalary(parseToMoneyValue(value))
    }

    const handleCommissionEarnedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        setCommissionEarned(parseToMoneyValue(value) ?? 0)
    }

    const parseAddressToStringRep = (address: Address) => {
        return `${address.street} ${address.city} ${address.region} ${address.country} ${address.postal_code}`
    }

    const total_travel_cost = parseNumberToMoneyValue(school_configs.reduce((acc, school) => acc + school.total_cost_of_travel, 0))
    let gross_earned: number | null = null
    let net_earned: number | null = null

    if (salary) {
        gross_earned = parseFloat((salary + commission_earned).toFixed(2))
        net_earned = parseFloat((gross_earned - (total_travel_cost ?? 0)).toFixed(2))
    }

	return (
		<Pages allowed_to_view={user.is_instructor} page_title="Instructor Dashboard">
            <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '1rem', marginBottom: '2rem', justifyContent: 'space-between', alignItems: 'center'}}> 
                <Box>
                    <PageHeadingTypography sx={{marginBottom: '0rem'}}> Instructor Dashboard </PageHeadingTypography>
                    <Typography variant="caption" display="block" gutterBottom>Powered by Google Maps <img width="12px" src="https://th.bing.com/th/id/OIP.EbBzpQ7hElK-gpgFCz31vwAAAA?rs=1&pid=ImgDetMain" alt="Google maps icon"/></Typography>
                </Box>
                <FormControl sx={{ m: 1 }} variant="standard">
                    <TextField
                        error={!!fuel_price_error}
                        helperText={fuel_price_error}
                        label="Fuel Price"
                        value={fuel_price}
                        onChange={handleFuelPriceChange}
                        onBlur={handleFuelPriceBlur}
                        InputProps={{startAdornment: (<InputAdornment position="start">R/ℓ</InputAdornment>)}}
                        variant="standard"
                    />
                </FormControl>
            </Box>
            <Box sx={{
                display: 'flex', 
                flexWrap: 'wrap', 
                gap: '1rem',
                '@media (max-width: 700px)': {
                    flexDirection: 'column',
                },
            }}>
                <Card variant="outlined" sx={{minWidth: '250px', flex: 1, marginBottom: '1rem'}}>
                    <CardContent>
                        <Typography sx={{fontSize: '0.8rem', color: 'darkslategray', marginBottom: '1rem'}}> Enumeration Calculation </Typography>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={12} sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                <Typography>Salary:</Typography>
                                <FormControl sx={{ width: '8rem' }}>
                                    <Input
                                        value={salary}
                                        onChange={handleSalaryChange}
                                        onBlur={handleSalaryBlur}
                                        type="number"
                                        inputProps={{ step: 0.01 }}
                                        sx={{width: '8rem'}}
                                        startAdornment={<InputAdornment position="start">R</InputAdornment>}
                                    />
                                    {!salary && <FormHelperText sx={{color: 'red'}}>Add a Salary</FormHelperText>}
                                </FormControl>
                            </Grid>
                            {/* <Grid item xs={12} md={12} sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem'}}>
                                <Typography>Commission:</Typography>
                                <FormControl sx={{ width: '8rem' }}>
                                    <Input
                                        value={commission}
                                        onChange={handleCommissionChange}
                                        onBlur={handleCommissionBlur}
                                        type="number"
                                        inputProps={{ step: 0.01 }}
                                        sx={{width: '8rem'}}
                                        endAdornment={<InputAdornment position="start">%</InputAdornment>}
                                    />
                                    {!commission && <FormHelperText sx={{color: 'red'}}>Add a Commission</FormHelperText>}
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} sx={{marginBottom: '1rem'}}>
                                <Typography>Amount earned from commission:</Typography>
                            </Grid> 
                            <Grid item xs={6} sx={{textAlign: 'right'}}>
                                <Typography>R{amount_earned_from_commission}</Typography>
                            </Grid>*/}
                            <Grid item xs={12} md={12} sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem'}}>
                                <Typography>Est. Commission earned:</Typography>
                                <FormControl sx={{ width: '8rem' }}>
                                    <Input
                                        value={commission_earned}
                                        onChange={handleCommissionEarnedChange}
                                        onBlur={handleCommissionEarnedBlur}
                                        type="number"
                                        inputProps={{ step: 0.01 }}
                                        sx={{width: '8rem'}}
                                        startAdornment={<InputAdornment position="start">R</InputAdornment>}
                                    />
                                    {!commission_earned && <FormHelperText sx={{color: 'red'}}>Add est. Commission</FormHelperText>}
                                </FormControl>
                            </Grid>
                            {gross_earned && net_earned &&
                                <>
                                <Grid item xs={6}>
                                    <Typography sx={{ fontWeight: 'bold', borderBottom: '2px solid', borderRight: '2px solid' }}>
                                        Gross earned:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={{ fontWeight: 'bold', borderBottom: '2px solid' }}>R{gross_earned}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>Total Travel Time:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>{school_configs.reduce((acc, school) => acc + school.total_time_of_travel, 0)} Mins</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>Total Travel Cost:</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={{ color: 'red' }}>-R{total_travel_cost}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={{ fontWeight: 'bold', borderBottom: '4px double', borderRight: '2px solid' }}>
                                        Net earned:
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography sx={{ fontWeight: 'bold', borderBottom: '4px double' }}>R{net_earned}</Typography>
                                </Grid>
                                </>
                            }
                        </Grid>
                    </CardContent>
                </Card>
                <Card variant="outlined" sx={{minWidth: '250px', flex: 1, marginBottom: '1rem'}}>
                    <CardContent>
                        <Typography sx={{fontSize: '0.8rem', color: 'darkslategray'}}> 
                            Your Start Address 
                            <Tooltip 
                                slotProps={{popper: {modifiers: [{name: 'offset', options: {offset: [0, -14]}}]}}}
                                title="Google Maps will use this as your Start Address." 
                            >
                                <IconButton>
                                    <InfoIcon sx={{fontSize: '0.9rem'}}/>
                                </IconButton>
                            </Tooltip>
                        </Typography>
                        <Typography sx={{fontSize: '0.8rem', color: 'darkslategray', marginBottom: '1rem'}}> To change permanently. Go to <a href="/settings/profile">settings</a> </Typography>
                        <Box sx={{display: 'flex', gap: '2rem'}}>
                            <Box sx={{flex:0}}>
                                {address && <NonGridAddressFields address_values={address} setAddressCallback={handleAddressCallback} text_field_size='small' styles={{minWidth: '200px', width: '100px', margin: '0.5rem'}}/>}
                            </Box>
                            <Button onClick={handleAddressRecalculate} variant='contained' sx={{minWidth: '2.5rem', color: 'white', padding: 0}}>
                                <CalculateIcon sx={{fontSize: '2rem'}}/>
                            </Button>
                        </Box>
                    </CardContent>
                </Card>                
            </Box>
            {school_configs.map((school_config, index) => {
                return (
                    <Accordion key={index} expanded={school_config.accordion_expanded} onChange={() => handleAccordionExpandedChange(school_config)}>
                        <AccordionSummary
                            expandIcon={school_config.is_loading ? <Box><GradientCircularProgress/></Box> : <ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                            sx={{alignItems: 'baseline'}}
                        >
                            <Box sx={{
                                display: 'flex', 
                                flexWrap: 'wrap',
                                gap: '1rem', 
                                rowGap: '2rem',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%'
                            }}>
                                <Box >
                                    <Typography sx={{fontWeight: '700', color: 'darkslategray'}}>
                                        {school_config.school_name}
                                    </Typography>
                                    <Typography sx={{fontWeight: '200', color: 'darkslategray', fontSize: '0.8rem'}}>
                                        {school_config.school_address}
                                    </Typography>
                                </Box>
                                {(!school_config.accordion_expanded) ?
                                    <Box sx={{                                            
                                        flexGrow: 1, 
                                        textAlign: 'center', 
                                        fontWeight: '700', 
                                        fontSize: '1.2rem',
                                        marginRight: '1rem',
                                        color: 'darkorange',
                                    }}>
                                        {!!school_config.total_time_of_travel && 
                                            <Typography sx={{fontWeight: 'inherit', fontSize: 'inherit'}}> 
                                                {school_config.total_time_of_travel} Mins
                                            </Typography>
                                        }
                                        {!!school_config.total_cost_of_travel &&
                                            <Typography sx={{fontWeight: 'inherit', fontSize: '0.8em'}}> 
                                                R{school_config.total_cost_of_travel} 
                                            </Typography>
                                        }
                                    </Box>
                                    : <div style={{flexGrow: 1}}></div>
                                }
                                <Box sx={{display: 'flex', gap: '1rem', marginRight: '1rem', flexWrap: 'wrap', justifyContent: 'center'}}>
                                    <FormControl sx={{minWidth: '9ch'}}>
                                        <InputLabel id="transit-mode-select-label">Transit</InputLabel>
                                        <Select
                                            labelId="transit-mode-select-label"
                                            id="transit-mode-select"
                                            value={school_config.transit_mode}
                                            label="Transit"
                                            onChange={(event) => handleTransitModeChange(event, school_config)}
                                            sx={{
                                                '& .MuiSelect-select': {
                                                    padding: '0.8rem',
                                                    paddingBottom: '0.3rem',
                                                    display: 'flex',
                                                    justifyContent: 'center'
                                                }
                                            }}
                                        >
                                            <MenuItem value={'walking'}><DirectionsWalkIcon fontSize='small'/></MenuItem>
                                            <MenuItem value={'bicycling'}><DirectionsBikeIcon fontSize='small'/></MenuItem>
                                            <MenuItem value={'driving'}><DriveEtaIcon fontSize='small'/> </MenuItem>
                                            <MenuItem value={'bus'}><DirectionsBusIcon fontSize='small'/></MenuItem>
                                            <MenuItem value={'rail'}><DirectionsSubwayIcon fontSize='small'/></MenuItem>
                                        </Select>
                                    </FormControl>
                                    {school_config.transit_mode === 'driving' && school_config.vehicle_weight && 
                                        <FormControl sx={{minWidth: '20ch'}} size="small">
                                            <InputLabel id="vehicle-weight-select-label">Vehicle Weight</InputLabel>
                                            <Select
                                                labelId="vehicle-weight-select-label"
                                                id="vehicle-weight-select"
                                                value={school_config.vehicle_weight}
                                                label="Vehicle Weight"
                                                onChange={(event) => handleVehicleWeightChange(event, school_config)}
                                            >
                                                <MenuItem value={'light'}>0 - 1,200 kg</MenuItem>
                                                <MenuItem value={'medium'}>1,200 - 1,600 kg</MenuItem>
                                                <MenuItem value={'large'}>1,400 - 1,800 kg</MenuItem>
                                            </Select>
                                        </FormControl>
                                    }
                                    <FormControl sx={{minWidth: '12ch'}} size="small">
                                        <InputLabel id="arrival-time-select-label">Arrival Time</InputLabel>
                                        <Select
                                            labelId="arrival-time-select-label"
                                            id="arrival-time-select"
                                            value={school_config.min_before_arrival_time}
                                            label="Arrival Time"
                                            onChange={(event) => handleMinBeforeArrivalTimeChange(event, school_config)}
                                        >
                                            <MenuItem value={'10'}>10min</MenuItem>
                                            <MenuItem value={'15'}>15min</MenuItem>
                                            <MenuItem value={'30'}>30min</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Box>
                        </AccordionSummary>
                        {renderAccordionDetails(school_config)}
                    </Accordion>
                )
            })}
            <div style={{marginBottom: '3rem'}}></div>
		</Pages>
	)
}

export default InstructorDashboardPage
