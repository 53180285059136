import {useState, useContext, useEffect} from 'react'
import { useTranslation } from 'react-i18next'

import AddIcon from '@mui/icons-material/Add'
import Typography from '@mui/material/Typography'

import StateManager from '@actions/StateManager/stateManager'
import { TableActionButon } from '@styles/Buttons'
import { DialogButton, DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { NumOfRequestsContext } from '@contexts/NumOfRequestsContext'
import trackRequests from '@adapters/helpers/trackRequests'
import { SetTableSelections } from '@components/tables/CRUD/CRUDTable'
import { School } from '@utils/interfaces/interfaces'
import { AllocationDialogProps} from '@utils/dialogProps/DialogProps'


const ConfirmAllocationDialog = (props: AllocationDialogProps) => {
	const {selectedObject, state_manager, setTableSelections, open, onClose} = props
    const { t } = useTranslation('tables')

	const {setNumOfRequests} = useContext(NumOfRequestsContext)

	const [tenantId, setTenantId] = useState('')

    useEffect(() => {
        const tenant = getLocalStorageCompany()
        setTenantId(tenant.company_uuid)
    }, [])


	const handleAllocationSubmit =  async () => {
		for (let school of selectedObject) {
			if (!school.id)
				throw new Error("There is no id in the form data object being posted. Add an id field")

			school.companies = [tenantId]
			const promise = state_manager.setUpdateAction(school, school.id.toString())
			return trackRequests(promise, setNumOfRequests)
			.then(() => {
				setTableSelections({
					row_selection_model: [],
					selections: []
				})
				handleClose()  // close the dialog if the promise resolved
				state_manager.setGetAction()
				return {message: "Updated successfully"}
			})
		}
		
	}

	const handleClose = () => {
		onClose()
	}

	return (
		<INastixDialog onClose={handleClose} open={open}>
 			<INastixDialogTitle> {'Confirm Allocation'} </INastixDialogTitle>
 			<DialogDivider/>
 			<Typography>
                 {'Confirm that you would like to allocate these schools to your company?'}
 			</Typography>
 			<DialogButton variant='contained' onClick={handleAllocationSubmit}> {t('allocate')} </DialogButton>
 		</INastixDialog>
	)
}

interface MyComponentProps {
	disabled: boolean
	selectedObject: School[]
	state_manager: StateManager
	setTableSelections: SetTableSelections
}


const AllocationDialogHandler = (props: MyComponentProps) => {
	const {disabled, selectedObject, state_manager, setTableSelections} = props
    const { t } = useTranslation('tables')

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
 		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<AddIcon/>}>
 			<Typography> {t('allocation')} </Typography>
 		</TableActionButon>
 		<ConfirmAllocationDialog selectedObject={selectedObject} state_manager={state_manager} setTableSelections={setTableSelections} open={open} onClose={handleClose}/>
 		</>
	)
}

export default AllocationDialogHandler


// const ConfirmAllocationDialog = (props: DialogForIdsProps) => {
// 	const {ids, state_manager, onClose, open} = props

// 	const {setMessage} = useContext(MessageContext)
//     const { t } = useTranslation('tables')
//     const [tenantId, setTenantId] = useState('')

//     useEffect(() => {
//         const tenant = getLocalStorageCompany()
//         setTenantId(tenant.company_uuid)
//     })

// 	const handleAllocation = async () => {
//         const school_actions = new SchoolActions()
//         for (let i = 0; i < ids.length; i++) {
//             school_actions.getById(ids[0].toString())
//             .then(school_data => {
//                 console.log(tenantId)
//                 console.log(school_data)
//                 console.log(school_data.companies)

//                 school_data.companies = [tenantId]
//                 console.log(school_data)

//                 school_actions.update(school_data, ids[0].toString())
//                 .then(() => {
// 		        	handleClose()  // close the dialog if the promise resolved
// 		        	setMessage(props => ({...props, success: "Allocated"}))
// 		        })
//                 const promise = state_manager.setUpdateAction(school_data, ids[0].toString())
//                 return state_manager.setUpdateAction(school_data, ids[0])
// 		        // .then(() => {
// 		        // 	handleClose()  // close the dialog if the promise resolved
// 		        // 	setMessage(props => ({...props, success: "Allocated"}))
// 		        // })
//             })
//         }
		
// 	}

// 	const handleClose = () => {
// 		onClose()

// 	}

// 	return (
// 		<INastixDialog onClose={handleClose} open={open}>
// 			<INastixDialogTitle> {'Confirm Allocation'} </INastixDialogTitle>
// 			<DialogDivider/>
// 			<Typography>
//                 {'Confirm that you would like to allocate these schools to your company?'}
// 			</Typography>
// 			<DialogButton variant='contained' onClick={handleAllocation}> {t('allocate')} </DialogButton>
// 		</INastixDialog>
// 	)
// }


// const ConfirmAllocationDialogHandler = (props: DialogComponentProps) => {
// 	const {disabled, ids, state_manager} = props

//     const { t } = useTranslation('tables')

// 	const [open, setOpen] = useState(false)

// 	const handleClickOpen = () => {
// 		setOpen(true)
// 	}

// 	const handleClose = () => {
// 		setOpen(false)
// 	}

// 	return (
// 		<>
// 		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<AddIcon/>}>
// 			<Typography> {t('allocation')} </Typography>
// 		</TableActionButon>
// 		<ConfirmAllocationDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>
// 		</>
// 	)
// }

// export default ConfirmAllocationDialogHandler