import { CRUDAction } from "@actions/Actions/actions"
import BillsHandlers from "@adapters/handlers/FinanceHandlers/BillsHandlers/billsHandlers"


class BillsActions extends CRUDAction {
    handler: BillsHandlers

    constructor() {
        super()
        this.handler = new BillsHandlers()
    }
}

export default BillsActions