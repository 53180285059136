import {useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import Form from "@components/forms/Form"
import UserActions from '@actions/CRUDActions/UserActions/userActions'
import { FormDataEntryType } from "@components/forms/Form"
import { UserType } from '@utils/enums/enums'
import { SetupBox, SetupHeadingTypography } from '@styles/Setup'
import { SubmitButon } from '@styles/Buttons'
import { LocalStorageCompany, LocalStorageUser } from '@utils/interfaces/interfaces'
import { MessageContext } from '@contexts/MessageContext'
import UserTypeInfoDialogHandler from '@components/dialogs/UserTypeInfoDialogHandler'
import { handleCompanyLocalStorageInit } from '@components/authentication/helper/helper'
import GenericFetchWrapper from '@adapters/api/fetchWrappers/genericFetchWrapper'
import { JWT_PING_ENDPOINT } from '@adapters/routes/endpoints'

import { SelectedUserTypeContext } from '../SetupStepper/setSelectedUserType'
import ChooseUserTypeFields from './ChooseUserTypeFields'
import { validateFileUploadsAndAppendToFormData } from './utils'


const ChooseUserType = () => {
    
    const{ t } = useTranslation('user_setup')

    const {setSelectedUserType} = useContext(SelectedUserTypeContext)
    const {setMessage} = useContext(MessageContext)

    const [is_instructor_checked, setIsInstructorChecked] = useState(false)
    const [south_african_gl_course, setSouthAfricanGICourse] = useState<File | null>(null)
    const [police_clearance_certificate, setPoliceClearanceCertificate] = useState<File | null>(null)
    const [employment_contract, setEmploymentContract] = useState<File | null>(null)
    const [copy_of_id_photo, setCopyOfIdPhoto] = useState<File | null>(null)

    const setIsInstructorCheckedCallback = (is_instructor_checked: boolean) => {
        setIsInstructorChecked(is_instructor_checked)
    }

    const createFields = () => {
        return (
            <div style={{position: 'relative'}}>
            <ChooseUserTypeFields 
                setIsInstructorCheckedCallback={setIsInstructorCheckedCallback} 
                setSouthAfricanGICourse={setSouthAfricanGICourse} 
                setPoliceClearanceCertificate={setPoliceClearanceCertificate} 
                setCopyOfIdPhoto={setCopyOfIdPhoto}
                setEmploymentContract={setEmploymentContract}
            />
            <UserTypeInfoDialogHandler/>
            <SubmitButon variant='contained' type="submit"> {t('submit')} </SubmitButon>
            </div>
        )
    }

    const onSubmit = async (form_data_object: FormDataEntryType): Promise<any> => {
        setMessage(props => ({...props, form_error: ""}))
        setMessage(props => ({...props, error: ""}))

        // due to unique file upload (content-type), making sure access token is valid/refreshed in order to prevent data not being sent in post request
        // because of chaning content-type to 'application/json' during refreshing
        const fetch_wrapper = new GenericFetchWrapper()
        await fetch_wrapper.get(JWT_PING_ENDPOINT)

        let form_data = new FormData()

        if (!form_data_object.user_type) {
            setMessage(props => ({...props, form_error: t('complete_all_fields')}))
            return
        }

        const form_data_object_string = JSON.stringify(form_data_object)
        form_data.append('object_data', form_data_object_string)

        const user_type = form_data_object.user_type
        if (is_instructor_checked || user_type === UserType.INSTRUCTOR || user_type === UserType.ASSISTANT) {
            const response = validateFileUploadsAndAppendToFormData(is_instructor_checked, south_african_gl_course, police_clearance_certificate, copy_of_id_photo, employment_contract, form_data, form_data_object, setMessage)
            if (!response)
                return
        }
        else if (user_type === UserType.SCHOOL_OPERATOR)
            setSelectedUserType(user_type)

        const user_action = new UserActions()
        return user_action.chooseUserSetup(form_data)
		.then((data: {user: LocalStorageUser, tenant?: LocalStorageCompany}) => {
            const user = data.user
            const tenant = data.tenant

            // update the localStorage user because the group needs all the permissions as well
            localStorage.setItem('user', JSON.stringify(user))
            if (tenant)
                handleCompanyLocalStorageInit(tenant)
        })
	}

    return (
        <SetupBox>
        <SetupHeadingTypography variant="h1" marginTop='3rem'> {t('choose_a_user_type')} </SetupHeadingTypography>
        <br/>
		<Form onSubmitCallback={onSubmit} createFields={createFields}/>
        </SetupBox>
    )
}

export default ChooseUserType