import GenericFetchWrapper from "@adapters/api/fetchWrappers/genericFetchWrapper"
import { BaseHandler } from "@adapters/handlers/Handlers/handlers"
import { INSTRUCTOR_DASHBOARD } from "@adapters/routes/endpoints"


class InstructorDashboardHandler extends BaseHandler {
    endpoint: string
    distance_calculations_endpoint: string
    fetch_wrapper: GenericFetchWrapper

    constructor () {
        super()
        this.fetch_wrapper = new GenericFetchWrapper()
        this.endpoint = INSTRUCTOR_DASHBOARD
        this.distance_calculations_endpoint = INSTRUCTOR_DASHBOARD + '/distance-calculator'
    }

    viewDistanceCalculations (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.distance_calculations_endpoint)
        return this.fetch_wrapper.post(parsed_endpoint, data)
    }
}

export default InstructorDashboardHandler