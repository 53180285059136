import {useEffect, useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import {
    Chart as ChartJS,
    ChartData,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import ChildActions from '@actions/CRUDActions/ChildActions/childActions'

import { ChartBox } from '../styles'
import ToggleChartVisibility from '../ToggleChartVisibility'
import { DashbaordStatsProps } from './interfaces'


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)


type TShirtsCountData = {
    extra_small_tshirts: number
    small_tshirts: number
    medium_tshirts: number
    large_tshirts: number
    extra_large_tshirts: number
    extra_extra_large_tshirts: number
    extra_extra_extra_large_tshirts: number
}


const labels = ['xs', 's', 'm', 'l', 'xl', 'xxl', 'xxxl']

const InstructorTShirtGroups = (props: DashbaordStatsProps) => {
    const {edit_dashboard_open, checked, setChecked, selected_franchise_option, selected_school_type_options} = props
    
    const { t } = useTranslation('dashboard')
    const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: "Instructor T-Shirts grouped by size",
            },
        },
    }

    const [data, setData] = useState<ChartData<"bar", (number | [number, number] | null)[], string>>({labels, datasets: []})

    useEffect(() => {
        if (!selected_franchise_option)
            return

        const setChildrenBirthdayStat = async () => {

            const child_actions = new ChildActions()
            
            const today = new Date()
            const oneYearAgo = new Date(today)
            oneYearAgo.setFullYear(today.getFullYear() - 1)  // Calculate date 1 year ago
            const threeYearsAgo = new Date(today)
            threeYearsAgo.setFullYear(today.getFullYear() - 3)  // Calculate date 3 years ago

            const data: any = {
                franchise_id: selected_franchise_option.id,
            }
            if (selected_school_type_options)
                data['selected_school_type_options'] = selected_school_type_options

            const tshirt_count_data: TShirtsCountData = await child_actions.instructorStatTShirts(data)

            setData({
                labels,
                datasets: [
                    {
                        label: "Instructors",
                        data: Object.values(tshirt_count_data),
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                ],
            })
        }
        setChildrenBirthdayStat()

    }, [selected_franchise_option, selected_school_type_options, change_company_rerender])

    return (
        <>
        <ChartBox edit_dashboard_open={edit_dashboard_open} checked={checked}>
            <Bar options={options} data={data} />
            <canvas style={{ display: 'inline', height: 0 }} />
        </ChartBox>
        {edit_dashboard_open && 
            <>
            <ToggleChartVisibility checked={checked} setChecked={setChecked}/>
            </>
        }
        </>
    )
}

export default InstructorTShirtGroups
