import { BaseAction } from "@actions/Actions/actions"
import InstructorDashboardHandler from "@adapters/handlers/InstructorDashboardHandlers/instructorDashboardHandlers"


class InstructorDashboardActions extends BaseAction {
    handler: InstructorDashboardHandler
    
    constructor() {
        super()
        this.handler = new InstructorDashboardHandler()
    }

    viewDistanceCalculations = (data: object): Promise<any> => {
        return this.handler.viewDistanceCalculations(data)
    }
}

export default InstructorDashboardActions