import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'

import { DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import Form, { FormDataEntryType } from '@components/forms/Form'
import {SchoolFinanceDialogProps} from '@utils/dialogProps/DialogProps'
import SchoolActions from '@actions/CRUDActions/SchoolActions/schoolActions'

import log from 'loglevel'

import SchoolsMap from './SchoolsMap'



const SchoolFinanceDialog = (props: SchoolFinanceDialogProps) => {
	const {open, setOpen} = props
    const{ t } = useTranslation('finance_details')

    const createFields = () => {
        return (
            <>
            <SchoolsMap />
            <Box sx={{textAlign: 'right'}}>
                <Button onClick={handleCancel}>
                    {t('cancel')}
                </Button>
                <Button variant='contained' type="submit">{t('ok')}</Button>
            </Box>
            </>
        )
    }

    const handleSubmit = async (form_data: FormDataEntryType) => {
        const school_actions = new SchoolActions()
        school_actions.update(form_data, form_data['school_company_id'] as string)
        .then(() => {
            handleClose()
            return {message: "Fees set"}
        })
        .catch(error => {
            log.error(error.message)
        })
    }

	const handleClose = () => {
		setOpen(false)
	}

    const handleCancel = () => {
        handleClose()
    }

    const handleBackdropClick = (event: any) => {
		event.stopPropagation()
    }

	return (
		<INastixDialog onClose={handleClose} open={open} onClick={handleBackdropClick}>
			<INastixDialogTitle> School fees </INastixDialogTitle>
			<DialogDivider/>
            <Form onSubmitCallback={handleSubmit} createFields={createFields}/>
		</INastixDialog>
	)
}

export default SchoolFinanceDialog
