import UserHandler from "@adapters/handlers/CRUDHandlers/UserHandlers/userHandlers"
import { CRUDAction } from "@actions/Actions/actions"
import ConnectedUserHandler from "@adapters/handlers/CRUDHandlers/UserHandlers/connectedUserHandler"
import SetupUserHandler from "@adapters/handlers/CRUDHandlers/UserHandlers/setupHandler"
import VerifyEmailHandler from "@adapters/handlers/CRUDHandlers/UserHandlers/verifiyEmailHandler"
import CompaniesHandler from "@adapters/handlers/CRUDHandlers/UserHandlers/companiesHandler"
import { LocalStorageCompany, LocalStorageDashboardConfig } from "@utils/interfaces/interfaces"
import UserSettingsHandler from "@adapters/handlers/CRUDHandlers/UserHandlers/userSettingsHandler"
import DashboardSettingsHandler from "@adapters/handlers/CRUDHandlers/UserHandlers/dashboardSettingsHandler"


class UserActions extends CRUDAction {
    handler: UserHandler
    companies_handler: CompaniesHandler
    connected_user_handler: ConnectedUserHandler
    setup_user_handler: SetupUserHandler
    verify_email_handler: VerifyEmailHandler
    user_settings_handler: UserSettingsHandler
    dashboard_settings_handler: DashboardSettingsHandler

    constructor() {
        super()
        this.handler = new UserHandler()
        this.companies_handler = new CompaniesHandler()
        this.connected_user_handler = new ConnectedUserHandler()
        this.setup_user_handler = new SetupUserHandler()
        this.verify_email_handler = new VerifyEmailHandler()
        this.user_settings_handler = new UserSettingsHandler()
        this.dashboard_settings_handler = new DashboardSettingsHandler()
    }

    get(
        page?: number,
        page_size?: number,
        filter = {},
        group: string = ''
    ): Promise<any> {
        return super.get(page, page_size, filter, group);
    }

    getCompanies = (user_id?: number): Promise<Array<LocalStorageCompany>> => {
        return this.companies_handler.get(user_id?.toString())
    }

    verifyEmailWithUidAndToken = (data: object): Promise<any> => {
        return this.verify_email_handler.verifyEmailWithUidAndToken(data)
    }

    verifyEmailWithCode = (data: object): Promise<any> => {
        return this.verify_email_handler.verifyEmailWithCode(data)
    }

    createSetupDetails = (data: object): Promise<any> => {
        return this.setup_user_handler.createSetupDetails(data)
    }

    chooseUserSetup = (data: object): Promise<any> => {
        return this.setup_user_handler.chooseUserSetup(data)
    }

    chooseCompanySetup = (data: object): Promise<any> => {
        return this.setup_user_handler.chooseCompanySetup(data)
    }

    getAuthorizedSetup = (): Promise<any> => {
        return this.setup_user_handler.getAuthorizedSetup()
    }

    // returns the connected user
    connectedUser (): Promise<any> {
        return this.connected_user_handler.get()
    } 

    getUserSettings = (id: string): Promise<any> => {
        return this.user_settings_handler.getById(id)
    }

    postUserSetttings = (data: object): Promise<any> => {
        return this.user_settings_handler.post(data)
    }
    updateUserSettings (data: object, id: string): Promise<any> {
        return this.user_settings_handler.put(data, id)
    }

    getDashboardSettings = (): Promise<LocalStorageDashboardConfig> => {
        return this.dashboard_settings_handler.get()
    }

    postDashboardSettings = (data: LocalStorageDashboardConfig): Promise<any> => {
        return this.dashboard_settings_handler.post(data)
    }
}

export default UserActions