import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import PendingActionsIcon from '@mui/icons-material/PendingActions'

import { AuthorizedStatus, AuthorizedStatusMap } from '@pages/Setup/UserSetup/GetAuthorized/enum'


interface UserAuthStatusSelectProps {
	component_id: string
	name: string
	label: string
	helper_text: string
	handleOnChange: (event: SelectChangeEvent) => void
	value: string
}

const UserAuthStatusSelect = (props: UserAuthStatusSelectProps) => {
	const {component_id, name, label, helper_text, handleOnChange, value} = props

	return (
		<FormControl sx={{marginBottom: 2}}>
			<InputLabel id={`${component_id}-authorised-status-select-label`}>{label}</InputLabel>
			<Select
				labelId={`${component_id}-authorised-status-select-label`}
				id={`${component_id}-authorised-status-select`}
				name={name}
				value={value.toString()}
				label={label}
				onChange={handleOnChange}
				sx={{textTransform: 'capitalize', '.MuiSelect-select': {
					display: 'flex',
					marginTop: '0.3rem',
					gap: '1rem'
				}}}
			>
				<MenuItem sx={{textTransform: 'capitalize', gap: '1rem'}} value={AuthorizedStatus.AUTHORIZED}>
					<> <CheckCircleIcon sx={{color:"forestgreen"}}/> {AuthorizedStatusMap[AuthorizedStatus.AUTHORIZED]} </>
				</MenuItem>
				<MenuItem sx={{textTransform: 'capitalize', gap: '1rem'}} value={AuthorizedStatus.PENDING} disabled>
					<> <PendingActionsIcon sx={{color:"orange"}}/> {AuthorizedStatusMap[AuthorizedStatus.PENDING]} </>
				</MenuItem>
				<MenuItem sx={{textTransform: 'capitalize', gap: '1rem'}} value={AuthorizedStatus.UNAUTHORIZED}>
					<> <CancelIcon sx={{color:"red"}}/> {AuthorizedStatusMap[AuthorizedStatus.UNAUTHORIZED]} </>
				</MenuItem>
			</Select>
			<FormHelperText>{helper_text}</FormHelperText>
		</FormControl>
	)
}


export default UserAuthStatusSelect