import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'

import MonkeyWork from '@images/inastix/monkey_work.png'


const UnderMaintenance = () => {
    return (
        <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h4" color='#1f365c' gutterBottom>
                We Apologize for the Inconvenience
            </Typography>
            <Typography variant="body1" color="text.secondary" textAlign="center" maxWidth="600px" mt={2}>
                Our website is currently undergoing maintenance to provide you with an even better experience. 
                We appreciate your patience and understanding during this time.
            </Typography>
            <Typography variant="body2" color='rgba(0, 0, 0, 0.6)' mt={4}>
                If you have any urgent inquiries, please feel free to contact our support team:
                {' '}
                <Link href="mailto:support@inastix.com">support@inastix.com</Link>
            </Typography>
            {/* You can also include an image, logo, or any other relevant content */}
            <img src={MonkeyWork} alt="Under Maintenance" style={{ width: '100%', maxWidth: '150px', marginTop: '20px' }} />
        </Box>
    )
}

export default UnderMaintenance
