import {useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import { getLocalStorageUser } from '@utils/localStorage/user'
import { isInGroup } from '@utils/utils/util'
import { UserType } from '@utils/enums/enums'
import BackToLogin from '@pages/Setup/BackToLogin'
import { getLocalStorageCompany } from '@utils/localStorage/company'


// page used to stop unauthorised user groups from being able to setup a company
const CompanyBeingSetup = () => {
    const { t } = useTranslation('set_up') 

    const user = getLocalStorageUser()
	const company = getLocalStorageCompany()
    const is_school_operator = isInGroup(user, company, UserType.SCHOOL_OPERATOR)
	const navigation = useNavigate()

    useEffect(() => {
		if (is_school_operator)
			navigation('/setup/school')

	}, [navigation, is_school_operator])

    return (
        <>
        <Box sx={{height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
            <Box>
                <Typography variant={"h5"} sx={{color: 'darkslategray'}}> 
                    {is_school_operator ? t('your_school_is_still_being_setup') : t('your_company_is_still_being_setup')}
                </Typography>
                <br/>
                <Typography> 
                    {t('log_back_in_when_the_setup_is_complete')}
                </Typography>
                <BackToLogin/>
            </Box>
        </Box>
        </>
    )
}

export default CompanyBeingSetup