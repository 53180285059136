import { alpha, styled } from '@mui/material/styles'
import { DataGrid, gridClasses } from '@mui/x-data-grid'
import { darken, lighten } from '@mui/material/styles'


const ODD_OPACITY = 0.1

const getBackgroundColor = (color: string, mode: string) =>
	mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);
  
const getHoverBackgroundColor = (color: string, mode: string) =>
	mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);
  
const getSelectedBackgroundColor = (color: string, mode: string) =>
	mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);
  
const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
	mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);


const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
	[`& .${gridClasses.row}.even`]: {
		backgroundColor: theme.palette.grey[100],
			'&:hover, &.Mui-hovered': {
			backgroundColor: alpha(theme.palette.primary.main, ODD_OPACITY),
			'@media (hover: none)': {
				backgroundColor: 'transparent',
			},
		},
		'&.Mui-selected': {
		backgroundColor: alpha(
			'#d6e1f3',
			1,
		),
		'&:hover, &.Mui-hovered': {
			backgroundColor: alpha(
				'#d6e1f3', 1
				),
			// Reset on touch devices, it doesn't add specificity
			'@media (hover: none)': {
				backgroundColor: alpha(
					'#d6e1f3', 1
				),
			},
		},
		},
	},
	[`& .${gridClasses.row}.odd`]: {
		'&.Mui-selected': {
		backgroundColor: alpha(
			'#d6e1f3',
			1,
		),
		'&:hover, &.Mui-hovered': {
			backgroundColor: alpha(
				'#d6e1f3', 1
				),
			// Reset on touch devices, it doesn't add specificity
			'@media (hover: none)': {
				backgroundColor: alpha(
					'#d6e1f3', 1
				),
			},
		},
		},
	},
	'& .super-app-theme--partially_complete': {
		backgroundColor: getBackgroundColor(
			theme.palette.warning.main,
			theme.palette.mode,
		),
		'&:hover': {
			backgroundColor: getHoverBackgroundColor(
				theme.palette.warning.main,
				theme.palette.mode,
			),
		},
		'&.Mui-selected': {
			backgroundColor: getSelectedBackgroundColor(
				theme.palette.warning.main,
				theme.palette.mode,
			),
			'&:hover': {
				backgroundColor: getSelectedHoverBackgroundColor(
					theme.palette.warning.main,
					theme.palette.mode,
				),
			},
		},
	},
	'& .super-app-theme--not_started': {
		backgroundColor: getBackgroundColor(
			theme.palette.error.main,
			theme.palette.mode,
		),
		'&:hover': {
			backgroundColor: getHoverBackgroundColor(
				theme.palette.error.main,
				theme.palette.mode,
			),
		},
		'&.Mui-selected': {
			backgroundColor: getSelectedBackgroundColor(
				theme.palette.error.main,
				theme.palette.mode,
			),
			'&:hover': {
				backgroundColor: getSelectedHoverBackgroundColor(
					theme.palette.error.main,
					theme.palette.mode,
				),
			},
		},
	},
}))

export default StripedDataGrid