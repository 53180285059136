import { ContentType } from "@adapters/api/enums/enum"
import GenericFetchWrapper from "@adapters/api/fetchWrappers/genericFetchWrapper"
import { BaseHandler } from "@adapters/handlers/Handlers/handlers"
import { USERS_ENDPOINT } from "@adapters/routes/endpoints"


class SetupUserHandler extends BaseHandler {
    endpoint: string
    fetch_wrapper: GenericFetchWrapper
    form_data_fetch_wrapper: GenericFetchWrapper

    constructor () {
        super()
        this.fetch_wrapper = new GenericFetchWrapper()
        this.form_data_fetch_wrapper = new GenericFetchWrapper(ContentType.FORM_DATA)
        this.endpoint = USERS_ENDPOINT + '/setup'
    }

    createSetupDetails (data: object): Promise<any> {
        const endpoint = this.endpoint + '/details'
        return this.fetch_wrapper.post(endpoint, data)
    }

    chooseUserSetup (data: object): Promise<any> {
        const endpoint = this.endpoint + '/choose-user'
        return this.form_data_fetch_wrapper.post(endpoint, data)
    }

    chooseCompanySetup (data: object): Promise<any> {
        const endpoint = this.endpoint + '/choose-company'
        return this.fetch_wrapper.post(endpoint, data)
    }

    getAuthorizedSetup (): Promise<any> {
        const endpoint = this.endpoint + '/is-authorized'
        return this.fetch_wrapper.get(endpoint)
    }
}

export default SetupUserHandler