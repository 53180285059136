import { CertificateNames } from "./enum"


const CertificateNamesToDisplayMap = {
    [CertificateNames.PERCEPTUAL_MA_CERTIFICATE]: "Perceptual MA Certificate",
    [CertificateNames.STANDARD_CERTIFICATE]: "Participation Certificate"
}

export {
    CertificateNamesToDisplayMap
}