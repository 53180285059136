// import { StrictMode } from 'react'
import ReactDOM from 'react-dom/client'

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import log from "loglevel"

import App from './App'
import reportWebVitals from './reportWebVitals'
import { registerServiceWorker, unregister } from './serviceWorker'
import { resources } from './init_language_conf'
import './index.css'


const root = ReactDOM.createRoot(
  	document.getElementById('root') as HTMLElement
)

root.render(
    // <StrictMode>
    //     <App />
    // </StrictMode>
    <App />
)

// Language I18n initialisation
i18n.use(initReactI18next).init({
    resources: resources,
    lng: 'en', // Set the default language
    fallbackLng: 'en', // Fallback language if translation is missing
    interpolation: {
        escapeValue: false, // React already escapes variables
    },
    ns: ['home', 'dashboard'],  // Specify the namespaces used in the app
    defaultNS: 'home',  // Set the default namespace
    load: 'languageOnly',  // Specify the load mode for dynamic loading
    fallbackNS: 'home',  // Fallback namespace if translation is missing
    backend: {
        // Configure the backend options for dynamic loading
        loadPath: './locales/{{lng}}/{{ns}}.json',
    },
})

log.info(process.env.REACT_APP_ENVIRONMENT)
log.info(`version -> ${process.env.CACHE_VERSION}`)
// only register service worker in production
if (process.env.REACT_APP_ENVIRONMENT === 'production' || process.env.REACT_APP_ENVIRONMENT === 'staging') {
    registerServiceWorker()
}
else {
    // set log level for dev debugging
    log.setLevel('info')
    unregister()
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

