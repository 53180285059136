import { MethodType } from "../enums/enum"

import FetchWrapper from "./fetchWrapper"


class AuthFetchWrapper extends FetchWrapper {

    get (endpoint: string): Promise<any> {
        const base_endpoint_url = this.getBaseEndpointUrl(endpoint)

        const request_options = this.createRequestOptions()
        const response = this.handleFetch(base_endpoint_url, request_options)
        return response
    }

    post (endpoint: string, data: object): Promise<any> {
        const base_endpoint_url = this.getBaseEndpointUrl(endpoint)

        const request_options = this.createRequestOptions(MethodType.POST, data)
        const response = this.handleFetch(base_endpoint_url, request_options)
        return response
    }

    handleFetch = async (url: string, obj: object): Promise<any> => {
        return fetch(url, obj)
        .then(async response => {

            if (response.status === 401)
                throw new Error("Email or password is incorrect")
            else if (!response.ok) {
                return response.text().then(text => {
                    const error_message = text.replace(/"/g, '')  // replaces "" with empty space, so our error message appears without quotes in the ui
                    throw new Error(error_message)
                })
            }
            try {
                let data = await response.json()
                return data
            } catch (error) {
                console.info('JSON parsing error:', error)
                return null
            }
        })
    }  

    createRequestOptions = (method: MethodType = MethodType.GET, data: object | null = null, headers: object | null = null) : object => {
        let obj = {}
        let header = {
            'Content-Type': this.content_type,
            ...headers
        }

        if (method === MethodType.POST || method === MethodType.PUT) {
            obj = {
                method: method,
                headers: header,
                body: JSON.stringify(data)
            }
        }
        else {
            obj = {
                method: method,
                headers: header
            }
        }
    
        return obj
    }
}

export default AuthFetchWrapper