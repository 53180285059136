import React, {useContext, useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Tooltip from '@mui/material/Tooltip'
import AddBusinessIcon from '@mui/icons-material/AddBusiness'
import { useMediaQuery, Theme } from '@mui/material'

import XeroLogo from '@images/xero/logo/logo.png'
import XeroLogoWithPlus from '@images/xero/logo/logo_with_plus.png'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { LocalStorageCompany } from '@utils/interfaces/interfaces'
import CompanyActions from '@actions/CompanyActions/companyActions'
import Divider from '@mui/material/Divider'
import XeroSignIn from '@components/authentication/XeroSignIn'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import { CreateCompanyDialog } from '@pages/Setup/UserSetup/SelectCompany/CreateCompanyDialogHandler'
import ConnectToAnExistingCompanyDialogHandler from '@pages/Setup/UserSetup/SelectCompany/ConnectToAnExistingCompanyDialogHandler'
import { useUpdateUsersConnectedCompany } from '@utils/system/updates'
import { isInGroup } from '@utils/utils/util'
import { CompanySetupStep, UserSetupStep, UserType } from '@utils/enums/enums'
import { getLocalStorageUser } from '@utils/localStorage/user'


const selectFieldStyles = {
    color: 'white',
    ".MuiSelect-select": {
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
    },
    ".MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
        top: '-2px'
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "white",
    },
    "& .MuiSvgIcon-root": {
        color: "white",
    },
}

const darkModeselectFieldStyles = {
    color: 'darkslategray',
    ".MuiSelect-select": {
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
    },
    ".MuiOutlinedInput-notchedOutline": {
        borderColor: "darkslategray",
        top: '-2px'
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "darkslategray",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "darkslategray",
    },
    "& .MuiSvgIcon-root": {
        color: "darkslategray",
    }
}


const getConnectedCompanyRecursively = (setCompany: React.Dispatch<React.SetStateAction<string>>) => {
    const connected_company = getLocalStorageCompany()
    if (connected_company.company_uuid)
        setCompany(connected_company.company_uuid) // companies must first be set so we don't set the select list to a company that doens't exist yet
    else {
        setTimeout(() => {
            getConnectedCompanyRecursively(setCompany)
        }, 1000)    
    }
}

interface XeroConnectionsSelectProps {
    dark_mode?: boolean
}

const XeroConnectionsSelect = (props: XeroConnectionsSelectProps) => {
    const {dark_mode} = props

    const is_small_screen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

    const navigation = useNavigate()
    const connected_companies = localStorage.getItem('connected_companies')
    const connected_company = getLocalStorageCompany()
	const [open, setOpen] = useState(false)

    const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)
    const updateUsersConnectedCompany = useUpdateUsersConnectedCompany()

    const [companies, setCompanies] = useState<LocalStorageCompany[]>(connected_companies ? JSON.parse(connected_companies) as LocalStorageCompany[]: [])
    const [company, setCompany] = useState<string>(
        connected_companies && connected_company?.company_uuid
          ? connected_company.company_uuid
          : ''
    )
    const { t } = useTranslation('navigation')

    const xero_sign_in_label = t('connect_a_xero_org')

    useEffect(() => {
        const fetchData = async () => {
            const company_actions = new CompanyActions()
            company_actions.getConnectedCompanies()
            .then((companies: LocalStorageCompany[]) => {
                setCompanies(companies)
                getConnectedCompanyRecursively(setCompany) // companies must first be set so we don't set the select list to a company that doens't exist yet
                localStorage.setItem('connected_companies', JSON.stringify(companies))
            })
        }
        fetchData()
    }, [change_company_rerender, connected_companies])

    const handleChange = (event: SelectChangeEvent) => {
        const company_uuid = event.target.value
        updateUsersConnectedCompany(company_uuid)
    }

    const user = getLocalStorageUser()
    const is_school_operator = isInGroup(user, connected_company, UserType.SCHOOL_OPERATOR)
    
    const is_xero_company = connected_company.is_xero_company

	const handleClickOpen = () => {
		setOpen(true)
	}

    const handleClose = () => {
		setOpen(false)
	}

    const companyName = (): string => {
        const found_company = companies.find(passed_company => company === passed_company.company_uuid)
        if (is_small_screen && found_company && found_company.tenant_name.length > 20)
            return found_company.tenant_name
        return ""
    }

    const selected_item_width = {maxWidth: connected_company.tenant_name ? `${connected_company.tenant_name.length + 8}ch` : '35ch'}
    return (
        <FormControl sx={{position: 'relative', marginRight: '0.5rem'}}>
            <Tooltip title={companyName()} placement="right">
            <Select
                labelId="company-select-label"
                id="company-select"
                value={company}
                name="company_uuid"
                onChange={handleChange}
                sx={dark_mode ? {...darkModeselectFieldStyles, ...selected_item_width} : {...selectFieldStyles, ...selected_item_width}}
            >
                {companies.map(company => {
                    return <MenuItem key={company.company_uuid} value={company.company_uuid}>{company.tenant_name}</MenuItem>
                })}
                {(!is_school_operator && user.setup_step === UserSetupStep.COMPLETED && connected_company.setup_step === CompanySetupStep.COMPLETED) && [
                    <Divider key="divider-1"/>,
                    <Button key="create-company" onClick={handleClickOpen} sx={{textTransform: 'capitalize', width: '100%', justifyContent: 'start', margin: '0 0.3rem'}} startIcon={<AddBusinessIcon sx={{ fontSize: "30px" }}/>}>
                        {t('add_another_organisation')}
                    </Button>,
                    <ConnectToAnExistingCompanyDialogHandler key="connect-company"/>,
                    <Box key="box-1" sx={{margin: '0.3rem'}}>
                        <XeroSignIn logo_image={XeroLogoWithPlus} label_text={xero_sign_in_label} logo_width='25px' styles={{textTransform: 'capitalize', border: 'none', width: '100%', justifyContent: 'start', padding: '0.3rem 0'}}/>
                    </Box>,
                    <Divider key="divider-2"/>,
                    <Button key="manage-connections" aria-label="manage-connections" sx={{width: '100%', justifyContent: 'stretch', marginTop: '0.4rem'}} onClick={() => navigation("/settings/business/xero-management")}> 
                        <img src={XeroLogo} alt="A blue Xero logo" width="25px"/>
                        <Typography ml={1} textTransform={'capitalize'} color={'darkslategray'}> {t('manage_connections')} </Typography>
                    </Button>
                ]
                }
            </Select>
            </Tooltip>
            {is_xero_company &&
                <Tooltip title="Connected with Xero">
                    <img src={XeroLogo} style={{position: 'absolute', right: '-9px', top: '-6px', width: '20px'}} alt="A blue Xero logo showing a Xero connection" width="15px"/>
                </Tooltip>
            }
            <CreateCompanyDialog setCompanies={setCompanies} open={open} onClose={handleClose}/>
        </FormControl>
    )
}

export default React.memo(XeroConnectionsSelect)