import Typography from '@mui/material/Typography'


const SetupFooter = () => {
    return (
        <Typography fontSize={'0.75rem'} textAlign={'center'} color={'darkslategrey'} pb={'1rem'} pl={'1rem'} pr={'1rem'}>
            Should you encounter any challenges, please do not hesitate to reach out to the dedicated iNastix team at{' '}
            <a href="mailto:support@inastix.com" style={{ color: 'blue', textDecoration: 'underline' }}>
                support@inastix.com
            </a>
        </Typography>
    )
}

export default SetupFooter