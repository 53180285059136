import {useState, useEffect} from 'react'
import Dialog from '@mui/material/Dialog'
import Link from '@mui/material/Link'

import UserActions from '@actions/CRUDActions/UserActions/userActions'
import PhoneNumber from '@pages/Setup/UserSetup/UserDetails/PhoneNumber'
import EmailFields from '@components/forms/Fields/EmailFields'
import { DialogButton, DialogDivider, DialogFormControl, INastixDialogTitle } from '@styles/Dialog'
import { useTranslation } from 'react-i18next'
import { ViewContactDialogProps } from '@utils/dialogProps/DialogProps'


interface ContactInfo {
    country_code: string
    phone_number: string
    email: string
}


const ViewContactDialog = (props: ViewContactDialogProps) => {
	const {id, onClose, open} = props

    const [contact_info, setContactInfo] = useState<ContactInfo>({
		country_code: '', 
		phone_number: '', 
		email: ''
	})

    useEffect(() => {
        const user_actions = new UserActions()
        user_actions.getUserSettings(id)
        .then(data => {
            console.log(data.contacts)
			const contact = data.contacts[0]
            setContactInfo({
				country_code: contact['country_code'], 
				phone_number: contact['phone_number'], 
				email: contact['email']
			})
        })
    }, [id])

	const handleClose = () => {
		onClose()
	}

	const { t } = useTranslation('company_details')

	return (
		<Dialog onClose={handleClose} open={open}>
			<INastixDialogTitle> {t('contact_details')} </INastixDialogTitle>
			<DialogDivider/>
			<DialogFormControl>
				<PhoneNumber 
					initial_phone_number={contact_info.phone_number} 
					initial_country_code={contact_info.country_code} 
					disabled={true}
				/>
				<br/>
				<EmailFields initial_email={contact_info.email} disabled={true}/>
				<DialogButton variant='contained' onClick={handleClose}> {t('okay')} </DialogButton>
			</DialogFormControl>
        </Dialog>
	)
}

interface ViewContactDialogHandlerProps {
	id: string
}


const ViewContactDialogHandler = (props: ViewContactDialogHandlerProps) => {
    const {id} = props

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const { t } = useTranslation('company_details')

	return (
		<>
        <Link href={'#'} onClick={handleClickOpen} sx={{width: '120px'}}> {t('view_details')} </Link>
		{open && <ViewContactDialog id={id} open={open} onClose={handleClose}/>}
		</>
	)
}

export default ViewContactDialogHandler