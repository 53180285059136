import BillsActions from '@actions/Finance/BillsActions/billsActions'

import Table from '@components/tables/Table/Table'
import OzowDialogHandler from '@components/tables/Custom/Finance/BillsTable/OzowMakePaymentDialogHandler'
import EditRoyaltyInvoicesHandler from '../RoyaltiesInvoicesTable/EditRoyaltyInvoicesHandler'
import { useTranslation } from 'react-i18next'


export interface TableProps {
	action: BillsActions
}

const BillsTable = (props: TableProps) => {
	const {action} = props

	const { t } = useTranslation('overview')

	const columns = [
		{ field: 'id', headerName: 'ID', minWidth: 50, flex:2 },
		{ field: 'num_lines', headerName: t('number_of_lines'), minWidth: 150, flex: 2 },
		{ field: 'total_amount', headerName: t('invoice_amount'), minWidth: 150, flex: 1.5 },
        { field: 'invoice_type', headerName: t('invoice_type'), minWidth: 150, flex: 2 },
		{ field: 'school_type', headerName: t('school_type'), minWidth: 150, flex: 1 },
		{ field: 'due_date', headerName: t('due_date'), minWidth: 100, flex: 2 },
		{ field: 'days_overdue', headerName: t('days_overdue'), minWidth: 120, flex: 2 },
		{ field: 'date_sent', headerName: t('date_sent'), minWidth: 150, flex: 1 },
	]

    return (
        <Table 
			single_selection_dialogs={[EditRoyaltyInvoicesHandler]}
			action={action} 
			columns={columns} 
			no_content_message="Add a Xero contact to your account to view their Xero bills"
		></Table>
    )
}

export default BillsTable