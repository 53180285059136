import { useEffect, useState, useContext, useRef } from 'react'

import DialogContent from '@mui/material/DialogContent'
import Box from '@mui/material/Box'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import { INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { MessageContext } from '@contexts/MessageContext'
import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import { IconButton, TextField, Typography } from '@mui/material'
import FindInPageIcon from '@mui/icons-material/FindInPage'
import { DialogProps } from '@utils/dialogProps/DialogProps'
import PrintAsPDF from '@components/utils/Helpers/PrintAsPDF'
import FranchiseCostingActions from '@actions/CRUDActions/FranchiseCostingActions/franchiseCostingActions'
import { FranchiseCosting, FranchiseCostingColumn } from '@utils/interfaces/interfaces'
import { FranchsiseCostingOpertions } from '@utils/enums/enums'
import { parseNumberToMoneyValue, parseToMoneyValue } from '@utils/numbers/numbers'
import FranchiseCostingColumnActions from '@actions/CRUDActions/FranchiseCostingColumnActions/franchiseCostingColumnActions'

import { tableStyles } from './styles'


const FranchiseCostingDialog = (props: DialogProps) => {
    const {ids, state_manager, onClose, open} = props

    const {setMessage} = useContext(MessageContext)
	const franchiseCostingRef = useRef(null)

	const [franchise_costing, setFranchiseCosting] = useState<FranchiseCosting | null>(null)
	const [total_fee, setTotalFee] = useState<number | null>(null)
	const [hide_component, setHideComponent] = useState(false)

    useEffect(() => {
		const franchise_costing_actions = new FranchiseCostingActions()
		franchise_costing_actions.getById(ids[0].toString())
		.then((franchise_costing: FranchiseCosting) => {
			console.log(franchise_costing)

			const sorted_columns = franchise_costing.franchise_costing_columns.sort(
				(a, b) => a.priority - b.priority
			)

            setFranchiseCosting({
                ...franchise_costing,
                franchise_costing_columns: sorted_columns,
            })

			let total_fee: number = 0
			for (const costing_column of franchise_costing.franchise_costing_columns) {
				total_fee = getNewFee(total_fee, costing_column) 
			}
			setTotalFee(total_fee)
		})
		.catch((error) => {
			console.log(error)
		})
        
    }, [])

	const handleMoveUpClick = (costing_column: FranchiseCostingColumn) => {
		if (!franchise_costing || costing_column.priority === 1) {
			return
		}
	
		const current_priority = costing_column.priority
		const new_priority = current_priority - 1
	
		const costing_column_actions = new FranchiseCostingColumnActions()
		costing_column_actions.update({priority: new_priority}, costing_column.id.toString())

		const target_column = franchise_costing.franchise_costing_columns.find(
			column => column.priority === new_priority
		)
	
		if (target_column) {
			costing_column_actions.update({priority: current_priority}, target_column.id.toString())

			target_column.priority = current_priority
			costing_column.priority = new_priority
	
			franchise_costing.franchise_costing_columns.sort((a, b) => a.priority - b.priority)
	
			setFranchiseCosting({ ...franchise_costing })
		}
	}

	const handleMoveDownClick = (costing_column: FranchiseCostingColumn) => {
		if (!franchise_costing || costing_column.priority === franchise_costing.franchise_costing_columns.length) {
			return
		}
	
		const current_priority = costing_column.priority
		const new_priority = current_priority + 1

		const costing_column_actions = new FranchiseCostingColumnActions()
		costing_column_actions.update({priority: new_priority}, costing_column.id.toString())
	
		const target_column = franchise_costing.franchise_costing_columns.find(
			column => column.priority === new_priority
		)
	
		if (target_column) {
			costing_column_actions.update({priority: current_priority}, target_column.id.toString())

			target_column.priority = current_priority
			costing_column.priority = new_priority
	
			franchise_costing.franchise_costing_columns.sort((a, b) => a.priority - b.priority)
	
			setFranchiseCosting({ ...franchise_costing })
		}
	}
	

	const getNewFee = (total_fee: number, costing_column: FranchiseCostingColumn) => {
		const costing_column_amount = parseToMoneyValue(costing_column.amount) as number

		const operations: { [key in FranchsiseCostingOpertions]: (a: number, b: number) => number } = {
			[FranchsiseCostingOpertions.ADD]: (a, b) => a + b,
			[FranchsiseCostingOpertions.MUL]: (a, b) => a * b,
			[FranchsiseCostingOpertions.SUB]: (a, b) => a - b,
			[FranchsiseCostingOpertions.DIV]: (a, b) => a / b,
		}
	
		const operationFn = operations[costing_column.operation]
		if (!operationFn) {
			throw new Error(`Unsupported operation: ${costing_column.operation}`)
		}
	
		const new_total_fee = operationFn(total_fee, costing_column_amount)
		return parseNumberToMoneyValue(new_total_fee) as number
	}

	const OperationMark = (costing_column: FranchiseCostingColumn) => {
		const getOperationMark = () => {
			switch (costing_column.operation) {
				case FranchsiseCostingOpertions.ADD:
				case FranchsiseCostingOpertions.MUL:
					return '+'
				case FranchsiseCostingOpertions.SUB:
				case FranchsiseCostingOpertions.DIV:
					return '-'
				default:
					return null
			}
		}
	
		const operationMark = getOperationMark()
		const color = operationMark === '+' ? 'green' : 'red'
	
		return operationMark ? <span style={{ color }}>{operationMark}</span> : null
	}

	const isAtTop = (costing_column: FranchiseCostingColumn) => {
		if (!franchise_costing)
			return false

		const first_column = franchise_costing.franchise_costing_columns[0]
		if (first_column.id === costing_column.id)
			return true
		return false
	}

	const isAtBottom = (costing_column: FranchiseCostingColumn) => {
		if (!franchise_costing)
			return false

		const last_column = franchise_costing.franchise_costing_columns[franchise_costing.franchise_costing_columns.length - 1]
		if (last_column.id === costing_column.id)
			return true
		return false
	}

	const handleNotesOnBlur = (event: any) => {
		console.log(event.target.value)

		const franchise_costing_actions = new FranchiseCostingActions()
		franchise_costing_actions.update({notes: event.target.value}, ids[0].toString())
		.then(() => {
			setMessage(props => ({...props, success: "Notes updated."}))
		})
		.catch((error) => {
			console.log(error)
		})
	}

    return (
        <INastixDialog onClose={onClose} open={open}>
        <INastixDialogTitle>Franchise Valuation</INastixDialogTitle>
        <DialogContent>
			{franchise_costing &&
				<>
				<TextField 
					size="small" 
					label="Notes"
					fullWidth
					onBlur={handleNotesOnBlur}
					defaultValue={franchise_costing.notes} 
					sx={{
						marginBottom: '1rem', 
						marginTop: '1rem',
						'& .MuiInputBase-root': {
							color: 'gray'
						}}
					} 
					multiline
				/>
				<PrintAsPDF filename='Franchise Valuation.pdf' setHideComponent={setHideComponent} ref={franchiseCostingRef}/>
				<Box ref={franchiseCostingRef}>
					{hide_component && <Typography  sx={{fontSize: '2rem', fontWeight: 'bold', color: 'darkslategray'}}>Franchise Valuation</Typography>}
					<table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '20px' }}>
						<thead>
							<tr>
								<th style={{...tableStyles.headerCell, minWidth: '30ch'}}><span style={{fontWeight: '300'}}>Franchise Area:</span> {franchise_costing.franchise_name}</th>
								<th style={{...tableStyles.headerCell, minWidth: '20ch', textAlign: 'center'}}>Franchise Fee <span style={{textTransform: 'uppercase'}}>({franchise_costing.currency})</span></th>
								<th> </th>
							</tr>
						</thead>
						<tbody>
							{franchise_costing.franchise_costing_columns.map((column, colIndex) => (
								<tr key={colIndex}>
									<td style={{...tableStyles.cell, textAlign: 'left'}}>
										{OperationMark(column)}
										{column.column_name}
									</td>
									<td style={{...tableStyles.cell}}>
										{column.amount}
									</td>
									<td>
										{!hide_component && !isAtTop(column) && <IconButton onClick={() => handleMoveUpClick(column)} sx={{padding: 0}}> <ArrowDropUpIcon sx={{fontSize: '1rem'}}/> </IconButton>}
										{!hide_component && !isAtBottom(column) && <IconButton onClick={() => handleMoveDownClick(column)} sx={{padding: 0}}> <ArrowDropDownIcon sx={{fontSize: '1rem'}}/> </IconButton>}
									</td>
								</tr>
							))}
							{total_fee &&
								<tr>
									<td style={{...tableStyles.cell, textAlign: 'left', fontWeight: 'bold'}}>
										Total Franchise Fee
									</td>
									<td style={{...tableStyles.cell, borderBottom: '3px double black', fontWeight: 'bold' }}>
										{total_fee}
									</td>
									<td></td>
								</tr>
							}
						</tbody>
					</table>
				</Box>
				</>
			}
		</DialogContent>
        </INastixDialog>
    )
}

const FranchiseCostingDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<FindInPageIcon/>}>
			<Typography> View </Typography>
		</TableActionButon>
		{open && <FranchiseCostingDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>}
		</>
	)
}

export default FranchiseCostingDialogHandler
