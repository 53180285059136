import { XERO_ACCOUNTS_ALL_ENDPOINT } from "@adapters/routes/endpoints"
import { GenericHandler } from "../../Handlers/handlers"


class XeroAccountCodesAllHandler extends GenericHandler {
    constructor (endpoint=XERO_ACCOUNTS_ALL_ENDPOINT) {
        super(endpoint)
    }
}

export default XeroAccountCodesAllHandler