import { CRUDAction } from "@actions/Actions/actions"
import FZoneEcommerceCartItemHandler from "@adapters/handlers/CRUDHandlers/FZoneEcommerceHandlers/fzoneEcommerceCartItemHandler"


class FZoneEcommerceCartItemActions extends CRUDAction {
    handler: FZoneEcommerceCartItemHandler

    constructor() {
        super()
        this.handler = new FZoneEcommerceCartItemHandler()
    }
}

export default FZoneEcommerceCartItemActions