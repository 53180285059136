import { FormDataEntryType } from "@components/forms/Form"


const cleanFeesObjectForBackend = (form_data_object: FormDataEntryType) => {
    let keysArray:string[] = []
    let extramural_fees_array:number[] = []
    let intramural_fees_array:number[] = []
    let class_fees_array:number[] = []
    Object.keys(form_data_object).forEach(key => {
        keysArray.push(key)
    })
    const length = keysArray.length
    for (let i = length - 1; i > -1; i--) {
        if (keysArray[i].includes('extramural_fee')) {
            let fee_value = Number(form_data_object[keysArray[i]])
            if (fee_value !== 0)
                extramural_fees_array.push(fee_value)
            delete form_data_object[keysArray[i]]
        }
        else if (keysArray[i].includes('intramural_fee')) {
            let fee_value = Number(form_data_object[keysArray[i]])
            if (fee_value !== 0)
                intramural_fees_array.push(fee_value)
            delete form_data_object[keysArray[i]]
        }
        else if (keysArray[i].includes('class_fee')) {
            let fee_value = Number(form_data_object[keysArray[i]])
            if (fee_value !== 0)
                class_fees_array.push(fee_value)
            delete form_data_object[keysArray[i]]
        }
    }
    const fees_object = {
        'extramural_fees': extramural_fees_array,
        'intramural_fees': intramural_fees_array,
        'per_class_fees': class_fees_array
    }

    return fees_object
}

export {cleanFeesObjectForBackend}