import {useEffect, useState, useMemo, useContext, ReactElement} from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import INastixTabs from '@components/navigation/INastixTabs'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import ChildYearlyTable from '@components/tables/CRUD/PaymentsTables/ChildPaymentsTable/ChildYearlyTable'
import ChildTermlyTable from '@components/tables/CRUD/PaymentsTables/ChildPaymentsTable/ChildTermlyTable'
import ChildMonthlyTable from '@components/tables/CRUD/PaymentsTables/ChildPaymentsTable/ChildMonthlyTable'
import ChildPaymentsActions from '@actions/Finance/PaymentActions/childPaymentActions'
import SchoolPaymentsActions from '@actions/Finance/PaymentActions/schoolPaymentActions'
import ChildHalfTermlyTable from '@components/tables/CRUD/PaymentsTables/ChildPaymentsTable/ChildHalfTermlyTable'
import SchoolMonthlyTable from '@components/tables/CRUD/PaymentsTables/SchoolPaymentsTable/SchoolMonthlyTable'
import SchoolTermlyTable from '@components/tables/CRUD/PaymentsTables/SchoolPaymentsTable/SchoolTermlyTable'
import SchoolYearlyTable from '@components/tables/CRUD/PaymentsTables/SchoolPaymentsTable/SchoolYearlyTable'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import CompanyActions from '@actions/CompanyActions/companyActions'


// used to map url to the iNastix Tab component
const PaymentTabsMap = {
    'monthly_members': 1,
	'termly_members': 2,
	'half_termly_members': 3,
	'yearly_members': 4,
	'monthly_schools': 5,
	'termly_schools': 6,
	'yearly_schools': 7
}

const PaymentTabs = () => {
	const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)

    const { t } = useTranslation('payments')

	const [tabs, setTabs] = useState<ReactElement[]>([])
	const [nav_items, setNavItems] = useState<string[]>([])

    const child_payment_actions = useMemo(() => new ChildPaymentsActions(), [])
	const school_payment_actions = useMemo(() => new SchoolPaymentsActions(), [])

	const location = useLocation()
	const tab_key = new URLSearchParams(location.search).get('tab')

	useEffect(() => {
        const company = getLocalStorageCompany()

        const company_actions = new CompanyActions()
        company_actions.getCompanySettings(company.company_uuid.toString())
        .then(data => {
            const finance = data.finance
			let all_tabs: ReactElement[] = []
			let all_navs: string[] = []

			if(finance.allows_yearly_fees === true) {
				all_tabs = [...all_tabs, 
					<ChildYearlyTable action={child_payment_actions}/>,
					<SchoolYearlyTable action={school_payment_actions}/>,
				]
				all_navs = [...all_navs, t('yearly_members'), t('yearly_schools')]
			}
			if(finance.allows_termly_fees === true) {
				all_tabs = [...all_tabs,
					<ChildTermlyTable action={child_payment_actions}/>,
					<ChildHalfTermlyTable action={child_payment_actions}/>,
					<SchoolTermlyTable action={school_payment_actions}/>,
				]
				all_navs = [...all_navs, t('termly_members'), t('half_termly_members'), t('termly_schools')]
			}
			if(finance.allows_monthly_fees === true) {
				all_tabs = [...all_tabs,
					<ChildMonthlyTable action={child_payment_actions}/>,
					<SchoolMonthlyTable action={school_payment_actions}/>,
				]
				all_navs = [...all_navs, t('monthly_members'), t('monthly_schools')]
			}

			setTabs(all_tabs)
			setNavItems(all_navs)
        })
    }, [change_company_rerender])

	return (
		<INastixTabs
			nav_items={nav_items}
			tab_items={tabs}
			tabs_map={PaymentTabsMap}
			tabs_key={tab_key}
		/>
	)
}

export default PaymentTabs