import { useTranslation } from 'react-i18next'
import { useMemo} from 'react'

import Pages from '@pages/Page'
import { PageHeadingTypography } from '@styles/Typography'
import { UserPermissions } from '@utils/enums/permissions'
import SchoolTable from '@components/tables/CRUD/SchoolTable'
import SchoolActions from '@actions/CRUDActions/SchoolActions/schoolActions'


const SchoolsPage = () => {

	const { t } = useTranslation('school_form')

	const school_actions = useMemo(() => new SchoolActions(), [])

	return (
		<Pages page_title="Schools" permission={UserPermissions.VIEW_SCHOOLS}>
			<PageHeadingTypography> {t('schools')} </PageHeadingTypography>
            <SchoolTable action={school_actions}/>
		</Pages>
	)
}

export default SchoolsPage
