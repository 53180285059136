import { useState  } from 'react'

import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'

import TradingStatus from './enum'


interface TradingStatusSelectProps {
    onSelect: (value: TradingStatus) => void
}

const CompanyTradingStatusSelect: React.FC<TradingStatusSelectProps> = ({ onSelect }) => {
    const [trading_status, setTradingStatus] = useState<TradingStatus>(TradingStatus.DEFAULT)

    const handleChange = (event: SelectChangeEvent<TradingStatus>) => {
        const selected_value = event.target.value as TradingStatus
        setTradingStatus(selected_value)
        onSelect(selected_value)
    }

    return (
        <FormControl sx={{minWidth: '30ch'}}>
            <InputLabel id="trading-status-label">Trading status</InputLabel>
            <Select
                labelId="trading-status-label"
                label="Trading Status"
                value={trading_status}
                onChange={handleChange}
                name="company_trading_status"
                required
            >
                <MenuItem value={TradingStatus.COMPANY}>Trading as a Company</MenuItem>
                <MenuItem value={TradingStatus.CC}>Trading as a CC</MenuItem>
                <MenuItem value={TradingStatus.PERSONAL}>Trading in Personal Name</MenuItem>
            </Select>
        </FormControl>
    )
}

export default CompanyTradingStatusSelect
