import {useState, useEffect} from 'react'

import Snackbar from '@mui/material/Snackbar'

import { BarProps } from './interface'
import Alert from '@mui/material/Alert'


const SuccessBar = (props: BarProps) => {
    const {message, resetMessage} = props

    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (message)
            setOpen(true)
    }, [message])

    const handleClose = () => {
        setOpen(false)
        resetMessage()
    }

    return (
        <>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={open} autoHideDuration={6000} onClose={handleClose} key={'topright'}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </>
    )
}

export default SuccessBar