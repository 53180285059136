import { useContext, useEffect } from 'react'

import { useLocation, useNavigate, useParams } from 'react-router-dom'

import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import Box from '@mui/material/Box'

import Form, { FormDataEntryType } from '@components/forms/Form'

import Button from '@mui/material/Button'

import { MessageContext } from '@contexts/MessageContext'
import SchoolServiceAgreementActions from '@actions/CRUDActions/SchoolActions/schoolServiceAgreement'
import BankDetailsFields from '@components/forms/Fields/BankDetails'

import log from 'loglevel'


const AcceptSchoolServiceAgreement = () => {
    const navigate = useNavigate()
    const { search } = useLocation()
    const query_params = new URLSearchParams(search)
    const { user_uidb64, company_class_uidb64, token } = useParams()

    const {setMessage} = useContext(MessageContext)

    const franchise_name = query_params.get('franchise_name')
    const class_name = query_params.get('class_name')

    useEffect(() => {
        // Load reCAPTCHA script when the component mounts
        const script = document.createElement('script')
        script.src = 'https://www.google.com/recaptcha/api.js?render=6LeSCWUmAAAAAC6acEwKoQoAcbpg_Do1RIesd0zt'
        script.async = true
        document.body.appendChild(script)
  
        return () => {
            // Cleanup: Remove the script when the component unmounts
            document.body.removeChild(script)
        }
    }, [])

    const createFields = () => {
        return (
            <>
            <FormControl sx={{width: '50%', display: 'flex', alignItems: 'center', margin: 'auto'}}>
                <BankDetailsFields/>
                <br/>
                <Button 
                    variant="contained" 
                    type="submit" 
                    sx={{display: 'inline-block', padding: '10px 20px', backgroundColor: '#4CAF50', color: '#fff', textDecoration: 'none', borderRadius: '5px'}}>
                        accept agreement
                    </Button> 
            </FormControl>
            </> 
        )
    }

    const onSubmit = async (form_data_object: FormDataEntryType): Promise<any> => {
        setMessage(props => ({...props, form_error: ""}))

        // Call the Google reCAPTCHA API to get the response token
        const recaptcha_token = await window.grecaptcha.execute('6LeSCWUmAAAAAC6acEwKoQoAcbpg_Do1RIesd0zt', { action: 'submit' })

        const data = {
            ...form_data_object,
            user_uidb64: user_uidb64,
            company_class_uidb64: company_class_uidb64,
            token: token,
            recaptcha_token: recaptcha_token
        }
        const service_agreement_action = new SchoolServiceAgreementActions()
        return service_agreement_action.acceptServiceAgreement(data)
        .then(message => {
            navigate('/login')
            return message
        })
        .catch(error => {
            log.error(error)
            return error
        })
	}

    return (
        <>
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection:'column', minHeight: '100vh', textAlign: 'center'}}>
            <Typography variant='h3' color="darkslategray" fontWeight={'500'} marginTop='2rem'>Monkeynastix Service Agreement</Typography>
            <br/>
            <Typography sx={{color: 'darkslategray', width: '50%', textAlign: 'center'}}>Accept the Monkeynastix Franchise-School Service Agreement </Typography>
            <br/>
            {franchise_name && class_name &&
                <Typography sx={{fontSize: '0.8rem'}}>For Franchise {franchise_name} and Class {class_name} </Typography>
            }
            <Form onSubmitCallback={onSubmit} createFields={createFields}/> 
        </Box>
        </>
    )
}

export default AcceptSchoolServiceAgreement