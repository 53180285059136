import {useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import changeLanguage from '@utils/language/change'
import { LanguageConf } from '@utils/enums/enums'
import { getLocalStorageUser } from '@utils/localStorage/user'
import UserActions from "@actions/CRUDActions/UserActions/userActions"


const LanguageSelector = () => {
    const { i18n, t } = useTranslation('settings')

    const [language, setLanguage] = useState('')

    useEffect(() => {
        const user = getLocalStorageUser()
        const user_actions = new UserActions()
        user_actions.getUserSettings(user.id.toString())
        .then(data => {
            if (data.settings.language === LanguageConf.AFRIKAANS)
                setLanguage(LanguageConf.AFRIKAANS)
            else if (data.settings.language === LanguageConf.ENGLISH)
                setLanguage(LanguageConf.ENGLISH)
        })
    }, [])

    const handleChange = (event: SelectChangeEvent) => {
        const lng = event.target.value as LanguageConf
        setLanguage(lng)
        changeLanguage(i18n, lng)
    }

    return (
        <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
            <InputLabel id="language-select-label">{t('language')}</InputLabel>
            <Select
                labelId="language-select-label"
                id="language-select"
                value={language}
                label="Language"
                onChange={handleChange}
            >
            <MenuItem value={LanguageConf.ENGLISH}>English</MenuItem>
            {/* <MenuItem value={LanguageConf.AFRIKAANS}>Afrikaans</MenuItem> */}
            </Select>
        </FormControl>
        </Box>
    )
}

export default LanguageSelector
