import { Grid } from "@mui/material"
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule'

import DatePickerNastix from "../DatePickerNastix"


interface DateRangePickerNastixProps {
    label_range_1: string
    label_range_2: string
    name_range_1: string
    name_range_2: string
    use_mobile?: boolean
}

export const DateRangePickerNastix = (props: DateRangePickerNastixProps) => {
    const {label_range_1, label_range_2, name_range_1, name_range_2, use_mobile} = props

    return (
        <>
        <Grid container alignItems={'center'} justifyContent={'center'}>
            <Grid item md={5} sm={12}>
                <DatePickerNastix label={label_range_1} name={name_range_1} use_mobile={use_mobile}/>
            </Grid>
            <Grid item md={2} sm={12} sx={{textAlign: 'center'}}>
                <HorizontalRuleIcon/>
            </Grid>
            <Grid item md={5} sm={12}>
                <DatePickerNastix label={label_range_2} name={name_range_2} use_mobile={use_mobile}/>
            </Grid>

        </Grid>
        </>
    )
}

export default DateRangePickerNastix