enum ContentType {
    JSON = 'application/json',
    XML = 'application/xml',
    FORM_DATA = 'multipart/form-data',
    TEXT_PLAIN = 'text/plain',
    SPREADSHEET = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    CSV = 'text/csv'
}

enum MethodType {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
}


export {ContentType, MethodType}