import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'

import InvoiceActions from '@actions/Finance/InvoiceActions/invoiceActions'
import InvoicesTable from '@components/tables/Custom/Finance/InvoicesTable'
import INastixTabs from '@components/navigation/INastixTabs'
import AwaitingPaymentsTable from '@components/tables/Custom/Finance/AwaitingPaymentsTable'

import FinancesPage from '../Finances'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { CompanyType } from '@utils/enums/enums'
import { companyIsInGroup } from '@utils/utils/util'
import PaidInvoicesTable from '@components/tables/Custom/Finance/PaidInvoicesTable'


const invoice_actions = new InvoiceActions()

const MemberInvoicesPage = () => {
    const navigate = useNavigate()

	const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)

	useEffect(() => {
		const company = getLocalStorageCompany()
		const is_franchise = companyIsInGroup(company, CompanyType.FRANCHISEE)
		const acts_like_a_franchise = company.acts_like_a_franchise

		if (!(is_franchise || acts_like_a_franchise)) {
			navigate('/finance/royalty-invoices')
			return
		}
	}, [change_company_rerender, navigate])

	const { t } = useTranslation('overview')

	return (
		<FinancesPage>
			<Box sx={{display: 'flex'}}>
				<INastixTabs
					nav_items={[
						t('awaiting_approval'), // `Awaiting Approval (${amount_of_finances_data.awaiting_approval_parents})`,
						t('awaiting_payment'), // `Awaiting Payment (${amount_of_finances_data.awaiting_payment_parents})`,
						t('paid_invoices'),
					]}
					tab_items={[
						<InvoicesTable action={invoice_actions}/>,
						<AwaitingPaymentsTable action={invoice_actions}/>,
						<PaidInvoicesTable action={invoice_actions}/>,
					]}
				> 
				</INastixTabs>
			</Box>
		</FinancesPage>
	)
}

export default MemberInvoicesPage
