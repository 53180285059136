import { createContext } from 'react'


export type SetCheckoutStep = React.Dispatch<React.SetStateAction<number>>

interface ICheckoutStepContext {
    checkout_step: number
    setCheckoutStep: SetCheckoutStep
}

export const CheckoutStepContext = createContext<ICheckoutStepContext>({
    checkout_step: 0,
    setCheckoutStep: () => {}
})