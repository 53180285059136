import React, {useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next'

import log from 'loglevel'

import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import { LocalStorageUser } from '@utils/interfaces/interfaces'
import UserActions from '@actions/CRUDActions/UserActions/userActions'
import { UserType } from '@utils/enums/enums'
import { getLocalStorageUser } from '@utils/localStorage/user'

import ViewContactDialogHandler from './ViewContactDialogHandler'


interface SelectPreferredContactProps {
    preferred_contact: string
    setPreferredContact: React.Dispatch<React.SetStateAction<string>>
    initial_value?: string
}

const SelectPreferredContact = (props: SelectPreferredContactProps) => {
    const {preferred_contact, setPreferredContact, initial_value} = props

    const [preferred_contacts, setPreferredContacts] = useState([])

    useEffect(() => {
        if (preferred_contacts.length && initial_value)
            setPreferredContact(initial_value)
    }, [preferred_contacts, initial_value, setPreferredContact])

    useEffect(() => {
        const user = getLocalStorageUser()
        
        const user_actions = new UserActions()
        Promise.all([
            user_actions.get(undefined, undefined, {'company_uuid': user.company_uuid}, UserType.SCHOOL_OPERATOR),
            user_actions.get(undefined, undefined, {'company_uuid': user.company_uuid}, UserType.FRANCHISEE_OPERATOR)
        ]).then(([school_operator_users, franchisee_operator_users]) => {
            const data = school_operator_users.concat(franchisee_operator_users)
            setPreferredContacts(data)
            console.log(school_operator_users)
        })
        .catch(error => {
            log.error(error)
        })

    }, [setPreferredContact])

    const handleChange = (event: SelectChangeEvent) => {
        setPreferredContact(event.target.value)
    }

    const { t } = useTranslation('company_details')

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap:'1rem'}}>
            <FormControl fullWidth>
                <InputLabel id="preferred-contact-select-label"> {t('contacts')} </InputLabel>
                <Select
                    labelId="preferred-contact-select-label"
                    id="preferred-contact-select"
                    value={preferred_contact}
                    label={t('preferred_contact')}
                    name="preferred_contact"
                    onChange={handleChange}
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {preferred_contacts.map((preferred_contact: LocalStorageUser) => {
                        return <MenuItem key={preferred_contact.id} value={preferred_contact.id}>{preferred_contact.username}</MenuItem>
                    })}
                </Select>
                <FormHelperText> {t('choose_this_company_main_contact_user')} </FormHelperText>
            </FormControl>
            {preferred_contact && <ViewContactDialogHandler id={preferred_contact}/>}
        </Box>    
    )
}

export default SelectPreferredContact