import { useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import { useMediaQuery } from '@mui/material'

import { getLocalStorageCompany } from '@utils/localStorage/company'
import { companyIsInGroup, createListOfMonkeynastixTypes, findCompanyCurrentTerm, sortCompanyTerms } from '@utils/utils/util'
import { CompanyType } from '@utils/enums/enums'
import { LocalStorageCompany, Term } from '@utils/interfaces/interfaces'
import FranchiseeActions from '@actions/CRUDActions/FranchiseeActions/franchiseeActions'
import { isMidTermBreak } from '@components/tables/TimeTable'


interface DashboardNavProps {
    edit_dashboard_open: boolean
    setEditDashboardOpen: React.Dispatch<React.SetStateAction<boolean>>
    handleSaveChanges: () => void
}

const CompanyInfo = () => {
    const [connected_company_to_school, setConnectedCompanyToSchool] = useState<LocalStorageCompany | null>(null)
    const { t } = useTranslation('dashboard')

    const connected_company = getLocalStorageCompany()

    useEffect(() => {
        const connected_company = getLocalStorageCompany()

        const fetchData = async () => {
            const franchise_actions = new FranchiseeActions()
            const connected_company_to_school: LocalStorageCompany = await franchise_actions.getById(connected_company.connected_company as string)
            setConnectedCompanyToSchool(connected_company_to_school)
        }
        if (connected_company.connected_company)
            fetchData()
    }, [])

    if (companyIsInGroup(connected_company, CompanyType.SCHOOL) && connected_company.connected_company) {

        return (
            <>
            <Box sx={{display: 'flex', gap: '0.5rem', alignItems: 'center'}}>
                <Typography> {connected_company.tenant_name}</Typography>
                <div>-</div>
                <Typography sx={{fontSize: '0.8rem', fontWeight: '300'}}> {connected_company.groups?.length ? t(connected_company.groups[0].name) : ""} </Typography>
            </Box>
            {connected_company_to_school &&
                <Box sx={{display: 'flex', gap: '0.5rem', alignItems: 'center'}}>
                    <Typography> {connected_company_to_school.tenant_name}</Typography>
                    <div>-</div>
                    <Typography sx={{fontSize: '0.8rem', fontWeight: '300'}}> {t('connected_franchise')} </Typography>
                </Box>
            }
            </>
        )
    }
    else    
        return (
            <>
            <Typography> {connected_company.tenant_name}</Typography>
            <Typography sx={{fontSize: '0.8rem', fontWeight: '300'}}> {connected_company.groups?.length ? t(connected_company.groups[0].name) : ""} </Typography>
            </>
        )
}


const DashboardNav = (props: DashboardNavProps) => {
    const {edit_dashboard_open, setEditDashboardOpen, handleSaveChanges} = props

    const is_small_screen = useMediaQuery('(max-width: 850px)')
    const { t } = useTranslation('dashboard')

    const handleClick = () => {
        setEditDashboardOpen(true)
    }

    const onCancelClick = () => {
        setEditDashboardOpen(false)
    }

    const ShowMonkeynastixTypes = () => {
        const connected_company = getLocalStorageCompany()
        const true_props = createListOfMonkeynastixTypes(connected_company)

        const rendered_props = true_props.join(" • ")

        const styles = {margin: 'auto', fontSize:'0.85rem', fontWeight: 100}

        return (
            <Box sx={is_small_screen ? {order: 3, ...styles, marginRight: 0} : {order: 2, ...styles}}>
                {rendered_props}
            </Box>
        )
    }

    const ShowCurrentTerm = () => {
        const sorted_terms = sortCompanyTerms()
        if (!sorted_terms)
            return <></>
        
        return (
            <Box sx={{marginRight: 'auto', fontSize:'0.85rem', fontWeight: 100}}>
                {Object.keys(sorted_terms).map((key, index) => {
                    const result = findCompanyCurrentTerm(sorted_terms[key])
                    let term: Term | null = null
                    let is_mid_term_break: boolean = false
                    if (result) {
                        const connected_company = getLocalStorageCompany();
                        is_mid_term_break = isMidTermBreak(new Date(), connected_company.mid_term_breaks);
                        ({ term, index } = result)
                    }
                    return (
                        <Box key={`${index}-${key}`} sx={{marginRight: 'auto', fontSize:'0.85rem', fontWeight: 100}}>
                            <Typography> 
                                <span style={{fontWeight: 400, marginRight: '0.8rem'}}>{t(key)}:</span>  
                                {result ?
                                    (term?.country_term_name === 'Private' && is_mid_term_break) ?
                                        (<><span style={{fontSize: '0.8rem', color: 'green'}}>{t('mid_term_break')}</span></>)
                                        :
                                        (<><span style={{fontSize: '0.8rem'}}>{t('term')} {index + 1}</span></>)
                                    : (<><span style={{fontSize: '0.8rem', color: 'green' }}>{t('term_break')}</span></>)} 
                            </Typography>
                        </Box>
                    )
                })
                }
            </Box>
        )
    }

    return (
        <>
        <Box sx={{paddingBottom: '1rem', display: 'flex', alignItems: 'center', flexWrap: 'wrap', rowGap: '1rem'}}>
            <Box textAlign={'right'} sx={is_small_screen ? {order: 0, flex: '0 0 50%', textAlign: 'left'} : {marginRight: 'auto'}}>
                <CompanyInfo/>
            </Box>
            <Box sx={is_small_screen ? {order: 2} : {order: 1}}>
                <ShowCurrentTerm/>
            </Box>
            <ShowMonkeynastixTypes/>
            <Box sx={is_small_screen ? {order: 1, flex: '0 0 50%', display: 'flex', justifyContent: 'end'} : {order: 3}}>
                {edit_dashboard_open ?
                    <>
                    <Button variant='outlined' onClick={onCancelClick} sx={{marginRight: '1rem'}}> {t('cancel')} </Button>
                    <Button variant='contained' onClick={handleSaveChanges}> {t('save_changes')} </Button>
                    </>
                    : 
                    <Button variant='contained' onClick={handleClick}> {t('edit_dashboard')} </Button>
                }
            </Box>
        </Box>
        <Divider/>
        </>
    )
}

export default DashboardNav