import GenericFetchWrapper from "@adapters/api/fetchWrappers/genericFetchWrapper"
import { BaseHandler } from "@adapters/handlers/Handlers/handlers"
import { BULK_CHILDREN_ENDPOINT, BULK_CHILDREN_SEND_CERTIFICATES_ENDPOINT, IMPORT_CHILDREN_TO_XERO_ENDPOINT, TRANSFER_CHILDREN_TO_OTHER_FRANCHISE_ENDPOINT } from "@adapters/routes/endpoints"


class BulkChildHandler extends BaseHandler {
    endpoint: string
    send_certificates_endpoint: string
    import_children_to_xero_endpoint: string
    transfer_children_to_other_franchise_endpoint: string
    fetch_wrapper: GenericFetchWrapper

    constructor () {
        super()
        this.fetch_wrapper = new GenericFetchWrapper()
        this.endpoint = BULK_CHILDREN_ENDPOINT
        this.send_certificates_endpoint = BULK_CHILDREN_SEND_CERTIFICATES_ENDPOINT
        this.import_children_to_xero_endpoint = IMPORT_CHILDREN_TO_XERO_ENDPOINT
        this.transfer_children_to_other_franchise_endpoint = TRANSFER_CHILDREN_TO_OTHER_FRANCHISE_ENDPOINT

    }

    updateToAuthorised (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.endpoint)
        return this.fetch_wrapper.putAll(parsed_endpoint, data)
    }

    updateToSentCertificates (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.send_certificates_endpoint)
        return this.fetch_wrapper.putAll(parsed_endpoint, data)
    }

    importChildrenToXero (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.import_children_to_xero_endpoint)
        return this.fetch_wrapper.putAll(parsed_endpoint, data)
    }

    transferChildrenToOtherFranchise (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.transfer_children_to_other_franchise_endpoint)
        return this.fetch_wrapper.putAll(parsed_endpoint, data)
    }
}

export default BulkChildHandler