import { useEffect, useContext, useState, useCallback } from "react"
import { useTranslation } from "react-i18next"

import { Typography } from "@mui/material"
import Button from "@mui/material/Button"
import { Box } from "@mui/system"
import RefreshIcon from '@mui/icons-material/Refresh'

import { getLocalStorageCompany, updateLocalStorageCompany } from "@utils/localStorage/company"
import CompanyActions from "@actions/CompanyActions/companyActions"
import { CompanySetupStepContext } from "@contexts/CompanySetupStepContext"
import { SetupBox, SetupHeadingTypography } from "@styles/Setup"
import { getLocalStorageUser } from "@utils/localStorage/user"
import { isInGroup } from "@utils/utils/util"
import { UserType } from "@utils/enums/enums"
import { MessageContext } from "@contexts/MessageContext"

import { AuthorizedStatus } from "./enum"


const GetCompanyAuthorized = () => {
    const user = getLocalStorageUser()
	const company = getLocalStorageCompany()
    const is_school_operator = isInGroup(user, company, UserType.SCHOOL_OPERATOR)

    const {setCompanySetupStep} = useContext(CompanySetupStepContext)
    const {setMessage} = useContext(MessageContext)

    const [auth_status, setAuthStatus] = useState<Record<string, AuthorizedStatus>>()

    const { t } = useTranslation('get_company_authorized')

    const checkAuthStatus = useCallback(async () => {
        const company_actions = new CompanyActions()
        return company_actions.getAuthorizedSetup()
        .then(data => {
            setAuthStatus(data)
            if (data.master_admin_authorized === AuthorizedStatus.AUTHORIZED && data.monkeynastix_authorized === AuthorizedStatus.AUTHORIZED && data.connected_company_authorized === AuthorizedStatus.AUTHORIZED) {
                updateLocalStorageCompany('setup_step', -1)
                setCompanySetupStep(-1)
                setMessage(props => ({...props, success: is_school_operator ? t('your_school_has_been_successfully_authorised'): t('your_franchise_has_been_successfully_authorised')}))
                return true
            }
            else
                return false
        })
    }, [is_school_operator, setMessage, setCompanySetupStep, t])
    

    useEffect(() => {
        checkAuthStatus()
    }, [checkAuthStatus])

    const checkAuthStatusWithMessage = () => {
        checkAuthStatus()
        .then((is_authorised: boolean) => {
            if (!is_authorised) 
                setMessage(props => ({...props, info:  is_school_operator ? t('your_school_is_still_pending_authorisation'): t('your_franchise_is_still_pending_authorisation')}))
        })
    }

    return (
        <SetupBox>
            <br/>
            <SetupHeadingTypography> {is_school_operator ? t('get_school_authorized') : t('get_company_authorized')} </SetupHeadingTypography>
            <Typography variant="body1" align="center" mt={'1rem'}> {t('you_are_pending_authorization_from')} 
                {
                    auth_status?.master_admin_authorized !== 1 ? <span> {t('your_master_franchise_operator')}.</span>
                    : auth_status?.connected_company_authorized !== 1 ? <span> {t('your_connected_franchise')}. </span>
                    : <span> {t('the_monkeynastix_admin')}.</span>
                }
                <br/>
            {t('they_have_been_notified_and_should_react_shortly')} 
            </Typography>
            <br/>
            {/* <Typography variant="caption" marginTop={'1rem'}> 
                {is_school_operator ? t('refresh_the_page_once_this_school_has_been_authorised') : t('refresh_the_page_once_this_company_has_been_authorised')}
            </Typography> */}
            <Button onClick={checkAuthStatusWithMessage} variant="contained" sx={{gap:'1rem', marginTop: '1rem'}}>
                {t('check_auth_status')}
                <RefreshIcon/>
            </Button>
            <Box sx={{flexGrow: 0.9}}></Box>
        </SetupBox>

    )
}

export default GetCompanyAuthorized