import { useEffect, useContext, useState } from "react"
import { useNavigate } from 'react-router-dom'

import Box from "@mui/material/Box"

import { AuthTypography } from "@components/authentication/util/StyledComponents"
import AuthFooter from "@components/authentication/util/AuthFooter"
import BackToLogin from "@pages/Setup/BackToLogin"
import { AuthenticationAction } from "@actions/Actions/actions"
import { SINGLE_SIGN_IN_AUTHENTICATION_ENDPOINT } from "@adapters/routes/endpoints"
import { MessageContext } from "@contexts/MessageContext"
import { IsLoggedInContext } from "@contexts/IsLoggedInContext"
import { SetupStepContext } from "@contexts/SetupStepContext"


interface SingleSignInSuccessResponse {
    uuid: string
    token: string
}

const SingleSignIn = () => {
	const navigate = useNavigate()

    const queryParams = new URLSearchParams(window.location.search)
    const uidb64 = queryParams.get('uidb64')
    const token = queryParams.get('token')

	const {setIsLoggedIn} = useContext(IsLoggedInContext)
	const {setMessage} = useContext(MessageContext)
	const {setSetupStep} = useContext(SetupStepContext)

    const [single_sign_in_link_invalid, setSingleSignInLinkInvalid] = useState(false)

    useEffect(() => {
        const authentication_actions = new AuthenticationAction()
        authentication_actions.signleSignIn(SINGLE_SIGN_IN_AUTHENTICATION_ENDPOINT, {uidb64: uidb64, token: token})
        .then((data: SingleSignInSuccessResponse) => {
            navigate(`/account/reset-password/${data.uuid}/${data.token}`)
        })
        .catch(error => {
            setMessage(props => ({...props, error: error.message}))
            setSingleSignInLinkInvalid(true)
        })
    }, [navigate, setMessage, setIsLoggedIn, setSetupStep, uidb64, token])

    return (
        <>
        {single_sign_in_link_invalid &&
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection:'column'}} height={'100vh'}>
                <AuthTypography variant="h1" mt={'auto'} gutterBottom> Single sign in link is no longer valid </AuthTypography>
                <BackToLogin/>
                <AuthFooter/>
            </Box>
        }
        </>
    )
}

export default SingleSignIn