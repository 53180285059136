import { useTranslation } from 'react-i18next'

import Pages from '@pages/Page'
import { PageHeadingTypography } from '@styles/Typography'
import { UserPermissions } from '@utils/enums/permissions'
import PaymentTabs from './PaymentTabs'


const PaymentsPage = () => {

	const { t } = useTranslation('payments')

	return (
		<Pages page_title="Payments" permission={UserPermissions.VIEW_CHILDREN}>
			<PageHeadingTypography> {t('payments')} </PageHeadingTypography>
            <PaymentTabs/>
		</Pages>
	)
}

export default PaymentsPage
