export const registerServiceWorker = () => {
    if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
            navigator.serviceWorker
            .register('service-worker.js')
            .then(registration => {
                console.log('Service Worker registered: ', registration)
            })
            .catch(error => {
                console.error('Service Worker registration failed: ', error)
            })
        })
    }
}

export function unregister() {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(registration => {
            registration.unregister()
        })
    }
}