import Typography from '@mui/material/Typography'

import Pages from '@pages/Page'
import { UserPermissions } from '@utils/enums/permissions'
import { useTranslation } from 'react-i18next'

const OrderPage = () => {

	const { t } = useTranslation('order')

	return (
		<Pages page_title="Orders" permission={UserPermissions.VIEW_ORDERS}>
        	<Typography variant="h1"> {t('order')} </Typography>
		</Pages>
	)
}

export default OrderPage
