import {useContext} from 'react'
import { useTranslation } from 'react-i18next'

import GenericFetchWrapper from '@adapters/api/fetchWrappers/genericFetchWrapper'
import { JWT_PING_ENDPOINT, USERS_ENDPOINT } from '@adapters/routes/endpoints'
import { MessageContext } from '@contexts/MessageContext'
import { ContentType } from '@adapters/api/enums/enum'


interface EditProfileProps {
    setProfilePicture: (value: string) => void 
}

const EditProfile = (props: EditProfileProps) => {
    const {setProfilePicture} = props

    const {setMessage} = useContext(MessageContext)
	const { t } = useTranslation('settings')

    const handleProfilePictureChange = async (event: any) => {
        // due to unique file upload (content-type), making sure access token is valid/refreshed in order to prevent data not being sent in post request
        // because of chaning content-type to 'application/json' during refreshing
        const fetch_wrapper = new GenericFetchWrapper()
        await fetch_wrapper.get(JWT_PING_ENDPOINT)

        const file = event.target.files[0]

        // TODO: Perform any necessary validations or file processing here. For example, you can check the file size, type, etc.
        const formData = new FormData()
        formData.append('profile_picture', file)

        const form_fetch_wrapper = new GenericFetchWrapper(ContentType.FORM_DATA)
        form_fetch_wrapper.post(USERS_ENDPOINT + '/profile-picture/', formData)
        .then(() => {
            setProfilePicture( URL.createObjectURL(file))
        })
        .catch(error => {
            setMessage(props => ({...props, error: error.message}))
        })
    }

    return (
        <>
        <label htmlFor="profilePictureInput" style={{ textDecoration: 'none', cursor: 'pointer' }}>
            <span
                style={{
                    textDecoration: 'underline',
                    transition: 'text-decoration 0.3s',
                    fontSize: '0.7rem'
                }}
            >
                {t('edit_profile_picture')}
            </span>
            <input
                id="profilePictureInput"
                type="file"
                accept="image/*"
                onChange={handleProfilePictureChange}
                style={{ display: 'none' }}
            />
        </label>
        </>
    )
}

export default EditProfile