import { createContext } from 'react'
import { AmountOfFinancesData } from './interfaces'
import { amountOfFinancesDataObject } from './objects'


export type SetAmountOfFinancesData = React.Dispatch<React.SetStateAction<AmountOfFinancesData>>

interface IAmountOfFinancesDataContext {
    amount_of_finances_data: AmountOfFinancesData
    setAmountOfFinancesData: SetAmountOfFinancesData
}

export const AmountOfFinancesDataContext = createContext<IAmountOfFinancesDataContext>({
    amount_of_finances_data: amountOfFinancesDataObject,
    setAmountOfFinancesData: () => {}
})