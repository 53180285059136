import { useNavigate } from 'react-router-dom'

import Monkey404 from '@images/inastix/404.png'

import { useMediaQuery, Theme } from '@mui/material'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'


const Page404 = () => {
    const is_small_screen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

    const navigate = useNavigate()

    const handleClick = (endpoint: string) => {
        navigate(endpoint)
    }

    return (
        <Box display={'flex'} alignItems={'center'} height={'100vh'} flexWrap='wrap' justifyContent='space-between'>
            <Box sx={{flexGrow: 1}}>
                <img src={Monkey404} alt="404 Monkey" width="300px" style={{margin: 'auto', display: 'block'}}/>
            </Box>
            <Box padding={5} paddingTop={is_small_screen ? 0 : 5} sx={{margin: 'auto', flex: '1 0 60%', maxWidth:'65ch'}}>
                <Typography variant={'h1'} fontWeight={800} color={'darkslategray'}> Page not found </Typography>
                <br/>
                <Typography variant={'h1'} fontWeight={700} color={'darkslategray'} > Sorry, we can't seem to find the page you're looking for </Typography>
                <br/>
                <br/>
                <Typography color={'darkslategray'}> Go back to your dashboard or send us an email if this is an issue. </Typography>
                <br/>
                <Box display={'flex'} gap={2} justifyContent={'end'} sx={is_small_screen ? {justifyContent: 'center'} : {}}>
                    <Button variant='contained' onClick={() => handleClick('/dashboard')}> go to dashboard </Button>
                    <Button variant='contained' onClick={() => handleClick('/contact')}> contact us </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default Page404