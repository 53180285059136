import {useState} from 'react'

import { Dayjs } from 'dayjs'

import { useMediaQuery, Theme } from '@mui/material'
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'


interface DatePickerNastixProps {
    initial_date?: Dayjs
    max_date?: Dayjs
    min_date?: Dayjs
    label: string
    helper_text?: string
    name: string
    use_mobile?: boolean
    shouldDisableDate?: (day: Dayjs) => boolean
    onDateChange?: (newDate: Dayjs | null) => void
}

export const responsiveDatePicker = (theme_or_use_mobile: boolean) => {
    return theme_or_use_mobile ? MobileDatePicker : DesktopDatePicker
}

const DatePickerNastix = (props: DatePickerNastixProps) => {
    const {initial_date, max_date, min_date, label, helper_text, name, use_mobile=false, shouldDisableDate, onDateChange } = props

    const [date, setDate] = useState<Dayjs | null>(initial_date ? initial_date : null)
    const theme = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'))
    const theme_or_use_mobile = theme || use_mobile

	const handleChange = (new_date: Dayjs | null) => {
        setDate(new_date)
        if (onDateChange) {
            onDateChange(new_date)
        }
    }

    const getDateString = () => {
        if (date)
            return date.format('YYYY-MM-DD')
        return ''
    }

    const ResponsiveDatePicker = responsiveDatePicker(theme_or_use_mobile)

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer 
            components={[          
                'DatePicker',
                'MobileDatePicker',
                'DesktopDatePicker',
                'StaticDatePicker'
            ]}>
            <ResponsiveDatePicker 
				label={label} 
				value={date}
				onChange={handleChange}
                maxDate={max_date}
                minDate={min_date}
                slotProps={{
                    textField: {
                        helperText: helper_text,
                    },
                }}
                format="DD/MM/YYYY"
                sx={{ minWidth: 100 }}
                shouldDisableDate={shouldDisableDate}
			/>
            {date && (
                // we use a hidden input so we can specify a name prop for the time picker field so it can be inluded in a form post submission
                <input 
                    type="hidden"
                    name={name} 
                    value={getDateString()}
                    required
                    style={{ minWidth: 100 }}
                />
            )}
        </DemoContainer>
        </LocalizationProvider>
    )
}

export default DatePickerNastix