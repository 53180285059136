import { ContentType } from "@adapters/api/enums/enum"
import GenericFetchWrapper from "@adapters/api/fetchWrappers/genericFetchWrapper"
import { BaseHandler } from "@adapters/handlers/Handlers/handlers"
import { INSTRUCTORS_ENDPOINT } from "@adapters/routes/endpoints"


class InstructorUserPermissionsHandler extends BaseHandler {
    endpoint: string
    instructor_files_endpoint: string
    fetch_wrapper: GenericFetchWrapper
    form_data_fetch_wrapper: GenericFetchWrapper

    constructor () {
        super()
        this.endpoint = INSTRUCTORS_ENDPOINT + '/instructor-user-permissions'
        this.instructor_files_endpoint = INSTRUCTORS_ENDPOINT + '/instructor-files'
        this.fetch_wrapper = new GenericFetchWrapper()
        this.form_data_fetch_wrapper = new GenericFetchWrapper(ContentType.FORM_DATA)
    }

    updateInstructorUserPermissions (data: object): Promise<any> {
        return this.fetch_wrapper.post(this.endpoint, data)
    }

    updateInstructorFiles (data: object, id: string): Promise<any> {
        return this.form_data_fetch_wrapper.put(this.instructor_files_endpoint, data, id)
    }
}

export default InstructorUserPermissionsHandler