import {useEffect, useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import IconButton from '@mui/material/IconButton'
import Divider from '@mui/material/Divider'

import TenantActions from '@actions/TenantActions/tenantActions'
import {ReactComponent as DisconnectXero} from '@images/xero/connect/disconnect_blue.svg'
import { MessageContext } from '@contexts/MessageContext'
import { logout } from '@adapters/helpers/logout'
import { handleCompanyLocalStorageInit } from '@components/authentication/helper/helper'
import { getLocalStorageUser, updateLocalStorageUser } from '@utils/localStorage/user'
import { SettingsHeadingTypography } from '@styles/Typography'
import XeroAccountCodesActions from '@actions/XeroActions/xeroAccountCodesActions'
import UserActions from '@actions/CRUDActions/UserActions/userActions'
import { LocalStorageCompany, UserGroup } from '@utils/interfaces/interfaces'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import FranchiseeActions from '@actions/CRUDActions/FranchiseeActions/franchiseeActions'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { IsLoggedInContext } from '@contexts/IsLoggedInContext'
import { getUserGroup } from '@utils/utils/util'
import { LocalstorageUserExistsContext } from '@contexts/LocalstorageUserExistsContext'
import { LocalstorageCompanyExistsContext } from '@contexts/LocalstorageCompanyExistsContext'
import { CompanyMap } from '@utils/maps/maps'
import { CompanyTypeExcludeDefault } from '@utils/enums/enums'
import XeroSignIn from '@components/authentication/XeroSignIn'

import log from 'loglevel'

import Settings from '../../Settings'
import SettingsSubHeading from '../../Settings/SettingsSubHeading'
import XeroAccountCodes from './XeroAccountCodes'
import { XeroAccountCodesStructProps } from './interfaces'
import XeroAccountCodesRoyaltiesActions from '@actions/XeroActions/xeroAccountCodesRoyaltiesActions'



interface XeroTenant {
    id: string
    tenantId: string
    tenantName: string
}

const XeroManager = () => {
    const navigate = useNavigate()
    
    const {setMessage} = useContext(MessageContext)
    const {change_company_rerender, setChangeCompanyRerender} = useContext(ChangeCompanyRerenderContext)
    const {setIsLoggedIn} = useContext(IsLoggedInContext)

    const [tenants, setTenants] = useState<XeroTenant[]>([])
    const [initial_xero_account_codes, setInitialXeroAccountCodes] = useState<XeroAccountCodesStructProps | null>(null)
    const [xero_account_codes, setXeroAccountCodes] = useState<XeroAccountCodesStructProps | null>(null)
    const [is_xero_company, setIsXeroCompany] = useState(false)
    const [company_group_name, setCompanyGroupName] = useState<string>("")
    
    const {setLocalstorageUserExists} = useContext(LocalstorageUserExistsContext)
    const {setLocalstorageCompanyExists} = useContext(LocalstorageCompanyExistsContext)

    const { t } = useTranslation('xero_manager')
    
    const localstorage_signed_in_with_xero = localStorage.getItem('signed_in_with_xero')
    const signed_in_with_xero = localstorage_signed_in_with_xero ? JSON.parse(localstorage_signed_in_with_xero) : false

    useEffect(() => {
        const tenant_actions = new TenantActions()
        tenant_actions.get()
        .then(tenants => {
            setTenants(tenants)
        })
        .catch(error => {
            setMessage(props => ({...props, info: error.message}))
        })
    }, [setMessage])

    useEffect(() => {
        const company = getLocalStorageCompany()
        if (company.is_xero_company)
            setIsXeroCompany(true)
        else {
            setIsXeroCompany(false)
            return
        }

        const xero_account_codes_actions = new XeroAccountCodesActions()
        const accounts_codes_royalties_actions = new XeroAccountCodesRoyaltiesActions()
        Promise.all([xero_account_codes_actions.get(), accounts_codes_royalties_actions.get()])
        .then(([sales_account_codes, royalty_account_codes]) => {
            console.log(sales_account_codes)
            console.log(royalty_account_codes)

            const combined_account_codes = {...sales_account_codes, ...royalty_account_codes}
            setInitialXeroAccountCodes(combined_account_codes)
            setXeroAccountCodes(combined_account_codes)
        })
        .catch(error => {
            setMessage(props => ({...props, info: error.message}))
        })
    }, [change_company_rerender, setMessage])

    useEffect(() => {
        const company = getLocalStorageCompany()
        const company_group = company.groups[0].name as CompanyTypeExcludeDefault
        const company_group_name = CompanyMap[company_group]
        setCompanyGroupName(company_group_name)
    }, [])

    const handleDisconnectClick = (connection_id: string, tenant_id: string) => {
        const user = getLocalStorageUser()

        const tenant_actions = new TenantActions()
        tenant_actions.disconnect(connection_id + ":" + tenant_id)
        .then(() => {
            // if tenants.length is only 1 it means the user has no other available tenants to connect and we should log them out
            if (tenants.length === 1)
                logout(navigate, setIsLoggedIn, setLocalstorageUserExists, setLocalstorageCompanyExists)
            else {
                const new_tenants = tenants.filter(tenant => tenant.id !== connection_id)
                setTenants(new_tenants)

                if (tenant_id === user.company_uuid) {
                    const franchisee_actions = new FranchiseeActions()
                    franchisee_actions.getById(new_tenants[0].tenantId)  // can just get the first tenant array to connect the user to a new tenant now that the one they were connected to got removed
                    .then((tenant: LocalStorageCompany) => {
                        const user_actions = new UserActions()
                        user_actions.update({company_uuid: tenant.company_uuid}, user.id.toString())
                        .then(() => {
                            updateLocalStorageUser('company_uuid', tenant.company_uuid)
                        })
                        const user_group: UserGroup | undefined = getUserGroup(user, tenant)
                        if (!user_group) {
                            const message = `${t('no_user_group_could_be_found_between_user')}, ${user.email} ${t('and_tenant')}, ${tenant.tenant_name}`
                            log.error(message)
                            throw new Error(message)
                        }

                        updateLocalStorageUser('connected_group', user_group.name)
                        handleCompanyLocalStorageInit(tenant)
                        setChangeCompanyRerender(props => !props)
                    })
                    .catch(error => {
                        log.error(error)
                    })
                }
            }
            setMessage(props => ({...props, success: t('connection_disconnected')}))
        })
        .catch(() => {
            setMessage(props => ({...props, error: t('connection_could_not_be_disconnected')}))
        })
    }

    const xeroAccountCodesCallback = (xero_account_codes: XeroAccountCodesStructProps) => {
        setXeroAccountCodes(xero_account_codes)
    }

    const handleAccountCodesSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault()

        if (!xero_account_codes) {
            return
        }

        const {sales_account_code, sales_account_uuid, ...royalty_account_codes } = xero_account_codes

        const sales_account_codes = {
            sales_account_code: sales_account_code,
            sales_account_uuid: sales_account_uuid,
        }

        const xero_account_codes_action = new XeroAccountCodesActions()
        const account_codes_royalties_action = new XeroAccountCodesRoyaltiesActions()
        
        Promise.all([account_codes_royalties_action.create(royalty_account_codes), xero_account_codes_action.create(sales_account_codes)])
        .then(() => {
            setMessage(props => ({...props, success: t('account_codes_updated')}))
        })
        .catch(error => {
            setMessage(props => ({...props, error: error.message}))
            
        })
    }

    return (
        <Settings>
            <Box sx={{display: 'flex', tems: 'center', justifyContent: 'space-between', gap: '1rem', flexWrap: 'wrap'}}>
                <SettingsHeadingTypography>{t('xero_management')}  </SettingsHeadingTypography>
                {(!signed_in_with_xero) &&
                    <XeroSignIn 
                        label_text={t('connect_with_xero') as string} 
                        styles={{
                            color: 'darkslategray',
                            margin: 'auto'
                        }}
                    />
                }
            </Box>
        <form onSubmit={handleAccountCodesSubmit}>
            {is_xero_company ? 
                <>
                <SettingsSubHeading button_text={signed_in_with_xero ? 'update codes': ''}> {t('xero_account_codes')} </SettingsSubHeading>
                {signed_in_with_xero ?
                    <>
                    {initial_xero_account_codes ?
                        <XeroAccountCodes initial_account_codes={initial_xero_account_codes} accountCodesCallback={xeroAccountCodesCallback}/>
                        : <XeroAccountCodes accountCodesCallback={xeroAccountCodesCallback}/>  
                    }
                    </>
                    : 
                    <Typography sx={{textAlign: 'center', margin: '2rem', fontSize: '0.8rem'}}> Sign in with Xero to view your Xero account codes </Typography>
                }
                </>
                : 
                <>
                <SettingsSubHeading> {t('xero_account_codes')} </SettingsSubHeading>
                <br/>
                <Typography textAlign='center' color='darkslategray'> {t('this')} {t(company_group_name)} {t('is_not_a_xero_organisation')} </Typography>
                </>
            }
        </form>
        <SettingsSubHeading> {t('your_xero_connections')} </SettingsSubHeading>
        {(tenants && tenants.length) ? tenants.map(tenant => (
            <div key={tenant.tenantId}>
            <Box  sx={{display: 'flex', justifyContent: 'space-between', marginTop: '1.5rem', alignItems: 'center', border: '1px solid lightgrey', padding: '0.5rem 1rem'}}>
                <Typography sx={{fontWeight: '100', fontSize: '1.15rem'}}> {tenant.tenantName} </Typography>
                <IconButton onClick={() => handleDisconnectClick(tenant.id, tenant.tenantId)}>
                    <DisconnectXero/>
                </IconButton>
            </Box>
            {(tenants.length > 1) ? <><br/><Divider sx={{width: '60%', margin: 'auto'}}></Divider></> : <></>}
            </div>
            ))
            : 
            <Typography sx={{textAlign: 'center', margin: '2rem', fontSize: '0.8rem'}}> Sign in with Xero to view your Xero connections </Typography>
        }
        </Settings>
    )
}

export default XeroManager