import {useState, useContext, useEffect, useCallback} from 'react'
import { useTranslation } from 'react-i18next'

import useMediaQuery from '@mui/material/useMediaQuery'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import { Divider, Typography, Theme } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'

import Form from "@components/forms/Form"
import UserActions from '@actions/CRUDActions/UserActions/userActions'
import { getLocalStorageUser, updateLocalStorageUser } from '@utils/localStorage/user'
import { SetupStepContext } from '@contexts/SetupStepContext'
import { LocalStorageCompany, LocalStorageUser } from '@utils/interfaces/interfaces'
import XeroSignIn from '@components/authentication/XeroSignIn'
import { checkUserConnectedGroup, isInGroup } from '@utils/utils/util'
import { UserType } from '@utils/enums/enums'
import CompanyActions from '@actions/CompanyActions/companyActions'
import { handleCompanyLocalStorageInit } from '@components/authentication/helper/helper'
import { getLocalStorageCompany } from '@utils/localStorage/company'

import CreateCompanyDialogHandler from './CreateCompanyDialogHandler'
import SelectAnExistingCompany from './SelectAnExistingCompany'


export interface CompanyLabel {
    label: string
    company_uuid: string
}

export type SetCompanies = React.Dispatch<React.SetStateAction<LocalStorageCompany[]>>
export type SetCompaniesCompanyLabel = React.Dispatch<React.SetStateAction<CompanyLabel[]>>


const SelectCompany = () => {
    const is_small_screen = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

    const user = getLocalStorageUser()
	const conected_company = getLocalStorageCompany()

    const {setSetupStep} = useContext(SetupStepContext)
    const { t } = useTranslation('set_up')

    const [open, setOpen] = useState(false)
    
    let is_school_operator = false
    let is_high_auth_user = false
    if (conected_company.company_uuid) {
        is_school_operator = isInGroup(user, conected_company, UserType.SCHOOL_OPERATOR)
        is_high_auth_user = isInGroup(user, conected_company, [UserType.SCHOOL_OPERATOR, UserType.FRANCHISEE_OPERATOR, UserType.MASTER_FRANCHISE_OPERATOR])
    } else {
        is_school_operator = checkUserConnectedGroup(user, UserType.SCHOOL_OPERATOR)
        if (checkUserConnectedGroup(user, UserType.SCHOOL_OPERATOR) || checkUserConnectedGroup(user, UserType.FRANCHISEE_OPERATOR) || checkUserConnectedGroup(user, UserType.MASTER_FRANCHISE_OPERATOR)) {
            is_high_auth_user = true
        }
    }

    const connect_with_xero_label = t('connect_with_xero')

    const isHighAuthUser = () => is_high_auth_user


    const [company, setCompany] = useState<CompanyLabel | null>(null)
    const [has_created_company_manually, setHasCreatedCompanyMaually] = useState(false)

    const is_signed_in_with_xero = JSON.parse(localStorage.getItem('signed_in_with_xero') || 'null')

    const onCreateCompanyCallback = (selected_company: CompanyLabel) => {
        setCompany(selected_company)
        setHasCreatedCompanyMaually(true)
    }

    const selectedCompanyCallback = (selected_company: CompanyLabel | null) => {
        setCompany(selected_company)
    }
      
    const main_box_styles = {display: 'flex', justifyContent: 'space-evenly', textAlign: 'center'}

    const createFields = () => {
        return (
            <>
            <Box sx={is_small_screen ? {...main_box_styles, flexDirection: 'column', alignItems: 'center'} : main_box_styles}>
                {(isHighAuthUser() && !is_signed_in_with_xero) ? 
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '1.5rem', justifyContent: 'center'}}>
                        <CreateCompanyDialogHandler onCreateCompanyCallback={onCreateCompanyCallback}/>
                        <XeroSignIn styles={{color: 'gray'}} label_text={connect_with_xero_label}/>
                        {/* // TODO: in progress...
                        <Button onClick={handleUseDemoCompanyClick} variant='contained'> 
                            {is_school_operator ? t('use_a_demo_school') : t('use_a_demo_company')}
                        </Button> */}
                    </Box>
                    :
                    <></>
                }
                {isHighAuthUser() && !is_signed_in_with_xero && <Divider orientation="vertical" flexItem> {t('or')} </Divider>}
                <Box sx={{ width: 250 }}>
                    <Typography sx={{fontSize: '1.5rem', color: 'darkslategray'}} gutterBottom>
                        {is_school_operator ? t('choose_school') : t('choose_company') }
                    </Typography>
                    <Typography sx={{fontSize: '0.8rem', color: 'darkslategray'}}>
                        {t('choose_from_the_list_of_already_connected')}
                        {is_school_operator ? t('schools') : t('companies')}
                        <Tooltip title={t('demo_orgs_will_not_show_here')}>
                            <InfoIcon fontSize='small' sx={{marginLeft: '0.3rem', transform: 'translateY(5px)'}}/>
                        </Tooltip>
                    </Typography>
                    <br/>
                    {is_signed_in_with_xero && <><Typography fontSize={'0.8rem'} color={'darkcyan'}> {t('find_your_xero_franchise_below')} </Typography><br/></>}
                    <SelectAnExistingCompany selectedCompanyCallback={selectedCompanyCallback}/>
                    <Button variant='contained' sx={{marginTop: '1rem'}} type="submit"> {t('submit')} </Button>
                    {is_small_screen ?
                        <Tooltip sx={{textAlign:'center'}} title={t('if_franchise_is_not_listed')} open={open} onClick={() => setOpen(!open)} enterTouchDelay={0}>
                            <Typography marginTop={'1rem'} fontSize={'0.7rem'} sx={{ '&:hover': { textDecoration: 'underline' } }}>
                                {t('dont_see_your_franchise_here')}
                            </Typography>
                        </Tooltip>
                        :
                        <Tooltip sx={{textAlign:'center'}} title={t('if_franchise_is_not_listed')}>
                            <Typography marginTop={'1rem'} fontSize={'0.7rem'} sx={{ '&:hover': { textDecoration: 'underline' } }}>
                                {t('dont_see_your_franchise_here')}
                            </Typography>
                        </Tooltip>
                    }
                </Box>  
            </Box>
            </>
        )
    }

    // TODO: in progress...
    // const handleUseDemoCompanyClick = async (): Promise<any> => {
    //     // TODO: still working on this
    //     const company_actions = new CompanyActions()
    //     return company_actions.provisionDemoCompany()
    //     .then(() => {
    //         // eslint-disable-next-line
    //         const user = getLocalStorageUser()
    //         // updateLocalStorageUser('setup_step', user.setup_step + 1)
    //         // setSetupStep(prop => prop + 1)
    //     })
    // }

    const onSubmit = useCallback(async (): Promise<any> => {
        if (company?.company_uuid) {
            const user_action = new UserActions()
            return user_action.chooseCompanySetup({company_uuid: company.company_uuid})
            .then(async (user: LocalStorageUser) => {
                const company_actions = new CompanyActions()
                const connected_company: LocalStorageCompany = await company_actions.getConnectedCompany()
                handleCompanyLocalStorageInit(connected_company)

                for (const [key, value] of Object.entries(user)) {
                    updateLocalStorageUser(key, value)
                }
                setSetupStep(prop => prop + 1)		
            })
        }
	}, [company, setSetupStep])

    useEffect(() => {
        if (has_created_company_manually)
            onSubmit()
    }, [company, has_created_company_manually, onSubmit])

    return (
		<Form onSubmitCallback={onSubmit} createFields={createFields}/>
    )
}

export default SelectCompany