import { CRUDAction } from "@actions/Actions/actions"
import EvaluationHandler from "@adapters/handlers/CRUDHandlers/ClassHandlers/evaluationHandler"


class EvaluationActions extends CRUDAction {
    handler: EvaluationHandler

    constructor() {
        super()
        this.handler = new EvaluationHandler()
    }
}

export default EvaluationActions