import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Link from '@mui/material/Link'

import { TranslationFunction } from '@utils/types/types'
import { backend_url } from 'src/config'


interface INastixTableContainerProps {
    table_row_data: object
    transFunc1: TranslationFunction 
    transFunc2: TranslationFunction 
    caption?: string
}

const INastixTableContainer = (props: INastixTableContainerProps) => {
    const {table_row_data, transFunc1, transFunc2=transFunc1, caption} = props
    
    return (
        <TableContainer component={props => <div {...props} style={{ boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }} />}>
            <Table>
                {caption && <caption>{caption}</caption>}
                <TableHead>
                <TableRow>
                    <TableCell sx={{fontWeight:700}}>Field</TableCell>
                    <TableCell sx={{fontWeight:700}}>Value</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {Object.entries(table_row_data).map(([attribute, value]) => (
                    value && (
                        <TableRow key={attribute}>
                            <TableCell sx={{textTransform: 'capitalize', fontWeight:600}}>{transFunc1(attribute)}</TableCell>
                            {(value.endsWith('.pdf') || value.endsWith('.zip')) ?
                                <TableCell><Link href={backend_url + value.substring(1)} target="_blank"> {transFunc1('open_in_new_tab')} </Link></TableCell>
                                : <TableCell>{transFunc2(value)}</TableCell>
                            }
                        </TableRow>
                    )
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default INastixTableContainer