import { ChatMessage, UserSelectOption } from "./interfaces"


const initial_assitant_messages: ChatMessage[] = [
    {
        message: "Hi There! Welcome to iNastix",
        sent_from: 'assistant',
    },
    {
        message: "How can I assist you today?",
        sent_from: 'assistant',
    },
]

const user_select_options_array: UserSelectOption[] = [
    {
        id: 1,
        priority: 1,
        message: "Explain the child fee structure?",
        type: 'feature'
    },
    {
        id: 2,
        priority: 2,
        message: "How to view certificates sent?",
        type: 'feature'
    },
    {
        id: 3,
        priority: 3,
        message: "List new features.",
        type: 'feature'
    },
    {
        id: 4,
        priority: 4,
        message: "Explain how the Certificate Reports work?",
        type: 'feature'
    },
    {
        id: 5,
        priority: 5,
        message: "Where can I complete end-of-term Assessments?",
        type: 'feature'
    },
    {
        id: 6,
        priority: 6,
        message: "Where to look for a missing Child?",
        type: 'feature'
    },
    {
        id: 7,
        priority: 7,
        message: "Where can I change my Fees?",
        type: 'feature'
    },
]

export {
    initial_assitant_messages,
    user_select_options_array
}