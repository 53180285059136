import {useEffect, useState, useRef } from 'react'

import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Pages from '@pages/Page'
import { Typography } from '@mui/material'
import VisibilityIcon from '@mui/icons-material/Visibility'

import { PageHeadingTypography } from '@styles/Typography'
import MemberHandoutsActions from '@actions/MemberHandoutsActions/franchiseCostingActions'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { formatDate } from '@utils/utils/util'
import { parseNumberToMoneyValue, parseToMoneyValue } from '@utils/numbers/numbers'
import ChildActions from '@actions/CRUDActions/ChildActions/childActions'
import { SchoolType } from '@utils/enums/enums'
import PrintAsPDF from '@components/utils/Helpers/PrintAsPDF'
import SelectProgramType from '@components/selects/ProgramType'

import { memberHandoutsTableStyles } from './styles'


interface MemberHandoutItemPartial {
    Code: string
    Item: string
    Ratio: string
    Visible: boolean
    PurchaseDetails: string
    SalesDetails: string
    QuantityOnHand: string
}

export interface MemberHandoutCategoryResponse {
    id: number
    category_name: string
    category_type: string
    member_handouts_items: string[]
}

export interface MemberHandoutCategoryPartial {
    id: number
    category_name: string
    category_type: string
    member_handouts_items: MemberHandoutItemPartial[]
}

interface MemberHandoutItemFull {
    Code: string
    Item: string
    Ratio: string
    Visible: boolean
    PurchaseDetails: string
    SalesDetails: string
    QuantityOnHand: string
    CostPerChild: number
    QtyToOrder: number | string 
    TotalCostPerItem: number
    Percentage: number | string | null
    StockRemaining: number | string
}

interface MemberHandoutCategoryFull {
    id: number
    category_name: string
    category_type: string
    member_handouts_items: MemberHandoutItemFull[]
}


const currentDate = new Date()
const year = currentDate.getFullYear()

const MemberHandoutsPage = () => {
    const company = getLocalStorageCompany()

    const tableRef = useRef(null)

    const [handout_categories, setHandoutCategories] = useState<MemberHandoutCategoryFull[] | null>(null)
    const [num_of_children, setNumOfChildren] = useState<number | null>(null)
    const [program_type, setProgramType] = useState<SchoolType>(SchoolType.MONKEYNASTIX)
	const [hide_component, setHideComponent] = useState(false)

    useEffect(() => {
        async function getHandoutCategories() {
            const child_actions = new ChildActions()
            const num_of_children = await child_actions.get(undefined, undefined, {tenant__real_school__school_type:program_type, num_of_rows_only: true })
            .catch(error => {
                console.log(error)
                return
            })
            setNumOfChildren(num_of_children)
            
            const member_handouts_actions = new MemberHandoutsActions()
            member_handouts_actions.getMemberHandoutItems({program_type: program_type})
            .then((member_handout_categories_response: MemberHandoutCategoryResponse[]) => {
                const member_handout_categories_partial: MemberHandoutCategoryPartial[] = member_handout_categories_response.map(category_response => {
                    return {
                        ...category_response,
                        member_handouts_items: category_response.member_handouts_items.map(member_handouts_item => JSON.parse(member_handouts_item))
                    }
                })
    
                let total_qty_to_ratio = 0
                let total_cost_per_child = 0
                let total_cost = 0
                let total_percentage = 0
    
                let member_handout_categories_full: MemberHandoutCategoryFull[] = member_handout_categories_partial.map(handout_category_partial => {
                    return {
                        ...handout_category_partial,
                        member_handouts_items: handout_category_partial.member_handouts_items.map(member_handout_item => {
                            const qty_to_order = calcQtyToOrder(member_handout_item, num_of_children)
                            const cost_per_child = calcCostPerChild(member_handout_item)
                            const total_cost_per_item = calcTotalCostForItem(member_handout_item, qty_to_order)
    
                            const ratio = parseToMoneyValue(member_handout_item.Ratio) as number
                            total_qty_to_ratio = total_qty_to_ratio + ratio
                            total_cost_per_child = total_cost_per_child + cost_per_child
                            total_cost = total_cost + total_cost_per_item
    
                            return {
                                ...member_handout_item,
                                CostPerChild: cost_per_child,
                                QtyToOrder: qty_to_order,
                                TotalCostPerItem: total_cost_per_item,
                                Percentage: null,
                                StockRemaining: Math.max((parseToMoneyValue(member_handout_item.QuantityOnHand) as number) - qty_to_order, 0)
                            }
                        })
                    }
                })
    
                member_handout_categories_full = member_handout_categories_full.map(handout_category_partial => {
                    return {
                        ...handout_category_partial,
                        member_handouts_items: handout_category_partial.member_handouts_items.map(member_handout_item => {
                            const percentage = parseNumberToMoneyValue((member_handout_item.TotalCostPerItem / total_cost) * 100) as number
                            total_percentage = parseNumberToMoneyValue(total_percentage + percentage) as number
                            return {
                                ...member_handout_item,
                                Percentage: percentage
                            }
                        })
                    }
                })
    
                member_handout_categories_full.push({
                    id: 0,
                    category_name: "Total",
                    category_type: "Monkeynastix",
                    member_handouts_items: [{
                        Code: "",
                        Item: "",
                        Ratio: total_qty_to_ratio.toString(),
                        Visible: true,
                        PurchaseDetails: "",
                        SalesDetails: "",
                        QuantityOnHand: "",
                        CostPerChild: total_cost_per_child,
                        QtyToOrder: "",
                        TotalCostPerItem: total_cost,
                        Percentage: (99.98 <= total_percentage && total_percentage <= 100.02) ? "100" : (total_percentage === 0 ? "0" : "Inaccurate "),
                        StockRemaining: "",
                    }]
                })
    
                setHandoutCategories(member_handout_categories_full)
            })
            .catch((error) => {
                console.log(error)
            })
        }

        getHandoutCategories()
            
    }, [program_type])

    useEffect(() => {
        const member_handouts_actions = new MemberHandoutsActions()
        member_handouts_actions.syncMemberHandoutItemsToXero()
        .then(() => {

        })
        .catch((error) => {
            console.log(error)
        })
    })

    // const handlSyncItemsWithXeroClick = () => {
    //     const member_handouts_actions = new MemberHandoutsActions()
    //     member_handouts_actions.syncMemberHandoutItemsToXero()
    //     .then(() => {

    //     })
    //     .catch((error) => {
    //         console.log(error)
    //     })
    // }
    
    const calcCostPerChild = (handout_item: MemberHandoutItemPartial) => {
        return parseNumberToMoneyValue(parseFloat(handout_item.Ratio) * parseFloat(handout_item.PurchaseDetails)) as number
    }

    const calcQtyToOrder = (handout_item: MemberHandoutItemPartial, num_of_children: number) => {
        const qty_to_order = parseNumberToMoneyValue(parseFloat(handout_item.Ratio) * num_of_children) as number
        return qty_to_order
    }

    const calcTotalCostForItem = (handout_item: MemberHandoutItemPartial, qty_to_order: number) => {
        const total_cost_for_item = parseNumberToMoneyValue(parseFloat(handout_item.PurchaseDetails) * qty_to_order) as number
        return total_cost_for_item
    }

    const isFirstTable = (index: number) => {
        return index === 0
    }

    const isLastTable = (index: number, handout_categories: MemberHandoutCategoryFull[]) => {
        return index + 1 === handout_categories.length
    }

    const handleVisibilityClick = (handout_item: MemberHandoutItemPartial) => {
        if (!handout_categories || !num_of_children)
            return 

        let total_qty_to_ratio = 0
        let total_cost_per_child = 0
        let total_cost = 0
        let total_percentage = 0

        let updated_handout_categories = handout_categories.slice(0, -1)
        updated_handout_categories = updated_handout_categories.map(category => ({
            ...category,
            member_handouts_items: category.member_handouts_items.filter(item => item.Visible)
        }))

        updated_handout_categories = updated_handout_categories.map(category => ({
            ...category,
            member_handouts_items: category.member_handouts_items.map(item => {
                if (item.Code === handout_item.Code) {
                    return {
                        ...item,
                        Visible: false
                    }
                }
                const qty_to_order = calcQtyToOrder(item, num_of_children)
                const cost_per_child = calcCostPerChild(item)
                const total_cost_per_item = calcTotalCostForItem(item, qty_to_order)

                const ratio = parseToMoneyValue(item.Ratio) as number
                total_qty_to_ratio = total_qty_to_ratio + ratio
                total_cost_per_child = total_cost_per_child + cost_per_child
                total_cost = total_cost + total_cost_per_item

                return {
                    ...item,
                    CostPerChild: cost_per_child,
                    QtyToOrder: qty_to_order,
                    TotalCostPerItem: total_cost_per_item,
                    Percentage: null,
                    StockRemaining: Math.max((parseToMoneyValue(item.QuantityOnHand) as number) - qty_to_order, 0)
                }
            })
        }))

        // filter out newly hidden handout item
        updated_handout_categories = updated_handout_categories.map(category => ({
            ...category,
            member_handouts_items: category.member_handouts_items.filter(item => item.Visible)
        }))

        updated_handout_categories = updated_handout_categories.map(category => {
            return {
                ...category,
                member_handouts_items: category.member_handouts_items.map(member_handout_item => {
                    const percentage = parseNumberToMoneyValue((member_handout_item.TotalCostPerItem / total_cost) * 100) as number
                    total_percentage = parseNumberToMoneyValue(total_percentage + percentage) as number
                    return {
                        ...member_handout_item,
                        Percentage: percentage
                    }
                })
            }
        })

        updated_handout_categories.push({
            id: 0,
            category_name: "Total",
            category_type: "Monkeynastix",
            member_handouts_items: [{
                Code: "",
                Item: "",
                Ratio: total_qty_to_ratio.toString(),
                Visible: true,
                PurchaseDetails: "",
                SalesDetails: "",
                QuantityOnHand: "",
                CostPerChild: total_cost_per_child,
                QtyToOrder: "",
                TotalCostPerItem: total_cost,
                Percentage: (99.98 <= total_percentage && total_percentage <= 100.02) ? "100" : (total_percentage === 0 ? "0" : "Inaccurate "),
                StockRemaining: "",
            }]
        })

        setHandoutCategories(updated_handout_categories)
    }

    const getBackgroundColor = () => {
        if (program_type === SchoolType.MONKEYNASTIX)
            return '#fbeab8'
        else if (program_type === SchoolType.BABYNASTIX)
            return '#abbefd'
        else if (program_type === SchoolType.SUPERNASTIX)
            return '#a9f9bc'
    }

    memberHandoutsTableStyles.headerCell = {
        border: '1px solid gray',
        backgroundColor: getBackgroundColor(),
        fontSize: '0.7rem',
        textAlign: 'left',
        minWidth: '15ch',
        padding: '2px 4px'
    }

	return (
        <>
        <Pages page_title="Member Handouts">
			<PageHeadingTypography> Member Handouts </PageHeadingTypography>
            <Box sx={{gap: '1rem', display: 'flex', alignItems: 'flex-start', marginBottom: '1rem'}}>
                <SelectProgramType program_type={program_type} setProgramType={setProgramType}/>
                {/* <Button onClick={handlSyncItemsWithXeroClick} sx={{border: '1px solid lightgrey', padding: "0.2rem 1rem", textTransform: 'none', gap: 1, borderRadius: 2, color: 'darkslategray'}}> 
                    {<img src={XeroLogo} alt="A blue Xero logo" width='20px'/>}
                    Sync Xero Items 
                </Button> */}
                <PrintAsPDF filename='Handouts Budget.pdf' ref={tableRef} setHideComponent={setHideComponent}/>
            </Box>
            <Box sx={{padding: '1rem'}} ref={tableRef}>
                <Box sx={{display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem'}}> 
                    <Typography sx={{fontSize: '1.2rem', fontWeight: 900, textTransform: 'uppercase'}}> {program_type} </Typography>    
                    <Typography sx={{fontSize: '1rem', fontWeight: 900, textTransform: 'uppercase'}}> {company.tenant_name} </Typography>    
                    <Typography sx={{fontSize: '1.2rem', fontWeight: 900, textTransform: 'uppercase'}}> {year} </Typography>    
                    <Typography sx={{fontSize: '0.8rem'}}> {formatDate(currentDate, false)} </Typography>    
                </Box>
                {handout_categories &&
                    <div style={{overflow: 'auto' }}>
                        {handout_categories.map((handout_category, index) => (
                            <table key={handout_category.id} style={{ borderCollapse: 'collapse', width: '100%' }}>
                                <thead>
                                        <tr>
                                        {isFirstTable(index) && (
                                            <>
                                                <th ></th>
                                                <th colSpan={1} style={{...memberHandoutsTableStyles.spanningHeaderCell}}></th>
                                                <th colSpan={3} style={{...memberHandoutsTableStyles.spanningHeaderCell}}>
                                                    Per Child
                                                </th>
                                                <th colSpan={2} style={{...memberHandoutsTableStyles.spanningHeaderCell}}>
                                                    Totals
                                                </th>
                                                <th colSpan={1} style={{...memberHandoutsTableStyles.spanningHeaderCell}}>
                                                    %
                                                </th>
                                                {/* <th colSpan={1} style={{...memberHandoutsTableStyles.spanningHeaderCell, border: 'none', backgroundColor: 'none',  maxWidth: '3rem', minWidth: '3rem'}}>
                                                    Amount Paid
                                                </th> */}
                                                <th></th>
                                            </>
                                        )}
                                        </tr>
                                        <tr>
                                            <th style={!hide_component ? {minWidth: '16px'} : {}}></th>
                                            <th style={{...memberHandoutsTableStyles.headerCell, minWidth: '20ch'}}>{handout_category.category_name}</th>
                                            <th style={memberHandoutsTableStyles.headerCell}>Qty <span style={memberHandoutsTableStyles.bracketText}>(Per Child/Annum)</span></th>
                                            <th style={memberHandoutsTableStyles.headerCell}>Cost <span style={memberHandoutsTableStyles.bracketText}>(Per Item)</span></th>
                                            <th style={memberHandoutsTableStyles.headerCell}>Cost <span style={memberHandoutsTableStyles.bracketText}>(Per Child/Annum)</span></th>
                                            <th style={memberHandoutsTableStyles.headerCell}>Qty to Order</th>
                                            <th style={memberHandoutsTableStyles.headerCell}>Cost <span style={memberHandoutsTableStyles.bracketText}>(Per Item)</span></th>
                                            <th style={memberHandoutsTableStyles.headerCell}>Percentage <span style={memberHandoutsTableStyles.bracketText}>(Item to Budget)</span></th>
                                            {isFirstTable(index) ?
                                                <th style={{...memberHandoutsTableStyles.headerCell, textAlign: 'center', maxWidth: '3rem', minWidth: '3rem'}}>Amounts</th>
                                                : <th style={{...memberHandoutsTableStyles.headerCell, backgroundColor: 'none',  maxWidth: '3rem', minWidth: '3rem'}}></th>
                                            }
                                            {isFirstTable(index) ?
                                                <th style={{...memberHandoutsTableStyles.headerCell, textAlign: 'center', maxWidth: '3rem', minWidth: '3rem'}}>Stock</th> 
                                                : <th style={{...memberHandoutsTableStyles.headerCell, backgroundColor: 'none', maxWidth: '3rem', minWidth: '3rem'}}></th>
                                            }
                                            {isFirstTable(index) ?
                                                <th style={{...memberHandoutsTableStyles.headerCell, textAlign: 'center', maxWidth: '4rem', minWidth: '4rem'}}>Remaining</th> 
                                                : <th style={{...memberHandoutsTableStyles.headerCell, backgroundColor: 'none', maxWidth: '4rem', minWidth: '4rem'}}></th>
                                            }
                                        </tr>
                                </thead>
                                <tbody>
                                    {handout_category.member_handouts_items.map((handout_item) => {
                                        if (handout_item.Visible) {
                                            return (
                                                <tr key={handout_item.Code}>
                                                    {!isLastTable(index, handout_categories) ? 
                                                        <td>
                                                            {!hide_component && 
                                                                <IconButton onClick={() => handleVisibilityClick(handout_item)} sx={{padding: 0, marginRight: '0.2rem'}}>
                                                                    <VisibilityIcon sx={{fontSize: '0.8rem'}}/>
                                                                </IconButton>
                                                            }
                                                        </td>
                                                        : <td></td>
                                                    }
                                                    <td style={{
                                                        ...memberHandoutsTableStyles.cell, textAlign: 'left', minWidth: '20ch',
                                                        ...(isLastTable(index, handout_categories) ? memberHandoutsTableStyles.greyedOutDiagonal : {})
                                                    }}>
                                                        {handout_item.Item}</td>
                                                    <td style={{...memberHandoutsTableStyles.cell, textAlign: 'center'}}>{handout_item.Ratio}</td>
                                                    <td style={{
                                                        ...memberHandoutsTableStyles.cell, 
                                                        ...(isLastTable(index, handout_categories) ? memberHandoutsTableStyles.greyedOutDiagonal : {})
                                                    }}>
                                                        {handout_item.PurchaseDetails ? "R " : ""}
                                                        {handout_item.PurchaseDetails}
                                                    </td>
                                                    <td style={memberHandoutsTableStyles.cell}>R {handout_item.CostPerChild}</td>
                                                    <td style={{
                                                        ...memberHandoutsTableStyles.cell, 
                                                        ...(isLastTable(index, handout_categories) ? memberHandoutsTableStyles.greyedOutDiagonal : {})
                                                    }}>
                                                        {handout_item.QtyToOrder}
                                                    </td>
                                                    <td style={
                                                        index + 1 === handout_categories.length ? {...memberHandoutsTableStyles.cell, borderBottom: '5px double #000', borderTop: '2px solid #000' } : memberHandoutsTableStyles.cell
                                                    }>
                                                        R {handout_item.TotalCostPerItem}
                                                    </td>
                                                    <td style={memberHandoutsTableStyles.cell}>{handout_item.Percentage}%</td>
                                                    <td style={{
                                                        ...memberHandoutsTableStyles.cell, 
                                                        ...(isLastTable(index, handout_categories) ? memberHandoutsTableStyles.mainResultValue : {})
                                                    }}></td>
                                                    <td style={{
                                                        ...memberHandoutsTableStyles.cell, 
                                                        ...(isLastTable(index, handout_categories) ? memberHandoutsTableStyles.mainResultValue : {}),
                                                        ...(handout_item.QtyToOrder > handout_item.QuantityOnHand ? {color: 'red'} : {color: 'green'})
                                                    }}>
                                                        {handout_item.QuantityOnHand}
                                                    </td>
                                                    <td style={{
                                                        ...memberHandoutsTableStyles.cell, 
                                                        ...(isLastTable(index, handout_categories) ? memberHandoutsTableStyles.mainResultValue : {}),
                                                        ...(handout_item.QtyToOrder > handout_item.QuantityOnHand ? {color: 'red'} : {color: 'green'})
                                                    }}>
                                                        {handout_item.QuantityOnHand}
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        return <tr key={handout_item.Code}></tr>
                                    })}
                                </tbody>
                            </table>
                        ))}
                    </div>
                }
            </Box>
        </Pages>
        </>
	)
}

export default MemberHandoutsPage
