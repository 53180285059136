import Table from '@components/tables/Table/Table'

import { TableProps } from '../ChildTable'
import FranchiseCostingDialogHandler from '@components/dialogs/FranchiseCostingDialog'


const FranchiseCostingTable = (props: TableProps) => {
	const {action} = props
    
	const columns = [
		{ field: 'id', headerName: 'ID', minWidth: 150, flex: 2 },
		{ field: 'franchise_name', headerName: 'Franchise', minWidth: 150, flex: 2 },
		{ field: 'currency', headerName: 'Currency', minWidth: 150, flex: 1 },
		{ field: 'created_at', headerName: "Date Created", minWidth:200, flex:1},
		{ field: 'modified_at', headerName: "Date Modified", minWidth:200, flex:2},
	]

    return (
        <Table
            single_selection_dialogs={[FranchiseCostingDialogHandler]}
			page_size={25}
			amount_of_rows_selection={[10, 25, 50]}
			action={action}
			columns={columns}
			can_add={false}
			can_edit={false}
			can_delete={false}
		/>
    )
}

export default FranchiseCostingTable