import {useState, useContext, useEffect} from 'react'
import { useTranslation } from 'react-i18next'

import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Alert from '@mui/material/Alert'

import { TableActionButon } from '@styles/Buttons'
import { DialogComponentProps } from '@components/tables/Table/Table'
import { INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { CommonDialogProps } from '@utils/dialogProps/DialogProps'
import { MessageContext } from '@contexts/MessageContext'
import EvaluationActions from '@actions/CRUDActions/ClassActions/evaluationActions'
import { Child, Evaluation } from '@utils/interfaces/interfaces'
import ChildActions from '@actions/CRUDActions/ChildActions/childActions'


const AddChildrenToEvaluationDialog = (props: CommonDialogProps) => {
    // eslint-disable-next-line
	const {ids, state_manager, onClose, open} = props
    const { t } = useTranslation('classes')

	const {setMessage} = useContext(MessageContext)

    const evaluation_id = ids[0]

    const [evaluation, setEvaluation] = useState<Evaluation | null>(null)
    const [children_options, setChildrenOptions] = useState<Child[] | null>(null)
    const [selected_children, setSelectedChildren] = useState<Child[]>([])

    useEffect(() => {
        const evaluation_action = new EvaluationActions()
        evaluation_action.getById(evaluation_id.toString())
        .then((evaluation: Evaluation) => {
            setEvaluation(evaluation)
            const child_actions = new ChildActions()
            child_actions.get(undefined, undefined, {'company_class': evaluation.company_class})
            .then((children: Child[]) => {
                const children_three_or_older = children.filter(child => Number.isInteger(child.age) && child.age >= 3)
                setChildrenOptions(children_three_or_older.filter(child => !evaluation.children.includes(child.id)))
            })                
        })
    }, [])
    
    const handleClose = () => {
		onClose()
	}

	const handleAddChildrenSubmit = () => {
        const evaluation_action = new EvaluationActions()
        evaluation_action.update({...evaluation,children: selected_children.map((child) => child.id)}, evaluation_id.toString())
        .then(() => {
            setMessage(props => ({...props, success: "Children added to Assessment"}))
        })
        .catch(() => {
            setMessage(props => ({...props, error: "Something went wrong. Please try again."}))
        })
        .finally(() => {
            handleClose()
        })
	}

    const handleOnAddChildrenChange = (event: any, children: Child[]) => {
        setSelectedChildren(children)
    }

    return (
		<INastixDialog onClose={handleClose} open={open}>
        	<INastixDialogTitle> Add to Assessment </INastixDialogTitle>
            {evaluation && children_options ?
                <>
                <Alert severity="info" sx={{width:'300px', margin: '0 auto 1rem auto', fontSize: '0.8rem'}}>Showing children over 3 years old, not already allocated to this Assessment and in the class - {evaluation.company_class_name} </Alert>
                <Autocomplete
                    multiple
                    value={selected_children}
                    options={children_options}
                    isOptionEqualToValue={(option, value) => option.id === value.id} // Check if option's id is equal to the value's id
                    getOptionLabel={(option) => option.username}
                    renderInput={(params) => <TextField {...params } label="Children" />}
                    onChange={handleOnAddChildrenChange}
                    sx={{width:'80%', margin: 'auto', marginBottom: '2rem'}}
                />
                </>
                :
                <></>
            }

			<Box sx={{display: 'flex', justifyContent: 'center', gap: '1rem'}}>
				<Button variant={'outlined'} onClick={handleClose}> {t('close')} </Button>
				<Button variant='contained' sx={{flexGrow: '0.5'}} onClick={handleAddChildrenSubmit}> Submit </Button>
			</Box>
		</INastixDialog>
    )
}


const AddChidlrenToEvaluationDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props
    const { t } = useTranslation('classes')

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<PersonAddAlt1Icon/>}>
			<Typography> Add children </Typography>
		</TableActionButon>
		{open && <AddChildrenToEvaluationDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>}
		</>
	)
}

export default AddChidlrenToEvaluationDialogHandler