import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepContent from '@mui/material/StepContent'
import { StepIconProps } from '@mui/material/StepIcon'

import { ColorlibConnector } from '@pages/Setup/UserSetup/SetupStepper/ColorStepIcon'


interface StepperComponentProps {
    setup_step: number
    steps: string[]
    stepCompleted?: (index: number) => boolean
    GetStepComponent: ((active_step: number) => JSX.Element | "Unknown stepIndex")
    ColorlibStepIcon: ((props: StepIconProps) => JSX.Element)
}

const DesktopStepper = (props: StepperComponentProps) => {
    const {setup_step, steps, stepCompleted, GetStepComponent, ColorlibStepIcon} = props

    return (
        <>
        <Stepper alternativeLabel activeStep={setup_step} connector={<ColorlibConnector />}>
            {steps.map((label, index) => (
                <Step key={label} completed={stepCompleted ? stepCompleted(index) : undefined}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                </Step>
            ))}
        </Stepper>
        {GetStepComponent(setup_step)}
        </>
    )
}

const MobileStepper = (props: StepperComponentProps) => {
    const {setup_step, steps, stepCompleted, GetStepComponent, ColorlibStepIcon} = props

    return (
        <Stepper alternativeLabel activeStep={setup_step} connector={<ColorlibConnector />} orientation="vertical">
            {steps.map((label, index) => (
                <Step key={label} completed={stepCompleted ? stepCompleted(index) : undefined}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                    <StepContent>
                        {GetStepComponent(setup_step)}
                    </StepContent>
                </Step>
            ))}
        </Stepper>
    )
}

export {DesktopStepper, MobileStepper}