import { BaseAction } from "@actions/Actions/actions"
import MemberHandoutsHandler from "@adapters/handlers/MemberHandoutsHandler/memberHandoutsHandlers"


class MemberHandoutsActions extends BaseAction {
    handler: MemberHandoutsHandler
    
    constructor() {
        super()
        this.handler = new MemberHandoutsHandler()
    }

    getMemberHandoutItems = (data: object): Promise<any> => {
        return this.handler.getMemberHandoutItems(data)
    }

    syncMemberHandoutItemsToXero = (): Promise<any> => {
        return this.handler.syncMemberHandoutItemsToXero()
    }
}


export default MemberHandoutsActions