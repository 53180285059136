import { CRUDAction } from "@actions/Actions/actions"
import FranchiseMemberPoolHandler from "@adapters/handlers/MinastixMemberPoolHandlers/franchiseMemberPoolHandler"
import MinastixMemberPoolHandler from "@adapters/handlers/MinastixMemberPoolHandlers/minastixMemberPoolHandlers"


class MinastixMemberPoolActions extends CRUDAction {
    handler: MinastixMemberPoolHandler
    franchise_member_pool_handler: FranchiseMemberPoolHandler

    constructor() {
        super()
        this.handler = new MinastixMemberPoolHandler()
        this.franchise_member_pool_handler = new FranchiseMemberPoolHandler()
    }

    thisFranchisesChild (data: object): Promise<any> {
        return this.franchise_member_pool_handler.thisFranchisesChild(data)
    }

    sendToMemberPool (data: object): Promise<any> {
        return this.franchise_member_pool_handler.sendToMemberPool(data)
    }
}


export default MinastixMemberPoolActions