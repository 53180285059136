import { Parent } from '@utils/interfaces/interfaces'
import { createContext } from 'react'


export type SetParents = React.Dispatch<React.SetStateAction<Parent[]>>

interface IParentsContext {
    parents: Parent[]
    setParents: SetParents
}

export const ParentsContext = createContext<IParentsContext>({
    parents: [],
    setParents: () => {}
})