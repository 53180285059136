import { LanguageConf } from "@utils/enums/enums"


const LanguageConfMap = {
    [LanguageConf.ENGLISH]: 'en',
    [LanguageConf.AFRIKAANS]: 'af'
}

export {
    LanguageConfMap
}