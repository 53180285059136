const parseToMoneyValue = (string_money_value: string | null) : number | null => {
    if (string_money_value === null)
        return null

    return parseFloat(parseFloat(string_money_value).toFixed(2))
}

const parseNumberToMoneyValue = (money_value: number | null) : number | null => {
    if (money_value === null)
        return null

    return parseFloat(money_value.toFixed(2))
}


export {
    parseToMoneyValue,
    parseNumberToMoneyValue
}