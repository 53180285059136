import {useState} from 'react'

import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup  from '@mui/material/FormGroup'
import Checkbox from '@mui/material/Checkbox'


export interface MonkeynastixType {
    is_monkeynastix: boolean,
    is_babynastix: boolean,
    is_supernastix: boolean
}

interface ChooseMonkeynastixTypeProps {
    chooseMonkeynastixTypeCallback: (data: MonkeynastixType) => void
    initial_values?: MonkeynastixType | null
    styling?: 'horizontal' | 'vertical'
}

const ChooseMonkeynastixType = (props: ChooseMonkeynastixTypeProps) => {
    const {chooseMonkeynastixTypeCallback, initial_values, styling='vertical'} = props

    const [monkeynastix_type, setMonkeynastixType] = useState<MonkeynastixType>({
        is_monkeynastix: !!initial_values?.is_monkeynastix,
        is_babynastix: !!initial_values?.is_babynastix,
        is_supernastix: !!initial_values?.is_supernastix
    })

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, monkeynastix_type_key: string) => {
        chooseMonkeynastixTypeCallback({...monkeynastix_type, [monkeynastix_type_key]: event.target.checked})
        setMonkeynastixType(props => ({...props, [monkeynastix_type_key]: event.target.checked}))
    }

    return (
        <Box sx={{ minWidth: 120 }}>
            <FormGroup sx={styling === 'horizontal' ? {flexDirection: 'row', justifyContent: 'space-around'}: {}}>
                <FormControlLabel 
                    control={<Checkbox checked={monkeynastix_type.is_babynastix} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'is_babynastix')}/>} 
                    label="Babynastix" 
                />
                <FormControlLabel 
                    control={<Checkbox checked={monkeynastix_type.is_monkeynastix} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'is_monkeynastix')}/>} 
                    label="Monkeynastix" 
                />
                <FormControlLabel 
                    control={<Checkbox checked={monkeynastix_type.is_supernastix} onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, 'is_supernastix')}/>} 
                    label="Supernastix" 
                />
            </FormGroup>
        </Box>
    )
}

export default ChooseMonkeynastixType