import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import BackToLogin from '@pages/Setup/BackToLogin'

import AuthFooter from '../util/AuthFooter'
import { AuthTypography } from '../util/StyledComponents'


const ResetSent = () => {
    return (
        <>
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection:'column'}} height={'100vh'}>
            <AuthTypography variant="h1" gutterBottom mt={'auto'}> Reset link sent </AuthTypography>
            <Typography sx={{maxWidth: '50%', margin: '1rem auto 2rem auto'}} textAlign={'center'}>
                An e-mail was sent to the e-mail address associated with the email you entered.
                Please check your mailbox and follow the link in the email
                to change your password.
            </Typography>
            <BackToLogin/>
            <AuthFooter/>
        </Box>
        </>
    )
}

export default ResetSent