import InstructorActions from "@actions/CRUDActions/InstructorActions/instructorActions"
import InstructorTable from "@components/tables/CRUD/InstructorTable"
import CreateInstructorWeeklyScheduleReporDialogHandler from "@components/dialogs/Reports/Details/InstructorWeeklyScheduleDialogHandler"

import ReportingPage from ".."


const ReportWeeklySchedule = () => {
	const isntructor_actions = new InstructorActions()

    return (
        <ReportingPage>
            <InstructorTable action={isntructor_actions} use_as_reporting={true} dialogs={[CreateInstructorWeeklyScheduleReporDialogHandler]}/>
        </ReportingPage>
    )
}

export default ReportWeeklySchedule