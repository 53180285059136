import { createContext } from 'react'


export type SetOpenInstructorFinanceDialog = React.Dispatch<React.SetStateAction<boolean>>

interface IOpenInstructorFinanceDialogContext {
    open_instructor_finance_dialog: boolean
    setOpenInstructorFinanceDialog: SetOpenInstructorFinanceDialog
}

export const OpenInstructorFinanceDialogContext = createContext<IOpenInstructorFinanceDialogContext>({
    open_instructor_finance_dialog: false,
    setOpenInstructorFinanceDialog: () => {}
})