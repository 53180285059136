import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import Badge from '@mui/material/Badge'


interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
                {children}
            </Box>
        )}
        </div>
    )
}

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    }
}


interface INastixTabsProps {
    nav_items: string[]
    tab_items: React.ReactNode[]
    tabs_map?: Record<string, number>
    tabs_key?: string | null
    orientation?: "horizontal" | "vertical"
    needs_a_badge?: Record<string, number>
    children?: React.ReactNode
}


const INastixTabs = (props: INastixTabsProps) => {
    const {nav_items, tab_items, tabs_map, tabs_key, orientation="horizontal", needs_a_badge, children} = props

    const navigate = useNavigate()

    const [value, setValue] = useState<number | undefined>(undefined)

    useEffect(() => {
        if (tabs_map && tabs_key)
            setValue(tabs_map[tabs_key])
        else if (!value)
            setValue(0)
    }, [tabs_map, tabs_key, value])

	const handleChange = (event: React.SyntheticEvent, new_value: number) => {
		setValue(new_value)

        const current_url = window.location.pathname

        if (window.location.href.includes('?tab=')) {
            const parts = current_url.split('?tab=')
            const new_url = parts[0]
            navigate(new_url)
        }
	}

    const vertical_box_styling = { flexGrow: 1, bgcolor: 'background.paper', display: 'flex', height: 224, position: 'relative' }
    const horizontal_box_stylying = { width: '100%', position: 'relative' }

    return (
        <>
        {value === undefined ? <></>
            :
            <Box sx={orientation === "horizontal" ? horizontal_box_stylying : vertical_box_styling}>
            <Tabs
                orientation={orientation}
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="inastix tabs"
                sx={{ borderRight: 1, borderColor: 'divider' }}
            >
                {nav_items.map((nav_item, index) => (
                    (needs_a_badge && needs_a_badge.hasOwnProperty(nav_item)) ? (
                        <Tab 
                            key={index}
                            label={
                                <Badge
                                    badgeContent={needs_a_badge[nav_item]} 
                                    color="error"
									sx={{'.MuiBadge-badge': {top: '-5px', right: '-4px'}}}  // right: -5px cuts off the badge at double digits so we use -4px

                                > 
                                    {nav_item} 
                                </Badge>
                            } 
                            {...a11yProps(index)} 
                        />
                    )
                        :
                        <Tab key={index} label={nav_item} {...a11yProps(index)} />
                    
                ))}
            </Tabs>
            {tab_items.map((TabItem, index) => (
                <TabPanel key={index} value={value} index={index}>
                    {TabItem}
                </TabPanel>
            ))}
            {children}
        </Box>
        }
        </>
    )
}

export default INastixTabs