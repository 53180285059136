import GenericFetchWrapper from "@adapters/api/fetchWrappers/genericFetchWrapper"
import { BaseHandler } from "@adapters/handlers/Handlers/handlers"
import { COMPANIES_ENDPOINT } from "@adapters/routes/endpoints"


class CompanySetupHandler extends BaseHandler {
    endpoint: string
    fetch_wrapper: GenericFetchWrapper

    constructor () {
        super()
        this.fetch_wrapper = new GenericFetchWrapper()
        this.endpoint = COMPANIES_ENDPOINT + '/setup'
    }

    createSetupDetails (data: object): Promise<any> {
        const endpoint = this.endpoint + '/details'
        return this.fetch_wrapper.post(endpoint, data)
    }

    chooseCompanyTypeSetup (data: object): Promise<any> {
        const endpoint = this.endpoint + '/choose-company-type'
        return this.fetch_wrapper.post(endpoint, data)
    }

    financeAndTerms (data: object): Promise<any> {
        const endpoint = this.endpoint + '/finance'
        return this.fetch_wrapper.post(endpoint, data)
    }

    xeroManagementSetup (data: object): Promise<any> {
        const endpoint = this.endpoint + '/xero-management'
        return this.fetch_wrapper.post(endpoint, data)
    }

    getAuthorizedSetup (): Promise<any> {
        const endpoint = this.endpoint + '/is-authorized'
        return this.fetch_wrapper.get(endpoint)
    }
}

export default CompanySetupHandler