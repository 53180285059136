import { forwardRef } from 'react'
import html2pdf from 'html2pdf.js'

import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'
import Button from '@mui/material/Button'


interface PrintAsPDFProps {
    filename: string
    setHideComponent?: React.Dispatch<React.SetStateAction<boolean>>
}

const PrintAsPDF = forwardRef<HTMLDivElement, PrintAsPDFProps>((props, ref) => {
    const {filename, setHideComponent} = props

    const handlePrintPDF = () => {
        if (setHideComponent)
            setHideComponent(true)

        setTimeout(() => {
            if (ref && typeof ref !== 'function') {
                const element = ref.current
                if (element) {
                    element.style.padding = '20px'; // Adjust the padding value as needed

                    const opt = {
                        filename: filename,
                        image: { type: 'jpeg', quality: 0.98 },
                        html2canvas: { scale: 2 },
                        jsPDF: { unit: 'in', format: 'a4', orientation: 'landscape'}
                    }

                    html2pdf().from(element).set(opt).save().finally(() => {
                        // Remove the padding after the PDF is generated to reset the element style
                        element.style.padding = '';
                    });
                }
            }
            if (setHideComponent)
                setHideComponent(false)
        }, 0)
    }

    return (
        <Button
            onClick={handlePrintPDF}
            sx={{ border: '1px solid lightgrey', padding: '0.2rem 1rem', textTransform: 'none', gap: 1, borderRadius: 2, color: 'darkslategray' }}
            startIcon={<LocalPrintshopIcon />}
        >
            Print as PDF
        </Button>
    )
})

export default PrintAsPDF
