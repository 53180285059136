import React, {useState, useEffect} from 'react'

import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { useMediaQuery, Theme } from '@mui/material'
import Rating, { IconContainerProps } from '@mui/material/Rating'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import { styled } from '@mui/material/styles'
import { useTheme } from '@mui/material/styles'
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined'
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

import { ChildEvaluation, ChildEvaluationCompletionStatusEnum, evaluation_criteria } from './enum'
import { SetChildrenEvaluationsFuncType } from './type'
import ChildEvaluationActions from '@actions/CRUDActions/ClassActions/childEvaluationActions'


const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
      color: theme.palette.action.disabled,
    },
}))

const customIcons: {
    [index: string]: {
      icon: React.ReactElement;
      label: string;
    };
  } = {
    1: {
        icon: <SentimentSatisfiedIcon color="error" />,
        label: 'Almost There',
    },
    2: {
        icon: <SentimentSatisfiedAltIcon color="warning" />,
        label: "You've Got It",
    },
    3: {
        icon: <SentimentVerySatisfiedIcon color="success" />,
        label: "You're A Natural",
    },
}


function IconContainer(props: IconContainerProps) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
  }


const EvaluationCriteriaMap: Record<string, string> = {
    static_balance_left: "static balance left",
    static_balance_right: "static balance right",
    dynamic_balance: "dynamic balance",
    ball_kick: "ball kick",
    jump_and_land: "jump and land",
    two_legs_jump: "two legs jump",
    one_leg_hop_left: "one leg hop left",
    one_leg_hop_right: "one leg hop right",
    ball_throw: "ball throw",
    ball_catch: "ball catch",
    monkeynastix_skill: "monkeynastix skill",
    sequence: "sequence"
}

type SetSliderLength = React.Dispatch<React.SetStateAction<number>>
type SetChildrenEvaluations = React.Dispatch<React.SetStateAction<ChildEvaluation[] | null>>

const CreateChildEvaluationSlides = (
        evaluation_or_awaiting_evaluation_id: string, 
        setSliderLengthCallback: SetSliderLength, 
        setChildrenEvaluationsFunc: SetChildrenEvaluationsFuncType,
        setChildrenEvaluationsCallback?: SetChildrenEvaluations,
        read_only: boolean = false
    ) => {
    const is_small_screen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

    const theme = useTheme()

    const [children_evaluations, setChildrenEvaluations] = useState<ChildEvaluation[] | null>(null)

    const left_criteria: string[] = []
    const right_criteria: string[] = []
    
    const keys = Object.keys(evaluation_criteria)
    keys.forEach((key, index) => {
        if (index % 2 === 0)
            left_criteria.push(key)
        else
            right_criteria.push(key)
    })

    useEffect(() => {
        setChildrenEvaluationsFunc(evaluation_or_awaiting_evaluation_id, setChildrenEvaluations)
    }, [evaluation_or_awaiting_evaluation_id, setChildrenEvaluations, setChildrenEvaluationsFunc])

    useEffect(() => {
        setSliderLengthCallback(children_evaluations ? children_evaluations.length : 0)
        if (setChildrenEvaluationsCallback)
            setChildrenEvaluationsCallback(children_evaluations)
    }, [children_evaluations, setSliderLengthCallback, setChildrenEvaluationsCallback])

    const handleChange = (event: any, criterion_key: string, index: number) => {
        const value = parseFloat(event.target.value)
        setChildrenEvaluations(props => props ? (
			props.map((child_evaluation, i) => {
				if (i === index)
					return { ...child_evaluation, [criterion_key]: value }
				else
					return child_evaluation
			}))
            : null
        )
    }

    const childEvaluationCompletionStatus = (child_evaluation: ChildEvaluation) => {
        if (
            child_evaluation.static_balance_left === 0 &&
            child_evaluation.static_balance_right === 0 &&
            child_evaluation.dynamic_balance === 0 &&
            child_evaluation.ball_kick === 0 &&
            child_evaluation.jump_and_land === 0 &&
            child_evaluation.two_legs_jump === 0 &&
            child_evaluation.one_leg_hop_left === 0 &&
            child_evaluation.one_leg_hop_right === 0 &&
            child_evaluation.ball_throw === 0 &&
            child_evaluation.ball_catch === 0 &&
            child_evaluation.monkeynastix_skill === 0 &&
            child_evaluation.sequence === 0
        )
            return ChildEvaluationCompletionStatusEnum.NOT_STARTED
        else if (
            child_evaluation.static_balance_left === 0 ||
            child_evaluation.static_balance_right === 0 ||
            child_evaluation.dynamic_balance === 0 ||
            child_evaluation.ball_kick === 0 ||
            child_evaluation.jump_and_land === 0 ||
            child_evaluation.two_legs_jump === 0 ||
            child_evaluation.one_leg_hop_left === 0 ||
            child_evaluation.one_leg_hop_right === 0 ||
            child_evaluation.ball_throw === 0 ||
            child_evaluation.ball_catch === 0 ||
            child_evaluation.monkeynastix_skill === 0 ||
            child_evaluation.sequence === 0
        )
            return ChildEvaluationCompletionStatusEnum.PARTIALLY_COMPLETE
        else
            return ChildEvaluationCompletionStatusEnum.COMPLETED
    }

    const childNameColour = (child_evaluation: ChildEvaluation) => {
        const child_evaluation_completion_status = childEvaluationCompletionStatus(child_evaluation)
        if (child_evaluation_completion_status === ChildEvaluationCompletionStatusEnum.NOT_STARTED)
            return {color: theme.palette.error.main}
        else if (child_evaluation_completion_status === ChildEvaluationCompletionStatusEnum.PARTIALLY_COMPLETE)
            return {color: theme.palette.warning.main}

        return {color: 'darkslategray'}
    }

    const handleDeleteChildEvaluation = (this_child_evaluation: ChildEvaluation) => {
        if (!children_evaluations)
            return

        const child_evaluation_actions = new ChildEvaluationActions()
        child_evaluation_actions.delete(this_child_evaluation.id.toString())

        const new_children_evaluations = children_evaluations.filter(child_evaluation => child_evaluation.child !== this_child_evaluation.child)
        setChildrenEvaluations(new_children_evaluations)
    }

    return children_evaluations ? children_evaluations.map((child_evaluation, index) => (
        <Box key={child_evaluation.child}>
            <Box display={'flex'} gap={'1rem'} justifyContent={'center'} alignItems={'center'}>
                <Typography fontSize={'1.3rem'} fontWeight={500} sx={() => childNameColour(child_evaluation)}> {child_evaluation.child_name} </Typography>
                <Tooltip title="Delete this assessment">
                    <IconButton onClick={() => handleDeleteChildEvaluation(child_evaluation)}>
                        <DeleteOutlineIcon/>
                    </IconButton>
                </Tooltip>
            </Box>
            <br/>
            <Box display={'flex'} justifyContent={'center'} sx={is_small_screen ? {gap: 0} : {gap: 6}}>
                <Box>
                {left_criteria.map((criterion, criterion_index) => (
                    <Box key={criterion_index} marginBottom={1}>
                        <Typography component="legend" textTransform={'capitalize'} fontSize={'0.8rem'}>{EvaluationCriteriaMap[criterion]}</Typography>
                        <StyledRating
                            value={parseFloat(child_evaluation[criterion])}
                            onChange={(event) => handleChange(event, criterion, index)}
                            IconContainerComponent={IconContainer}
                            max={3}
                            readOnly={read_only}
                            highlightSelectedOnly
                        />
                    </Box>
                ))}
                </Box>
                <Box>
                {right_criteria.map((criterion, criterion_index) => (
                    <Box key={criterion_index} marginBottom={1}>
                        <Typography component="legend" textTransform={'capitalize'} fontSize={'0.8rem'}>{EvaluationCriteriaMap[criterion]}</Typography>
                        <StyledRating
                            value={parseFloat(child_evaluation[criterion])}
                            onChange={(event) => handleChange(event, criterion, index)}
                            IconContainerComponent={IconContainer}
                            max={3}
                            readOnly={read_only}
                            highlightSelectedOnly
                        />
                    </Box>
                ))}
                </Box>
            </Box>
        </Box>
    )
    ): null
}

export default CreateChildEvaluationSlides