import FranchiseeActions from "@actions/CRUDActions/FranchiseeActions/franchiseeActions"
import FranchiseeTable from "@components/tables/Custom/FranchiseeTable"
import CreateFranchiseSalesReportDialogHandler from "@components/dialogs/Reports/Details/FranchiseSalesReportDialogHandler"

import ReportingPage from ".."


const ReportFranchiseSales = () => {
	const franchise_actions = new FranchiseeActions()

    return (
        <ReportingPage>
            <FranchiseeTable action={franchise_actions} use_as_reporting={true} dialogs={[CreateFranchiseSalesReportDialogHandler]}/>
        </ReportingPage>
    )
}

export default ReportFranchiseSales