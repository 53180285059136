import { CRUDAction } from "@actions/Actions/actions"
import FZoneEcommerceCategoryHandler from "@adapters/handlers/CRUDHandlers/FZoneEcommerceHandlers/fzoneEcommerceCategoryHandler"


class FZoneEcommerceCategoryActions extends CRUDAction {
    handler: FZoneEcommerceCategoryHandler

    constructor() {
        super()
        this.handler = new FZoneEcommerceCategoryHandler()
    }
}

export default FZoneEcommerceCategoryActions