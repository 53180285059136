import { createContext } from 'react'


export type SetAuthorisedInstructorIds = React.Dispatch<React.SetStateAction<string[]>>

interface IAuthorisedInstructorIdsContext {
    authorised_instructor_ids: string[]
    setAuthorisedInstructorIds: SetAuthorisedInstructorIds
}

export const AuthorisedInstructorIdsContext = createContext<IAuthorisedInstructorIdsContext>({
    authorised_instructor_ids: [],
    setAuthorisedInstructorIds: () => {}
})