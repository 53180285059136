import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { Typography } from '@mui/material'
import ViewListIcon from '@mui/icons-material/ViewList'

import { Child } from '@utils/interfaces/interfaces'
import React from 'react'


interface WarnAboutInvalidChildrenProps {
    alert_message?: string
    warning_alert_message?: string
    cell_headings: React.ReactNode[]
    custom_cell_heading_styles?: object
    cell_rows?: React.ReactNode[]
    cellRowWithChildren?: (child: Child) => React.ReactNode
    custom_cell_row_styles?: object
    children: Child[]
}

const WarnAboutInvalidChildren = (props: WarnAboutInvalidChildrenProps) => {
    const {alert_message, warning_alert_message, cell_headings, custom_cell_heading_styles, children, cell_rows, cellRowWithChildren, custom_cell_row_styles} = props

    const cellStyle = {
        textAlign: 'center', // Center align the cell content horizontally
        verticalAlign: 'middle', // Center align the cell content vertically
    }

    const cellResendStyle = {
        textAlign: 'left', // Center align the cell content horizontally
        verticalAlign: 'middle', // Center align the cell content vertically
    }

    return (
        <>
        {children.length ? 
            <>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ViewListIcon />}
                    aria-controls="panel1-content"
                    sx={{backgroundColor: alert_message ? '#fdb0a7' : "#fff4e5"}}
                >
                    <Typography><span style={{fontStyle: 'italic', fontSize: '0.8rem'}}>{alert_message ? alert_message : warning_alert_message}</span> <span> ({children.length}) </span></Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <TableContainer sx={{margin: '1rem 0', border: '1px solid darkgrey', borderRadius: '4px'}}>
                        <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={cellResendStyle}>Child</TableCell>
                                {cell_headings.map((cell_heading, index) => (
                                    <TableCell key={index} sx={{...cellStyle, ...custom_cell_heading_styles}}>
                                        {cell_heading}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {children.map((child, index) => (
                                <TableRow key={`${child.username}-${index}`}>
                                    <TableCell sx={cellResendStyle}>{child.username}</TableCell>
                                    {cell_rows ? cell_rows.map(cell_row => cell_row) : <></>}
                                    {cellRowWithChildren && cellRowWithChildren(child)}
                                </TableRow>
                                )
                            )}
                        </TableBody>
                        </Table>
                    </TableContainer>
                </AccordionDetails>
            </Accordion>
            <br/>
            </>
            : <></>
        }
        </>
    )
}

export default WarnAboutInvalidChildren