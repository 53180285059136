import GenericFetchWrapper from "@adapters/api/fetchWrappers/genericFetchWrapper"
import { BaseHandler } from "@adapters/handlers/Handlers/handlers"
import { EMAIL_ENDPOINT } from "@adapters/routes/endpoints"
import { FormDataEntryType } from "@components/forms/Form"
import { EmailType } from "@utils/email/enum"


class EmailHandler extends BaseHandler {
    endpoint: string
    fetch_wrapper: GenericFetchWrapper

    constructor () {
        super()
        this.fetch_wrapper = new GenericFetchWrapper()
        this.endpoint = EMAIL_ENDPOINT
    }

    createForNastix (data: FormDataEntryType): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.endpoint)
        const endpoint = this.addParamsToEndpoint(parsed_endpoint, {email_type: EmailType.MONKEYNASTIX})
        return this.fetch_wrapper.post(endpoint, data)
    }

    createForDevs (data: FormDataEntryType): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.endpoint)
        const endpoint = this.addParamsToEndpoint(parsed_endpoint, {email_type: EmailType.TECHNICAL_SUPPORT})
        return this.fetch_wrapper.post(endpoint, data)
    }

    createForAnother (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.endpoint)
        const endpoint = this.addParamsToEndpoint(parsed_endpoint, {email_type: EmailType.TO_ANOTHER})
        return this.fetch_wrapper.post(endpoint, data)
    }

    unauthorisedMember (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.endpoint)
        const endpoint = this.addParamsToEndpoint(parsed_endpoint, {email_type: EmailType.CHILD_UNAUTHORISED})
        return this.fetch_wrapper.post(endpoint, data)
    }

    authorisedMember (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.endpoint)
        const endpoint = this.addParamsToEndpoint(parsed_endpoint, {email_type: EmailType.CHILD_AUTHORISED})
        return this.fetch_wrapper.post(endpoint, data)
    }

    awaitingPaymentMember (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.endpoint)
        const endpoint = this.addParamsToEndpoint(parsed_endpoint, {email_type: EmailType.CHILD_AWAITING_PAYMENT})
        return this.fetch_wrapper.post(endpoint, data)
    }

    notifyDevsOnError (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.endpoint)
        const endpoint = this.addParamsToEndpoint(parsed_endpoint, {email_type: EmailType.SYSTEM_ERROR})
        return this.fetch_wrapper.post(endpoint, data)
    }
}

export default EmailHandler