import log from "loglevel"

import { logoutRisky } from "@adapters/helpers/logout"
import { frontend_url } from "src/config"

import { MethodType } from "../enums/enum"
import GenericFetchWrapper from "./genericFetchWrapper"


class CRUDFetchWrapper extends GenericFetchWrapper {

    get (endpoint: string, page_size?: number, page?: number, filter = {}, group: string = ''): Promise<any> {
        const base_endpoint_url = this.getBaseEndpointUrl(endpoint)

        // construct the URL for the endpoint by concatenating the base endpoint URL with optional "page" and "page_size" query parameters, depending on whether "page" and "page_size" are defined or not
        const queryParams = []

        if (page !== undefined) {
            queryParams.push(`page=${page}`)
        }
      
        if (page_size !== undefined) {
            queryParams.push(`page_size=${page_size}`)
        }
      
        if (Object.keys(filter).length > 0) {
            for (const key in filter) {
                if (Object.prototype.hasOwnProperty.call(filter, key)) {
                    queryParams.push(`${key}=${(filter as Record<string, string>)[key]}`);
                }
            }
        }

        if (group) {
            queryParams.push(`group=${group}`)
        }
      
        // Construct the final URL by concatenating the base endpoint URL and query parameters
        const url = `${base_endpoint_url}${queryParams.length > 0 ? `?${queryParams.join('&')}` : ''}`

        const response = this.handleFetch(url, {}, MethodType.GET)
        return response
    }

    post (endpoint: string, data: object): Promise<any> {
        const base_endpoint_url = this.getBaseEndpointUrl(endpoint)

        const response = this.handleFetch(base_endpoint_url, data, MethodType.POST)
        return response
    }

    put (endpoint: string, data: object, id: string): Promise<any> {
        const base_endpoint_url = this.getBaseEndpointUrl(endpoint) + (id.endsWith('/') ? id : id + '/')

        const response = this.handleFetch(base_endpoint_url, data, MethodType.PUT)
        return response
    }

    patch (endpoint: string, data: object, id: string): Promise<any> {
        const base_endpoint_url = this.getBaseEndpointUrl(endpoint) + (id.endsWith('/') ? id : id + '/')

        const response = this.handleFetch(base_endpoint_url, data, MethodType.PATCH)
        return response
    }

    delete (endpoint: string, id: string): Promise<any> {
        return this._delete(endpoint, id)
    }

    deleteMultiple (endpoint: string, ids: Array<string>): Promise<any> {
        const query_param = `?ids=${ids}`
        return this._delete(endpoint, query_param)
    }

    private _delete = (endpoint: string, query_param: string | Array<string>) => {
        const base_endpoint_url = this.getBaseEndpointUrl(endpoint) + query_param

        const response = this.handleFetch(base_endpoint_url, {}, MethodType.DELETE)
        return response
    }

    handleFetch = async (url: string, data: object, method_type: MethodType): Promise<any> => {  
        const request_options = this.createRequestOptions(method_type, data)

        return fetch(url, request_options)
        .then(async response => {

            if (response.status === 401) {
                // if we get a  401 from the jwt refresh endpoint it means the refresh token is no longer valid and we need to log the user out
                if (this.refreshAttempted) {
                    return logoutRisky()
                }

                log.info('Access Token expired')
                
                this.refreshAttempted = true
                try {
                    await this.refreshToken()
                    log.info('Refreshed the Access Token')
                } catch (error) {
                    log.error(error)
                    return logoutRisky()
                }

                // If the access token was successfully refreshed, make the same request
                const response = this.handleFetch(url, data, method_type)
                return response
            }
            else if (response.status === 500) {
                window.location.href =  frontend_url + '500'

                const { sendEmailToDevs } = await import("@utils/email/email")  // lazy load sendEmailToDevs to prevent BaseHandler not yet loaded error

                response.text().then(text => {
                    const error_message = text.replace(/"/g, '')  // replaces "" with empty space, so our error message appears without quotes in the ui
                    sendEmailToDevs(error_message)
                })
            }
            else if (!response.ok) {
                return response.text().then(text => {
                    const error_message = text.replace(/"/g, '')  // replaces "" with empty space, so our error message appears without quotes in the ui
                    throw new Error(error_message)
                })
            }
            else if (response.status === 204) {
                return response
            }

            let response_data = await response.json()

            if (method_type !== MethodType.GET) {
                if (typeof response_data !== 'object')
                    throw new Error('Invalid response type: expecseceted object')
            }

            return response_data
        })
    }  
}

export default CRUDFetchWrapper