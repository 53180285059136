import React, {useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next'

import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select, {SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'

import CompanyActions from '@actions/CompanyActions/companyActions'
import { CountryTerm } from '@pages/Setup/CompanySetup/FinanceAndTerms'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { filterTermsByCountryTermId, getCurrentTerm } from '@utils/utils/util'
import { ADDED_ON_TO_TO_END_TERM_DATE } from '@components/dialogs/MemberCertificatesDialogHandler'


interface SelectCountryTermsOnlyProps {
    country_term_id: number | "All" | null
    term_index: number | null
    setTermIndex: React.Dispatch<React.SetStateAction<number | null>> 
}

const SelectTermIndex = (props: SelectCountryTermsOnlyProps) => {
    const {country_term_id, term_index, setTermIndex} = props

    const { t } = useTranslation('set_up')

    const [country_term, setCountryTerm] = useState<CountryTerm | null>(null)

    useEffect(() => {
        const company = getLocalStorageCompany()

        if (typeof country_term_id === 'number' && !isNaN(country_term_id) && company.country_name) {
            const company_actions = new CompanyActions()
            company_actions.getCountryTerms(company.country_name)
            .then((country_terms: CountryTerm[]) => {
                const country_term = country_terms.find(country_term => country_term.id === country_term_id)
                if (country_term)
                    setCountryTerm(country_term)

                const terms = filterTermsByCountryTermId(country_term_id)
                const current_term = getCurrentTerm(terms, ADDED_ON_TO_TO_END_TERM_DATE)

                if (!term_index && current_term.index !== null)
                    setTermIndex(current_term.index + 1)
            }) 
        }
    }, [term_index, setTermIndex, country_term_id])

    const handleTermIndexChange = (event: SelectChangeEvent) => {
        setTermIndex(parseInt(event.target.value))
    }

    return (
        <>
        {country_term &&
            <Box sx={{display: 'flex', gap: '1rem', width: '160px', margin: 'auto', marginTop: '1rem', marginBottom: '0.5rem'}}>
                <FormControl fullWidth>
                    <InputLabel id="term-index-type-select-label">{t('term_index')}</InputLabel>
                    <Select
                        labelId="term-index-type-select-label"
                        id="term-index-type-select"
                        value={term_index ? term_index.toString(): ""}
                        name="term_index"
                        label={t('term_index')}
                        onChange={handleTermIndexChange}
                        required
                    >
                        {Array.from({ length: country_term.num_of_terms }, (_, index) => (
                            <MenuItem key={index} value={index + 1}>{index + 1}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        }
        </>
    )
}

export default SelectTermIndex