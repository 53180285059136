import { UserSelectOption } from "./interfaces"


const getTop5OfUserSelections = (user_select_options: UserSelectOption[]) => {
    return user_select_options
    .sort((a, b) => a.priority - b.priority)
    .slice(0, 5)
}


export {
    getTop5OfUserSelections
}