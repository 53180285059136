import React, { useState, useEffect } from 'react'
import { TextField, Grid } from '@mui/material'
import { Address } from '@utils/interfaces/interfaces'
import { useTranslation } from 'react-i18next'


interface AddressFieldsProps {
    address_values?: Address
    setAddressCallback?: (data: Address) => void
    handleAddressOnBlurCallback?: (data: Address) => void
    autocomplete?: "on" | "off"
    required?: boolean
    helper_text_for_suburb?: string
    disabled?: boolean
    text_field_size?: 'small' | 'medium'
    styles?: object
}

const NonGridAddressFields = (props: AddressFieldsProps) => {
    const {address_values, handleAddressOnBlurCallback, setAddressCallback, autocomplete="on", required=false, helper_text_for_suburb, disabled, text_field_size='medium', styles={}} = props

    const [address, setAddress] = useState(address_values ? {...address_values} : {
        country: '',
        street: '',
        city: '',
        region: '',
        postal_code: '',
    })

    const { t } = useTranslation('fields')

    useEffect(() => {
        if (address_values) 
            setAddress(address_values)
    }, [address_values])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAddress({ ...address, [event.target.name]: event.target.value })
        if (setAddressCallback)
            setAddressCallback({ ...address, [event.target.name]: event.target.value })
    }

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (!handleAddressOnBlurCallback)
            return

        const { name, value } = event.target
        handleAddressOnBlurCallback({ ...address, [name]: value })
    }

    return (
        <>
        {address_values?.id !== undefined && <input type="hidden" value={address_values.id} id="id" name="id"/>}
        <TextField
            label={t('country')}
            name="country"
            value={address.country}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete={autocomplete}
            required={required}
            fullWidth
            disabled={disabled}
            size={text_field_size}
            sx={styles}
        />
        <TextField
            label={t('street')}
            name="street"
            value={address.street}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete={autocomplete}
            required={required}
            fullWidth
            disabled={disabled}
            size={text_field_size}
            sx={styles}
        />
        <TextField
            label={t('city_town')}
            name="city"
            value={address.city}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete={autocomplete}
            helperText={helper_text_for_suburb}
            required={required}
            fullWidth
            disabled={disabled}
            size={text_field_size}
            sx={styles}
        />
        <TextField
            label={t('state_province')}
            name="region"
            value={address.region}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete={autocomplete}
            required={required}
            fullWidth
            disabled={disabled}
            size={text_field_size}
            sx={styles}
        />
        <TextField
            label={t('zip_postal_code')}
            name="postal_code"
            value={address.postal_code}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete={autocomplete}
            required={required}
            fullWidth
            disabled={disabled}
            size={text_field_size}
            sx={styles}
        />
        </>
    )
}

export default NonGridAddressFields
