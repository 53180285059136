import {useContext, useEffect, useState, ReactNode, useRef} from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import { Box } from '@mui/system'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Container from '@mui/material/Container'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import CachedIcon from '@mui/icons-material/Cached'

import ListItemIcon from '@mui/material/ListItemIcon'
import SendIcon from '@mui/icons-material/Send'
import Avatar from '@mui/material/Avatar'
import CloseIcon from '@mui/icons-material/Close'
import INastixAvatar from '@pages/Settings/Settings/INastixAvatar'
import OpenAIChatActions from '@actions/OpenAIChatActions/openAIChatActions'
import TypingAnimation from '@components/backdrops/TypingAnimation'

import log from 'loglevel'

import {initial_assitant_messages, user_select_options_array } from './messageOptions'
import { ChatMessage, OpenAIResponse, UserSelectOption } from './interfaces'
import ChatbotAssistantIcon from './ChatbotAssistantIcon'
import { getTop5OfUserSelections } from './utils'


const OpenAIChatbot = () => {
    const paperRef = useRef<HTMLDivElement | null>(null)

    const [profile_picture, setProfilePicture] = useState('')
    const [assistant_chat_open, setAssistantChatOPen] = useState(false)
    const [user_query, setUserQuery] = useState("")
    const [chat_messages, setChatMessages] = useState<ChatMessage[]>(initial_assitant_messages)
    const [user_select_options, setUserSelectOptions] = useState<UserSelectOption[]>(getTop5OfUserSelections(user_select_options_array))
    const [user_awaiting_response, setUserAwaitingResponse] = useState(false)
    const [user_selected_id_options, setUserSelectedIdOptions] = useState<number[]>([])

    useEffect(() => {
        const paper_component_ref = paperRef.current
        if (paper_component_ref) {
            paper_component_ref.classList.add('container')
        }
    }, [])

    useEffect(() => {
        if (paperRef.current) {
            paperRef.current.scrollTop = paperRef.current.scrollHeight
        }
    }, [chat_messages])

    const handleClickOpenAssistantChat = () => {
        setAssistantChatOPen(props => !props)
    }

    const addMessage = (message: string, sent_from: 'user' | 'assistant') => {
        setChatMessages((prevMessages) => [
            ...prevMessages,
            {
                sent_from: sent_from,
                message: message
            }
        ])
    }

    const addUserMessage = (message: string) => {
        addMessage(message, 'user')
        setUserAwaitingResponse(true)
        const openai_chat_actions = new OpenAIChatActions()
        openai_chat_actions.sendUserQuery({'user_query': message})
        .then((openai_responses: OpenAIResponse[]) => {
            console.log(openai_responses)
            addAssistantMessage(openai_responses[0].content)
            setUserAwaitingResponse(false)
        })
        .catch((error) => {
            log.error(error.message)
            addAssistantMessage("Something went wrong. Please refresh the chat and try again.")
        })
    }

    const addAssistantMessage = (message: string) => {
        const formatted_message = message.replace(/\n/g, '<br />')
        addMessage(formatted_message, 'assistant')
    }

    const handleUserMessageSend = () => {
        if (user_query.trim() !== "") {
            addUserMessage(user_query)
            setUserQuery('')
        }
    }

    const handleUserSelectOptionClick = (user_option: UserSelectOption) => {
        addUserMessage(user_option.message)

        const user_selected_options_ids = [...user_selected_id_options, user_option.id]
        setUserSelectedIdOptions(user_selected_options_ids)

        const new_user_select_options_array = user_select_options_array.filter(select_option => !user_selected_options_ids.includes(select_option.id))

        setUserSelectOptions(getTop5OfUserSelections(new_user_select_options_array))
    }

    const handleRestartClick = () => {
        setUserAwaitingResponse(false)
        setChatMessages(initial_assitant_messages)
        setUserSelectOptions(getTop5OfUserSelections(user_select_options_array))
    }

    const add_yes_no = [
        {
            message: "Yes",
            type: 'answer'
        },
        {
            message: "No",
            type: 'answer'
        },
    ]

    const chat_messages_list_styling = {
        marginBottom: '1rem', 
        padding: 0, 
        gap: '0', 
        width: 'fit-content'
    }

    const assistant_chat_messages_list_styling = {
        ...chat_messages_list_styling
    }

    const user_chat_messages_list_styling = {
        ...chat_messages_list_styling,
        gap: '0.5rem', 
        marginLeft: 'auto'
    }

    const chat_message_styling = {
        padding: '5px 10px',
        color: '#fff',
        wordBreak: 'break-word',
        '& .MuiTypography-root': {
            fontSize: '0.8rem', // Adjust font size as needed
        },
        width: '100%',
        boxShadow: '0px 0px 4px 0px grey',
    }
    
    const assistant_message_style = {
        ...chat_message_styling,
        backgroundColor: '#1f8ceb',
        borderRadius: '0 1rem 1rem 1rem',
    }
    
    const user_message_style = {
        ...chat_message_styling,
        backgroundColor: '#eaf1f6',
        borderRadius: '1rem 1rem 0 1rem',
        color: '#000',
    }
    
    return (
        <Container sx={{position: 'fixed', right: 0, bottom: 0, padding: '0 !important', zIndex: 100, margin: '0.5rem', width: 'fit-content'}}>
            <Paper hidden={!assistant_chat_open} elevation={3} sx={{marginBottom: '1rem', backgroundColor: 'whitesmoke', boxShadow: 'grey 0px 0px 3px 1px', width: 'clamp(250px, 50%, 400px) !important', marginLeft: 'auto'}}>
                <Box sx={{borderBottom: '1px solid grey', display: 'flex'}}>
                    <Tooltip title="Restart">
                        <IconButton onClick={handleRestartClick} sx={{padding: '0.1rem', marginLeft: 'auto', marginRight: '2px'}}>
                            <CachedIcon sx={{fontSize: '1.2rem'}}/>
                        </IconButton>
                    </Tooltip>
                    <IconButton onClick={handleClickOpenAssistantChat}>
                        <CloseIcon sx={{fontSize: '0.8rem'}}/>
                    </IconButton>
                </Box>
                <Box ref={paperRef} sx={{padding: '1rem', overflow: 'auto', backgroundColor: 'inherit', maxHeight: '300px'}}>
                    <List>
                        {chat_messages.map((msg, index) => (
                            <ListItem key={index} sx={msg.sent_from === 'user' ? user_chat_messages_list_styling : assistant_chat_messages_list_styling}>
                                {msg.sent_from === 'assistant' &&
                                    <>
                                    <ListItemIcon>
                                        <Avatar sx={{ width: 30, height: 30, backgroundColor: 'whitesmoke' }}> 
                                            <ChatbotAssistantIcon />
                                        </Avatar>
                                    </ListItemIcon>
                                    <ListItemText 
                                        primary={
                                            <span
                                                dangerouslySetInnerHTML={{ __html: msg.message }}
                                                style={{ fontSize: '14px' }}  // Adjust the font size as needed
                                            />
                                        } 
                                        sx={assistant_message_style}
                                    />
                                    </>
                                }
                                {msg.sent_from === 'user' &&
                                    <>
                                    <ListItemIcon>
                                        <Avatar sx={{ width: 30, height: 30, backgroundColor: 'whitesmoke' }}>
                                            <INastixAvatar profile_picture={profile_picture} setProfilePicture={setProfilePicture}/>
                                        </Avatar>
                                    </ListItemIcon>
                                    <ListItemText 
                                        primary={msg.message} 
                                        sx={user_message_style}
                                    />
                                    </>
                                }
                            </ListItem>
                        ))}
                        {user_awaiting_response &&
                            <ListItem sx={assistant_chat_messages_list_styling}>
                                <ListItemIcon>
                                    <Avatar sx={{ width: 30, height: 30, backgroundColor: 'whitesmoke' }}> 
                                        <ChatbotAssistantIcon />
                                    </Avatar>
                                </ListItemIcon>
                                <ListItemText 
                                    primary={<TypingAnimation/>} 
                                    sx={{...assistant_message_style, paddingTop: '0.15rem'}}
                                />
                            </ListItem>
                        }
                    </List>
                    <List sx={{display: 'flex', gap: '1rem', flexWrap: 'wrap'}}>
                        {!user_awaiting_response && user_select_options.map((user_option, index) => {
                            return (
                                <ListItem key={user_option.id} sx={{border: '1px solid black', borderRadius: '20px', padding: 0, width: 'fit-content', fontSize: '0.7rem'}}>
                                    <ListItemButton onClick={() => handleUserSelectOptionClick(user_option)}>
                                        {user_option.message}
                                    </ListItemButton> 
                                </ListItem>
                            )
                        })}
                    </List>
                </Box>
                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '1rem', padding: '1rem'}}>
                    <TextField
                        label="Type your message..."
                        variant="outlined"
                        fullWidth
                        multiline
                        value={user_query}
                        onChange={(e) => setUserQuery(e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                if (e.shiftKey) {
                                    // Allow new line with Shift + Enter
                                    return
                                } else {
                                    // Prevent new line on Enter and send the message
                                    e.preventDefault()
                                    handleUserMessageSend()
                                }
                            }
                        }}
                    />
                    <IconButton onClick={handleUserMessageSend}>
                        <SendIcon/>
                    </IconButton>
                </Box>
            </Paper>
            <Box sx={{textAlign: 'right'}}>
                <Tooltip title="Chat with the iNastix assistant!">
                    <IconButton onClick={handleClickOpenAssistantChat} aria-label="Chatbot assistant" sx={{boxShadow: '0px 0px 4px -1px black'}}>
                        {assistant_chat_open ? 
                            <CloseIcon sx={{fontSize: '2rem'}}/>
                            : <ChatbotAssistantIcon sx={{fontSize: '2rem'}}/>
                        }
                    </IconButton>
                </Tooltip>
            </Box>
        </Container>
    )
}

export default OpenAIChatbot