import XeroLogo from '@images/xero/logo/white.png'

import SettingsIcon from '@mui/icons-material/Settings'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import { StepIconProps } from '@mui/material/StepIcon'
import StoreIcon from '@mui/icons-material/Store'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import { ColorlibStepIconRoot } from '@pages/Setup/UserSetup/SetupStepper/ColorStepIcon'
import { getLocalStorageCompany } from '@utils/localStorage/company'


const ColorlibStepIcon = (props: StepIconProps) => {
    const { active, completed, className } = props

    const company = getLocalStorageCompany()

    const icons: { [index: string]: React.ReactElement } = {
        1: <SettingsIcon />,
        2: <GroupAddIcon />,
        3: <StoreIcon/>,
        4: company.is_xero_company ? <img src={XeroLogo} alt="A blue Xero logo" width="35px"/> : <LockOpenIcon/> ,
        5: <LockOpenIcon/>,
    }

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    )
}

export default ColorlibStepIcon