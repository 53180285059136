import {useState} from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'

import { getLocalStorageUser } from '@utils/localStorage/user'

import INastixAvatar from './INastixAvatar'
import EditProfile from './EditProfile'


export interface ProfileSectionStylesObject {
    profile_box: object
    username: object
    group_name: object
}

interface ProfileSectionProps {
    styles?: ProfileSectionStylesObject
    hide_edit_profile?: boolean
}

const ProfileSection = (props: ProfileSectionProps) => {
    const {styles, hide_edit_profile=false} = props

	const { t } = useTranslation('settings')

    const user = getLocalStorageUser()

    const [profile_picture, setProfilePicture] = useState('')

    return (
        <>
        <Box sx={{display: 'flex', gap: '1rem', alignItems: 'center', marginBottom: 1, ...styles?.profile_box}}>
            <INastixAvatar profile_picture={profile_picture} setProfilePicture={setProfilePicture}/>
            <Box>
                <Tooltip title={user.username}>
                    <Typography variant='h5' fontWeight={'700'} sx={{overflow: 'hidden', textOverflow: 'ellipsis', fontSize: '16px', marginRight: '3rem', ...styles?.username}}> {user.username} </Typography>
                </Tooltip>
                {user.groups.length ? <Typography variant='caption' sx={{...styles?.group_name}}> {t([user.connected_group])} </Typography> : t('you_are_not_in_a_group')}
            </Box>
        </Box>
        {!hide_edit_profile && <EditProfile setProfilePicture={setProfilePicture}/>}
        </>
    )
}

export default ProfileSection