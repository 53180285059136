import { CRUDAction } from "@actions/Actions/actions"
import ChildPaymentsHandler from "@adapters/handlers/FinanceHandlers/PaymentHandlers/childPaymentHandlers"


class ChildPaymentsActions extends CRUDAction {
    handler: ChildPaymentsHandler

    constructor() {
        super()
        this.handler = new ChildPaymentsHandler()
    }    
}

export default ChildPaymentsActions