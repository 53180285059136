enum AuthorizedStatus {
    AUTHORIZED = 1,
    PENDING = 2,
    UNAUTHORIZED = 3
}

const AuthorizedStatusMap = {
    1: "Authorised",
    2: "Pending",
    3: "Unauthorized"
}

export {AuthorizedStatus, AuthorizedStatusMap}