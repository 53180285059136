import { styled } from '@mui/material/styles'
import Typography, {TypographyProps} from '@mui/material/Typography'
import Box, {BoxProps} from '@mui/material/Box'
import Grid, {GridProps} from '@mui/material/Grid'
import Button, { ButtonProps } from '@mui/material/Button'


export const MainHeadingTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
    fontWeight: 700,
    color: 'darkslategray'
}))


export const SetupBox = styled(Box)<BoxProps>(() => ({
    display: 'flex',
    flexDirection: 'column', 
    justifyContent: 'flex-start',
    alignItems: 'center',
    height: '100%',
    marginTop: '0rem !important',
}))


export const SetupFormGrid = styled(Grid)<GridProps>(() => ({
    marginBottom: '1.5rem',
}))


export const SetupButon = styled(Button)<ButtonProps>(() => ({
    marginLeft: 'auto'
}))


export const SetupHeadingTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
    fontSize: theme.typography.h4.fontSize,
    fontWeight: 'bold',
    color: 'darkslategray',
    textAlign: 'center'
}))


export const SetupSubHeadingTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
    fontSize: theme.typography.h6.fontSize,
    fontWeight: '600',
    color: 'darkslategray',
    margin: '2rem 0 1.5rem 0'
}))