import { BaseAction } from "@actions/Actions/actions"
import FZoneHandler from "@adapters/handlers/FZoneHandlers/fZoneHandlers"


class FZoneActions extends BaseAction {
    handler: FZoneHandler
    
    constructor() {
        super()
        this.handler = new FZoneHandler()
    }

    viewFzone = (): Promise<any> => {
        return this.handler.viewFzone()
    }
}

export default FZoneActions