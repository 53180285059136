import {useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Tooltip from '@mui/material/Tooltip'
import { Typography } from '@mui/material'
import InfoIcon from '@mui/icons-material/Info'

import Form, { FormDataEntryType } from "@components/forms/Form"
import SchoolActions from '@actions/CRUDActions/SchoolActions/schoolActions'
import { getLocalStorageCompany, updateLocalStorageCompany } from '@utils/localStorage/company'
import { CompanySetupStepContext } from '@contexts/CompanySetupStepContext'
import { MessageContext } from '@contexts/MessageContext'

import log from 'loglevel'

import SelectAnExistingConnectingSchoolCompany from './SelectFranchise'


export interface CompanyLabel {
    label: string
    company_uuid: string
}

export type SetCompanies = React.Dispatch<React.SetStateAction<CompanyLabel[]>>


const ChooseConnectedSchoolCompany = () => {
    const { t } = useTranslation('set_up')

    const {setCompanySetupStep} = useContext(CompanySetupStepContext)
    const {setMessage} = useContext(MessageContext)

    const [company, setCompany] = useState<CompanyLabel | null>(null)

    const selectedCompanyCallback = (selected_company: CompanyLabel | null) => {
        setCompany(selected_company)
    }
      
    const createFields = () => {
        return (
            <>
            <Box sx={{display: 'flex', justifyContent: 'space-evenly', textAlign: 'center'}}>
                <Box sx={{ width: 250 }}>
                    <Typography sx={{fontSize: '1.5rem', color: 'darkslategray'}} gutterBottom>
                        {t('choose_company') }
                    </Typography>
                    <Typography sx={{fontSize: '0.8rem', color: 'darkslategray'}}>
                        {t('choose_from_the_list_of_already_connected')}
                        {t('companies')}
                        <Tooltip title={t('demo_orgs_will_not_show_here')}>
                            <InfoIcon fontSize='small' sx={{marginLeft: '0.3rem', transform: 'translateY(5px)'}}/>
                        </Tooltip>
                    </Typography>
                    <br/>
                    <SelectAnExistingConnectingSchoolCompany selectedCompanyCallback={selectedCompanyCallback}/>
                    <Button variant='contained' sx={{marginTop: '1rem'}} type="submit"> {t('submit')} </Button>
                    <Tooltip sx={{textAlign:'center'}} title={t('if_franchise_is_not_listed')}>
                        <Typography marginTop={'1rem'} fontSize={'0.7rem'} sx={{ '&:hover': { textDecoration: 'underline' } }}>
                            {t('dont_see_the_franchise_here')}
                        </Typography>
                    </Tooltip>
                </Box>  
            </Box>
            </>
        )
    }

    const onSubmit = async (form_data_object: FormDataEntryType): Promise<any> => {
        log.info(form_data_object)

        if (company?.company_uuid) {
            const school_actions = new SchoolActions()
            return school_actions.setConnectAFranchiseSetup({connected_company: company.company_uuid})
            .then(() => {
                const company = getLocalStorageCompany()
                updateLocalStorageCompany('setup_step', company.setup_step + 1)
                setCompanySetupStep(prop => prop + 1)
            })
            .catch(error => {
                log.error(error)
                setMessage(props => ({...props, error: t('something_went_wrong_please_refresh_and_try_again')}))
            })
        }
        else
            setMessage(props => ({...props, error: t('choose_a_franchise_before_continuing')}))
	}

    return (
		<Form onSubmitCallback={onSubmit} createFields={createFields}/>
    )
}

export default ChooseConnectedSchoolCompany