import { CRUDAction } from "@actions/Actions/actions"
import InastixSystemHandler from "@adapters/handlers/CRUDHandlers/InastixSystemHandlers/instructorDashboardHandlers"


class InastixSystemActions extends CRUDAction {
    handler: InastixSystemHandler

    constructor() {
        super()
        this.handler = new InastixSystemHandler()
    }
}

export default InastixSystemActions