import { getLocalStorageUser, updateLocalStorageUser } from "@utils/localStorage/user"

import ChooseUserType from "../ChooseUserType"
import UserDetails from "../UserDetails"
import GetAuthorized from "../GetAuthorized"
import SelectCompany from "../SelectCompany"


const GetStepComponent = (active_step: number) => {
    
    switch (active_step) {
        case 0:
            return <UserDetails/>
        case 1:
            return <ChooseUserType/>
        case 2:
            // send user to next step of they choose company setup but their stepper value is shown to be here on choose company
            const user = getLocalStorageUser()
            if (user.is_choose_company_setup) {
                updateLocalStorageUser('setup_stepper', user.setup_step + 1)
                return <GetAuthorized/>
            }
            return <SelectCompany/>
        case 3:
            return <GetAuthorized/>
        default:
            return 'Unknown stepIndex'
    }
}

export default GetStepComponent