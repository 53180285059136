import {useState, useCallback, useEffect} from 'react'
import { useTranslation } from 'react-i18next'
import {useDropzone} from 'react-dropzone'

import IconButton from '@mui/material/IconButton'
import FileOpenIcon from '@mui/icons-material/FileOpen'

import FormHelperText from '@mui/material/FormHelperText'


interface INastixFileUploadProps {
    initial_file?: File | null
    setSelectedFileCallback: (file: File) => void
    label?: string
    helper_text?: string
}

const INastixFileUpload = (props: INastixFileUploadProps) => {
    const {initial_file, setSelectedFileCallback, label, helper_text} = props

    const{ t } = useTranslation('user_setup')

    const [selected_file, setSelectedFile] = useState<File | null>(null)
    const [is_hovered, setIsHovered] = useState(false)
    const [is_file_type_error, setIsFileTypeError] = useState(false)

    useEffect(() => {
        if (initial_file) {
            setSelectedFile(initial_file)
        }
    }, [initial_file, setSelectedFileCallback])

    const onDrop = useCallback((accepted_files: File[]) => {
        const file = accepted_files[0]
        setIsFileTypeError(false)

        if (file.type !== 'application/pdf' && file.type !== 'application/zip') {
            setIsFileTypeError(true)
        }
        else {
            setSelectedFile(file)
            setSelectedFileCallback(file)
        }
    }, [setSelectedFileCallback])
        
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        maxFiles: 1, // Allow only one file to be uploaded
    })

    const handleMouseEnter = () => setIsHovered(true)
    const handleMouseLeave = () => setIsHovered(false)

    const handleOpenFile = () => {
        if (!selected_file)
            return

        const file_url = URL.createObjectURL(selected_file)

        if (file_url) {
            window.open(file_url, '_blank')
        }
    }

    return (
        <div>
            {label && <label htmlFor="fileInput" style={{...dropzone_label}}> {label} </label>}
            <div 
                {...getRootProps()} 
                style={{
                    ...dropzone_styles,
                    ...(is_hovered ? dropzone_hover_styles : {}), 
                    ...(selected_file ? has_file_in_dropzone: {}), 
                    ...(is_file_type_error ? dropzone_file_error: {}), 
                    textAlign: 'center'
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <input {...getInputProps()} id="fileInput"/>
                {selected_file ? 
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <p 
                            style={{
                                color: 'forestgreen',
                                width: '250px',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}>
                                {selected_file.name}
                        </p> 
                        <IconButton onClick={handleOpenFile} style={{ marginLeft: '8px' }}>
                            <FileOpenIcon />
                        </IconButton>
                    </div>
                    : <p>{t('drag_and_drop')}</p>
                }
            </div>
            {is_file_type_error && <p style={{color: 'red'}}> {t('file_type_error')} </p>}
            {helper_text && <FormHelperText>{helper_text}</FormHelperText>}
        </div>
    )
}

const dropzone_label = {
    marginBottom: '8px',
    color: 'darkslategray',
    fontSize: '1.1rem',
    fontWeight: '500',
    display: 'block'
}
const dropzone_styles = {
    transition: 'background-color 0.3s ease', 
    border: '2px dashed #cccccc', 
    borderRadius: '4px', 
    padding: '0.5rem 2rem', 
    cursor: 'pointer', 
    color: 'darkslategrey'
}
const dropzone_hover_styles = {backgroundColor: 'rgba(169,169,169,0.5)', color: 'white'}
const has_file_in_dropzone = {border: '2px solid forestgreen'}
const dropzone_file_error = {border: '2px solid red'}


export default INastixFileUpload
