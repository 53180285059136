import { LocalStorageUser } from "@utils/interfaces/interfaces"


const getLocalStorageUser = (): LocalStorageUser => {
    const user_json = localStorage.getItem("user")

    // if (process.env.REACT_APP_ENVIRONMENT !== 'production' && user_json === undefined) {
    //     alert('Function called with undefined user_json')
    //     const error = new Error()
    //     const stackLines = error.stack?.split('\n')
    //     if (stackLines && stackLines.length >= 3) {
    //         const callerLine = stackLines[2]
    //         const fileNameMatches = callerLine.match(/\s\((.*\.ts)\)/);
    //         if (fileNameMatches && fileNameMatches.length >= 2) {
    //             const fileName = fileNameMatches[1]
    //             alert(`Function called from file: ${fileName}`)
    //         }
    //     }
    // }

    const user = user_json ? JSON.parse(user_json) : {}

    return user
}

const updateLocalStorageUser = (key: string, value: any): LocalStorageUser => {
    const user = getLocalStorageUser()
  
    if (value !== undefined && value !== null) {
        const updated_user = { ...user, [key]: value }
        try {
            localStorage.setItem('user', JSON.stringify(updated_user))
        } catch (error) {
            // if updated_user is cannot be convert to JSON just ignore it.
            console.error('Error updating local storage:', error)
        }
        return updated_user
    }
    return user
}


export {getLocalStorageUser, updateLocalStorageUser}