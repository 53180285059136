import {useContext, useEffect, useState} from 'react'
import { useTranslation } from 'react-i18next'

import { TextField, Button, InputLabel, MenuItem, FormControl } from '@mui/material'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Box from '@mui/material/Box'
import { DialogFormSubHeadingTypography } from '@styles/Typography'
import { DialogFormControl } from '@styles/Dialog'

import PhoneNumber from "@pages/Setup/UserSetup/UserDetails/PhoneNumber"
import { Parent, School } from '@utils/interfaces/interfaces'
import ParentActions from '@actions/CRUDActions/ParentActions/parentActions'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { MessageContext } from '@contexts/MessageContext'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import { companyIsInGroup } from '@utils/utils/util'
import { CompanyType } from '@utils/enums/enums'
import HandleParentSchools from '../ParentForm/HandleParentSchools'
import SchoolActions from '@actions/CRUDActions/SchoolActions/schoolActions'

interface ParentProps {
    onCreateCallback?: (value: boolean, parent: Parent | null) => void
}

const ParentDetails = (props: ParentProps) => {
    const {onCreateCallback} = props
    const { t } = useTranslation('parents_form')
    const {setMessage} = useContext(MessageContext)
    const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)

    const company = getLocalStorageCompany()
    const [is_school, setIsSchool] = useState(false)
    const [parent_name, setParentName] = useState('')
    const [parent_phone, setParentPhone] = useState('')
    const [parent_country_code, setParentCountryCode] = useState('ZA +27')
    const [parent_email, setParentEmail] = useState('')
    const [prefferred_contact, setPreferredContact] = useState('')
    const [parent_schools, setParentSchools] = useState<School[]>([])
    const [schools, setSchools] = useState<School[]>([])

    useEffect(() => {
        setIsSchool(companyIsInGroup(company, CompanyType.SCHOOL))

        const school_actions = new SchoolActions()
        school_actions.get()
        .then((data:School[]) => {
            setSchools(data)
        })
    }, [change_company_rerender])

    const setPhoneNumberCallback = (number: string) => {
        setParentPhone(number)
    }  

    const setCountryCodeCallback = (country_code: string) => {
        console.log(country_code)
        setParentCountryCode(country_code)
    } 

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setParentName(event.target.value as string)
    }

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setParentEmail(event.target.value as string)
    }

    const handlePreferenceChange = (event: SelectChangeEvent) => {
        setPreferredContact(event.target.value as string)
    }

    const onSelectSchoolCallback = (selected_schools: School[]) => {
        setParentSchools(selected_schools)
    }

    const handleCreateSubmit = () => {
        
        let school_ids = []
        for (let i = 0; i < parent_schools.length; i++) {
            school_ids.push(parent_schools[i].tenant)
        }
        let parent_data = {
            'name': parent_name,
            'country_code': parent_country_code,
            'phone_number': parent_phone,
            'email': parent_email,
            'preferred_means_of_contact': prefferred_contact
        }
        let school_data = {}
        if (is_school) {
            school_data = {'school': company.company_uuid}
        } else {
            if (school_ids.length === 0) {
                setMessage(props => ({...props, error: `Please select at least one school`}))
                return
            } else {
                school_data = {
                    'tenant': company.company_uuid,
                    'schools': school_ids.toString()
                }
            }
        }
        let data = {
            ...parent_data,
            ...school_data
        }
        
        console.log(data)
        if(data["name"] === '' || data["phone_number"] === '' || data["preferred_means_of_contact"] === '') {
            setMessage(props => ({...props, error: `Please fill in all the fields`}))
            return
        } else {
            const parent_actions = new ParentActions()
            parent_actions.create(data)
            .then(parent_data => {
                console.log(parent_data)
                if(onCreateCallback)
                    onCreateCallback(false, parent_data)
            })
            .catch(error => {
                console.log(error)
                setMessage(props => ({...props, error: `Parent email already exists.`}));
            });
        }
    }

    const cancelCreateParent = () => {
        if(onCreateCallback)
            onCreateCallback(false, null)
    }

    return(
        <DialogFormControl>
            <br/>
            <br/>
            <DialogFormSubHeadingTypography variant="h3"> {t('parent_details')} </DialogFormSubHeadingTypography>
            <TextField 
                label={t('name')}
                name="name"
                variant="outlined"
                value={parent_name}
                onChange={handleNameChange}
                sx={{marginBottom: 2}}
            />
            <TextField
                label={t('email')}
                name="email"
                variant="outlined"
                value={parent_email}
                onChange={handleEmailChange}
                sx={{marginBottom: 2}}
                fullWidth
            />
            <PhoneNumber setPhoneNumberCallback={setPhoneNumberCallback} setCountryCodeCallback={setCountryCodeCallback} required={false}/>
            <br/>
            <FormControl fullWidth>
                <InputLabel id="preferred-contact-label">{t('preferred_means_of_contact')}</InputLabel>
                <Select
                    labelId="preferred-contact-label"
                    id="preferred-contact"
                    value={prefferred_contact}
                    label={t('preferred_means_of_contact')}
                    onChange={handlePreferenceChange}
                    fullWidth
                    required
                >
                    <MenuItem value={'email'}>{t('email')}</MenuItem>
                    <MenuItem value={'sms'}>{t('phone')}</MenuItem>
                </Select>
            </FormControl>
            <br/>
            { !is_school && <HandleParentSchools onSelectCallback={onSelectSchoolCallback} schools={schools} parent_schools={parent_schools}/>}
            <Box display={'flex'} flexWrap={'wrap'} columnGap={'1rem'}>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={cancelCreateParent}
                    sx={{flexBasis: '30%'}}
                >
                    {t('cancel')}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCreateSubmit}
                    sx={{flexGrow: 1}}
                >
                    {t('create_parent')}
                </Button>
            </Box>
        </DialogFormControl>
    )
}

export default ParentDetails