import {useEffect, useState} from 'react'

import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'

import EarthIcon from '@images/icons/earth.png'

import { CountriesOptions } from '@utils/arrays/countries'


interface ChooseCountryProps {
    setSelectedCountryCallback?: (selected_country: CountryOption | null) => void
    helper_text?: string
    country_id?: string
}

export interface CountryOption {
    code: string
    label: string
}

const ChooseCountry = (props: ChooseCountryProps) => {
    const {setSelectedCountryCallback, helper_text, country_id} = props

    const [selected_country, setSelectedCountry] = useState<CountryOption | null>(null)

    const handleCountryChange = (event: React.ChangeEvent<{}>, value: CountryOption | null) => {
        setSelectedCountry(value)
        if (setSelectedCountryCallback)
            setSelectedCountryCallback(value)
    }

    useEffect(() => {
        for (let i = 0; i < CountriesOptions.length; i++) {
            if (country_id !== undefined) {
                if (CountriesOptions[i].id.toString() === country_id.toString()) {
                    setSelectedCountry(CountriesOptions[i])
                    if (setSelectedCountryCallback) 
                        setSelectedCountryCallback(CountriesOptions[i])
                }
            }
        }
    }, [country_id, setSelectedCountryCallback])

    return (
        <Autocomplete
            disablePortal
            id="combo-box-countries"
            options={CountriesOptions}
            value={selected_country} 
            onChange={handleCountryChange}
            sx={{ width: '220px' }}
            renderOption={(props, option) => (
                <Box key={option.code} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    {option.code === 'Earth' ? 
                        <img loading='lazy' width={"20"} src={EarthIcon} alt={"Earth/globe flag"}/>
                    :
                        <img
                            loading="lazy"
                            width="20"
                            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                            alt={`${option.label} flag`}
                        />
                    }
                    {option.label}
                </Box>
            )}
            renderInput={(params) => (
                <TextField 
                    {...params}
                    required 
                    name="country" 
                    label="Choose a country" 
                    helperText={helper_text}
                />
            )}
        />
    )
}

export default ChooseCountry