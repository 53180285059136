import {useEffect, useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
} from 'chart.js'
import { Pie } from 'react-chartjs-2'

import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import ChildActions from '@actions/CRUDActions/ChildActions/childActions'

import { ChartBox } from '../styles'
import ToggleChartVisibility from '../ToggleChartVisibility'
import { DashbaordStatsProps } from './interfaces'


ChartJS.register(
    ArcElement,
    Tooltip,
    Legend
)

type ChildrenCertifcatesCountData = {
    emailed_to_parents: number
    whatsapped_to_parents: number
    emailed_to_schools: number
    printed: number
}


const ChildrenCertificatesStat = (props: DashbaordStatsProps) => {
    const {edit_dashboard_open, checked, setChecked, selected_franchise_option, selected_school_type_options} = props
    
    const { t } = useTranslation('dashboard')
    const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)

    const emailed_to_parents_label = "Emailed to parents"
    const whatsapped_to_parents_label = "Whatsapped to parents"
    const emailed_to_schools_label = "Emailed to schools"
    const printed_label = 'Printed'

    const labels = [emailed_to_parents_label, emailed_to_schools_label, whatsapped_to_parents_label, printed_label]

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: "Certificates",
            },
        },
    }

    const [data, setData] = useState<any>({labels, datasets: []})
    const [children_certificates_total, setChildrenCertificatesTotal] = useState<number>(0)

    useEffect(() => {
        if (!selected_franchise_option)
            return

        const setChildrenBirthdayStat = async () => {
            const child_actions = new ChildActions()
            
            const data: any = {
                franchise_id: selected_franchise_option.id,
            }
            if (selected_school_type_options)
                data['selected_school_type_options'] = selected_school_type_options

            const c_c_count_data: ChildrenCertifcatesCountData = await child_actions.childrenCertificatesStat(data)
            setChildrenCertificatesTotal(c_c_count_data.emailed_to_parents + c_c_count_data.emailed_to_schools + c_c_count_data.whatsapped_to_parents + c_c_count_data.printed)
            setData({
                labels,
                datasets: [
                    {
                        label: "Total",
                        data: [c_c_count_data.emailed_to_parents, c_c_count_data.emailed_to_schools, c_c_count_data.whatsapped_to_parents, c_c_count_data.printed],
                        backgroundColor: [
                            'rgba(53, 162, 235, 0.7)',
                            'rgba(255, 99, 132, 0.5)',
                            'rgba(53, 162, 235, 0.3)',
                            'rgb(85, 133, 222)'
                        ],
                    }
                ],
            })
        }
        setChildrenBirthdayStat()

    }, [selected_franchise_option, selected_school_type_options, change_company_rerender])

    return (
        <>
        <ChartBox edit_dashboard_open={edit_dashboard_open} checked={checked}>
            <Pie options={options} data={data} />
            <p style={{margin: 0, color: 'darkslategrey', fontStyle: 'italic', fontWeight: '100', textAlign: 'right', marginRight: '2rem'}}> 
                Total: <span style={{fontStyle: 'normal'}}>{children_certificates_total}</span>
            </p>
            <canvas style={{ display: 'inline', height: 0 }} />
        </ChartBox>
        {edit_dashboard_open && 
            <>
            <ToggleChartVisibility checked={checked} setChecked={setChecked}/>
            </>
        }
        </>
    )
}

export default ChildrenCertificatesStat
