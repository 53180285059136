import { CSSProperties } from 'react'


const memberHandoutsTableStyles: { [key: string]: CSSProperties } = {
    spanningHeaderCell: {
        border: '1px solid gray',
        padding: '2px 4px',
        fontSize: '0.8rem',
        textAlign: 'center',
    },
    cell: {
        border: '1px solid gray',
        padding: '4px 4px',
        textAlign: 'right',
        color: 'gray', 
        fontSize: '0.7rem',
    },
    bracketText: {
        display: 'block',
        fontSize: '0.6rem'
    },
    mainResultValue: {
        borderBottom: '5px double #000', borderTop: '2px solid #000'
    },
    greyedOutDiagonal: {
        backgroundImage: 'repeating-linear-gradient(45deg, #ccc, #ccc 10px, transparent 10px, transparent 20px)'
    }
}

export {
    memberHandoutsTableStyles
}