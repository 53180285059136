import React, {useEffect, useState} from 'react'

import Box from '@mui/material/Box'

import StateManager from '@actions/StateManager/stateManager'
import { DialogComponent } from '@components/tables/Table/Table'


export interface TableProps {
	dialogs?: Array<DialogComponent>
	single_selection_dialogs?: Array<DialogComponent>
	no_selection_dialogs?: Array<DialogComponent>
	table_selections: TableSelections
	state_manager: StateManager
}

type TableSelections = {
	row_selection_model: Array<any>
	selections: Array<any>
}

export type SetTableSelections = React.Dispatch<React.SetStateAction<TableSelections>>


// DataGrid docs => https://mui.com/x/react-data-grid
const CustomTable = (props: TableProps) => { 
	const {dialogs, single_selection_dialogs, no_selection_dialogs, table_selections, state_manager} = props

	const [multiple_selection_dialog_disable, setMultipleSelectionDialogDisable] = useState(true)
	const [single_selection_dialog_disable, setSingleSelectionDialogDisable] = useState(true)
	const [no_selection_dialog_disable, setNoSelectionDialogDisable] = useState(true)

	useEffect(() => {
		if (table_selections.selections.length > 0) {
			setMultipleSelectionDialogDisable(false)
			setNoSelectionDialogDisable(true)	
		}
		else {
			setMultipleSelectionDialogDisable(true)
			setNoSelectionDialogDisable(false)
		}
		if (table_selections.selections.length === 1) {
			setSingleSelectionDialogDisable(false)
		}
		else {
			setSingleSelectionDialogDisable(true)
		}
	}, [table_selections.selections.length])

	return (
		<>
		<Box bgcolor="#eaf0fa" sx={{display: 'flex', justifyContent: 'right', gap: '1rem', padding: 1, flexWrap: 'wrap'}}>
			<Box sx={{flexGrow: 1}}></Box>
			{dialogs && dialogs.map((Dialog, index) => (
				<Dialog
					disabled={multiple_selection_dialog_disable}
					key={index}
					ids={table_selections.row_selection_model}
					state_manager={state_manager}
				/>
			))}
			{/* // user should only be able to edit one element at a time
			// we can index by 0 because 'selections' should only have one element inside */}
			{single_selection_dialogs && single_selection_dialogs.map((DialogHandler, index) => (
				<DialogHandler
					disabled={single_selection_dialog_disable}
					key={index}
					ids={table_selections.row_selection_model}
					state_manager={state_manager}
				/>
			))}
			{no_selection_dialogs && no_selection_dialogs.map((DialogHandler, index) => (
				<DialogHandler
					disabled={no_selection_dialog_disable}
					key={index}
					ids={[]}
					state_manager={state_manager}
				/>
			))}
		</Box>
		</>
	)
}


export default CustomTable