import { useTranslation } from 'react-i18next'

import { CRUDAction } from '@actions/Actions/actions'
import ParentForm from '@components/forms/ParentForm'
import Table from '@components/tables/Table/Table'
import { getLocalStorageUser } from '@utils/localStorage/user'
import { companyIsInGroup, permissionValid } from '@utils/utils/util'
import { UserPermissions } from '@utils/enums/permissions'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { CompanyType } from '@utils/enums/enums'

export interface TableProps {
	action: CRUDAction
}

const ParentTable = (props: TableProps) => {
    const {action} = props
    const { t } = useTranslation('parents')

    const user = getLocalStorageUser()
	const company = getLocalStorageCompany()

	const is_master_franchise_doesnt_act_like_franchise_or_franchisor = !company.acts_like_a_franchise && companyIsInGroup(company, [CompanyType.MASTER_FRANCHISE, CompanyType.FRANCHISOR])

    const can_add = !is_master_franchise_doesnt_act_like_franchise_or_franchisor && permissionValid(user, UserPermissions.CREATE_PARENTS)
	const can_edit = !is_master_franchise_doesnt_act_like_franchise_or_franchisor && permissionValid(user, UserPermissions.EDIT_PARENTS)
	const can_delete = !is_master_franchise_doesnt_act_like_franchise_or_franchisor && permissionValid(user, UserPermissions.DELETE_PARENTS)

    const columns = [
        { field: 'id', headerName: t('id') },
		{ field: 'name', headerName: t('name'), minWidth: 150, flex: 0.2 },
		{ field: 'email', sortable: false, headerName: t('email'), minWidth: 200, flex: 0.2 },
		{ field: 'full_phone_number', sortable: false, headerName: t('phone_number'), minWidth: 180, flex: 0.2 },
		{ field: 'preferred_means_of_contact', sortable: false, headerName: t('preferred_means_of_contact'),  minWidth: 150, flex: 0.2 },
    ]

	const paginationFilterParser = (field: string, value: string) => {
		let custom_operator = undefined
        
		if (field === 'full_phone_number') {
			field = 'phone_number'
		}
		return {field, value, custom_operator}
	}

    const paginationSortParser = (field: string) => {
		return field
	}

    return (
        <Table
            action={action}
            Form={ParentForm}
            columns={columns}
            can_add={can_add}
            can_edit={can_edit}
            can_delete={can_delete}
			use_pagination={true}
            page_size={100}
			amount_of_rows_selection={[100]}
            paginationFilterParser={paginationFilterParser}
            paginationSortParser={paginationSortParser}
        />
    )
}

export default ParentTable