import {useContext} from 'react'

import UserActions from "@actions/CRUDActions/UserActions/userActions"
import CompanyActions from "@actions/CompanyActions/companyActions"
import { getLocalStorageUser, updateLocalStorageUser } from "@utils/localStorage/user"
import { CompanySetupStepContext } from '@contexts/CompanySetupStepContext'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import { LocalStorageCompany, UserGroup } from '@utils/interfaces/interfaces'


const useUpdateUsersConnectedCompany = () => {
    const { setCompanySetupStep } = useContext(CompanySetupStepContext)
    const { setChangeCompanyRerender } = useContext(ChangeCompanyRerenderContext)

    const updateUsersConnectedCompany = (company_uuid: string) => {
        const user = getLocalStorageUser()

        const user_actions = new UserActions()
        user_actions.update({ ...user, company_uuid: company_uuid }, user.id.toString())
        .then(() => {
            updateLocalStorageUser('company_uuid', company_uuid);

            const company_actions = new CompanyActions()
            company_actions.getConnectedCompany()
            .then((company: LocalStorageCompany) => {
                const user_group: UserGroup = user.groups.filter(group => group.tenant === company.company_uuid)[0]  // index by 0 because will be unique
                updateLocalStorageUser('connected_group', user_group.name)

                localStorage.setItem('company', JSON.stringify(company))
                setCompanySetupStep(company.setup_step)
                setChangeCompanyRerender(prev => !prev)
            })
        })
    }

    return updateUsersConnectedCompany
}

export {useUpdateUsersConnectedCompany}

