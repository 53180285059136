import {useEffect, useContext, useState} from 'react'
import { useNavigate } from 'react-router-dom'

import InastixWord from '@images/inastix/inastix_word.png'

import log from 'loglevel'

import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import Divider from '@mui/material/Divider'
import { useTheme } from '@mui/material/styles'
import Grid from '@mui/material/Grid'

import AuthFetchWrapper from '@adapters/api/fetchWrappers/authFetchWrapper'
import Form, { FormDataEntryType } from '@components/forms/Form'
import { AuthenticationAction } from '@actions/Actions/actions'
import { IsLoggedInContext } from '@contexts/IsLoggedInContext'
import { JWT_LOGIN_ENDPOINT } from '@adapters/routes/endpoints'
import XeroSignIn from '@components/authentication/XeroSignIn'
import { MessageContext } from '@contexts/MessageContext'
import { SetupStepContext } from '@contexts/SetupStepContext'
import { NumOfRequestsContext } from '@contexts/NumOfRequestsContext'
import { LocalStorageCompany } from '@utils/interfaces/interfaces'
import { logout } from '@adapters/helpers/logout'
import { LocalstorageUserExistsContext } from '@contexts/LocalstorageUserExistsContext'
import { LocalstorageCompanyExistsContext } from '@contexts/LocalstorageCompanyExistsContext'
import NetworkStatus from '@components/checks/NetworkStatus'

import { handleCompanyLocalStorageInit, handleLogin } from '../helper/helper'
import { AuthTypography } from '../util/StyledComponents'


const Login = () => {
    const navigation = useNavigate()
    const theme = useTheme()

	const {setIsLoggedIn} = useContext(IsLoggedInContext)
	const {setMessage} = useContext(MessageContext)
	const {setSetupStep} = useContext(SetupStepContext)
    const {setNumOfRequests} = useContext(NumOfRequestsContext)
    const {setLocalstorageUserExists} = useContext(LocalstorageUserExistsContext)
    const {setLocalstorageCompanyExists} = useContext(LocalstorageCompanyExistsContext)

	const [is_online, setIsOnline] = useState(false)
    const [is_server_online, setIsServerOnline] = useState(true)

    useEffect(() => {
        if (!is_online || !is_server_online)
            return

        // Load reCAPTCHA script when the component mounts
        const script = document.createElement('script')
        script.src = 'https://www.google.com/recaptcha/api.js?render=6LeSCWUmAAAAAC6acEwKoQoAcbpg_Do1RIesd0zt'
        script.async = true
        document.body.appendChild(script)

        return () => {
            // Cleanup: Remove the script when the component unmounts
            document.body.removeChild(script)
        }
    }, [is_online, is_server_online])

    // used to handle Xero sign in callback
    useEffect(() => {
        const params = new URLSearchParams(window.location.search)
        const code = params.get('code')

        if (code) {
            setNumOfRequests(props => props + 1)

            const fetch_wrapper = new AuthFetchWrapper()
            fetch_wrapper.post('auth/xero/sign-in-callback', {code: code})
            .then(user_jwt => {
                localStorage.setItem('signed_in_with_xero', "true")
                const company: LocalStorageCompany = user_jwt?.tenant
                if (company)
                    handleCompanyLocalStorageInit(company)

                handleLogin(user_jwt, setSetupStep, setIsLoggedIn)
            })
            .catch(error => {
                log.error(error.message)
                if (error.message.toLowerCase().includes("failed to fetch"))
                    logout(navigation, setIsLoggedIn, setLocalstorageUserExists, setLocalstorageCompanyExists)
                else
                    setMessage(props => ({...props, error: error.message}))
            })
            .finally(() => {
                setNumOfRequests(props => props - 1)
            })
        }
    }, [navigation, setIsLoggedIn, setMessage, setSetupStep, setNumOfRequests, setLocalstorageUserExists, setLocalstorageCompanyExists])

    const createFields = () => {
        return (
            <FormControl sx={{width: '300px', marginBottom: 2}}>
            <TextField
                required
                id="email"
                name="email"
                label="Email"
                placeholder="example@email.com"
                autoComplete="email"
            />
            <br/>
            <TextField
                required
                id="password"
                name="password"
                label="Password"
                type="password"
                autoComplete="current-password"
            />
            <Typography variant='caption' mt={2} ml={'auto'}><Link href="/account/reset"><i>Forgot password?</i></Link></Typography>
            <Button type="submit" variant='contained' sx={{marginTop: '2rem'}}> login </Button>
            </FormControl>
        )
    }

    const onSubmit = async (form_data_object: FormDataEntryType): Promise<any> => {
        if (!is_online && !is_server_online) {
            setMessage(props => ({...props, form_error: "Check your network connection"}))
            return
        }
            
        setMessage(props => ({...props, form_error: ""}))
        // Call the Google reCAPTCHA API to get the response token
        const token = await window.grecaptcha.execute('6LeSCWUmAAAAAC6acEwKoQoAcbpg_Do1RIesd0zt', { action: 'submit' })

        // Here, you can send the token to the backend for verification if required.
        // Append the reCAPTCHA token to the FormData
        const updatedFormDataObject = {
            ...form_data_object,
            recaptcha_token: token,
        }

        const authentication_action = new AuthenticationAction()
        return authentication_action.authenticate(JWT_LOGIN_ENDPOINT, updatedFormDataObject)
		.then(user_jwt => {
            localStorage.setItem('signed_in_with_xero', "false")
            handleLogin(user_jwt, setSetupStep, setIsLoggedIn)
		})
        .catch(error => {
            log.error(error)
            setMessage(props => ({...props, form_error: error.message}))
        })
	}

    const isOnlineCallback = (is_online: boolean) => {
        setIsOnline(is_online)
    }

    const isServerOnlineCallback = (is_server_online: boolean) => {
		setIsServerOnline(is_server_online)
	}

    return (
        <>
        <NetworkStatus isOnlineCallback={isOnlineCallback} isServerOnlineCallback={isServerOnlineCallback}/>
        <Grid container alignItems="center" height={'100vh'} sx={{gap: { xs: '1rem', md: 0 }}}>
            <Grid item md={6} sm={12} xs={12}  sx={{backgroundColor: theme.palette.primary.main, height:'100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: '3rem'}}>
                <div style={{position: 'relative'}}>
                    <Typography sx={{position: 'absolute', right: 0, top: '-3px', color: 'white', fontSize: '0.9rem'}}> Welcome to </Typography> 
                    <img src={InastixWord} width="230ch" alt="iNastix logo"/>
                </div>
                {/* DONT REMOVE. ADD BACK IN WHEN INSTALL APP IS WORKING */}
                {/* <div style={{textAlign: 'center'}}>
                    <InstallApp text_1={"iNastix is available as an app!"} text_2={"Click the install button below."}/>
                </div> */}
            </Grid>
            <Grid item md={6} sm={12} xs={12} padding={2} textAlign={'center'} width={'100%'}>
                <AuthTypography variant='h1' mb={5}> Login </AuthTypography>
                <Form onSubmitCallback={onSubmit} createFields={createFields}/>
                <Typography variant='caption' sx={{display: 'block'}}> Don't have an iNastix account yet? </Typography>
                <Link href="/signup" sx={{display: 'block', width: 'fit-content', margin: 'auto'}}> Sign up </Link>
                <br/>
                <Divider>or</Divider>
                <br/>
                <XeroSignIn/>
            </Grid>
        </Grid>
        </>
    )
}



export default Login