import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import FormHelperText from '@mui/material/FormHelperText'


interface MasterFranchiseRegFeesProps {
    styles?: object
    currency_symbol: string | null
}

const MasterFranchiseRegFees = (props: MasterFranchiseRegFeesProps) => {
    const {styles, currency_symbol} = props

    const{ t } = useTranslation('finance_terms')

    const parsed_currency_symbol = currency_symbol ? `(${currency_symbol})` : ''

    return (
        <>
        <FormHelperText> {t('these_fees_will_be_set_for_all_your_franchisees')} </FormHelperText>
        <br/>
        <Box sx={{display: 'flex', gap: '1rem', justifyContent: 'space-between', ...styles}}>
            <TextField
                type="number"
                label={`${t('intramural_registration_fee')} ${parsed_currency_symbol}`}
                name='intramural_reg_fee'
                inputProps={{ step: 0.01 }}
                required
                sx={{width: '100%'}}
            />
            <TextField
                type="number"
                label={`${t('extramural_registration_fee')} ${parsed_currency_symbol}`}
                name='extramural_reg_fee'
                inputProps={{ step: 0.01 }}
                required
                sx={{width: '100%'}}
            />
        </Box>
        </>
    )
}

export default MasterFranchiseRegFees