import {useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import DeleteIcon from '@mui/icons-material/Delete'
import Typography from '@mui/material/Typography'

import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import { MessageContext } from '@contexts/MessageContext'
import { DialogButton, DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'

import { DialogForIdsProps } from '../interfaces/interface'


const ConfirmDeleteDialog = (props: DialogForIdsProps) => {
	const {ids, state_manager, onClose, open} = props

	const {setMessage} = useContext(MessageContext)
    const { t } = useTranslation('tables')

	const handleDelete = async () => {
		return state_manager.setDeleteMultipleAction(ids)
		.then(() => {
			handleClose()  // close the dialog if the promise resolved
			setMessage(props => ({...props, success: t('deleted')}))
		})
	}

	const handleClose = () => {
		onClose()
	}

	return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> {t('confirm_delete')} </INastixDialogTitle>
			<DialogDivider/>
			<Typography>
				{t('confirm_delete_message', { count: ids.length })} {t(ids.length === 1 ? 'item' : 'items')}?
			</Typography>
			<DialogButton variant='contained' onClick={handleDelete}> {t('delete')} </DialogButton>
		</INastixDialog>
	)
}


const ConfirmDeleteDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

    const { t } = useTranslation('tables')

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<DeleteIcon/>}>
			<Typography> {t('delete')} </Typography>
		</TableActionButon>
		<ConfirmDeleteDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>
		</>
	)
}

export default ConfirmDeleteDialogHandler