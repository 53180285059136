import { GenericHandler } from "../../Handlers/handlers"
import { TENANT_ENDPOINT } from "@adapters/routes/endpoints"


class TenantHandler extends GenericHandler {
    constructor (endpoint=TENANT_ENDPOINT) {
        super(endpoint)
    }
}

export default TenantHandler