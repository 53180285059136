import React, {useState, useEffect, useContext, useRef} from 'react'

import { GridColDef, GridColumnVisibilityModel, GridFilterModel, GridSortModel, GridSortItem, GridRowClassNameParams, GridValidRowModel } from '@mui/x-data-grid'
import Box from '@mui/material/Box'

import StateManager from '@actions/StateManager/stateManager'
import { CRUDAction } from '@actions/Actions/actions'
import trackRequests from '@adapters/helpers/trackRequests'
import { NumOfRequestsContext } from '@contexts/NumOfRequestsContext'
import { FormComponent } from '@components/forms/Form'
import NoRowsOverlay from '@components/tables/Table/NoRowsOverlay'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import { MessageContext } from '@contexts/MessageContext'
import { TriggerTableDataRerenderContext } from '@contexts/TriggerRerender/triggerTableDataRerender'
import { SetNum } from '@utils/types/types'

import CRUDTable from '../CRUD/CRUDTable'
import CustomTable from '../Custom/CustomTable'
import StripedDataGrid from './styles'


export interface DialogComponentProps {
	disabled?: boolean
	ids: any[]
	state_manager: StateManager
}

export type DialogComponent = React.ComponentType<DialogComponentProps>

export interface TableProps {
	dialogs?: Array<DialogComponent>  // pass in dialogs for the Table to render the CustomTable component
    single_selection_dialogs?: Array<DialogComponent>  // dialogs that will only show when one row is selected
    no_selection_dialogs?: Array<DialogComponent> // dialogs that will only show when no rows are selected
	Form?: FormComponent  // pass in Form for the Table to render the CRUDTable component
    column_visibility_model?: GridColumnVisibilityModel
    getRowClassName?: ((params: GridRowClassNameParams<GridValidRowModel>) => string) | undefined
    page_size?: number
    use_pagination?: boolean
    amount_of_rows_selection?: number[]
    paginationFilterParser?: (field: string, value: string) => {field: string, value: string, custom_operator: string | undefined} 
    paginationSortParser?: (field: string) => string
	action: CRUDAction
	columns: GridColDef[]
    getRowId?: (row: any) => string  //  The getRowId prop allows you to specify a function that generates a unique identifier for each row.
    can_add?: boolean
	can_edit?: boolean
	can_delete?: boolean
    can_allocate?: boolean
	include_add_operation?: boolean
	makeData?: (data: any) => {}  // makeData can be used to filter out desired/undesired data from the get action
    setNumOfDataOnCreate?: SetNum
    setNumOfDataOnDelete?: SetNum
    setCallbackFn?: (data: any) => void
    setSelectionsCallback?: (data: TableSelections) => void
    setStateManagerCallback?: (state_manager: StateManager) => void
    filter?: object  // used to filter out data from the backend response
    sort_by_asc?: string
    storage_key?: string  // provide the name of the localStorage key of the data that has been cached to avoid making a server request
    no_content_message?: string  // write a message to the user specifically when the backend responds with a no content message type
}

export type TableSelections = {
	row_selection_model: Array<any>
	selections: Array<any>
}


interface OrderBy {
    order_by: string
}


const Table = (props: TableProps) => {
	const {dialogs, single_selection_dialogs, no_selection_dialogs, Form, column_visibility_model, getRowClassName, page_size=10, use_pagination=false, amount_of_rows_selection=[10, 25, 50], paginationFilterParser, paginationSortParser, action, columns, getRowId, can_add, can_edit, can_delete, can_allocate, include_add_operation=true, makeData = (data: any) => data, setNumOfDataOnCreate, setNumOfDataOnDelete, setCallbackFn, setSelectionsCallback, setStateManagerCallback, filter, sort_by_asc, storage_key, no_content_message} = props

    const {setNumOfRequests} = useContext(NumOfRequestsContext)
    const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)
    const {trigger_table_data_rerender} = useContext(TriggerTableDataRerenderContext)

    const {setMessage} = useContext(MessageContext)

    const [data, setData] = useState<Array<any>>([])
    const [table_selections, setTableSelections] = useState<TableSelections>({
        row_selection_model: [],
        selections: []
    })
    const [pagination_model, setPaginationModel] = useState({pageSize: page_size, page: 0})
    const [row_count_state, setRowCountState] = useState(0)
    const [filter_model, setFilterModel] = useState<GridFilterModel>({items: []})
    const [filter_model_temp, setFilterModelTemp] = useState<GridFilterModel>({items: []})
    const [sort_model, setSortModel] = useState<OrderBy | null>(null)

    const actionRef = useRef(action)
    const state_manager = new StateManager(actionRef.current, data, setData, makeData, setNumOfDataOnCreate, setNumOfDataOnDelete)

    useEffect(() => {
        if (setStateManagerCallback)
            setStateManagerCallback(state_manager)
    }, [])

    const makeDataRef = useRef(makeData)
    const setCallbackFnRef = useRef(setCallbackFn)
    const filterRef = useRef(filter)

    const filterRefLastModified = useRef(0)

    const onFilterModelChange = (model: any) => {
        setFilterModel(model)
        filterRefLastModified.current = Date.now()
    }

    useEffect(() => {
        const interval = setInterval(() => {
            const now = Date.now()
            const timeDifference = now - filterRefLastModified.current
            if (timeDifference > 1000) {
                setFilterModelTemp(filter_model)
            }
        }, 1000)

        return () => clearInterval(interval)
    }, [filter_model])

    useEffect(() => {
        if (!use_pagination)
            return

        if (filter)
            filterRef.current = filter
        else
            filterRef.current = {}

        for (const item of filter_model_temp.items) {
            let field
            let value
            let custom_operator
            if (paginationFilterParser)
                ({ field, value, custom_operator } = paginationFilterParser(item.field, item.value))
            else
                throw new Error('Need pagination filter parser in order to filter server side')

            let operator: string
            let field_and_operator: string
            if (custom_operator !== undefined) {
                operator = custom_operator
                field_and_operator = field
            }
            else {
                operator = item.operator
                if (operator !== 'contains') {
                    setMessage(props => ({...props, info: "This operator cannot be used"}))
                    continue
                }
                const operator_str = `i${operator.replace('_', '')}`
                field_and_operator = `${field}__${operator_str}`
            }

            let encodedValue
            if (filterRef.current) {
                encodedValue = value ? encodeURIComponent(value) : '';
                (filterRef.current as any)[field_and_operator] = encodedValue
            }
        }

        if (sort_model)
            (filterRef.current as any)['order_by'] = sort_model.order_by

    }, [use_pagination, filter, filter_model_temp, sort_model, paginationFilterParser, setMessage])
  
    useEffect(() => {
        const storage_data = storage_key ? localStorage.getItem(storage_key) : null
        const parsed_storage_data = storage_data ? JSON.parse(storage_data) : []

        if (parsed_storage_data.length)
            setData(parsed_storage_data)
        else { 
            trackRequests(actionRef.current.get(use_pagination ? pagination_model.pageSize : undefined, use_pagination ? pagination_model.page : undefined, filterRef.current), setNumOfRequests)
                .then(response => {
                    // handle if the backend response was a 204
                    if (response.headers && response.status === 204) {
                        if (!no_content_message) {
                            throw new Error("a 'no_content_message' arg must be passed in when the server responds with a no content 204. Add a 'no_content_message' to the pages Table component")
                        }
                        setMessage(props => ({ ...props, info: no_content_message }))
                        return []
                    } else {
                        return response
                    }
                })
                .then(data => {
                    setData(() => makeDataRef.current(data))
                    if (setCallbackFnRef.current) {
                        setCallbackFnRef.current(data)
                    }
                })
                .catch(error => {
                    console.log(error)
                    setMessage(props => ({ ...props, error: error.message }))
                })

            filterRefLastModified.current = Date.now()
        }
    }, [use_pagination, pagination_model.pageSize, pagination_model.page, use_pagination ? pagination_model : null, setNumOfRequests, setMessage, filter_model_temp, sort_model, storage_key, change_company_rerender, trigger_table_data_rerender, no_content_message])
  
    const selected = (ids: Array<any>) => {
        const selections = state_manager.data.filter(row => ids.includes(row.id))
        const table_selections: TableSelections = {
            row_selection_model: ids,
            selections: selections
        }
        setTableSelections(table_selections)
    }

    useEffect(() => {
        if (use_pagination) {
            action.get(use_pagination ? pagination_model.pageSize: undefined, use_pagination ? pagination_model.page: undefined, {...filterRef.current, 'num_of_rows_only': true})
            .then((length: number) => {
                setRowCountState(length)
            })
        }
	}, [use_pagination, action, pagination_model.pageSize, pagination_model.page, use_pagination ? pagination_model : null, filter_model_temp, trigger_table_data_rerender])

    useEffect(() => {
        if (setSelectionsCallback)
            setSelectionsCallback(table_selections)
    }, [table_selections, setSelectionsCallback])

    const handleSortModelChange = (sort_model: GridSortModel) => {
        if (!paginationSortParser)
            return

        const sort_item: GridSortItem = sort_model[0]

        if (!sort_item) {
            setSortModel(null)
            return 
        }

        let sign
        if (sort_item.sort === 'asc')
            sign = ''
        else if (sort_item.sort === 'desc')
            sign = '-'
        else
            sign = ''

        setSortModel({order_by: `${sign}${paginationSortParser(sort_item.field)}`})
    }
    
    return (
        <>
        {!Form && (dialogs || single_selection_dialogs) && <CustomTable
            dialogs={dialogs}
            single_selection_dialogs={single_selection_dialogs}
            no_selection_dialogs={no_selection_dialogs}
            table_selections={table_selections}
            state_manager={state_manager}
        />}
        {Form && <CRUDTable
            dialogs={dialogs}
            single_selection_dialogs={single_selection_dialogs}
            no_selection_dialogs={no_selection_dialogs}
            Form={Form}
            table_selections={table_selections}
            setTableSelections={setTableSelections}
            state_manager={state_manager}
            can_add={can_add}
            can_edit={can_edit}
            can_allocate={can_allocate}
			can_delete={can_delete}
            include_add_operation={include_add_operation}
        />}
        <Box sx={{ 
            width: '100%', 
            height: 660,
            '& .super-app-theme--cell': {
                backgroundColor: 'rgba(224, 183, 60, 0.55)',
                color: '#1a3e72',
                fontWeight: '600',
            },
            '& .super-app.negative': {
                backgroundColor: 'rgba(157, 255, 118, 0.49)',
                color: '#1a3e72',
                fontWeight: '600',
            },
            '& .super-app.positive': {
                backgroundColor: '#d47483',
                color: '#1a3e72',
                fontWeight: '600',
            },
        }}>
            <StripedDataGrid
                rowHeight={40}
                slots={{
                    noRowsOverlay: NoRowsOverlay,
                }}
                rows={state_manager.data}
                columns={columns}
                getRowId={getRowId}
                checkboxSelection
                onRowSelectionModelChange={selected}
                rowSelectionModel={table_selections.row_selection_model}
                rowCount={use_pagination ? row_count_state : undefined}
                paginationModel={pagination_model}
                paginationMode={use_pagination ? 'server' : 'client'}
                filterMode={use_pagination ? 'server' : 'client'}
                onPaginationModelChange={setPaginationModel}
                pageSizeOptions={amount_of_rows_selection}
                getRowClassName={getRowClassName 
                    ? getRowClassName 
                    : (params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                }
                filterModel={filter_model}
                onSortModelChange={handleSortModelChange}
                sortingMode={use_pagination ? 'server' : 'client'}
                disableColumnSelector 
                initialState={{
                    sorting: {
                        sortModel: sort_by_asc ? [{
                                field: sort_by_asc,
                                sort: 'asc',
                            }]
                            : []
                    },
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                            ...column_visibility_model
                        }
                    }
                }}
                onFilterModelChange={onFilterModelChange}
            />
        </Box>
        </>
    )
}

export default Table
