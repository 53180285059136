import {useState, useEffect, useContext} from 'react'

import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import FranchiseeActions from "@actions/CRUDActions/FranchiseeActions/franchiseeActions"
import FranchiseeTable from "@components/tables/Custom/FranchiseeTable"
import CreateMasterFranchiseTShirtReporDialogHandler from "@components/dialogs/Reports/TShirtReports/MasterFranchiseTShirtReportDialogHandler"
import SchoolTable from '@components/tables/CRUD/SchoolTable'
import SchoolActions from '@actions/CRUDActions/SchoolActions/schoolActions'
import CreateFranchiseTShirtReporDialogHandler from '@components/dialogs/Reports/TShirtReports/FranchiseTShirtReportDialogHandler'
import CreateInstructorTShirtReporDialogHandler from '@components/dialogs/Reports/TShirtReports/InstructorTShirtReportDialogHandler'
import InstructorTable from '@components/tables/CRUD/InstructorTable'
import InstructorActions from '@actions/CRUDActions/InstructorActions/instructorActions'
import { getLocalStorageCompany } from '@utils/localStorage/company'

import ReportingPage from ".."
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'


const ReportTShirts = () => {
    const connected_company = getLocalStorageCompany()

    const acts_like_a_franchise = connected_company.acts_like_a_franchise

    const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)

    const [report_type, setReportType] = useState(acts_like_a_franchise ? 'school' : 'franchise')

    useEffect(() => {
        setReportType(acts_like_a_franchise ? 'school' : 'franchise')
    }, [change_company_rerender, acts_like_a_franchise])

    const franchise_actions = new FranchiseeActions()
    const school_actions = new SchoolActions()
    const instructor_actions = new InstructorActions()

    const handleChange = (event: SelectChangeEvent) => {
        setReportType(event.target.value as string)
    }

    const renderReportTypeTable = () => {
        if (report_type === 'franchise')
            return <FranchiseeTable action={franchise_actions} use_as_reporting={true} dialogs={[CreateMasterFranchiseTShirtReporDialogHandler]}/>
        else if (report_type === 'school')
            return <SchoolTable action={school_actions} use_as_reporting={true} dialogs={[CreateFranchiseTShirtReporDialogHandler]}/>
        else if (report_type === 'instructor')
            return <InstructorTable action={instructor_actions} use_as_reporting={true} dialogs={[CreateInstructorTShirtReporDialogHandler]}/>
        return <></>
    }

    return (
        <ReportingPage>
            <FormControl fullWidth>
                <InputLabel id="report-for-label">Report Type</InputLabel>
                <Select
                labelId="report-for-select-label"
                id="report-for-select"
                value={report_type}
                label="Report Type"
                onChange={handleChange}
                sx={{
                    marginBottom: '1rem', width: '200px'
                }}
                >
                    {!acts_like_a_franchise && <MenuItem value={'franchise'}>Franchise</MenuItem>}
                    <MenuItem value={'school'}>School</MenuItem>
                    <MenuItem value={'instructor'}>Instructor</MenuItem>
                </Select>
            </FormControl>
            {renderReportTypeTable()}
        </ReportingPage>
    )
}

export default ReportTShirts