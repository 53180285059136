function generateStringChar(str_length: number): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789' // Use uppercase letters and digits for the order ID
    let orderId = ''
    for (let i = 0; i < str_length; i++) {
        orderId += characters.charAt(Math.floor(Math.random() * characters.length))
    }
    return orderId
}

export {
    generateStringChar
}
