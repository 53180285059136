import {useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Divider } from '@mui/material'
import BusinessIcon from '@mui/icons-material/Business'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import StorefrontIcon from '@mui/icons-material/Storefront'
import SettingsIcon from '@mui/icons-material/Settings'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'

import XeroLogo from '@images/xero/logo/logo.png'
import { isInGroup, permissionValid } from '@utils/utils/util'
import { UserPermissions } from '@utils/enums/permissions'
import { getLocalStorageUser } from '@utils/localStorage/user'

import ProfileSection from './ProfileSection'
import './styles.css'
import { UserType } from '@utils/enums/enums'
import { getLocalStorageCompany } from '@utils/localStorage/company'


const CustomIcon = (props: any) => {
    const { src, alt, ...otherProps } = props
    return <img src={src} alt={alt} {...otherProps} />
}

const SideNav = () => {
    const navigate = useNavigate()
	const { t } = useTranslation('settings')

    const [company_open, setCompanyOpen] = useState(true)
    const [user_open, setUserOpen] = useState(true)

    const handleUserClick = () => {
        setUserOpen(!user_open)
    }

    const handleCompanyClick = () => {
        setCompanyOpen(!company_open)
    }

    const handleToPageClick = (path: string) => {
		navigate("/" + path)
	}

    const user = getLocalStorageUser()
	const company = getLocalStorageCompany()
    const can_view_finances = permissionValid(user, UserPermissions.VIEW_FINANCES)
    const can_view_xero_management = permissionValid(user, UserPermissions.VIEW_XERO_MANAGEMENT)

    return (
        <List
            sx={{bgcolor: 'background.paper', borderRight: '1px solid lightgrey', padding: '1rem' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
        >
        <ProfileSection/>
        <Divider sx={{marginTop: 2, marginBottom: 2}}/>
        <ListItemButton onClick={handleUserClick}>
            <ListItemIcon>
                <PermIdentityIcon fontSize='small'/>
            </ListItemIcon>
            <ListItemText primary={t('user')} />
            {user_open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <Collapse in={user_open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
                <ListItemButton onClick={() => handleToPageClick('settings/profile')} sx={{ pl: 4 }}>
                    <ListItemIcon>
                        <SettingsIcon fontSize='small' />
                    </ListItemIcon>
                    <ListItemText primary={t('settings')} />
                </ListItemButton>
            </List>
        </Collapse>
        {!isInGroup(user, company, [UserType.INSTRUCTOR, UserType.ASSISTANT], false) && 
            <>
            <Divider sx={{marginTop: 2, marginBottom: 2}}/>
            <ListItemButton onClick={handleCompanyClick}>
                <ListItemIcon>
                    <BusinessIcon fontSize='small'/>
                </ListItemIcon>
                <ListItemText primary={t('company')} />
                {company_open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={company_open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    <ListItemButton onClick={() => handleToPageClick('settings/business/profile')} sx={{ pl: 4 }}>
                        <ListItemIcon>
                            <StorefrontIcon fontSize='small'/>
                        </ListItemIcon>
                        <ListItemText primary={t('business_profile')} />
                    </ListItemButton>
                    {can_view_finances && 
                        <ListItemButton onClick={() => handleToPageClick('settings/business/finance')} sx={{ pl: 4 }}>
                            <ListItemIcon>
                                <AccountBalanceIcon fontSize='small'/>
                            </ListItemIcon>
                            <ListItemText primary={t("finance")} />
                        </ListItemButton>
                    }
                    {can_view_xero_management && 
                        <ListItemButton onClick={() => handleToPageClick('settings/business/xero-management')} sx={{ pl: 4 }}>
                            <ListItemIcon>
                                <CustomIcon src={XeroLogo} alt="My Icon" width={24} height={24} />
                            </ListItemIcon>
                            <ListItemText primary={t("xero_management")} />
                        </ListItemButton>
                    }
                </List>
            </Collapse>
            </>
        }
        </List>
    )
}

export default SideNav