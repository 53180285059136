import GenericFetchWrapper from "@adapters/api/fetchWrappers/genericFetchWrapper"
import { BaseHandler } from "@adapters/handlers/Handlers/handlers"
import { USERS_ENDPOINT } from "@adapters/routes/endpoints"


class CompaniesHandler extends BaseHandler {
    endpoint: string
    fetch_wrapper: GenericFetchWrapper

    constructor () {
        super()
        this.fetch_wrapper = new GenericFetchWrapper()
        this.endpoint = USERS_ENDPOINT + '/companies'
    }

    get (user_id?: string): Promise<any> {
        if (user_id)
            this.endpoint = `${this.endpoint}/${user_id}`

        return this.fetch_wrapper.get(this.endpoint)
    }
}

export default CompaniesHandler