import { AccountCodesFields } from "./interfaces"


export const account_codes_fields: AccountCodesFields[] = [
    {
        title: "Royalties received for Babynastix authorisations will go to this account in Xero",
        key: "baby_auth",
        label: "Babynastix Authorisation Account"
    },
    {
        title: "Royalties received for Babynastix registrations will go to this account in Xero",
        key: "baby_reg",
        label: "Babynastix Registration Account"
    },
    {
        title: "Royalties received for Monkeynastix authorisations will go to this account in Xero",
        key: "monkey_auth",
        label: "Monkeynastix Authorisation Account"
    },
    {
        title: "Royalties received for Monkeynastix registrations will go to this account in Xero",
        key: "monkey_reg",
        label: "Monkeynastix Registration Account"
    },
    {
        title: "Royalties received for Supernastix authorisations will go to this account in Xero",
        key: "super_auth",
        label: "Supernastix Authorisation Account"
    },
    {
        title: "Royalties received for Supernastix registrations will go to this account in Xero",
        key: "super_reg",
        label: "Supernastix Registration Account"
    }
]