export enum UserPermissions {
    VIEW_DASHBOARD_MODEL = 'view_userdashboardsettingsmodel',

    VIEW_USERS = 'view_user',
    EDIT_USERS = 'change_user',
    DELETE_USERS = 'delete_user',

    VIEW_FRANCHISEES = 'view_tenant',
    EDIT_FRANCHISEES = 'change_tenant',  // edit royalties
    SEND_BULK_EMAIL_FRANCHISEES = 'send_bulk_email_tenant',  // edit royalties

    CREATE_CLASSES = 'add_companyclassmodel',
    VIEW_CLASSES = 'view_companyclassmodel',
    EDIT_CLASSES = 'change_companyclassmodel',
    DELETE_CLASSES= 'delete_companyclassmodel',
    TRANSFER_CLASSES = 'transfer_companyclassmodel',

    CREATE_SCHOOLS = 'add_schoolmodel',
    VIEW_SCHOOLS = 'view_schoolmodel',
    EDIT_SCHOOLS = 'change_schoolmodel',  
    DELETE_SCHOOLS = 'delete_schoolmodel',
    SEND_BULK_EMAIL_SCHOOLS = 'send_bulk_email_schools',

    CREATE_CHILDREN = 'add_childmodel',
    VIEW_CHILDREN = 'view_childmodel',
    EDIT_CHILDREN = 'change_childmodel',  // edit child suspension status
    DELETE_CHILDREN = 'delete_childmodel',
    TRANSFER_CHILDREN = 'transfer_childmodel',
    SEND_BULK_EMAIL_MEMBERS = 'can_send_bulk_email_members', 
    CAN_PRINT_MEMBER_CERTIFICATES = 'can_print_member_certificates',

    VIEW_NOTIFICATIONS = 'view_notificationmodel',
    DELETE_NOTIFICATIONS = 'delete_notificationmodel',
    
    VIEW_COMPANY_NOTIFICATIONS = 'view_companynotificationmodel',
    DELETE_COMPANY_NOTIFICATIONS = 'delete_companynotificationmodel',

    VIEW_FINANCES = 'view_financemodel',
    EDIT_FINANCES = 'change_financemodel',
    DELETE_FINANCES = 'delete_financemodel',

    VIEW_XERO_MANAGEMENT = 'view_xerotokenmodel',

    VIEW_ORDERS = 'view_oredermodel',
    EDIT_ORDERS = 'change_oredermodel',
    DELETE_ORDERS = 'delete_oredermodel',

    VIEW_INSTRUCTORS = 'view_instructormodel',
    EDIT_INSTRUCTORS = 'change_instructormodel',  // edit instructor salary, commission
    DELETE_INSTRUCTORS = 'delete_instructormodel',

    VIEW_PARENTS = 'view_parentmodel',
    CREATE_PARENTS = 'add_parentmodel',
    EDIT_PARENTS = 'change_parentmodel',
    DELETE_PARENTS = 'delete_parentmodel',

    VIEW_FZONE = 'view_fzone'
}

export enum CompanyPermissions {
    VIEW_FRANCHISEES = 'view_tenant',
    EDIT_FRANCHISEES = 'change_tenant',  // edit royalties
    
    VIEW_COMPANY_NOTIFICATIONS = 'view_companynotificationmodel',
    DELETE_COMPANY_NOTIFICATIONS = 'delete_companynotificationmodel',
}