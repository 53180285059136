import {useState, useEffect} from 'react'

import Box from '@mui/material/Box'
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import { School } from '@utils/interfaces/interfaces'
import SchoolActions from '@actions/CRUDActions/SchoolActions/schoolActions'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { useTranslation } from 'react-i18next'
import log from 'loglevel'
import { capitalizeFirstLetter } from '@utils/utils/util';


interface SelectSchoolProp {
    school_id?: number
    selectedSchoolCallback?: (selected_school: School | null) => void
}

const SelectSchool = (props: SelectSchoolProp) => {
    const {school_id, selectedSchoolCallback} = props

    const [school, setSchool] = useState<School | null>(null)
    const [schools, setSchools] = useState<Array<School>>([])
    const { t } = useTranslation('class_form')

    useEffect(() => {
        const fetchData = async () => {
            const company = getLocalStorageCompany()
            const school_actions = new SchoolActions()
            school_actions.get(undefined, undefined, {'by_franchise_or_tenant_school_uuid': company.company_uuid.toString()})
            .then((schools: School[]) => {
                const found_school = schools.find(school => school.id === school_id)
                if (found_school) {
                    setSchool(found_school)
                    if (selectedSchoolCallback)
                        selectedSchoolCallback(found_school)
                }
                setSchools(schools)
            })
            .catch(error => {
                log.error(error.message)
            })
        }
      
        fetchData()
    }, [])

    return (
        <Box sx={{ width: 200 }}>
            <Autocomplete
                options={schools.sort((a, b) => -b.school_type.localeCompare(a.school_type))}
                groupBy={(option) => capitalizeFirstLetter(option.school_type)}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id} // Check if option's id is equal to the value's id
                renderInput={(params) => <TextField {...params} label={t('school')}/>}
                value={school}
                onChange={(event, new_value) => {
                    setSchool(new_value)
                    if (selectedSchoolCallback)
                        selectedSchoolCallback(new_value)
                }}
            />
        </Box>              
    )

}

export default SelectSchool