import { createContext } from 'react'


export type SetNumOfNotifications = React.Dispatch<React.SetStateAction<number>>

interface INumOfNotificationsContext {
    num_of_notifications: number
    setNumOfNotifications: SetNumOfNotifications
}

export const NumOfNotificationsContext = createContext<INumOfNotificationsContext>({
    num_of_notifications: 0,
    setNumOfNotifications: () => {}
})


export type SetNumOfCompanyNotifications = React.Dispatch<React.SetStateAction<number>>

interface INumOfCompanyNotificationsContext {
    num_of_company_notifications: number
    setNumOfCompanyNotifications: SetNumOfCompanyNotifications
}

export const NumOfCompanyNotificationsContext = createContext<INumOfCompanyNotificationsContext>({
    num_of_company_notifications: 0,
    setNumOfCompanyNotifications: () => {}
})