import { SetMessage } from "@contexts/MessageContext"
import { SetNumOfRequests } from "@contexts/NumOfRequestsContext"


/**
A function that handles the response from a promise and sets the message state accordingly.
@param promise - The promise to be resolved.
@param key - The key to access the data attribute from the resolved promise.
@param success_string - The string to concatenate with the data[key] string when success.
@param setMessage - The function that updates the message state.
*/
const handleUIMessage = (promise: Promise<any>, setMessage: SetMessage, setNumOfRequests: SetNumOfRequests) => {
    promise
    .then(data => {
        setMessage(props => ({...props, success: data?.message}))
    })
    .catch(error => {
        let error_message = error?.message
        // If there are multiple form errors then the errors come through in an array format as a string. 
        if (error_message.includes('[') && error_message.includes(']'))
            error_message = error_message.replace('[', '').replace(']', '').replace(/,/g, ' ')
        setMessage(props => ({...props,  form_error: error_message}))
    })
    .finally(() => {
        setNumOfRequests(0)
    })
}

export default handleUIMessage