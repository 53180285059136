import { ContentType } from "@adapters/api/enums/enum"
import GenericFetchWrapper from "@adapters/api/fetchWrappers/genericFetchWrapper"
import { BaseHandler } from "@adapters/handlers/Handlers/handlers"
import { ASSESSMENT_REPORT_ENDPOINT, CHILD_AUTHORISATION_REPORT_ENDPOINT, CHILD_CERTIFICATES_REPORT_ENDPOINT, FRANCHISE_SALES_REPORT_ENDPOINT, INSTRUCTOR_WEEKLY_SCHEDULE_REPORT_ENDPOINT, PACKAGE_SLIP_REPORT_ENDPOINT } from "@adapters/routes/endpoints"


class ExcelReportHandler extends BaseHandler {
    endpoint: string
    package_slip_endpoint: string
    franchise_sales_endpoint: string
    child_certificates_endpoint: string
    child_authorisation_endpoint: string
    assessment_report_endpoint: string

    fetch_wrapper: GenericFetchWrapper

    constructor () {
        super()
        this.fetch_wrapper = new GenericFetchWrapper(ContentType.SPREADSHEET)
        this.endpoint = INSTRUCTOR_WEEKLY_SCHEDULE_REPORT_ENDPOINT
        this.package_slip_endpoint = PACKAGE_SLIP_REPORT_ENDPOINT
        this.franchise_sales_endpoint = FRANCHISE_SALES_REPORT_ENDPOINT
        this.child_certificates_endpoint = CHILD_CERTIFICATES_REPORT_ENDPOINT
        this.child_authorisation_endpoint = CHILD_AUTHORISATION_REPORT_ENDPOINT

        this.assessment_report_endpoint = ASSESSMENT_REPORT_ENDPOINT
    }

    createInstructorWeeklyScheduleReport (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.endpoint)
        return this.fetch_wrapper.post(parsed_endpoint, data)
    }

    createPackageSlip (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.package_slip_endpoint)
        return this.fetch_wrapper.post(parsed_endpoint, data)
    }

    createFranchiseSalesReport (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.franchise_sales_endpoint)
        return this.fetch_wrapper.post(parsed_endpoint, data)
    }

    createChildCertificatesReport (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.child_certificates_endpoint)
        return this.fetch_wrapper.post(parsed_endpoint, data)
    }

    createChildAuthorisationReport (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.child_authorisation_endpoint)
        return this.fetch_wrapper.post(parsed_endpoint, data)
    }

    createAssessmentReport (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.assessment_report_endpoint)
        return this.fetch_wrapper.post(parsed_endpoint, data)
    }
}

export default ExcelReportHandler