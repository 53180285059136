import CompanyDetails from "@pages/Setup/CompanySetup/CompanyDetails"
import GetCompanyAuthorized from "@pages/Setup/CompanySetup/GetCompanyAuthorized"

import SchoolFinanceSetup from "../SchoolFinanceSetup"
import ChooseConnectedSchoolCompany from "../ChooseConnectedCompany"


const GetStepComponent = (active_step: number) => {
    
    switch (active_step) {
        case 0:
            return <CompanyDetails/>
        case 1:
            return <ChooseConnectedSchoolCompany/>
        case 2:
            return <SchoolFinanceSetup/>
        case 3:
            return <GetCompanyAuthorized/>
        case 4:
            return <GetCompanyAuthorized/>
        default:
            return 'Unknown stepIndex'
    }
}

export default GetStepComponent