import GenericFetchWrapper from "@adapters/api/fetchWrappers/genericFetchWrapper"
import { BaseHandler } from "@adapters/handlers/Handlers/handlers"
import { VERIFY_EMAIL_ENDPOINT } from "@adapters/routes/endpoints"


class VerifyEmailHandler extends BaseHandler {
    endpoint: string
    fetch_wrapper: GenericFetchWrapper

    constructor () {
        super()
        this.fetch_wrapper = new GenericFetchWrapper()
        this.endpoint = VERIFY_EMAIL_ENDPOINT
    }

    verifyEmailWithUidAndToken = (data: object): Promise<any> => {
        return this.fetch_wrapper.post(this.endpoint + '/with-link', data)
    }

    verifyEmailWithCode = (data: object): Promise<any> => {
        return this.fetch_wrapper.post(this.endpoint + '/with-code', data)
    }
}

export default VerifyEmailHandler