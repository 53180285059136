import React, {useState} from 'react'

import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'

import {LeftRightDialogProps} from '@utils/dialogProps/DialogProps'


interface TransferListObj {
    id: number
    name: string
}

const not = (a: readonly TransferListObj[], b: readonly TransferListObj[]) => {
  return a.filter((value) => b.indexOf(value) === -1)
}

const intersection = (a: readonly TransferListObj[], b: readonly TransferListObj[]) => {
  return a.filter((value) => b.indexOf(value) !== -1)
}

const union = (a: readonly TransferListObj[], b: readonly TransferListObj[]) => {
  return [...a, ...not(b, a)]
}


const TransferList = (props: LeftRightDialogProps) => {
	const {left, right, setLeft, setRight, title1='Choices', title2='Chosen'} = props

    const [checked, setChecked] = useState<readonly TransferListObj[]>([])

    const leftChecked = intersection(checked, left)
    const rightChecked = intersection(checked, right)

    const handleToggle = (value: TransferListObj) => () => {
        const currentIndex = checked.indexOf(value)
        const newChecked = [...checked]

        if (currentIndex === -1) {
            newChecked.push(value)
        } else {
            newChecked.splice(currentIndex, 1)
        }

        setChecked(newChecked)
    }

    const numberOfChecked = (items: readonly TransferListObj[]) =>
        intersection(checked, items).length

    const handleToggleAll = (items: readonly TransferListObj[]) => () => {
        if (numberOfChecked(items) === items.length) 
            setChecked(not(checked, items))
        else
            setChecked(union(checked, items))
    }

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked))
        setLeft(not(left, leftChecked))
        setChecked(not(checked, leftChecked))
    }

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked))
        setRight(not(right, rightChecked))
        setChecked(not(checked, rightChecked))
    }

    const customList = (title: React.ReactNode, items: readonly TransferListObj[]) => (
        <Card sx={{paddingLeft: 0}}>
        <CardHeader
            sx={{paddingLeft: 0}}
            avatar={
            <Checkbox
                onClick={handleToggleAll(items)}
                checked={numberOfChecked(items) === items.length && items.length !== 0}
                indeterminate={
                numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                }
                disabled={items.length === 0}
                inputProps={{
                'aria-label': 'all items selected',
                }}
            />
            }
            title={title}
            subheader={`${numberOfChecked(items)}/${items.length} selected`}
        />
        <Divider />
        <List
            sx={{
                width: 200,
                height: 230,
                bgcolor: 'background.paper',
                overflow: 'auto',
            }}
            dense
            component="div"
            role="list"
        >
            {items.map((value: TransferListObj) => {
                const labelId = `transfer-list-all-item-${value}-label`
                return (
                    <ListItem
                        key={value.id}
                        role="listitem"
                        button
                        onClick={handleToggle(value)}
                    >
                        <ListItemIcon>
                            <Checkbox
                            checked={checked.indexOf(value) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{
                                'aria-labelledby': labelId,
                            }}
                            />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={`${value.name}`} />
                    </ListItem>
                )
            })}
        </List>
        </Card>
    )

    return (
        <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{'.MuiPaper-root': {paddingLeft: '0.5rem'}, '.MuiListItem-button': {paddingLeft: '0rem'}}}>
            <Grid item sm={5} xs={12}>{customList(title1, left)}</Grid>
            <Grid item sm={2} xs={12}> 
                <Grid container direction="column" alignItems="center">
                <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedRight}
                    disabled={leftChecked.length === 0}
                    aria-label="move selected right"
                >
                    &gt;
                </Button>
                <Button
                    sx={{ my: 0.5 }}
                    variant="outlined"
                    size="small"
                    onClick={handleCheckedLeft}
                    disabled={rightChecked.length === 0}
                    aria-label="move selected left"
                >
                    &lt;
                </Button>
                </Grid>
            </Grid>
            <Grid item sm={5}  xs={12}>{customList(title2, right)}</Grid>
        </Grid>
    )
}

export default TransferList