import { CRUDAction } from "@actions/Actions/actions"
import ClassHandler from "@adapters/handlers/CRUDHandlers/ClassHandlers/classHandlers"
import TransferToChildHandler from "@adapters/handlers/CRUDHandlers/ClassHandlers/transferToChildHandler"
import { Class } from "@utils/interfaces/interfaces"
import { getLocalStorageCompany } from "@utils/localStorage/company"


class ClassActions extends CRUDAction {
    handler: ClassHandler
    transfer_to_child_handler: TransferToChildHandler

    constructor() {
        super()
        this.handler = new ClassHandler()
        this.transfer_to_child_handler = new TransferToChildHandler()
    }

    get(
        page?: number,
        page_size?: number,
        filter = {},
        group: string = ''
    ): Promise<any> {
        return super.get(page, page_size, filter, group);
    }

    getByContainsChild = (child_id: string, page_size?: number, page?: number) => {
        return this.handler.get(page, page_size, {'children': child_id})
    }

    getByNotContainsChild = (child_id: string, tenant_school_id: string, page_size?: number, page?: number) => {
        const filter_object = {
            'not_contains_child': child_id, 
            'school__tenant': tenant_school_id,
        }
        return this.handler.get(page, page_size, filter_object)
    }

    /**
     * Transfers children between classes.
     * 
     * @param {number} child_id - The ID of the target class.
     * @param {Class[]} contains_child - An array of children to transfer to the target class.
     * @param {Class[]} not_contains_child - An array of children to not put in the target class.
     * @returns {Promise<any>} - A promise that resolves with the response from the API.
    */
    transferClasses = (child_id: number, contains_child: Class[], not_contains_child: Class[]): Promise<any> => {
        const updated_contains_child = contains_child.map(nastix_class => {
            const children = nastix_class.children
            
            if (!children.includes(child_id))
                children.push(child_id)
            
            return nastix_class
        })

        const updated_not_contains_child = not_contains_child.map(nastix_class => {
            const children = nastix_class.children
            
            const filtered_class = children.filter(id => id !== child_id)
            nastix_class.children = filtered_class
            
            return nastix_class
        })

        const data = updated_contains_child.concat(updated_not_contains_child)
        return this.transfer_to_child_handler.transferClasses(data)
    }
}

export default ClassActions