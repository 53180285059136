import {useState, useEffect, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import {Typography} from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import { DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { DialogProps } from '@utils/dialogProps/DialogProps'
import MinastixMemberPoolActions from '@actions/MinastixMemberPoolActions/minastixMemberPoolActions'
import ChildTransferIcon from '@images/icons/child_transfer.png'
import { School } from '@utils/interfaces/interfaces'
import SchoolActions from '@actions/CRUDActions/SchoolActions/schoolActions'
import { MessageContext } from '@contexts/MessageContext'


const MemeberPoolToFranchiesDialog = (props: DialogProps) => {
	// disable the warning for not using state_manager in this dialog
	// eslint-disable-next-line  
	const {ids, state_manager, onClose, open} = props

	const { t } = useTranslation('send_bulk_email')

	const {setMessage} = useContext(MessageContext)

	// const [children, setChildren] = useState<Child[]>([])
	const [schools, setSchools] = useState<School[]>([])
	const [selected_school, setSelectedSchool] = useState<School | null>(null)

	useEffect(() => {
		// const child_actions = new ChildActions()
		// child_actions.get(undefined, undefined, {'get_by_no_franchise': true, 'ids': [ids]})
		// .then((children: Child[]) => {
		// 	setChildren(children)
		// })

		const school_actions = new SchoolActions()
		school_actions.get(undefined, undefined, {})
		.then((schools: School[]) => {
			setSchools(schools)
		})
	}, [])

	const handleAllocateToAFranchise = async (): Promise<any> => {
		if (!selected_school) {
			setMessage(props => ({...props, error: "Selecting a school is required"}))
			return
		}

		const minastix_member_pool_actions = new MinastixMemberPoolActions()
		minastix_member_pool_actions.thisFranchisesChild({'child_ids': ids, 'school_id': selected_school.id})
		.then(() => {
			state_manager.setDeleteMultipleActionFilter(ids)
			handleClose()
		})
		.catch(error => {
			console.log(error)
		})
	}

	const handleClose = () => {
		onClose()
	}

	return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> Allocate To My Franchise </INastixDialogTitle>
			<DialogDivider/>
			<Grid container spacing={2}>
                {/* ADD CHILDREN NAMES THAT ARE BEING ALLOCATED 
				<Grid item xs={12}>
					<Typography> Are you sure you want  </Typography>
                    {children.map(child => {
						<Typography> {child.username} </Typography>
					})}
                </Grid> */}
				<Autocomplete
					value={selected_school}
					onChange={(event: any, new_value: School | null) => {
						setSelectedSchool(new_value)
					}}
					options={schools}
					getOptionLabel={(option: School) => option.name}  // Adjust based on your object structure
					sx={{ width: 300, margin: 'auto', marginTop: '2rem' }}
					renderInput={(params) => <TextField {...params} label="Your Schools"/>}
				/>
				<Grid item xs={12}>
                    <Button onClick={handleAllocateToAFranchise} variant="contained" sx={{width: '100%'}}>
                        {t('submit')}
                    </Button>
                </Grid>
			</Grid>
		</INastixDialog>
	)
}


const MemeberPoolToFranchiesDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	// const { t } = useTranslation('send_bulk_email')

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<img src={ChildTransferIcon} width={'30px'}/>}>
			<Typography> Allocate to my franchise</Typography>
		</TableActionButon>
		{open && <MemeberPoolToFranchiesDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>}
		</>
	)
}

export default MemeberPoolToFranchiesDialogHandler