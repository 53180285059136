import {useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import Typography from '@mui/material/Typography'
import ArchiveIcon from '@mui/icons-material/Archive'

import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import { MessageContext } from '@contexts/MessageContext'
import { DialogButton, DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { DialogForIdsProps } from '@components/dialogs/interfaces/interface'


const ArchiveInstructorDialog = (props: DialogForIdsProps) => {
	const {ids, state_manager, onClose, open} = props

	const {setMessage} = useContext(MessageContext)
    const { t } = useTranslation('tables')

	const handleDelete = async () => {
		return state_manager.setDeleteMultipleAction(ids)
		.then(() => {
			handleClose()  // close the dialog if the promise resolved
			setMessage(props => ({...props, success: "Successfully Archived"}))
		})
	}

	const handleClose = () => {
		onClose()
	}

	return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> Confirm Archive </INastixDialogTitle>
			<DialogDivider/>
			<Typography>
				{t('Are you sure you want to archive', { count: ids.length })} {t(ids.length === 1 ? 'item' : 'items')}?
			</Typography>
			<DialogButton variant='contained' onClick={handleDelete}> Archive </DialogButton>
		</INastixDialog>
	)
}


const ArchiveInstructorDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

    const { t } = useTranslation('tables')

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<ArchiveIcon/>}>
			<Typography> Archive </Typography>
		</TableActionButon>
		<ArchiveInstructorDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>
		</>
	)
}

export default ArchiveInstructorDialogHandler