import {useContext} from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import Tooltip from '@mui/material/Tooltip'
import Button from '@mui/material/Button'
import LogoutIcon from '@mui/icons-material/Logout'

import { IsLoggedInContext } from '@contexts/IsLoggedInContext'
import { logout } from '@adapters/helpers/logout'
import { LocalstorageUserExistsContext } from '@contexts/LocalstorageUserExistsContext'
import { LocalstorageCompanyExistsContext } from '@contexts/LocalstorageCompanyExistsContext'


const BackToLogin = () => {
    const navigate = useNavigate()

    const { t } = useTranslation('set_up') 

    const {setIsLoggedIn} = useContext(IsLoggedInContext)
    const {setLocalstorageUserExists} = useContext(LocalstorageUserExistsContext)
    const {setLocalstorageCompanyExists} = useContext(LocalstorageCompanyExistsContext)

    return (
        <Tooltip title={t('back_to_login')}>
            <Button onClick={() => logout(navigate, setIsLoggedIn, setLocalstorageUserExists, setLocalstorageCompanyExists)}> 
                <LogoutIcon sx={{ fontSize: 25 }}/>
            </Button>
        </Tooltip>
    )
}

export default BackToLogin