import TShirtReportActions from "@actions/ReportActions/tShirtReportActions"
import { SetNumOfRequests } from "@contexts/NumOfRequestsContext"

import { ReportDocType, TShirtReportType } from "./enum"
import { downloadFile } from "@utils/files/blobs"
import { convertDateToYYYYMMDD } from "@utils/utils/util"
import { ContentType } from "@adapters/api/enums/enum"


export const handleTShirtReportCreate = async (event: React.FormEvent<HTMLFormElement>, setNumOfRequests: SetNumOfRequests, key: string, ids: string[], t_shirt_report_type: TShirtReportType, report_doc_type: ReportDocType) => {
    event.preventDefault()
    event.stopPropagation()

    setNumOfRequests(props => props + 1)

    const form_data = new FormData(event.currentTarget) 
    const form_data_object = Object.fromEntries(form_data.entries())

    const tShirtReportActions = new TShirtReportActions()

    const data = {
        ...form_data_object,
        t_shirt_report_type: t_shirt_report_type,
        report_doc_type: report_doc_type,
        [key]: ids
    }

    try {
        let content_type

        let current_date = convertDateToYYYYMMDD(new Date())
        let filename = `T-Shirt Report ${current_date}`
        if (report_doc_type === ReportDocType.EXCEL) {
            filename = `${filename}.xlsx`
            content_type = ContentType.SPREADSHEET
        }
        else if (report_doc_type === ReportDocType.CSV) {
            filename = `${filename}.csv`
            content_type = ContentType.CSV
        }
        else 
            throw new Error(`Incorrect file type. File Type: ${report_doc_type}`)

        let response: any
        if (t_shirt_report_type === TShirtReportType.PER_INSTRUCTOR_REPORT)
            response = await tShirtReportActions.createInstructorTShirtReport(data, content_type)
        else
            response = await tShirtReportActions.createTShirtReport(data, content_type)
        
        setNumOfRequests(props => props - 1)
	
        downloadFile(response, filename)

    } catch (error) {
        console.error('Error downloading file:', error)
        setNumOfRequests(props => props - 1)
    }
}