import { CRUDAction } from "@actions/Actions/actions"
import FZoneEcommerceOrderHandler from "@adapters/handlers/CRUDHandlers/FZoneEcommerceHandlers/fzoneEcommerceOrderHandler"


class FZoneEcommerceOrderActions extends CRUDAction {
    handler: FZoneEcommerceOrderHandler

    constructor() {
        super()
        this.handler = new FZoneEcommerceOrderHandler()
    }
}

export default FZoneEcommerceOrderActions