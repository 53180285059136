import GenericFetchWrapper from "@adapters/api/fetchWrappers/genericFetchWrapper"
import { BaseHandler } from "@adapters/handlers/Handlers/handlers"
import { COMPANIES_ENDPOINT } from "@adapters/routes/endpoints"


class CompanySettingsHandler extends BaseHandler {
    endpoint: string
    country_terms_endpoint: string
    finance_endpoint: string
    payment_structures_endpoint: string
    fetch_wrapper: GenericFetchWrapper

    constructor () {
        super()
        this.fetch_wrapper = new GenericFetchWrapper()
        this.endpoint = COMPANIES_ENDPOINT + '/settings'
        this.finance_endpoint = COMPANIES_ENDPOINT + '/settings/finance'
        this.payment_structures_endpoint = COMPANIES_ENDPOINT + '/settings/finance/payment-structures'
        this.country_terms_endpoint = COMPANIES_ENDPOINT + '/settings/country-terms/'
    }

    getById (id: string): Promise<any> {
        return this.fetch_wrapper.get(this.endpoint + '/' + id)
    }

    getCountryTerms (country?: string): Promise<any> {
        let endpoint
        if (country)
            endpoint = `${this.country_terms_endpoint}?country=${country}`
        else    
            endpoint = this.country_terms_endpoint
        return this.fetch_wrapper.get(endpoint)
    }

    post (data: object): Promise<any> {
        return this.fetch_wrapper.post(this.endpoint, data)
    }

    updateFinance (data: object, id: string): Promise<any> {
        return this.fetch_wrapper.put(this.finance_endpoint, data, id)
    }

    updatePaymentStructures (data: object, id: string): Promise<any> {
        return this.fetch_wrapper.put(this.payment_structures_endpoint, data, id)
    }
}

export default CompanySettingsHandler