import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { TextField, Button, IconButton } from '@mui/material'
import { AddCircle, Delete } from '@mui/icons-material'

interface AllergyFieldProps {
    allergy_values?: string[]
}

const AllergyForm = (props: AllergyFieldProps) => {
    const {allergy_values} = props
    const { t } = useTranslation('child_form')
    const [allergies, setAllergies] = useState(allergy_values ? [...allergy_values] : [''])

    useEffect(() => {
        if (allergy_values)
            setAllergies(allergy_values)
    }, [allergy_values])

    const handleAddAllergy = () => {
        setAllergies([...allergies, ''])
    }

    const handleDeleteAllergy = (index: number) => {
        const updatedAllergies = [...allergies]
        updatedAllergies.splice(index, 1)
        setAllergies(updatedAllergies)
    }

    return (
        <div>
            {Array.isArray(allergies) && allergies.map((allergy, index) => (
            <div key={index}>
                <br/>
                <TextField
                    label={t('allergy')}
                    name={`${index}-allergy`}
                    defaultValue={allergy}
                />
                { index !== 0 ?
                    <IconButton
                        aria-label="Delete"
                        onClick={() => handleDeleteAllergy(index)}
                    >
                        <Delete />
                    </IconButton> : null
                }
            </div>
            ))}
            <br/>
            <Button
                variant="contained"
                color="primary"
                startIcon={<AddCircle />}
                onClick={handleAddAllergy}
            >
                {t('add_allergy')}
            </Button>
        </div>
    )
}

export default AllergyForm
