import {useEffect, useContext} from 'react'

import { useNavigate } from 'react-router-dom'

import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import CancelIcon from '@mui/icons-material/Cancel'

import { IsLoggedInContext } from '@contexts/IsLoggedInContext'


const OzowErrorCallback = () => {
    const navigate = useNavigate()

    const {is_logged_in} = useContext(IsLoggedInContext)

    useEffect(() => {
        const delay = 3000
        const redirectTimer = setTimeout(() => {
            if (is_logged_in)
                navigate('/finance/sales-overview')
            else
                navigate('/login')
        }, delay)

        return () => clearTimeout(redirectTimer)
    }, [is_logged_in, navigate])

    return (
        <Box style={{display:'flex', flexDirection: 'column', height: '100vh', alignItems: 'center', justifyContent: 'center'}}>
            <CancelIcon color={'error'}/>
            <Typography variant='h6' sx={{color: 'error.main', fontWeight: 'bold'}}> Transfer failed </Typography>
            <br/>
            <Typography variant='body1'> There was an error processing your request </Typography>
            <Typography variant='body1'> You will be redirected shortly... </Typography>
        </Box>
    )
}

export default OzowErrorCallback