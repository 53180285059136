import ChildPaymentsActions from "@actions/Finance/PaymentActions/childPaymentActions";
import { ChangeCompanyRerenderContext } from "@contexts/TriggerRerender/companyRerender";
import { getLocalStorageCompany } from "@utils/localStorage/company";
import { ReactElement, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FinancesPage from "../Finances";
import { Box } from "@mui/system";
import INastixTabs from "@components/navigation/INastixTabs";
import Tooltip from '@mui/material/Tooltip'
import CachedIcon from '@mui/icons-material/Cached'
import IconButton from '@mui/material/IconButton'
import CompanyActions from "@actions/CompanyActions/companyActions";
import ChildYearlyTable from "@components/tables/CRUD/PaymentsTables/ChildPaymentsTable/ChildYearlyTable";
import ChildTermlyTable from "@components/tables/CRUD/PaymentsTables/ChildPaymentsTable/ChildTermlyTable";
import ChildHalfTermlyTable from "@components/tables/CRUD/PaymentsTables/ChildPaymentsTable/ChildHalfTermlyTable";
import ChildMonthlyTable from "@components/tables/CRUD/PaymentsTables/ChildPaymentsTable/ChildMonthlyTable";


const child_payment_actions = new ChildPaymentsActions()

const ChildPaymentsPage = () => {
    const navigate = useNavigate()

    const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)

    const [tabs, setTabs] = useState<ReactElement[]>([])
	const [nav_items, setNavItems] = useState<string[]>([])

    useEffect(() => {
        const company = getLocalStorageCompany()
        const acts_like_a_franchise = company.acts_like_a_franchise

        if (!acts_like_a_franchise) {
            navigate('/finance/royalty-invoices')
			return
        } else {
            const company_actions = new CompanyActions()
            company_actions.getCompanySettings(company.company_uuid.toString())
            .then(data => {
                const finance = data.finance
                let all_tabs: ReactElement[] = []
                let all_navs: string[] = []

                if(finance.allows_yearly_fees === true) {
                    all_tabs = [...all_tabs, 
                        <ChildYearlyTable action={child_payment_actions}/>,
                    ]
                    all_navs = [...all_navs, t('yearly_payers')]
                }
                if(finance.allows_termly_fees === true) {
                    all_tabs = [...all_tabs,
                        <ChildTermlyTable action={child_payment_actions}/>,
                        <ChildHalfTermlyTable action={child_payment_actions}/>,
                    ]
                    all_navs = [...all_navs, t('termly_payers'), t('half_termly_payers')]
                }
                if(finance.allows_monthly_fees === true) {
                    all_tabs = [...all_tabs,
                        <ChildMonthlyTable action={child_payment_actions}/>,
                    ]
                    all_navs = [...all_navs, t('monthly_payers')]
                }

                setTabs(all_tabs)
                setNavItems(all_navs)
        })
        }
    }, [change_company_rerender, navigate])

    const handleRefresh = () => {
        child_payment_actions.get()
        .then((data) => {
            console.log(data)
        })
    }

    const [ t ] = useTranslation('payments')

    return(
        <FinancesPage>
            <Box sx={{display: 'flex'}}>
                <INastixTabs nav_items={nav_items} tab_items={tabs}>
                    <Tooltip title="Refresh payments">
						<IconButton sx={{position: 'absolute', top: 0, right: 0, marginRight: '1rem'}} onClick={handleRefresh}>
							<CachedIcon/>
						</IconButton>
					</Tooltip>
                </INastixTabs>
            </Box>
        </FinancesPage>
    )
}

export default ChildPaymentsPage