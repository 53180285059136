import { useContext } from "react"
import { useNavigate } from 'react-router-dom'

import Link from '@mui/material/Link'

import GenericFetchWrapper from "@adapters/api/fetchWrappers/genericFetchWrapper"
import { JWT_RESEND_VERIFICATION_LINK_ENDPOINT } from "@adapters/routes/endpoints"

import { MessageContext } from "@contexts/MessageContext"
import log from "loglevel"


const ResendEmailVerificationLink = () => {
	const navigate = useNavigate()

    const {setMessage} = useContext(MessageContext)

    const resendVerificationLink = () => {
        const fetch_wrapper = new GenericFetchWrapper()
        fetch_wrapper.post(JWT_RESEND_VERIFICATION_LINK_ENDPOINT, {})
        .then(() => {
            setMessage(props => ({...props, success: "Verification link sent"}))
            navigate('/verify-email')
        })
        .catch(error => {
            log.error(error.message)
        })
    }

    return (
        <>
        {/* <TextField 
            required
            error={email_error}
            name="email"
            label={t('email_address')} 
            value={email}
            onChange={handleEmailChange}
            onBlur={handleBlur}
            helperText={email_error && t('invalid_email')}
            sx={{display: 'block'}}
            fullWidth
            autoComplete="email"
        /> */}
        <Link href='#' onClick={resendVerificationLink}> Resend link </Link>
        </>
    )
}

export default ResendEmailVerificationLink