import {useState} from 'react'
import { useTranslation } from 'react-i18next'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import NotificationActions from '@actions/CRUDActions/NotificationActions/notifcationActions'
import { MessageType } from '@actions/CRUDActions/NotificationActions/enum'
import Table from '@components/tables/Table/Table'

import AcceptNotificationDialogHandler from './AcceptNotificationDialogHandler'
import DeclineNotificationDialogHandler from './DeclineNotificationDialogHandler'
import { AuthorisedInstructorIdsContext } from './AuthorisedInstructorIdsContext'
import { OpenInstructorFinanceDialogContext } from './OpenInstructorFinanceDialogContext'
import InstructorFinanceDialog from './InstructorFinanceDialog'

import log from 'loglevel'


export interface TableProps {
	action: NotificationActions
}


const NotificationTable = (props: TableProps) => {
	const {action} = props

	const [open_instructor_finance_dialog, setOpenInstructorFinanceDialog] = useState(false)
	const [authorised_instructor_ids, setAuthorisedInstructorIds] = useState<string[]>([])

	const { t } = useTranslation('notifications_table')

	const columns = [
		{ field: 'id', headerName: 'ID' },
		{ field: 'message', headerName: t('message'), flex: 1,
			renderCell: (params: any) => {
				const notification = params.row
				const message_type = notification.message_type
				let message = ""
				if (message_type === MessageType.AUTHORIZE_INSTRUCTOR)
					message = t('authorize_instructor')
				else if (message_type === MessageType.AUTHORIZE_ASSISTANT)
					message = t('authorize_assistant')
				else if (message_type === MessageType.AUTHORIZE_FRANCHISE_OPERATOR)
					message = t('authorize_franchise_operator')
				else if (message_type === MessageType.AUTHORIZE_FRANCHISEE_ADMIN)
					message = t('authorize_franchisee_admin')
				else if (message_type === MessageType.AUTHORIZE_USER)
					message = t('authorize_user')
				else if (message_type === MessageType.AUTHORIZE_ADMINISTRATOR)
					message = t('authorize_administrator')
				else if (message_type === MessageType.AUTHORIZE_SCHOOL_OPERATOR)
					message = t('authorize_school_operator')
				else if (message_type === MessageType.AUTHORIZE_SPONSORED_MEMBER)
					message = t('authorize_sponsored_member')
				else
					log.error(`message_type is ${message_type} which doesn't exist in MessageType`)
	
				const date_created = new Date(notification.date_created)
				const date: Date = new Date(date_created)
	
				const options: Intl.DateTimeFormatOptions = { day: "numeric", month: "long" }
				const formatted_date: string = date.toLocaleDateString("en-US", options)
	
				return (
					<Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
						<Typography sx={{width: 10}}> {notification.username} </Typography>
						<Typography width={5}> {message} </Typography>
						<Typography mr={2}> {formatted_date} </Typography>
					</Box>
				)
			},
		},
	]

    return (
		<>
		<OpenInstructorFinanceDialogContext.Provider value={{open_instructor_finance_dialog, setOpenInstructorFinanceDialog}}>
		<AuthorisedInstructorIdsContext.Provider value={{authorised_instructor_ids, setAuthorisedInstructorIds}}>
        	<Table dialogs={[DeclineNotificationDialogHandler, AcceptNotificationDialogHandler]} action={action} columns={columns}></Table>
			<InstructorFinanceDialog open={open_instructor_finance_dialog} setOpen={setOpenInstructorFinanceDialog}/>
		</AuthorisedInstructorIdsContext.Provider>
		</OpenInstructorFinanceDialogContext.Provider>
		</>
	)
}

export default NotificationTable