import React, {useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next'

import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select, {SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'

import CompanyActions from '@actions/CompanyActions/companyActions'
import { CountryTerm } from '@pages/Setup/CompanySetup/FinanceAndTerms'
import { getLocalStorageCompany } from '@utils/localStorage/company'


interface SelectCountryTermsOnlyProps {
    country_term_id: number | "All" | null
    setCountryTermId: React.Dispatch<React.SetStateAction<number | "All" | null>>
    use_all?: boolean 
}

const SelectCountryTermsOnly = (props: SelectCountryTermsOnlyProps) => {
    const {country_term_id, setCountryTermId, use_all} = props

    const { t } = useTranslation('set_up')

    const [country_terms, setCountryTerms] = useState<CountryTerm[] | null>(null)

    useEffect(() => {
        const company = getLocalStorageCompany()

        if (company.country_name) {
            const company_actions = new CompanyActions()
            company_actions.getCountryTerms(company.country_name)
            .then((country_terms: CountryTerm[]) => {
                if (!country_term_id) {
                    if (country_terms.length > 1) {
                        if (use_all)
                            setCountryTermId('All')
                        else
                            setCountryTermId(country_terms[0].id)
                    }
                    else
                        setCountryTermId(country_terms[0].id)
                }
                setCountryTerms(country_terms)
            }) 
        }
    }, [country_term_id, setCountryTermId, use_all])

    const handleCountryTermChange = (event: SelectChangeEvent) => {
        if (event.target.value === 'All') {
            setCountryTermId('All')
            return 
        }

        const country_term = country_terms?.find(country_term => country_term.id.toString() === event.target.value.toString())
        if (country_term)
            setCountryTermId(country_term.id)
    }

    return (
        <>
        {country_term_id && country_terms &&
            <Box sx={{display: 'flex', gap: '1rem', width: '160px', margin: 'auto', marginTop: '1rem', marginBottom: '0.5rem'}}>
                <FormControl fullWidth>
                    <InputLabel id="country-term-type-select-label"> {t('country_terms')} </InputLabel>
                    <Select
                        labelId="country-term-type-select-label"
                        id="country-term-type-select"
                        value={country_term_id.toString()}
                        name="country_term"
                        label={t('country_terms')}
                        onChange={handleCountryTermChange}
                        required
                    >
                        {use_all && <MenuItem key={'All'} value={'All'}>All</MenuItem>}
                        {country_terms?.map((country_term: CountryTerm) => (
                            <MenuItem key={country_term.id} value={country_term.id}>{country_term.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>
        }      
        </>
    )
}

export default SelectCountryTermsOnly