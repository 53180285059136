import {useEffect, useState, useMemo, useContext} from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import ClassActions from '@actions/CRUDActions/ClassActions/classActions'
import ClassTable from '@components/tables/CRUD/ClassTable'
import EvaluationActions from '@actions/CRUDActions/ClassActions/evaluationActions'
import INastixTabs from '@components/navigation/INastixTabs'
import RegisterTable from '@components/tables/CRUD/ClassTable/Registration/RegisterTable'
import RegisterActions from '@actions/CRUDActions/ClassActions/registerActions'
import AttendanceTable from '@components/tables/CRUD/ClassTable/Attendance/AttendanceTable'
import AttendanceActions from '@actions/CRUDActions/ClassActions/attendanceActions'
import EvaluationsTable from '@components/tables/CRUD/ClassTable/Evaluations/EvaluationsTable'
import TimeTable from '@components/tables/TimeTable'

import { NumOfRegistersContext } from './contexts/NumOfRegistersContext'
import { NumOfAwaitingEvaluationsContext } from './contexts/AwaitingEvaluationsContext'
import { getLocalStorageUser } from '@utils/localStorage/user'
// eslint-disable-next-line
import { companyIsInGroup, isInGroup } from '@utils/utils/util'
// eslint-disable-next-line
import { CompanyType, UserType } from '@utils/enums/enums'
import { getLocalStorageCompany } from '@utils/localStorage/company'
// eslint-disable-next-line
import FranchiseeClassTable from '@components/tables/CRUD/ClassTable/FranchiseeClass'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'


const ClassesTabsMap = {
    'timetable': 1,
    // 'register': 2,
    // 'attendance': 3,
    'assessments': 2
}

const ClassTabs = () => {
	const { t } = useTranslation('classes')

	const class_actions = new ClassActions()
	const attendance_actions = new AttendanceActions()
	const evaluation_actions = new EvaluationActions()

    const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)

	const location = useLocation()
	const tab_key = new URLSearchParams(location.search).get('tab')

	// const register_actions = useMemo(() => new RegisterActions(), [])

	const connected_company = getLocalStorageCompany()
	const is_master_franchise_doesnt_act_like_franchise_or_franchisor = !connected_company.acts_like_a_franchise && companyIsInGroup(connected_company, [CompanyType.MASTER_FRANCHISE, CompanyType.FRANCHISOR])

	// const user = getLocalStorageUser()
	// const company = getLocalStorageCompany()
	// const is_instructor = isInGroup(user, company, UserType.INSTRUCTOR)
	// const is_master_franchise = companyIsInGroup(company, CompanyType.MASTER_FRANCHISE)

	useEffect(() => {
		// register_actions.get()
		// .then(data => {
		// 	setNumOfRegisters(data.length)
		// })

	}, [change_company_rerender, is_master_franchise_doesnt_act_like_franchise_or_franchisor])  // }, [awaiting_evaluation_actions, register_actions, change_company_rerender])

	let nav_items = [t('classes'), t('evaluations')]  // let nav_items = [t('classes'), t('timetable'), t('attendance'), t('evaluations')]

	if (!is_master_franchise_doesnt_act_like_franchise_or_franchisor) {
		// nav_items.splice(2, 0, t('register'))
		nav_items.splice(1, 0, t('timetable'))
	}

	// if (is_master_franchise) {
	// 	nav_items.splice(1, 0, "franchisee classes")
	// }

	let tab_items = [
		<ClassTable action={class_actions}/>,
		// <AttendanceTable action={attendance_actions}/>,
		<EvaluationsTable action={evaluation_actions}/>
	]

	// if (is_master_franchise) {
	// 	tab_items.splice(1, 0, 
	// 		<FranchiseeClassTable action={class_actions}/>
	// 	)
	// }

	// tab_items.splice(2, 0, 
	// 	<NumOfRegistersContext.Provider value={{num_of_registers, setNumOfRegisters}}>
	// 		<RegisterTable action={register_actions}/>
	// 	</NumOfRegistersContext.Provider>
	// )

	if (!is_master_franchise_doesnt_act_like_franchise_or_franchisor) {
		tab_items.splice(1, 0, 
			<TimeTable/>,
		)
	}

	return (
		<INastixTabs
			nav_items={nav_items}
			tab_items={tab_items}
			tabs_map={ClassesTabsMap}
			tabs_key={tab_key}
			// needs_a_badge={{"Register": num_of_registers, "Awaiting Assessments": num_of_awaiting_evaluations}}
			// needs_a_badge={{"Awaiting Assessments": num_of_awaiting_evaluations}}
		/>
	)
}

export default ClassTabs