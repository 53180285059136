enum MessageType {
    AUTHORIZE_SCHOOL_OPERATOR = 'authorize_school_operator',
    AUTHORIZE_FRANCHISE_OPERATOR = 'authorize_franchise_operator',
    AUTHORIZE_FRANCHISEE_ADMIN = 'authorize_franchisee_admin',
    AUTHORIZE_USER = 'authorize_user',
    AUTHORIZE_INSTRUCTOR = 'authorize_instructor',
    AUTHORIZE_ASSISTANT = 'authorize_assistant',
    AUTHORIZE_ADMINISTRATOR = 'authorize_administrator',
    AUTHORIZE_SPONSORED_MEMBER = 'authorize_sponsored_member'
}

enum CompanyMessageType {
    AUTHORIZE_COMPANY = 'authorize_company',
    AUTHORIZE_FRANCHISEE = 'authorize_franchisee',
    AUTHORIZE_SCHOOL = 'authorize_school'
}

export {MessageType, CompanyMessageType}