import { FormDataEntryType } from "@components/forms/Form"
import { SetMessage } from "@contexts/MessageContext"


const validateFileUploadsAndAppendToFormData = (has_checked_instructor: boolean, south_african_gl_course: File | null, police_clearance_certificate: File | null, copy_of_id_photo: File | null, employment_contract: File | null, form_data: FormData, form_data_object: FormDataEntryType, setMessage: SetMessage) => {
    if (!has_checked_instructor && !form_data_object.date_of_qualification) {
        setMessage(props => ({...props, error: 'Date of qualification required'}))
        return null
    }
    if (!police_clearance_certificate) {
        setMessage(props => ({...props, error: 'Police clearance required'}))
        return null
    }
    if (!copy_of_id_photo) {
        setMessage(props => ({...props, error: 'Copy of ID photo required'}))
        return null
    }
    if (!employment_contract && !has_checked_instructor) {
        setMessage(props => ({...props, error: 'Employment contract required'}))
        return null
    }

    // gl course can be blank
    if (south_african_gl_course)
        form_data.append('south_african_gl_course', south_african_gl_course)

    form_data.append('police_clearance_certificate', police_clearance_certificate)
    form_data.append('copy_of_id_photo', copy_of_id_photo)
    form_data.append('employment_contract', employment_contract as File)

    return true
}

export {
    validateFileUploadsAndAppendToFormData
}