import { createContext } from 'react'


export type SetCompanySetupStep = React.Dispatch<React.SetStateAction<number>>

interface ICompanySetupStepContext {
    company_setup_step: number
    setCompanySetupStep: SetCompanySetupStep
}

export const CompanySetupStepContext = createContext<ICompanySetupStepContext>({
    company_setup_step: 0,
    setCompanySetupStep: () => {}
})