import {useEffect, useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import {
    Chart as ChartJS,
    ChartData,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

import { ChartBox } from '../styles'
import ToggleChartVisibility from '../ToggleChartVisibility'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import ClassActions from '@actions/CRUDActions/ClassActions/classActions'
import InstructorActions from '@actions/CRUDActions/InstructorActions/instructorActions'
import { FranchiseOption } from '..'


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)


interface InstructorsVsClassesProps {
    edit_dashboard_open: boolean
    checked: boolean
    setChecked: React.Dispatch<React.SetStateAction<any>>
    selected_franchise_option: FranchiseOption | null
}

const InstructorsVsClasses = (props: InstructorsVsClassesProps) => {
    const {edit_dashboard_open, checked, setChecked, selected_franchise_option} = props

    const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)
    const { t } = useTranslation('dashboard')

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: t('instructors_vs_classes'),
            },
        },
    }
    
    const labels = [t('no_of_instructors'), t('avg_classes_per_week')]

    const [data, setData] = useState<ChartData<"bar", (number | [number, number] | null)[], string>>({labels, datasets: []})

    useEffect(() => {
        if (!selected_franchise_option)
            return

        const setInstructorVsClassesData = async () => {
            const class_actions = new ClassActions()
            const instructor_actions = new InstructorActions()
    
            const classes_promise = class_actions.get(undefined, undefined, {
                by_franchise_or_tenant_school_uuid: selected_franchise_option.id, 
                num_of_rows_only: true,
            })
            const instructors_promise = instructor_actions.get(undefined, undefined, {by_franchise_or_tenant_school_uuid: selected_franchise_option.id, num_of_rows_only: true}) 			
            
            const [classes_data, instructors_data]: [number, number] = await Promise.all([
                classes_promise,
                instructors_promise,
            ])
    
            setData({
                labels,
                datasets: [
                    {
                        data: [instructors_data, classes_data],
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                ],
            })
        }

        setInstructorVsClassesData()
    }, [change_company_rerender, selected_franchise_option])

    return (
        <>
        <ChartBox edit_dashboard_open={edit_dashboard_open} checked={checked}>
            <Bar options={options} data={data} />
            <canvas style={{ display: 'inline', height: 0 }} />
        </ChartBox>
        {edit_dashboard_open && 
            <>
            <ToggleChartVisibility checked={checked} setChecked={setChecked}/>
            </>
        }
        </>
    )
}

export default InstructorsVsClasses
