import { LocalStorageCompany } from "@utils/interfaces/interfaces"


const getLocalStorageCompany = (): LocalStorageCompany => {
    const company_json = localStorage.getItem("company")

    // if (process.env.REACT_APP_ENVIRONMENT !== 'production' && company_json === undefined) {
    //     alert('Function called with undefined company_json')
    //     const error = new Error()
    //     const stackLines = error.stack?.split('\n')
    //     if (stackLines && stackLines.length >= 3) {
    //         const callerLine = stackLines[2]
    //         const fileNameMatches = callerLine.match(/\s\((.*\.ts)\)/)
    //         if (fileNameMatches && fileNameMatches.length >= 2) {
    //             const fileName = fileNameMatches[1]
    //             alert(`Function called from file: ${fileName}`)
    //         }
    //     }
    // }

    const company = company_json ? JSON.parse(company_json) : {}

    return company
}

const updateLocalStorageCompany = (key: string, value: any): LocalStorageCompany => {
    const company = getLocalStorageCompany()
    const updated_company = { ...company, [key]: value }  // create a new object with the updated property

    localStorage.setItem('company', JSON.stringify(updated_company))

    return updated_company
}


export {getLocalStorageCompany, updateLocalStorageCompany}