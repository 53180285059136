import { SvgIcon, SvgIconProps } from '@mui/material'

import ChatbotAssistant from '@images/inastix/chatbot_assitant.png'


const ChatbotAssistantIcon: React.FC<SvgIconProps> = (props) => {
    return (
        <SvgIcon {...props}>
            <image
                href={ChatbotAssistant}
                height="100%"
                width="100%"
                preserveAspectRatio="xMidYMid meet"
            />
        </SvgIcon>
    )
}

export default ChatbotAssistantIcon
