import {useEffect, useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

import { DialogFormSubHeadingTypography } from '@styles/Typography'
import { Franchisee } from '@utils/interfaces/interfaces'
import FranchiseeActions from '@actions/CRUDActions/FranchiseeActions/franchiseeActions'
import INastixCardSlider from '@components/sliders/CardSlider'
import { AuthorisedFranchiseIdsContext } from './context'



interface FranchiseeFormProps {
    franchisee_id: string
}

const FranchiseeForm = (props: FranchiseeFormProps) => {
    const {franchisee_id} = props
    const {t} = useTranslation('franchise_table')

    return (
        <FormControl sx={{width: '50%', margin: 'auto'}}>
            <TextField
                id={`${franchisee_id}:royalties_percentage`}
                label={t('royalty')}
                type="number"
                name={`${franchisee_id}:royalties_percentage`}
                required
                inputProps={{ step: '0.01' }} // Allow two decimal points
            />
            <br/>
        </FormControl>
    )
}


const FranchiseesMap = () => {
    const {authorised_franchise_ids} = useContext(AuthorisedFranchiseIdsContext)

    const [franchisees, setFranchisees] = useState<Franchisee[]>([])

    useEffect(() => {
        if (authorised_franchise_ids.length) {
            const franchisee_actions = new FranchiseeActions()
            franchisee_actions.getFranchiseesByCompanyUUIDs(authorised_franchise_ids)
            .then((franchisees: Franchisee[]) => {
                setFranchisees(franchisees)
            })
        }
    }, [authorised_franchise_ids])
  
    return (
        <INastixCardSlider slider_length={franchisees.length}>
            {franchisees.map((franchisee, index) => (
                <div key={index}>
                    <DialogFormSubHeadingTypography> {franchisee.tenant_name} </DialogFormSubHeadingTypography>
                    <FranchiseeForm franchisee_id={franchisee.id}/>
                </div>
            ))}
        </INastixCardSlider>
    )
}

export default FranchiseesMap
  