import {useState} from 'react'

import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import { Department } from './enum'
import { useTranslation } from 'react-i18next'


const SelectDepartments = () => {
    const [department, setDepartment] = useState('')

    const handleChange = (event: SelectChangeEvent) => {
        setDepartment(event.target.value)
    }

    const { t } = useTranslation('contact_form')

    return (
        <>
        <Box sx={{ width: 200 }}>
            <FormControl fullWidth>
                <InputLabel id="department-select-label">{t('departments')}</InputLabel>
                <Select
                    labelId="department-select-label"
                    id="department-select"
                    value={department}
                    label={t('department')}
                    name="department"
                    onChange={handleChange}
                >
                    <MenuItem key={Department.MONKEYNASTIX} value={Department.MONKEYNASTIX} sx={{textTransform: "capitalize"}}>{Department.MONKEYNASTIX}</MenuItem>
                    <MenuItem key={Department.DEVS} value={Department.DEVS} sx={{textTransform: "capitalize"}}>{Department.DEVS}</MenuItem>
                </Select>
            </FormControl>
        </Box>  
        </>
    )
}

export default SelectDepartments