import { createContext } from 'react'


export type SetNumOfChildrenNotAllocated = React.Dispatch<React.SetStateAction<number>>

interface INumOfChildrenNotAllocatedContext {
    num_of_children_not_allocated: number
    setNumOfChildrenNotAllocated: SetNumOfChildrenNotAllocated
}

export const NumOfChildrenNotAllocatedContext = createContext<INumOfChildrenNotAllocatedContext>({
    num_of_children_not_allocated: 0,
    setNumOfChildrenNotAllocated: () => {}
})