import {useEffect, useState, useMemo, useContext} from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import INastixTabs from '@components/navigation/INastixTabs'
import ChildActions from '@actions/CRUDActions/ChildActions/childActions'
import ChildTable from '@components/tables/CRUD/ChildTable'
import ChildNotAllocatedTable from '@components/tables/CRUD/ChildTable/ChildNotAllocatedTable/ChildNotAllocatedTable'
import { Parent, School } from '@utils/interfaces/interfaces'
import ParentTable from '@components/tables/CRUD/ParentTable'
import ParentActions from '@actions/CRUDActions/ParentActions/parentActions'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import ChildNeedsSchoolTable from '@components/tables/CRUD/ChildTable/ChildNeedsSchoolTable/ChildNeedsSchoolTable'
import SchoolActions from '@actions/CRUDActions/SchoolActions/schoolActions'

import { NumOfChildrenNeedingSchoolContext } from './contexts/NumOfChildrenNeedingSchool'
import { ParentsContext } from './contexts/Parents'
import { SchoolsContext } from './contexts/Schools'
import { NumOfChildrenNotAllocatedContext } from './contexts/NumOfChildrenNotAllocated'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { companyIsInGroup } from '@utils/utils/util'
import { CompanyType } from '@utils/enums/enums'
import ArchivedChildTable from '@components/tables/CRUD/ChildTable/ArchivedChildTable/ArchivedChildTable'
import ArchivedChildActions from '@actions/CRUDActions/ArchivedChildActions/archivedChildActions'


// used to map url to the iNastix Tab component
const MemberTabsMap = {
    'children-not-allocated': 1,
	'children-without-schools': 2,
    'parents': 3,
    'archived_children': 4
}

const MemberTabs = () => {
	const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)

	const [num_of_children_not_allocated, setNumOfChildrenNotAllocated] = useState(0)
	const [num_of_children_needing_school, setNumOfChildrenNeedingSchool] = useState(0)
    const [parents, setParents] = useState<Parent[]>([])
    const [schools, setSchools] = useState<School[]>([])

	const child_actions = useMemo(() => new ChildActions(), [])
	const archived_child_actions = useMemo(() => new ArchivedChildActions(), [])
	const parent_actions = useMemo(() => new ParentActions(), [])
	const { t } = useTranslation('children')

	const location = useLocation()
	const tab_key = new URLSearchParams(location.search).get('tab')

	const connected_company = getLocalStorageCompany()
	const is_master_franchise_doesnt_act_like_franchise_or_franchisor = !connected_company.acts_like_a_franchise && companyIsInGroup(connected_company, [CompanyType.MASTER_FRANCHISE, CompanyType.FRANCHISOR])

	const nav_items = [t('children'),  t('parents')]
	if (!is_master_franchise_doesnt_act_like_franchise_or_franchisor) {
		nav_items.splice(1, 0, 
			t('children_without_classes'), t('children_without_schools'),
		)
	}

	nav_items.push('Archived Children')

	const tab_items = [
		<ChildTable action={child_actions} />,
		<ParentTable action={parent_actions}/>,
	]

	if (!is_master_franchise_doesnt_act_like_franchise_or_franchisor) {
		tab_items.splice(1, 0, 
			<ChildNotAllocatedTable action={child_actions}/>,
			<NumOfChildrenNeedingSchoolContext.Provider value={{num_of_children_needing_school, setNumOfChildrenNeedingSchool}}>
				<ChildNeedsSchoolTable action={child_actions}/>
			</NumOfChildrenNeedingSchoolContext.Provider>,
		)
	}

	tab_items.push(<ArchivedChildTable action={child_actions}/>)
	
	useEffect(() => {
		if (!is_master_franchise_doesnt_act_like_franchise_or_franchisor) {
			child_actions.get(undefined, undefined, {'has_school': true, 'num_of_rows_only': true, 'company_class__isnull': true})
			.then((children_length: number) => {
				setNumOfChildrenNotAllocated(children_length)
			})
			child_actions.get(undefined, undefined, {'has_school': false, 'num_of_rows_only': true})
			.then((children_length: number) => {
				setNumOfChildrenNeedingSchool(children_length)
			})
		}
	}, [child_actions, change_company_rerender, is_master_franchise_doesnt_act_like_franchise_or_franchisor])

	useEffect(() => {
		if (!is_master_franchise_doesnt_act_like_franchise_or_franchisor) {
			const school_actions = new SchoolActions()
			school_actions.get()
			.then((data: School[]) => {
				if (!Object.keys(data).length) {
					setSchools([])
					return 
				}
				setSchools(data)
			})
			const parent_actions = new ParentActions()
			parent_actions.get()
			.then((data: Parent[]) => {
				setParents(data)
			})
		}
    }, [change_company_rerender, is_master_franchise_doesnt_act_like_franchise_or_franchisor])

	return (
		<ParentsContext.Provider value={{parents, setParents}} >
		<SchoolsContext.Provider value={{schools, setSchools}} >
		<NumOfChildrenNotAllocatedContext.Provider value={{num_of_children_not_allocated, setNumOfChildrenNotAllocated}}>
		<INastixTabs
			nav_items={nav_items}
			tab_items={tab_items}
			tabs_map={MemberTabsMap}
			tabs_key={tab_key}
			needs_a_badge={{
				"children without classes": num_of_children_not_allocated > 0 ? num_of_children_not_allocated : 0,
				"children without schools": num_of_children_needing_school > 0 ? num_of_children_needing_school : 0
			}}
		/>
		</NumOfChildrenNotAllocatedContext.Provider>
		</SchoolsContext.Provider>
		</ParentsContext.Provider>
	)
}

export default MemberTabs