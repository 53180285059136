import { SetSetupStep } from "@contexts/SetupStepContext"
import { SetIsLoggedIn } from "@contexts/IsLoggedInContext"
import { LocalStorageCompany, LocalStorageUser } from "@utils/interfaces/interfaces"


export interface LoginData {
    user: LocalStorageUser
    access_token: string
    refresh_token: string
}


const handleLogin = (data: LoginData, setSetupStep: SetSetupStep, setIsLoggedIn: SetIsLoggedIn) => {
    setSetupStep(data.user.setup_step)
    localStorage.setItem('user', JSON.stringify(data.user))
            
    localStorage.setItem('access_token', data.access_token)
    localStorage.setItem('refresh_token', data.refresh_token)
    setIsLoggedIn(true)
}

const handleCompanyLocalStorageInit = (tenant: LocalStorageCompany) => {
    // IF NO ERROR ARISES AFTER A WHILE THIS CODE CAN BE REMOVED
    // if (process.env.REACT_APP_ENVIRONMENT !== 'production' && tenant === undefined) {
    //     alert('Function called with undefined tenant')
    //     const error = new Error()
    //     const stackLines = error.stack?.split('\n')
    //     if (stackLines && stackLines.length >= 0) {
    //         const callerLine = stackLines[2]
    //         const fileNameMatches = callerLine.match(/\s\((.*\.ts)\)/);
    //         if (fileNameMatches && fileNameMatches.length >= 2) {
    //             const fileName = fileNameMatches[1]
    //             alert(`Function called from file: ${fileName}`)
    //         }
    //     }
    // }

    localStorage.setItem('company', JSON.stringify(tenant))
}

export {handleLogin, handleCompanyLocalStorageInit}