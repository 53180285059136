import {useContext, useState} from 'react'
import { useTranslation } from 'react-i18next'

import log from 'loglevel'

import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Select, {SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Box from '@mui/material/Box'

import { CompanySetupStepContext } from '@contexts/CompanySetupStepContext'
import { SchoolType } from '@utils/enums/enums'
import { SchoolMap } from '@utils/maps/maps'
import { SubmitButon } from '@styles/Buttons'
import { SetupBox, SetupHeadingTypography } from '@styles/Setup'
import Form, { FormDataEntryType } from '@components/forms/Form'
import SchoolActions from '@actions/CRUDActions/SchoolActions/schoolActions'
import { MessageContext } from '@contexts/MessageContext'
import { getLocalStorageCompany, updateLocalStorageCompany } from '@utils/localStorage/company'

import SelectCountryTerms from './SelectCountryTerms'


const SchoolFinanceSetup = () => {
    const {setCompanySetupStep} = useContext(CompanySetupStepContext)
    const {setMessage} = useContext(MessageContext)
    const { t } = useTranslation('school_finance_setup')

    const [school_type, setSchoolType] = useState('')
    const [extracurricular_activity, setExtracurricularActivity] = useState('')

    const handleChange = (event: SelectChangeEvent) => {
        setSchoolType(event.target.value as string)
    }

    const createFields = () => (
        <SetupBox>
            <SetupHeadingTypography variant='h2'> {t('additional_setup')} </SetupHeadingTypography>
            <br/>
            <br/>
            <FormControl sx={{minWidth: '200px'}}>
                <SelectCountryTerms country='' terms=''/>
                <Box sx={{display: 'flex', gap: '1rem', width: '300px'}}>
                    <FormControl fullWidth>
                        <InputLabel id="school-type-select-label">{t('school_types')}</InputLabel>
                        <Select
                            labelId="school-type-select-label"
                            id="school-type-select"
                            value={school_type}
                            name="school_type"
                            label={t('school_type')}
                            onChange={handleChange}
                        >
                            {Object.values(SchoolType).map((school_type: string, index: number) => (
                                <MenuItem key={index} value={school_type}>{SchoolMap[school_type]}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <br/>
                <FormControl fullWidth>
                    <InputLabel id="extracurricular-activitiy-select-label">{t('extracurricular_activity_type')}</InputLabel>
                    <Select
                        labelId="extracurricular-activitiy-select-label"
                        id="extracurricular-activitiy-select"
                        name="extracurricular_activitiy_type"
                        label={t('extracurricular_activity_type')}
                        value={extracurricular_activity}
                        onChange={handleExtracurricularActivityChange}
                    >
                        <MenuItem value={"intramural"}> {t('intramural')} </MenuItem>
                        <MenuItem value={"extramural"}> {t('extramural')} </MenuItem>
                    </Select>
                </FormControl>
                <SubmitButon variant='contained' type="submit"> {t('submit')} </SubmitButon>
            </FormControl>
        </SetupBox>
    )

    const handleExtracurricularActivityChange = (event: SelectChangeEvent) => {
        setExtracurricularActivity(event.target.value as string)
    }

    const onSubmit = async (form_data_object: FormDataEntryType): Promise<any> => {
        log.info(form_data_object)
        const school_actions = new SchoolActions()
        return school_actions.setFinanceSetup(form_data_object)
        .then(() => {
            const company = getLocalStorageCompany()
            updateLocalStorageCompany('setup_step', company.setup_step + 1)
            setCompanySetupStep(prop => prop + 1)
        })
        .catch(error => {
            log.error(error)
            setMessage(props => ({...props, error: t('something_went_wrong_please_refresh_and_try_again')}))
        })
    }

    return (
        <>
        <Form onSubmitCallback={onSubmit} createFields={createFields}/>
        </>
    )
}

export default SchoolFinanceSetup