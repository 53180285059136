import { PageHeadingTypography } from '@styles/Typography'
import Pages from '@pages/Page'

import NotifcationsTabs from './NotificationsTabs'
import { UserPermissions } from '@utils/enums/permissions'
import { useTranslation } from 'react-i18next'


const NotificationPage = () => {

	const { t } = useTranslation('notifications_table')

	return (
		<Pages page_title="Notifications" permission={UserPermissions.VIEW_NOTIFICATIONS}>
			<PageHeadingTypography> {t('notifications')} </PageHeadingTypography>
			<NotifcationsTabs/>
		</Pages>
	)
}

export default NotificationPage
