import { createContext } from 'react'


export type SetIsLoggedIn = React.Dispatch<React.SetStateAction<boolean>>

interface IIsLoggedInContext {
    is_logged_in: boolean
    setIsLoggedIn: SetIsLoggedIn
}

export const IsLoggedInContext = createContext<IIsLoggedInContext>({
    is_logged_in: false,
    setIsLoggedIn: () => {}
})