import { CRUDAction } from "@actions/Actions/actions"
import FranchiseCostingHandler from "@adapters/handlers/FranchiseCostingHandlers/franchiseCostingHandlers"


class FranchiseCostingActions extends CRUDAction {
    handler: FranchiseCostingHandler
    
    constructor() {
        super()
        this.handler = new FranchiseCostingHandler()
    }
}

export default FranchiseCostingActions