import { GenericAction } from "@actions/Actions/actions"
import XeroAccountCodesRoyaltiesHandler from "@adapters/handlers/Xero/XeroAccountCodesHandlers/xeroAccountCodesRoyaltiesHandler"


class XeroAccountCodesRoyaltiesActions extends GenericAction {
    handler: XeroAccountCodesRoyaltiesHandler

    constructor() {
        super()
        this.handler = new XeroAccountCodesRoyaltiesHandler()
    }
}

export default XeroAccountCodesRoyaltiesActions