import jsSHA from 'jssha'
import { MD5 } from 'crypto-js'


const getSha512Hash = (inputString: string): string => {
    let shaObj = new jsSHA('SHA-512', 'TEXT')
    shaObj.update(inputString)
    return shaObj.getHash('HEX')
}


const getMD5Hash = (inputString: string): string => {
    const md5Hash = MD5(inputString).toString()
    return md5Hash
}


export {
    getSha512Hash, getMD5Hash
}