import { styled } from '@mui/material/styles'
import Button, {ButtonProps} from '@mui/material/Button'


export const SubmitButon = styled(Button)<ButtonProps>(() => ({
    marginTop: '1.5rem',
    width: '100%'
}))


interface TableActionButonrProps extends ButtonProps {
    border_color?: string
}

export const TableActionButon = styled(Button, {
    shouldForwardProp: (prop) => prop !== 'border_color',
    })<TableActionButonrProps>(({border_color, disabled}) => ({
        border: '1px solid grey',
        borderRadius: '10px',
        textTransform: 'capitalize',
        ...((border_color && !disabled) && {borderColor: border_color})
}))
