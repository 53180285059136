import GenericFetchWrapper from "@adapters/api/fetchWrappers/genericFetchWrapper"
import { BaseHandler } from "@adapters/handlers/Handlers/handlers"
import { FRANCHISEES_ENDPOINT } from "@adapters/routes/endpoints"


class FranchiseesByCompanyUUIDsHandler extends BaseHandler {
    endpoint: string
    fetch_wrapper: GenericFetchWrapper

    constructor () {
        super()
        this.endpoint = FRANCHISEES_ENDPOINT + '/by-company-uuids'
        this.fetch_wrapper = new GenericFetchWrapper()
    }

    getByCompanyUUIDs (ids: string[]): Promise<any> {
        return this.fetch_wrapper.post(this.endpoint, {'ids': ids})
    }
}

export default FranchiseesByCompanyUUIDsHandler