import { styled } from '@mui/material/styles'
import Dialog, {DialogProps} from '@mui/material/Dialog'
import DialogTitle, {DialogTitleProps} from '@mui/material/DialogTitle'
import FormControl, {FormControlProps} from '@mui/material/FormControl'
import Button, {ButtonProps} from '@mui/material/Button'
import Divider, {DividerProps} from '@mui/material/Divider'


export const INastixDialog = styled(Dialog)<DialogProps>(({theme}) => ({
    '.MuiDialog-paper': {padding: '0 1rem 1rem 1rem'}
}))


export const INastixDialogTitle = styled(DialogTitle)<DialogTitleProps>(({theme}) => ({
    fontSize: theme.typography.h4.fontSize,
    fontWeight: 'bold',
    color: 'darkslategray',
    paddingBottom: '0.5rem'
}))


export const INastixDialogSubTitle = styled(DialogTitle)<DialogTitleProps>(({theme}) => ({
    fontSize: theme.typography.h6.fontSize,
    fontWeight: 'bold',
    color: 'darkslategray',
    paddingBottom: '0.5rem'
}))


export const DialogFormControl = styled(FormControl)<FormControlProps>(({theme}) => ({
    padding: '0 1rem 1rem 1rem'
}))


export const DialogButton = styled(Button)<ButtonProps>(() => ({
    marginTop: '2rem'
}))


export const DialogDivider = styled(Divider)<DividerProps>(() => ({
    marginBottom: '0.5rem'
}))