import {useState} from 'react'

import Box from '@mui/material/Box'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useTranslation } from 'react-i18next'


export enum Frequency {
    WEEKLY = 'weekly',
    DAILY = 'daily',
    MONTHLY = 'monthly'
}


interface SelectFrequencyProps {
    initial_frequency: Frequency | undefined
}

const SelectFrequency = (props: SelectFrequencyProps) => {
    const {initial_frequency} = props

    const [frequency, setFrequency] = useState<Frequency | ''>(initial_frequency ? initial_frequency : '')
  
    const handleChange = (event: SelectChangeEvent) => {
        setFrequency(event.target.value as Frequency)
    }

    const { t } = useTranslation('class_form')
      
    return (
        <>
        <Box sx={{ width: 200 }}>
            <FormControl fullWidth>
                <InputLabel id="frequency-select-label"> {t('frequency')} </InputLabel>
                <Select
                    labelId="frequency-select-label"
                    id="frequency-select"
                    value={frequency}
                    label={t('frequency')}
                    name="frequency"
                    onChange={handleChange}
                    required
                >
                    <MenuItem value={Frequency.DAILY}>{Frequency.DAILY}</MenuItem>
                    <MenuItem value={Frequency.WEEKLY}>{Frequency.WEEKLY}</MenuItem>
                    <MenuItem value={Frequency.MONTHLY}>{Frequency.MONTHLY}</MenuItem>
                </Select>
            </FormControl>
        </Box>  
        </>
    )
}

export default SelectFrequency