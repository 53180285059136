import { GenericAction } from "@actions/Actions/actions"
import XeroAccountCodesAllHandler from "@adapters/handlers/Xero/XeroAccountCodesAllHandlers/xeroAccountCodesAllHandlers"


class XeroAccountCodesAllActions extends GenericAction {
    handler: XeroAccountCodesAllHandler

    constructor() {
        super()
        this.handler = new XeroAccountCodesAllHandler()
    }
}

export default XeroAccountCodesAllActions