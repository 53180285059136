import { UserType } from '@utils/enums/enums'
import { createContext } from 'react'


export type SetSelectedUserType = React.Dispatch<React.SetStateAction<UserType>>

interface ISelectedUserTypeContext {
    selected_user_type: UserType
    setSelectedUserType: SetSelectedUserType
}

export const SelectedUserTypeContext = createContext<ISelectedUserTypeContext>({
    selected_user_type: UserType.DEFAULT,
    setSelectedUserType: () => {}
})