import { createContext } from 'react'


export type SetTriggerTableDataRerender = React.Dispatch<React.SetStateAction<boolean>>

interface ITriggerTableDataContext {
    trigger_table_data_rerender: boolean
    setTriggerTableDataRerender: SetTriggerTableDataRerender
}

export const TriggerTableDataRerenderContext = createContext<ITriggerTableDataContext>({
    trigger_table_data_rerender: false,
    setTriggerTableDataRerender: () => {}
})