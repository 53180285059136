import {useEffect, useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Divider } from '@mui/material'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

import { DialogFormSubHeadingTypography, SettingsSubHeadingTypography } from '@styles/Typography'
import { LocalStorageUser } from '@utils/interfaces/interfaces'
import InstructorActions from '@actions/CRUDActions/InstructorActions/instructorActions'
import { INastixCurrencyMap } from '@utils/maps/maps'
import { getLocalStorageCompany } from '@utils/localStorage/company'
// import INastixCardSlider from '@components/sliders/CardSlider'

import { AuthorisedInstructorIdsContext } from './AuthorisedInstructorIdsContext'


interface InstructorFormProps {
    instructor_id: number
    salary?: string
    commission?: string
    manage_classes?: boolean
    manage_members?: boolean
    transfer_children?: boolean
    manage_schools?: boolean
}

export const InstructorForm = (props: InstructorFormProps) => {
    const {instructor_id, salary="", commission="", manage_classes=true, manage_members=true, transfer_children=true, manage_schools=true} = props

    const company = getLocalStorageCompany()

    const { t } = useTranslation('notifications_table')

    const currency_symbol = INastixCurrencyMap[company.currency]

    return (
        <>
        <FormControl sx={{width: '75%', margin: 'auto'}}>
            <SettingsSubHeadingTypography> {t('finances')} </SettingsSubHeadingTypography>
            <br/>
            <TextField
                id={`${instructor_id}:salary`}
                label={`${t('salary')} (${currency_symbol})`}
                type="number"
                name={`${instructor_id}:salary`}
                required
                defaultValue={salary}
                inputProps={{ step: '0.01' }} // Allow two decimal points
            />
            <br/>
            <TextField
                id={`${instructor_id}:commission`}
                label={`${t('commission')}`}
                type="number"
                name={`${instructor_id}:commission`}
                required
                defaultValue={commission}
                inputProps={{ step: '0.01' }} // Allow two decimal points
            />
            <br/>
            <br/>
            <Divider/>
            <br/>
            <SettingsSubHeadingTypography> {t('permissions')} </SettingsSubHeadingTypography>
            <br/>
            <FormGroup>
                <FormControlLabel 
                    label={t('add_remove_edit_classes')}
                    control={            
                        <Checkbox defaultChecked={manage_classes} name={`${instructor_id}:manage_classes`}/>
                    } 
                    sx={{'.MuiFormControlLabel-label': {fontSize: '0.9rem'}}}
                />
                <FormControlLabel 
                    label={t('add_remove_edit_members')}
                    control={            
                        <Checkbox defaultChecked={manage_members} name={`${instructor_id}:manage_members`}/>
                    } 
                    sx={{'.MuiFormControlLabel-label': {fontSize: '0.9rem'}}}
                />
                <FormControlLabel 
                    label={t('transfer_children_between_classes')}
                    control={            
                        <Checkbox defaultChecked={transfer_children} name={`${instructor_id}:transfer_children`}/>
                    } 
                    sx={{'.MuiFormControlLabel-label': {fontSize: '0.9rem'}}}
                />
                <FormControlLabel 
                    label={t('add_remove_edit_schools')}
                    control={            
                        <Checkbox defaultChecked={manage_schools} name={`${instructor_id}:manage_schools`}/>
                    } 
                    sx={{'.MuiFormControlLabel-label': {fontSize: '0.9rem'}}}
                />
            </FormGroup>
        </FormControl>
        </>
    )
}


const InstructorsMap = () => {
    const {authorised_instructor_ids} = useContext(AuthorisedInstructorIdsContext)

    const [instructors, setInstructors] = useState<LocalStorageUser[]>([])

    useEffect(() => {
        if (authorised_instructor_ids.length) {
            const instructor_actions = new InstructorActions()
            instructor_actions.get(undefined, undefined, {'ids': authorised_instructor_ids})
            .then((instructors: LocalStorageUser[]) => {
                setInstructors(instructors)
            })
        }
    }, [authorised_instructor_ids])
  
    return (
        <>
        {instructors.length &&
            <>
            <DialogFormSubHeadingTypography> {instructors[0].username} </DialogFormSubHeadingTypography>
            <InstructorForm instructor_id={instructors[0].id}/>
            </>
        }
        </>
        // ADD BACK IN IF THE ABILITY TO AUTH MULTIPLE INSTRUCTORS GETS ADDED BACK
        // <INastixCardSlider slider_length={instructors.length}>
        //     {instructors.map((instructor, index) => (
        //         <div key={index}>
        //             <DialogFormSubHeadingTypography> {instructor.username} </DialogFormSubHeadingTypography>
        //             <InstructorForm instructor_id={instructor.id}/>
        //         </div>
        //     ))}
        // </INastixCardSlider>
    )
}

export default InstructorsMap
  