import React, {useEffect, useState} from 'react'

import TableCell from '@mui/material/TableCell'
import Checkbox from '@mui/material/Checkbox'
import { Typography } from '@mui/material'
import Alert from '@mui/material/Alert'

import { Child } from '@utils/interfaces/interfaces'

import { CertifcateChildren, ValidChildrenAndEvaluation } from '.'
import { CertificateType } from './enum'
import WarnAboutInvalidChildren from './WarnAboutInvalidChildren'


export interface ValidateChildrenForPrintProps {
	certifcate_children: CertifcateChildren
    setChildren:  React.Dispatch<React.SetStateAction<CertifcateChildren>>
}


interface ResendCertificates {
    child_id: number
    [CertificateType.STANDARD_CERTIFICATE_CHECKED]: boolean
    [CertificateType.PERCEPTUAL_MA_CERTIFICATE_CHECKED]: boolean
}

interface SendAsParticipationCertificates {
    child_id: number
    is_checked: boolean
}

interface SendIncompleteAssessmentsAnywaynCertificates {
    child_id: number
    is_checked: boolean
}

const ValidateChildrenForPrint = (props: ValidateChildrenForPrintProps) => {
	const {certifcate_children, setChildren} = props

    const [resend_all_partcipation_children_checked, setResendAllParcipationChildrenChecked] = useState(false)
    const [resend_all_evaluation_children_checked, setResendAllEvaluationChildrenChecked] = useState(false)
    const [resend_certificate_checked, setResendCertificateChecked] = useState<ResendCertificates[]>([])
    const [send_all_as_participation_certificate_checked, setSendAllAsParticipationCertificateChecked] = useState(false)
    const [send_as_participation_certificate_checked, setSendAsParticipationCertificateChecked] = useState<SendAsParticipationCertificates[]>([])
    const [send_all_incomplete_assessments_checked, setSendAllIncompleteAssessmentsChecked] = useState(false)
    const [send_incomplete_assessments_checked, setSendIncompleteAssessmentsChecked] = useState<SendIncompleteAssessmentsAnywaynCertificates[]>([])

    useEffect(() => {
        setResendCertificateChecked([])
        setResendAllParcipationChildrenChecked(false)
        setResendAllEvaluationChildrenChecked(false)
        
        const children_certificate_already_sent = [...certifcate_children.participation_certificate_already_sent_children, ...certifcate_children.evaluation_certificate_already_sent_children]

        // take out duplicates
        const unique_children_certificate_already_sent = children_certificate_already_sent.reduce((children: Child[], child: Child) => {
            const existingItem = children.find(item => item.id === child.id)
            if (!existingItem) {
                children.push(child)
            }
            return children
        }, [])

        for (const child of unique_children_certificate_already_sent) {
            setResendCertificateChecked(props => ([
                ...props,
                {
                    child_id: child.id,
                    [CertificateType.STANDARD_CERTIFICATE_CHECKED]: false,
                    [CertificateType.PERCEPTUAL_MA_CERTIFICATE_CHECKED]: false
                }
            ]))
        }
    }, [certifcate_children.participation_certificate_already_sent_children, certifcate_children.evaluation_certificate_already_sent_children])

    useEffect(() => {
        setSendAllAsParticipationCertificateChecked(false)
        setSendAsParticipationCertificateChecked([])

        for (const child of certifcate_children.invalid_evaluation) {
            setSendAsParticipationCertificateChecked(props => ([
                ...props,
                {
                    child_id: child.id,
                    is_checked: false,
                }
            ]))
        }        
    }, [certifcate_children.invalid_evaluation])

    useEffect(() => {
        setSendAllIncompleteAssessmentsChecked(false)
        setSendIncompleteAssessmentsChecked([])

        for (const child of certifcate_children.incomplete_assessment_children) {
            setSendIncompleteAssessmentsChecked(props => ([
                ...props,
                {
                    child_id: child.id,
                    is_checked: false,
                }
            ]))
        }        
    }, [certifcate_children.incomplete_assessment_children])

    const handleResendAllPartcipationChildrenChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setResendAllParcipationChildrenChecked(event.target.checked)

        const new_resend_certificate_checked = resend_certificate_checked.map(resend_certificate => {
            return {
                child_id: resend_certificate.child_id,
                [CertificateType.PERCEPTUAL_MA_CERTIFICATE_CHECKED]: resend_certificate[CertificateType.PERCEPTUAL_MA_CERTIFICATE_CHECKED],
                [CertificateType.STANDARD_CERTIFICATE_CHECKED]: event.target.checked,
            }
        })

        if (event.target.checked) {
            console.log(resend_certificate_checked)

            const list_of_child_ids = resend_certificate_checked.map(resend_certificate => {
                if (!resend_certificate[CertificateType.STANDARD_CERTIFICATE_CHECKED])
                    return resend_certificate.child_id
            })
            const children_gathered = certifcate_children.intial_cleaned_children.filter(child => list_of_child_ids.includes(child.id))

            setChildren(props => ({
                ...props,
                participation_valid_children: [...props.participation_valid_children, ...children_gathered]
            }))
        }
        else {
            const list_of_child_ids = resend_certificate_checked.map(resend_certificate => {
                if (resend_certificate[CertificateType.STANDARD_CERTIFICATE_CHECKED])
                    return resend_certificate.child_id
            })
            setChildren(props => ({
                ...props,
                participation_valid_children: [...props.participation_valid_children.filter(participation_child => !list_of_child_ids.includes(participation_child.id))]
            })) 
        }

        setResendCertificateChecked(new_resend_certificate_checked)
    }

    const handleResendAllEvaluationChildrenChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const is_checked = event.target.checked

        setResendAllEvaluationChildrenChecked(is_checked)

        const new_resend_certificate_checked = resend_certificate_checked.map(resend_certificate => {
            return {
                child_id: resend_certificate.child_id,
                [CertificateType.PERCEPTUAL_MA_CERTIFICATE_CHECKED]: is_checked,
                [CertificateType.STANDARD_CERTIFICATE_CHECKED]: resend_certificate[CertificateType.STANDARD_CERTIFICATE_CHECKED],
            }
        })

        let children_gathered: Child[]

        if (is_checked) {
            console.log(resend_certificate_checked)

            const list_of_child_ids = resend_certificate_checked.map(resend_certificate => {
                if (!resend_certificate[CertificateType.PERCEPTUAL_MA_CERTIFICATE_CHECKED])
                    return resend_certificate.child_id
            })
            children_gathered = certifcate_children.intial_cleaned_children.filter(child => list_of_child_ids.includes(child.id))
        }
        else {
            const list_of_child_ids = resend_certificate_checked.map(resend_certificate => {
                if (resend_certificate[CertificateType.PERCEPTUAL_MA_CERTIFICATE_CHECKED])
                    return resend_certificate.child_id
            })
            children_gathered = certifcate_children.intial_cleaned_children.filter(child => list_of_child_ids.includes(child.id))
        }

        let search_children = certifcate_children.valid_children_and_evaluations

        for (const child of children_gathered) {
            search_children = updateChildrenAndEvaluations(is_checked, child, search_children)
        }

        const parsed_valid_children_and_evaluations = search_children
        setChildren(props => ({
            ...props,
            valid_children_and_evaluations: parsed_valid_children_and_evaluations
        })) 

        setResendCertificateChecked(new_resend_certificate_checked)
    }

    const handleSendAllIncompleteAssessmentChildrenChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const is_checked = event.target.checked

        setSendAllIncompleteAssessmentsChecked(is_checked)

        let new_incomplete_assessment_children_checked = send_incomplete_assessments_checked.map(incomplete_assessment_children => {
            return {
                child_id: incomplete_assessment_children.child_id,
                is_checked: is_checked
            }
        })
        let children_gathered: Child[]

        const list_of_child_ids = send_incomplete_assessments_checked.map(incomplete_assessment_children => incomplete_assessment_children.child_id)
        children_gathered = certifcate_children.intial_cleaned_children.filter(child => list_of_child_ids.includes(child.id))

        let search_children = certifcate_children.valid_children_and_evaluations

        for (const child of children_gathered) {
            search_children = updateChildrenAndEvaluations(is_checked, child, search_children)
        }

        const parsed_valid_children_and_evaluations = search_children
        setChildren(props => ({
            ...props,
            valid_children_and_evaluations: parsed_valid_children_and_evaluations
        })) 

        setSendIncompleteAssessmentsChecked(new_incomplete_assessment_children_checked)
    }

    const handleSendIncompleteAssessmentChildrenChange = async (event: React.ChangeEvent<HTMLInputElement>, child: Child) => {
        const is_checked = event.target.checked

        let new_incomplete_assessment_children_checked = send_incomplete_assessments_checked.map(incomplete_assessment_children => {
            if (child.id === incomplete_assessment_children.child_id) {
                return {
                    child_id: incomplete_assessment_children.child_id,
                    is_checked: is_checked
                }
            }
            return incomplete_assessment_children
        })

        if (new_incomplete_assessment_children_checked.some(incomplete_assessment_children => incomplete_assessment_children.is_checked))
            setSendAllIncompleteAssessmentsChecked(true)
        else
            setSendAllIncompleteAssessmentsChecked(false)

        let search_children = certifcate_children.valid_children_and_evaluations
        search_children = updateChildrenAndEvaluations(is_checked, child, search_children)

        const parsed_valid_children_and_evaluations = search_children
        setChildren(props => ({
            ...props,
            valid_children_and_evaluations: parsed_valid_children_and_evaluations
        })) 

        setSendIncompleteAssessmentsChecked(new_incomplete_assessment_children_checked)
    }

    const handleResendParticipationCertificateChange = async (event: React.ChangeEvent<HTMLInputElement>, child: Child) => {
        const is_checked = event.target.checked

        if (is_checked) {
            setChildren(props => ({
                ...props,
                participation_valid_children: [...props.participation_valid_children, child]
            }))
        }
        else {
            setChildren(props => ({
                ...props,
                participation_valid_children: [...props.participation_valid_children.filter(participation_child => participation_child.id !== child.id)]
            })) 
        }

        const new_resend_certificate_checked = resend_certificate_checked.map(resend_certificate => {
            if (resend_certificate.child_id === child.id) {
                return {
                    child_id: child.id,
                    [CertificateType.PERCEPTUAL_MA_CERTIFICATE_CHECKED]: resend_certificate[CertificateType.PERCEPTUAL_MA_CERTIFICATE_CHECKED],
                    [CertificateType.STANDARD_CERTIFICATE_CHECKED]: is_checked,
                }
            }
            return resend_certificate
        })

        if (new_resend_certificate_checked.some(new_certificate_checked => new_certificate_checked[CertificateType.STANDARD_CERTIFICATE_CHECKED]))
            setResendAllParcipationChildrenChecked(true)
        else
            setResendAllParcipationChildrenChecked(false)

        setResendCertificateChecked(new_resend_certificate_checked)
    }

    const handleResendEvaluationCertificateChange = async (event: React.ChangeEvent<HTMLInputElement>, child: Child) => {
        const is_checked = event.target.checked

        const parsed_valid_children_and_evaluations = updateChildrenAndEvaluations(is_checked, child, certifcate_children.valid_children_and_evaluations)

        setChildren(props => ({
            ...props,
            valid_children_and_evaluations: parsed_valid_children_and_evaluations
        })) 

        const new_resend_certificate_checked = resend_certificate_checked.map(resend_certificate => {
            if (resend_certificate.child_id === child.id) {
                return {
                    child_id: child.id,
                    [CertificateType.STANDARD_CERTIFICATE_CHECKED]: resend_certificate[CertificateType.STANDARD_CERTIFICATE_CHECKED],
                    [CertificateType.PERCEPTUAL_MA_CERTIFICATE_CHECKED]: is_checked,
                }
            }
            return resend_certificate
        })

        if (new_resend_certificate_checked.some(new_certificate_checked => new_certificate_checked[CertificateType.PERCEPTUAL_MA_CERTIFICATE_CHECKED]))
            setResendAllEvaluationChildrenChecked(true)
        else
            setResendAllEvaluationChildrenChecked(false)

        setResendCertificateChecked(new_resend_certificate_checked)    
    }

    const handleSendAllAsParticipationCertificateChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const is_checked = event.target.checked

        setSendAllAsParticipationCertificateChecked(is_checked)

        if (is_checked) {
            setChildren(props => ({
                ...props,
                participation_valid_children: [...props.invalid_evaluation]
            }))
        }
        else {
            const invalid_evaluation_child_ids = certifcate_children.invalid_evaluation.map(invalid_evaluation_child => invalid_evaluation_child.id)
            setChildren(props => ({
                ...props,
                participation_valid_children: [...props.participation_valid_children.filter(participation_child => !invalid_evaluation_child_ids.includes(participation_child.id))]
            })) 
        }

        const new_certificate_checked = send_as_participation_certificate_checked.map(participation_certificate_checked => {
            return {
                child_id: participation_certificate_checked.child_id,
                is_checked: is_checked,
            }
        })

        setSendAsParticipationCertificateChecked(new_certificate_checked)
    }
    

    // handles sending the children a participation certificate if they don't have a valid evaluation
    const handleSendAsParticipationCertificateChange = async (event: React.ChangeEvent<HTMLInputElement>, child: Child) => {
        const is_checked = event.target.checked

        if (is_checked) {
            setChildren(props => ({
                ...props,
                participation_valid_children: [...props.participation_valid_children, child]
            }))
        }
        else {
            setChildren(props => ({
                ...props,
                participation_valid_children: [...props.participation_valid_children.filter(participation_child => participation_child.id !== child.id)]
            })) 
        }

        const new_certificate_checked = send_as_participation_certificate_checked.map(participation_certificate_checked => {
            if (participation_certificate_checked.child_id === child.id) {
                return {
                    child_id: child.id,
                    is_checked: is_checked,
                }
            }
            return participation_certificate_checked
        })

        if (new_certificate_checked.some(new_certificate => new_certificate.is_checked))
            setSendAllAsParticipationCertificateChecked(true)
        else
            setSendAllAsParticipationCertificateChecked(false)

        setSendAsParticipationCertificateChecked(new_certificate_checked)
    }

    const updateChildrenAndEvaluations = (is_checked: boolean, child: Child, search_children: ValidChildrenAndEvaluation[]) => {
        const parsed_valid_children_and_evaluations: ValidChildrenAndEvaluation[] = search_children.map(valid_children_and_evaluation => {
            const evaluation_children_ids = valid_children_and_evaluation.evaluation.children
            if (evaluation_children_ids.includes(child.id)) {
                let new_valid_children: Child[] | null = []
                if (is_checked) {
                    new_valid_children = valid_children_and_evaluation.valid_children
                    // only add child if it is not already in the system
                    if (!new_valid_children.some(valid_child => valid_child.id === child.id))
                        new_valid_children.push(child)
                }
                else {
                    new_valid_children = valid_children_and_evaluation.valid_children.filter(filter_child => filter_child.id !== child.id)
                }
                const parsed_valid_children_and_evaluation = {
                    valid_children: new_valid_children,
                    evaluation: valid_children_and_evaluation.evaluation
                }
                return parsed_valid_children_and_evaluation
            }
            return valid_children_and_evaluation
        })

        return parsed_valid_children_and_evaluations
    }

    const cellStyle = {
        textAlign: 'center', // Center align the cell content horizontally
        verticalAlign: 'middle', // Center align the cell content vertically
    }

    return (
        <>
        {/* <Alert severity="warning" >
            Will skip {term_name} term because it is currently on Term Break
        </Alert> */}
        {certifcate_children.not_in_selected_term_children.length || certifcate_children.send_to_school_children.length || certifcate_children.no_parent_email_or_phone_number.length || 
            certifcate_children.not_authorised_children.length || certifcate_children.not_allocated_class.length || certifcate_children.invalid_evaluation.length || certifcate_children.participation_certificate_already_sent_children.length || 
            certifcate_children.evaluation_certificate_already_sent_children.length ?
            <Alert severity="warning" sx={{marginBottom: '1rem'}}>
                Skipping certificate creation for these children
            </Alert>    
            : <></>
        }
        <WarnAboutInvalidChildren
            alert_message={"Not in the selected term"}
            cell_headings={[<Typography>In selected term</Typography>]}
            cell_rows={
                [<TableCell sx={{...cellStyle, color: 'red'}}>No</TableCell>]
            }
            children={certifcate_children.not_in_selected_term_children}
        />
        <WarnAboutInvalidChildren
            alert_message={"Franchise not ready to send certificates"}
            cell_headings={[<Typography>Ready for certificate</Typography>]}
            cell_rows={
                [<TableCell sx={{...cellStyle, color: 'red'}}>No</TableCell>]
            }
            children={certifcate_children.not_ready_to_send_certificate_children}
        />
        <WarnAboutInvalidChildren
            alert_message={"Missing Assessment points"}
            cell_headings={             
                [
                    <>             
                    <Typography sx={{fontSize: '0.8rem', width: '80px'}}>Send anyway</Typography>
                    <Checkbox 
                        checked={send_all_incomplete_assessments_checked}
                        onChange={handleSendAllIncompleteAssessmentChildrenChange}
                        inputProps={{'aria-label': 'Checkbox resend certificate'}} 
                    />
                    </>
                ]
            }
            custom_cell_heading_styles={{
                display: 'flex',
                alignItems: 'center'
            }}
            cellRowWithChildren={
                (child: Child) => 
                    <TableCell sx={cellStyle}>
                        <Checkbox 
                            checked={(() => {
                                const certificate_checked = send_incomplete_assessments_checked.find(certificate_checked => certificate_checked.child_id === child.id)
                                if (!certificate_checked)
                                    return false
                                return certificate_checked.is_checked
                            })()}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSendIncompleteAssessmentChildrenChange(event, child)}
                            inputProps={{'aria-label': 'Checkbox resend certificate'}} 
                        />
                    </TableCell>
            }
            children={certifcate_children.incomplete_assessment_children}
        />
        <WarnAboutInvalidChildren
            alert_message={"Are intramural and their certificate will be sent to their school"}
            cell_headings={[<Typography>Extramural or parent contact</Typography>] }
            cell_rows={
                [<TableCell sx={{...cellStyle, color: 'red'}}>No</TableCell>]
            }
            children={certifcate_children.send_to_school_children}
        />
        <WarnAboutInvalidChildren
            alert_message={"No parent email or number"}
            cell_headings={[<Typography>Has a parent email or number</Typography>]}
            cell_rows={
                [<TableCell sx={{...cellStyle, color: 'red'}}>No</TableCell>]
            }
            children={certifcate_children.no_parent_email_or_phone_number}
        />
        <WarnAboutInvalidChildren
            alert_message={"Not authorised"}
            cell_headings={[<Typography>Authorised</Typography>]}
            cell_rows={
                [<TableCell sx={{...cellStyle, color: 'red'}}>No</TableCell>]
            }
            children={certifcate_children.not_authorised_children}
        />
        <WarnAboutInvalidChildren
            alert_message={"Not allocated to a class"}
            cell_headings={[<Typography>Allocated to class</Typography>]}
            cell_rows={
                [<TableCell sx={{...cellStyle, color: 'red'}}>No</TableCell>]
            }
            children={certifcate_children.not_allocated_class}
        />
        <WarnAboutInvalidChildren
            alert_message={"No valid evaluation"}
            cell_headings={             
                [
                    <>             
                    <Typography sx={{fontSize: '0.8rem', width: '80px'}}>Send as participation certificate</Typography>
                    <Checkbox 
                        checked={send_all_as_participation_certificate_checked}
                        onChange={handleSendAllAsParticipationCertificateChange}
                        inputProps={{'aria-label': 'Checkbox resend certificate'}} 
                    />
                    </>
                ]
            }
            custom_cell_heading_styles={{
                display: 'flex',
                alignItems: 'center'
            }}
            cellRowWithChildren={
                (child: Child) => 
                    <TableCell sx={cellStyle}>
                        <Checkbox 
                            checked={(() => {
                                const certificate_checked = send_as_participation_certificate_checked.find(certificate_checked => certificate_checked.child_id === child.id)
                                if (!certificate_checked)
                                    return false
                                return certificate_checked.is_checked
                            })()}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleSendAsParticipationCertificateChange(event, child)}
                            inputProps={{'aria-label': 'Checkbox resend certificate'}} 
                        />
                    </TableCell>
            }
            children={certifcate_children.invalid_evaluation}
        />
        <WarnAboutInvalidChildren
            warning_alert_message={"Participation certificate already sent"}
            cell_headings={     
                [
                    <>                   
                    <Typography>Resend certificate</Typography>
                    <Checkbox 
                        checked={resend_all_partcipation_children_checked}
                        onChange={handleResendAllPartcipationChildrenChange}
                        inputProps={{'aria-label': 'Checkbox resend certificate'}} 
                    />
                    </>
                ]
            }
            custom_cell_heading_styles={{
                display: 'flex',
                alignItems: 'center'
            }}
            cellRowWithChildren={
                (child: Child) => 
                    <TableCell sx={cellStyle}>
                        <Checkbox 
                            checked={(() => {
                                const certificate_checked = resend_certificate_checked.find(resend => resend.child_id === child.id)
                                if (!certificate_checked)
                                    return false
                                return certificate_checked[CertificateType.STANDARD_CERTIFICATE_CHECKED]
                            })()}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleResendParticipationCertificateChange(event, child)}
                            inputProps={{'aria-label': 'Checkbox resend certificate'}} 
                        />
                    </TableCell>
            }
            children={certifcate_children.participation_certificate_already_sent_children}
        />
        <WarnAboutInvalidChildren
            warning_alert_message={"Evaluation certificate already sent"}
            cell_headings={             
                [
                    <>             
                    <Typography>Resend certificate</Typography>
                    <Checkbox 
                        checked={resend_all_evaluation_children_checked}
                        onChange={handleResendAllEvaluationChildrenChange}
                        inputProps={{'aria-label': 'Checkbox resend certificate'}} 
                    />
                    </>
                ]
            }
            custom_cell_heading_styles={{
                display: 'flex',
                alignItems: 'center'
            }}
            cellRowWithChildren={
                (child: Child) => 
                    <TableCell sx={cellStyle}>
                        <Checkbox 
                            checked={(() => {
                                const certificate_checked = resend_certificate_checked.find(resend => resend.child_id === child.id)
                                if (!certificate_checked)
                                    return false
                                return certificate_checked[CertificateType.PERCEPTUAL_MA_CERTIFICATE_CHECKED]
                            })()}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleResendEvaluationCertificateChange(event, child)}
                            inputProps={{'aria-label': 'Checkbox resend certificate'}} 
                        />
                    </TableCell>
            }
            children={certifcate_children.evaluation_certificate_already_sent_children}
        />
        </>
    )
    
    
      
}

export default ValidateChildrenForPrint