import TextField from '@mui/material/TextField'


const ChildAuthorisationDuePeriod = () => {
    return (
        <>
        <TextField
            type="number"
            label="Child Authorisation Due Period (Days)"
            name='children_authorized_before_days'
            helperText="Force your franchisees to authorise members this many days in advance"
            required
            sx={{width: '100%'}}
        />
        <br/>
        <br/>
        </>
    )
}

export default ChildAuthorisationDuePeriod