import {useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import AddIcon from '@mui/icons-material/Add'

import { FormDataEntryType } from '@components/forms/Form'
import { TableActionButon } from '@styles/Buttons'
import { DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { MessageContext } from '@contexts/MessageContext'

import { DialogForFormsHandlerProps, DialogForFormsProps } from '../interfaces/interface'


const CreateDialog = (props: DialogForFormsProps) => {
	const {Form, state_manager, onClose, open} = props
    const { t } = useTranslation('tables')

	const handleCreate = async (form_data_object: FormDataEntryType): Promise<any> => {
		return state_manager.setCreateAction(form_data_object)
		.then(() => {
			handleClose()  // close the dialog if the promise resolved
			return {message: t('created_successfully')}
		})
	}

	const handleClose = () => {
		onClose()
	}

	const form_component = Form(handleCreate)

	return (
        open ? (
            <INastixDialog onClose={handleClose} open={open}>
                <INastixDialogTitle> {t('create')} </INastixDialogTitle>
                <DialogDivider />
                {form_component}
            </INastixDialog>
        ) : <></>
	)
}


const CreateDialogHandler = (props: DialogForFormsHandlerProps) => {
	const {disabled, Form, state_manager} = props
    const { t } = useTranslation('tables')

	const [open, setOpen] = useState(false)
	const {setMessage} = useContext(MessageContext)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setMessage({'success': "", 'error': "", 'form_error': "", 'info': "", 'warning': ""})
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<AddIcon/>}>
			{t('add')}
		</TableActionButon>
		<CreateDialog Form={Form} state_manager={state_manager} open={open} onClose={handleClose}/>
		</>
	)
}

export default CreateDialogHandler