import {useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import log from 'loglevel'

import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import Box from "@mui/material/Box"
import AddBusinessIcon from '@mui/icons-material/AddBusiness'

import { DialogButton, DialogDivider, DialogFormControl, INastixDialog, INastixDialogTitle } from "@styles/Dialog"
import Form, { FormDataEntryType } from '@components/forms/Form'
import CompanyActions from '@actions/CompanyActions/companyActions'
import { getLocalStorageUser } from '@utils/localStorage/user'
import { isInGroup } from '@utils/utils/util'
import { UserType } from '@utils/enums/enums'
import { LocalStorageCompany, LocalStorageUser } from '@utils/interfaces/interfaces'
import { CreateCompanyDialogDialogProps} from '@utils/dialogProps/DialogProps'
import { MessageContext } from '@contexts/MessageContext'
import { handleCompanyLocalStorageInit } from '@components/authentication/helper/helper'
import { CompanySetupStepContext } from '@contexts/CompanySetupStepContext'

import { CompanyLabel, SetCompanies } from '.'
import { SetupStepContext } from '@contexts/SetupStepContext'
import { getLocalStorageCompany } from '@utils/localStorage/company'


type CreateCompanyDialogFormDataObject = {
    [key: string]: string
    company_name: string
}

export const CreateCompanyDialog = (props: CreateCompanyDialogDialogProps) => {
	const {setCompanies, onClose, open, onCreateCompanyCallback} = props

    const {setMessage} = useContext(MessageContext)
    const {setCompanySetupStep} = useContext(CompanySetupStepContext)
    const {setSetupStep} = useContext(SetupStepContext)

    const user = getLocalStorageUser()
	const company = getLocalStorageCompany()
    const is_school_operator = isInGroup(user, company, UserType.SCHOOL_OPERATOR)
    const { t } = useTranslation('set_up')

    const createFields = () => {
        return (
            <Box sx={{textAlign:'center'}}>
            <DialogFormControl sx={{width: '300px', margin: 'auto'}}>
                <br/>
                <TextField
                    label={is_school_operator ? t('school_name') : t('company_name') }
                    name="company_name"
                    placeholder={t('enter_company_name') as string}
                    required
                />
                <DialogButton type="submit" variant='contained'> {t('create')} </DialogButton>
            </DialogFormControl>
            </Box>
        )
    }

	const handleCreate = async (form_data_object: FormDataEntryType): Promise<any> => {
        log.debug(form_data_object)
        setMessage(props => ({...props, form_error: ""}))

        const company_actions = new CompanyActions()
        await company_actions.getAllCompanies()
        .then((companies: LocalStorageCompany[]) => {
            const company_dialog_data = form_data_object as CreateCompanyDialogFormDataObject
            for (const company of companies)
                if (company_dialog_data.company_name === company.tenant_name) {
                    const company_type = is_school_operator ? "school" : "franchise"
                    setMessage(props => ({...props, form_error: `${t(company_type)} ${t('with_name')} ${company_dialog_data.company_name} ${t('already_exists')}`}))
                    return
                }
                    
        })
        
        return company_actions.create(form_data_object)
        .then((data: {company: LocalStorageCompany, user: LocalStorageUser}) => {
            const company = data.company
            const user = data.user

            const parsed_data: CompanyLabel = {
                label: company.tenant_name,
                company_uuid: company.company_uuid
            }
            if (setCompanies)
                setCompanies(props => ([...props, company]))
            
            localStorage.setItem('user', JSON.stringify(user))
            setSetupStep(user.setup_step)

            const local_connected_companies = localStorage.getItem('connected_companies')
            const connected_companies = local_connected_companies ? JSON.parse(local_connected_companies) as LocalStorageCompany[]: []

            const updated_connected_companies = [...connected_companies, company]

            localStorage.setItem('connected_companies', JSON.stringify(updated_connected_companies))

            handleCompanyLocalStorageInit(company)
            setCompanySetupStep(company.setup_step)
            handleClose()
            if (onCreateCompanyCallback)
                onCreateCompanyCallback(parsed_data)
            return {message: is_school_operator ? t('school_created') : t('company_created') }
        })
    }

	const handleClose = () => {
		onClose()
        setMessage(props => ({...props, form_error: ""}))
	}

	return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle sx={{textAlign: 'center'}}> 
                {is_school_operator ? t('create_school') : t('create_company') }
            </INastixDialogTitle>
			<DialogDivider/>
            <Form onSubmitCallback={handleCreate} createFields={createFields}/>
		</INastixDialog>
	)
}


interface CreateCompanyDialogHandlerProps {
    setCompanies?: SetCompanies
    onCreateCompanyCallback?: (selected_company: CompanyLabel) => void
    button_option_1?:  boolean
}

const CreateCompanyDialogHandler = (props: CreateCompanyDialogHandlerProps) => {
    const {setCompanies, onCreateCompanyCallback, button_option_1=false} = props

    const user = getLocalStorageUser()
	const company = getLocalStorageCompany()
    const is_school_operator = isInGroup(user, company, UserType.SCHOOL_OPERATOR)
    const { t } = useTranslation('set_up')

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

    return (
        <>
        {button_option_1 ? (
            <Button onClick={handleClickOpen} sx={{textTransform: 'capitalize', width: '100%', justifyContent: 'start', margin: '0 0.3rem'}} startIcon={<AddBusinessIcon sx={{ fontSize: "30px" }}/>}>
                Add a franchise
            </Button>
        ) : (
            <Button onClick={handleClickOpen} variant='contained'>
                {is_school_operator ? t('create_a_school') : t('create_a_company')}
            </Button>
        )}
		<CreateCompanyDialog setCompanies={setCompanies} open={open} onClose={handleClose} onCreateCompanyCallback={onCreateCompanyCallback}/>
        </>
    )
}

export default CreateCompanyDialogHandler


