import {useState} from 'react'
import { useTranslation } from 'react-i18next'

import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove'
import Button from '@mui/material/Button'

import { DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { CommonDialogProps } from '@utils/dialogProps/DialogProps'
import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import MinastixMemberPoolActions from '@actions/MinastixMemberPoolActions/minastixMemberPoolActions'
import { SetNum } from '@utils/types/types'
import { Typography } from '@mui/material'


interface SendToMemberPoolDialogProps extends CommonDialogProps {
	setNumState: SetNum
}

const SendToMemberPoolDialog = (props: SendToMemberPoolDialogProps) => {
	const {ids, state_manager, onClose, open, setNumState} = props

    const { t } = useTranslation('children')

	const handleSendToMemberPool = async (): Promise<any> => {
		const minastix_member_pool_actions = new MinastixMemberPoolActions()
		minastix_member_pool_actions.sendToMemberPool({child_ids: ids})
		.then(() => {
			state_manager.setDeleteMultipleActionFilter(ids)
			setNumState(props => props - ids.length)
			handleClose()
		})
		.catch(error => {
			console.log(error)
		})
	}

    const handleClose = () => {
		onClose()
	}

    return (
		<INastixDialog onClose={handleClose} open={open}>
        	<INastixDialogTitle sx={{padding: 0, paddingTop: '0.4rem'}}> {t('send_to_member_pool')} </INastixDialogTitle>
			<DialogDivider/>
			<Typography>
			{ids.length === 1 ? <span>{t('this_member_will_no_longer_be_part_of_your_franchise')}</span> : <span>{t('these_members_will_no_longer_be_part_of_your_franchise')}</span>}
			</Typography>
            <Button onClick={handleSendToMemberPool} variant='contained'> {t('send')} </Button>
		</INastixDialog>
    )
}


interface SendToMemberPoolDialogHandlerProps extends DialogComponentProps {
	setNumState: SetNum
}

const SendToMemberPoolDialogHandler = (props: SendToMemberPoolDialogHandlerProps) => {
	const {disabled, ids, state_manager, setNumState} = props

    const { t } = useTranslation('children')

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<DriveFileMoveIcon/>}>
			{t('send_to_member_pool')}
		</TableActionButon>
		{open && <SendToMemberPoolDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose} setNumState={setNumState}/>}
		</>
	)
}

export default SendToMemberPoolDialogHandler