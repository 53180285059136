import {useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import Typography from '@mui/material/Typography'
import ChecklistIcon from '@mui/icons-material/Checklist'
import Button from '@mui/material/Button'
import PrintIcon from '@mui/icons-material/Print'

import { TableActionButon } from '@styles/Buttons'
import { DialogComponentProps } from '@components/tables/Table/Table'
import { INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { CommonDialogProps } from '@utils/dialogProps/DialogProps'
import { getLocalStorageUser } from '@utils/localStorage/user'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { MessageContext } from '@contexts/MessageContext'
import ExcelReportActions from '@actions/ReportActions/excelReportActions'
import { NumOfRequestsContext } from '@contexts/NumOfRequestsContext'
import { convertDateToYYYYMMDD } from '@utils/utils/util'
import { downloadFile } from '@utils/files/blobs'


const PrintAssessmentDialog = (props: CommonDialogProps) => {
    // eslint-disable-next-line
	const {ids, state_manager, onClose, open} = props
    const { t } = useTranslation('classes')

	const {setNumOfRequests} = useContext(NumOfRequestsContext)

    const handleClose = () => {
		onClose()
	}

	const handlePrintSubmit = async () => {
        const execl_report_actions = new ExcelReportActions()

		try {
            const response = await execl_report_actions.createAssessmentReport({evaluation_id: ids[0]})
			setNumOfRequests(props => props - 1)

			let current_date = convertDateToYYYYMMDD(new Date())

			let filename = `Class Assessment Report ${current_date}.xlsx`
			downloadFile(response, filename)

		} catch (error) {
			console.error('Error generating Assessment Report:', error)
			setNumOfRequests(props => props - 1)
		}

        handleClose()
	}

    return (
		<INastixDialog onClose={handleClose} open={open}>
        	<INastixDialogTitle> Print Assessment </INastixDialogTitle>
            <Button variant='contained' onClick={handlePrintSubmit}> Print </Button>
		</INastixDialog>
    )
}


const PrintAssessmentDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props
    const { t } = useTranslation('classes')

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<PrintIcon/>}>
			<Typography> Print Assessment </Typography>
		</TableActionButon>
		{open && <PrintAssessmentDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>}
		</>
	)
}

export default PrintAssessmentDialogHandler