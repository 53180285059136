import Pages from '@pages/Page'
import { PageHeadingTypography } from '@styles/Typography'
import MinastixMemberPoolActions from '@actions/MinastixMemberPoolActions/minastixMemberPoolActions'
import MinastixMemberPoolTable from '@components/tables/Custom/MinastixMemberPoolTable'


const MinastixMemberPoolPage = () => {
	const minastix_member_pool_actions = new MinastixMemberPoolActions()

	return (
		<Pages page_title="Minastix Member Pool">
			<PageHeadingTypography> Minastix Member Pool </PageHeadingTypography>
            <MinastixMemberPoolTable action={minastix_member_pool_actions}/>
		</Pages>
	)
}

export default MinastixMemberPoolPage
