import {useEffect, useState, useContext} from 'react'
import { useNavigate } from 'react-router-dom'

import Modal from 'react-modal'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import DoneIcon from '@mui/icons-material/Done'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart'
import { IconButton, Typography, Tooltip } from '@mui/material'
import Badge from '@mui/material/Badge'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { CardActionArea } from '@mui/material'
import CardMedia from '@mui/material/CardMedia'

import FZoneEcommerceProductActions from '@actions/CRUDActions/FZoneEcommerceAtions/fzoneEcommerceProductActions'
import FZoneEcommerceCartItemActions from '@actions/CRUDActions/FZoneEcommerceAtions/fzoneEcommerceCartItemActions'

import { FZoneCartItem } from '../Checkout/Cart'
import { CartContext } from '../contexts'
import {ProductOnSale} from './utils'


export interface FZoneProduct {
    id: number
    category: number
    description: string
    image: string
    name: string
    price: string
    on_sale: boolean
    on_sale_price: string
}


const FzoneEcommercePage = () => {
    const navigation = useNavigate()

    const {cart, setCart} = useContext(CartContext)

    const [add_to_cart_icons, setAddToCartIcons] = useState<Record<string, JSX.Element> | null>(null)
    const [products, setProducts] = useState<FZoneProduct[]>([])
    const [modalIsOpen, setModalIsOpen] = useState(false)

    const closeModal = () => {
        setModalIsOpen(false)
    }

    useEffect(() => {
        const product_actions = new FZoneEcommerceProductActions()

        product_actions.get()
        .then((products: FZoneProduct[]) => {
            setProducts(products)

            let add_to_cart_icons: Record<string, JSX.Element> = {}
            for (const product of products)
                add_to_cart_icons[product.id.toString()] = <AddShoppingCartIcon/>

            setAddToCartIcons(add_to_cart_icons)
        })

    }, [])

    useEffect(() => {

    }, [])

    const goToCheckout = () => {
        navigation('/fzone/checkout')
    }

    const addToShoppingCart = (product: FZoneProduct) => {
        if (!cart)
            return

        if (cart.products.includes(product.id)) {
            setCart(props => (props ? {
                ...props, 
                cart_items: 
                    [...props.cart_items.map(cart_item => {
                        if (cart_item.product === product.id) {
                            const cart_item_actions = new FZoneEcommerceCartItemActions()
                            cart_item_actions.update({quantity: cart_item.quantity + 1}, cart_item.id.toString())

                            cart_item.quantity = cart_item.quantity + 1
                            setAddToCartIcons(props => ({...props, [cart_item.product.toString()]: <DoneIcon/>}))
                            setTimeout(() => {
                                setAddToCartIcons(props => ({...props, [cart_item.product.toString()]: <AddShoppingCartIcon/>}))
                            }, 1500)
                        }
                        return cart_item
                    })]
            } : null ))
        }
        else {
            const cart_item_actions = new FZoneEcommerceCartItemActions()
            cart_item_actions.create({
                cart: cart.id,
                product: product.id
            })
            .then((cart_item: FZoneCartItem) => {
                setAddToCartIcons(props => ({...props, [cart_item.product.toString()]: <DoneIcon/>}))
                setTimeout(() => {
                    setAddToCartIcons(props => ({...props, [cart_item.product.toString()]: <AddShoppingCartIcon/>}))
                }, 1500)

                setCart(props => (props ? { ...props, products: [...props.products, product.id], cart_items: [...props.cart_items, cart_item]} : null))
            })
        }
    }

    const handleCardActionAreaClick = (product: FZoneProduct) => {
        navigation(`/fzone/products/${product.id}`)
    }

	return (
        <>
        <Badge badgeContent={cart ? cart.products.length : 0} color="primary" sx={{top: '25px', right: '30px', position: 'absolute'}}>
            <IconButton onClick={goToCheckout}>
                <ShoppingCartIcon/>
            </IconButton>
        </Badge>
        <Box sx={{ flexGrow: 1 }}>
            <Typography variant='h5' color='darkslategray' > Products </Typography>
            <Grid container sx={{justifyContent: 'center'}} spacing={5}>
                {products.map(product => (
                    <Grid item md={3} xs={3} key={product.id} sx={{justifyContent: 'center'}}>
                        <Card>
                            {product.on_sale && <ProductOnSale/>}
                            <CardActionArea onClick={() => handleCardActionAreaClick(product)}>
                                <CardMedia
                                    component="img"
                                    height="140"
                                    image={product.image}
                                    alt={product.name}
                                    loading="lazy"
                                />
                            </CardActionArea>
                            <CardContent sx={{paddingBottom: '0.75rem !important', pt: '0.5rem'}}>
                                {/* <Box sx={{width: '100%', height: 0, paddingTop: '100%', position: 'relative', overflow: 'hidden'}}>
                                    <img
                                        src={product.image}
                                        alt={product.name}
                                        onClick={openModal}
                                        style={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover'}}
                                    />
                                </Box> */}
                                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                                    <Typography sx={{color: 'darkslategrey'}}>{product.name}</Typography>
                                    {add_to_cart_icons && 
                                        <Tooltip title="Add to cart">
                                            <IconButton onClick={() => addToShoppingCart(product)}>
                                                {add_to_cart_icons[product.id.toString()]}
                                            </IconButton>
                                        </Tooltip>
                                    }
                                </Box>
                                <Typography sx={{textAlign: 'left', color: 'darkslategrey', fontWeight: 700, fontSize: '1.1rem'}}>
                                    R{product.price}
                                </Typography>
                                <Modal
                                    isOpen={modalIsOpen}
                                    onRequestClose={closeModal}
                                    contentLabel="Image Modal"
                                >
                                    <button onClick={closeModal}>Close</button>
                                    <img src={product.image} alt={product.name} />
                                </Modal>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Box>
        </>
	)
}

export default FzoneEcommercePage
