import { CRUDAction } from "@actions/Actions/actions"
import InvoiceHandler from "@adapters/handlers/FinanceHandlers/InvoiceHandlers/invoiceHandlers"
import SendInvoiceHandler from "@adapters/handlers/FinanceHandlers/InvoiceHandlers/sendInvoiceHandler"


class InvoiceActions extends CRUDAction {
    handler: InvoiceHandler
    send_invoice_handler: SendInvoiceHandler

    constructor() {
        super()
        this.handler = new InvoiceHandler()
        this.send_invoice_handler = new SendInvoiceHandler()
    }    

    sendInvoices (data: object): Promise<any> {
        return this.send_invoice_handler.sendInvoices(data)
    }
}

export default InvoiceActions