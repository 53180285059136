import { BaseAction } from "@actions/Actions/actions"
import SchoolServiceAgreementHandler from "@adapters/handlers/CRUDHandlers/SchoolHandlers/schoolServiceAgreementHandler"


class SchoolServiceAgreementActions extends BaseAction {
    school_service_agreement_handler: SchoolServiceAgreementHandler

    constructor() {
        super()
        this.school_service_agreement_handler = new SchoolServiceAgreementHandler()
    }

    acceptServiceAgreement (data: object): Promise<any> {
        return this.school_service_agreement_handler.acceptServiceAgreement(data)
    }
}


export default SchoolServiceAgreementActions