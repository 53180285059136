import { useContext, useState } from "react"

import VerifiedIcon from '@mui/icons-material/Verified'
import { Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material"
import { TableActionButon } from "@styles/Buttons"
import { DialogDivider, INastixDialog, INastixDialogTitle } from "@styles/Dialog"

import { DialogComponentProps } from "@components/tables/Table/Table"
import { MessageContext } from "@contexts/MessageContext"
import {DialogProps} from '@utils/dialogProps/DialogProps'
import { useTranslation } from "react-i18next"
import ChildPaymentsActions from "@actions/Finance/PaymentActions/childPaymentActions"


const ChildHalfTermlyAuthorisation = (props: DialogProps) => {
    const {ids, state_manager, onClose, open} = props

    const { t } = useTranslation('payments')

    const {setMessage} = useContext(MessageContext)

    const [half_term, setHalfTerm] = useState('')
    const [disable_button, setDisableButton] = useState(false)

    const handleSend = () => {
        setDisableButton(true)
        const child_payment_actions = new ChildPaymentsActions()
        const data = {
            'ids': ids,
            'payment_structure': 'half termly',
            'half_term': half_term

        }
        child_payment_actions.create(data)
        .then(data => {
            console.log(data)
            setMessage(props => ({...props, success: t("children_marked_as_paid")}))
        })
        .catch(error => {
            setMessage(props => ({...props, error: error.message}))
            setDisableButton(false)
        })
        .finally(async () => {
            return state_manager.setGetAction(undefined, undefined, {'payment_structure': 'half termly'})
            .then(() => {
                setDisableButton(false)
                handleClose()
            })
        })
    }

    const handleClose = () => {
		onClose()
	}

    const handleHalfTermChange = (event: SelectChangeEvent) => {
        setHalfTerm(event.target.value as string)
    }

    return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> {t('confirm')} </INastixDialogTitle>
			<DialogDivider/>
			<Typography>
			{t('which_term_would_you_like_to_mark_as_paid_for')} {ids.length} {ids.length === 1 ? <span>{t('child')}</span> : <span>{t('children')}</span>}
			</Typography>
            <br/>
            <FormControl fullWidth>
                <InputLabel id="half-term-select-label">{t('term')}</InputLabel>
                <Select
                    labelId="half-term-select-label"
                    id="half-term-select"
                    name="half_term"
                    label={t('half_term')}
                    value={half_term}
                    onChange={handleHalfTermChange}
                    sx={{marginBottom: 2}}
                    required
                >
                    <MenuItem value={'term_1'}>{t('term_1')}</MenuItem>
                    <MenuItem value={'term_1_2'}>{t('term_1_2')}</MenuItem>
                    <MenuItem value={'term_2'}>{t('term_2')}</MenuItem>
                    <MenuItem value={'term_2_2'}>{t('term_2_2')}</MenuItem>
                    <MenuItem value={'term_3'}>{t('term_3')}</MenuItem>
                    <MenuItem value={'term_3_2'}>{t('term_3_2')}</MenuItem>
                    <MenuItem value={'term_4'}>{t('term_4')}</MenuItem>
                    <MenuItem value={'term_4_2'}>{t('term_4_2')}</MenuItem>
                </Select>
            </FormControl>
			<Button disabled={disable_button} variant='contained' onClick={handleSend}> {t('confirm')} </Button>
		</INastixDialog>
	)
}

const ChildHalfTermlyAuthorisationHandler = (props: DialogComponentProps) => {
    const {disabled, ids, state_manager} = props

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

    const { t } = useTranslation('payments')

    return (
        <>
            <TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<VerifiedIcon/>}>
                <Typography> {t('authorise')} </Typography>
            </TableActionButon>
            <ChildHalfTermlyAuthorisation ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>
        </>
    )
}

export default ChildHalfTermlyAuthorisationHandler