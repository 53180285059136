import {useState, useContext, useEffect} from 'react'

import Typography from '@mui/material/Typography'
import FormControlLabel  from '@mui/material/FormControlLabel'
import Checkbox  from '@mui/material/Checkbox'
import AddIcon from '@mui/icons-material/Add'

import { DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import InvoiceActions from '@actions/Finance/InvoiceActions/invoiceActions'
import { MessageContext } from '@contexts/MessageContext'
import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import { DialogProps } from '@utils/dialogProps/DialogProps'
import { useTranslation } from 'react-i18next'
import { Button, Grid } from '@mui/material'
import { DialogFormSubHeadingTypography } from '@styles/Typography'
import { Dayjs } from 'dayjs'
import DatePickerObjectNastix from '@components/pickers/DatePickerObjectNastix'

const invoice_actions = new InvoiceActions()

const GenerateInvoicesDialog = (props: DialogProps) => {
	const { ids, state_manager, onClose, open} = props

	const { t } = useTranslation('overview')

	const {setMessage} = useContext(MessageContext)

	const [disable_button, setDisableButton] = useState(false)

	const [per_class, setPerClass] = useState(false)
	const [monthly, setMonthly] = useState(false)
	const [half_termly, setHalfTermly] = useState(false)
	const [termly, setTermly] = useState(false)
	const [yearly, setYearly] = useState(false)
	const [authorized, setAuthorized] = useState(false)
	const [awaiting_payment, setAwaitingPayment] = useState(false)
	const [date, setDate] = useState<Record<string, Dayjs | null>>({
		'date': null,
	})
	const [due_date, setDueDate] = useState<Record<string, Dayjs | null>>({
		'due_date': null,
	})

	const handleGenerate = () => {
		setDisableButton(true)
		if (!due_date['due_date'] || !date['date']) {
			setMessage(props => ({...props, error: "Please fill out both dates"}))
			setDisableButton(false)
			return
		}
		const data = {
			per_class: per_class,
            monthly: monthly,
            half_termly: half_termly,
            termly: termly,
            yearly: yearly,
            authorized: authorized,
            awaiting_payment: awaiting_payment,
			date: date,
			due_date: due_date
		}
		console.log(data)
		invoice_actions.create(data)
		.then(data => {
			console.log(data)
			setMessage(props => ({...props, success: t("invoices_generated")}))
		})
		.catch(error => {
			setMessage(props => ({...props, error: error.message}))
			setDisableButton(false)
		})
		.finally(async () => {
			return state_manager.setGetAction(undefined, undefined, {'approved': 'False', 'paid': 'False'})
			.then(() => {
				setDisableButton(false)
				handleClose()
			})
		})
	}

	const handleClose = () => {
		onClose()
	}

    const handlePerClassChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPerClass(event.target.checked)
	}

	const handleMonthlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setMonthly(event.target.checked)
	}

	const handleHalfTermlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setHalfTermly(event.target.checked)
	}

	const handleTermlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTermly(event.target.checked)
	}

	const handleYearlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setYearly(event.target.checked)
	}

	const handleAuthorizedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setAuthorized(event.target.checked)
	}

	const handleAwaitingPaymentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setAwaitingPayment(event.target.checked)
	}

	return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> {t('confirm')} </INastixDialogTitle>
			<DialogDivider/>
			<Typography>
			{t('please_select_which_type_of_member_to_generate_invoices_for')} 
			</Typography>
			<br/>
            <Grid container spacing={2}>
				<Grid item md={4}>
					<DialogFormSubHeadingTypography variant="h3"> {t('payment_structure')} </DialogFormSubHeadingTypography>
					<FormControlLabel 
						control={
							<Checkbox 
								checked={per_class}
								onChange={handlePerClassChange}
							/>
						} 
						label={t('per_class')}
					/>
					<FormControlLabel 
						control={
							<Checkbox 
								checked={monthly}
								onChange={handleMonthlyChange}
							/>
						} 
						label={t('monthly')}
					/>
					<FormControlLabel 
						control={
							<Checkbox 
								checked={half_termly}
								onChange={handleHalfTermlyChange}
							/>
						} 
						label={t('half_termly')}
					/>
					<FormControlLabel 
						control={
							<Checkbox 
								checked={termly}
								onChange={handleTermlyChange}
							/>
						} 
						label={t('termly')}
					/>
					<FormControlLabel 
						control={
							<Checkbox 
								checked={yearly}
								onChange={handleYearlyChange}
							/>
						} 
						label={t('yearly')}
					/>
				</Grid>
				<Grid item md={4}>
					<DialogFormSubHeadingTypography variant="h3"> {t('status')} </DialogFormSubHeadingTypography>
					<FormControlLabel 
						control={
							<Checkbox 
								checked={authorized} 
								onChange={handleAuthorizedChange} 
							/>
						} 
						label={t('authorized')}
					/>
					<FormControlLabel 
						control={
							<Checkbox 
								checked={awaiting_payment} 
								onChange={handleAwaitingPaymentChange} 
							/>
						} 
						label={t('awaiting_payment')}
					/>
				</Grid>
				<Grid item md={4}>
					<DialogFormSubHeadingTypography variant="h3"> {t('invoice_dates')} </DialogFormSubHeadingTypography>
					<DatePickerObjectNastix
						disabled={false}
						dates={date}
						setDates={setDate}
						dates_key='date'
						label={t('date')}
						use_mobile={true}
					/>
					<DatePickerObjectNastix
						disabled={false}
						dates={due_date}
						setDates={setDueDate}
						dates_key='due_date'
						label={t('due_date')}
						use_mobile={true}
					/>
				</Grid>
			</Grid>
			<Button disabled={disable_button} variant='contained' onClick={handleGenerate}> {t('generate')} </Button>
		</INastixDialog>
	)
}

const GenerateInvoicesDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

	const { t } = useTranslation('overview')

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<AddIcon/>}>
			<Typography> {t('generate_invoices')} </Typography>
		</TableActionButon>
		<GenerateInvoicesDialog ids={ids} open={open} state_manager={state_manager} onClose={handleClose}/>
		</>
	)
}

export default GenerateInvoicesDialogHandler