import { getMD5Hash } from '@utils/security/security'


const generatePayfastSignature = (data: any, passPhrase: string | null=null) => {
    let pfOutput = ""
    for (let key in data) {
        if(data.hasOwnProperty(key)) {
            console.log(data[key])
            if (data[key] !== "") {
                pfOutput +=`${key}=${encodeURIComponent(data[key].trim()).replace(/%20/g, "+")}&`
            }
        }
    }

    // Remove last ampersand
    let getString = pfOutput.slice(0, -1)
    if (passPhrase !== null) {
        getString +=`&passphrase=${encodeURIComponent(passPhrase.trim()).replace(/%20/g, "+")}`
    }

    return getMD5Hash(getString)
}

export {
    generatePayfastSignature
}