import CRUDFetchWrapper from "@adapters/api/fetchWrappers/crudFetchWrapper"
import { BaseHandler } from "@adapters/handlers/Handlers/handlers"
import { CHILDREN_ATTRITION_RATE_NUMBERS_STAT, CHILDREN_ATTRITION_RATE_PERCENTAGE_STAT, CHILDREN_CERTIFICATES_STAT, CHILDREN_STAT_AGE, CHILDREN_STAT_BIRTHDAY, CHILDREN_STAT_TERM_TO_TERM_GROWTH, CHILDREN_STAT_TSHIRTS, CHILDREN_STATUSES_STAT, CHILDREN_TOTAL_STAT, INSTRUCTOR_STAT_TSHIRTS } from "@adapters/routes/endpoints"


class ChildStatHandler extends BaseHandler {
    children_stat_age_endpoint: string
    children_total_stat_endpoint: string
    children_statuses_stat_endpoint: string
    children_stat_tshirts_endpoint: string
    instructor_stat_tshirts_endpoint: string
    children_attrition_rate_numbers_stat_endpoint: string
    children_attrition_rate_percentage_stat_endpoint: string
    children_certificates_stat_endpoint: string
    children_stat_term_to_term_growth_endpoint: string
    children_stat_birthday_endpoint: string
    fetch_wrapper: CRUDFetchWrapper

    constructor () {
        super()
        this.fetch_wrapper = new CRUDFetchWrapper()
        this.children_stat_age_endpoint = CHILDREN_STAT_AGE
        this.children_stat_tshirts_endpoint = CHILDREN_STAT_TSHIRTS
        this.instructor_stat_tshirts_endpoint = INSTRUCTOR_STAT_TSHIRTS
        this.children_total_stat_endpoint = CHILDREN_TOTAL_STAT
        this.children_statuses_stat_endpoint = CHILDREN_STATUSES_STAT
        this.children_attrition_rate_numbers_stat_endpoint = CHILDREN_ATTRITION_RATE_NUMBERS_STAT
        this.children_attrition_rate_percentage_stat_endpoint = CHILDREN_ATTRITION_RATE_PERCENTAGE_STAT
        this.children_certificates_stat_endpoint = CHILDREN_CERTIFICATES_STAT
        this.children_stat_term_to_term_growth_endpoint = CHILDREN_STAT_TERM_TO_TERM_GROWTH
        this.children_stat_birthday_endpoint = CHILDREN_STAT_BIRTHDAY
    }

    childrenStatAge (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.children_stat_age_endpoint)
        return this.fetch_wrapper.post(parsed_endpoint, data)
    }

    childrenStatTShirts (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.children_stat_tshirts_endpoint)
        return this.fetch_wrapper.post(parsed_endpoint, data)
    }

    instructorStatTShirts (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.instructor_stat_tshirts_endpoint)
        return this.fetch_wrapper.post(parsed_endpoint, data)
    }

    childrenTotalStat (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.children_total_stat_endpoint)
        return this.fetch_wrapper.post(parsed_endpoint, data)
    }

    childrenStatusesStat (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.children_statuses_stat_endpoint)
        return this.fetch_wrapper.post(parsed_endpoint, data)
    }

    childrenAttritionRateNumbersStat (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.children_attrition_rate_numbers_stat_endpoint)
        return this.fetch_wrapper.post(parsed_endpoint, data)
    }

    childrenAttritionRatePercentageStat (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.children_attrition_rate_percentage_stat_endpoint)
        return this.fetch_wrapper.post(parsed_endpoint, data)
    }

    childrenCertificatesStat (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.children_certificates_stat_endpoint)
        return this.fetch_wrapper.post(parsed_endpoint, data)
    }

    childrenStatTermToTermGrowth (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.children_stat_term_to_term_growth_endpoint)
        return this.fetch_wrapper.post(parsed_endpoint, data)
    }

    childrenStatBirthday (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.children_stat_birthday_endpoint)
        return this.fetch_wrapper.post(parsed_endpoint, data)
    }
}

export default ChildStatHandler