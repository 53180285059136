const TShirtReportDisplayMap = {
    per_child_report: "Per Child",
    per_school_report: "Per School",
    per_franchise_report: "Per Franchise"
}

const ReportDocTypeDisplayMap = {
    excel: "Excel",
    csv: "CSV",
}

export {
    TShirtReportDisplayMap,
    ReportDocTypeDisplayMap
}