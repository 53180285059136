import {useState} from 'react'
import { useNavigate } from 'react-router-dom'

import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

import GetCheckoutStepsComponent from './CheckoutStepsComponent'
import { CheckoutStepContext } from './contexts'


const steps = ['Your cart', 'Checkout details', 'Order complete']


const CheckoutSteps = () => {
    const navigation = useNavigate()

    const [checkout_step, setCheckoutStep] = useState(0)

    const handleBack = () => {
        setCheckoutStep(props => props - 1)
    }

    return (
        <Box sx={{ padding: '2rem' }}>
            <Button variant="outlined" onClick={() => navigation('/fzone?tab_type=shop')} startIcon={<ArrowBackIcon />} sx={{marginBottom: '2rem'}}>
                Shop
            </Button>
            <Stepper activeStep={checkout_step} sx={{marginBottom: '3rem'}}>
                {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {}
                const labelProps: {
                    optional?: React.ReactNode
                } = {}
                return (
                    <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                )
                })}
            </Stepper>
            {checkout_step === steps.length ? (
                <>
                <Typography sx={{ mt: 2, mb: 1 }}>
                    All steps completed - you&aposre finished
                </Typography>
                </>
            ) : (
                <>
                <CheckoutStepContext.Provider value={{checkout_step, setCheckoutStep}}>
                    {GetCheckoutStepsComponent(checkout_step)}
                </CheckoutStepContext.Provider>
                {checkout_step === 1 &&
                    <Button
                        color="inherit"
                        onClick={handleBack}
                        variant="contained"
                        sx={{ mt: 5 }}
                    >
                        Back
                    </Button>
                }
                </>
            )}
        </Box>
    )
}


export default CheckoutSteps