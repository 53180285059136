import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

import BackToLogin from '@pages/Setup/BackToLogin'

import AuthFooter from '../util/AuthFooter'
import { AuthTypography } from '../util/StyledComponents'


const ResetPasswordSucess = () => {
    return (
        <>
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection:'column'}} height={'100vh'}>
            <AuthTypography variant="h1" gutterBottom mt={'auto'}> Your Password has been reset </AuthTypography>
            <Typography sx={{maxWidth: '50%', margin: '1rem auto 2rem auto'}}>
                Go back to the login page to sign in with your new password
            </Typography>
            <BackToLogin/>
            <AuthFooter/>
        </Box>
        </>
    )
}

export default ResetPasswordSucess