import {useState, useEffect} from 'react'

import log from 'loglevel'

import { Franchisee, LocalStorageCompany, School } from '@utils/interfaces/interfaces'
import StateManager from '@actions/StateManager/stateManager'
import { createListOfMonkeynastixTypes, companyIsInGroup } from '@utils/utils/util'
import FranchiseeActions from '@actions/CRUDActions/FranchiseeActions/franchiseeActions'
import { CompanyAuthorisedMap } from '@utils/maps/maps'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { CompanySetupStep, CompanyType } from '@utils/enums/enums'
import SchoolActions from '@actions/CRUDActions/SchoolActions/schoolActions'


interface CompanyInfo {
	tenant_name: string
	role: string
	company_owner_name: string
    company_owner_email: string
	monkeynastix_type?: string
    master_admin_name?: string
    master_admin_authorised?: string
    monkeynastix_authorised?: string
}

interface SchoolInfo {
	tenant_name: string
	role: string
	school_owner_name: string
    school_owner_email: string
    school_type?: string
    is_sponsored?: "Yes" | undefined
    sponsored_fee?: number
    master_admin_name?: string
    master_admin_authorised?: string
    monkeynastix_authorised?: string
}

interface NoGroupYetInfo {
	tenant_name: string
	franchise_or_school_owner_name: string
    franchise_or_school_owner_email: string
}

function useCompanyNotificationInfo(ids: string[], state_manager: StateManager) {
	const [franchise_info, setFranchiseInfo] = useState<CompanyInfo | SchoolInfo | NoGroupYetInfo | null>(null)
	const [table_caption, setTableCaption] = useState("")

  	useEffect(() => {
		if (ids.length > 0) {
            const company = getLocalStorageCompany()

			const notification_id = ids[0]
			const notification = state_manager.data.find((notification) => notification.id === notification_id)
            if (notification) {
                const sent_from_company_uuid = notification.sent_from_company

                const franchisee_actions = new FranchiseeActions()
                franchisee_actions.getById(sent_from_company_uuid)
                .then(async (franchise: Franchisee) => {
                    let result
                    if (companyIsInGroup(franchise, CompanyType.SCHOOL))
                        result = await createSchoolInfo(franchise, company)
                    else if (companyIsInGroup(franchise, [CompanyType.FRANCHISEE, CompanyType.MASTER_FRANCHISE]))
                        result = await createFranchiseInfo(franchise, company)
                    else
                        result = await createNoGroupYetInfo(franchise, company)

                    const {franchise_info, table_caption} = result

                    setFranchiseInfo(franchise_info)
                    setTableCaption(table_caption)
                })
            }
    	}
	}, [ids, state_manager.data])

	return {franchise_info, table_caption}
}


const createSchoolInfo = async (franchise: Franchisee, company: LocalStorageCompany) => {
    let franchise_info: SchoolInfo = {
        tenant_name: franchise.tenant_name,
        role: franchise.groups[0]?.name ?? "",
        school_owner_name: franchise.company_owner_name,
        school_owner_email: franchise.company_owner_email
    }

    const school_actions = new SchoolActions()
    const schools: School[] = await school_actions.get(undefined, undefined, {'tenant__company_uuid': franchise.company_uuid})
    if (schools.length) {
        const school = schools[0]  // we can index by 0 because a company will only ever have one SchoolModel
        franchise_info.school_type = school.school_type
        franchise_info.is_sponsored = school.is_sponsored ? "Yes": undefined
        franchise_info.sponsored_fee = school.tuition_fee
    }

    if (franchise.setup_step >= CompanySetupStep.DETAILS_SETUP && franchise.setup_step !== CompanySetupStep.GET_AUTHORIZED)
        return {franchise_info: franchise_info, table_caption: "This School is not yet fully setup. Not all details will be shown."}
        
    return {franchise_info: franchise_info, table_caption: ""}
}

const createFranchiseInfo = async (franchise: Franchisee, company: LocalStorageCompany) => {
    const monkeynastix_types = createListOfMonkeynastixTypes(franchise)
    let franchise_info: CompanyInfo = {
        tenant_name: franchise.tenant_name,
        role: franchise.groups[0]?.name ?? "",
        company_owner_name: franchise.company_owner_name,
        company_owner_email: franchise.company_owner_email,
        monkeynastix_type: monkeynastix_types.join(" • "),
    }
    if (companyIsInGroup(company, CompanyType.FRANCHISOR)) {
        const is_franchise_past_choose_company_setup = franchise.setup_step > CompanySetupStep.CHOOSE_COMPANY
        const is_franchise_completed =  franchise.setup_step === CompanySetupStep.COMPLETED
        const master_franchise: string | null = franchise.master_franchisee

        if (is_franchise_past_choose_company_setup || is_franchise_completed) {
            const franchisee_actions = new FranchiseeActions()

            if (master_franchise) {
                franchisee_actions.getById(franchise.master_franchisee)
                .then((master_franchise: LocalStorageCompany) => {
                    franchise_info.master_admin_name = master_franchise.tenant_name

                    // we don't have to show the franchisor that the franchisor status of the company they authorizing 
                    if (!companyIsInGroup(master_franchise, CompanyType.FRANCHISOR))
                        franchise_info.master_admin_authorised = CompanyAuthorisedMap[franchise.master_admin_authorized.toString() as keyof typeof CompanyAuthorisedMap]
                })
            }
        }
        else
            return {franchise_info: franchise_info, table_caption: "This Franchise is not yet fully setup. Not all details will be shown."}
    }
    else if (companyIsInGroup(company, CompanyType.MASTER_FRANCHISE))
        franchise_info.monkeynastix_authorised = CompanyAuthorisedMap[franchise.monkeynastix_authorized.toString() as keyof typeof CompanyAuthorisedMap]
    else {
        const message = "Company does not the have the permissions to make authorisations"
        log.error(message)
        throw new Error(message)
    }

    return {franchise_info: franchise_info, table_caption: ""}
}

const createNoGroupYetInfo = async (franchise: Franchisee, company: LocalStorageCompany) => {
    let franchise_info: NoGroupYetInfo = {
        tenant_name: franchise.tenant_name,
        franchise_or_school_owner_name: franchise.company_owner_name,
        franchise_or_school_owner_email: franchise.company_owner_email,
    }
    return {franchise_info: franchise_info, table_caption: "This Franchise/School is not yet fully setup. Not all details will be shown."}
}

export default useCompanyNotificationInfo