import { useTranslation } from 'react-i18next'

import { CRUDAction } from '@actions/Actions/actions'
import ClassForm from '@components/forms/AttendanceForm'
import Table from '@components/tables/Table/Table'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { CompanyType } from '@utils/enums/enums'
import { companyIsInGroup } from '@utils/utils/util'

import ViewAndEditEvaluationDialogHandler from './ViewAndEditEvaluationDialogHandler'
import { addToColumnVisibilityModelForClassTables, columns_for_class_tables } from '..'
import PrintAssessmentDialogHandler from './PrintAssessments'
import AddChidlrenToEvaluationDialogHandler from './AddChildrenToEvaluationDialogHandler'


export interface TableProps {
	action: CRUDAction
	use_as_reporting?: boolean
}

const EvaluationsTable = (props: TableProps) => {
	const {action, use_as_reporting} = props
    const { t } = useTranslation('classes')

	const connected_company = getLocalStorageCompany()

	const connected_company_header = t('connected_franchise')
	const school_header = t('school')

	const is_franchise = connected_company.acts_like_a_franchise && companyIsInGroup(connected_company, CompanyType.FRANCHISEE)
	const use_pagination = companyIsInGroup(connected_company, CompanyType.MASTER_FRANCHISE) && !connected_company.acts_like_a_franchise ? true : false

	const can_delete = !use_as_reporting

	const columns = [
		{ field: 'id', headerName: 'ID', minWidth: 50, flex:2 },
		{ field: 'company_class_name', headerName: t('class_name'), minWidth: 150, flex:2},
		...columns_for_class_tables(connected_company_header, school_header, !use_pagination),
		{ field: 'instructor_name', sortable: !use_pagination, headerName: t('instructors'), minWidth: 275, flex: 2 },
		{ field: 'country_term_name_display', filterable: !use_pagination, sortable: !use_pagination, headerName: "Term Type", minWidth: 150, flex:2 },
		{ field: 'term_index_display', filterable: !use_pagination, sortable: !use_pagination, headerName: "Term", minWidth: 150, flex:2 },
		{ field: 'date_created', filterable: !use_pagination, sortable: !use_pagination, headerName: t('date_created'), minWidth: 150, flex: 2 },
	]

	const column_visibility_model = addToColumnVisibilityModelForClassTables()

	const paginationFilterParser = (field: string, value: string) => {
		let custom_operator = undefined

		if (field === 'company_class_name') {
			field = 'company_class__name'
		}
		else if (field === 'school_name') {
			field = 'company_class__school__name'
		}
		else if (field === 'connected_company_name') {
			field = 'company_class__school__connected_company__tenant_name'
		}
		return {field, value, custom_operator}
	}

	const getRowClassName = (params: any) => `super-app-theme--${params.row.evaluation_completion_status}`

	const single_selection_dialogs = []

	if (use_as_reporting)
		single_selection_dialogs.push(PrintAssessmentDialogHandler)
	else
		single_selection_dialogs.push(...[ViewAndEditEvaluationDialogHandler, AddChidlrenToEvaluationDialogHandler])

    return (
        <Table 
			single_selection_dialogs={single_selection_dialogs} 
			Form={ClassForm} 
			column_visibility_model={column_visibility_model}
			getRowClassName={getRowClassName}
			paginationFilterParser={use_pagination ? paginationFilterParser : undefined}
			use_pagination={use_pagination}
			action={action} 
			can_delete={can_delete}
			columns={columns}></Table>
    )
}

export default EvaluationsTable