import { createContext } from 'react'


export type SetSetupStep = React.Dispatch<React.SetStateAction<number>>

interface ISetupStepContext {
    setup_step: number
    setSetupStep: SetSetupStep
}

export const SetupStepContext = createContext<ISetupStepContext>({
    setup_step: 0,
    setSetupStep: () => {}
})