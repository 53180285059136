import { CRUDAction } from "@actions/Actions/actions"
import FZoneEcommerceCartHandler from "@adapters/handlers/CRUDHandlers/FZoneEcommerceHandlers/fzoneEcommerceCartHandler"


class FZoneEcommerceCartActions extends CRUDAction {
    handler: FZoneEcommerceCartHandler

    constructor() {
        super()
        this.handler = new FZoneEcommerceCartHandler()
    }
}

export default FZoneEcommerceCartActions