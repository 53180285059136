import { CRUDAction } from "@actions/Actions/actions"
import SendRoyaltiesHandler from "@adapters/handlers/FinanceHandlers/RoyaltyHandlers/sendRoyaltiesHandler"
import RoyaltyInvoiceHandler from "@adapters/handlers/FinanceHandlers/RoyaltyInvoiceHandler/royaltyInvoiceHandler"



class RoyaltyInvoiceActions extends CRUDAction {
    handler: RoyaltyInvoiceHandler
    send_royalties_handler: SendRoyaltiesHandler

    constructor() {
        super()
        this.handler = new RoyaltyInvoiceHandler()
        this.send_royalties_handler = new SendRoyaltiesHandler()
    }    

    sendRoyalties (data: object): Promise<any> {
        return this.send_royalties_handler.sendRoyalties(data)
    }
}

export default RoyaltyInvoiceActions