import {useEffect, useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import {
    Chart as ChartJS,
    ChartData,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import ChildActions from '@actions/CRUDActions/ChildActions/childActions'

import { ChartBox } from '../styles'
import ToggleChartVisibility from '../ToggleChartVisibility'
import { DashbaordStatsProps } from './interfaces'
import ReportingStatActions from '@actions/ReportActions/reportingStatActions'
import { FranchiseOption } from '..'


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
)


type ReportingStatData = {
    assessment: number
    child_certificates: number
    class_register: number
    franchise_details: number
    franchise_sales: number
    child_t_shirt: number
    instructor_t_shirt: number
    instructor_weekly_schedule: number
    package_slip: number
}

interface ReportingStatProps {
    edit_dashboard_open: boolean
    checked: boolean
    setChecked: React.Dispatch<React.SetStateAction<any>>
    selected_franchise_option: FranchiseOption | null
}

const ReportingStat = (props: ReportingStatProps) => {
    const {edit_dashboard_open, checked, setChecked, selected_franchise_option} = props
    
    const { t } = useTranslation('dashboard')
    
    const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)

    const labels = [t('assessment'), t('child_certificates'), t('class_register'), t('franchise_details'), t('franchise_sales'), t('child_t_shirt'), t('instructor_t_shirt'), t('instructor_weekly_schedule'), t('package_slip')]

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: t("number_of_each_report_created"),
            },
        },
    }

    const [data, setData] = useState<ChartData<"bar", (number | [number, number] | null)[], string>>({labels, datasets: []})

    useEffect(() => {
        if (!selected_franchise_option)
            return

        const setReportingStat = async () => {

            const reporting_stat_actions = new ReportingStatActions()

            const data: any = {
                franchise_id: selected_franchise_option.id,
            }


            const reporting_stat_data: ReportingStatData = await reporting_stat_actions.reportingStat(data)

            setData({
                labels,
                datasets: [
                    {
                        label: "reports",
                        data: Object.values(reporting_stat_data),
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                ],
            })
        }
        setReportingStat()

    }, [selected_franchise_option, change_company_rerender])

    return (
        <>
        <ChartBox edit_dashboard_open={edit_dashboard_open} checked={checked}>
            <Bar options={options} data={data} />
            <canvas style={{ display: 'inline', height: 0 }} />
        </ChartBox>
        {edit_dashboard_open && 
            <>
            <ToggleChartVisibility checked={checked} setChecked={setChecked}/>
            </>
        }
        </>
    )
}

export default ReportingStat
