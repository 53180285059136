import React, {useContext} from "react"

import handleUIMessage from "@adapters/helpers/handleUIMessage"
import { MessageContext } from "@contexts/MessageContext"
import { NumOfRequestsContext } from "@contexts/NumOfRequestsContext"


export type FormDataEntryType = {[k: string]: globalThis.FormDataEntryValue | boolean }

export type FormComponent = (onSubmitCallback: (form_data_object: FormDataEntryType) => Promise<any>, values?: object) => JSX.Element

export type OnSubmitCallback = (form_data_object: FormDataEntryType) => Promise<any>

interface FormProps {
    onSubmitCallback: OnSubmitCallback
    createFields: () => JSX.Element
    autofill?: "on" | "off"
}
  
const Form = (props: FormProps) => {
    const {onSubmitCallback, createFields, autofill="on"} = props

    const { message, setMessage } = useContext(MessageContext)
	const {setNumOfRequests} = useContext(NumOfRequestsContext)

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault()

        event.stopPropagation()

        setMessage({'success': "", 'error': "", 'form_error': "", 'info': "", 'warning': ""})
		setNumOfRequests(props => props + 1)

        const data = new FormData(event.currentTarget) 

        const form_data_object = Object.fromEntries(data.entries())
        const promise = onSubmitCallback(form_data_object)
        handleUIMessage(promise, setMessage, setNumOfRequests)
    }

    return (
        <>
        <form onSubmit={handleSubmit} autoComplete={autofill} style={{paddingTop: '1rem'}}>
            {createFields()}
            {message.form_error && <p style={{ color: 'red', textAlign: 'center' }}>{message.form_error}</p>}
        </form>
        </>
    )
}

export default Form
