import SettingsIcon from '@mui/icons-material/Settings'
import { StepIconProps } from '@mui/material/StepIcon'
import ShareIcon from '@mui/icons-material/Share'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import DataThresholdingIcon from '@mui/icons-material/DataThresholding'
import { ColorlibStepIconRoot } from '@pages/Setup/UserSetup/SetupStepper/ColorStepIcon'


const ColorlibStepIcon = (props: StepIconProps) => {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement } = {
        1: <SettingsIcon />,
        2: <ShareIcon/>,
        3: <DataThresholdingIcon />,
        4: <LockOpenIcon/>,
    }

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    )
}

export default ColorlibStepIcon