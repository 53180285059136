import { useTranslation } from 'react-i18next'

import Typography from '@mui/material/Typography'

import PageHelp from '@components/utils/PageHelp'
import BillsActions from '@actions/Finance/BillsActions/billsActions'
import BillsTable from '@components/tables/Custom/Finance/BillsTable'
import AddXeroContactDialogHandler from '@components/tables/Custom/Finance/BillsTable/AddXeroContactDialogHandler'

import FinancesPage from '../Finances'


const bills_actions = new BillsActions()

const BillsPage = () => {
	const { t } = useTranslation('overview')

	return (
		<FinancesPage>
			{/* <AddXeroContactDialogHandler/> */}
			<br/>
			<BillsTable action={bills_actions}/>
		</FinancesPage>
	)
}

export default BillsPage
