import { GenericAction } from "@actions/Actions/actions"
import AllCompaniesHandler from "@adapters/handlers/CompanyHandlers/allCompaniesHanlder"
import CompanyBankDetailsHandler from "@adapters/handlers/CompanyHandlers/companyBankDetails"
import CompanyHandler from "@adapters/handlers/CompanyHandlers/companyHandler"
import CompanySettingsHandler from "@adapters/handlers/CompanyHandlers/companySettingsHandler"
import CompanySetupHandler from "@adapters/handlers/CompanyHandlers/companySetupHandler"
import ConnectedCompaniesHandler from "@adapters/handlers/CompanyHandlers/connectedCompaniesHanlder"
import ProvisionDemoCompanyHandler from "@adapters/handlers/CompanyHandlers/provisionDemoAccountHandler"

import { LocalStorageCompany } from "@utils/interfaces/interfaces"


class CompanyActions extends GenericAction {
    handler: CompanyHandler
    setup_handler: CompanySetupHandler
    all_handler: AllCompaniesHandler
    company_settings_handler: CompanySettingsHandler
    connected_companies_handler: ConnectedCompaniesHandler
    company_bank_details: CompanyBankDetailsHandler
    provision_demo_company_handler: ProvisionDemoCompanyHandler

    constructor() {
        super()
        this.handler = new CompanyHandler()
        this.setup_handler = new CompanySetupHandler()
        this.all_handler = new AllCompaniesHandler()
        this.company_settings_handler = new CompanySettingsHandler()
        this.connected_companies_handler = new ConnectedCompaniesHandler()
        this.company_bank_details = new CompanyBankDetailsHandler()
        this.provision_demo_company_handler = new ProvisionDemoCompanyHandler()
    }

    /**
     * @returns the company that the user is connected to based on the user company_uuid field
     */
    getConnectedCompany (): Promise<LocalStorageCompany> {
        return this.handler.get()
    }

    createSetupDetails = (data: object): Promise<any> => {
        return this.setup_handler.createSetupDetails(data)
    }

    chooseCompanySetup = (data: object): Promise<any> => {
        return this.setup_handler.chooseCompanyTypeSetup(data)
    }

    financeAndTermsSetup = (data: object): Promise<any> => {
        return this.setup_handler.financeAndTerms(data)
    }

    xeroManagementSetup = (data: object): Promise<any> => {
        return this.setup_handler.xeroManagementSetup(data)
    }

    getAuthorizedSetup = (): Promise<any> => {
        return this.setup_handler.getAuthorizedSetup()
    }

    getAllCompanies = (): Promise<any> => {
        return this.all_handler.get()
    }

    getConnectedCompanies = (): Promise<any> => {
        return this.connected_companies_handler.get()
    }

    getCompanySettings = (id: string): Promise<any> => {
        return this.company_settings_handler.getById(id)
    }

    postCompanySetttings = (data: object): Promise<any> => {
        return this.company_settings_handler.post(data)
    }

    updateCompanySetttingsFinance = (data: object, id: string): Promise<any> => {
        return this.company_settings_handler.updateFinance(data, id)
    }

    updateCompanySetttingsPaymentStructures = (data: object, id: string): Promise<any> => {
        return this.company_settings_handler.updatePaymentStructures(data, id)
    }

    getCountryTerms = (country?: string): Promise<any> => {
        return this.company_settings_handler.getCountryTerms(country)
    }

    getBankDetails = (): Promise<any> => {
        return this.company_bank_details.get()
    }

    postBankDetails = (data: object): Promise<any> => {
        return this.company_bank_details.post(data)
    }

    provisionDemoCompany = (): Promise<any> => {
        return this.provision_demo_company_handler.post({})
    }
}

export default CompanyActions