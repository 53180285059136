import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'

import { SchoolType } from '@utils/enums/enums'


interface SelectProgramType {
    program_type: SchoolType
    setProgramType: React.Dispatch< React.SetStateAction<SchoolType>>
}

const SelectProgramType = (props: SelectProgramType) => {
    const {program_type, setProgramType} = props

    const handleProgramTypeChange = (event: SelectChangeEvent) => {
        setProgramType(event.target.value as SchoolType)
    }
    return (
        <FormControl sx={{width: '20ch', marginRight: 'auto'}} size="small">
            <InputLabel>Program Type</InputLabel>
            <Select
                value={program_type}
                onChange={handleProgramTypeChange}
                label="Program Type"
            >
                {Object.values(SchoolType).map((type) => (
                    <MenuItem key={type} value={type}>
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default SelectProgramType