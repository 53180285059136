import {useEffect, useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
} from 'chart.js'
import { Pie } from 'react-chartjs-2'

import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import ChildActions from '@actions/CRUDActions/ChildActions/childActions'

import { ChartBox } from '../styles'
import ToggleChartVisibility from '../ToggleChartVisibility'
import { FranchiseOption } from '..'
import { DashbaordStatsProps } from './interfaces'


ChartJS.register(
    ArcElement,
    Tooltip,
    Legend
)

type ChildrenCountData = {
    boy_children: number
    girl_children: number
    other_children: number
}


const ChildTotal = (props: DashbaordStatsProps) => {
    const {edit_dashboard_open, checked, setChecked, selected_franchise_option, selected_school_type_options} = props
    
    const { t } = useTranslation('dashboard')
    const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)

    const boys_label = t('boys')
    const girls_label = t('girls')
    const others_label = t('other')

    const labels = [boys_label, girls_label, others_label]

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: "Children Total",
            },
        },
    }

    const [data, setData] = useState<any>({labels, datasets: []})
    const [child_total, setChildTotal] = useState<number>(0)

    useEffect(() => {
        if (!selected_franchise_option)
            return

        const setChildrenBirthdayStat = async () => {

            const child_actions = new ChildActions()
            
            const data: any = {
                franchise_id: selected_franchise_option.id,
            }
            if (selected_school_type_options)
                data['selected_school_type_options'] = selected_school_type_options

            const children_count_data: ChildrenCountData = await child_actions.childrenTotalStat(data)
            setChildTotal(children_count_data.boy_children + children_count_data.girl_children + children_count_data.other_children)
            setData({
                labels,
                datasets: [
                    {
                        label: "Total",
                        data: [children_count_data.boy_children, children_count_data.girl_children, children_count_data.other_children],
                        backgroundColor: [
                            'rgba(53, 162, 235, 0.7)',
                            'rgba(255, 99, 132, 0.5)',
                            'rgba(53, 162, 235, 0.3)'
                        ],
                    }
                ],
            })
        }
        setChildrenBirthdayStat()

    }, [selected_franchise_option, selected_school_type_options, change_company_rerender])

    return (
        <>
        <ChartBox edit_dashboard_open={edit_dashboard_open} checked={checked}>
            <Pie options={options} data={data} />
            <p style={{margin: 0, color: 'darkslategrey', fontStyle: 'italic', fontWeight: '100', textAlign: 'right', marginRight: '2rem'}}> 
                Total: <span style={{fontStyle: 'normal'}}>{child_total}</span>
            </p>
            <canvas style={{ display: 'inline', height: 0 }} />
        </ChartBox>
        {edit_dashboard_open && 
            <>
            <ToggleChartVisibility checked={checked} setChecked={setChecked}/>
            </>
        }
        </>
    )
}

export default ChildTotal
