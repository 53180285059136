import { PDFPage } from 'pdf-lib'
import { COL2_EVALUATION_KEYS, EVALUATION_KEYS } from '.'
import { DEFAULT_COORDS } from './defaults'


const getStandardCertificateFormCoordinates = (certificate_page: PDFPage, add_height: number=0, add_width: number=0, is_print_certificates_checked: boolean=false, shift_up: string='', shift_down: string='', shift_left: string='', shift_right: string='') => {
    const { width, height } = certificate_page.getSize()
    
    let {form_width_start, form_height_start} = {form_width_start: width / 4, form_height_start: height / 2}

    let less_height_1 = 5
    let less_height_2 = 5
    let less_height_3 = 5
    if (is_print_certificates_checked) {
        form_width_start = form_width_start - 20
        // less_height_1 = 0
        // less_height_2 = 0
        // less_height_3 = 0
        
    }

    console.log(shift_up)
    console.log(shift_down)
    console.log(shift_right)
    console.log(shift_left)

    let shift_y_axis = Number(shift_up) - Number(shift_down)
    let shift_x_axis = Number(shift_right) - Number(shift_left)
    console.log(shift_y_axis)
    console.log(shift_x_axis)

    const name_coord = {x: form_width_start - 115 + 30 + add_width + shift_x_axis, y: form_height_start - 35 + add_height - less_height_1 + shift_y_axis}
    const surname_coord = {x: form_width_start - 105 + 30 + add_width + shift_x_axis, y: form_height_start - 75 + add_height - less_height_2 + shift_y_axis}
    const date_coord = {x: form_width_start - 135 + 40 + add_width + shift_x_axis, y: form_height_start - 115 + add_height - less_height_3 + shift_y_axis}
    const term_coord = {x: form_width_start + 85 + 30 + add_width + shift_x_axis, y: form_height_start - 115 + add_height - less_height_3 + shift_y_axis}
    const instructor_name_coord = {x: form_width_start - 50 + 30 + add_width + shift_x_axis, y: form_height_start - 155 + add_height - less_height_3 + shift_y_axis}

    return {name_coord, surname_coord, date_coord, term_coord, instructor_name_coord}
}

const getEvaluationPointsCoordinates = (certificate_page: PDFPage, is_print_certificates_checked: boolean, shift_up: string, shift_down: string, shift_left: string, shift_right: string) => {
    const { width, height } = certificate_page.getSize()

    let subtract_width_colum_1 = 307.5
    let subtract_width_column_2 = 174.9
    let divide_height = 3.5
    let space_to_next_evaluation = 67
    let space_between_to_red_points = 20
    let space_between_to_green_points = 21
    let shift_y_axis = 0
    let shift_x_axis = 0
    if (is_print_certificates_checked) {
        subtract_width_colum_1 = 256
        subtract_width_column_2 = 120
        divide_height = 3.75
        space_to_next_evaluation = 69
        shift_y_axis = Number(shift_up) - Number(shift_down)
        shift_x_axis = Number(shift_right) - Number(shift_left)
        // width_inputted = shift_right - shift_left
        // space_between_to_red_points = 21
        // space_between_to_green_points = 20.5
    }

    const column_1 = (width - subtract_width_colum_1) + shift_x_axis
    const column_2 = (width - subtract_width_column_2) + shift_x_axis

    const form_height_start = (height / divide_height) + shift_y_axis
    
    type EvaluationKeys = typeof EVALUATION_KEYS[number] | typeof COL2_EVALUATION_KEYS[number]

    const evaluations: {
        [x in EvaluationKeys]: {
            red: {x: number, y: number}, 
            yellow: {x: number, y: number}, 
            green: {x: number, y: number}
        }
    } = DEFAULT_COORDS

    EVALUATION_KEYS.forEach((key, index) => {
        evaluations[key] = getRedAndGreen(column_1, form_height_start + (space_to_next_evaluation * index), space_between_to_red_points, space_between_to_green_points)
    })
    COL2_EVALUATION_KEYS.forEach((key, index) => {
        evaluations[key] = getRedAndGreen(column_2, form_height_start + (space_to_next_evaluation * index), space_between_to_red_points, space_between_to_green_points)
    })

    return evaluations
} 

const getRedAndGreen = (form_width_start: number, form_height_start: number, y_red: number, y_green: number) => {
    return {
        red: {x: form_width_start, y: form_height_start + y_red},
        yellow: {x: form_width_start, y: form_height_start},
        green: {x: form_width_start, y: form_height_start - y_green}
    }
}


export {
    getStandardCertificateFormCoordinates,
    getEvaluationPointsCoordinates
}