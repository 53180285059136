import {useState, useEffect, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import Switch from '@mui/material/Switch'
import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'

import Pages from '@pages/Page'
import { PageHeadingTypography } from '@styles/Typography'
import { UserPermissions } from '@utils/enums/permissions'

import MemberTabs from './MemberTabs'
import { getLocalStorageCompany, updateLocalStorageCompany } from '@utils/localStorage/company'
import CompanyActions from '@actions/CompanyActions/companyActions'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import { getLocalStorageUser } from '@utils/localStorage/user'
import { isInGroup } from '@utils/utils/util'
import { UserType } from '@utils/enums/enums'


const ChildPage = () => {
	const { t } = useTranslation('children')

	const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)
	const [checked, setChecked] =  useState(false)

	const user = getLocalStorageUser()
	const connected_company = getLocalStorageCompany()

	const is_franchise_operator_admin = isInGroup(user, connected_company, [UserType.FRANCHISEE_OPERATOR, UserType.FRANCHISEE_ADMIN, UserType.MASTER_FRANCHISE_OPERATOR])
	const act_like_franchise = connected_company.acts_like_a_franchise

	useEffect(() => {
		if (connected_company.ready_to_send_certificates)
			setChecked(true)
		else
			setChecked(false)
	}, [change_company_rerender])

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
	  	const is_checked = event.target.checked
		
		const company_actions = new CompanyActions()
		company_actions.update({ready_to_send_certificates: is_checked}, connected_company.tenant_id.toString())
		.then(() => {
			updateLocalStorageCompany('ready_to_send_certificates', is_checked)
			setChecked(is_checked)
		})
	}

	const label_text = checked ? "Certificates ready!" : "Ready to send certificates?"

	return (
		<Pages page_title="Members" permission={UserPermissions.VIEW_CHILDREN}>
			<Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} sx={{flexWrap: 'wrap', gap: '1rem', marginBottom: '2rem'}}>
				<PageHeadingTypography> {t('members')} </PageHeadingTypography>
				{is_franchise_operator_admin && act_like_franchise ?
					<FormControlLabel
						value="bottom" 
						control={
							<Switch 
								checked={checked}
								onChange={handleChange}
								inputProps={{ 'aria-label': 'Color switch demo' }} 
								color="warning" 
							/>
						}
						label={label_text}
						labelPlacement="bottom"
						sx={{ 
							'.MuiFormControlLabel-label': {
							  fontSize: '0.7rem', // Change this to your desired font size
							},
							color: 'dimgray',
						}}
					/>
					: <></>
				}
			</Box>
            <MemberTabs/>
		</Pages>
	)
}

export default ChildPage
