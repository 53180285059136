import { CSSProperties } from 'react';

const tableStyles: {
    headerCell: CSSProperties,
    cell: CSSProperties,
} = {
    headerCell: {
        border: '1px solid black', 
        padding: '8px',
        textAlign: 'left',
    },
    cell: {
        border: '1px solid black', 
        padding: '4px',
        textAlign: 'right'
    }
};

export { tableStyles };
