import { XERO_ACCOUNTS_ENDPOINT } from "@adapters/routes/endpoints"
import { GenericHandler } from "../../Handlers/handlers"


class XeroAccountCodesHandler extends GenericHandler {
    constructor (endpoint=XERO_ACCOUNTS_ENDPOINT) {
        super(endpoint)
    }
}

export default XeroAccountCodesHandler