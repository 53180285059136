import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import { useMediaQuery, Theme } from '@mui/material'

import Pages from '@pages/Page'
import ContactForm from '@components/forms/ContactForm'
import { PageHeadingTypography } from '@styles/Typography'
import { useTranslation } from 'react-i18next'


// building the contact page to similar to payfast -> https://payfast.io/contact/
const ContactPage = () => {
    const is_small_screen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

    const { t } = useTranslation('contact')

    return (
		<Pages page_title="Contact">
			<PageHeadingTypography> {t('contact')} </PageHeadingTypography>
            <br/>
            <Grid container spacing={4} sx={is_small_screen ? {flexDirection: 'column'} : {}}>
                <Grid item sm={6}>
                    <ContactForm/>
                </Grid>
                {is_small_screen &&
                    <Grid item sm={6} sx={{ maxWidth: '100%' }}>
                        <Divider/>
                    </Grid>
                }
                <Grid item sm={6} sx={{ maxWidth: 400 }}>
                    <Typography variant="h6" sx={{ mb: 2 }}>
                        {t('contact_support')}
                    </Typography>
                    <Typography variant="body1" sx={{ mb: 2 }}>
                        {t('contact_our_customer_services_team_directly')}
                    </Typography>
                    <Typography variant="body2" component="div" sx={{ mb: 2 }}>
                        <strong>{t('office_hours')}:</strong>
                        <br />
                        Mon – Fri: 08:00 – 17:00 (GMT +2)
                        <br />
                        <br />
                        {t('our_office_hours_and_email_support_exclude_public_holidays')}
                    </Typography>
                    <Typography variant="body2" component="div">
                        <strong>{t('contact_details')}:</strong>
                        <br />
                        {t('email_support_devsden_co_za')}
                        <br />
                        Tel: +27 72 236 9424
                    </Typography>
                </Grid>
            </Grid>
        </Pages>
    )
}

export default ContactPage
