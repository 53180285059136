import {useEffect} from 'react'

import Box from '@mui/material/Box'

import { Typography } from '@mui/material'


const OrderComplete = () => {
    useEffect(() => {

    }, [])

	return (
        <Box sx={{ flexGrow: 1 }}>
            <Typography variant='h3'> Order Complete </Typography>
        </Box>
	)
}

export default OrderComplete
