import FranchiseeHandler from "@adapters/handlers/CRUDHandlers/FranchiseeHandlers/franchiseeHandlers"

import { CRUDAction } from "@actions/Actions/actions"
import FranchiseesByCompanyUUIDsHandler from "@adapters/handlers/CRUDHandlers/FranchiseeHandlers/franchiseesByCompanyUUIDsHandler"


class FranchiseeActions extends CRUDAction {
    handler: FranchiseeHandler
    by_company_uuids: FranchiseesByCompanyUUIDsHandler
    constructor() {
        super()
        this.handler = new FranchiseeHandler()
        this.by_company_uuids = new FranchiseesByCompanyUUIDsHandler()

    }

    getFranchiseesByCompanyUUIDs (ids: string[]): Promise<any> {
        return this.by_company_uuids.getByCompanyUUIDs(ids)
    }

    updateMultiple (data: object[]): Promise<any> {
        return this.handler.putMultiple(data)
    }
}

export default FranchiseeActions