import EvaluationsTable from "@components/tables/CRUD/ClassTable/Evaluations/EvaluationsTable"
import EvaluationActions from "@actions/CRUDActions/ClassActions/evaluationActions"

import ReportingPage from ".."


const ReportAssessments = () => {
	const evalaution_actions = new EvaluationActions()

    return (
        <ReportingPage>
            <EvaluationsTable action={evalaution_actions} use_as_reporting={true}/>
        </ReportingPage>
    )
}

export default ReportAssessments