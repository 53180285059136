import { CRUDAction } from "@actions/Actions/actions"
import ChildEvaluationHandler from "@adapters/handlers/CRUDHandlers/ClassHandlers/childEvaluationHandler"


class ChildEvaluationActions extends CRUDAction {
    handler: ChildEvaluationHandler

    constructor() {
        super()
        this.handler = new ChildEvaluationHandler()
    }
}

export default ChildEvaluationActions