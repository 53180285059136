import XeroLogo from '@images/xero/logo/logo.png'
import { xero_config as config } from 'src/config'

import Button from '@mui/material/Button'


interface XeroSignUpProps {
    label_text?: string
    styles?: React.CSSProperties
}

const CLIENT_ID = process.env.REACT_APP_INASTIX_CLIENT_ID

// Xero sign in button docs -> https://developer.xero.com/documentation/xero-app-store/app-partner-guides/sign-in/#1-create-your-oauth20-app
const XeroSignUp = ({label_text = "Sign Up with Xero", styles}: XeroSignUpProps) => {

    const handleSignUp = () => {
        window.location.href = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${CLIENT_ID}&redirect_uri=${config.xero_sign_up_callback_url}&scope=${config.xero_scopes}`
    }
    
    return (
        <>
        <Button onClick={handleSignUp} sx={{border: '1px solid lightgrey', padding: "0.2rem 1rem", textTransform: 'none', gap: 1, borderRadius: 2, color: 'white', ...styles}}> 
            <img src={XeroLogo} alt="A blue Xero logo" width="35px"/>
            {label_text} 
        </Button>
        </>
    )
}

export default XeroSignUp
