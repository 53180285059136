import { SchoolConfig } from "."
import { DRIVING_VEHICLE_FUEL_EFFICIENCY_IN_KM_PER_L_MAP } from './maps'


const schoolsTotalTimeOfTravel = (school_config: SchoolConfig) => {
    if (!school_config.school_classes)
        return 0

    const schools_total_time_of_travel = school_config.school_classes.reduce((acc, school_class) => acc + school_class.travel_time, 0)
    return schools_total_time_of_travel
}

const schoolsTotalDistanceOfTravel = (school_config: SchoolConfig) => {
    if (!school_config.school_classes)
        return 0

    const schools_total_distance_of_travel = school_config.school_classes.reduce((acc, school_class) => acc + school_class.travel_distance, 0)
    return schools_total_distance_of_travel
}

const schoolsTotalCostOfTravel = (school_config: SchoolConfig) => {
    if (!school_config.school_classes)
        return 0

    const schools_total_cost_of_travel = school_config.school_classes.reduce((acc, school_class) => acc + (school_class.travel_cost ?? 0), 0)
    return schools_total_cost_of_travel
}

const schoolsAverageFuelEfficieny = (school_config: SchoolConfig) => {
    if (!school_config.vehicle_weight)
        return 0

    const fuel_effiency = DRIVING_VEHICLE_FUEL_EFFICIENCY_IN_KM_PER_L_MAP[school_config.vehicle_weight]
    return fuel_effiency
}

export {
    schoolsTotalTimeOfTravel,
    schoolsTotalDistanceOfTravel,
    schoolsTotalCostOfTravel,
    schoolsAverageFuelEfficieny
}