import ClassTable from "@components/tables/CRUD/ClassTable"
import ClassActions from "@actions/CRUDActions/ClassActions/classActions"

import ReportingPage from ".."


const ReportClassRegisters = () => {
	const class_actions = new ClassActions()

    return (
        <ReportingPage>
            <ClassTable action={class_actions} use_as_reporting={true}/>
        </ReportingPage>
    )
}

export default ReportClassRegisters