import CompanyActions from '@actions/CompanyActions/companyActions'
import { LocalStorageCompany } from '@utils/interfaces/interfaces'


const fetchConnectedCompany = async (retry_count: number = 0): Promise<LocalStorageCompany> => {
    const company_actions = new CompanyActions();

    return company_actions.getConnectedCompany()
        .then(company => {
            if (company.is_demo_company && !company.groups.length && retry_count < 3) {
                return fetchConnectedCompany(retry_count + 1)  // Recursive call with incremented counter
            } else {
                return company
            }
        })
}

export {
    fetchConnectedCompany
}