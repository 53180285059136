import {useEffect, useState, useCallback} from 'react'
import { useTranslation } from 'react-i18next'

import { GridColumnVisibilityModel  } from '@mui/x-data-grid'

import { CRUDAction } from '@actions/Actions/actions'
import Table, { TableSelections } from '@components/tables/Table/Table'
import { companyIsInGroup, permissionValid } from '@utils/utils/util'
import { getLocalStorageUser } from '@utils/localStorage/user'
import { UserPermissions } from '@utils/enums/permissions'
import EditInstructorForm from '@components/forms/InstructorForm'
import { CompanyType } from '@utils/enums/enums'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import UserActions from '@actions/CRUDActions/UserActions/userActions'
import { Instructor, LocalStorageCompany } from '@utils/interfaces/interfaces'
import { INastixCurrencyMap } from '@utils/maps/maps'
import CreateInstructorTShirtReporDialogHandler from '@components/dialogs/Reports/TShirtReports/InstructorTShirtReportDialogHandler'

import './styles.css'
import { authorized_column_config, getDateTimeValue, renderConnectedCompanyCell, renderUsernameCell } from '../UsersTable'

import log from 'loglevel'
import { instructorShirtSizeValueGetter } from '../InstructorTable'


export interface TableProps {
	action: CRUDAction
}


const Assistantable = (props: TableProps) => {
	const {action} = props
    const { t } = useTranslation('instructors')

	const user = getLocalStorageUser()
	const company = getLocalStorageCompany()

	const can_edit_users = permissionValid(user, UserPermissions.EDIT_USERS)
	const can_delete_users = permissionValid(user, UserPermissions.DELETE_USERS)
	const is_franchisor = companyIsInGroup(company, CompanyType.FRANCHISOR)

    const [can_edit, setCanEdit] = useState<boolean>(can_edit_users)
    const [can_delete, setCanDelete] = useState<boolean>(can_delete_users)
    const [table_selections, setTableSelections] = useState<TableSelections>({
        row_selection_model: [],
        selections: []
    })

	const salary_field_name = `${t('salary')} (${INastixCurrencyMap[company.currency]})`

	const setSelectionsCallback = (data: TableSelections) => {
		setTableSelections(data)
	}

	const isUserInConnectedCompany = useCallback(async (user_id: number) => {
		const user_actions = new UserActions()
	
		try {
			const connected_companies: LocalStorageCompany[] = await user_actions.getCompanies(user_id)
			const found_company = connected_companies.find(connected_company => connected_company.company_uuid === company.company_uuid)
			if (found_company)
				return true
			else
				return false

		} catch (error: any) {
			log.error(error.message)
			return false
		}
	}, [company])

	useEffect(() => {
		const checkUserInCompany = async () => {
			if (!table_selections.selections.length) {
				setCanEdit(can_edit_users)
				setCanDelete(can_delete_users)
			} else {
				const selected_user = table_selections.selections[0] as Instructor

				if (selected_user.email === user.email) {
					setCanEdit(false)
					setCanDelete(false)
				}
				else if (!is_franchisor && !await isUserInConnectedCompany(selected_user.user)) {
					setCanEdit(false)
					setCanDelete(false)
				}
				else {
					setCanEdit(can_edit_users)
					setCanDelete(can_delete_users)
				}
			}
		}
		checkUserInCompany()
	}, [table_selections, user, can_edit_users, can_delete_users, is_franchisor, isUserInConnectedCompany])

	const columns = [
		{ field: 'id', headerName: 'ID', minWidth: 50, flex: 2 },
		{ field: 'username', headerName: t('name'), renderCell: renderUsernameCell, minWidth: 200, flex: 2 },
		{ field: 'company_name', headerName: t('franchise_school'), renderCell: renderConnectedCompanyCell, minWidth: 200, flex: 2 },
		{ field: 'salary', headerName: salary_field_name, minWidth: 100, flex: 2 },
		{ field: 'commission', headerName: t('commission'), minWidth: 120, flex: 2 },
		{ field: 'master_admin_authorized', headerName: t('mfo_authorized'), description: t('master_franchise_operator_authorized'), ...authorized_column_config},	
		{ field: 'operator_authorized', headerName: t('fo_authorised'), description: t('franchise_operator_authorized'), ...authorized_column_config},
		{ field: 'monkeynastix_authorized', headerName: t('mn_authorised'), description: t('monkeynastix_authorised'), ...authorized_column_config},
		{ field: 'shirt_size', headerName: t('shirt_size'), valueGetter: instructorShirtSizeValueGetter },
		{ field: 'email', headerName: t('contact'), minWidth: 200, flex: 2},
		{ field: 'last_login', headerName: t('last_login'), valueGetter: getDateTimeValue, minWidth: 200, flex: 2}
	]

	const column_visibility_model: GridColumnVisibilityModel = {}

	const is_school_or_franchise = companyIsInGroup(company, [CompanyType.SCHOOL, CompanyType.FRANCHISEE])
	if (is_school_or_franchise) {
		column_visibility_model['company_name'] = false
	}

    return (
        <Table
			dialogs={[CreateInstructorTShirtReporDialogHandler]}
			action={action}
			columns={columns}
			Form={EditInstructorForm}
			column_visibility_model={column_visibility_model}
			can_edit={can_edit}
			can_delete={can_delete}
			setSelectionsCallback={setSelectionsCallback}
		/>
    )
}

export default Assistantable