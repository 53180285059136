import { CRUDAction } from "@actions/Actions/actions"
import SchoolPaymentsHandler from "@adapters/handlers/FinanceHandlers/PaymentHandlers/schoolPaymentHandlers"


class SchoolPaymentsActions extends CRUDAction {
    handler: SchoolPaymentsHandler

    constructor() {
        super()
        this.handler = new SchoolPaymentsHandler()
    }    
}

export default SchoolPaymentsActions