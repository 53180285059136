import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { Button, IconButton, TextField, Typography } from "@mui/material"
import { DialogFormControl } from "@styles/Dialog"
import EditIcon from '@mui/icons-material/Edit'
import { Delete } from "@mui/icons-material"
import { RoyaltyInvoice, RoyaltyLine } from "@utils/interfaces/interfaces"
import { MessageContext } from "@contexts/MessageContext"
import RoyaltyInvoiceActions from "@actions/Finance/RoyaltyInvoiceActions/royaltyInvoiceActions"
import RoyaltyActions from "@actions/Finance/RoyaltyActions/royaltyActions"
import StateManager from "@actions/StateManager/stateManager"


interface LineItemProps {
    royalty_line_values: RoyaltyLine | null
    royalty_invoice_values: RoyaltyInvoice | null
    index: number
    disabled: boolean
    state_manager: StateManager
}

const LineItemForm = (props: LineItemProps) => {
    const {royalty_line_values, royalty_invoice_values, index, disabled, state_manager} = props

    const { t } = useTranslation('')

    const {setMessage} = useContext(MessageContext)

    const [amount, setAmount] = useState('')

    useEffect(() => {
        if (royalty_line_values) {
            setAmount(royalty_line_values.vat_amount)
        }
        if (royalty_invoice_values) {
            setAmount(royalty_invoice_values.royalties_payable)
        }
    }, [royalty_line_values, royalty_invoice_values])
    
    const handleAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAmount(event.target.value as string)
    }

    const handleTotalAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAmount(event.target.value as string)
    }

    const changeLineItem = (type: string) => {
        const royalty_actions = new RoyaltyActions()
        const royalty_invoice_actions = new RoyaltyInvoiceActions()
        if (type === 'member') {
            if (royalty_line_values) {
                console.log(royalty_line_values)
                const line_data = {
                    'amount': amount,
                }
                royalty_actions.update(line_data, royalty_line_values.id.toString())
                .then(data => {
                    state_manager.setGetAction()
                    setMessage(data)    
                })
            }
        }
        if (type === 'invoice') {
            if (royalty_invoice_values) {
                console.log(royalty_invoice_values)
                const invoice_data = {
                    'amount': amount,
                }
                royalty_invoice_actions.update(invoice_data, royalty_invoice_values.id.toString())
                .then(data => {
                    console.log(data)
                    setMessage(data)
                })
            }
        }   
    }
    const DeleteLineItem = (index: number, type: string) => {
        const royalty_actions = new RoyaltyActions()
        const royalty_invoice_actions = new RoyaltyInvoiceActions()
        if (type === 'member') {
            if (royalty_line_values) {
                royalty_actions.delete(royalty_line_values.id.toString())
                .then(data => {
                    setMessage(data)
                })
            }
        }
        if (type === 'invoice') {
            if (royalty_invoice_values) {
                royalty_invoice_actions.delete(royalty_invoice_values.id.toString())
                .then(data => {
                    setMessage(data)
                })
            }
        }
    }

    return (
        <>
            { royalty_line_values &&
                <DialogFormControl style={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography variant="body1" fontSize="1.1rem" style={{ textAlign: "center", width: '400px', borderRight: "1px black solid", marginRight: "1rem" }}>
                        {royalty_line_values.child_name}
                    </Typography>
                    <TextField
                        label={t('amount')}
                        name={`${index}-amount`}
                        value={amount}
                        onChange={handleAmountChange}
                        disabled={disabled}
                    />
                    { !disabled &&
                    <>
                    <IconButton
                        aria-label="Delete"
                        onClick={() => DeleteLineItem(index, 'member')}
                    >
                        <Delete />
                    </IconButton>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={() => changeLineItem('member')}
                        style={{ width: '300px' }}
                    >
                        {t('change_line')}
                    </Button>
                    </>
                    }
                </DialogFormControl>
            }
            { royalty_invoice_values &&
                <DialogFormControl style={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography variant="body1" fontSize="1.1rem" style={{ textAlign: "center", width: '400px', borderRight: "1px grey solid", marginRight: "1rem" }}>
                        {royalty_invoice_values.tenant_name}
                    </Typography>
                    <TextField
                        label={t('amount')}
                        name={`${index}-amount`}
                        value={amount}
                        onChange={handleTotalAmountChange}
                        style={{ marginRight: '10px', width: '200px' }}
                        disabled={disabled}
                    />
                    { !disabled &&
                    <>
                    <IconButton
                        aria-label="Delete"
                        onClick={() => DeleteLineItem(index, 'invoice')}
                    >
                        <Delete />
                    </IconButton>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<EditIcon />}
                        onClick={() => changeLineItem('invoice')}
                        style={{ width: '300px' }}
                    >
                        {t('change_line')}
                    </Button>
                    </>
                    }
                </DialogFormControl>
            }
        </>
    )
}

export default LineItemForm