import GenericFetchWrapper from "@adapters/api/fetchWrappers/genericFetchWrapper"
import { BaseHandler } from "@adapters/handlers/Handlers/handlers"
import { SCHOOLS_CONNECT_A_FRANCHISE_SETUP_ENDPOINT } from "@adapters/routes/endpoints"


class SchoolConnectAFranchiseSetupHandler extends BaseHandler {
    endpoint: string
    fetch_wrapper: GenericFetchWrapper

    constructor () {
        super()
        this.fetch_wrapper = new GenericFetchWrapper()
        this.endpoint = SCHOOLS_CONNECT_A_FRANCHISE_SETUP_ENDPOINT
    }

    connectAFranchisesetup (data: object): Promise<any> {
        const parsed_endpoint = this.fetch_wrapper.parse_endpoint(this.endpoint)
        return this.fetch_wrapper.post(parsed_endpoint, data)
    }
}

export default SchoolConnectAFranchiseSetupHandler