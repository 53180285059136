import React, { useState, useEffect, useContext } from "react"
import { useTranslation } from "react-i18next"

import TextField from '@mui/material/TextField'
import InputLabel from "@mui/material/InputLabel"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import { SelectChangeEvent } from "@mui/material/Select"

import AddressFields from "@components/forms/Fields/AddressFields"
import { Address, Instructor} from '@utils/interfaces/interfaces'
import LanguageSelector from "@components/language/LanguageSelector"
import UserActions from "@actions/CRUDActions/UserActions/userActions"
import InstructorActions from "@actions/CRUDActions/InstructorActions/instructorActions"
import { ChangeUserRerenderContext } from "@contexts/TriggerRerender/userRerender"
import PhoneNumber from "@pages/Setup/UserSetup/UserDetails/PhoneNumber"
import EmailFields from "@components/forms/Fields/EmailFields"
import { MessageContext } from '@contexts/MessageContext'
import { SettingsHeadingTypography } from '@styles/Typography'
import { getLocalStorageUser } from "@utils/localStorage/user"

import Settings from "../Settings"
import SettingsSubHeading from '../Settings/SettingsSubHeading'
import { EmptyInstructorDocsProps } from "@components/tables/CRUD/UsersTable/UploadFilesDialogHandler"
import INastixFileUpload from "@pages/Setup/UserSetup/ChooseUserType/INastixFileUpload"
import { backend_url } from "src/config"
import { Box } from "@mui/material"
import GenericFetchWrapper from "@adapters/api/fetchWrappers/genericFetchWrapper"
import { JWT_PING_ENDPOINT } from "@adapters/routes/endpoints"
import log from "loglevel"


const UserSettings = () => {
    const {setMessage} = useContext(MessageContext)
    const {change_user_rerender} = useContext(ChangeUserRerenderContext)
    const { t } = useTranslation('user_settings')

    const [username, setUsername] = useState('');
    const [address, setAddress] = useState<Address>({
        id: 0,
        country: '',
        street: '',
        city: '',
        region: '',
        postal_code: '',
    })

    const [contact_info, setContactInfo] = useState({
        email: '',
        phone_number: '',
        country_code: ''
    })

    const [shirtSize, setShirtSize] = useState('')
    const [isInstructor, setIsInstructor] = useState(false)
    const [instructorId, setInstructorId] = useState('')
    const [user_id, setUserId] = useState(0)

    const [instructor_docs, setInstructorDocs] = useState<EmptyInstructorDocsProps | null>(null)

    const gl_course_label = "GL Course" 
    const police_clearance_label = "Police Clearance Certificate"
    const copy_of_id_label = "Copy of your ID"
    const employment_contract_label = "Employment Contract"

    const convertFileToBlob = async (intial_file_url: string): Promise<File | null> => {
        return fetch(intial_file_url)
        .then(response => response.blob())
        .then(blob => {
            const name = intial_file_url.split('/').pop() || 'existing_file'
            const file = new File([blob], name, { type: blob.type })
            return file
        })
        .catch(error => {
            console.error('Error fetching the existing file:', error)
            return null
        })
    }

    useEffect(() => {
        if (!instructorId)
            return

        const settingIntiialInstructorDocs = async () => {
            const instructor_actions = new InstructorActions()
            instructor_actions.getById(instructorId.toString())
            .then(async (instructor: Instructor) => {
                const empty_instructor_docs: EmptyInstructorDocsProps = {
                    gl_course: null,
                    police_clearance_certificate: null,
                    copy_of_id_photo: null,
                    employment_contract: null
                }

                if (instructor.south_african_gl_course)
                    empty_instructor_docs.gl_course = await convertFileToBlob(`${backend_url}${instructor.south_african_gl_course}`)
                if (instructor.police_clearance_certificate)
                    empty_instructor_docs.police_clearance_certificate = await convertFileToBlob(`${backend_url}${instructor.police_clearance_certificate}`)
                if (instructor.copy_of_id_photo)
                    empty_instructor_docs.copy_of_id_photo = await convertFileToBlob(`${backend_url}${instructor.copy_of_id_photo}`)
                if (instructor.employment_contract)
                    empty_instructor_docs.employment_contract = await convertFileToBlob(`${backend_url}${instructor.employment_contract}`)

                setInstructorDocs(empty_instructor_docs)
            })
        }

        settingIntiialInstructorDocs()

    }, [instructorId])

    useEffect(() => {
        const user = getLocalStorageUser()
        const name = user.username
        setUsername(name)
        const user_actions = new UserActions()
        user_actions.getUserSettings(user.id.toString())
        .then(user_data => {
            setUserId(user_data.user_id)

            for (const address of user_data.addresses) {
                const parsed_address = Object.fromEntries(Object.keys(address).map(key => address[key] ? [key, address[key]] : [key, '']))
                setAddress(parsed_address as Address)  // TODO: only setting one address block for now. Address is a one to many so need to add support for multiple
            }

            const contacts = user_data.contacts
            for (const contact of contacts) {
                setContactInfo(contact)  // TODO: only setting one contact block for now. Contact is a one to many so need to add support for multiple
            }
        })
        
    }, [change_user_rerender])

    useEffect(() => {
        const user = getLocalStorageUser()
        const instructor_actions = new InstructorActions()
        instructor_actions.get(undefined, undefined, {user: user.id.toString()})
        .then(instructor_data => {
            if (instructor_data.length > 0) {
                setShirtSize(instructor_data[0].shirt_size)
                setIsInstructor(true)
                setInstructorId(instructor_data[0].id)
            }
        })
    }, [])

    const handleAddressSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault()

        const data = new FormData(event.currentTarget)
        const form_data_object = Object.fromEntries(data.entries())
        const user_actions = new UserActions()
        user_actions.postUserSetttings({id: user_id, addresses: [form_data_object]})
        .then(() => {
            setMessage(props => ({...props, success: t('updated_successfully')}))
        })
        .catch(() => {
            setMessage(props => ({...props, error: t('something_went_wrong')}))
        }) 
    }

    const handleContactSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault()

        // set default country code to south africa
        if (!contact_info.country_code)
            setContactInfo(props => ({...props, country_code: '27'}))

        const user_actions = new UserActions()
        user_actions.postUserSetttings({id: user_id, contacts: [contact_info]})
        .then(() => {
            setMessage(props => ({...props, success: t('updated_successfully')}))
        })
        .catch(() => {
            setMessage(props => ({...props, error: t('something_went_wrong')}))
        })
    }

    const handleInstructorDetailsSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const instructor_actions = new InstructorActions()
        instructor_actions.update({shirt_size: shirtSize, }, instructorId.toString())
        .then(() => {
            setMessage(props => ({...props, success: t('updated_successfully')}))
        })
        .catch(() => {
            setMessage(props => ({...props, error: t('something_went_wrong')}))
        })

        let form_data = new FormData()

        const fetch_wrapper = new GenericFetchWrapper()
        await fetch_wrapper.get(JWT_PING_ENDPOINT)

        form_data.append('instructor_id', instructorId.toString())

        if (instructor_docs) {
            if (instructor_docs.gl_course)
                form_data.append('south_african_gl_course', instructor_docs.gl_course)
            if (instructor_docs.police_clearance_certificate)
                form_data.append('police_clearance_certificate', instructor_docs.police_clearance_certificate)
            if (instructor_docs.copy_of_id_photo)
                form_data.append('copy_of_id_photo', instructor_docs.copy_of_id_photo)
            if (instructor_docs.employment_contract)
                form_data.append('employment_contract', instructor_docs.employment_contract)
        }
        else {
            setMessage(props => ({...props, error: "Submit at least one document."}))
            return
        }

        instructor_actions.updateInstructorFiles(form_data, instructorId.toString())
        .catch((message) => {
            log.error(message)
        })
    }

    const handleSelectedSouthAfricanGICourseFileCallback = (file: File) => {
        if (!instructor_docs)
            return

        instructor_docs['gl_course'] = file
        setInstructorDocs(instructor_docs)
    }

    const handleSelectedPoliceClearanceCertificateFileCallback = (file: File) => {
        if (!instructor_docs)
            return

        instructor_docs['police_clearance_certificate'] = file
        setInstructorDocs(instructor_docs)
    }

    const handleSelectedCopyOfIdPhotoFileCallback = (file: File) => {
        if (!instructor_docs)
            return

        instructor_docs['copy_of_id_photo'] = file
        setInstructorDocs(instructor_docs)
    }

    const handleSelectedEmploymentContractFileCallback = (file: File) => {
        if (!instructor_docs)
            return

        instructor_docs['employment_contract'] = file
        setInstructorDocs(instructor_docs)
    }

    const setPhoneNumberCallback = (number: string) => {
        setContactInfo(props => ({...props, phone_number: number}))
    }  

    const setCountryCodeCallback = (country_code: string) => {
        setContactInfo(props => ({...props, country_code: country_code}))
    }  
    
    const setEmailCallback = (email: string) => {
        setContactInfo(props => ({...props, email: email}))
    }   

    const handleShirtChange = (event: SelectChangeEvent) => {
        setShirtSize(event.target.value as string)
    }


    return (
        <Settings>
            <SettingsHeadingTypography variant="h1" > {t('your_profile')} </SettingsHeadingTypography>
            <SettingsSubHeading> {t('personal_details')} </SettingsSubHeading> 
            <form>
                <TextField
                    disabled
                    label={t('name')}
                    name="Name"
                    value={username}
                    fullWidth
                />
            </form>

            <form onSubmit={handleAddressSubmit}>
                <SettingsSubHeading button_text={t('update_address') as string}>{t('address')}</SettingsSubHeading>
                {address.country && <AddressFields address_values={address}/>}
            </form>

            <form onSubmit={handleContactSubmit}>
                <SettingsSubHeading button_text={t('update_contact_details') as string}>{t('contact_details')}</SettingsSubHeading>
                <PhoneNumber initial_phone_number={contact_info.phone_number} setPhoneNumberCallback={setPhoneNumberCallback} initial_country_code={contact_info.country_code} setCountryCodeCallback={setCountryCodeCallback}/>
                <br/>
                <EmailFields initial_email={contact_info.email} setEmailCallback={setEmailCallback}/>
            </form>
            {isInstructor && <form onSubmit={handleInstructorDetailsSubmit}>
                <SettingsSubHeading button_text={t('update_instructor_details') as string}>{t('instructor_details')}</SettingsSubHeading>
                <InputLabel id="demo-simple-select-label">{t('shirt_size')}</InputLabel>
                <Select
                    labelId="shirt-select-label"
                    id="shirt-select"
                    value={shirtSize}
                    label={t('shirt_size')}
                    onChange={handleShirtChange}
                    fullWidth
                    sx={{marginBottom: '2rem'}}
                >
                    <MenuItem value={1}>{t('extra_small')}</MenuItem>
                    <MenuItem value={2}>{t('small')}</MenuItem>
                    <MenuItem value={3}>{t('medium')}</MenuItem>
                    <MenuItem value={4}>{t('large')}</MenuItem>
                    <MenuItem value={5}>{t('extra_large')}</MenuItem>
                    <MenuItem value={6}>XXL</MenuItem>
                    <MenuItem value={7}>XXXL</MenuItem>
                </Select>
                {instructor_docs ?
                    <Box sx={{display: 'flex', flexWrap: 'wrap', gap: '1rem', justifyContent: 'space-between'}}>
                        <INastixFileUpload initial_file={instructor_docs.gl_course} label={gl_course_label} setSelectedFileCallback={handleSelectedSouthAfricanGICourseFileCallback} helper_text='*Optional'/>
                        <INastixFileUpload initial_file={instructor_docs.employment_contract} label={employment_contract_label} setSelectedFileCallback={handleSelectedEmploymentContractFileCallback}/>
                        <INastixFileUpload initial_file={instructor_docs.police_clearance_certificate} label={police_clearance_label} setSelectedFileCallback={handleSelectedPoliceClearanceCertificateFileCallback}/>
                        <INastixFileUpload initial_file={instructor_docs.copy_of_id_photo} label={copy_of_id_label} setSelectedFileCallback={handleSelectedCopyOfIdPhotoFileCallback}/>
                    </Box>
                    : <></>
                }
            </form>}
            <SettingsSubHeading>Language</SettingsSubHeading>
            <LanguageSelector/>
        </Settings>
    )
}

export default UserSettings