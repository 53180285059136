import { CRUDAction } from "@actions/Actions/actions"
import FZoneEcommerceProductHandler from "@adapters/handlers/CRUDHandlers/FZoneEcommerceHandlers/fzoneEcommerceProductHandler"


class FZoneEcommerceProductActions extends CRUDAction {
    handler: FZoneEcommerceProductHandler

    constructor() {
        super()
        this.handler = new FZoneEcommerceProductHandler()
    }
}

export default FZoneEcommerceProductActions