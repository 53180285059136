import { GenericAction } from "@actions/Actions/actions"
import TenantHandler from "@adapters/handlers/Xero/TenantHandlers/tenantHandler"


class TenantActions extends GenericAction {
    handler: TenantHandler

    constructor() {
        super()
        this.handler = new TenantHandler()
    }

    get (): Promise<Array<any>> {
        return this.handler.get()
    }

    // id: is the id found in xero tokens response on OAuth and not the tenantId (aka. the company_uuid)
    disconnect (id: string): Promise<any> {
        return this.handler.delete(id)
    }
}

export default TenantActions