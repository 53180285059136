import ChildHandler from "@adapters/handlers/CRUDHandlers/ChildHandlers/childHandlers"
import { CRUDAction } from "@actions/Actions/actions"
import TransferToClassHandler from "@adapters/handlers/CRUDHandlers/ChildHandlers/transferToClassHandler"
import { Child, ChildAuthorisation, MonkeynastixChildAuthorisation } from "@utils/interfaces/interfaces"
import TransferToAttendanceHandler from "@adapters/handlers/CRUDHandlers/ChildHandlers/transferToAttendanceHandler"
import EmailActions from "@actions/EmailActions/emailActions"
import log from "loglevel"
import ChildStatHandler from "@adapters/handlers/ChildHandlers/childStatHandler"


class ChildActions extends CRUDAction {
    handler: ChildHandler
    transfer_to_class_handler: TransferToClassHandler
    transfter_to_attendance_handler: TransferToAttendanceHandler
    child_stat_handler: ChildStatHandler

    constructor() {
        super()
        this.handler = new ChildHandler()
        this.transfer_to_class_handler = new TransferToClassHandler()
        this.transfter_to_attendance_handler = new TransferToAttendanceHandler()
        this.child_stat_handler = new ChildStatHandler()
    }

    getChild(id: string): Promise<any> {
        return this.handler.getById(id)
    }

    getByFranchisee(franchisee: string): Promise<any> {
        return this.handler.getByFranchisee(franchisee)
    }

    getByInClass = (class_id: string, page_size?: number, page?: number) => {
        const filter_object = {
            'company_class': class_id, 
            'authorized': [ChildAuthorisation.AUTHORISED, ChildAuthorisation.AWAITING_PAYMENT], 
            'monkeynastix_authorised': MonkeynastixChildAuthorisation.AUTHORISED, 
        }

        return this.handler.get(page, page_size, filter_object)
    }

    getByNotInClass = (class_id: string, school_id: number, page_size?: number, page?: number) => {
        const filter_object = {
            'not_in_company_class': class_id, 
            'authorized': [ChildAuthorisation.AUTHORISED, ChildAuthorisation.AWAITING_PAYMENT], 
            'monkeynastix_authorised': MonkeynastixChildAuthorisation.AUTHORISED, 
            'tenant__real_school': school_id,  // filter child by the classes school
        }
        return this.handler.get(page, page_size, filter_object)
    }

    /**
     * Transfers children between classes.
     * @param {number} class_id - The ID of the target class.
     * @param {Child[]} in_class - An array of children to transfer to the target class.
     * @param {Child[]} not_in_class - An array of children to not put in the target class.
     * @returns {Promise<any>} - A promise that resolves with the response from the API.
    */

    unarchiveChild(child: Child): Promise<any> {
        const updatedChild = { ...child, archive: false }
        return this.update(updatedChild, child.id.toString()) 
    }

    transferChildren = (class_id: number, in_class: Child[], not_in_class: Child[]): Promise<any> => {
        const updated_in_class: Child[] = in_class.map(child => {
            const company_class = child.company_class
            
            if (!company_class.includes(class_id))
                company_class.push(class_id)
            
            return child
        })

        const updated_not_in_class: Child[] = not_in_class.map(child => {
            const company_class = child.company_class
            
            const filteredClass = company_class.filter(id => id !== class_id)
            child.company_class = filteredClass
            
            return child
        })

        const data = updated_in_class.concat(updated_not_in_class)
        return this.transfer_to_class_handler.transferChildren(data)
    }

    resetChildClasses = (child: Child): Child => {
        child.company_class = []
        return child
    }

    childHasBecomeUnauthorized = (child: Child): Promise<any> => {
        const reset_child = this.resetChildClasses(child)

        // email parent that child has become unauthorised
        const email_actions = new EmailActions()
        email_actions.sendToUnauthorisedMember({child_id: reset_child.id})
        .then(() => {
            log.info("Child unauthorised email has been sent successfully")
        })
        .catch(() => {
            log.error("Failed to send child unauthorised email")
            log.info("Ignoring email send failure...")
        })

        return this.transfer_to_class_handler.transferChildren([reset_child])
    }

    /**
     * Transfers children from didn't attend class to attended class.
     * @param {number} class_id - The ID of the target class.
     * @param {Child[]} attended_class - An array of children to transfer to the target class.
     * @param {Child[]} didnt_attend_class - An array of children to not put in the target class.
     * @returns {Promise<any>} - A promise that resolves with the response from the API.
    */
    transferAttendanceChildren = (class_id: number, attended_class: Child[], didnt_attend_class: Child[]): Promise<any> => {
        const obj = {
            class_id: class_id,
            attended_class: attended_class,
            didnt_attend_class: didnt_attend_class
        }

        return this.transfter_to_attendance_handler.transferToAttendanceChildren(obj)
    }

    childrenStatAge = (data: object): Promise<any> => {
        return this.child_stat_handler.childrenStatAge(data)
    }

    childrenStatTShirts = (data: object): Promise<any> => {
        return this.child_stat_handler.childrenStatTShirts(data)
    }

    instructorStatTShirts = (data: object): Promise<any> => {
        return this.child_stat_handler.instructorStatTShirts(data)
    }

    childrenTotalStat = (data: object): Promise<any> => {
        return this.child_stat_handler.childrenTotalStat(data)
    }

    childrenStatusesStat = (data: object): Promise<any> => {
        return this.child_stat_handler.childrenStatusesStat(data)
    }

    childrenAttritionRateNumbersStat = (data: object): Promise<any> => {
        return this.child_stat_handler.childrenAttritionRateNumbersStat(data)
    }

    childrenAttritionRatePercentageStat = (data: object): Promise<any> => {
        return this.child_stat_handler.childrenAttritionRatePercentageStat(data)
    }

    childrenCertificatesStat = (data: object): Promise<any> => {
        return this.child_stat_handler.childrenCertificatesStat(data)
    }

    childrenStatTermToTermGrowth = (data: object): Promise<any> => {
        return this.child_stat_handler.childrenStatTermToTermGrowth(data)
    }

    childrenStatBirthday = (data: object): Promise<any> => {
        return this.child_stat_handler.childrenStatBirthday(data)
    }
}

export default ChildActions