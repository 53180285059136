
import React from 'react'
import { useTranslation } from 'react-i18next'

import Grid from '@mui/material/Grid'

import DatePickerObjectNastix from '@components/pickers/DatePickerObjectNastix'

import { Finance } from './types'


interface CompanyFinanceDetailsProps {
    dates: Finance
    setDates: React.Dispatch<React.SetStateAction<Finance>>
    disabled?: boolean
}

const CompanyFinanceDetails = (props: CompanyFinanceDetailsProps) => {
    const {dates, setDates, disabled=false} = props

    const { t } = useTranslation('finance_terms')

    return (
        <>
        {dates &&
            <Grid container spacing={2} justifyContent={'center'}>
                <Grid item md={6} sm={12}>
                    <DatePickerObjectNastix 
                        disabled={disabled}
                        dates={dates} 
                        setDates={setDates} 
                        dates_key='financial_year_start'
                        label={t('financial_year_start')}
                    />
                </Grid>
                <Grid item md={6} sm={12}>
                    <DatePickerObjectNastix
                        disabled={disabled}
                        dates={dates} 
                        setDates={setDates} 
                        dates_key='financial_year_end'
                        label={t('financial_year_end')}
                    />
                </Grid>
            </Grid>
        }
        </>

    )
}

export default CompanyFinanceDetails