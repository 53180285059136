

import log from "loglevel"
//import { PublicHolidays } from "../../../../packages/timetable/src/TimeTableComponent"

interface PublicHoliday {
    summary: string,
    description: string,
    start: {date: string},
    end: {date: string},
}

export interface PublicHolidays {
    items: PublicHoliday[]
}


// NOTE: remove '.official' if holidays must be 'public holdiays and other holidays'
const CALENDAR_ID_MAP: Record<string, string> = {
    '1': encodeURIComponent('en.sa.official#holiday@group.v.calendar.google.com'),  // South Africa
    '2': encodeURIComponent('en.bw.official#holiday@group.v.calendar.google.com'),  // Botswana
    '3': encodeURIComponent('en.malaysia.official#holiday@group.v.calendar.google.com'),  // Malaysia
    '4': encodeURIComponent('en.gh.official#holiday@group.v.calendar.google.com'),  // Ghana
    '5': encodeURIComponent('en.ls.official#holiday@group.v.calendar.google.com'),  // Lesotho
    '6': encodeURIComponent('en.ke.official#holiday@group.v.calendar.google.com'),  // Kenya
    '7': encodeURIComponent('en.zm.official#holiday@group.v.calendar.google.com'),  // Zambia
    '8': encodeURIComponent('en.na.official#holiday@group.v.calendar.google.com'),  // Namibia
    '9': encodeURIComponent('en.mu.official#holiday@group.v.calendar.google.com'),  // Mauritius
    '10': encodeURIComponent('en.cy.official#holiday@group.v.calendar.google.com'),  // Cyprus
    '11': encodeURIComponent('en.irish.official#holiday@group.v.calendar.google.com'),  // Northern Ireland
    '12': encodeURIComponent('en.uk.official#holiday@group.v.calendar.google.com'),  // United Kingdom
    '13': encodeURIComponent('en.mt.official#holiday@group.v.calendar.google.com'),  // Malta
    '14': encodeURIComponent('en.sa.official#holiday@group.v.calendar.google.com'),  // Earth as South Africa
    '15': encodeURIComponent('en.usa.official#holiday@group.v.calendar.google.com'),  // United States
    '16': encodeURIComponent('en.french.official#holiday@group.v.calendar.google.com'),  // France
    '17': encodeURIComponent('en.cd.official#holiday@group.v.calendar.google.com'),  // Democratic Republic of the Congo
}

// FIND THE GOOGLE CALENDER ID FOR A COUNTRY
// Open Google Calendar (https://calendar.google.com/):

// Go to Google Calendar and make sure you are signed in with the Google Account you want to use.
// Navigate to Other Calendars:

// On the left side of the page, find the "Other calendars" section.
// Browse Interesting Calendars:

// Click on the "+" icon next to "Other calendars" to open the menu.
// Select "Browse interesting calendars."
// Add Country Holidays Calendar:

// In the "Browse calendars of interest" window, go to the "More" tab.
// Look for "Public holidays" and find the entry for "South Africa."
// View Calendar Details:

// Click on "South Africa" to view the details of the calendar.
// Get Calendar ID:

// In the calendar details, you should see the "Calendar ID." It will look something like en.south_africa#holiday@group.v.calendar.google.com.


const fetchGoogleCalendarEvents = async (company_country: number, start_date: Date, end_date: Date): Promise<PublicHolidays | null> => {
    const api_key = process.env.REACT_APP_GOOGLE_CALENDER_KEY
    if (!api_key) {
        log.error(`api_key is undefined. Define it in the env vars`)
        return null
    }
    const calendar_id = CALENDAR_ID_MAP[company_country.toString()]

    const time_min_string = start_date.toISOString()
    const time_max = end_date.toISOString()
  
    const url = `https://www.googleapis.com/calendar/v3/calendars/${calendar_id}/events?key=${api_key}&timeMin=${time_min_string}&timeMax=${time_max}`
  
    try {
        const response = await fetch(url)
    
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`)
        }
    
        const data = await response.json()
        return data
    } catch (error) {
        log.error("Error fetching Google Calendar events:", error)
        throw error
    }
}
  

export {fetchGoogleCalendarEvents}