import React, {useEffect, useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import {
    Chart as ChartJS,
    ChartData,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'

import { ChartBox } from '../styles'
import ToggleChartVisibility from '../ToggleChartVisibility'
import ChildActions from '@actions/CRUDActions/ChildActions/childActions'
import { ChangeCompanyRerenderContext } from '@contexts/TriggerRerender/companyRerender'
import { DashbaordStatsProps } from './interfaces'


ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
)


interface YearlyData {
    previous_year_data: number[]
    current_year_data: number[]
}


const MemberRegistrations = (props: DashbaordStatsProps) => {
    const {edit_dashboard_open, checked, setChecked, selected_franchise_option, selected_school_type_options} = props

    const {change_company_rerender} = useContext(ChangeCompanyRerenderContext)
    const { t } = useTranslation('dashboard')

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top' as const,
            },
            title: {
                display: true,
                text: "Member Registrations",
            },
        },
    }

    const labels = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    const current_year_label = t('current_year')
    const previous_year_label = t('previous_year')

    const [data, setData] = useState<ChartData<"bar", (number | [number, number] | null)[], string>>({labels, datasets: []})

    useEffect(() => {
        if (!selected_franchise_option)
            return
        
        const setChildrenTermToTermGrowthData = async () => {
            const child_actions = new ChildActions()

            const data: any = {
                franchise_id: selected_franchise_option.id,
            }
            if (selected_school_type_options)
                data['selected_school_type_options'] = selected_school_type_options

            const term_to_term_growth_chidlren: YearlyData = await child_actions.childrenStatTermToTermGrowth(data)
    
            setData({
                labels,
                datasets: [
                    {
                        label: current_year_label,
                        data: term_to_term_growth_chidlren.current_year_data,
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                    {
                        label: previous_year_label,
                        data: term_to_term_growth_chidlren.previous_year_data,
                        backgroundColor: 'rgba(53, 162, 235, 0.7)',
                    },
                ],
            })
        }

        setChildrenTermToTermGrowthData()
    }, [selected_franchise_option, selected_school_type_options, change_company_rerender])

    return (
        <>
        <ChartBox edit_dashboard_open={edit_dashboard_open} checked={checked}>
            <Bar options={options} data={data} />
            <canvas style={{ display: 'inline', height: 0 }} />
        </ChartBox>
        {edit_dashboard_open && 
            <>
            <ToggleChartVisibility checked={checked} setChecked={setChecked}/>
            </>
        }
        </>
    )
}

export default MemberRegistrations
