/**
 * Converts seconds to minutes.
 * @param seconds - The number of seconds to convert.
 * @param round - A boolean indicating whether to round the result.
 * @param decimalPlaces - The number of decimal places to round to (default is 2).
 * @returns The equivalent number of minutes.
*/
export function convertSecondsToMinutes(seconds: number, round: boolean=false, decimalPlaces: number = 2): number {
    if (seconds < 0) {
        throw new Error("Seconds cannot be negative")
    }

    let minutes = seconds / 60

    if (round) {
        minutes = parseFloat(minutes.toFixed(decimalPlaces))
    }

    return minutes
}