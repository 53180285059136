import {useState} from 'react'
import { useTranslation } from 'react-i18next'

import TextField from '@mui/material/TextField'
import FormControl from '@mui/material/FormControl'
import Divider from '@mui/material/Divider'

import PhoneNumber from '@pages/Setup/UserSetup/UserDetails/PhoneNumber'

import SelectPreferredContact from './SelectPreferredContact'


interface InitialValues {
    preferred_contact_user_id?: string
    email: string
    country_code?: string
    phone_number?: string
}

interface ContactProps {
    initial_values?: InitialValues
    show_preferred_contact_user?: boolean
    autocomplete?: "on" | "off"
}

const Contact = (props: ContactProps) => {
    const {initial_values, show_preferred_contact_user, autocomplete="on"} = props
    
    const [preferred_contact, setPreferredContact] = useState('')

    const{ t } = useTranslation('company_details')

    const add_autocomplete = autocomplete === "on" ? true : false

    return (
        <>
        {show_preferred_contact_user && (
            <div>
                <SelectPreferredContact
                    preferred_contact={preferred_contact}
                    setPreferredContact={setPreferredContact}
                    initial_value={initial_values?.preferred_contact_user_id}
                />
                {preferred_contact ? null : <Divider sx={{ margin: '1rem 0' }}> or </Divider>}
            </div>
        )}
        {preferred_contact ? <></> : 
            <FormControl>
            <TextField
                id="email"
                name="email"
                label={t('email')}
                placeholder="example@email.com"
                defaultValue={initial_values?.email}
                autoComplete={autocomplete}
                required
            />
            <br/>
            <PhoneNumber 
                initial_phone_number={initial_values?.phone_number}
                initial_country_code={initial_values?.country_code}
                add_autocomplete={add_autocomplete}
            />
            </FormControl>
        }
        </>
    )
}

export default Contact