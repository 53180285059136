import { GenericAction } from "@actions/Actions/actions"
import XeroAccountCodesHandler from "@adapters/handlers/Xero/XeroAccountCodesHandlers/xeroAccountCodesHandler"


class XeroAccountCodesActions extends GenericAction {
    handler: XeroAccountCodesHandler

    constructor() {
        super()
        this.handler = new XeroAccountCodesHandler()
    }
}

export default XeroAccountCodesActions