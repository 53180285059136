import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { TextField } from '@mui/material'


interface EmailFieldsProps {
    initial_email?: string
    setEmailCallback?: (value: string) => void
    disabled?: boolean
}

const EmailFields = (props: EmailFieldsProps) => {
    const {initial_email, setEmailCallback, disabled=false} = props

    const [email, setEmail] = useState('')
    const { t } = useTranslation('fields')

    useEffect(() => {
        if (initial_email)
            setEmail(initial_email)
    }, [initial_email])

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value)

        if (setEmailCallback)
            setEmailCallback(event.target.value)
    }

    return (
        <>
        <TextField
            disabled={disabled}
            label={t('email')}
            name="email"
            value={email}
            onChange={handleChange}
            fullWidth
        />
        </>
    )
}

export default EmailFields
