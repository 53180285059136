import { useEffect, useContext, useState, useCallback } from "react"
import { useTranslation } from "react-i18next"

import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import { Box } from "@mui/system"
import RefreshIcon from '@mui/icons-material/Refresh'

import UserActions from "@actions/CRUDActions/UserActions/userActions"
import {updateLocalStorageUser } from "@utils/localStorage/user"
import { SetupStepContext } from '@contexts/SetupStepContext'
import { SetupBox, SetupHeadingTypography } from "@styles/Setup"

import { MessageContext } from "@contexts/MessageContext"

import { AuthorizedStatus } from "./enum"


const GetAuthorized = () => {

    const {setSetupStep} = useContext(SetupStepContext)
    const {setMessage} = useContext(MessageContext)

    const [auth_status, setAuthStatus] = useState<Record<string, AuthorizedStatus>>()

    const{ t } = useTranslation('get_authorized')

    const checkAuthStatus = useCallback(async () => {
        const user_actions = new UserActions()
        return user_actions.getAuthorizedSetup()
        .then(data => {
            setAuthStatus(data)
            updateLocalStorageUser('connected_group', data['connected_group'])
            const { master_admin_authorized, monkeynastix_authorized, operator_authorized } = data
            if ([master_admin_authorized, monkeynastix_authorized, operator_authorized].every(status => status === AuthorizedStatus.AUTHORIZED)) {
                updateLocalStorageUser('setup_step', -1)
                setSetupStep(-1)
                setMessage(props => ({...props, success: t('you_have_been_successfully_authorised')}))
                return true
            }
            else
                return false
        })
    }, [setMessage, setSetupStep, t])
    
    useEffect(() => {
        checkAuthStatus()
    }, [checkAuthStatus])

    const checkAuthStatusWithMessage = () => {
        checkAuthStatus()
        .then((is_authorised: boolean) => {
            if (!is_authorised) 
                setMessage(props => ({...props, info: t('you_are_still_pending_authorisation')}))
        })
    }

    return (
        <SetupBox>
            <SetupHeadingTypography marginTop='3rem'> {t('get_authorized')} </SetupHeadingTypography>
            <Typography variant="subtitle1" align="center" marginTop='0.7rem'> {t('you_are_pending_authorization_from')} 
                {
                    auth_status?.master_admin_authorized !== 1 ? <span> {t('your_master_franchise_operator')}</span>
                    : (
                        auth_status?.operator_authorized !== 1 ? (
                            <>
                                <span> {t('your_franchise_operator')}. </span>
                            </>
                        ) : (
                            <span> {t('the_monkeynastix_admin')}</span>
                        )
                    )
                } 
                <br/>
            {t('they_have_been_notified_and_should_react_shortly')} 
            </Typography>
            {/* <Typography variant="caption" marginTop={'1rem'}> {t('refresh_the_page_once_you_have_been_authorised')} </Typography> */}
            <Button onClick={checkAuthStatusWithMessage} variant="contained" sx={{gap:'1rem', marginTop: '2rem'}}>
                {t('check_auth_status')}
                <RefreshIcon/>
            </Button>
            <Box sx={{flexGrow: 0.9}}></Box>
        </SetupBox>
    )
}

export default GetAuthorized