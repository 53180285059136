import {useState, useContext} from 'react'
import { useTranslation } from 'react-i18next'

import Button from '@mui/material/Button'
import {Typography} from '@mui/material'
import SummarizeIcon from '@mui/icons-material/Summarize'

import { DialogComponentProps } from '@components/tables/Table/Table'
import { TableActionButon } from '@styles/Buttons'
import { DialogDivider, INastixDialog, INastixDialogTitle } from '@styles/Dialog'
import { DialogProps } from '@utils/dialogProps/DialogProps'
import { NumOfRequestsContext } from '@contexts/NumOfRequestsContext'

import { handleTShirtReportCreate } from '../handleSend'
import { ReportDocType, TShirtReportType } from '../enum'
import { SelectReportDocType } from '../components'


const CreateInstructorTShirtReportDialog = (props: DialogProps) => {
	// disable the warning for not using state_manager in this dialog
	// eslint-disable-next-line  
	const {ids, state_manager, onClose, open} = props

	const { t } = useTranslation('t_shirt_reports')

	const {setNumOfRequests} = useContext(NumOfRequestsContext)

	const [report_doc_type, setReportDocType] = useState<ReportDocType>(ReportDocType.EXCEL)

	const setReportDocTypeCallback = (value: ReportDocType) => {
		setReportDocType(value)
	}

	const handleClose = () => {
		onClose()
	}

	return (
		<INastixDialog onClose={handleClose} open={open}>
			<INastixDialogTitle> {t('create_t_shirt_report')} </INastixDialogTitle>
			<DialogDivider/>
			<form onSubmit={(event) => handleTShirtReportCreate(event, setNumOfRequests, 'instructor_ids', ids, TShirtReportType.PER_INSTRUCTOR_REPORT, report_doc_type)}>
				<SelectReportDocType setReportDocTypeCallback={setReportDocTypeCallback}/>
				<Button variant='contained' type="submit" sx={{width: '100%', marginTop: '1rem'}}>{t('create')}</Button>
        	</form>
        </INastixDialog>
	)
}


const CreateInstructorTShirtReporDialogHandler = (props: DialogComponentProps) => {
	const {disabled, ids, state_manager} = props

	const [open, setOpen] = useState(false)

	const handleClickOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	const { t } = useTranslation('t_shirt_reports')

	return (
		<>
		<TableActionButon disabled={disabled} onClick={handleClickOpen} startIcon={<SummarizeIcon/>}>
			<Typography> {t('create_t_shirt_report')} </Typography>
		</TableActionButon>
		<CreateInstructorTShirtReportDialog ids={ids} state_manager={state_manager} open={open} onClose={handleClose}/>
		</>
	)
}

export default CreateInstructorTShirtReporDialogHandler