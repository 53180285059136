import { useState, useContext, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { Dayjs } from 'dayjs'

import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Accordion from '@mui/material/Accordion'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { FormHelperText } from '@mui/material'

import { MessageContext } from '@contexts/MessageContext'
import { SubmitButon } from '@styles/Buttons'
import Form, { FormDataEntryType } from "@components/forms/Form"
import CompanyActions from '@actions/CompanyActions/companyActions'
import { getLocalStorageCompany, updateLocalStorageCompany } from '@utils/localStorage/company'
import { CompanySetupStepContext } from '@contexts/CompanySetupStepContext'
import { SetupBox, SetupFormGrid, SetupHeadingTypography, SetupSubHeadingTypography } from '@styles/Setup'
import BankDetailsFields from '@components/forms/Fields/BankDetails'
import TradingStatus from '@components/forms/Fields/CompanyTradingStatusSelect/enum'
import CompanyTradingStatusSelect from '@components/forms/Fields/CompanyTradingStatusSelect'
import { companyIsInGroup } from '@utils/utils/util'
import { CompanyType } from '@utils/enums/enums'
import { INastixCurrencyMap } from '@utils/maps/maps'
import { cleanFeesObjectForBackend } from '@utils/finance/fees'
import { CountryEnum } from '@components/forms/Fields/ChooseCountry/enum'

import log from 'loglevel'

import CurrencySelector, { Currency } from './CurrencySelector'
import CompanyRegistrationDetails from './CompanyRegistrationDetails'
import CompanyFinanceDetails from './CompanyFinaceDetails'
import HandleFees from './HandleFees'
import HandleTerms from './HandleTerms'
import { Terms } from './types'
import MasterFranchiseRegFees from './components/MasterFranchiseRegFees'
import ChildAuthorisationDuePeriod from './components/ChildAuthorisationDuePeriod'
import IsVatRegistered from './IsVatRegistered'


interface FinanceAndTermsFormData {
    company_reg_number: string
    company_vat_number: string
    currency: string
    financial_year_end: string
    financial_year_start: string
    [key: string]: string // Dynamic terms properties
}

export interface FeesObject {
    extramural_fees: Number[]
    intramural_fees: Number[]
    per_class_fees: Number[]
}

export interface CountryTerm {
    country: number
    country_name: string
    id: number
    name: string
    num_of_terms: number
}


const FinanceAndTerms = () => {
    const theme = useTheme()
    const company = getLocalStorageCompany()
    const is_master_franchise = companyIsInGroup(company, CompanyType.MASTER_FRANCHISE)

    const {setCompanySetupStep} = useContext(CompanySetupStepContext)
    const {setMessage} = useContext(MessageContext)
    const{ t } = useTranslation('finance_terms')

    const [dates, setDates] = useState<Record<string, Dayjs | null> | null>({
		'financial_year_start': null,
		'financial_year_end': null,
	})

    const [terms, setTerms] = useState<Record<string, Terms> | null>(null)
    const [country_terms_type_checkbox, setCountryTermsTypeCheckbox] = useState<string[]>([])
    const [trading_status, setTradingStatus] = useState<TradingStatus>(TradingStatus.DEFAULT)
    const [is_vat_registered, setIsVatRegistered] = useState(false)
    const [is_intramural, setIsIntramural] = useState(false)
    const [is_extramural, setIsExtramural] = useState(false)
    const [country_terms, setCountryTerms] = useState<CountryTerm[] | null>(null)
    const [selected_currency, setSelectedCurrency] = useState<Currency | null>(null)
    const country_name = country_terms ? country_terms[0].country_name : ""

    const [is_yearly_payments, setIsYearlyPayments] = useState(false)
    const [is_termly_payments, setIsTermlyPayments] = useState(false)
    const [is_monthly_payments, setIsMonthlyPayments] = useState(false)
    const [is_per_class_payments, setIsPerClassPayments] = useState(false)

    const fees_for_intramural = t('fees_for_intramural')
    const fees_for_extramural = t('fees_for_extramural')
    const possible_fees_charged_per_class = t('possible_fees_charged_per_class')

    useEffect(() => {
        const company_actions = new CompanyActions()
        company_actions.getCountryTerms()
        .then((country_terms: CountryTerm[]) => {
            setCountryTerms(country_terms)
        })
    }, [])
    
    const handleTradingStatusOnSelect = (status: TradingStatus) => {
        setTradingStatus(status)
    }

    const handleVatRegisteredOnSelect = (value: string) => {
        if (value === 'True') {
            setIsVatRegistered(true)
        } else {
            setIsVatRegistered(false)
        }
    }

    const handleIsIntramural = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsIntramural(event.target.checked)
    }

    const handleIsExtramural = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsExtramural(event.target.checked)
    }

    const handleIsYearlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsYearlyPayments(event.target.checked)
    }

    const handleIsTermlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsTermlyPayments(event.target.checked)
    }

    const handleIsMonthlyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsMonthlyPayments(event.target.checked)
    }

    const handleIsPerClassChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsPerClassPayments(event.target.checked)
    }

    const setTermsCallback = useCallback((terms: Terms, key_terms_callback_wrapper: string) => {
        setTerms((prevProps) => ({ ...prevProps, [key_terms_callback_wrapper]: terms }))
    }, [setTerms])

    const currencySelectedCallback = (selected_curreny: Currency | null) => {
        setSelectedCurrency(selected_curreny)
    }

    const currency_symbol = selected_currency?.backend_map ? INastixCurrencyMap[selected_currency.backend_map] : null

    console.log(company.country)
    const createFields = () => {
        return (
            <SetupBox>
                <SetupHeadingTypography variant='h2'> {t('finance_and_fees')} </SetupHeadingTypography>
                <br/>
                <br/>
                <SetupFormGrid container sx={{...(is_master_franchise ? {} : {flexDirection: 'row-reverse'})}}>
                    <Grid item md={6} sx={{padding:'1rem 2rem'}}>
                        <Typography variant="h3" sx={{fontSize: theme.typography.h6.fontSize, fontWeight: '600', color: 'darkslategray'}}>
                            {t('payment_structures')} 
                        </Typography>
                        <br/>
                        <Alert severity="info" >
                            <AlertTitle> {t('note')} </AlertTitle>
                            <Typography variant="body1" fontSize={'0.85rem'}>
                                {t('these_should_be_all_the_payment_structures_that_you_allow')}
                            </Typography>
                        </Alert>
                        <Grid container spacing={2} justifyContent={'space-around'}>
                            <Grid item md={6}>    
                                <FormControlLabel 
                                control={
                                    <Checkbox 
                                        name='is_yearly_payments' 
                                        checked={is_yearly_payments}
                                        onChange={handleIsYearlyChange}
                                    />
                                } 
                                label={t("yearly_payments")} 
                            />
                            </Grid>
                            <Grid item md={6}> 
                                <FormControlLabel 
                                control={
                                    <Checkbox 
                                        name='is_termly_payments' 
                                        checked={is_termly_payments}
                                        onChange={handleIsTermlyChange}
                                    />
                                } 
                                label={t("termly_payments")} 
                            />
                            </Grid>
                            <Grid item md={6}> 
                            <FormControlLabel 
                                control={
                                    <Checkbox 
                                        name='is_monthly_payments' 
                                        checked={is_monthly_payments}
                                        onChange={handleIsMonthlyChange}
                                    />
                                } 
                                label={t("monthly_payments")} 
                            />
                            </Grid>
                            <Grid item md={6}> 
                            <FormControlLabel 
                                control={
                                    <Checkbox 
                                        name='is_per_class_payments' 
                                        checked={is_per_class_payments}
                                        onChange={handleIsPerClassChange}
                                    />
                                } 
                                label={t("per_class_payments")} 
                            />
                            </Grid>
                        </Grid>
                        <br/>
                        <Typography variant="h3" sx={{fontSize: theme.typography.h6.fontSize, fontWeight: '600', color: 'darkslategray'}}>
                            {t('fees')} 
                        </Typography>
                        <br/>
                        <Alert severity="info" >
                            <AlertTitle> {t('important_notes')} </AlertTitle>
                            <Typography variant="body1" fontSize={'0.85rem'}>
                                {is_vat_registered ? <>{t('fees_are_vat_exclusive')}<br/> </> : null}
                                {t('fees_entered_here')}
                                <br/>
                                {t('all_fees_should_be_yearly')}
                            </Typography>
                        </Alert>
                        {is_master_franchise ?
                            <MasterFranchiseRegFees currency_symbol={currency_symbol || null}/>
                            : <></>
                        }
                        <br/>
                        <FormControlLabel 
                            control={
                                <Checkbox 
                                    name='is_intramural'
                                    checked={is_intramural}
                                    onChange={handleIsIntramural}
                                />
                            } 
                            label={t('intramural')} 
                        />
                        <FormControlLabel 
                            control={
                                <Checkbox 
                                    name='is_extramural'
                                    checked={is_extramural}
                                    onChange={handleIsExtramural}
                                />
                            } 
                            label={t('extramural')} 
                        />

                        { is_extramural && is_intramural ?
                        <>
                            <Grid container spacing={2}>
                                    <Grid item md={6}>    
                                        <HandleFees fees_key='extramural_fee' label={`${t('extramural_fees')} ${currency_symbol ? `(${currency_symbol})` : ''}`} helper_text={fees_for_extramural}/>
                                    </Grid>
                                    <Grid item md={6}> 
                                        <HandleFees fees_key='intramural_fee' label={`${t('intramural_fees')} ${currency_symbol ? `(${currency_symbol})` : ''}`} helper_text={fees_for_intramural}/>
                                </Grid>
                            </Grid>
                            <br/>
                            { is_per_class_payments &&
                                <HandleFees fees_key='class_fee' label={`${t('class_fees')} ${currency_symbol ? `(${currency_symbol})` : ''}`} helper_text={possible_fees_charged_per_class}/>
                            }
                        </>
                        : 
                        <>
                            <Grid container spacing={2}>
                                { is_extramural && (is_yearly_payments || is_termly_payments || is_monthly_payments) &&
                                    <Grid item md={6}>    
                                        <HandleFees fees_key='extramural_fee' label={`${t('extramural_fees')} ${currency_symbol ? `(${currency_symbol})` : ''}`} helper_text={fees_for_extramural}/>
                                    </Grid>
                                }
                                { is_intramural && (is_yearly_payments || is_termly_payments || is_monthly_payments) &&
                                    <Grid item md={6}>
                                        <HandleFees fees_key='intramural_fee' label={`${t('intramural_fees')} ${currency_symbol ? `(${currency_symbol})` : ''}`} helper_text={fees_for_intramural}/>
                                    </Grid>
                                }
                                { is_per_class_payments &&
                                    <Grid item md={6}>
                                        <HandleFees fees_key='class_fee' label={`${t('class_fees')} ${currency_symbol ? `(${currency_symbol})` : ''}`} helper_text={possible_fees_charged_per_class}/>
                                    </Grid>
                                }
                            </Grid>
                        </>
                        }

                    </Grid>
                    <Grid item md={6} sx={{padding:'1rem', position: 'relative'}}>
                        <Typography variant="h3" sx={{fontSize: theme.typography.h6.fontSize, fontWeight: '600', color: 'darkslategray'}}> 
                            {t('terms')} 
                        </Typography>
                        <br/>
                        {is_master_franchise && <ChildAuthorisationDuePeriod/>}
                        {country_name && 
                            <Alert severity="info" sx={{ maxWidth: '100%', textAlign: 'left'}}>
                                <AlertTitle> {t('note')} </AlertTitle>
                                <Typography variant="body1" fontSize={'0.85rem'}>
                                    {t('terms_based_off_the')} {country_name} {t('term_system')}.
                                    <br/> 
                                    {t('please_select_term_types_for_franchise')}
                                </Typography>
                            </Alert>
                        } 
                        {country_terms && 
                            country_terms.map((country_term: CountryTerm) => (
                            <div key={country_term.id}>
                                {is_master_franchise ? 
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>{country_term.name}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <HandleTerms setTermsCallback={setTermsCallback} key_terms_callback_wrapper={country_term.name} num_of_terms={country_term.num_of_terms}/>
                                        </AccordionDetails>
                                    </Accordion>
                                    : 
                                    <FormControlLabel 
                                        control={
                                            <Checkbox 
                                                checked={country_terms_type_checkbox.includes(country_term.name)}
                                                onChange={(e) => handleCountryTermsTypeCheckboxChange(e, country_term.name)}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />
                                        } 
                                        label={country_term.name} 
                                    />
                                }
                            </div>
                        ))}  
                        <>
                        <br/>
                        <Typography variant="h3" sx={{fontSize: theme.typography.h6.fontSize, fontWeight: '600', color: 'darkslategray'}}>
                            {t('finance')} 
                        </Typography>
                        <br/>
                        <Box display={'flex'} gap='1rem' flexWrap={'wrap'}>
                            <CompanyTradingStatusSelect onSelect={handleTradingStatusOnSelect}/>
                            <IsVatRegistered onSelect={handleVatRegisteredOnSelect}/>
                            <CurrencySelector currencySelectedCallback={currencySelectedCallback}/>
                        </Box>
                        {(trading_status === TradingStatus.DEFAULT) ? <></>
                            : (
                                (trading_status === TradingStatus.PERSONAL) ? 
                                    <><CompanyRegistrationDetails show_reg_number={false} show_vat_number={is_vat_registered}/><br/></>
                                    : <>
                                        <CompanyRegistrationDetails reg_num_required={true} show_vat_number={is_vat_registered}/>
                                        <br/>
                                    </>
                            )
                        }
                        <CompanyFinanceDetails dates={dates} setDates={setDates}/>
                        {/* We only have South African banks availble at the moment */}
                        {(company.country_name === CountryEnum.SOUTH_AFRICA || company.country === CountryEnum.SOUTH_AFRICA) ? 
                            <>
                            <br/>
                            <SetupSubHeadingTypography variant="h3"> {t('bank_details')} </SetupSubHeadingTypography>
                            <FormHelperText> {t('bank_details_of_this_franchise')} </FormHelperText>
                            <BankDetailsFields/>
                            </> : null 
                        }
                        </>
                    </Grid>
                </SetupFormGrid>
                <SubmitButon variant='contained' type="submit" sx={{width:'150px', marginLeft: 'auto'}}> {t('submit')} </SubmitButon>
            </SetupBox>
        )
    }

    const handleCountryTermsTypeCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, country_term_name: string) => {
        const checked = event.target.checked

        let updated_country_terms_type_checkbox: string[] = [...country_terms_type_checkbox]
        if (!checked) {
            updated_country_terms_type_checkbox = updated_country_terms_type_checkbox.filter(item => item !== country_term_name)
        }
        else 
            updated_country_terms_type_checkbox?.push(country_term_name)

        setCountryTermsTypeCheckbox(updated_country_terms_type_checkbox)
    }

    const onSubmit = async (form_data_object: FormDataEntryType): Promise<any> => {
        log.info(`Passed to on submit ${JSON.stringify(form_data_object)}`)

        setMessage(props => ({...props, error: ""}))
        setMessage(props => ({...props, form_error: ""}))

        if (!dates) {
            setMessage(props => ({...props, error: t('financial_year_details_cannot_be_empty')}))
            return
        }

        if (is_master_franchise && !terms) {
            setMessage(props => ({...props, error: t('term_details_cannot_be_empty')}))
            return
        }

        if (terms !== null && typeof terms === 'object') {
            if (is_master_franchise && !Object.keys(terms).length) {
                setMessage(props => ({...props, error: t('term_details_cannot_be_empty')}))
                return
            }
        }

        if (!is_master_franchise && !country_terms_type_checkbox.length) {
            setMessage(props => ({...props, error: t('select_at_least_one_term')}))
            return
        }

        const converted_dates: Record<string, string> = {}
        // convert dates to a more readible format
        Object.keys(dates).forEach((key) => {
            const date_value = dates[key]
            if (!date_value) {
                setMessage(props => ({...props, error: t('financial_year_details_cannot_be_empty')}))
                return
            }
            else
                converted_dates[key] = date_value.format('YYYY-MM-DD')
        })

        let converted_terms: Record<string, Record<string, string>> = {}
        if (is_master_franchise && terms) {
            // convert terms to a more readible format. Used to put in correct format for django Database datefield as well
            for (const country_term_key of Object.keys(terms)) {
                const country_terms = terms[country_term_key]
                if (!country_terms)
                    continue
                let found_valid_term_value = false
                for (const key of Object.keys(country_terms)) {
                    const term_value = country_terms[key]
                    
                    if (!term_value) {
                        if (found_valid_term_value) {
                            setMessage(props => ({...props, error: t('term_details_cannot_be_empty')}))
                            return
                        }
                    }
                    else {
                        if (!term_value.isValid()) {
                            setMessage(props => ({...props, error: t('invalid_date_chosen')}))
                            return
                        }

                        found_valid_term_value = true
                        if (!converted_terms[country_term_key]) {
                            converted_terms[country_term_key] = {}
                        }
                        converted_terms[country_term_key][key] = term_value.format('YYYY-MM-DD')
                    }
                }
            }
        }

        const fees_object = cleanFeesObjectForBackend(form_data_object)

        // combine the dates and the other form data into one object for post request
        const combined_object: {[x: string]: boolean | FormDataEntryValue| object} = {
            ...converted_dates,
            ...form_data_object,
            ...fees_object,
        }
        console.log(combined_object)
        if (is_master_franchise)
            combined_object['country_terms'] = {...converted_terms}
        else
            combined_object['country_terms_type'] = [...country_terms_type_checkbox]

        log.info(`Sent from on submit ${JSON.stringify(combined_object)}`)

        if (isFormValid(combined_object as any, fees_object as any)) {
            const company_action = new CompanyActions()
            return company_action.financeAndTermsSetup(combined_object)
            .then(() => {
                const company = getLocalStorageCompany()
                updateLocalStorageCompany('setup_step', company.setup_step + 1)
                setCompanySetupStep(prop => prop + 1)
            })
        }
	}

    const isFinancialYearValid = (form_data: FinanceAndTermsFormData) => {
        const year_start = new Date(form_data.financial_year_start)
        const year_end = new Date(form_data.financial_year_end)

        const difference_in_milliseconds = year_end.getTime() - year_start.getTime()
        const difference_in_days = difference_in_milliseconds / (1000 * 60 * 60 * 24)  // convert milliseconds to days

        if (difference_in_days > 150)  // 150 is estimated days of at least 5 months for financial year to be valid 
            return true

        setMessage(props => ({...props, form_error: t('financial_year_start_and_end_must_be_a_minium_of_150_days_apart')}))
        return false
    }

    const isFeesDataValid = (fees_data: FeesObject) => {
        const extramural_fees = fees_data.extramural_fees
        const intramural_fees = fees_data.intramural_fees
        const per_class_fees = fees_data.per_class_fees
        if (!is_yearly_payments && !is_termly_payments && !is_monthly_payments && !is_per_class_payments) {
            setMessage(props => ({...props, form_error: t('please_select_at_least_one_payment_structure')}))
            return false
        }

        if (is_per_class_payments && per_class_fees.length === 0) {
            setMessage(props => ({...props, form_error: t('please_make_sure_to_fill_in_one_or_more_per_class_fees')}))
            return false
        }
        if ((is_yearly_payments || is_termly_payments || is_monthly_payments) && (extramural_fees.length === 0 && intramural_fees.length === 0)) {
            setMessage(props => ({...props, form_error: t('please_make_sure_to_fill_in_one_or_more_fees_for_each_type')}))
            return false
        }
        return true
    }

    const isFormValid = (form_data: FinanceAndTermsFormData, fees_data: FeesObject) => {
        if (!isFinancialYearValid(form_data)) {
            return false
        }

        if (companyIsInGroup(company, CompanyType.FRANCHISEE) && !isFeesDataValid(fees_data))
            return false

        return true
    }

    return (
		<Form onSubmitCallback={onSubmit} createFields={createFields}/>
    )
}

export default FinanceAndTerms