import { useTranslation } from 'react-i18next'

import FranchiseeActions from '@actions/CRUDActions/FranchiseeActions/franchiseeActions'
import FranchiseeTable from '@components/tables/Custom/FranchiseeTable'

import Pages from '@pages/Page'
import { PageHeadingTypography } from '@styles/Typography'
import { CompanyPermissions, UserPermissions } from '@utils/enums/permissions'


const FranchiseePage = () => {
	const franchisee_actions = new FranchiseeActions()

	const { t } = useTranslation('franchisee_table')

	return (
		<Pages page_title="Franchisees" permission={UserPermissions.VIEW_FRANCHISEES} company_permission={CompanyPermissions.VIEW_FRANCHISEES}>
			<PageHeadingTypography> {t('franchisees')} </PageHeadingTypography>
            <FranchiseeTable action={franchisee_actions}/>
		</Pages>
	)
}

export default FranchiseePage
