enum TShirtReportType {
    PER_CHILD_REPORT = 'per_child_report',
    PER_SCHOOL_REPORT = 'per_school_report',
    PER_FRANCHISE_REPORT = 'per_franchise_report',
    PER_INSTRUCTOR_REPORT = 'per_instructor_report'
}

enum ReportDocType {
    EXCEL = 'excel',
    CSV = 'csv'
}

export {
    TShirtReportType,
    ReportDocType
}
