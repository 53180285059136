import "./styles.css";


const TypingAnimation = () => {
    return (
        <div>
            <span className="dot dot1">•</span>
            <span className="dot dot2">•</span>
            <span className="dot dot3">•</span>
        </div>
    )
}

export default TypingAnimation
