import { useEffect, useContext, useState } from "react"
import { useNavigate } from 'react-router-dom'

import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import { useMediaQuery, Theme } from '@mui/material'

import UserActions from "@actions/CRUDActions/UserActions/userActions"
import { getLocalStorageUser, updateLocalStorageUser } from "@utils/localStorage/user"
import { AuthTypography } from "@components/authentication/util/StyledComponents"
import BackToLogin from "@pages/Setup/BackToLogin"
import { IsEmailVerifiedContext } from "@contexts/IsEmailVerifiedContext"
import { LocalStorageUser } from "@utils/interfaces/interfaces"

import ResendEmailVerificationLink from "./ResendEmailVerificationLink"
import { MessageContext } from "@contexts/MessageContext"
import log from "loglevel"
import SetupFooter from "@pages/Setup/Footer"


const VerifiyEmail = () => {
    const is_small_screen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

	const navigate = useNavigate()

    const {setIsEmailVerified} = useContext(IsEmailVerifiedContext)
    const {setMessage} = useContext(MessageContext)

    const [code, setCode] = useState('')

    useEffect(() => {
        const user = getLocalStorageUser()
        
        if (Object.keys(user).length) {
            if (user.is_email_verified) {
                updateLocalStorageUser('is_email_verified', true)
                setIsEmailVerified(true)
            }
            else {
                const user_actions = new UserActions()
                user_actions.getById(user.id.toString())
                .then((user: LocalStorageUser) => {
                    if (user.is_email_verified) {
                        updateLocalStorageUser('is_email_verified', true)
                        setIsEmailVerified(true)
                    }
                })
            }
        }
    }, [setIsEmailVerified])

    const handleCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCode(event.target.value)
    }

    const handleSend = () => {
        const user_actions = new UserActions()
        user_actions.verifyEmailWithCode({code: code})
        .then(() => {
            const user = getLocalStorageUser()
            if (Object.keys(user).length) {
                setIsEmailVerified(true)
                updateLocalStorageUser('is_email_verified', true)
                navigate('/')
            }
            else {
                setMessage(props => ({...props, success: "Email verified"}))
                navigate('/login')
            }
        })
        .catch(error => {
            log.error(error.message)
            setMessage(props => ({...props, error: "Code invalid. Make sure you've entered the correct code."}))
        })
    }

    const styles_for_md_up = {position: 'absolute', right: '-100px'}
    const styles_for_md_down = {flexDirection: 'column', gap: 0}
    const main_styles = {display: 'flex', gap: '2rem', alignItems: 'center', justifyContent: 'center', position: 'relative'}
    return (
        <>
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection:'column', marginTop: '1rem'}} height={'100vh'}>
            <Box sx={is_small_screen ? {...main_styles, ...styles_for_md_down}: main_styles} mt={'auto'}>
                <AuthTypography  variant="h1"> Verify your email </AuthTypography>
                <Box sx={is_small_screen ? {}: styles_for_md_up}>
                    <BackToLogin/>
                </Box>
            </Box>
            <Typography variant="body1" textAlign={'center'} sx={{maxWidth: '50%', margin: '1rem auto 0 auto'}}> We have sent an email to your chosen email address. </Typography>
            <Typography variant="body1" textAlign={'center'} sx={{maxWidth: '50%', margin: '0.3rem auto 2rem auto'}}> Please enter the code found in the email. </Typography>
            <TextField
                label="Code"
                variant="outlined"
                value={code}
                onChange={handleCodeChange}
                fullWidth
                margin="normal"
                sx={{width: '15ch'}}
            />
            <br/>
            <br/>
            <Typography> Didn't receive an email? </Typography>
            <ResendEmailVerificationLink/>
            <br/>
            <Button variant="contained" onClick={handleSend} sx={{marginBottom: '1rem'}}> submit </Button>
            <Box sx={{ marginTop:'auto', marginBottom: '1rem'}}>
                <SetupFooter/>
            </Box>
        </Box>
        </>
    )
}

export default VerifiyEmail