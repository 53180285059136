import {useState, useEffect, useCallback} from 'react'
import { useTranslation } from 'react-i18next'

import { SelectChangeEvent } from '@mui/material/Select'

import { Instructor, LocalStorageCompany } from '@utils/interfaces/interfaces'
import { AuthorizedStatus } from '@pages/Setup/UserSetup/GetAuthorized/enum'
import { getLocalStorageCompany } from '@utils/localStorage/company'
import { companyIsInGroup, isAMemberOfThisGroup } from '@utils/utils/util'
import { CompanyType, UserType } from '@utils/enums/enums'
import UserActions from '@actions/CRUDActions/UserActions/userActions'
import { getLocalStorageUser } from '@utils/localStorage/user'
import { UserTableUser } from '@components/forms/UserForm'

import UserAuthStatusSelect from '../UserAuthStatusSelect'

import log from 'loglevel'


interface UserMultipleAuthStatusSelectProps {
    values: Instructor | UserTableUser
}

const UserMultipleAuthStatusSelect = (props: UserMultipleAuthStatusSelectProps) => {
    const {values} = props
	const { t } = useTranslation('instructor_form')

	const company = getLocalStorageCompany()
	const user = getLocalStorageUser()

	const [master_admin_authorized, setMaterAdminAuthorized] = useState<AuthorizedStatus | "">('')
	const [monkeynastix_authorized, setMonkeynastixAuthorized] = useState<AuthorizedStatus | "">('')
	const [operator_authorized, setOperatorAuthorized] = useState<AuthorizedStatus | "">('')
	const [is_user_in_company, setIsUserInCompany] = useState<boolean>(false)

	const isUserInConnectedCompany = useCallback(async (user_id: number) => {
		const user_actions = new UserActions()
	
		try {
			const connected_companies: LocalStorageCompany[] = await user_actions.getCompanies(user_id)
			const found_company = connected_companies.find(connected_company => connected_company.company_uuid === company.company_uuid)
		
			if (found_company)
				setIsUserInCompany(true)
			else
				setIsUserInCompany(false)

		} catch (error: any) {
			log.error(error.message)
			setIsUserInCompany(false)
		}
	}, [company, setIsUserInCompany])

	useEffect(() => {
		if (values?.user)
			isUserInConnectedCompany(values.user)
	}, [isUserInConnectedCompany, values])

    useEffect(() => {
		if (values?.master_admin_authorized)
			setMaterAdminAuthorized(values.master_admin_authorized)

		if (values?.operator_authorized)
			setOperatorAuthorized(values.operator_authorized)

		if (values?.monkeynastix_authorized)
			setMonkeynastixAuthorized(values.monkeynastix_authorized)
			
	}, [values])

	const handleMasterAdminAuthorisedStatusChange = (event: SelectChangeEvent) => {
		setMaterAdminAuthorized(parseInt(event.target.value, 10) as AuthorizedStatus)
	}

	const handleOperatorAuthorisedStatusChange = (event: SelectChangeEvent) => {
		setOperatorAuthorized(parseInt(event.target.value, 10) as AuthorizedStatus)
	}

	const handleMonkeynastixAdminAuthorisedStatusChange = (event: SelectChangeEvent) => {
		setMonkeynastixAuthorized(parseInt(event.target.value, 10) as AuthorizedStatus)
	}

    return (
        <>
        {is_user_in_company && 
            companyIsInGroup(company, CompanyType.MASTER_FRANCHISE) ? 
                <UserAuthStatusSelect 
                    component_id='master-admin' 
                    name='master_admin_authorized' 
                    label={t('master_admin_authorised_status')} 
                    helper_text={t('mfo_helper_text')} 
                    handleOnChange={handleMasterAdminAuthorisedStatusChange} 
                    value={master_admin_authorized.toString()}
                />

            : companyIsInGroup(company, CompanyType.FRANCHISEE) &&
                <UserAuthStatusSelect 
                    component_id='operator' 
                    name='operator_authorized' 
                    label={t('operator_authorised_status')} 
                    helper_text={t('franchise_operator_auth_status')} 
                    handleOnChange={handleOperatorAuthorisedStatusChange} 
                    value={operator_authorized.toString()}
                />
        }
        {isAMemberOfThisGroup(user, UserType.MASTER_ADMINISTRATOR) &&
            <UserAuthStatusSelect 
                component_id='monkeynastix-admin' 
                name={'monkeynastix_authorized'} 
                label={t('monkeynastix_authorised_status')}
                helper_text={t('ma_helper_text')} 
                handleOnChange={handleMonkeynastixAdminAuthorisedStatusChange} 
                value={monkeynastix_authorized.toString()}
            />
        }
        </>
    )
}

export default UserMultipleAuthStatusSelect