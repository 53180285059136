import {useState} from 'react'

import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { UserType } from '@utils/enums/enums'
import { UserMap, UserTypeDescriptions } from '@utils/maps/maps'


const default_styles = {flexShrink: 0, textTransform: 'capitalize'}

const UserTypeInfo = () => {
    const [expanded, setExpanded] = useState<string | false>(false)

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false)
    }

    return (
        <div>
        {Object.keys(UserMap).map(key => {
            if (key && key !== UserType.MASTER_ADMINISTRATOR)
                return (
                    <Accordion key={key} expanded={expanded === key} onChange={handleChange(key)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography sx={expanded === key ? {fontWeight: '600', ...default_styles} : default_styles}>
                                {UserMap[key as UserType]}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography >
                                {UserTypeDescriptions[key as string]}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                )
            else 
                return (<div key={key}></div>)
            })
        }
        </div>
    )
}

export default UserTypeInfo