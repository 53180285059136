import { CRUDAction } from "@actions/Actions/actions"
import AttendanceHandler from "@adapters/handlers/CRUDHandlers/ClassHandlers/attendanceHandlers"


class AttendanceActions extends CRUDAction {
    handler: AttendanceHandler

    constructor() {
        super()
        this.handler = new AttendanceHandler()
    }
}

export default AttendanceActions