import { useTranslation } from 'react-i18next'

import ParentForm from '@components/forms/ParentForm'
import Table from '@components/tables/Table/Table'
import { getLocalStorageUser } from '@utils/localStorage/user'
import { permissionValid } from '@utils/utils/util'
import { UserPermissions } from '@utils/enums/permissions'
import ChildPaymentsActions from '@actions/Finance/PaymentActions/childPaymentActions'
import ChildPaymentsForm from '@components/forms/PaymentsForms/ChildPaymentsForm'
import CheckCircle from '@mui/icons-material/CheckCircle'
import Cancel from '@mui/icons-material/Cancel'
import SchoolPaymentsActions from '@actions/Finance/PaymentActions/schoolPaymentActions'
import SchoolYearlyAuthorisationHandler from './schoolYearlyAuthorisationHandler'

export interface TableProps {
	action: SchoolPaymentsActions
}

const SchoolYearlyTable = (props: TableProps) => {
    const {action} = props
    const { t } = useTranslation('payments')

    const user = getLocalStorageUser()

	const can_edit = permissionValid(user, UserPermissions.EDIT_CHILDREN)

    const columns = [
        { field: 'id', headerName: t('id') },
        { field: 'school_name', headerName: t('school'), minWidth: 150, flex: 0.2 },
        { field: 'num_children', headerName: t('num_children'), minWidth: 100, flex: 0.2 },
        { field: 'yearly', headerName: t('yearly'), cellClassName: 'center-aligned-cell', minWidth: 100, 
            renderCell: (params: any) => {
                if (params.value === true) 
					return <CheckCircle sx={{ color: '#51b77f' }} />
				else if (params.value === false)
					return <Cancel sx={{ color: '#ed586e' }} />
                else
                    return ''
            },
        },
    ]

    return (
        <Table
            single_selection_dialogs={[SchoolYearlyAuthorisationHandler]}
            action={action}
            filter={{'payment_structure': 'yearly'}}
            columns={columns}
            page_size={100}
			amount_of_rows_selection={[100]}
        />
    )
}

export default SchoolYearlyTable