import { BaseAction } from "@actions/Actions/actions"
import DownloadCSVReportHandler from "@adapters/handlers/ReportHandlers/downloadCSVReportHandler"


class DownloadCSVReportActions extends BaseAction {
    download_csv_report_handler: DownloadCSVReportHandler

    constructor() {
        super()
        this.download_csv_report_handler = new DownloadCSVReportHandler()
    }

    dowloadCSVReport (data: object): Promise<any> {
        return this.download_csv_report_handler.dowloadCSVReport(data)
    }
}


export default DownloadCSVReportActions