import React from 'react'

import Box from '@mui/material/Box'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
// import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
// import IconButton from '@mui/material/IconButton'


interface ToggleChartVisibilityProps {
    checked: boolean
    setChecked: React.Dispatch<React.SetStateAction<boolean>>
}

const ToggleChartVisibility = (props: ToggleChartVisibilityProps) => {
    const {checked, setChecked} = props

    const handleCheckClick = () => {
        setChecked(!checked)
    }

    return (
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', border: '1px solid grey', padding: '0.2rem'}} > 
            {/* <IconButton sx={{cursor: 'grab'}}><DragIndicatorIcon/></IconButton> */}
            <FormControlLabel sx={{marginLeft: 'auto'}} control={<Checkbox checked={checked} onClick={handleCheckClick}/>} label="Show" />
        </Box>
    )
}

export default ToggleChartVisibility