import GenericFetchWrapper from "@adapters/api/fetchWrappers/genericFetchWrapper"
import { BaseHandler } from "@adapters/handlers/Handlers/handlers"
import { USERS_ENDPOINT } from "@adapters/routes/endpoints"


class ConnectedUserHandler extends BaseHandler {
    endpoint: string
    fetch_wrapper: GenericFetchWrapper


    constructor () {
        super()
        this.fetch_wrapper = new GenericFetchWrapper()
        this.endpoint = USERS_ENDPOINT + '/connected'
    }

    get (): Promise<any> {
        return this.fetch_wrapper.get(this.endpoint)
    }
}

export default ConnectedUserHandler